import styled from 'styled-components';
import { solidColors } from '@constants/styles/colors.constants';
import { BaseButton, ImageImgix } from '@atoms';
import { Container, Grid } from '@library';

export const StyledContainer = styled(Container)`
  width: 1136px;
  height: auto;
  margin: 0;
  padding: 0;
  background-color: transparent;
  background: none;
`;

export const TitleContainer = styled.div`
  display: inline-flex;
  align-items: center;
`;

export const ButtonContainer = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: flex-end;
`;

export const StyledSvg = styled(ImageImgix)`
  width: 20px;
  height: 20px;
`;

export const SvgButton = styled(BaseButton)`
  width: 20px;
  height: 20px;
  margin-right: 32px;
  background: none;
  background-color: transparent;
`;

export const CardContent = styled.div`
  padding: 60px 0 60px 0;
`;

export const CardContainer = styled.div`
  margin-top: 40px;
  max-width: 1136px;
`;

export const BorderLine = styled.div`
  width: 968px;
  height: 1px;
  border-bottom: 1px solid;
  border-color: ${solidColors.mediumGray.color};
  margin: 39px 60px 40px 60px;
`;

export const StyledLegalSection = styled(Grid)`
  margin: 0 80px 0 80px;
`;

export const StyledGridItem = styled(Grid)`
  max-width: 444px;
  margin: 0 20px 0 20px;
`;

export const HeaderContainer = styled.div`
  margin: 0 80px 0 80px;
`;
