import { useEffect, useState } from 'react';
import { ISubPagePayment } from './SubPagePayment.interfaces';
import {
  StyledCard,
  StyledTextGroupIconText,
  StyledTitleContainer,
  StyledLine,
  StyledCustomCheckbox,
  StyledTitle,
  StyledInfoContainer,
  CardContainer,
} from './SubPagePayment.styles';
import { typography } from '@constants/styles/typography.constants';
import { solidColors } from '@constants/styles/colors.constants';
import TextGroupIconText from '@molecules/TextGroupIconText/TextGroupIconText';
import TitleIconContainer from '@molecules/TitleIconContainer/TitleIconContainer';
import { useAppDispatch } from '@app/hooks';
import {
  setPaymentLocation,
  setSelectedCredits,
  setSelectedPaymentMethods,
} from '@features/Fly/Payment/PaymentStatusSlice';
import Typography from '@atoms/Typography/Typography';
import { IKeyValue, IPayment } from '@components/types';
import { pageSliceOptions } from '@services/sliceFactoryService/sliceFactoryService.types';
import { asyncThunkFactory } from '@services/sliceFactoryService/requestSlice';
import { getFromSessionStorage } from '@components/utils/storage';
import { trackAnalytics } from '@services/mixpanelService/mixpanel.service';
import useSnackbar from '@components/molecules/SubPageFlightSearchV2/LegContainers/useSnackbar';
import { useViewport } from '@hooks';
import { theme } from '@constants/styles/theme.constants';
import {
  Checkbox,
  Icon,
  Link,
} from '@wheelsup/wu-react-components';
import { useNavigate } from 'react-router-dom';
import { useFeatureFlag } from '@services/featureFlagService/featureFlagService.services';
import { FeatureFlags } from '@services/featureFlagService/constants';

const SubPagePayment = ({
  title,
  availableCredits,
  selectedPayments,
}: ISubPagePayment) => {
  const dispatch = useAppDispatch();
  const { width } = useViewport();
  const navigate = useNavigate();
  const use_native_manage_payment_methods = useFeatureFlag(
    FeatureFlags.USE_NATIVE_MANAGE_PAYMENT_METHODS,
  );
  const isMobile = width < theme.breakpoints.values.sm;

  useEffect(() => {
    const preSelectedCredits = availableCredits?.credits?.filter(
      (credit) => credit.isPreselected,
    );
    dispatch(setSelectedCredits(preSelectedCredits || []));
    const preSelectedPayments = selectedPayments?.payments;
    dispatch(
      setSelectedPaymentMethods(preSelectedPayments),
    );
  }, [dispatch]);

  useEffect(() => {
    const preSelectedPayments = selectedPayments?.payments;
    dispatch(
      setSelectedPaymentMethods(preSelectedPayments),
    );
    if (
      preSelectedPayments &&
      preSelectedPayments[0] &&
      (preSelectedPayments[0].paymentType === 'check' ||
        preSelectedPayments[0].paymentType ===
          'wireTransfer')
    ) {
      setBackupCreditCard(
        preSelectedPayments[0].backupCreditCard,
      );
    } else {
      setBackupCreditCard(undefined);
    }
  }, [selectedPayments]);

  const [
    backupCreditCard,
    setBackupCreditCard,
  ] = useState<IPayment>();

  const openSelectPayment = () => {
    const actionAnalytics: unknown = {
      key: 'Page Name',
      value: 'Airmed Payment',
    };
    trackAnalytics({
      trackingTitle:
        'Select_EditPaymentMethodAirMed_Button',
      properties: [actionAnalytics] as IKeyValue[],
    });

    dispatch(setPaymentLocation('selectPayment'));
  };

  const openNewCreditCard = () => {
    use_native_manage_payment_methods
      ? navigate(
          '/checkout/add-payment-method?type=bank-accounts',
        )
      : dispatch(setPaymentLocation('addCreditCardPopUp'));
  };

  const snackbarHandler = useSnackbar(dispatch);

  const handleCreditCheckbox = (
    selectedCredit: IPayment,
  ) => {
    const currentSelectedCreditList =
      availableCredits?.credits?.filter(
        (credit: IPayment) => credit.isPreselected,
      ) || [];
    let newCreditList = [];
    if (
      currentSelectedCreditList?.includes(selectedCredit)
    ) {
      const newSelectedCreditList = currentSelectedCreditList?.filter(
        (credit: IPayment) =>
          credit.id !== selectedCredit.id,
      );
      newCreditList = newSelectedCreditList;
    } else {
      newCreditList = [
        ...currentSelectedCreditList,
        {
          ...selectedCredit,
          preselectedSplitValue: selectedCredit.balance,
        },
      ];
    }
    const bookingFlow = getFromSessionStorage(
      'flightPurchaseCheckout',
    );
    dispatch(setSelectedCredits(newCreditList));
    dispatch(
      asyncThunkFactory(pageSliceOptions.payment)({
        resPayload: {
          ...bookingFlow,
          needsDefaultPayment: false,
          selectedPayments: newCreditList,
        },
      }),
    );
    snackbarHandler(
      'Total cost updated. Select new primary method.',
    );
  };

  return (
    <CardContainer>
      {availableCredits && (
        <StyledCard>
          {availableCredits?.creditsTitle && (
            <StyledTitleContainer>
              <StyledTitle
                variant={typography.heading03Large}
                color={solidColors.midnight}
              >
                {availableCredits?.creditsTitle}
              </StyledTitle>
              <StyledTitle
                variant={typography.body1}
                color={solidColors.darkGray}
              >
                {availableCredits?.creditsSubtitle}
              </StyledTitle>
            </StyledTitleContainer>
          )}

          <StyledInfoContainer>
            {availableCredits.credits?.map(
              (credit: IPayment, index) => {
                return (
                  <StyledCustomCheckbox key={index}>
                    <Checkbox
                      onChange={(e) =>
                        handleCreditCheckbox(credit)
                      }
                      checked={!!credit.isPreselected}
                      label={
                        <div>
                          <Typography
                            variant={typography.body1}
                            color={solidColors.midnight}
                          >
                            {credit?.displayValue1}
                          </Typography>

                          <StyledLine>
                            <Typography
                              variant={typography.body2}
                            >
                              {credit?.displayValue2}
                            </Typography>
                            <Typography
                              variant={typography.body2}
                            >
                              {credit.displayValue3}
                            </Typography>
                          </StyledLine>
                        </div>
                      }
                      size="sm"
                    />
                  </StyledCustomCheckbox>
                );
              },
            )}
          </StyledInfoContainer>
        </StyledCard>
      )}
      {selectedPayments && (
        <StyledCard>
          {selectedPayments?.paymentTitle && (
            <StyledTitleContainer>
              <StyledTitle
                variant={typography.heading02Large}
                color={solidColors.midnight}
              >
                {selectedPayments?.paymentTitle}
              </StyledTitle>
            </StyledTitleContainer>
          )}
          <StyledInfoContainer>
            {selectedPayments?.payments?.map(
              (payment: IPayment, index) => {
                const image = (
                  <TitleIconContainer
                    width="64px"
                    height="64px"
                    radius="50%"
                    image={payment.image?.url}
                  />
                );
                return (
                  <StyledTextGroupIconText key={index}>
                    <TextGroupIconText
                      headline={payment?.displayValue1}
                      headlineVariant={
                        isMobile
                          ? typography.body2Bold
                          : typography.body1Bold
                      }
                      copy={payment?.displayValue2}
                      copySize="12px"
                      subCopy={
                        payment.fundExpirationDate
                          ? payment.fundExpirationDate
                          : ''
                      }
                      subCopy2={
                        payment.fundBalanceForfeitDate
                          ? payment.fundBalanceForfeitDate
                          : ''
                      }
                      icon={image}
                    />
                  </StyledTextGroupIconText>
                );
              },
            )}
          </StyledInfoContainer>

          {backupCreditCard && (
            <>
              <StyledTitleContainer>
                <StyledTitle
                  variant={typography.heading02Large}
                  color={solidColors.midnight}
                >
                  {'Backup Credit Card'}
                </StyledTitle>
              </StyledTitleContainer>

              <StyledInfoContainer>
                <StyledTextGroupIconText>
                  <TextGroupIconText
                    headline={
                      backupCreditCard.displayValue1
                    }
                    copy={backupCreditCard.displayValue2}
                    icon={
                      <TitleIconContainer
                        width="64px"
                        height="64px"
                        radius="50%"
                        image={backupCreditCard.image?.url}
                      />
                    }
                  />
                </StyledTextGroupIconText>
              </StyledInfoContainer>
            </>
          )}
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            {selectedPayments?.editPaymentButton && (
              <Link
                onPress={() => {
                  trackAnalytics({
                    trackingTitle:
                      'Select_EditPaymentviaPaymentPage_Button',
                    properties: [
                      {
                        key: 'Page Name',
                        value: 'Review and Pay',
                      },
                    ] as IKeyValue[],
                  });
                  openSelectPayment();
                }}
                trailingIcon={'chevron-right'}
              >
                {selectedPayments.editPaymentButton?.title}
              </Link>
            )}
            {selectedPayments?.addCreditCardButton && (
              <Link
                onPress={() => {
                  trackAnalytics({
                    trackingTitle:
                      'Select_AddPrimaryCreditCardviaPaymentPage_Button',
                    properties: [
                      {
                        key: 'Page Name',
                        value: 'Review and Pay',
                      },
                    ] as IKeyValue[],
                  });
                  openNewCreditCard();
                }}
                trailingIcon={'add'}
              >
                {
                  selectedPayments.addCreditCardButton
                    ?.title
                }
              </Link>
            )}
          </div>
        </StyledCard>
      )}
    </CardContainer>
  );
};

export default SubPagePayment;
