import { TitleIconContainer } from './TitleIconContainer.styles';
import { ITitleIconContainer } from './TitleIconContainer.interfaces';
import { ImageImgix } from '@atoms';

const TitleIcon = ({
  radius,
  borderColor,
  bgColor,
  width,
  height,
  active,
  image,
  tintColor,
  title,
}: ITitleIconContainer) => {
  const widthNumber = parseInt(width as string, 10);
  const heightNumber = parseInt(height as string, 10);

  return (
    <TitleIconContainer
      radius={radius}
      borderColor={borderColor}
      bgColor={bgColor}
      width={width}
      height={height}
      active={active}
    >
      {image && (
        <ImageImgix
          src={image}
          width={borderColor ? 20 : widthNumber}
          height={borderColor ? 20 : heightNumber}
          fit="crop"
          alt="Description"
          filter={tintColor}
        />
      )}
      {title && title}
    </TitleIconContainer>
  );
};
export default TitleIcon;
