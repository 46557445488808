import { Container } from '@library';
import styled from 'styled-components';
import {
  ISlideProp,
  ISlideGroup,
  IStyledCarouselSlideDiv,
} from './CarouselArrow.interfaces';

export const StyledCarouselCarouselArrow = styled.div<{
  styleWidth?: string;
}>`
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  width: ${({ styleWidth }) => styleWidth ?? ''};
`;

export const CarouselArrowWrapper = styled(Container)`
  align-items: center;
  justify-content: flex-start;
  padding-left: 0;
  padding-right: 0;
  margin-left: -1rem;
`;

export const StyledCarouselSlideDiv = styled.div<IStyledCarouselSlideDiv>`
  height: 100%;

  &.backgroundUpWhite {
    ${(props) => props.theme.breakpoints.down('md')} {
      .CardRequestedBookedFlightStatusCard {
        min-width: ${({ widthDiv }) => `${widthDiv}px`};
      }
    }
  }
`;
const SlideContainer = styled.div`
  margin-left: 1rem;
  margin-right: 1rem;
  height: 100%;
`;

export const CarouselSlide = ({ children }: ISlideProp) => (
  <SlideContainer
    data-name="CarouselSlide"
    className="carouselSlide"
  >
    {children}
  </SlideContainer>
);

export const SlideGroupWrapper = styled.div<ISlideGroup>`
  display: flex;
  transform: ${({ transformAmount }) =>
    transformAmount != 0
      ? `translateX(-${transformAmount}px);`
      : 'none'};
  transition: all 1s ease;
  justify-content: ${({ singleCard }) =>
    singleCard ? 'center' : 'flex-start'};
  position: relative;
  width: 100%;
`;

export const SlideWrapper = styled.div<ISlideProp>`
  flex: 0 0 auto;
  opacity: ${(props) => (props.active ? 1 : 0.4)};
  transition: all 0.5s ease;

  ${({ padding, width }) =>
    padding &&
    width &&
    `
        width: ${width + padding}px;
    `}
  ${({ isFullWidth }) =>
    isFullWidth &&
    `
        width: 100%;
    `}
`;
