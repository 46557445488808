import styled from 'styled-components';

export const AircraftInfo = styled.div``;

export const CardMobileContainer = styled.div<{
  width: string;
}>`
  width: ${({ width }) => width};
`;

export const FlightInfoContainer = styled.div`
  display: flex;
  margin-bottom: 16px;
`;

export const FlightInfoCard = styled.div`
  background-color: #fff;
  padding: 16px;
`;

export const IconInfo = styled.div`
  display: flex;
  align-items: center;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const PriceAndFlightType = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
`;

export const Divider = styled.hr`
  border-color: #d1d1d1;
  height: 1px;
  width: 90%;
  border-style: solid;
`;

export const ReservationType = styled.div`
  display: flex;
  justifycontent: end;
  padding: 16px;
`;

export const Flex1 = styled.div`
  flex: 1;
`;
