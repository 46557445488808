import { Button } from '@atoms';
import { IButton } from '@atoms/Button/Button.interfaces';
import {
  IBasePassengerDisplay,
  IImage,
} from '@components/types';
import { setSelectedLeg } from '@features/Fly/FlySearchTripDetailsSlice';
import { trackAnalytics } from '@services/mixpanelService/mixpanel.service';
import { useNavigate } from 'react-router-dom';
import TextGroupIconText from '../TextGroupIconText/TextGroupIconText';
import TitleIconContainer from '../TitleIconContainer/TitleIconContainer';
import {
  GroupContainer,
  ItemContainer,
} from './PassengerListGroup.styles';

interface IPassengerListGroup {
  passengerList: IBasePassengerDisplay[];
  leadIcon: IImage;
  addButton?: IButton;
  handlePassengerSelection: (
    passenger: IBasePassengerDisplay,
  ) => void;
  addPassengerUrl: string;
  isPetList?: boolean;
  leg?: number;
  LeadPaxButton?: React.ReactNode;
}

export default function PassengerListGroup({
  passengerList,
  leadIcon,
  addButton,
  handlePassengerSelection,
  addPassengerUrl,
  isPetList = false,
  leg,
  LeadPaxButton,
}: IPassengerListGroup) {
  const navigate = useNavigate();

  const handleAddPaxPet = () => {
    const mpTitle = isPetList
      ? 'Select_AddNewPetResPage_CTA'
      : 'Select_AddNewPassengerResPage_CTA';
    trackAnalytics({
      trackingTitle: mpTitle,
      properties: [],
    });
    setSelectedLeg(leg);
    navigate(addPassengerUrl);
  };

  return (
    <GroupContainer>
      {passengerList.map((x) => {
        return (
          <ItemContainer
            key={x.id}
            onClick={() => {
              handlePassengerSelection(x);
            }}
          >
            <TextGroupIconText
              headline={x.displayName ?? ''}
              isSpecial={x.isLead}
              isSelected={x.isSelected}
              copy={x.isLead ? 'Lead Passenger' : ''}
              image={leadIcon}
              icon={
                  <TitleIconContainer
                    width="64px"
                    height="64px"
                    image={x.profileImage?.url}
                    tintColor={x.profileImage?.tintColor}
                  />
              }
            />
          </ItemContainer>
        );
      })}
      <div
        style={{
          display: 'flex',
          marginTop: '1rem',
        }}
      >
        {addButton ? (
          <Button
            action={{
              actionMethod: handleAddPaxPet,
            }}
            title={addButton?.title}
            styleType="text_with_icon_leading"
            image={addButton.image}
          />
        ) : null}
        {LeadPaxButton}
      </div>
    </GroupContainer>
  );
}
