import { Typography } from '@atoms';
import { Grid } from '@components/library';
import {
  EmailInputStyled,
  GridStyled,
  StyledPhoneInput,
} from './DayOfTraveAlertsForm.styles';
import { typography } from '@constants/styles/typography.constants';
import { solidColors } from '@constants/styles/colors.constants';
import { useAppSelector } from '@app/hooks';
import { useDispatch } from 'react-redux';
import { setInputValue } from '@components/organisms/SubPagePassengerAddEdit/SubPagePassengerAddEdit.slice';
import { IInputField } from '@components/types';
import { useState } from 'react';

interface IDayofTravelAlertsForm {
  dayOfTravelTitle: string;
  dayOfTravelSubtitle: string;
  phoneFormField: IInputField;
  emailFormField: IInputField;
  getEmailError: (isError: boolean) => void;
  getPhoneError: (isError: boolean) => void;
}

export default function DayOfTraveAlertsForm({
  dayOfTravelTitle,
  dayOfTravelSubtitle,
  phoneFormField,
  emailFormField,
  getEmailError,
  getPhoneError,
}: IDayofTravelAlertsForm) {
  const dispatch = useDispatch();
  const { email, phone } = useAppSelector(
    (state) => state.subPassengerAddEdit,
  );
  const [isPhoneError, setIsPhoneError] = useState(false);

  const handleInputChange = (
    inputKey: string,
    value: string,
  ) => {
    dispatch(
      setInputValue({
        key: inputKey,
        value: value,
      }),
    );
  };

  const onPhoneBlur = () => {
    if (
      (phone?.length ?? 0) < 10 &&
      (phone?.length ?? 0) > 0
    ) {
      setIsPhoneError(true);
      getPhoneError(true);
    } else {
      setIsPhoneError(false);
      getPhoneError(false);
    }
  };

  return (
    <div style={{ marginTop: '40px' }}>
      <Typography
        variant={typography.heading03Large}
        color={solidColors.midnight}
      >
        {dayOfTravelTitle}
      </Typography>
      <Typography
        variant={typography.body1}
        color={solidColors.darkGray}
      >
        {dayOfTravelSubtitle}
      </Typography>
      <GridStyled
        container
        spacing={2}
      >
        <Grid
          item
          md={4}
          xs={12}
          sm={12}
        >
          <StyledPhoneInput
            type="phone"
            payloadKey={phoneFormField.key ?? ''}
            title={phoneFormField.placeholder ?? ''}
            value={phone}
            onChange={(e) => {
              handleInputChange('phone', e.target.value);
            }}
            onBlur={onPhoneBlur}
            error={isPhoneError}
            errorMessage="Use a valid phone number"
          />
        </Grid>
        <Grid
          item
          md={4}
          xs={12}
          sm={12}
        >
          <EmailInputStyled
            payloadKey="email"
            variant="outlined"
            title={emailFormField.placeholder ?? ''}
            value={email}
            onChange={(e) => {
              handleInputChange('email', e.target.value);
            }}
            getError={getEmailError}
            isRequired={false}
            maxCharactersAllowed={
              emailFormField.maxCharactersAllowed ?? 30
            }
          />
        </Grid>
      </GridStyled>
    </div>
  );
}
