import styled from 'styled-components';
import { solidColors } from '@constants/styles/colors.constants';
import { Typography } from '@atoms';
import { theme } from '@constants/styles/theme.constants';
import { Elevation } from '@wheelsup/wu-react-components';

export const StyledCard = styled.div<{
  $width: number;
  $isWithinChangeWindow?: boolean;
}>`
  background-color: #ffffff;
  position: relative;
  min-height: ${(props) =>
    props.$isWithinChangeWindow ? '665' : '601'}px;
  padding: 32px;
  min-width: ${(props) => props.$width * 0.575}px;
  border-radius: 8px;
  overflow: hidden;

  @media (max-width: 976px) {
    min-width: ${(props) => props.$width * 0.55}px;
    margin-bottom: 20px;
    min-height: 0;
  }

  ${(props) =>
    props.theme.breakpoints.down(
      theme.breakpoints.values.md,
    )} {
    min-width: ${(props) => props.$width}px;
    padding: 16px;
  }

  .styled-grid {
    > div {
      padding: 13px 0;
      border-bottom: 1px solid
        ${solidColors.mediumGray.color};
    }
  }
`;

export const StyledCardSearchTripDetails = styled.div<{
  $width: number;
  $isWithinChangeWindow?: boolean;
}>`
  background-color: #ffffff;
  position: relative;
  height: 100%;
  padding: 32px;
  min-width: 100%;
  border-radius: 8px;
  overflow: hidden;

  ${({ theme }) =>
    theme.breakpoints.down(theme.breakpoints.values.md)} {
    padding: 16px;
  }

  ${({ theme }) =>
    theme.breakpoints.up(theme.breakpoints.values.md)} {
    .MuiButton-endIcon {
      margin-right: 0;
    }
  }

  .styled-grid {
    > div {
      padding: 13px 0;
      border-bottom: 1px solid
        ${solidColors.mediumGray.color};
    }
  }

  .MuiButton-root:hover {
    background-color: inherit;
  }
`;

export const StyledSubitle = styled(Typography)`
  margin-top: 7px;
  margin-bottom: 25px;
`;

export const PaxContainer = styled.div<{
  $isHorizontal?: boolean;
}>`
  // leaving here to possibly implement later
  @keyframes slide-in {
    0% {
      transform: translateY(-10%);
      opacity: 0;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  margin-top: 1rem;
  width: 100%;
  height: 100%;
  min-height: 100%;
  overflow: hidden;
`;
