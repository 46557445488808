import React, { useState } from 'react';
import { FrameWrapper } from './Iframe.styles';
import { IIframe } from './Iframe.interfaces';
import Loader from '@components/molecules/Loader/Loader';
import { getFromLocalStorage } from '@components/utils/storage';

const Iframe = ({
  title,
  src,
  styleHeight,
  styleWidth,
  styleBorderStyle,
  styleBorder,
  isLoading,
  styleBorderRadius,
  refresh,
}: IIframe) => {
  const iframeRef = React.createRef<HTMLIFrameElement>();
  const [iframeLoading, setIframeLoading] = useState(true);
  const handleIframeLoading = () => {
    setIframeLoading(false);
  };

  const random = refresh ? Math.random() : null;
  const titleAttributeVal = title ?? 'iframe';

  return (
    <>
      {iframeLoading && <Loader />}

      <FrameWrapper
        ref={iframeRef}
        key={random}
        src={src}
        title={titleAttributeVal}
        aria-label={titleAttributeVal}
        aria-hidden="true"
        tabIndex={-1}
        onLoad={handleIframeLoading}
        style={{
          width: styleWidth,
          height: styleHeight,

          borderStyle: styleBorderStyle,
          border: styleBorder,
          overflow: 'scroll',
          borderRadius: styleBorderRadius,
        }}
      />
    </>
  );
};

export default Iframe;
