import parse from 'html-react-parser';

interface IProps {
  textCopy: string;
  textToReplace: string;
  replaceKeyword: string;
}

export default function replaceText({
  textCopy,
  textToReplace,
  replaceKeyword,
}: IProps) {
  const htmlToReplace = `<span>
                        ${textToReplace}
                    </span>`;

  const replaced = textCopy.replace(
    `<${replaceKeyword}>`,
    htmlToReplace,
  );
  return parse(replaced);
}
