import {
  FormHelperText,
  InputAdornment,
} from '@components/library';
import {
  CustomCssTextField,
  inputUseStyles,
} from '@components/molecules/Input/Input.styles';
import SmsIcon from '@material-ui/icons/Sms';
import { ISmsCodeInput } from './SmsCodeInput.interfaces';

export default function SmsCodeInput({
  error,
  maxCharactersAllowed,
  minCharactersAllowed,
  payloadKey,
  value,
  onChange,
  title,
  errorMessage,
  smsIcon,
  ...rest
}: ISmsCodeInput) {
  const classes = inputUseStyles({ error });

  return (
    <>
      <CustomCssTextField
        {...rest}
        type="text"
        error={error}
        name={payloadKey}
        label={title}
        value={value}
        onChange={onChange}
        InputLabelProps={{
          classes: {
            root: classes.label,
          },
        }}
        FormHelperTextProps={{
          classes: {
            root: classes.helperText,
          },
        }}
        InputProps={{
          endAdornment: smsIcon ? (
            <InputAdornment position="end">
              <SmsIcon />
            </InputAdornment>
          ) : null,
        }}
        inputProps={{
          maxLength: maxCharactersAllowed,
          minLength: minCharactersAllowed,
          inputMode: 'numeric',
          pattern: '[0-9]*',
          autoComplete: 'one-time-code',
        }}
      />
      {error && (
        <FormHelperText error>
          {errorMessage ?? 'Error'}
        </FormHelperText>
      )}
    </>
  );
}
