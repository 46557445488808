import {
  StyledLine,
  StyledSubPageCreditCardForm,
} from './AmexCardInfo.styles';

import { Button } from '@atoms';
import { IAmexCardInfo } from './AmexCardInfo.interfaces';
import InputBase from '@atoms/InputBase';
import { getAccessToken } from '@services/tokenService/token';
import { saveCreditCardRequest } from '@features/PartnerProgram/AmexEligibilitySlice';
import { useAppDispatch } from '@app/hooks';
import { useState } from 'react';

const AmexCardInfo = ({
  cardNumberFormField,
}: IAmexCardInfo) => {
  const dispatch = useAppDispatch();

  const [creditCardNumber, setCreditCardNumber] = useState(
    '',
  );

  const handleSubmit = () => {
    dispatch(
      saveCreditCardRequest({
        token: getAccessToken(),
        resPayload: {
          cardNumber: creditCardNumber,
        },
      }),
    );
  };

  return (
    <StyledSubPageCreditCardForm>
      <StyledLine>
        <InputBase
          title={cardNumberFormField.title || ''}
          payloadKey={cardNumberFormField.type}
          value={creditCardNumber}
          type={cardNumberFormField.type}
          minCharactersAllowed={
            cardNumberFormField.minCharactersRequired
          }
          maxCharactersAllowed={
            cardNumberFormField.maxCharactersAllowed
          }
          onChange={(e) => {
            setCreditCardNumber(e.target.value);
          }}
          isRequired
        />
      </StyledLine>
      <StyledLine>
        <div style={{ width: '310px' }}>
          <Button
            action={{
              actionMethod: () => {
                handleSubmit();
              },
            }}
            title="Submit"
            disabled={creditCardNumber.length !== 15}
            width={'310px'}
          />
        </div>
      </StyledLine>
    </StyledSubPageCreditCardForm>
  );
};

export default AmexCardInfo;
