import React from 'react';
import { gsap } from 'gsap';
import ToggleContainersNavigation from '../../molecules/ToggleContainersNavigation/ToggleContainersNavigation';
import ToggleTabsNavigation from '../../molecules/ToggleTabsNavigation/ToggleTabsNavigation';
import { IToggleNavigation } from './ToggleNavigation.interfaces';

function ToggleNavigation({
  items,
  children,
  selected,
  setSelected,
}: IToggleNavigation) {
  const handleSlice = (index: number) => {
    const xPosition = `-${index}00vw`;
    gsap.fromTo(
      '#sliceContainer',
      {
        autoAlpha: 0.4,
      },
      {
        duration: 1.7,
        autoAlpha: 1,
        x: xPosition,
        ease: 'power2.inOut',
      },
    );
  };

  return (
    <div data-name="ToggleNavigation">
      <ToggleTabsNavigation
        items={items}
        handleSlice={handleSlice}
        selected={selected}
        setSelected={setSelected}
      />
      {children && (
        <ToggleContainersNavigation items={items}>
          {children}
        </ToggleContainersNavigation>
      )}
    </div>
  );
}

export default ToggleNavigation;
