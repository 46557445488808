import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './app/App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import ScrollToTop from './shared/components/utils/ScrollToTop';
import redirect from '@components/utils/redirects';
import handleShouldRedirect from '@components/utils/shouldRedirect';
import { IconDefs } from '@wheelsup/wu-react-components';

const {
  shouldRedirect,
  location,
  hash,
  search,
  memberSite,
} = handleShouldRedirect();

if (shouldRedirect) {
  redirect(location, hash, search, memberSite);
} else {
  // eslint-disable-next-line react/no-deprecated
  ReactDOM.render(
    <React.StrictMode>
      <Router>
        <IconDefs />
        <ScrollToTop />
        <App />
      </Router>
    </React.StrictMode>,
    document.getElementById('root'),
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// if ('serviceWorker' in navigator) {
//      navigator.serviceWorker
//         .getRegistrations()
//         .then(function (registrations) {
//             for (const registration of registrations) {
//                 registration.unregister();
//             }
//         });
// }
