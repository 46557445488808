import styled from 'styled-components';
import { solidColors } from '@constants/styles/colors.constants';
import { Dialog } from '@library';
import { Button } from '@atoms/Button/Button';
import { Box } from '@atoms';

export const ImagelessSpacer = styled.div`
  height: 1rem;
`;

export const HeadlineSpacerStyled = styled.div`
  width: 1rem;
`;

export const PopupCardContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 999992;
`;

export const PopupCardWrapper = styled.div<{
  $width?: string;
  $height?: string;
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  background-color: ${solidColors.upWhite.color};
  max-height: 500px;
  min-height: 282px;
  border-radius: 8px;
  padding-top: 1rem;
  ${({ $width }) => {
    return $width ? `width: ${$width};` : '';
  }};
  ${({ $height }) => {
    return $height ? `height: ${$height};` : '';
  }};
  overflow: hidden;
  ${(props) => props.theme.breakpoints.down('sm')} {
    width: 330px;
    overflow: auto;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 100%;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: auto;
  padding: 0.3rem 1.5rem;
`;

export const BoxStyled = styled.div`
  display: flex;
  justify-content: center;
  padding: 0px 0.5rem;
`;
export const HeadlineStyled = styled.div`
  margin-bottom: 1.5rem;
  height: fit-content;
  max-height: 70px;
  width: 100%;
`;
export const HeadlineCopyStyled = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;
`;
export const BodyStyled = styled.div`
  padding: 0px 0.5rem;
`;
export const StyledP = styled.p`
  max-height: 75px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 16px 0px 32px 0px;
  width: 92% !important;
  background-color: ${solidColors.upWhite.color};
  border-radius: 5px;
  gap: 5px;
  width: auto;
  ${(props) => props.theme.breakpoints.down('sm')} {
    flex-wrap: wrap;
    height: 90px;
    margin-left: 0;
  }
`;

export const ButtonItemContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 270px;
  margin: 0 16px 0 8px;
  height: 48px;
`;

export const PopUpChildrenContainer = styled.div<{
  $width: number;
}>`
  margin-right: auto;
  margin-left: auto;
  width: ${({ $width }) => `${$width}%`};
  overflow-y: scroll;
  max-height: 330px;
  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 1000px;
    background-color: #E1E1E1;
  }
  ${(props) => props.theme.breakpoints.down('sm')} {
    max-height: 244px;
  }
`;

export const DialogStyled = styled(Dialog)`
  .MuiPaper-root {
    border-radius: 8px;
    overflow: hidden;
  }
`;

export const ButtonStyled = styled(Button)`
  width: 100%;
  padding: 0.75rem 0.5rem;
  height: 3rem !important;
  line-height: 2.5rem !important;
`;

export const SingleButtonContainer = styled.div`
  width: 100%;
`;

export const SingleButtonStyled = styled(Button)`
  margin: 1rem 0 0 0 !important;
  width: 100%;
  height: 45px;
  border-radius: 0px;
  padding: 1rem 1rem 1rem 1rem;
  display: flex;
  justify-content: 'center';
`;
