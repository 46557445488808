import { Button, ImageImgix, Typography } from '@atoms';
import { solidColors } from '@constants/styles/colors.constants';
import styled from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: sticky;
  margin-top: 25px;
  z-index: 10;
`;

export const StyledContainerHeader = styled.div`
  margin: 5rem 0 23px 0;
  justify-content: center;
  width: 100%;
  margin-right: 0 60px 0 0;
  z-index: 1;
  background-color: ${solidColors.upWhite.color};
`;

export const StyledHeadlineWithBackArrow = styled.div`
  width: 100%;
`;

export const StyledTrip = styled.div<{
  $lastCard: boolean;
}>`
  padding: 20px 0;
  border-top-style: solid;
  border-width: 1px;
  border-bottom-style: ${({ $lastCard }) =>
    $lastCard ? 'solid' : 'hidden'};
  border-color: ${solidColors.mediumGray.color};
`;

export const StyledHeader = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;

export const CenterIcon = styled(ImageImgix)`
  margin: 0 1rem;
`;

export const FlightLine = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const StyledButton = styled(Button)`
  padding: 6px 0;
`;

export const MobileTripLeg = styled(Typography)<{
  $isDepart?: boolean;
}>`
  margin: ${({ $isDepart }) =>
    $isDepart ? '0 12px 0 0' : '0 12px 0 14px'};
`;

export const MobileTripContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TimeContainer = styled.div`
  display: flex;
  justify-content: center;
`;
