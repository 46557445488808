import styled from 'styled-components';
import { solidColors } from '@constants/styles/colors.constants';

export const MenuMobileWrapper = styled.div`
  display: none;
  cursor: pointer;
  overflow: hidden;
  position: absolute;
  top: 10px;
  right: 24px;
  z-index: 11000;
  text-align: center;
  padding: 12px 0 0;
  margin: auto;
  background: transparent;
  border-radius: 50%;
  transition: all 0.2s ease-in-out;

  width: 40px;
  height: 40px;
  border-radius: 100%;
  box-shadow: inset 0 0 0 1px
    ${solidColors.blue40Percent.color};

  .menu-box {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 14px;
  }

  .menu-inner {
    top: 50%;
    margin-top: -2px;
    &,
    &:before,
    &:after {
      background-color: #000;
      position: absolute;
      width: 20px;
      height: 2px;
      display: block;
      transition: all 0.2s ease-in-out;
    }
    & {
      width: 16px;
      left: 2px;
    }
    &:before {
      content: '';
      top: -6px;
      left: -2px;
    }
    &:after {
      content: '';
      bottom: -6px;
      left: -2px;
    }
  }

  &:hover {
    .menu-inner {
      &,
      &:before,
      &:after {
        left: 0;
        width: 20px;
      }
    }
  }

  ${(props) => props.theme.breakpoints.down('md')} {
    display: block;
  }
`;
