import styled from 'styled-components';
import { Typography } from '@atoms';

export const TermList = styled.ul<{
  $hasBullets?: boolean;
}>`
  width: 444px;
  height: auto;
  margin: 0;
  padding-left: ${({ $hasBullets }) =>
    $hasBullets ? '25px' : '0'};
  list-style-type: ${({ $hasBullets }) =>
    $hasBullets ? 'disc' : 'none'};
`;

export const TermListItem = styled.li<{
  $hasBullets?: boolean;
}>`
  font-size: ${({ $hasBullets }) =>
    $hasBullets ? '10px' : '0'};
`;

export const TermText = styled(Typography)<{
  $hasBullets?: boolean;
}>`
  margin-left: ${({ $hasBullets }) =>
    $hasBullets ? '10px' : '0'};
  // margin-left: 10px;
  margin-bottom: 8px;
  width: auto;
`;

export const TermTitle = styled.div`
    width: 444px;
    height: auto;
    margin-bottom: 16px;
    text-align-left;
`;
