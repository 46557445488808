import { useState, useEffect } from 'react';
import { Typography } from '@wheelsup/wu-react-components';
import { solidColors } from '@constants/styles/colors.constants';
import { typography } from '@constants/styles/typography.constants';
import { Button, ImageImgix } from '@atoms';
import {
  StyledCard,
  CardContent,
  StyledFlightDealType,
  StyledDateContainer,
  StyledDepartureArrivalContainer,
  StyledAircraftWrapper,
  StyledCostWrapper,
  StyledAircraftDottedLineWrapper,
  LineSpaceStyled,
  AircraftSection,
  PriceRowContainer,
  EstimatedTextStyled,
} from './CardFlightDeal.styles';
import { ICardFlightDeal } from './CardFlightDeal.interfaces';

const CardFlightDeal = ({
  flight,
  ...data
}: ICardFlightDeal) => {
  if (!flight || !data) {
    return null;
  }
  const [showTimes, setShowTimes] = useState(false);
  const [showAircraft, setShowAircraft] = useState(false);
  const [showPriceSubhead, setShowPriceSubhead] = useState(
    false,
  );

  const showAircraftRow =
    data.aircraftTypeTitle &&
    data.aircraftTypeLine1 &&
    data.aircraftTypeLine2;

  useEffect(() => {
    // used to toggle different optional containers such as 'dep/arrival time'/'aircraft'/'price' subhead
    flight.departureTime && flight.arrivalTime
      ? setShowTimes(true)
      : setShowTimes(false);
    showAircraftRow
      ? setShowAircraft(true)
      : setShowAircraft(false);
    data.additionalFeesText
      ? setShowPriceSubhead(true)
      : setShowPriceSubhead(false);
  }, [data]);

  return (
    <StyledCard data-name="CardFlightDeal">
      <StyledFlightDealType>
        <ImageImgix
          src={data.flightDealTypeIcon.url}
          filter={data.flightDealTypeIcon.tintColor}
          width={20}
          height={20}
          fit="crop"
          alt="Description"
        />
      </StyledFlightDealType>
      <CardContent>
        <StyledDateContainer>
          <Typography variant="body02">
            {flight.departureDateTime}
          </Typography>
          {flight.departureTimeOfDay && (
            <Typography
              variant="body02"
              className="timeOfDay"
            >
              {flight.departureTimeOfDay}
            </Typography>
          )}
          <Typography variant="body02">
            {flight.flightLegCopy}
          </Typography>
        </StyledDateContainer>
        <StyledDepartureArrivalContainer>
          <Typography
            variant="heading01"
            className="typoBackground"
          >
            {flight.fromAirport.displayCompactValue1}
          </Typography>
          {/* Replace with svg that comes from pegasus */}
          <StyledAircraftDottedLineWrapper>
            <ImageImgix
              src={data.centerIconImage.url}
              filter={data.centerIconImage.tintColor}
              width={25}
              height={25}
              fit="crop"
              alt="center icon"
            />
          </StyledAircraftDottedLineWrapper>
          <Typography
            variant="heading01"
            className="typoBackground"
          >
            {flight.toAirport.displayCompactValue1}
          </Typography>
        </StyledDepartureArrivalContainer>
        <StyledDepartureArrivalContainer>
          <Typography
            variant="caption"
            color="secondary"
          >
            {flight.fromAirport.displayCompactValue2}
          </Typography>
          <Typography
            variant="caption"
            color="secondary"
          >
            {flight.toAirport.displayCompactValue2}
          </Typography>
        </StyledDepartureArrivalContainer>
        {showTimes ? (
          <StyledDepartureArrivalContainer className="departureArrivalTime">
            {flight.departureTime && (
              <Typography variant="body02">
                {flight.departureTime}
              </Typography>
            )}
            {flight.arrivalTime && (
              <Typography
                variant="body02"
                className="inline"
              >
                <Typography
                  variant="caption"
                  color="secondary"
                  className="estimated"
                >
                  Est.
                </Typography>
                {flight.arrivalTime}
              </Typography>
            )}
          </StyledDepartureArrivalContainer>
        ) : null}
        <StyledDepartureArrivalContainer>
          <Typography variant="body02">
            {flight.flightDetailCopyLeft}
            {flight.flightDetailCopyCenter && (
              <span className="fuelStop">
                {flight.flightDetailCopyCenter}
              </span>
            )}
          </Typography>
        </StyledDepartureArrivalContainer>
        {showAircraft ? (
          <AircraftSection>
            <Typography variant="body02">
              {data.aircraftTypeTitle}
            </Typography>
            <StyledAircraftWrapper>
              <div>
                <Typography
                  variant="body02"
                  color="secondary"
                >
                  {data.aircraftTypeLine1}
                </Typography>
                <Typography variant="body01">
                  {data.aircraftTypeLine2}
                </Typography>
              </div>
              <div>
                <Typography
                  variant="body02"
                  color="secondary"
                >
                  {data.paxAvailabilityLine1}
                </Typography>
                <Typography variant="body01">
                  {data.paxAvailabilityLine2}
                </Typography>
              </div>
            </StyledAircraftWrapper>
          </AircraftSection>
        ) : (
          <LineSpaceStyled />
        )}
        <StyledCostWrapper>
          {data.savingsTitle ? (
            <Typography
              variant="caption"
              color="secondary"
            >
              {data.savingsTitle}
            </Typography>
          ) : (
            <LineSpaceStyled />
          )}
        </StyledCostWrapper>
        <StyledCostWrapper>
          <PriceRowContainer>
            <EstimatedTextStyled
              variant={typography.body2}
              color={solidColors.midnight}
            >
              {data.estimatedPriceDisclaimerText}
            </EstimatedTextStyled>
            <div className="price">
              {data.priceIcons?.map(
                (icon, i) =>
                  icon && (
                    <ImageImgix
                      key={i}
                      src={icon.url}
                      filter={icon.tintColor}
                      width={20}
                      height={20}
                      fit="crop"
                      alt="Description"
                    />
                  ),
              )}
              <Typography variant="heading03">
                {data.price}
              </Typography>
            </div>
          </PriceRowContainer>
          {showPriceSubhead ? (
            <Typography variant="body02">
              {data.additionalFeesText}
            </Typography>
          ) : null}
        </StyledCostWrapper>
      </CardContent>
      <Button
        image={data.button.image ?? undefined}
        title={data.button.title}
        action={data.surfaceAction}
        styleType={
          data.button.styleType === 'primary'
            ? 'card_secondary_web'
            : data.button.styleType
        }
        width={'100%'}
        height={'3.25rem'}
      />
    </StyledCard>
  );
};

export default CardFlightDeal;
