import styled from 'styled-components';

export const ContainersWrapper = styled.div<{
  totalSlices: number;
}>`
  width: ${(props) => props.totalSlices}00vw;
  padding-top: 30px;
  position: relative;
`;

export const Slide = styled.div`
  position: relative;
  float: left;
  width: 100vw;
  height: 100%;
`;

export const ClearFloat = styled.div`
  clear: both;
`;
