import styled from 'styled-components';
import { theme } from '@constants/styles/theme.constants';
import { solidColors } from '@constants/styles/colors.constants';
import { Button, Typography } from '@atoms';
import { Icons } from '@components/library';

export const IconText = styled(Typography)<{
  $paddingTop?: boolean;
}>`
  padding-top: ${({ $paddingTop }) =>
    $paddingTop ? '4px' : '0'};
  margin-left: 5px;
`;

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    flex-direction: column;
    height: fit-content;
  }
`;

export const MultiFlightContainer = styled.div`
  width: 450px;
  display: flex;
  display-direction: column;
  align-items: center;
`;

export const FlightStatus = styled.div<{
  $backgroundColor?: string;
}>`
  width: 83px;
  height: 22px;
  background-color: ${({ $backgroundColor }) =>
    $backgroundColor
      ? $backgroundColor
      : solidColors.midnight.color};
  border-radius: 0 0 75px 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SmallDivider = styled.div`
  border-left-style: solid;
  border-left-color: ${solidColors.mediumGray.color};
  border-left-width: 1px;
  margin: 0 10px;
  height: 20px;
  width: 0.5px;
`;

export const TopSectionContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const StyledFlightCard = styled.div<{
  isClickable: boolean;
}>`
  ${(props) =>
    props.isClickable &&
    `
        cursor: pointer;
    `}
  display: flex;
  flex-direction: column;
  height: fit-content;
  width: 100%;
  max-width: 1088px;
  padding: 40px 0 40px 36px;

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    height: fit-content;
    min-height: fit-content;
    width: 100%;
    padding: 20px 10px;
  }
`;

export const AircraftContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-left: 1px solid ${solidColors.mediumGray.color};
  margin: 0 20px 0 12px;
  padding-left: 12px;
  padding-right: 12px;
`;

export const AircraftTextContainer = styled.div`
  padding-top: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: fit-content;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60px;
  margin: 0 20px 0 12px;
  padding-left: 12px;
  padding-right: 12px;
`;

export const StyledButton = styled(Button)`
  &.MuiButton-root {
    border: none;
    padding: 0;
  }
`;

export const StyledIcon = styled(Icons.ChevronRight)<{
  color: string;
}>`
  color: ${({ color }) => color};
`;
