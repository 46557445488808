import {
  legends,
  tripTypeTitle,
} from '../SubPageDatePicker.interface';
import {
  DayPicker,
  DateRange,
  DateFormatter,
  SelectRangeEventHandler,
  MonthChangeEventHandler,
} from 'react-day-picker';
import { format } from 'date-fns';
import 'react-day-picker/dist/style.css';
import {
  DatePickerContainer,
  DatePopupContainer,
} from '../SubPageDatePicker.style';
import CustomSubpage from '@components/molecules/CustomSubpage/CustomSubpage';
import {
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useFeatureFlag } from '@services/featureFlagService/featureFlagService.services';
import { FeatureFlags } from '@services/featureFlagService/constants';
import { ReactDayPickerStyleOverrides } from './CalendarPricing.style';

import { FlightSearchContext } from '@components/molecules/SubPageFlightSearchV2/FlightSearchContext/FlightSearchContext';

import {
  daysBetween,
  endOfNextMonth,
  startOfCurrentMonth,
} from '../dateHelper';
import { useOnClickOutside, useViewport } from '@hooks';
import { DateTime } from 'luxon';
import { useFlightSearch } from '@components/molecules/SubPageFlightSearchV2/FlightSearchContext/FlightSearchHooks';
import { TSharedProps } from '@components/molecules/SubPageFlightSearchV2/LegContainers/CalendarSection';
import { DateWithPrice } from './DateWithPrice/DateWithPrice';
import {
  extractAndFormatPrice,
  formatPrice,
  requiresQuote,
} from './DateWithPrice/helpers';
import { theme } from '@constants/styles/theme.constants';

const formatWeekdayName: DateFormatter = (day) => {
  return format(day, 'iii');
};

export const formatDisplayDate = (day: Date) => {
  return format(day, 'EEEE, MMMM d');
};

const CalendarPricingRoundTrip = ({
  sharedProps,
  legNumber,
}: {
  sharedProps: TSharedProps;
  legNumber: number;
}) => {
  const {
    legs,
    updateDisplayDate,
    updateLeg,
    updatePricingRequest,
    pricingRequest,
    isLegendOpen,
    contextDispatch,
  } = useFlightSearch(useContext(FlightSearchContext));
  const { width } = useViewport();
  const useTod = useFeatureFlag(FeatureFlags.USE_TOD);

  const departLeg = legs[0];
  const returnLeg = legs[1];

  const popupRef = useRef(null);

  const [
    footerPriceDisplay,
    setFooterPriceDisplay,
  ] = useState('');

  const [range, setRange] = useState<DateRange | undefined>(
    {
      from: departLeg.date as Date,
      to: returnLeg.date as Date,
    },
  );

  const handleContinue = () => {
    updateLeg(
      { ...returnLeg, dateOpen: false, date: range?.to },
      1,
    );
    updateLeg(
      {
        ...departLeg,
        dateOpen: false,
        date: range?.from,
        ...(useTod
          ? undefined
          : {
              timeOpen: true,
            }),
      },
      0,
    );
  };

  const setFormInputDisplayValue = (
    from?: Date,
    to?: Date,
  ) => {
    if (from) {
      if (to) {
        updateDisplayDate(
          `${formatDisplayDate(from)} - ${formatDisplayDate(
            to,
          )}`,
        );
      } else {
        updateDisplayDate(`${formatDisplayDate(from)} - `);
      }
    } else {
      updateDisplayDate('');
    }
  };

  const handleMonthChange: MonthChangeEventHandler = (
    newStartDate: Date,
  ) => {
    const newEndDate = endOfNextMonth(newStartDate);
    if (pricingRequest) {
      updatePricingRequest({
        ...pricingRequest,
        startDate: newStartDate,
        endDate: newEndDate ? newEndDate : newStartDate,
      });
    }
  };

  const {
    pricingData,
    isPricingDataLoading,
    dateDecorationMap,
    decorationMapLoading,
  } = sharedProps;

  const handleFooterPriceDisplayChange = (
    range: DateRange | undefined,
  ) => {
    // Return early if pricing data is still loading
    if (isPricingDataLoading) {
      setFooterPriceDisplay('');
      return;
    }

    // Decide whether to display a quote or price
    if (
      range?.from &&
      requiresQuote(range.from, dateDecorationMap)
    ) {
      setFooterPriceDisplay('Request Quote');
    } else if (range && range.from) {
      const priceDisplay = range.to
        ? extractAndFormatPrice(range.to, pricingData)
        : extractAndFormatPrice(range.from, pricingData);
      setFooterPriceDisplay(priceDisplay);
    }
  };

  const handleRangeChange: SelectRangeEventHandler = (
    range: DateRange | undefined,
  ) => {
    handleFooterPriceDisplayChange(range);
    setRange(range);
    setFormInputDisplayValue(range?.from, range?.to);
    if (pricingRequest && range) {
      if (range.from && !range.to) {
        // set the pricing request values in context so the GraphQL pricing call is triggered in DateWithPrice
        updatePricingRequest({
          ...pricingRequest,
          roundTripDays: undefined,
          anchoredDate: DateTime.fromJSDate(
            range.from,
          ).toFormat('yyyy-MM-dd'),
        });
      }
      if (range?.from && range?.to) {
        // set the pricing request values in context so the GraphQL pricing call is triggered in DateWithPrice
        updatePricingRequest({
          ...pricingRequest,
          roundTripDays: daysBetween(range.to, range.from),
          anchoredDate: undefined,
        });
      }
    }
  };

  // when we finish loading, want to re-evaluate the footer price display
  useEffect(() => {
    handleFooterPriceDisplayChange(range);
  }, [isPricingDataLoading, decorationMapLoading]);

  useOnClickOutside(popupRef, () => {
    if (!isLegendOpen) {
      // Reset start/end month to current month or month of first leg
      const newStartMonth =
        getCorrectStartingMonth() || new Date();
      updatePricingRequest({
        ...pricingRequest,
        startDate: startOfCurrentMonth(newStartMonth),
        endDate: endOfNextMonth(newStartMonth),
      });

      // update both legs with the new dates
      contextDispatch({
        payload: {
          legs: [
            {
              ...departLeg,
              dateOpen: false,
              date: range?.from || null,
            },
            {
              ...returnLeg,
              dateOpen: false,
              date: range?.to || null,
            },
          ],
        },
        type: 'replaceLegsRoundTrip',
      });
    }
  });

  // one off
  const isDesktop = width > theme.breakpoints.values.md;

  const getCorrectStartingMonth = () => {
    // Get the date of the departing flight
    if (legs[0].date) return legs[0].date;
    return new Date();
  };

  return (
    <DatePickerContainer>
      <DatePopupContainer ref={popupRef}>
        <CustomSubpage
          width={'unset'}
          action={handleContinue}
          isButtonDisabled={!range?.from || !range?.to}
          legends={legends}
          tripType={tripTypeTitle['round-trip']}
          isCalendar={true}
          selectedPrice={footerPriceDisplay}
          showPrice={!!footerPriceDisplay}
        >
          <ReactDayPickerStyleOverrides>
            <DayPicker
              id="test"
              mode={'range'}
              disabled={{
                before: DateTime.now()
                  .startOf('day')
                  .toJSDate(),
              }}
              fromMonth={new Date()}
              numberOfMonths={isDesktop ? 2 : 1}
              defaultMonth={getCorrectStartingMonth()}
              selected={range}
              onMonthChange={handleMonthChange}
              onSelect={handleRangeChange}
              formatters={{ formatWeekdayName }}
              components={{
                // eslint-disable-next-line react/display-name
                DayContent: (props) => (
                  <DateWithPrice
                    dayContentProps={props}
                    sharedProps={sharedProps}
                    legNumber={legNumber}
                  />
                ),
              }}
            />
          </ReactDayPickerStyleOverrides>
        </CustomSubpage>
      </DatePopupContainer>
    </DatePickerContainer>
  );
};

export { CalendarPricingRoundTrip };
