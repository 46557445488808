import { IButton } from '@atoms/Button/Button.interfaces';
import {
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

export interface IErrorPayload {
  buttons: IButton[];
  copy: string;
  headline: string;
  requiredUserInteraction: boolean;
}
export interface IErrorState {
  errorOpen?: boolean;
  errorPayload: IErrorPayload;
}

const initialState: IErrorState = {
  errorOpen: false,
  errorPayload: {
    buttons: [
      {
        action: {
          type: 'molecule_close',
        },
        title: 'Close',
      },
      {
        action: {},
      },
    ],
    copy: 'Please try again later.',
    headline: 'Something went wrong',
    requiredUserInteraction: false,
  },
};

const errorSlice = createSlice({
  name: 'Error',
  initialState,
  reducers: {
    setErrorPayload: (
      state,
      action: PayloadAction<IErrorPayload>,
    ) => {
      state.errorPayload = action.payload
        ? action.payload
        : initialState.errorPayload;
    },
    setErrorOpen: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.errorOpen = action.payload;
    },
  },
});

export const {
  setErrorPayload,
  setErrorOpen,
} = errorSlice.actions;

export default errorSlice.reducer;
