import React from 'react';
import { ITabContent } from './TabContent.interfaces';
import {
  StyledTabPanel,
  StyledImageImgix,
  StyledChildWrapper,
} from './TabContent.styles';

export default function TabContent({
  id,
  children,
  current,
  items,
}: ITabContent) {
  const backgroundImage = items[current].image?.url;
  const tabTitle = items[current].title;
  const childrenIsArray =
    children && Array.isArray(children);
  return (
    <>
      {childrenIsArray
        ? React.Children.map(children[current], (child) => (
            <StyledTabPanel
              id={id}
              key={id}
              position={current}
              value={current}
            >
              {backgroundImage ? (
                <StyledImageImgix
                  src={backgroundImage}
                  width={1600}
                  height={800}
                  crop="top"
                  fit="crop"
                  alt={`${tabTitle} - WheelsUp`}
                  isBackground
                >
                  <StyledChildWrapper>
                    <>{child}</>
                  </StyledChildWrapper>
                </StyledImageImgix>
              ) : (
                <>{child}</>
              )}
            </StyledTabPanel>
          ))
        : React.Children.map(children, (child, index) => (
            <StyledTabPanel
              id={id}
              key={id}
              position={index}
              value={current}
            >
              {backgroundImage ? (
                <StyledImageImgix
                  src={backgroundImage}
                  width={1600}
                  height={800}
                  crop="top"
                  fit="crop"
                  alt={`${tabTitle} - WheelsUp`}
                  isBackground
                >
                  <StyledChildWrapper>
                    <>{child}</>
                  </StyledChildWrapper>
                </StyledImageImgix>
              ) : (
                <>{child}</>
              )}
            </StyledTabPanel>
          ))}
    </>
  );
}
