import { ColorStyleTranslation } from '@components/utils/newStyleTranslation';
import { IImgix } from '@services/imgix/imgix.interfaces';
import { resize } from '@services/imgix/imgix.service';
import {
  StyledBackgroundImage,
  StyledImg,
} from './ImageImgix.styles';

export interface IImgixComp extends IImgix {
  id?: string;
  alt: string;
  filter?: string;
  className?: string;
  isBackground?: boolean;
  opacity?: number;
  children?: React.ReactNode;
  position?: string;
  bgPosition?: string;
  borderRadius?: string;
}

const ImageImgix = ({
  id,
  src,
  isSrcSet,
  width,
  height,
  aspectRatio,
  alt,
  className,
  isBackground = false,
  children,
  crop,
  fit,
  fill,
  minW,
  minH,
  maxW,
  maxH,
  ellipse = false,
  radius,
  borderRadius,
  widths,
  filter,
  opacity = 1.0,
  position = 'absolute',
  bgPosition = 'center',
  fpX,
  fpY,
  fpZ,
  trim,
  rect,
  sharp
}: IImgixComp) => {
  if (src === null) return <></>;

  const resizedImage = resize({
    src,
    isSrcSet,
    width,
    height,
    aspectRatio,
    crop,
    fit,
    fill,
    minW,
    minH,
    maxW,
    maxH,
    ellipse,
    radius,
    widths,
    fpX,
    fpY,
    fpZ,
    trim,
    rect,
    sharp
  });

  let isValidFile = false;

  if (src) {
    const srcLen = src.length;
    const threeCharFileType = src.slice(srcLen - 3);
    const validFiles = {
      svg: true,
      pdf: true,
    };
    isValidFile = threeCharFileType in validFiles;
  }

  return (
    <>
      {isBackground ? (
        <StyledBackgroundImage
          id={id}
          url={resizedImage.srcResult}
          width={width}
          height={height}
          position={position}
          bgPosition={bgPosition}
          filter={isValidFile ? filter : ''}
          {...(className && { className })}
        >
          {children}
        </StyledBackgroundImage>
      ) : (
        <StyledImg
          id={id}
          src={resizedImage.srcResult}
          alt={alt}
          {...(resizedImage.srcSetResult && {
            srcSet: resizedImage.srcSetResult,
          })}
          {...(className && { className })}
          width={width}
          height={height}
          filter={
            isValidFile
              ? ColorStyleTranslation(filter as string)
              : ''
          }
          $opacity={opacity}
          borderRadius={borderRadius}
        />
      )}
    </>
  );
};

export default ImageImgix;
