import { AppDispatch, RootState } from '@app/store';
import {
  getFromLocalStorage,
  getFromSessionStorage,
  saveDataInSessionStorage,
} from '@components/utils/storage';
import { refresh } from '@services/authService/authService';
import { setAppIsLoading } from './handle401Slice';
import { retryHandler } from './retryHandler';
import { trackAnalytics } from '@services/mixpanelService/mixpanel.service';
import { IKeyValue } from '@components/types';
import { AxiosError } from 'axios';

export default async function handle401(
  storeGetState: RootState,
  storeDispatch: AppDispatch,
  error: AxiosError,
) {
  /*
        Helper function responsible for handling
        '401 Unauthorized' code from pegasus
    */
  storeDispatch(setAppIsLoading(true));
  const hasAttemptedRefresh = getFromLocalStorage(
    'attemptedRefresh',
  );
  const retryParams = getFromSessionStorage('last_call');
  const requestUrl = error.request.responseURL;
  const refreshTokenExpiry = getFromLocalStorage(
    'refresh_token_expires',
  );
  const refreshTokenAsDate = new Date(refreshTokenExpiry);
  const validToken = refreshTokenAsDate >= new Date();
  const actionParams: IKeyValue[] = [
    {
      key: 'Refresh Token Endpoint',
      value: requestUrl,
    },
    {
      key: 'Refresh Token Exp Date',
      value: refreshTokenExpiry,
    },
  ];
  if (
    (hasAttemptedRefresh === 'false' ||
      !hasAttemptedRefresh) &&
    validToken
  ) {
    await refresh(true);
    actionParams.push({
      key: 'Refresh Token Valid',
      value: true,
    });
    trackAnalytics({
      trackingTitle: 'Unauthorized Logout',
      properties: actionParams,
    });
    retryHandler({
      ...retryParams,
      resPayload: retryParams?.body,
      storeGetState: storeGetState,
      storeDispatch: storeDispatch,
    });
  } else if (
    !window.location.href.includes('signin') &&
    hasAttemptedRefresh === 'true'
  ) {
    storeDispatch(setAppIsLoading(false));
    actionParams.push({
      key: 'Refresh Token Valid',
      value: false,
    });
    trackAnalytics({
      trackingTitle: 'Unauthorized Logout',
      properties: actionParams,
    });
    const pathToAttach =
      window.location.pathname + window.location.search;
    const encodedUrl: string = encodeURIComponent(
      pathToAttach,
    );
    window.location.href = `${window.location.origin}/signout?redirect_route=${encodedUrl}`;
  } else {
    // compare the current date and this refresh date
    // if current date is after refresh date, replicate previous else if statement.
    if (!validToken || !refreshTokenExpiry) {
      storeDispatch(setAppIsLoading(false));
      actionParams.push({
        key: 'Refresh Token Valid',
        value: false,
      });
      trackAnalytics({
        trackingTitle: 'Unauthorized Logout',
        properties: actionParams,
      });
      const pathToAttach =
        window.location.pathname + window.location.search;
      const encodedUrl: string = encodeURIComponent(
        pathToAttach,
      );
      window.location.href = `${window.location.origin}/signout?redirect_route=${encodedUrl}`;
    }
  }
}
