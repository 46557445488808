import styled from 'styled-components';
import { Container, Grid } from '@library';

export const ContainerContent = styled(Container)`
  margin-top: 1rem;
`;

export const GridWithMarginTop = styled(Grid)`
  margin-top: 0.5rem;

  button {
    .MuiButton-endIcon {
      margin-left: 0.25rem;
    }
  }
`;
