import {
  AppConfigurationClient,
  parseFeatureFlag,
  ConfigurationSetting,
  FeatureFlagValue,
} from '@azure/app-configuration';

import { FeatureFlags } from 'flagged';
import { useQuery } from '@tanstack/react-query';
import { getUserId } from '@services/tokenService/token';
import { fetchProcessEnvVariable } from '@services/envService/envService';
import { useFeature } from 'flagged';
import { datadogRum } from '@datadog/browser-rum';

// this file is used to fetch feature flags from Azure App Configuration
export const getClient = () => {
  const azureInstance = fetchProcessEnvVariable('FEATURE_FLAG_CONFIG');
  return new AppConfigurationClient(azureInstance ? azureInstance : '');
};

export const refreshAllFlags = async (
  client: AppConfigurationClient
): Promise<FeatureFlags> => {
  const allKeysIterator = client.listConfigurationSettings({
    labelFilter: 'Members Site',
  });

  const features: FeatureFlags = {};

  for await (const setting of allKeysIterator) {
    const flagValue = parseFeatureFlag(setting);

    const targetedUsers = getTargetedUsers(flagValue);

    if (targetedUsers) {
      /**
       * This means a Microsoft.Targeting client filter is present on the feature flag, and we need to check if the current user is targeted.
       * */
      const currentUser = getCurrentUser();

      if (currentUser && targetedUsers.includes(currentUser)) {
        features[flagValue.value.id ?? flagValue.key] =
          flagValue.value?.enabled ?? false;
      } else {
        // if a client filter is present and the current user is not targeted, the feature is disabled no matter what the flag value is.
        features[flagValue.value.id ?? flagValue.key] = false;
      }
    } else
      features[flagValue.value.id ?? flagValue.key] =
        flagValue.value?.enabled ?? false;
  }

  return features;
};

const getCurrentUser = () => {
  return getUserId();
};

/**
 * This function checks to see if a Microsoft.Targeting client filter is present on the provided feature flag and returns the collection of targeted users if it is.
 */
const getTargetedUsers = (flag: ConfigurationSetting<FeatureFlagValue>) => {
  const targetingCondition = flag.value.conditions.clientFilters.find(
    (filter) => filter.name === 'Microsoft.Targeting'
  );

  if (targetingCondition && isTargetingClientFilter(targetingCondition)) {
    const audience = targetingCondition.parameters?.Audience as any;
    return audience['Users'];
  } else {
    return null;
  }
};

/**
 * typeguard - for targeting client filter
 */
function isTargetingClientFilter(clientFilter: any): clientFilter is {
  parameters: {
    Audience: {
      Groups: Array<{ Name: string; RolloutPercentage: number }>;
      Users: Array<string>;
      DefaultRolloutPercentage: number;
    };
  };
} {
  return (
    clientFilter.name === 'Microsoft.Targeting' &&
    clientFilter.parameters &&
    clientFilter.parameters['Audience'] &&
    Array.isArray(clientFilter.parameters['Audience']['Groups']) &&
    Array.isArray(clientFilter.parameters['Audience']['Users']) &&
    typeof clientFilter.parameters['Audience']['DefaultRolloutPercentage'] ===
      'number'
  );
}

const fetchFlags = async () => {
  try {
    const client = getClient();
    return await refreshAllFlags(client);
  } catch (e) {
    console.log(`Could not initialize Azure client: ${e}`);
    return null;
  }
};

export const useFeatureFlags = () =>
  useQuery({
    queryKey: ['featureFlags'],
    queryFn: fetchFlags, 
    staleTime: Infinity,
    cacheTime: 0,
    refetchInterval: 0,
    refetchOnMount: false,
  });

export const useFeatureFlag = (flag: string): boolean => {
  const flagValue = useFeature(flag) as boolean;
  datadogRum.addFeatureFlagEvaluation(flag, flagValue);
  return flagValue;
};

