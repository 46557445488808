import {
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit';
import {
  endpoints,
  getAuthHeaders,
  pegasusClient,
} from '@services/apiService';
import {
  IComponent,
  IPage,
} from '@services/dynamicRenderingService/dynamicRendering.interfaces';

interface IState {
  accountSubpage?: string;
  title?: string;
  openLogOut?: boolean;
  openRenewAccount?: boolean;
  loadingRenewAccountContent?: boolean;
  renewPopupContent?: IComponent;
}

export const initialState: IState = {};

interface IProps {
  urlParams?: string;
  queryParams?: string;
  token?: string;
  type?: string;
  resPayload?: unknown;
}

export const getRenewalPopUp = createAsyncThunk(
  'accountSubpage/fetchRenewPage',
  async (props: IProps) => {
    const { queryParams = '', urlParams = '' } = props;
    try {
      return (
        await pegasusClient.get(
          `${endpoints.renew}${urlParams}?version=1&platform=web${queryParams}`,
          {
            headers: getAuthHeaders(),
          },
        )
      ).data;
    } catch (err) {
      return err;
    }
  },
);

export const accountSubpageSlice = createSlice({
  name: 'accountSubpage',
  initialState: initialState,
  reducers: {
    setAccountSubpage: (state, action) => {
      state.accountSubpage = action.payload.accountSubpage;
      state.title = action.payload.title;
      state.openLogOut = false;
    },
    setPopUp: (state, action) => {
      state.openLogOut = action.payload;
    },
    setRenewAlert: (state, action) => {
      state.openRenewAccount = action.payload;
      state.renewPopupContent = undefined;
    },
    cleanState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getRenewalPopUp.pending, (state) => {
      state.loadingRenewAccountContent = true;
    });
    builder.addCase(
      getRenewalPopUp.fulfilled,
      (state, action) => {
        const { payload } = action;
        // Extracts pop up from page structured payload
        state.renewPopupContent = (payload.data as IPage)
          .elements[0].data.element as IComponent;
        state.loadingRenewAccountContent = false;
        state.openRenewAccount = true;
      },
    );
  },
});

export const {
  setAccountSubpage,
  setPopUp,
  setRenewAlert,
  cleanState,
} = accountSubpageSlice.actions;

export default accountSubpageSlice;
