//Omar: This wrapper component loads the webfonts, injects global css rules and overrides MUI styles with our custom theme

import React, { useEffect } from 'react';
import { StylesProvider } from '@material-ui/styles'; // MUI components
import {
  CssBaseline, // similar to css resets/'normalize.css'
  ThemeProvider, // allows to inject our custom theme to MUI components
  createTheme,
} from '@material-ui/core';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';

import { theme as WUTheme } from '../constants/styles/theme.constants'; // Wheels Up Themes
import GlobalStyles from './GlobalStyles'; // Global CSS Rules
import WebFont from 'webfontloader'; //google's tool to load webfonts

interface AppChildren {
  children: React.ReactNode;
}

const themeOverride = createTheme(WUTheme);

function ThemeWrapper(props: AppChildren) {
  return (
    <React.Fragment>
      <StylesProvider injectFirst>
        <StyledThemeProvider theme={themeOverride}>
          {/* rearranges css specificity */}
          <ThemeProvider theme={themeOverride}>
            <CssBaseline />
            <GlobalStyles />
            {props.children}
          </ThemeProvider>
        </StyledThemeProvider>
      </StylesProvider>
    </React.Fragment>
  );
}

export default ThemeWrapper;
