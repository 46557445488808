export type ActionMap<
  M extends { [index: string]: any }
> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

export enum MainTypes {
  Create = 'CREATE',
  Delete = 'DELETE',
  Update = 'UPDATE',
}
