import { useState } from 'react';

import ImageCarouselButtonContainer from '@molecules/ImageCarouselButtonContainer/ImageCarouselButtonContainer';
import {
  ImageCarouselContainer,
  ImageCarouselContent,
  ImageImgix,
} from '@atoms';
import { ImageCarouselProps } from '@atoms/ImageCarousel/ImageCarousel.interfaces';
import { StyledFlag } from './ImageCarousel.styles';
import { BREAKPOINTS } from '@components/utils/breakpoints';
import { useViewport } from '@hooks';
import {
  PaginationContainer,
  PaginationMarker,
} from '@components/molecules/ImageCarouselButtonContainer/ImageCarouselButtonContainer.styles';
import { solidColors } from '@constants/styles/colors.constants';

const ImageCarousel = ({
  imageURLs,
  heightRem,
  widthRem,
  arrowsInside,
  arrowsSize,
  borderRadius,
  fit = 'crop',
  tag,
}: ImageCarouselProps) => {
  const [translate, setTranslate] = useState(0);
  const [carouselHovered, setCarouselHovered] = useState(
    false,
  );
  const [activeIndex, setActiveIndex] = useState(0);

  const lastImageIndex = imageURLs.length - 1;
  const translateAmountPixels = widthRem * 16;

  const goNext = () => {
    if (activeIndex === lastImageIndex) {
      setTranslate(0);
      setActiveIndex(0);
      return;
    }

    const nextActiveIndex = activeIndex + 1;
    setActiveIndex(nextActiveIndex);
    setTranslate(nextActiveIndex * translateAmountPixels);
  };
  const goBack = () => {
    if (activeIndex === 0) {
      setTranslate(lastImageIndex * translateAmountPixels);
      setActiveIndex(lastImageIndex);
      return;
    }

    const prevActiveIndex = activeIndex - 1;
    setActiveIndex(prevActiveIndex);
    setTranslate(prevActiveIndex * translateAmountPixels);
  };
  const { width } = useViewport();
  const isDesktop = width > BREAKPOINTS.DESKTOP;

  // if its desktop, check if hovered
  // if mobile, show either way
  const shouldShowArrows =
    (carouselHovered && isDesktop) || !isDesktop;

  const imageCount = imageURLs.length;

  const tagColorMap = {
    // membership required
    c1: 'secondary',
    // best match
    c2: 'primary',
    // exclusive discount
    c12: 'success',
    c15: 'interactivePrimary',
    // fastest
    Eggplant: 'special1',
    // least expensive
    'Sea Green': 'special2',
  };

  const textColor =
    tag?.flightTagColor === 'c15'
      ? solidColors.upBlue.color
      : undefined;

  return (
    <ImageCarouselContainer
      onMouseEnter={() => setCarouselHovered(true)}
      onMouseLeave={() => setCarouselHovered(false)}
      heightRem={heightRem}
      widthRem={widthRem}
    >
      {imageURLs.length > 1 && shouldShowArrows && (
        <ImageCarouselButtonContainer
          disabledBack={false}
          disabledForward={false}
          backFunction={goBack}
          forwardFunction={goNext}
          marginTop={heightRem / 2 - 1}
          widthRem={widthRem - 8}
          imageCarousel
          arrowsInside={arrowsInside}
          arrowsSize={arrowsSize}
        />
      )}
      {tag && (
        <StyledFlag
          color={tagColorMap[tag.flightTagColor]}
          textColor={textColor}
        >
          {tag?.flightTagText}
        </StyledFlag>
      )}

      <PaginationContainer
        width={imageURLs.length * 12 + 40}
        top={heightRem - 1.5}
      >
        {Array.from({ length: imageCount }).map((_, i) => (
          <PaginationMarker
            key={`${i}`}
            active={i === activeIndex}
          />
        ))}
      </PaginationContainer>
      <ImageCarouselContent
        translate={translate}
        transition={0.45}
        width={translateAmountPixels * imageURLs.length}
      >
        {imageURLs.map((imageURL) => (
          <ImageImgix
            key={imageURL}
            alt="image"
            minW={widthRem * 16}
            borderRadius={borderRadius || ''}
            src={imageURL}
            height={heightRem * 16}
            width={widthRem * 16}
            sharp={5}
            fit={fit}
          />
        ))}
      </ImageCarouselContent>
    </ImageCarouselContainer>
  );
};

export default ImageCarousel;
