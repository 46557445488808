import { useState } from 'react';
import { Grid } from '@library';
import { ImageImgix, Typography } from '@atoms';
import { typography } from '@constants/styles/typography.constants';
import { solidColors } from '@constants/styles/colors.constants';
import styled from 'styled-components';
import { DetailListItem, IImage } from '@components/types';
import { useViewport } from '@hooks';
import { useEffect } from 'react';
import { IAnalytics } from '@services/dynamicRenderingService/dynamicRendering.interfaces';

export interface IGroupSubCopyImageSpecs {
  title: string;
  subCopy: string;
  image: IImage;
  specs: DetailListItem[];
  analytics?: IAnalytics;
}

const SubCopyContainer = styled.div`
  padding: 0;
  margin: 1rem auto;
  width: 570px;
  max-width: 100%;
`;

const SpecsContainer = styled(Grid)`
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  max-width: 300px;
  padding: 20px 0;
`;

const CopySpacer = styled.div`
  width: 0.5rem;
`;

const GroupContainer = styled.div`
  margin: 0;
  padding: 0;
  padding-bottom: 11rem;
  height: 100%;
  transition-timing-function: ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    max-width: 100%;
  }
`;

export default function GroupSubCopyImageSpecs({
  subCopy,
  image,
  specs,
  analytics,
}: IGroupSubCopyImageSpecs) {
  const [imageWidth, setImageWidth] = useState<
    number | undefined
  >(900);
  const [imageHeight, setImageHeight] = useState<
    number | undefined
  >(350);
  const { width } = useViewport();
  useEffect(() => {
    if (width > 600) {
      setImageWidth(900);
      setImageHeight(350);
    } else {
      setImageWidth(550);
      setImageHeight(undefined);
    }
  }, [width]);

  return (
    <GroupContainer>
      <SubCopyContainer>
        <Typography
          variant={
            width > 600
              ? typography.heading03Large
              : typography.heading03Small
          }
          color={solidColors.midnight}
          align="center"
          as='p'
        >
          {subCopy}
        </Typography>
      </SubCopyContainer>

      <ImageImgix
        src={image.url}
        filter={image.tintColor}
        isSrcSet
        width={imageWidth}
        height={imageHeight}
        fit="crop"
        crop="edges"
        alt="Meet the fleet"
      />
      {/* Replacing this with code above until imgx is implemented for bg images*/}
      {/*<ImageContainer imgSrc={image.url} />*/}

      <Grid
        container
        justifyContent="center"
        spacing={0}
      >
        {specs.map(
          (
            { title, detail }: DetailListItem,
            index: number,
          ) => (
            <SpecsContainer
              item
              md={3}
              xs={12}
              key={index}
            >
              <Typography
                as="p"
                variant={typography.heading05Large}
                color={solidColors.midnight}
                align="center"
                truncate={1}
              >
                {title}
              </Typography>
              <CopySpacer />
              <Typography
                as="p"
                variant={typography.heading05Large}
                color={solidColors.midnight}
                align="center"
                truncate={1}
              >
                {detail}
              </Typography>
            </SpecsContainer>
          ),
        )}
      </Grid>
    </GroupContainer>
  );
}
