import styled from 'styled-components';
import { solidColors } from '@constants/styles/colors.constants';
import { Button, ImageImgix } from '@atoms';
import { IconButton } from '@components/library';

export const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: 684px;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05);
  position: relative;
  height: fit-content;
  padding: 18px 16px 0px;
`;

export const BoxStyled = styled.div`
  display: flex;
  margin-bottom: 5px;
`;

export const StyledImage = styled(ImageImgix)`
  margin-right: 0.75rem;
`;

export const HeadlineStyled = styled.div`
  padding-right: 2rem;
  flex-wrap: nowrap;
  white-space: pre-line;
`;

export const StyledIconButton = styled(IconButton)`
  padding: 0px;
  margin-left: auto;
`;

export const SubCopyStyled = styled.div`
  margin: 5px 0 20px 0;
`;

export const FormFieldStyled = styled.div`
  padding: 5px 0;
  margin: 10px 0 40px 0;
  overflow-x: hidden;
  border-top: 1px solid ${solidColors.darkGray.color};
  border-bottom: 1px solid ${solidColors.darkGray.color};
`;

export const ButtonStyled = styled(Button)<{
  $multi?: boolean;
}>`
  width: 100%;
  padding: 1rem 1rem 1rem 1rem;
`;
