import styled from 'styled-components';
import { Typography } from '@atoms';
import { solidColors } from '@constants/styles/colors.constants';

export const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  height: fit-content;
  justify-content: center;
`;

export const ItemsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  max-width: 350px;
  flex-wrap: wrap;
  border-left: 1px solid ${solidColors.mediumGray.color};
  margin-left: 32px;
`;

export const IconText = styled(Typography)<{
  $paddingTop?: boolean;
}>`
  padding-top: ${({ $paddingTop }) =>
    $paddingTop ? '4px' : '0'};
  margin-left: 5px !important;
`;

export const FuelStopText = styled(Typography)<{
  $paddingTop?: boolean;
}>`
  padding-top: ${({ $paddingTop }) =>
    $paddingTop ? '4px' : '0'};
`;
