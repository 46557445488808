import { RootState } from '@app/store';
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ButtonContainer from './ButtonContainer';
import { ICarousel } from './Carousel.interfaces';
import {
  CarouselWrapper,
  SlideContainer,
  SlideGroupWrapper,
  SlideWrapper,
  StyledCarousel,
} from './Carousel.styles';
import { slideCard } from '@features/Dashboard/CarouselSlice';

export default function Carousel({
  numberSlidesTransform = 1,
  children,
}: ICarousel) {
  const dispatch = useDispatch();
  const [currentSlides, setCurrentSlides] = useState([0]);
  const [
    currentTransformAmount,
    setCurrentTransformAmount,
  ] = useState(0 - numberSlidesTransform * 0);
  const [
    transformWidthAmount,
    setTransformWidthAmount,
  ] = useState(0);
  const [showArrow, setShowArrow] = useState(false);
  const cardElement = useRef<HTMLDivElement>(null);
  const wrapperElementRef = useRef<HTMLDivElement>(null);
  const { slide } = useSelector(
    (state: RootState) => state.progressiveProfile,
  );
  const [childrenArray, setChildrenArray] = useState<
    React.ReactNode | React.ReactNode[] | undefined
  >(children);

  const goBack = () => {
    setCurrentSlides(
      currentSlides.map(
        (slideIndex) => slideIndex - numberSlidesTransform,
      ),
    );
    setCurrentTransformAmount(
      (prevValue) =>
        prevValue -
        numberSlidesTransform * transformWidthAmount,
    );
  };

  const goForward = () => {
    setCurrentSlides(
      currentSlides.map(
        (slideIndex) => slideIndex + numberSlidesTransform,
      ),
    );
    setCurrentTransformAmount(
      (prevValue) =>
        prevValue +
        numberSlidesTransform * transformWidthAmount,
    );
  };

  const updateChildren = () => {
    const currSlideIndex = currentSlides[0];
    if (Array.isArray(childrenArray)) {
      const firstHalf = childrenArray.slice(
        0,
        currSlideIndex,
      );
      const secondHalf = childrenArray.slice(
        currSlideIndex + 1,
      );
      setChildrenArray([...firstHalf, ...secondHalf]);
    }
  };

  useEffect(() => {
    if (slide === false) return;
    else if (
      Array.isArray(childrenArray) &&
      childrenArray.length - 1 > currentSlides[0]
    ) {
      // goForward();
      updateChildren();
    } else if (
      Array.isArray(childrenArray) &&
      currentSlides[0] === childrenArray.length - 1
    ) {
      updateChildren();
      goBack();
    }
    dispatch(slideCard(false));
  }, [slide]);

  useEffect(() => {
    setShowArrow(
      Array.isArray(children) &&
        children.length > currentSlides.length,
    );
  }, [currentSlides]);

  const activeSlides = Array.isArray(childrenArray) ? (
    childrenArray.map((slide, index) => {
      const active = currentSlides.indexOf(index) > -1;
      const hideInactive =
        !active && currentSlides[0] > index;
      const id = Math.floor(Math.random() * 1000);
      return (
        <SlideWrapper
          data-name="SlideWrapper"
          active={active}
          hideInactive={hideInactive}
          data-width={transformWidthAmount}
          key={`${id}`}
          ref={(element) => {
            if (!element || transformWidthAmount !== 0)
              return;
            const width = element.getBoundingClientRect()
              .width;
            setTransformWidthAmount(width);
          }}
        >
          <SlideContainer ref={cardElement}>
            {slide}
          </SlideContainer>
        </SlideWrapper>
      );
    })
  ) : (
    <SlideContainer>{childrenArray}</SlideContainer>
  );

  return (
    <StyledCarousel data-name="StyledCarousel">
      {showArrow && Array.isArray(childrenArray) && (
        <ButtonContainer
          data-name="ButtonContainer"
          backDisabled={currentSlides[0] === 0}
          forwardDisabled={
            currentSlides[currentSlides.length - 1] ===
            childrenArray.length - 1
          }
          backFunction={goBack}
          forwardFunction={goForward}
        />
      )}
      <CarouselWrapper ref={wrapperElementRef}>
        <SlideGroupWrapper
          singleCard={
            !Array.isArray(childrenArray) ||
            (Array.isArray(childrenArray) &&
              childrenArray.length === 1)
          }
          transformAmount={currentTransformAmount}
        >
          {activeSlides}
        </SlideGroupWrapper>
      </CarouselWrapper>
    </StyledCarousel>
  );
}
