import { pageInitialState } from '@components/types';
import {
  createAsyncThunk,
  createSlice,
  Dispatch,
} from '@reduxjs/toolkit';
import { ISliceFactoryArgs } from './sliceFactoryService.types';
import { pegasusClient } from '@services/apiService';
import { trackAnalytics } from '@services/mixpanelService/mixpanel.service';
import { getFromLocalStorage } from '@components/utils/storage';
import { IAnalytics } from '@services/dynamicRenderingService/dynamicRendering.interfaces';

export const FOOTER_STORAGE_STATE = 'dashboard_state';

interface IProps {
  resPayload?: unknown;
  urlParams?: string;
  queryParams?: string;
  type?: string;
}

export function asyncThunkFactory(
  { pageName, endpoint, version, type }: ISliceFactoryArgs,
  dispatch?: Dispatch,
) {
  return createAsyncThunk(
    `${pageName}/fetch${pageName}`,
    async (props: IProps) => {
      const {
        resPayload,
        urlParams = '',
        queryParams = '',
      } = props;
      // urlParams should be in format of '/param' to appear before "&"
      // queryParams should be in format of '&queryString' to appear after "?"
      try {
        if (type === 'post') {
          return (
            await pegasusClient.post(
              `${
                endpoint + urlParams
              }?version=${version}&platform=web${queryParams}`,
              resPayload,
            )
          ).data;
        } else {
          return (
            await pegasusClient.get(
              `${
                endpoint + urlParams
              }?version=${version}&platform=web${queryParams}`,
            )
          ).data;
        }
      } catch (err) {
        console.log('error', err);
        return err;
      }
    },
  );
}

export function pageSliceFactory({
  pageName,
  endpoint,
  version,
}: ISliceFactoryArgs) {
  const pageFetchAsyncThunk = asyncThunkFactory({
    endpoint: endpoint,
    pageName: pageName,
    version: version,
  });

  const flyPageSlice = createSlice({
    name: pageName,
    initialState: pageInitialState,
    reducers: {
      getPage(state, action) {
        const { payload } = action;
        state.data = payload.data;
      },
      cleanState: () => pageInitialState,
    },
    extraReducers: (builder) => {
      builder.addCase(
        pageFetchAsyncThunk.pending,
        (state) => {  
          state.isLoading = true;
        },
      );
      builder.addCase(
        pageFetchAsyncThunk.fulfilled,
        (state, action) => {
          const { payload } = action;
          state.isLoading = false;
          state.data = payload?.data;
          let pageAnalytics = state.data?.pageAnalytics;
          const trackingTitle = pageAnalytics?.trackingTitle;
          if (trackingTitle) {
            if (trackingTitle === 'ViewPage_Confirmation_RequestQuoteBookingFlow' || trackingTitle === 'ViewPage_Confirmation_InstantBookingFlow') {
              const aircraftType = getFromLocalStorage('aircraftType');
              pageAnalytics = {
                ...pageAnalytics,
                properties: [
                  ...(pageAnalytics?.properties ?? []),
                  {
                    key: 'aircraftType',
                    value: aircraftType
                  },
                ]
              } as IAnalytics;
            } 
          }
          trackAnalytics(pageAnalytics);
        },
      );
      builder.addCase(
        pageFetchAsyncThunk.rejected,
        (state, action) => {
          const { payload } = action;
          state.isLoading = false;
          state.error = payload;
        },
      );
    },
  });

  return {
    mainReducer: flyPageSlice.reducer,
    actions: flyPageSlice.actions,
  };
}
