import { IErrorPayload } from '@components/molecules/Error/ErrorSlice';
import { IPayment } from '@components/types';
import {
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit';
import {
  endpoints,
  getAuthHeaders,
  pegasusClient,
} from '@services/apiService';
import axios, { AxiosError } from 'axios';

interface ICreditCardDetails {
  [key: string]: any;
}

export type paymentLocations =
  | 'payment'
  | 'selectPayment'
  | 'addCreditCardPopUp'
  | 'checkWirePopUp';

interface IPaymentPopUpStatusInitialState {
  needsDefaultPayment: boolean;
  pastLocation: paymentLocations;
  paymentLocation: paymentLocations;
  locationDisableNext?: boolean;
  legalCheckboxFlag: boolean;
  legalCheckboxFlags?: Array<boolean>;
  checkWireFlag?: boolean;
  reloadPayment?: boolean;
  isPaymentLoading: boolean;
  purchaseId: string;
  selectedCredits: IPayment[];
  selectedPaymentMethods: IPayment[];
  creditCardFeeAmount?: string;
  creditCardFeeCaption?: string;
  totalCostAmount?: string;
  totalCostValue: number;
  newCreditCard?: ICreditCardDetails;
  errorPayload?: IErrorPayload;
  airmedFlag: boolean;
  airmedSuccessFlag: boolean;
  airmedLegalFlag: string[];
}

export const PopUpStatusInitialState: IPaymentPopUpStatusInitialState = {
  needsDefaultPayment: true,
  pastLocation: 'payment',
  paymentLocation: 'payment',
  isPaymentLoading: false,
  purchaseId: '',
  checkWireFlag: false,
  selectedCredits: [],
  selectedPaymentMethods: [],
  totalCostValue: 0,
  locationDisableNext: true,
  legalCheckboxFlag: false,
  legalCheckboxFlags: [],
  airmedFlag: false,
  airmedSuccessFlag: false,
  airmedLegalFlag: [],
};

interface IProps {
  urlParams?: string;
  queryParams?: string;
  token?: string;
  type?: string;
  resPayload?: unknown;
}

export const saveCreditCardRequest = createAsyncThunk(
  'payment/saveCreditCard',
  async (props: IProps) => {
    const { resPayload = '', queryParams = '' } = props;
    try {
      return (
        await pegasusClient.post(
          `${endpoints.saveCreditCardRequest}?version=1&platform=web${queryParams}`,
          resPayload,
          {
            headers: getAuthHeaders(),
          },
        )
      ).data;
    } catch (err) {
      return err;
    }
  },
);

const CancelToken = axios.CancelToken; // This is a cancel token used to cancel the request
let cancel: any;
export const updatePriceBreakdownRequest = createAsyncThunk(
  'payment/updatePriceBreakdown',
  async (props: IProps) => {
    cancel && cancel('cancel'); // will cancel the request with an error message of 'cancel'
    const { resPayload = '', queryParams = '' } = props;
    try {
      return (
        await pegasusClient.post(
          `${endpoints.updatePriceBreakdown}?version=1&platform=web${queryParams}`,
          resPayload,
          {
            headers: getAuthHeaders(),
            cancelToken: new CancelToken(function executor(
              c,
            ) {
              // executor is called when you cancel the request with the cancel token
              cancel = c;
            }),
          },
        )
      ).data;
    } catch (err: unknown) {
      if (axios.isCancel(err as AxiosError)) {
        // catches the cancel error so it is not thrown
        console.log('Request canceled');
        return;
      } else {
        return err;
      }
    }
  },
);

export const confirmPurchaseRequest = createAsyncThunk(
  'payment/confirmPurchase',
  async (props: IProps) => {
    const { resPayload = '', queryParams = '' } = props;
    try {
      return (
        await pegasusClient.post(
          `${endpoints.confirmPurchase}?version=1&platform=web${queryParams}`,
          resPayload,
          {
            headers: getAuthHeaders(),
          },
        )
      ).data;
    } catch (err) {
      return err;
    }
  },
);

export const confirmAirmedPurchaseRequest = createAsyncThunk(
  'payment/confirmAirmedPurchase',
  async (props: IProps) => {
    const { resPayload = '', queryParams = '' } = props;
    try {
      return (
        await pegasusClient.post(
          `${endpoints.confirmAirmedPurchase}?version=1&platform=web${queryParams}`,
          resPayload,
          {
            headers: getAuthHeaders(),
          },
        )
      ).data;
    } catch (err) {
      return err;
    }
  },
);

const PaymentStatusSlice = createSlice({
  name: 'paymentStatus',
  initialState: PopUpStatusInitialState,
  reducers: {
    setNeedsDefaultPayment: (state, action) => {
      const { payload } = action;
      state.needsDefaultPayment = payload;
    },
    setPaymentLocation: (state, action) => {
      const { payload } = action;
      state.pastLocation = state.paymentLocation;
      state.paymentLocation = payload;
      state.locationDisableNext = true;
    },
    setLocationDisableNext: (state, action) => {
      const { payload } = action;
      state.locationDisableNext = payload;
    },
    setPurchaseId: (state, action) => {
      const { payload } = action;
      state.purchaseId = payload;
    },
    setLegalCheckboxFlag: (state, action) => {
      const { payload } = action;
      state.legalCheckboxFlag = payload;
    },
    setInitialCheckboxFlags: (state, action) => {
      const { payload } = action;
      state.legalCheckboxFlags = payload;
    },
    setLegalCheckboxFlags: (state, action) => {
      const { payload } = action;
      const { payloadIndex, value } = payload;
      state.legalCheckboxFlags = state.legalCheckboxFlags ? state.legalCheckboxFlags.map((checkboxValue, index) => {
        if (index === payloadIndex) {
          return value;
        }
        return checkboxValue;
      }) : [];
    },
    setCheckWireFlag: (state, action) => {
      const { payload } = action;
      state.checkWireFlag = payload;
    },
    setReloadPayment: (state, action) => {
      const { payload } = action;
      state.reloadPayment = payload;
    },
    setSelectedCredits: (state, action) => {
      const { payload } = action;
      state.selectedCredits = payload;
      state.selectedPaymentMethods = [];
    },
    setSelectedPaymentMethods: (state, action) => {
      const { payload } = action;
      state.selectedPaymentMethods = payload;
    },
    setCreditCardFee: (state, action) => {
      const { payload } = action;
      state.creditCardFeeAmount =
        payload.creditCardFeeAmount;
      state.creditCardFeeCaption =
        payload.creditCardFeeCaption;
    },
    setTotalCostAmount: (state, action) => {
      const { payload } = action;
      state.totalCostAmount = payload.totalCostAmount;
      state.totalCostValue = payload.totalCostValue;
    },
    setNewCreditCardDetails: (state, action) => {
      const { payload } = action;
      state.newCreditCard = payload;
    },
    setPaymentPageBack: (state) => {
      switch (state.paymentLocation) {
        case 'selectPayment':
          state.pastLocation = 'payment';
          state.paymentLocation = 'payment';
          break;
        default:
          state.paymentLocation = state.pastLocation;
          break;
      }
    },
    setPaymentPageNext: (state) => {
      state.pastLocation = state.paymentLocation;
      switch (state.paymentLocation) {
        case 'selectPayment':
          state.reloadPayment = true;
          state.paymentLocation = 'payment';
          break;
        case 'addCreditCardPopUp':
          state.paymentLocation = state.pastLocation;
          break;
        case 'checkWirePopUp':
          state.checkWireFlag = true;
          state.reloadPayment = true;
          state.totalCostValue = 0;
          state.paymentLocation = 'payment';
          break;
        default:
          state.paymentLocation = 'payment';
          break;
      }
    },
    setAirmedFlag: (state, action) => {
      const { payload } = action;
      state.airmedFlag = payload;
    },
    setAirmedSuccessFlag: (state, action) => {
      const { payload } = action;
      state.airmedSuccessFlag = payload;
    },
    setAirmedLegalFlag: (state, action) => {
      const { payload } = action;
      state.airmedLegalFlag = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      saveCreditCardRequest.fulfilled,
      (state, action) => {
        const { payload } = action;
        if (payload?.response?.status === 400) {
          state.errorPayload =
            payload.response.data.data.data;
        } else {
          if (state.pastLocation === 'payment') {
            state.reloadPayment = true;
            state.needsDefaultPayment = true;
          }
          state.paymentLocation = state.pastLocation;
        }
      },
    );
    builder.addCase(
      updatePriceBreakdownRequest.fulfilled,
      (state, action) => {
        const { payload } = action;
        if (payload?.response?.status === 400) {
          state.errorPayload =
            payload.response.data.data.data;
        } else {
          const { data } = payload;
          if (data) {
            state.creditCardFeeAmount =
              data.creditCardFeeAmount;
            state.creditCardFeeCaption =
              data.creditCardFeeCaption;
            state.totalCostAmount = data.totalCostAmount;
            state.totalCostValue = data.totalCostValue;
          }
        }
      },
    );
    builder.addCase(
      confirmPurchaseRequest.pending,
      (state) => {
        state.isPaymentLoading = true;
      },
    );
    builder.addCase(
      confirmPurchaseRequest.fulfilled,
      (state, action) => {
        const { payload } = action;
        if (payload?.response?.status === 400) {
          state.errorPayload =
            payload.response.data.data.data;
          state.isPaymentLoading = false;
        } else {
          const { data } = payload;
          state.purchaseId = data?.purchaseId;
          state.pastLocation = 'payment';
          state.paymentLocation = 'payment';
          state.isPaymentLoading = false;
          state.locationDisableNext = true;
          state.checkWireFlag = false;
          state.selectedCredits = [];
          state.selectedPaymentMethods = [];
          state.totalCostValue = 0;
          state.legalCheckboxFlag = false;
        }
      },
    );
    builder.addCase(
      confirmPurchaseRequest.rejected,
      (state) => {
        state.isPaymentLoading = false;
      },
    );
    builder.addCase(
      confirmAirmedPurchaseRequest.pending,
      (state) => {
        state.isPaymentLoading = true;
      },
    );
    builder.addCase(
      confirmAirmedPurchaseRequest.fulfilled,
      (state, action) => {
        const { payload } = action;
        if (payload?.response?.status === 400) {
          state.errorPayload =
            payload.response.data.data.data;
          state.isPaymentLoading = false;
        } else {
          const { data } = payload;
          state.airmedSuccessFlag = true;
        }
      },
    );
    builder.addCase(
      confirmAirmedPurchaseRequest.rejected,
      (state) => {
        state.isPaymentLoading = false;
      },
    );
  },
});

export const {
  setNeedsDefaultPayment,
  setPaymentLocation,
  setLocationDisableNext,
  setReloadPayment,
  setPurchaseId,
  setLegalCheckboxFlag,
  setInitialCheckboxFlags,
  setLegalCheckboxFlags,
  setCheckWireFlag,
  setSelectedCredits,
  setSelectedPaymentMethods,
  setCreditCardFee,
  setTotalCostAmount,
  setNewCreditCardDetails,
  setPaymentPageBack,
  setPaymentPageNext,
  setAirmedFlag,
  setAirmedSuccessFlag,
  setAirmedLegalFlag,
} = PaymentStatusSlice.actions;

export default PaymentStatusSlice.reducer;
