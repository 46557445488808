import styled from 'styled-components';
import { IconButton as MuiIconButton } from '@material-ui/core';
import { solidColors } from '@constants/styles/colors.constants';

const IconButton = styled(MuiIconButton)<{
  imageCarousel?: boolean;
  arrowsSize?: string;
}>`
  height: 50px;
  margin-top: 2%;
  box-shadow: none;
  display: flex;
  background-color: ${solidColors.upBlue.color};
  color: #fff;
  &:hover {
    box-shadow: 0px 0px 5px -3px;
    background-color: #fff;
    color: ${solidColors.upBlue.color};
  }

  ${({ arrowsSize }) =>
    arrowsSize === 'small' &&
    `
        width: 20px;
        height: 20px;
        background-color: #fff;
        color: ${solidColors.upBlue.color};

        &:hover {
            background-color: ${solidColors.upBlue.color};
            color: #fff;
        }

        svg {
            font-size: 0.6rem;
        }
    `}
`;

export default IconButton;
