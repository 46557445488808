// This component is used for CardHeadlineSearchSelectCTADismissSingleSelect | CardHeadlineSearchSelectCTADismissMultiSelect
import React, { useEffect, useState } from 'react';
import { solidColors } from '@constants/styles/colors.constants';
import { typography } from '@constants/styles/typography.constants';
import { TextField, Typography } from '@atoms';
import { ListItemText, ListItemIcon } from '@library';
import {
  IButtonId,
  ICardSearchAirportSelect,
} from './CardHeadlineSearchSelectCTADismissSelect.interfaces';
import { TitleCTACard } from '@molecules';
import {
  InputLabelStyled,
  ListStyled,
  ListItemStyled,
  NoResultTypography,
} from './CardHeadlineSearchSelectCTADismissSelect.styles';
import { useDebounce } from '@hooks';
import { SingleSelect } from './SingleSelect';
import { MultiSelect } from './MultiSelect';
import { searchProgressiveProfiling } from '@services/progressiveProfilingService/progressiveProfilingService';
import { useDispatch } from 'react-redux';
import { submitAnswers } from '@features/Dashboard/CarouselSlice';
import { Checkbox } from '@wheelsup/wu-react-components';

interface ISelectedObj {
  [key: string]: boolean;
}

export default function CardHeadlineSearchSelectCTADismissSelect({
  id,
  searchCardType,
  maxSelectable = 1,
  headline,
  dismissable, // FIXME
  button,
  searchField,
  items,
}: ICardSearchAirportSelect) {
  const dispatch = useDispatch();
  const [resultsArray, setResultsArray] = useState<
    IButtonId[]
  >(items);
  const [
    selectedObj,
    setSelectedObj,
  ] = useState<ISelectedObj>({
    'not a real button': false,
  }); // This is used to check if an item is already selected so it is not shown in results list, only selected list
  const [selectedResults, setSelectedResults] = useState<
    string[]
  >([]); // This is the value sent to pegasus on submit
  const [isSearching, setIsSearching] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [disabled, setDisabled] = useState(false);
  const debouncedSearchTerm = useDebounce<string>(
    inputValue,
    250,
  );
  const question = button.action.outboundPayloadKeys?.filter(
    (obj) =>
      obj.key === 'Question' || obj.key === 'question',
  )[0];

  const onInputChange = (
    _event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    _event.preventDefault();
    const value = _event.target.value;
    setInputValue(value);
    if (value.length >= 2) {
      setIsSearching(true);
    } else {
      setIsSearching(false);
      setResultsArray(items);
    }
  };

  const checkSelected = (result?: string) => {
    return result && selectedObj[result as string];
  };

  useEffect(() => {
    const getOptions = async (matchString: string) => {
      if (isSearching && matchString.length >= 2) {
        const res = await searchProgressiveProfiling(
          debouncedSearchTerm,
          searchCardType,
        );
        setResultsArray(res.data.data.results);
      }
    };
    getOptions(debouncedSearchTerm);
  }, [debouncedSearchTerm]);

  useEffect(() => {
    selectedResults.length >= maxSelectable
      ? setDisabled(true)
      : setDisabled(false);
  }, [selectedResults]);

  const selectOnChange = (
    _event: React.ChangeEvent<HTMLInputElement>,
    item: string,
    remove: boolean,
  ) => {
    _event.preventDefault();
    remove
      ? setSelectedResults(
          selectedResults.filter((res) => res !== item),
        )
      : setSelectedResults([...selectedResults, item]);
    setSelectedObj({
      ...selectedObj,
      [item]: remove ? false : true,
    });
  };

  const singleSelectOnChange = (
    _event: React.ChangeEvent<HTMLInputElement>,
    item: IButtonId,
  ) => {
    _event.preventDefault();
    const currSelected = selectedResults[0];
    item.title === currSelected
      ? setSelectedResults([])
      : setSelectedResults([item.title as string]);
  };

  const submitAnswer = () => {
    const submitQuestion = question?.value as string;
    dispatch(
      submitAnswers({
        q: submitQuestion,
        a: selectedResults,
      }),
    );
  };

  // used to submit an empty answer when dismissing.
  const submitDismissAnswer = () => {
    const submitQuestion = question?.value as string;
    dispatch(submitAnswers({ q: submitQuestion, a: [''] }));
  };

  const onDismiss = () => {
    submitDismissAnswer();
  };

  return (
    <TitleCTACard
      headline={headline}
      button={{
        ...button,
        action: {
          actionMethod: submitAnswer,
        },
      }}
      onDismiss={onDismiss}
      dismissable={dismissable}
    >
      {maxSelectable > 1 &&
        selectedResults.map((result) => (
          <ListItemStyled
            key={result}
            onClick={(
              _event: React.ChangeEvent<HTMLInputElement>,
            ) => {
              selectOnChange(_event, result, true);
            }}
            dense
            button
            value={result}
          >
            <ListItemIcon>
              <Checkbox
                checked
                onChange={() => null}
                size="sm"
              />
            </ListItemIcon>
            <ListItemText>
              <Typography
                variant={typography.heading05Large}
                color={solidColors.darkGray}
                truncate={1}
              >
                {result}
              </Typography>
            </ListItemText>
          </ListItemStyled>
        ))}
      {/* This might need to be changed in the future to include leading/trailing icons */}
      <TextField
        onChange={onInputChange}
        label={
          <InputLabelStyled>
            <Typography
              variant={typography.body1}
              color={solidColors.mediumGray}
              truncate={1}
            >
              {searchField.placeholder}
            </Typography>
          </InputLabelStyled>
        }
        value={inputValue}
      />
      <ListStyled>
        {maxSelectable === 1
          ? resultsArray.map((item) => (
              <SingleSelect
                key={item.id}
                checked={item.title === selectedResults[0]}
                item={item.title as string}
                onClick={(
                  _event: React.ChangeEvent<HTMLInputElement>,
                ) => singleSelectOnChange(_event, item)}
              />
            ))
          : resultsArray
              .filter(
                (item) =>
                  !checkSelected(item.title as string),
              )
              .map((item) => (
                <MultiSelect
                  key={item.id}
                  item={item.title as string}
                  disabled={disabled}
                  checked={!!item.checked}
                  onClick={selectOnChange}
                />
              ))}
      </ListStyled>

      {isSearching && resultsArray.length === 0 && (
        <NoResultTypography>
          <Typography
            variant={typography.body1}
            color={solidColors.mediumGray}
            truncate={2}
          >
            No results matching your search, please try
            again.
          </Typography>
        </NoResultTypography>
      )}
    </TitleCTACard>
  );
}
