import { AppDispatch } from '@app/store';
import { asyncThunkFactory } from '@services/sliceFactoryService/sliceFactory';
import { asyncThunkFactory as asyncRequestFactory } from '@services/sliceFactoryService/requestSlice';
import {
  dataSliceOptions,
  IPageSliceOptions,
  pageSliceOptions,
} from '@services/sliceFactoryService/sliceFactoryService.types';
import { setAppIsLoading } from './handle401Slice';

interface IRetry {
  urlParams?: string;
  queryParams?: string;
  pageSliceName: string;
  requestType: string;
  type?: string;
  resPayload?: unknown;
  storeDispatch: AppDispatch;
}

export const retryHandler = ({
  urlParams,
  queryParams,
  pageSliceName,
  requestType,
  type,
  resPayload,
  storeDispatch,
}: IRetry) => {
  const pageOption = pageSliceName as keyof IPageSliceOptions;
  switch (requestType) {
    case 'sliceFactory':
      storeDispatch(
        asyncThunkFactory(pageSliceOptions[pageOption])({
          /*
                        FIXME:
                        This is a quick fix because it looks like a token/code is being passed
                        back from the v1 app disrupting the fly user -> member conversion flow
                    */
          urlParams:
            pageOption === 'confirmation' ? '' : urlParams,
          queryParams: queryParams,
          resPayload: resPayload,
          type: type,
        }),
      );
      storeDispatch(setAppIsLoading(false));
      break;
    case 'requestFactory':
      if (type === 'get') {
        storeDispatch(
          asyncRequestFactory(dataSliceOptions[pageOption])(
            {
              queryParams: queryParams,
            },
          ),
        );
        storeDispatch(setAppIsLoading(false));
      } else if (type === 'post') {
        storeDispatch(
          asyncRequestFactory(dataSliceOptions[pageOption])(
            {
              queryParams: queryParams,
              resPayload: resPayload as any,
            },
          ),
        );
        storeDispatch(setAppIsLoading(false));
      }
      break;
    default:
      storeDispatch(setAppIsLoading(false));
      break;
  }
};
