import styled from 'styled-components';
import { solidColors } from '@constants/styles/colors.constants';

export const BannerContainer = styled.div`
  display: flex;
  align-items: flex-start;
  position: relative;
  height: 470px;
  overflow: hidden;
  background-color: ${solidColors.c1.color};
  flex-wrap: wrap;

  ${(props) => props.theme.breakpoints.down('sm')} {
    height: auto;
  }
`;

export const BannerContent = styled.div`
  width: 45%;
  padding: 0 80px;
  align-self: center;

  ${(props) => props.theme.breakpoints.down('sm')} {
    width: 100%;
    padding: 80px;
  }
`;

export const BannerImage = styled.div`
  width: 55%;
  height: inherit;

  ${(props) => props.theme.breakpoints.down('sm')} {
    width: 100%;
    height: 260px;
  }
`;
