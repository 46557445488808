import { solidColors } from '@constants/styles/colors.constants';
import styled from 'styled-components';
import { theme } from '@constants/styles/theme.constants';

export const StyledQuickAccessBar = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 32px;

  @media (max-width: ${theme.breakpoints.values.md}px) {
    justify-content: center;
    padding: 0 20px;
  }
`;

export const Divider = styled.div`
  border-bottom-style: solid;
  border-bottom-color: ${solidColors.c9.color};
  border-bottom-width: 1px;
  width: 100%;
  height: 0.5px;
  margin: 0;
`;

export const ButtonContainer = styled.div<{
  $align: 'left' | 'right';
}>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: ${({ $align }) =>
    $align === 'left' ? 'flex-start' : 'flex-end'};
  align-items: flex-start;
  width: 50%;
  height: 100%;
  min-height: 20px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 8px 0;
`;
