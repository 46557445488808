import { PageBackground } from '@atoms';
import { Loader } from '..';

const LoadingPage = () => {
  return (
    <PageBackground metadata={undefined}>
      <Loader />
    </PageBackground>
  );
};

export default LoadingPage;
