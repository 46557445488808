import styled from 'styled-components';
import { solidColors } from '@constants/styles/colors.constants';
import { Button, Typography } from '@atoms';
import { theme } from '@constants/styles/theme.constants';
import { Icons } from '@components/library';
import { Elevation } from '@wheelsup/wu-react-components';

export const StyledElevation = styled(Elevation).attrs({
  variant: 'raisedLevel2',
  padding: 0,
})`
  width: 100%;
`;

export const StyledFlightCard = styled.div<{
  $isClickable: boolean;
  $inCarousel?: boolean;
}>`
  margin: 0;
  overflow: hidden;
  border-radius: 8px;
  ${(props) => {
    let result = '';
    if (props.$isClickable) {
      result += `
                cursor: pointer;
            `;
    }
    if (props.$inCarousel) {
      result += `
                margin: 4px 0 4px 2px;
                min-width: 100%;

                @media (max-width: ${theme.breakpoints.values.md}px) {
                    min-width: 99%;
                    max-width: 600px;
                }
                @media (max-width: ${theme.breakpoints.values.sm}px) {
                    min-width: 99%;
                    max-width: 330px;
                }

            `;
    }
    return result;
  }}
  display: flex;
  flex-direction: column;
  min-height: 208px;
  width: 100%;
  max-width: 1088px;
  padding: 0 36px 0 0;
  height: fit-content;

  @media (max-width: ${theme.breakpoints.values.md}px) {
    padding: 0;
  }
`;

export const StyledContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 0 0 24px 32px;
  margin-top: 16px;

  @media (max-width: ${theme.breakpoints.values.md}px) {
    justify-content: center;
    padding: 0 20px 20px 20px;
  }
  @media (max-width: ${theme.breakpoints.values.md}px) {
    justify-content: center;
    margin: 8px 0 0 0;
    padding: 0 20px 20px 20px;
  }
`;

export const TopSectionContainer = styled.div`
  width: max-content;
  display: flex;
  flex-direction: row;
  margin: 15px 0 0 32px;
  align-items: center;
  width: 100%;

  @media (max-width: ${theme.breakpoints.values.md}px) {
    margin-left: 20px;
  }

  @media (max-width: ${theme.breakpoints.values.md}px) {
    display: flex;
    width: fit-content;
    justify-content: flex-end;
  }
`;

export const SmallDivider = styled.div`
  border-left-style: solid;
  border-left-color: ${solidColors.mediumGray.color};
  border-left-width: 1px;
  margin: 0 10px;
  height: 20px;
  width: 0.5px;
`;

export const FlightStatus = styled.div<{
  $backgroundColor?: string;
}>`
  width: fit-content;
  height: 26px;
  padding: 0 1.25rem 0 1rem;
  background-color: ${({ $backgroundColor }) =>
    $backgroundColor
      ? $backgroundColor
      : solidColors.midnight.color};
  border-radius: 0 0 75px 0;
  border-color: ${({ $backgroundColor }) =>
    $backgroundColor
      ? $backgroundColor
      : solidColors.midnight.color};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const IconText = styled(Typography)<{
  $paddingTop?: boolean;
}>`
  padding-top: ${({ $paddingTop }) =>
    $paddingTop ? '4px' : '0'};
  margin-left: 5px;
`;

export const AircraftContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-left: 1px solid ${solidColors.mediumGray.color};
  margin: 0 0 0 12px;
  padding-left: 12px;
`;

export const AircraftTextContainer = styled.div`
  padding-top: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: fit-content;
`;

export const LinkButton = styled(Button)`
  padding: 0 !important;
`;

export const StyledAircraftDetailsButton = styled(Button)`
  &.MuiButton-root {
    border: none;
    padding: 0;
  }
`;

export const StyledIcon = styled(Icons.ChevronRight)`
  color: ${solidColors.upBlue.color};
`;

export const TripCTAContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
