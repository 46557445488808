import { useState } from 'react';
import { TitleCTACard } from '@molecules';
import OptionPicker from '../OptionPicker/OptionPicker';
import { ICardThreeOptionPicker } from './CardThreeOptionPicker.interfaces';
import { StyledBackgroundImage } from './CardThreeOptionPicker.styles';
import { hostedImage } from '@constants/images/images.constants';
import { useDispatch } from 'react-redux';
import { submitAnswers } from '@features/Dashboard/CarouselSlice';

const CardThreeOptionPicker = ({
  headline,
  items,
  dismissable,
  button,
}: ICardThreeOptionPicker) => {
  const dispatch = useDispatch();
  const [clearState, setClearState] = useState(false);
  const [answerArray, setAnswerArray] = useState<string[]>([
    '',
  ]);
  const question = button.action.outboundPayloadKeys?.filter(
    (obj) =>
      obj.key === 'Question' || obj.key === 'question',
  )[0];

  const optionClickHandler = (option: string) => {
    setAnswerArray([option]);
  };

  // used to submit an empty answer when dismissing.
  const submitDismissAnswer = () => {
    const submitQuestion = question?.value as string;
    dispatch(submitAnswers({ q: submitQuestion, a: [''] }));
  };

  const submitProfileAnswers = () => {
    const submitQuestion = question?.value as string;
    dispatch(
      submitAnswers({
        q: submitQuestion,
        a: answerArray,
      }),
    );
  };

  const onDismiss = () => {
    submitDismissAnswer();
    setClearState(true);
  };

  return (
    <TitleCTACard
      headline={headline}
      onDismiss={onDismiss}
      button={{
        title: button.title,
        action: {
          actionMethod: submitProfileAnswers,
        },
      }}
      dismissable={dismissable}
    >
      <StyledBackgroundImage
        $url={hostedImage.wheelsUpDottedLine}
      />
      <OptionPicker
        options={items}
        clear={clearState}
        onClick={optionClickHandler}
      />
    </TitleCTACard>
  );
};

export default CardThreeOptionPicker;
