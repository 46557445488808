import styled from 'styled-components';

export const MainContainer = styled.div<{
  $isVertical: boolean;
}>`
  display: flex;
  justify-content: space-between;

  ${({ $isVertical }) =>
    $isVertical &&
    `
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    `}

  > div:first-child {
    user-select: none;
    pointer-events: none;
  }
`;
