import ImageImgix from '@atoms/ImageImgix/ImageImgix';
import { solidColors } from '@constants/styles/colors.constants';
import { theme } from '@constants/styles/theme.constants';
import styled from 'styled-components';

export const PageBackgroundStyled = styled.div<{
  backgroundColor?: string;
  clouds?: boolean;
}>`
  min-height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
  display: flow-root;
  ${(props) =>
    !props.clouds
      ? `
        background-color: ${props.backgroundColor};
    `
      : `
        background: ${props.backgroundColor};
    `}
  background-size: cover;
  z-index: -2;
  background-attachment: fixed;
`;

export const StyledExtendedHeaderBackground = styled.div`
  position: absolute;
  height: 300px;
  width: 100%;
  background-color: ${solidColors.midnight.color};

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    height: 380px;
  }
`;

export const SignInClouds = styled.img`
  position: absolute;
  top: -2rem;
  width: 120%;
`;

export const SideCloud = styled.img`
  position: absolute;
  top: 4rem;
  right: 0%;
  width: 30%;
`;

export const BackgroundImage = styled(ImageImgix)`
  position: absolute;
  border-radius: 0;
`;
