import {
  FormContainer,
  FormRow,
  PassengerHeader,
} from './AirmedForm.styles';
import Input from '@molecules/Input';
import { Select, Typography } from '@atoms';
import { typography } from '@constants/styles/typography.constants';
import { MenuItem } from '@material-ui/core';
import { stateItems } from '@components/types';
import { IAirmedForm } from './AirmedForm.interfaces';
import {
  emailRegex,
  phoneRegex,
} from '@constants/validations/regexLibrary';
import { useState, useRef } from 'react';
import { useOnClickOutside } from '@hooks';

export const validateDate = (date: string) => {
  if (date.length >= 8) {
    if (date.includes('/')) {
      const dateArray = date.split('/');

      return (
        Number(dateArray[0]) <= 12 &&
        Number(dateArray[1]) <= 31 &&
        Number(dateArray[2]) > 1900 &&
        Number(dateArray[2]) < 2022
      );
    } else {
      const formated_date = date.replace(
        /(\d{2})(\d{2})(\d{4})/,
        '$1/$2/$3',
      );
      const dateArray = formated_date.split('/');

      return (
        Number(dateArray[0]) <= 12 &&
        Number(dateArray[1]) <= 31 &&
        Number(dateArray[2]) > 1900 &&
        new Date(formated_date) <
          new Date(new Date().toDateString())
      );
    }
  } else {
    return false;
  }
};

export const validatePax = (pax: any) => {
  return (
    pax.firstName !== '' &&
    pax.lastName !== '' &&
    validateDate(pax.birthDate || '') &&
    (pax.phone ? pax.phone.length >= 10 : true) &&
    (pax.email ? emailRegex.test(pax.email) : true) &&
    pax.addressLine1 &&
    pax.addressLine1.length >= 2 &&
    pax.city !== '' &&
    pax.state &&
    pax.state !== '' &&
    pax.postalCode &&
    pax.postalCode.length >= 5
  );
};

export const validateInputs = (
  keyList: string[],
  pax: any,
) => {
  let errorList: string[] = [];
  keyList.forEach((key: string) => {
    switch (key) {
      case 'firstName':
        if (pax.firstName.length < 1) {
          errorList.push('firstName');
        } else {
          errorList = errorList.filter(
            (value: string) => value === 'firstName',
          );
        }
        break;
      case 'lastName':
        if (pax.lastName.length < 1) {
          errorList.push('lastName');
        } else {
          errorList = errorList.filter(
            (value: string) => value === 'lastName',
          );
        }
        break;
      case 'birthDate':
        if (validateDate(pax.birthDate || '')) {
          errorList = errorList.filter(
            (value: string) => value === 'birthDate',
          );
        } else {
          errorList.push('birthDate');
        }
        break;
      case 'phone':
        if (pax.phone && phoneRegex.test(pax.phone)) {
          errorList = errorList.filter(
            (value: string) => value === 'phone',
          );
        } else {
          errorList.push('phone');
        }
        break;
      case 'email':
        if (pax.email && emailRegex.test(pax.email)) {
          errorList = errorList.filter(
            (value: string) => value === 'email',
          );
        } else {
          errorList.push('email');
        }
        break;
      case 'addressLine1':
        if (
          pax.addressLine1 &&
          pax.addressLine1.length >= 2
        ) {
          errorList = errorList.filter(
            (value: string) => value === 'addressLine1',
          );
        } else {
          errorList.push('addressLine1');
        }
        break;
      case 'city':
        if (pax.city < 2) {
          errorList.push('city');
        } else {
          errorList = errorList.filter(
            (value: string) => value === 'city',
          );
        }
        break;
      case 'state':
        if (pax.state !== '') {
          errorList = errorList.filter(
            (value: string) => value === 'state',
          );
        } else {
          errorList.push('state');
        }
        break;
      case 'postalCode':
        if (pax.postalCode && pax.postalCode.length >= 5) {
          errorList = errorList.filter(
            (value: string) => value === 'state',
          );
        } else {
          errorList.push('state');
        }
        break;
    }
  });
  return errorList;
};

export default function AirmedForm({
  title,
  copy,
  phoneFlag,
  emailFlag,
  addressTitle,
  mainPassenger,
  index,
  onChange,
}: IAirmedForm) {
  const popupRef = useRef(null);

  const [errorList, setErrorList] = useState<string[]>([]);

  const changeState = (
    inputKey: string,
    value: string,
    index?: number,
  ) => {
    onChange(inputKey, value, index);
    validateInput(inputKey, value);
  };

  const validateInput = (key: string, value: any) => {
    switch (key) {
      case 'firstName':
        if (value.length < 1) {
          setErrorList([...errorList, 'firstName']);
        } else {
          setErrorList(
            errorList.filter(
              (value: string) => value !== 'firstName',
            ),
          );
        }
        break;
      case 'lastName':
        if (value.length < 1) {
          setErrorList([...errorList, 'lastName']);
        } else {
          setErrorList(
            errorList.filter(
              (value: string) => value !== 'lastName',
            ),
          );
        }
        break;
      case 'birthDate':
        if (validateDate(value || '')) {
          setErrorList(
            errorList.filter(
              (value: string) => value !== 'birthDate',
            ),
          );
        } else {
          setErrorList([...errorList, 'birthDate']);
        }
        break;
      case 'phone':
        if (phoneRegex.test(value)) {
          setErrorList(
            errorList.filter(
              (value: string) => value !== 'phone',
            ),
          );
        } else {
          setErrorList([...errorList, 'phone']);
        }
        break;
      case 'email':
        if (emailRegex.test(value)) {
          setErrorList(
            errorList.filter(
              (value: string) => value !== 'email',
            ),
          );
        } else {
          setErrorList([...errorList, 'email']);
        }
        break;
      case 'addressLine1':
        if (value.length >= 2) {
          setErrorList(
            errorList.filter(
              (value: string) => value !== 'addressLine1',
            ),
          );
        } else {
          setErrorList([...errorList, 'addressLine1']);
        }
        break;
      case 'city':
        if (value.length < 2) {
          setErrorList([...errorList, 'city']);
        } else {
          setErrorList(
            errorList.filter(
              (value: string) => value !== 'city',
            ),
          );
        }
        break;
      case 'state':
        if (value && value !== '') {
          setErrorList(
            errorList.filter(
              (value: string) => value !== 'state',
            ),
          );
        } else {
          setErrorList([...errorList, 'state']);
        }
        break;
      case 'postalCode':
        if (value.length >= 5) {
          setErrorList(
            errorList.filter(
              (value: string) => value !== 'postalCode',
            ),
          );
        } else {
          setErrorList([...errorList, 'postalCode']);
        }
        break;
    }
  };

  const handleOutsideClick = () => {
    validateInput('state', mainPassenger.state);
  };

  useOnClickOutside(popupRef, handleOutsideClick);

  return (
    <form>
      {title && (
        <PassengerHeader>
          <Typography variant={typography.heading02Large}>
            {title}
          </Typography>
          {copy && (
            <Typography variant={typography.body1a}>
              {copy}
            </Typography>
          )}
        </PassengerHeader>
      )}
      <FormContainer>
        <FormRow>
          <Input
            isRequired
            variant={'outlined'}
            title="First Name"
            type="firstName"
            disabled={mainPassenger.firstNameEnableFlag}
            payloadKey="firstName"
            value={mainPassenger.firstName}
            datatestid="first-name"
            onChange={(e) => {
              onChange('firstName', e.target.value, index);
            }}
            onBlur={(e) => {
              validateInput('firstName', e.target.value);
            }}
            errorMessage={'Invalid name'}
            error={errorList.includes('firstName')}
          />
          <Input
            variant={'outlined'}
            title="Middle Name"
            type="standard"
            disabled={mainPassenger.middleNameEnableFlag}
            payloadKey="middleName"
            value={mainPassenger.middleName}
            datatestid="middle-name"
            leadingHelperCopy="Optional"
            onChange={(e) => {
              onChange('middleName', e.target.value, index);
            }}
          />
          <Input
            isRequired
            variant={'outlined'}
            title="Last Name"
            type="lastName"
            disabled={mainPassenger.lastNameEnableFlag}
            payloadKey="lastName"
            value={mainPassenger.lastName}
            datatestid="last-name"
            onChange={(e) => {
              onChange('lastName', e.target.value, index);
            }}
            onBlur={(e) => {
              validateInput('lastName', e.target.value);
            }}
            errorMessage={'Invalid last name'}
            error={errorList.includes('lastName')}
          />
          <Input
            isRequired
            variant={'outlined'}
            title={'Birthday'}
            type={'simpleDate'}
            value={mainPassenger.birthDate}
            disabled={mainPassenger.birthDateEnableFlag}
            payloadKey="birthDate"
            onChange={(e) => {
              onChange('birthDate', e.target.value, index);
            }}
            onBlur={(e) => {
              validateInput('birthDate', e.target.value);
            }}
            errorMessage={'Invalid date'}
            error={errorList.includes('birthDate')}
          />
          {phoneFlag && (
            <div id={'something'}>
              <Input
                isRequired
                variant={'outlined'}
                title={'Phone'}
                type={'phone'}
                disabled={mainPassenger.phoneEnableFlag}
                payloadKey={'phone'}
                value={mainPassenger.phone}
                datatestid={'phone'}
                onChange={(e) => {
                  onChange('phone', e.target.value, index);
                }}
                onBlur={(e) => {
                  validateInput('phone', e.target.value);
                }}
                errorMessage={'Invalid phone'}
                error={errorList.includes('phone')}
              />
            </div>
          )}
          {emailFlag && (
            <Input
              isRequired
              variant={'outlined'}
              title={'Email'}
              type={'email'}
              disabled={mainPassenger.emailEnableFlag}
              payloadKey={'email'}
              value={mainPassenger.email}
              datatestid={'email'}
              onChange={(e) => {
                onChange('email', e.target.value, index);
              }}
              onBlur={(e) => {
                validateInput('email', e.target.value);
              }}
              errorMessage={'Invalid email'}
              error={errorList.includes('email')}
            />
          )}
        </FormRow>
      </FormContainer>

      {addressTitle && (
        <PassengerHeader>
          <Typography variant={typography.heading02Large}>
            {addressTitle}
          </Typography>
        </PassengerHeader>
      )}

      <FormContainer>
        <FormRow>
          <Input
            isRequired
            title={'Address'}
            variant={'outlined'}
            type={'standard'}
            disabled={false}
            payloadKey={'address'}
            value={mainPassenger.addressLine1}
            onChange={(e) => {
              onChange(
                'addressLine1',
                e.target.value,
                index,
              );
            }}
            onBlur={(e) => {
              validateInput('addressLine1', e.target.value);
            }}
            errorMessage={'Invalid address'}
            error={errorList.includes('addressLine1')}
          />
          <Input
            title={'Apt, suite or floor'}
            variant={'outlined'}
            type={'standard'}
            disabled={false}
            payloadKey={'aptSuite'}
            value={mainPassenger.addressLine2}
            onChange={(e) => {
              onChange(
                'addressLine2',
                e.target.value,
                index,
              );
            }}
          />
          <Input
            isRequired
            title={'City'}
            type={'standard'}
            variant={'outlined'}
            disabled={false}
            payloadKey={'city'}
            value={mainPassenger.city}
            onChange={(e) => {
              onChange('city', e.target.value, index);
            }}
            onBlur={(e) => {
              validateInput('city', e.target.value);
            }}
            errorMessage={'Invalid city'}
            error={errorList.includes('city')}
          />
          <Select
            isRequired
            label={'State'}
            variant={'outlined'}
            onChange={(e) =>
              changeState('state', e.target.value, index)
            }
            value={mainPassenger.state}
            error={errorList.includes('state')}
            helperText={
              errorList.includes('state')
                ? 'Invalid state'
                : ''
            }
          >
            {stateItems.map((state: string) => (
              <MenuItem
                ref={popupRef}
                key={state}
                value={state}
              >
                {state}
              </MenuItem>
            ))}
          </Select>
          <Input
            isRequired
            title={'Postal Code'}
            variant={'outlined'}
            type={'standard'}
            disabled={false}
            payloadKey={'postalCode'}
            value={mainPassenger.postalCode}
            onChange={(e) => {
              onChange('postalCode', e.target.value, index);
            }}
            onBlur={(e) => {
              validateInput('postalCode', e.target.value);
            }}
            errorMessage={'Invalid postal code'}
            error={errorList.includes('postalCode')}
          />
        </FormRow>
      </FormContainer>
    </form>
  );
}
