import { DateTime } from 'luxon';

const startOfDay = DateTime.now().startOf('day');
export const options: {
  label: string;
  value: string;
}[] = [];
for (let i = 0; i < 48; i++) {
  const option = startOfDay.plus({ minutes: i * 30 });
  options.push({
    label: option.toFormat('hh:mm a'),
    // we have to do string for the dropdown componnet
    // because its a primitive
    value: option.toISO(),
  });
}
export const presets = [
  {
    text: '9 AM',
    value: startOfDay.plus({ hours: 9 }).toISO(),
  },
  {
    text: '3 PM',
    value: startOfDay.plus({ hours: 15 }).toISO(),
  },
  {
    text: '5 PM',
    value: startOfDay.plus({ hours: 17 }).toISO(),
  },
];
