import Spacer from '@atoms/Spacer/Spacer';
import { Container } from '@components/library';
import TabContent from '@components/molecules/TabContent';
import Tabs from '@components/molecules/Tabs';
import { typography } from '@constants/styles/typography.constants';
import { trackAnalytics } from '@services/mixpanelService/mixpanel.service';
import React from 'react';
import { IContainerSubNav } from './ContainerSubNav.interfaces';
import {
  StyledContainerSubNavWrapper,
  StyledSubNavContainer,
  StyledSubNavTitle,
  StyledTabsWrapper,
} from './ContainerSubNav.styles';

export default function ContainerSubNav({
  children,
  defaultItemIndex = 0,
  id,
  headline,
  items,
  tabChangeHandle,
  spacerTypeTop,
  spacerTypeBottom,
}: IContainerSubNav) {
  if (!items || items.length === 0) {
    return null;
  }
  const [current, setCurrent] = React.useState(
    defaultItemIndex,
  );

  const handleChange = (
    _event: React.ChangeEvent<{}>,
    newValue: number,
  ) => {
    setCurrent(newValue);
    tabChangeHandle ? tabChangeHandle(newValue) : null;

    if (items[current]?.analytics) {
      trackAnalytics(items[newValue].analytics);
    }
  };

  return (
    <>
      {spacerTypeTop && <Spacer height={spacerTypeTop} />}
      {headline ? (
        <StyledContainerSubNavWrapper data-name="ContainerSubNav-Header">
          <Container>
            <StyledSubNavTitle
              variant={typography.heading04Large}
            >
              {headline}
            </StyledSubNavTitle>
          </Container>
        </StyledContainerSubNavWrapper>
      ) : null}
      <StyledTabsWrapper data-name="ContainerSubNav-Body">
        <StyledSubNavContainer>
          <Tabs
            current={current}
            handleChange={handleChange}
            items={items}
          />
          <TabContent
            id={id}
            current={current}
            items={items}
          >
            {children}
          </TabContent>
        </StyledSubNavContainer>
      </StyledTabsWrapper>
      {spacerTypeBottom && (
        <Spacer height={spacerTypeBottom} />
      )}
    </>
  );
}
