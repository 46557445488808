import { TextInput } from '@wheelsup/wu-react-components';
import styled from 'styled-components';
import { theme } from '@constants/styles/theme.constants';

export const StyledInputContainer = styled.div`
  padding: 0;
  display: flex;
  align-items: center;
  margin: 24px 0;
  width: 100%;
  ${(props) => props.theme.breakpoints.down(theme.breakpoints.values.md)} {
    flex-direction: column;
    align-items: start;
  }
`;

export const StyledInputCheckboxContainer = styled(
  StyledInputContainer,
)`
  flex-direction: row;
`;

export const PetTypeOtherFormField = styled.div`
  ${(props) => props.theme.breakpoints.down(theme.breakpoints.values.md)} {
    width: 100%;
    margin-top: 24px;
  }
  ${(props) => props.theme.breakpoints.up(theme.breakpoints.values.md)} {
    margin-left: 15px;
    margin-bottom: 10px;
  }
  min-width: 220px;
`;

export const StyledContainer = styled.div`
  margin: 12px 0;
`;

export const StyledGroupPillText = styled.div`
  width: 85px;
  margin-right: 16px;
`;

export const StyledTextInput = styled(TextInput)`
  ${(props) => props.theme.breakpoints.up(theme.breakpoints.values.md)} {
    width: 286px;
  }
`;
