import { typography } from '@constants/styles/typography.constants';
import {
  Color,
  solidColors,
} from '@constants/styles/colors.constants';
import {
  ButtonStyled,
  ButtonTextStyled,
} from './Button.styles';
import { CTAVariants, CtaVariantsColorTranslationType, IButton } from './Button.interfaces';
import { ImageImgix } from '@atoms';
import { useNavigate } from 'react-router-dom';
import {
  ActionTypes,
  IAction,
} from '@atoms/Action/Action.interfaces';
import ProviderWrapper from '@components/utils/ProviderWrapper';
import { useDispatch } from 'react-redux';
import { trackAnalytics } from '@services/mixpanelService/mixpanel.service';
import { IAnalytics } from '@services/dynamicRenderingService/dynamicRendering.interfaces';
import { useAppSelector } from '@app/hooks';
import getServerAction from './getServerAction';
import { useState } from 'react';
import { TypographyProps } from '@wheelsup/wu-react-components/types/components/atoms/Typography/Typography';
import { WidthOptions } from '@wheelsup/wu-react-components';

const CtaVariantsColorTranslation: CtaVariantsColorTranslationType = {
  primary: solidColors.upWhite,
  secondary: solidColors.upBlue,
  tertiary: solidColors.midnight,
  destructive: solidColors.lava,
  disabled: solidColors.darkGray,
  lock: solidColors.upWhite,
  pill: solidColors.midnight,
  icon_leading: solidColors.midnight,
  icon_trailing: solidColors.midnight,
  icon_button: solidColors.upBlue,
  text: solidColors.upBlue,
  text_with_icon_leading: solidColors.upBlue,
  text_with_icon_trailing: solidColors.upBlue,
  card_primary: solidColors.upBlue,
  card_secondary: solidColors.upWhite,
  card_tertiary: solidColors.midnight,
  card_destructive: solidColors.lava,
  card_disabled: solidColors.darkGray,
  card_lock: solidColors.upWhite,
  card_secondary_web: solidColors.upBlue,
  icon: solidColors.darkGray,
  icon_no_padding: solidColors.upBlue,
  white: solidColors.upWhite,
  card_destructive_web: solidColors.lava,
  outlined: solidColors.upBlue,
  ghost: solidColors.upBlue,
  'links-icon-leading': solidColors.upBlue,
  'links-icon-trailing': solidColors.upBlue,
};

export function Button({
  action,
  title,
  image,
  imgixStyles,
  nativeIcon,
  styleType = 'secondary',
  width,
  radius,
  height,
  className,
  disabled,
  color,
  datatestid,
  textAlign = 'center',
  iconWidth = 20,
  iconHeight = 20,
  textTransform = '',
}: IButton): JSX.Element | null {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isHovered, setIsHovered] = useState(false);
  const buttonAction: Partial<IAction> = action || {};
  const type: ActionTypes | undefined = buttonAction?.type;
  const url: string | undefined = buttonAction?.data?.split(
    '/',
  )[3];

  const {
    paymentLocation,
    checkWireFlag,
    selectedCredits,
    selectedPaymentMethods,
    airmedLegalFlag,
  } = useAppSelector((state) => state.paymentStatus);

  const { mainPassenger, passengerList } = useAppSelector(
    (state) => state.airmed,
  );

  const { isAppLoading } = useAppSelector(
    (state) => state.appLoading,
  );

  const handleIframeClick = () => {
    if (buttonAction.actionAnalytics)
      trackAnalytics(
        buttonAction.actionAnalytics as IAnalytics,
      );

    const fileExtension = buttonAction.data
      ?.split('.')
      .pop();
    if (fileExtension === 'pdf') {
      window.open(buttonAction.data, '_blank');
      return;
    }
    return navigate(`/members/${url}`);
  };

  //Update new styles
  const textColorLookupTable: {
    [key: string]: Color;
  } = {
    'links-icon-leading': solidColors.upBlue,
    'links-icon-trailing': solidColors.upBlue,
    card_destructive_web: solidColors.lava,
    card_secondary_web: solidColors.upBlue,
    card_tertiary: solidColors.darkGray,
    destructive: solidColors.lava,
    icon: solidColors.darkGray,
    icon_leading: solidColors.midnight,
    icon_trailing: solidColors.midnight,
    lock: solidColors.upWhite,
    pill: solidColors.midnight,
    primary: solidColors.upWhite,
    secondary: solidColors.upBlue,
    tertiary: solidColors.midnight,
    text_with_icon_leading: solidColors.upBlue,
    white: solidColors.upWhite,
  };

  const getIconSrc = () => {
    if (image) {
      return (
        <ImageImgix
          src={image.url}
          alt="icon"
          height={iconHeight}
          width={iconWidth}
          filter={image.tintColor}
          {...imgixStyles}
        />
      );
    } else if (nativeIcon) {
      return nativeIcon;
    }
    return null;
  };

  const handleAction = (e: Event) => {
    if (buttonAction.actionAnalytics) {
      trackAnalytics(
        buttonAction.actionAnalytics as IAnalytics,
      );
    }

    if (buttonAction.actionMethod !== undefined) {
      buttonAction.actionMethod();
    } else {
      getServerAction({
        buttonAction,
        action,
        navigate,
        dispatch,
        title,
        mainPassenger,
        passengerList,
        selectedCredits,
        selectedPaymentMethods,
      });
    }
  };

  const disableButton = (
    disabledFlag: boolean,
    styleType?: CTAVariants,
  ) => {
    if (
      window.location.pathname === '/fly/checkout-flight'
    ) {
      if (disabledFlag || styleType === 'disabled') {
        return paymentLocation === 'checkWirePopUp' &&
          checkWireFlag
          ? false
          : true;
      }
    } else if (
      window.location.pathname ===
      '/upglobalresponse/payment'
    ) {
      if (disabledFlag || styleType === 'disabled') {
        return !(
          paymentLocation === 'payment' &&
          airmedLegalFlag.length === 3 &&
          selectedPaymentMethods.length >= 1
        );
      }
    } else {
      return disabledFlag || isAppLoading;
    }
  };

  const fixedStyleType =
    styleType === 'disabled' &&
    !disableButton(disabled || false, styleType)
      ? 'primary'
      : styleType;

  return (
    <ButtonStyled
      onMouseOver={() => setIsHovered(true)}
      onMouseOut={() => setIsHovered(false)}
      onClick={(e: Event) => {
        if (type === 'in_app_browser') {
          handleIframeClick();
        } else {
          handleAction(e);
        }
      }}
      startIcon={
        styleType === 'pill' ||
        styleType === 'icon_leading' ||
        styleType === 'lock' ||
        styleType === 'text_with_icon_leading' ||
        styleType === 'links-icon-leading'
          ? getIconSrc()
          : null
      }
      endIcon={
        styleType === 'lock' ||
        styleType === 'icon_leading' ||
        styleType === 'pill' ||
        styleType === 'text_with_icon_leading' ||
        styleType === 'links-icon-leading'
          ? null
          : getIconSrc()
      }
      width={width}
      height={height}
      radius={radius}
      data-testid={datatestid}
      $styleType={fixedStyleType}
      className={className}
      disabled={disableButton(disabled || false, styleType)}
      $isAppLoading={isAppLoading || false}
    >
      <div style={{width: '100%', height: '100%'}}>
        <ButtonTextStyled
          wrapperWidth={WidthOptions.INHERIT}
          variant={{
            ...(styleType === 'pill' ? typography.body1 : typography.cta),
          }}
          truncate={1}
          color={
            color || CtaVariantsColorTranslation[fixedStyleType]
          }
          $styleType={fixedStyleType}
          height={height}
          disabled={disableButton(
            disabled || false,
            styleType,
          )}
          align={textAlign}
          $isHovered={isHovered}
          libraryColor={(disableButton(
            disabled || false,
            styleType
          ) ? 'disabled' : undefined) as unknown as TypographyProps['color']}
        >
          {title}
        </ButtonTextStyled>
      </div>
    </ButtonStyled>
  );
}

const ButtonWrapper = ({
  action,
  title,
  image,
  imgixStyles,
  nativeIcon,
  styleType = 'primary',
  width,
  radius,
  height,
  className,
  disabled,
  datatestid,
  textAlign,
  iconWidth = 20,
  iconHeight = 20,
  textTransform = '',
}: IButton) => {
  return (
    <ProviderWrapper>
      <Button
        action={action}
        title={title}
        image={image}
        imgixStyles={imgixStyles}
        nativeIcon={nativeIcon}
        styleType={styleType}
        width={width}
        height={height}
        radius={radius}
        className={className}
        disabled={disabled}
        datatestid={datatestid}
        textAlign={textAlign}
        iconWidth={iconWidth}
        iconHeight={iconHeight}
        textTransform={textTransform}
      />
    </ProviderWrapper>
  );
};

export default ButtonWrapper;
