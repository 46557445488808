import styled from 'styled-components';

export const StyledTitle = styled.div`
  padding: '0px';
`;
export const StyledList = styled.ul`
  padding: 0px;
`;
export const StyledItem = styled.li`
  padding: '0px';
  margin: 0;
  list-style-type: none;
`;
