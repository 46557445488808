import { ActionMap, MainTypes } from './reducerUtils';

export type Background = {
  hasImage: boolean;
};

type BackgroundPayload = {
  [MainTypes.Update]: {
    hasImage: boolean;
  };
};

export type BackgroundActions = ActionMap<BackgroundPayload>[keyof ActionMap<BackgroundPayload>];

export const backgroundReducer = (
  backgroundState: Background,
  action: BackgroundActions,
) => {
  const actions = {
    [MainTypes.Update]: {
      ...backgroundState,
      hasImage: action.payload.hasImage,
    },
  };

  return actions[action.type];
};
