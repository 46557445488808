import { Typography } from '@atoms';
import { Grid } from '@components/library';
import { solidColors } from '@constants/styles/colors.constants';
import styled from 'styled-components';
import { IconButton } from '@library';
import { IStyledIcon } from '../AirportSearchListItem/AirportSearchListItem.interfaces';

export const StyledAutoCompleteItem = styled(Grid)<{
  $isTo: boolean;
  $isStack?: boolean;
}>`
  margin-left: ${({ $isTo }) => ($isTo ? '40px' : '0')};
  max-width: 275px;

  @media (max-width: 1164px) {
    max-width: 350px;
    margin-left: ${({ $isTo }) => ($isTo ? '20px' : '0')};
  }

  ${(props) => props.theme.breakpoints.down('sm')} {
    margin-left: 0;
  }
`;

export const StyledFlightSearchTitle = styled(Typography)`
  margin-top: 1rem;
  margin-bottom: 3rem;
  display: inline-block;
`;

export const StyledSearchFlightContainer = styled.div<{
  $bgColor?: string;
}>`
  background: ${(props) => props.$bgColor ?? 'inherit'};
  padding: 1rem;
`;

export const StyledAutoCompleteContainer = styled(Grid)`
  flex-direction: row;
  ${(props) => props.theme.breakpoints.down('sm')} {
    flex-direction: column;
  }
`;

export const IconContainer = styled.div`
  width: 50px;
  max-height: 80px;
  height: -webkit-fill-available;
  justify-content: center;
  display: flex;
  align-content: center;
  align-items: center;
`;

export const SwitchIconButton = styled(
  IconButton,
)<IStyledIcon>`
  border-radius: 0;
  background-repeat: no-repeat;
  background-size: contain;
  padding: 0;
`;

export const StyledSMiddleContainer = styled(Grid)`
  margin: 1.5rem 0 0.313rem 0;
  justify-content: center;
  display: flex;
  align-items: center;
  align-content: center;

  svg,
  img {
    width: 3rem;
  }
  svg {
    transform: rotate(90deg);
    transform-origin: center;
  }

  ${(props) => props.theme.breakpoints.down('lg')} {
    svg,
    img {
      width: 3rem;
    }
  }

  ${(props) => props.theme.breakpoints.down('sm')} {
    margin: 0.5rem 0 0.5rem 0;
    justify-content: left;
    align-items: left;
    align-content: left;
  }
`;

export const ContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
`;

export const SearchFlightContainer = styled.div`
  background: transparent;
  display: flex;
  flex-direction: column;
  height: auto;
  gap: 15px;
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
export const RowWrapper = styled.div<{ withGap?: boolean }>`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-wrap: nowrap;
  flex-direction: row;
  gap: 24px;

  ${(props) => props.theme.breakpoints.down('md')} {
    flex-wrap: wrap;
    flex-direction: column;
  }
`;

export const SubPagesWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
`;

export const StyledSubHeading = styled(Typography)`
  font-size: 16px;
  font-weight: 500;
`;

export const ErrorContainer = styled.div`
  display: flex;
`;

export const SpacingErrorWrapper = styled.div`
  padding-left: 100px;
`;

export const BorderBottom = styled.div<{
  disable?: boolean;
}>`
  border-bottom: solid 1px;
  border-color: ${({ disable }) =>
    disable
      ? `${solidColors.mediumGray.color}`
      : `${solidColors.midnight.color}`};
  height: 1px;
  display: flex;
  position: absolute;
  width: 100%;
  bottom: 0;
`;

export const InputWrapper = styled.div<{
  $fullWidth?: boolean;
}>`
  width: ${({ $fullWidth }) =>
    $fullWidth ? '100%' : 'auto'};

  > div {
    > div {
      input {
        width: 100%;
        min-width: initial;
        height: 62px;
      }
    }
  }

  ${(props) => props.theme.breakpoints.down('md')} {
    width: 100%;
    flex-wrap: wrap;
  }

  ${(props) => props.theme.breakpoints.down('sm')} {
    width: 100%;
    flex-wrap: wrap;
  }
`;

export const AutoContainer = styled.div`
  width: 50%;
  ${(props) => props.theme.breakpoints.down('md')} {
    width: 100%;
    flex-wrap: wrap;
  }
`;

export const LabelContainer = styled.div`
  padding: '40px 0px 20px 0px';
`;
export const SpaceContainer = styled.div`
  flex: 1;
`;

export const FlightNumberContainer = styled.div`
  display: flex;
  align-items: baseline;
`;

export const MultiFlightLabelContainer = styled.div`
  display: flex;
  align-items: center;
`;
