export const validateExpirationDate = (
  month: string,
  year: string,
) => {
  const currentMonth = new Date().getMonth() + 1;
  const inputMonth = parseInt(month, 10);
  const currentYear = new Date().getFullYear();
  const inputYear = parseInt(year, 10);

  if (isNaN(inputMonth) || isNaN(inputYear)) {
    return false;
  }

  let valid = false;
  if (inputYear > currentYear && inputMonth <= 12) {
    valid = true;
  } else if (
    inputYear === currentYear &&
    inputMonth >= currentMonth &&
    inputMonth <= 12
  ) {
    valid = true;
  }

  return valid;
};

export const validateInput = (
  errorList: string[],
  key: string,
  value: any,
) => {
  switch (key) {
    case 'firstname':
      if (value.length < 1) {
        return [...errorList, 'firstname'];
      } else {
        return errorList.filter(
          (value: string) => value !== 'firstname',
        );
      }
    case 'lastname':
      if (value.length < 1) {
        return [...errorList, 'lastname'];
      } else {
        return errorList.filter(
          (value: string) => value !== 'lastname',
        );
      }
    case 'address':
      if (value.length >= 2) {
        return errorList.filter(
          (value: string) => value !== 'address',
        );
      } else {
        return [...errorList, 'address'];
      }
    case 'city':
      if (value.length >= 2) {
        return errorList.filter(
          (value: string) => value !== 'city',
        );
      } else {
        return [...errorList, 'city'];
      }
    case 'state':
      if (value && value !== '') {
        return errorList.filter(
          (value: string) => value !== 'state',
        );
      } else {
        return [...errorList, 'state'];
      }
    case 'zipCode':
      if (value.length >= 5) {
        return errorList.filter(
          (value: string) => value !== 'zipCode',
        );
      } else {
        return [...errorList, 'zipCode'];
      }
    case 'creditCardNumber':
      if (value.length >= 9) {
        return errorList.filter(
          (value: string) => value !== 'creditCardNumber',
        );
      } else {
        return [...errorList, 'creditCardNumber'];
      }
    case 'creditCardExpiration':
      if (value.length >= 4) {
        const { month, year } = {
          month: value.slice(0, 2),
          year: value.slice(3),
        };
        if (validateExpirationDate(month, year)) {
          return errorList.filter(
            (value: string) =>
              value !== 'creditCardExpiration',
          );
        } else {
          return [...errorList, 'creditCardExpiration'];
        }
      } else {
        return [...errorList, 'creditCardExpiration'];
      }
    case 'cardCVV':
      if (value.length >= 3) {
        return errorList.filter(
          (value: string) => value !== 'cardCVV',
        );
      } else {
        return [...errorList, 'cardCVV'];
      }
    default:
      return [];
  }
};
