import { RootState } from '@app/store';
import { IButton } from '@atoms/Button/Button.interfaces';
import { IErrorPayload } from '@components/molecules/Error/ErrorSlice';
import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import {
  endpoints,
  getAuthHeaders,
  pegasusClient,
} from '@services/apiService';
import {
  IComponent,
  IPage,
} from '@services/dynamicRenderingService/dynamicRendering.interfaces';

export interface IMyTripsCancelState {
  isSelectFlightsPopupOpen: boolean;
  selectedFlights: string[];
  selectFlightsContent: IPage | undefined;
  confirmCancelContent: IPage | undefined;
  selectFlightsPopUpButtons: {
    primaryTitle: string;
    secondaryTitle: string;
  };
  isConfirmCancelPopupOpen: boolean;
  errorPayload?: IErrorPayload;
  tripId: string;
  cancelPostError: boolean;
  reloadFromCancel: boolean;
  successCancelSnackbar?: IComponent;
  isMultiLeg?: boolean;
  isLoading: boolean;
}

const initialState: IMyTripsCancelState = {
  isSelectFlightsPopupOpen: false,
  selectedFlights: [],
  selectFlightsContent: undefined,
  selectFlightsPopUpButtons: {
    primaryTitle: '',
    secondaryTitle: '',
  },
  isConfirmCancelPopupOpen: false,
  confirmCancelContent: undefined,
  tripId: '',
  isLoading: false,
  cancelPostError: false,
  reloadFromCancel: false,
};

interface IProps {
  urlParams?: string;
  queryParams?: string;
  token?: string;
  type?: string;
  resPayload?: unknown;
}

export const getCancelConfirmationPayload = createAsyncThunk(
  'myTripsCancel/fetchMyTripsCancelSelectionList',
  async (props: IProps) => {
    const { urlParams = '', queryParams = '' } = props;
    try {
      return (
        await pegasusClient.get(
          `${endpoints.cancelTripLegForm}${urlParams}?version=1&platform=web${queryParams}`,
          {
            headers: getAuthHeaders(),
          },
        )
      ).data;
    } catch (err) {
      return err;
    }
  },
);

export const postCancelLegPayload = createAsyncThunk(
  'myTripsCancel/fetchMyTripsCancelConfirmation',
  async (
    props: IProps & {
      reservationId: string;
      flights: string[];
    },
  ) => {
    const { reservationId, flights } = props;

    try {
      return (
        await pegasusClient.post(
          `${endpoints.cancelTripLegFee}?version=1&platform=web`,
          {
            reservationId: reservationId,
            flights: flights,
          },
          {
            headers: getAuthHeaders(),
          },
        )
      ).data;
    } catch (err) {
      return err;
    }
  },
);

export const postCancelFlightRequest = createAsyncThunk(
  'myTripsCancel/postCancelFlights',
  async (props: IProps, { getState }) => {
    const state = getState() as RootState;

    try {
      return (
        await pegasusClient.post(
          `${endpoints.cancelTrip}?version=1&platform=web`,
          {
            reservationId: state.myTripsCancel.tripId,
            flights: state.myTripsCancel.selectedFlights,
          },
          {
            headers: getAuthHeaders(),
          },
        )
      ).data;
    } catch (err) {
      return err;
    }
  },
);

const myTripCancelSlice = createSlice({
  name: 'flySearchTripDetailsComponents',
  initialState,
  reducers: {
    setIsSelectFlightsPopupOpen: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.isSelectFlightsPopupOpen = action.payload;
    },
    setIsConfirmCancelPopupOpen: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.isConfirmCancelPopupOpen = action.payload;
    },
    transitionCancelPopups: (state) => {
      state.isSelectFlightsPopupOpen = false;
      state.isConfirmCancelPopupOpen = true;
    },
    setSelectedFLights: (
      state,
      action: PayloadAction<string[]>,
    ) => {
      state.selectedFlights = action.payload;
    },
    setTripId: (state, action: PayloadAction<string>) => {
      state.tripId = action.payload;
    },
    setIsMultiLeg: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.isMultiLeg = action.payload;
    },
    setReloadFromCancel: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.reloadFromCancel = action.payload;
    },
    cleanState: (state) => {
      const auxTripId = state.tripId;
      state = {
        ...initialState,
        tripId: auxTripId,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getCancelConfirmationPayload.pending,
      (state) => {
        state.isLoading = true;
      },
    );
    builder.addCase(
      getCancelConfirmationPayload.fulfilled,
      (state, action) => {
        const { payload } = action;
        state.isLoading = false;
        state.selectFlightsContent = payload?.data;
        state.selectFlightsPopUpButtons.primaryTitle =
          (payload?.data?.metadata.stickyFooterContent
            ?.elements[0].data?.element?.data[
            'button'
          ] as IButton)?.title ?? '';
        state.selectFlightsPopUpButtons.secondaryTitle =
          (payload?.data?.metadata.stickyFooterContent
            ?.elements[1].data?.element?.data[
            'button'
          ] as IButton)?.title ?? '';
      },
    );
    builder.addCase(
      getCancelConfirmationPayload.rejected,
      (state) => {
        state.isLoading = false;
        state.cancelPostError = true;
      },
    );

    builder.addCase(
      postCancelLegPayload.pending,
      (state) => {
        state.isLoading = true;
      },
    );
    builder.addCase(
      postCancelLegPayload.fulfilled,
      (state, action) => {
        const { payload } = action;
        payload.data.elements[0].data.isModalView = true;
        state.confirmCancelContent = payload?.data;
        state.isLoading = false;
      },
    );
    builder.addCase(
      postCancelLegPayload.rejected,
      (state) => {
        state.isLoading = false;
        state.cancelPostError = true;
      },
    );

    builder.addCase(
      postCancelFlightRequest.pending,
      (state) => {
        state.isLoading = true;
      },
    );
    builder.addCase(
      postCancelFlightRequest.fulfilled,
      (state, action) => {
        const { payload } = action;
        if (payload?.response?.status === 400) {
          state.cancelPostError = true;
          state.errorPayload =
            payload.response.data.data.data;
        } else {
          state.isConfirmCancelPopupOpen = false;
          state.reloadFromCancel = true;
          state.successCancelSnackbar = payload.data;
        }
        state.isLoading = false;
      },
    );
    builder.addCase(
      postCancelFlightRequest.rejected,
      (state) => {
        state.isConfirmCancelPopupOpen = false;
        state.isLoading = false;
        state.cancelPostError = true;
      },
    );
  },
});

export const {
  setIsSelectFlightsPopupOpen,
  setIsConfirmCancelPopupOpen,
  transitionCancelPopups,
  setSelectedFLights,
  setTripId,
  setIsMultiLeg,
  setReloadFromCancel,
  cleanState,
} = myTripCancelSlice.actions;

export default myTripCancelSlice.reducer;
