import { useEffect } from 'react';

interface KeyCombination {
  keys: string[];
  ctrl?: boolean;
  alt?: boolean;
  shift?: boolean;
  meta?: boolean;
}

// Define the type for the callback function
type CallbackFunction = () => void;

const useKeyboardShortcut = (
  keyCombination: KeyCombination,
  callback: CallbackFunction
): void => {
  useEffect(() => {
    const handleKeydown = (event: KeyboardEvent) => {
      const { key, ctrlKey, altKey, shiftKey, metaKey } = event;
      const { keys, ctrl = false, alt = false, shift = false, meta = false } = keyCombination;

      if (
        keys.includes(key) &&
        ctrl === ctrlKey &&
        alt === altKey &&
        shift === shiftKey &&
        meta === metaKey
      ) {
        event.preventDefault();
        callback();
      }
    };

    document.addEventListener('keydown', handleKeydown);

    return () => {
      document.removeEventListener('keydown', handleKeydown);
    };
  }, [keyCombination, callback]);
};

export default useKeyboardShortcut;
