import { IHeadline } from './Headline.interfaces';
import { StyledHeadline } from './Headline.styles';
import { Typography } from '@atoms';
import { typography } from '@constants/styles/typography.constants';

export default function Headline({
  headline,
  align,
}: IHeadline) {
  return (
    <Typography
      variant={typography.body2Bold}
      align={align}
    >
      <StyledHeadline>{headline}</StyledHeadline>
    </Typography>
  );
}
