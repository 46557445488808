import { Typography } from '@atoms';
import styled from 'styled-components';

export const CardPetDetailsContainer = styled.div`
  width: 100%;
  height: auto;
  background-color: white;
  margin: 2rem 0rem;
  border-radius: 4px;
  padding: 40px;
`;

export const PetEdit = styled.div`
  display: flex;
  gap: 10px;
`;

export const TypographyStyled = styled(Typography)<{
  fontSize?: string;
}>`
  font-size: ${({ fontSize }) => fontSize ?? '24px'};
  font-weight: 500;
`;

export const TitleSubtitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  gap: 15px;
  min-width: 160px;
  ${(props) => props.theme.breakpoints.down('sm')} {
    min-width: 100%;
  }
`;

export const TitleSubtitleWrapper = styled.div`
  display: flex;
  max-width: 800px;
  flex-wrap: wrap;
  gap: 40px;
`;

export const InfoContainer = styled.div`
  min-width: 188px;
`;
