import { Typography } from '@atoms';
import { Icons } from '@components/library';
import { solidColors } from '@constants/styles/colors.constants';
import styled from 'styled-components';

export const ContainerStyled = styled.div`
  margin: 0 auto;
  margin-bottom: 40px;
  width: 100%;
`;

export const SwitchContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align: center;
  margin: 2.5rem auto 2.5rem auto;
  display: flex;
  justify-content: space-between;
`;

export const SwitchTypography = styled(Typography)`
  margin: auto 0;
`;

export const StyledCloseIcon = styled(Icons.Close)`
    color ${solidColors.upBlue.color};
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  border-bottom: 1px solid ${solidColors.mediumGray.color};
  margin-top: 40px;
`;
