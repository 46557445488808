import {
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import {
  IFlightLeg,
  IReservationType,
} from '@components/types';
import { IAirportItem } from '@components/molecules/FormFieldAirportSearchDashboard/SearchFlight.interfaces';
import { DateTime } from 'luxon';

interface IDateTracker {
  [key: number]: Date;
}

export interface INearbyAirportProps {
  type: string | undefined;
  data: IAirportItem[];
}

export interface ISelectFlightPayload {
  searchId: string;
  reservationType: IReservationType;
}
export interface IFlightLegs {
  legs: IFlightLeg[];
  clientId: string | null;
  searchId: string | null;
  reservationType: IReservationType | null;
  canValidate: boolean;
  errors: [];
  checkDate: IDateTracker;
  nearbyAirports: INearbyAirportProps;
  activeNearby: string;
  activeIndex: number;
  editSearchTab: string | null;
  airportError: boolean;
  selectedPrice?: string;
  shouldLoadPrice?: boolean;
}

export const getInitialLegDate = () => {
  const today = new Date();
  const nextDate = new Date(today);
  nextDate.setDate(nextDate.getDate() + 1);

  return nextDate.toISOString();
};

export const getUserLocalTime = () => {
  const currentDate = DateTime.now().toISODate();
  const currentDateTime = DateTime.now().toISOTime();

  let min = Number(currentDateTime.split(':')[0]);
  const secTemp = Number(currentDateTime.split(':')[1]);
  const changeMin = () => {
    min += 1;
    return '00';
  };
  const sec = Number(
    secTemp > 45
      ? changeMin()
      : secTemp > 30
      ? 45
      : secTemp > 15
      ? 30
      : secTemp > 0
      ? 15
      : '00',
  );
  const newDateTime = new Date(
    `${currentDate}T${min < 10 ? `0${min}` : min}:${
      sec < 10 ? `0${sec}` : sec
    }`,
  );
  return newDateTime;
};

export const initialNearbyAirports = {
  type: '',
  data: [],
};

export const initialState: IFlightLegs = {
  legs: [
    {
      id: '0',
      departureAirport: null,
      arrivalAirport: null,
      date: new Date(),
      departTime: getUserLocalTime(),
      flightType: '',
      tripTypeTitle: '',
      numberOfPassengers: 1,
      numberOfPets: 0,
      steps: 0,
      departureOpen: false,
      arrivalOpen: false,
      errors: [],
    },
  ],
  clientId: null,
  searchId: null,
  reservationType: null,
  errors: [],
  canValidate: false,
  checkDate: {},
  nearbyAirports: initialNearbyAirports,
  activeNearby: '',
  activeIndex: 0,
  editSearchTab: null,
  airportError: false,
};

export const searchFlightSlice = createSlice({
  name: 'searchFlight',
  initialState,
  reducers: {
    addLeg: (state, action: PayloadAction<IFlightLeg>) => {
      state.legs.push(action.payload);
    },
    addToCheckDate: (
      state,
      action: PayloadAction<IDateTracker>,
    ) => {
      state.checkDate = action.payload;
    },
    addClientId: (state, action: PayloadAction<string>) => {
      state.clientId = action.payload;
    },
    addTopLevelError: (
      state,
      action: PayloadAction<[]>,
    ) => {
      state.errors = action.payload;
    },
    addNearbyAirports: (
      state,
      action: PayloadAction<INearbyAirportProps>,
    ) => {
      state.nearbyAirports = action.payload;
    },
    addActiveNearby: (
      state,
      action: PayloadAction<string>,
    ) => {
      state.activeNearby = action.payload;
    },
    addActiveindex: (
      state,
      action: PayloadAction<number>,
    ) => {
      state.activeIndex = action.payload;
    },
    updateAirportError: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.airportError = action.payload;
    },
    updateEditSearchTab: (
      state,
      action: PayloadAction<string | null>,
    ) => {
      state.editSearchTab = action.payload;
    },
    removeLeg: (state, action: PayloadAction<number>) => {
      state.legs.splice(action.payload, 1);
    },
    updateLeg: (state, action: PayloadAction<any>) => {
      state.legs = action.payload;
      state.errors = [];
    },
    selectFlight: (
      state,
      action: PayloadAction<ISelectFlightPayload>,
    ) => {
      const data = action.payload;
      state.searchId = data.searchId;
      state.reservationType = data.reservationType;
    },
    updateSelectedPrice: (
      state,
      action: PayloadAction<string>,
    ) => {
      state.selectedPrice = action.payload;
    },
    updateShouldLoadPrice: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.shouldLoadPrice = action.payload;
    },
  },
  extraReducers: {},
});

export const {
  addLeg,
  removeLeg,
  updateLeg,
  selectFlight,
  addClientId,
  addToCheckDate,
  addTopLevelError,
  addNearbyAirports,
  addActiveNearby,
  addActiveindex,
  updateEditSearchTab,
  updateAirportError,
  updateSelectedPrice,
  updateShouldLoadPrice,
} = searchFlightSlice.actions;

export const SearchFlightReducer =
  searchFlightSlice.reducer;

export default SearchFlightReducer;
