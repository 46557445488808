import { Button, Typography } from '@atoms';
import { Grid } from '@components/library';
import styled from 'styled-components';

export const StyledAutoCompleteItem = styled(Grid)<{
  $isTo: boolean;
  $isStack?: boolean;
}>`
  margin-left: ${({ $isTo }) => ($isTo ? '40px' : '0')};
  max-width: 370px;

  @media (max-width: 1164px) {
    max-width: 350px;
    margin-left: ${({ $isTo }) => ($isTo ? '20px' : '0')};
  }

  ${(props) => props.theme.breakpoints.down('sm')} {
    margin-left: 0;
  }
`;

export const StyledFlightSearchTitle = styled(Typography)`
  margin-top: 1rem;
  margin-bottom: 3rem;
  display: inline-block;
`;

export const StyledSearchFlightContainer = styled.div<{
  $bgColor?: string;
}>`
  background: ${(props) => props.$bgColor ?? 'inherit'};
  padding: 1rem;
`;

export const StyledAutoCompleteContainer = styled(Grid)`
  flex-direction: row;
  ${(props) => props.theme.breakpoints.down('sm')} {
    flex-direction: column;
  }
`;

export const StyledSMiddleContainer = styled(Grid)`
  margin: 35px 0 30px 0;
  justify-content: center;
  display: flex;
  align-items: center;
  align-content: center;

  svg,
  img {
    width: 5rem;
  }
  svg {
    transform: rotate(90deg);
    transform-origin: center;
  }

  ${(props) => props.theme.breakpoints.down('lg')} {
    svg,
    img {
      width: 2rem;
    }
  }

  ${(props) => props.theme.breakpoints.down('sm')} {
    margin: 1.5rem 0 0.313rem 0;
    justify-content: left;
    align-items: left;
    align-content: left;
  }
`;

export const StyledSubmitFlightSearch = styled(Button)`
  text-align: center;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 1.86px;
  line-height: 15px;
  margin-bottom: 2rem;
  margin-top: 2rem;
  height: 60px;
  width: 340px;

  svg,
  img {
    width: 1rem;
    margin-right: 0.5rem;
  }
`;
