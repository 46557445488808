import styled from 'styled-components';
import { solidColors } from '@constants/styles/colors.constants';
import { Elevation } from '@wheelsup/wu-react-components';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 12px 24px 40px;

  @media (max-width: 600px) {
    flex-direction: column;
    padding: 0 24px;
  }
`;

export const FlightStatus = styled.div<{
  $backgroundColor?: string;
}>`
  width: fit-content;
  height: 22px;
  padding: 0 1.25rem 0 1rem;
  background-color: ${({ $backgroundColor }) =>
    $backgroundColor
      ? $backgroundColor
      : solidColors.midnight.color};
  border-radius: 0 0 75px 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TopSectionContainer = styled.div`
  width: max-content;
  display: flex;
  margin: 15px 0 10px 40px;
  @media (max-width: 600px) {
    margin: 10px 0 5px 24px;
  }
`;

export const StyledFlightCard = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: fit-content;
  width: 100%;
  max-width: 1088px;
  border-radius: 8px;
  overflow: hidden;
`;

export const ButtonContainer = styled.div`
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 600px) {
    // height: 100%;
  }
`;

export const FlightTripSectionContainer = styled.div`
  max-width: 330px;
  @media (max-width: 600px) {
    max-width: 100%;
  }
`;
