import React from 'react';
import {
  ICardPastFlight,
  IItemObj,
} from './CardPastFlight.interfaces';
import {
  StyledContainer,
  TopSectionContainer,
  SmallDivider,
  LargeDivider,
  FlightStatus,
  IconText,
  AircraftContainer,
  AircraftTextContainer,
  ButtonContainer,
  StyledFlightCardDesktop,
  ButtonContainerMobile,
  StyledElevation,
} from './CardPastFlight.styles';
import { Typography, ImageImgix, Button } from '@atoms';
import { typography } from '@constants/styles/typography.constants';
import { solidColors } from '@constants/styles/colors.constants';
import { useNavigate } from 'react-router-dom';
import { IFlightLeg } from '@components/types';
import {
  getUserLocalTime,
  updateEditSearchTab,
  updateLeg,
} from '@features/Fly/searchFlightSlice';
import { useDispatch } from 'react-redux';
import FlightTripSection from '../FlightTripSection/FlightTripSection';
import FlightItemsSection from '../FlightItemsSection/FlightItemsSection';
import { useViewport } from '@hooks';

const CardPastFlightDesktop = ({
  id,
  flight,
  flightStatus,
  flightStatusFillColor,
  aircraftTypeLine1,
  aircraftTypeLine2,
  peakDayStatus,
  items,
  centerIconImage,
  button,
  surfaceAction,
}: ICardPastFlight) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { width } = useViewport();
  const itemsObj: IItemObj = {};
  const aircraftImage =
    flight.aircraft && flight.aircraft.image
      ? flight.aircraft.image.url
      : '';
  const aircraftImageTint =
    flight.aircraft && flight.aircraft.image
      ? flight.aircraft.image.tintColor
      : '';
  items.map(
    (item) =>
      (itemsObj[item.type] = {
        title: item.title,
        copy: item.copy,
        image: item.image,
        isBlue: false,
      }),
  );

  if (!flight || !items) {
    return null;
  }

  const handleOnClick = (_event: React.MouseEvent) => {
    _event.stopPropagation();
    const flightLeg: IFlightLeg = {
      id: '0',
      departureAirport: flight.fromAirport,
      arrivalAirport: flight.toAirport,
      flightType: 'one-way',
      steps: 3,
      date: new Date(),
      departTime: getUserLocalTime(),
      errors: [],
      numberOfPassengers: 1,
      numberOfPets: 0,
    };
    dispatch(updateLeg([flightLeg]));
    dispatch(updateEditSearchTab('one-way'));
    navigate('/fly');
  };

  return (
    <StyledElevation>
      <StyledFlightCardDesktop
        data-name="CardPastFlight"
        className="CardPastFlight"
        onClick={handleOnClick}
      >
        <FlightStatus
          $backgroundColor={
            flightStatusFillColor
              ? solidColors[flightStatusFillColor].color
              : ''
          }
        >
          {flightStatus && (
            <Typography
              variant={typography.captionB}
              libraryColor="interactivePrimary"
            >
              {flightStatus}
            </Typography>
          )}
        </FlightStatus>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <TopSectionContainer>
            <Typography
              variant={typography.body2a}
              color={solidColors.c6}
            >
              {flight.departureDateDisplayValue}
            </Typography>
            <SmallDivider />
            {peakDayStatus && (
              <>
                <Typography
                  variant={typography.body2a}
                  color={solidColors.c6}
                >
                  {peakDayStatus}
                </Typography>
                <SmallDivider />
              </>
            )}
            {itemsObj['tailNumber'].image && (
              <ImageImgix
                src={
                  itemsObj['tailNumber'] &&
                  itemsObj['tailNumber'].image
                    ? itemsObj['tailNumber'].image.url
                    : ''
                }
                alt="icon"
                width={20}
                height={20}
                filter={
                  itemsObj['tailNumber'] &&
                  itemsObj['tailNumber'].image
                    ? itemsObj['tailNumber'].image.tintColor
                    : 'darkGray'
                }
              />
            )}
            <IconText
              variant={typography.body2a}
              color={solidColors.c6}
            >
              {itemsObj['tailNumber']
                ? itemsObj['tailNumber'].copy
                : ''}
            </IconText>
          </TopSectionContainer>
          <StyledContainer>
            <FlightTripSection
              {...flight}
              centerIconImage={centerIconImage}
              isBlue={false}
            />
            {width > 960 && (
              <FlightItemsSection {...itemsObj} />
            )}
            {width > 600 && (
              <AircraftContainer>
                <ImageImgix
                  alt="aircraft"
                  src={aircraftImage}
                  filter={aircraftImageTint}
                  width={103}
                  height={37}
                  opacity={0.65}
                />
                <AircraftTextContainer>
                  <Typography
                    variant={typography.body2a}
                    libraryColor="disabled"
                  >
                    {aircraftTypeLine1}
                  </Typography>
                  <Typography
                    variant={typography.body2a}
                    libraryColor="disabled"
                  >
                    {aircraftTypeLine2}
                  </Typography>
                </AircraftTextContainer>
              </AircraftContainer>
            )}
          </StyledContainer>
        </div>
        <ButtonContainer onClick={handleOnClick}>
          <Button
            title={button.title}
            styleType="card_secondary_web"
            action={{
              actionMethod: () => null,
              ...button.action,
            }}
            image={button.image}
          />
        </ButtonContainer>
      </StyledFlightCardDesktop>
    </StyledElevation>
  );
};

export default CardPastFlightDesktop;
