import { ISubcopy } from './Subcopy.interfaces';
import { StyledSubcopy } from './Subcopy.styles';
import { Typography } from '@atoms';
import { typography } from '@constants/styles/typography.constants';

export default function Subcopy({ copy, align }: ISubcopy) {
  return (
    <Typography
      variant={typography.body2}
      align={align}
    >
      <StyledSubcopy>{copy}</StyledSubcopy>
    </Typography>
  );
}
