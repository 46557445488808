import { typography } from '@constants/styles/typography.constants';
import { Button, Typography } from '@atoms';
import {
  LineItem,
  TextGroupCheckboxBodyCopyLink,
  TextGroupSingleTextGroup,
} from '@molecules';
import {
  StyledDivision,
  StyledCard,
  StyledTitleContainer,
  StyledHoldContainer,
  StyledPaymentRulesContainer,
  StyledButton,
} from './CardPriceBreakdown.styles';
import { ICardPriceBreakdown } from './CardPriceBreakdown.interfaces';
import { ISingleTextGroup } from '@molecules/TextGroupSingleTextGroup/TextGroupSingleTextGroup.interfaces';
import { ILineItem } from '@molecules/LineItem/LineItem.interfaces';
import { ActionTypes } from '@atoms/Action/Action.interfaces';
import {
  confirmPurchaseRequest,
  setLegalCheckboxFlag,
} from '@features/Fly/Payment/PaymentStatusSlice';
import { useAppSelector } from '@app/hooks';
import { useDispatch } from 'react-redux';
import { getFromSessionStorage } from '@components/utils/storage';
import { getAccessToken } from '@services/tokenService/token';
import { IPassengerPayment } from '@components/types';

const CardPriceBreakdown = ({
  title,
  lineItems,
  priceItems,
  paymentItemsTitle,
  paymentItems,
  holdItems,
  paymentRulesTitle,
  paymentRulesCopy,
  legalCheckbox,
  applyPaymentButton,
}: ICardPriceBreakdown) => {
  const dispatch = useDispatch();

  const {
    paymentLocation,
    totalCostValue,
    selectedCredits,
    selectedPaymentMethods,
    legalCheckboxFlag,
  } = useAppSelector((state) => state.paymentStatus);

  const handleButton = (type: ActionTypes) => {
    if (
      type === 'page_submit' &&
      paymentLocation === 'payment'
    ) {
      const bookingFlow = getFromSessionStorage(
        'flightPurchaseCheckout',
      );
      const newBookingRequestLegDetails = bookingFlow.bookingRequestLegDetails.map(
        (leg: any) => {
          const newPassengers = leg.passengers.map(
            (passenger: any) => {
              if (passenger.pet) {
                return {
                  id: passenger.id,
                  firstName: passenger.name,
                  isPet: true,
                  isLead: false,
                } as IPassengerPayment;
              } else {
                return {
                  id: passenger.id,
                  firstName: passenger.firstName,
                  lastName: passenger.lastName,
                  isLead: passenger.isLeadPassenger,
                  phone: passenger.phone,
                  email: passenger.email,
                  isPet: false,
                } as IPassengerPayment;
              }
            },
          );

          return {
            ...leg,
            passengers: newPassengers,
          };
        },
      );
      dispatch(
        confirmPurchaseRequest({
          token: getAccessToken(),
          resPayload: {
            ...{
              ...bookingFlow,
              bookingRequestLegDetails: newBookingRequestLegDetails,
            },
            selectedPayments: [
              ...selectedCredits,
              ...selectedPaymentMethods,
            ],
          },
        }),
      );
    }
  };

  const handleLegalCheckboxFlag = (
    legalCheckboxFlag: boolean,
  ) => {
    dispatch(setLegalCheckboxFlag(legalCheckboxFlag));
  };

  const disablePaymentButton = () => {
    return !(
      (selectedCredits.length > 0 ||
        selectedPaymentMethods.length > 0) &&
      legalCheckboxFlag
    );
  };

  return (
    <StyledCard data-name="CardPriceBreakdown">
      <StyledTitleContainer>
        <Typography variant={typography.heading03Large}>
          {title}
        </Typography>
      </StyledTitleContainer>

      {lineItems &&
        lineItems.map((lineItem: ILineItem, index) => (
          <LineItem
            key={index}
            leftItem={lineItem.leftItem}
            rightItem={lineItem.rightItem}
          />
        ))}
      <StyledDivision />
      {priceItems &&
        priceItems.map((priceItem: ILineItem, index) => (
          <LineItem
            key={index}
            leftItem={priceItem.leftItem}
            rightItem={priceItem.rightItem}
          />
        ))}
      {paymentItemsTitle && (
        <StyledTitleContainer>
          <Typography variant={typography.heading03Large}>
            {paymentItemsTitle}
          </Typography>
        </StyledTitleContainer>
      )}
      {paymentItems &&
        paymentItems.map(
          (paymentItem: ILineItem, index) => (
            <LineItem
              key={index}
              leftItem={paymentItem.leftItem}
              rightItem={paymentItem.rightItem}
            />
          ),
        )}
      <StyledHoldContainer>
        {holdItems &&
          holdItems.map(
            (holdItems: ISingleTextGroup, index) => (
              <TextGroupSingleTextGroup
                key={index}
                title={holdItems.title}
                styleType={holdItems.styleType}
                alignmentState={holdItems.alignmentState}
              />
            ),
          )}
      </StyledHoldContainer>
      <StyledPaymentRulesContainer>
        {paymentRulesTitle && (
          <TextGroupSingleTextGroup
            title={paymentRulesTitle.title}
            styleType={paymentRulesTitle.styleType}
            alignmentState={
              paymentRulesTitle.alignmentState
            }
          />
        )}
        {paymentRulesCopy && (
          <TextGroupSingleTextGroup
            title={paymentRulesCopy.title}
            styleType={paymentRulesCopy.styleType}
            alignmentState={paymentRulesCopy.alignmentState}
          />
        )}
        {legalCheckbox && (
          <TextGroupCheckboxBodyCopyLink
            titleTextGroup={
              legalCheckbox.data.titleTextGroup
            }
            isPreselected={legalCheckboxFlag}
            onChange={() =>
              handleLegalCheckboxFlag(!legalCheckboxFlag)
            }
          />
        )}
        {applyPaymentButton && (
          <StyledButton
            title={applyPaymentButton.title}
            action={{
              actionMethod: () => {
                handleButton(
                  applyPaymentButton.action.type ||
                    'page_back',
                );
              },
            }}
            styleType={'primary'}
            disabled={disablePaymentButton()}
          />
        )}
      </StyledPaymentRulesContainer>
    </StyledCard>
  );
};

export default CardPriceBreakdown;
