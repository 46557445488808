import { Button, Typography } from '@atoms';
import {
  WrapperContainer,
  IconsStyled,
  StyledContainer,
  StyledInfo,
  StyledMedia,
} from './ContainerHeadlineSubCopyImageCTAHorizontalWEBONLY.styles';
import { IProps } from './ContainerHeadlineSubCopyImageCTAHorizontalWEBONLY.interfaces';
import { typography } from '@constants/styles/typography.constants';
import { solidColors } from '@constants/styles/colors.constants';
import Carousel from './Carousel/Carousel';
import Spacer from '@atoms/Spacer/Spacer';

const ContainerHeadlineSubCopyImageCTAHorizontalWEBONLY = ({
  headline,
  subCopy,
  background,
  spacerTypeTop,
  spacerTypeBottom,
  canContainDismissable,
  button,
  children,
}: IProps) => {
  // Using below to bypass Athena's required CarouselCarouselArrowWEBONLY.
  // Data should be equal to the progressive profiling cards or anything else
  // used as children for this container.
  const data = children?.props.children;
  const color =
    background === 'blue'
      ? solidColors.upWhite
      : solidColors.darkGray;

  return (
    <WrapperContainer
      data-name="ContainerHeadlineSubCopyImageCTAHorizontalWEBONLY"
      background={background}
    >
      {spacerTypeTop && <Spacer height={spacerTypeTop} />}
      <StyledContainer>
        <StyledInfo data-name="StyledInfo">
          <Typography
            variant={typography.heading01Large}
            color={color}
            className="headline"
          >
            {headline}
          </Typography>
          <Typography
            variant={typography.body1}
            color={color}
            className="subCopy"
          >
            {subCopy}
          </Typography>
          <Button
            title={button?.title}
            styleType={button?.styleType}
            action={{
              actionMethod: button?.action?.actionMethod,
            }}
            nativeIcon={<IconsStyled />}
            className={button?.className}
          />
        </StyledInfo>
        {data && (
          <StyledMedia data-name="StyledMedia-CarouselContainer">
            <Carousel>{data}</Carousel>
          </StyledMedia>
        )}
      </StyledContainer>
      {spacerTypeBottom && (
        <Spacer height={spacerTypeBottom} />
      )}
    </WrapperContainer>
  );
};

export default ContainerHeadlineSubCopyImageCTAHorizontalWEBONLY;
