/**
 * Takes in a string input and validates the integer is 0 or greater
 * @param input string
 * @returns true if the input is a integer 0 or greater, false otherwise
 */
export const isPositiveInteger = (
    input: string,
): boolean => {
    const pattern = /^[0-9]+$/;

    if (!pattern.test(input)) {
        return false; // Input contains non-numeric characters
    }

    const numericValue: number = parseInt(input, 10);

    if (isNaN(numericValue) || numericValue < 0) {
        return false; // Input is not a positive number
    }

    return true; // Input is a positive number
};
