import {
  isAuthenticated,
  refresh,
} from '@services/authService/authService';

export default function checkRefreshTokenExpiration() {
  {
    /*
        This function polls local storage to check if we have a valid refresh token expiration date.
        If a datetime strign is found, set a timer to refresh the token in the background 10 minutes
        before it expires.
    */
  }
  const expireString = localStorage.getItem(
    'refresh_token_expires',
  );
  const expiresDate =
    expireString && new Date(expireString);
  const auth = isAuthenticated();
  if (!auth) {
    setTimeout(() => {
      checkRefreshTokenExpiration();
    }, 1000 * 60 * 60); // poll local storage once an hour
  } else if (expiresDate) {
    const timeToRefresh =
      expiresDate.getTime() -
      new Date().getTime() -
      1000 * 60 * 10; // curr time left - 10 mimnutes
    timeToRefresh > 0 &&
      setTimeout(async () => {
        await refresh(false);
        checkRefreshTokenExpiration();
      }, timeToRefresh);
  }
}
