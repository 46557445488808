import styled from 'styled-components';

export const CardStyled = styled.div<{
  $maxWidth: string;
}>`
  padding: 1.5rem 2rem;
  margin-bottom: 2rem;
  border-radius: 8px;
  max-width: ${({ $maxWidth }) => $maxWidth};
`;

export const HeadingStyled = styled.div`
  margin-bottom: 0.5rem;
`;
