import React, { useLayoutEffect, useState } from 'react';
import { ImageImgix, Typography } from '@atoms';
import { typography } from '@constants/styles/typography.constants';
import { ICardOversized } from './CardHeadlinesubcopyCTAImageOversizedWEBONLY.interfaces';
import {
  CardBody,
  CardContainer,
  CardOversizedWrapper,
  FirstSideCard,
  OversizedSideCard,
  ResponsiveButton,
  TextContainer,
} from './CardHeadlinesubcopyCTAImageOversizedWEBONLY.styles';
import { useAppSelector } from '@app/hooks';
import MemberTabPopup from '@molecules/MemberTabPopup/MemberTabPopup';
import { useViewport } from '@hooks';
import { IImgixFit } from '@services/imgix/imgix.interfaces';
import { Elevation } from '@wheelsup/wu-react-components';

const CardHeadlinesubcopyCTAImageOversizedWEBONLY = ({
  headline,
  copy,
  image,
  button,
}: ICardOversized) => {
  const { width } = useViewport();
  const containerRef = React.useRef<HTMLDivElement>(null);
  const [styleOverrides, setStyleOverrides] = useState(
    false,
  );
  const type: any = button?.action?.type;
  const { isButtonPopupOpen } = useAppSelector(
    (state) => state.popupOpen,
  );
  const [imageWidth, setImageWidth] = useState(0);

  // takes a /src/client/inspirato/whatever/fix
  // returns inspirato*whatever*fix

  const splitUrl = button?.action?.data?.split(
    'src/client/',
  )[1];

  useLayoutEffect(() => {
    const imageContainer = document.querySelector<HTMLElement>(
      '.OversizedSideCard',
    );
    if (
      containerRef.current?.parentElement?.dataset.id ===
      'CarouselSmallArrowWebOnlySlide-slide'
    ) {
      setStyleOverrides(true);
      if (width < 960) {
        imageContainer &&
          setImageWidth(imageContainer.offsetWidth);
      }
    } else {
      setStyleOverrides(false);
      width < 960 &&
        imageContainer &&
        setImageWidth(imageContainer.offsetWidth);
    }
  }, [width]);

  const cardImageProps = {
    fit: 'crop' as IImgixFit,
    src: image.url,
    filter: image.tintColor,
    alt: 'Oversized Image',
    borderRadius: '0',
    width: width > 960 ? 650 : imageWidth,
    height: width > 960 ? 500 : 320,
  };

  return (
    <Elevation variant="raisedLevel2" padding={0}>
      <CardOversizedWrapper
        $styleOverrides={styleOverrides}
        ref={containerRef}
        data-name="CardHeadlinesubcopyCTAImageOversizedWEBONLY"
      >
        {isButtonPopupOpen && <MemberTabPopup />}
        <FirstSideCard
          $mobileWidth={containerRef.current?.offsetWidth}
        >
          <CardContainer>
            <TextContainer>
              <Typography
                variant={typography.heading03Large}
              >
                {headline}
              </Typography>
              <CardBody variant={typography.body1}>
                {copy}
              </CardBody>
            </TextContainer>

            {button && (
              <ResponsiveButton
                styleType={button?.styleType}
                title={button?.title}
                {...button}
              />
            )}
          </CardContainer>
        </FirstSideCard>
        <OversizedSideCard className="OversizedSideCard">
          <ImageImgix {...cardImageProps} />
        </OversizedSideCard>
      </CardOversizedWrapper>
    </Elevation>
  );
};

export default CardHeadlinesubcopyCTAImageOversizedWEBONLY;
