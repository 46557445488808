import { ICardTextGroupIconBody } from './CardTextGroupIconBody.interfaces';
import {
  StyledCard,
  StyledTitleContainer,
  StyledSingleTextGroup,
  StyledTitle,
} from './CardTextGroupIconBody.styles';
import { typography } from '@constants/styles/typography.constants';
import { solidColors } from '@constants/styles/colors.constants';
import TextGroupIconBody from '@molecules/TextGroupIconBody/TextGroupIconBody';
import { ITextGroupIconBody } from '@molecules/TextGroupIconBody/TextGroupIconBody.interfaces';

const CardTextGroupIconBody = ({
  title,
  textGroups,
}: ICardTextGroupIconBody) => {
  return (
    <StyledCard>
      {title && (
        <StyledTitleContainer>
          <StyledTitle
            variant={typography.heading03Large}
            color={solidColors.midnight}
          >
            {title}
          </StyledTitle>
        </StyledTitleContainer>
      )}
      <StyledSingleTextGroup>
        {textGroups &&
          textGroups.map(
            (TextGroup: ITextGroupIconBody, index) => (
              <TextGroupIconBody
                key={index}
                type={TextGroup.type}
                image={TextGroup.image}
                titleTextView={TextGroup.titleTextView}
                bodyTextView={TextGroup.bodyTextView}
              />
            ),
          )}
      </StyledSingleTextGroup>
    </StyledCard>
  );
};

export default CardTextGroupIconBody;
