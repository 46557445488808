import styled from 'styled-components';
import { solidColors } from '@constants/styles/colors.constants';
import { makeStyles } from '@components/library';

export const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${solidColors.upWhite.color};
  max-width: 730px;
  max-height: 929px;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  overflow: hidden;
`;

export const StyledBreak = styled.div`
  width: 100%;
  margin: 24px 0;
  border-bottom: 1px solid ${solidColors.mediumGray.color};
`;

export const CardBody = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 24px 32px 24px;
  display: flex;
  flex-direction: column;
`;

export const TextContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const ItemsContainer = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  margin-top: 16px;
  justify-content: space-between;
`;

export const MobileItemsContainer = styled.div`
  max-width: 280px;
  margin-right: 24px;
`;

export const MobileItemContainer = styled.div`
  :not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const useStyles = makeStyles(() => ({
  root: {
    borderRadius: '8px',
    boxShadow: '0 2px 4px 1px rgba(0, 0, 0, 0.12)',
  },
}));
