import styled from 'styled-components';

export const StyledCard = styled.div`
  width: 100%;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05);
  position: relative;
  height: fit-content;
`;

export const CardContent = styled.div`
  padding: 25px 20px;
`;
