import InputBase from '@atoms/InputBase';
import { Grid } from '@components/library';
import styled from 'styled-components';

export const GridStyled = styled(Grid)`
  margin: 1.5rem 0 1.5rem 0;
`;

export const InputBaseStyled = styled(InputBase)`
  width: 100%;
`;

export const StyledContainer = styled.div`
  display: flex;
`;

export const DropdownContainer = styled.div`
  width: 100%;
  max-width: 286px;
  margin: 24px 0;
  
  ${(props) => props.theme.breakpoints.down('sm')} {
    max-width: none;
  }
`;
