import { formatDisplayDate } from '@components/molecules/DateSection/CalendarPricing/CalendarPricingRoundTrip';
import { flightTypeMap,singleTripRequest, roundTripRequest } from '@components/molecules/SubPageFlightSearchV2/FlightSearchContext/FlightSearchContext';
import { endOfNextMonth } from '@components/molecules/DateSection/dateHelper';
import {
  FlightSearchObjType,
  FlightType,
  flightTypes,
} from '@components/molecules/SubPageFlightSearchV2/FlightSearchContext/FlightSearchContext.types';
import { Action } from '@components/molecules/SubPageFlightSearchV2/FlightSearchContext/FlightSearchContext.types';
import { IKeyValue } from '@components/types';
import {
  endpoints,
  getAuthHeaders,
  pegasusClient,
} from '@services/apiService';
import { IAnalytics } from '@services/dynamicRenderingService/dynamicRendering.interfaces';
import { trackAnalytics } from '@services/mixpanelService/mixpanel.service';
import { DateTime } from 'luxon';

export const isFlightType = (
  input: string | null,
): input is FlightType => {
  if (input == null) {
    return false;
  }

  return input.toLowerCase() in flightTypeMap;
};

export const capitalizeFirstLetter = (
  input: string,
): string => {
  return input.charAt(0).toUpperCase() + input.slice(1);
};

export const createDateFromFormat = (
  dateString: string | null,
): Date | null => {
  if (dateString === null) {
    return null;
  }

  const dateComponents = dateString.split('-').map(Number);

  // Check if the format is correct (YYYY-MM-DD)
  if (
    dateComponents.length === 3 &&
    !isNaN(dateComponents[0]) &&
    !isNaN(dateComponents[1]) &&
    !isNaN(dateComponents[2])
  ) {
    // Adjust month value (months in JavaScript are 0-indexed)
    dateComponents[1]--;

    return new Date(
      dateComponents[0],
      dateComponents[1],
      dateComponents[2],
    );
  } else {
    // Return null for invalid formats
    return null;
  }
};

export const createTimeFromFormat = (
  timeString: string | null,
): Date | null => {
  if (timeString === null) {
    return null;
  }

  const [hours, minutes] = timeString
    .split(':')
    .map(Number);

  // Check if the format is correct (HH:mm:ss)
  if (
    !isNaN(hours) &&
    !isNaN(minutes) &&
    hours >= 0 &&
    hours < 24 &&
    minutes >= 0 &&
    minutes < 60
  ) {
    const currentDate = new Date();
    currentDate.setHours(hours, minutes, 0, 0);
    return currentDate;
  } else {
    // Return null for invalid formats
    return null;
  }
};

export async function getAirportDetails(
  matchString: string,
) {
  const headers = getAuthHeaders();
  const endpoint = `${endpoints.airportSearch}&matcher=${matchString}`;
  const airportDetails = await pegasusClient.get(endpoint, {
    headers: headers,
  });
  return airportDetails.data.data.results[0];
}

export function trackPredefinedSearch(
  searchParams: URLSearchParams
): void {
  // Log all of the search params to mixpanel
  const mixpanelProps: IKeyValue[] = [];
  for (const [key, value] of searchParams.entries()) {
    mixpanelProps.push({ key, value });
  }
  trackAnalytics({
    trackingTitle: 'Did Select Prefill Link',
    properties: mixpanelProps
  } as IAnalytics);
}

export function getRoundTripDays(
  departDate: Date,
  returnDate: Date
): number {
  // Number of milliseconds per day
  const msPerDay = 24 * 60 * 60 * 1000; 

  if (!!departDate && !!returnDate) {
    // Difference in milliseconds
    const differenceInMs = returnDate.getTime() - departDate.getTime(); 
    return Math.round(differenceInMs / msPerDay);
  }
  return roundTripRequest.roundTripDays;
}

export async function executePredefinedSearch(
  searchParams: URLSearchParams,
  contextDispatch: React.Dispatch<Action>,
  setHasRetrievedDeal: React.Dispatch<
    React.SetStateAction<boolean>
  >,
) {
  // Track that the user has interacted with the predefined url in Mixpanel
  trackPredefinedSearch(searchParams);

  const tripType = searchParams.get('tripType');
  const flightType = isFlightType(tripType)
    ? flightTypeMap[tripType]
    : flightTypes.oneWay;
  const startDepartDate = createDateFromFormat(
    searchParams.get('departDate'),
  );
  const startReturnDate = createDateFromFormat(
    searchParams.get('returnDate'),
  );
  const startDepartTime =
    createTimeFromFormat(searchParams.get('departTime')) ||
    DateTime.now()
      .startOf('day')
      .plus({ hours: 9 })
      .toJSDate();
  const startReturnTime =
    createTimeFromFormat(searchParams.get('returnTime')) ||
    DateTime.now()
      .startOf('day')
      .plus({ hours: 9 })
      .toJSDate();

  const departAirport = searchParams.get('fromAirport');
  const arriveAirport = searchParams.get('toAirport');
  const departureAirport = departAirport
    ? await getAirportDetails(departAirport)
    : null;
  const arrivalAirport = arriveAirport
    ? await getAirportDetails(arriveAirport)
    : null;

  const firstLeg: FlightSearchObjType = {
    departureAirport: departureAirport,
    arrivalAirport: arrivalAirport,
    date: startDepartDate,
    departTime: startDepartTime,
    numberOfPassengers:
      Number(searchParams.get('departPassengers')) || 1,
    numberOfPets:
      Number(searchParams.get('departPets')) || 0,
  };

  const endDate = endOfNextMonth(startDepartDate ? startDepartDate : new Date());
  const pricingRequest = {
    startDate: startDepartDate ? startDepartDate : new Date(),
    endDate,
    arrivalAirport: Number(arrivalAirport.id) ?? 1,
    departureAirport: Number(departureAirport.id) ?? 1,
  };

  switch (flightType) {
    case flightTypes.oneWay: {
      // If you don't have a depart date, just open the calendar
      const shouldOnlyOpenCalendar = !startDepartDate ? true : false;
      contextDispatch({
        type: 'updateFromURL',
        payload: {
          legs: [{
            ...firstLeg,
            dateOpen: shouldOnlyOpenCalendar
          }],
          flightType: flightType,
          displayDate: startDepartDate
            ? `${formatDisplayDate(startDepartDate)}`
            : '',
          pricingRequest,
        },
      });
      if (!shouldOnlyOpenCalendar) {
        // Trigger the flight search
        setHasRetrievedDeal(true);
      }
      break;
    }
    case flightTypes.roundTrip: {
      const secondLeg: FlightSearchObjType = {
        departureAirport: arrivalAirport,
        arrivalAirport: departureAirport,
        date: startReturnDate,
        departTime: startReturnTime,
        numberOfPassengers:
          Number(searchParams.get('returnPassengers')) || 1,
        numberOfPets:
          Number(searchParams.get('returnPets')) || 0,
      };
      // If you don't have a depart date or return date, just open the calendar
      const shouldOnlyOpenCalendar = (!startDepartDate || !startReturnDate) ? true : false;

      // Determine the number of days the round trip is
      let roundTripDays = roundTripRequest.roundTripDays;
      if (!!startDepartDate && !!startReturnDate) {
        roundTripDays = getRoundTripDays(startDepartDate, startReturnDate);
      }

      contextDispatch({
        type: 'updateFromURL',
        payload: {
          legs: [{
            ...firstLeg,
            dateOpen: shouldOnlyOpenCalendar
          }, secondLeg],
          flightType: flightType,
          displayDate:
            startDepartDate && startReturnDate
              ? `${formatDisplayDate(
                startDepartDate,
              )} - ${formatDisplayDate(startReturnDate)}`
              : '',
            pricingRequest: {
              ...pricingRequest,
              roundTripDays,
            },
        },
      });
      if (!shouldOnlyOpenCalendar) {
        // Trigger the flight search
        setHasRetrievedDeal(true);
      }
      break;
    }
    default: {
      break;
    }
  }
}
