import { mixpanelInit } from '@services/mixpanelService/mixpanel.service';
import { useEffect } from 'react';
import { checkRefreshTokenExpiration } from '@services/ErrorHandlingServices';
import { datadogInit, datadogLogsInit } from '@services/loggingService/datadogLogging.service';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import './App.css';
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import LayoutWrapper from './LayoutWrapper';

mixpanelInit();
datadogInit();
datadogLogsInit();

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

function App() {
  useEffect(checkRefreshTokenExpiration, []);

  return (
    <QueryClientProvider client={queryClient}>
      <LayoutWrapper />
      {process.env.REACT_APP_LOCAL_ENVIRONMENT
        ? (
          <ReactQueryDevtools initialIsOpen={false} />
        ) : null
      }
    </QueryClientProvider>
  );
}

export default App;
