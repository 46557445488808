import styled from 'styled-components';
import { solidColors } from '@constants/styles/colors.constants';
import { Typography } from '@atoms';

export const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 336px;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05);
  position: relative;
  height: fit-content;
`;

export const CardContent = styled.div`
  padding: 35px 1rem 0;
`;

export const StyledFlightDealType = styled.div`
  height: 48px;
  width: 48px;
  border: 1px solid ${solidColors.mediumGray.color};
  background-color: ${solidColors.upWhite.color};
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.03);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -24px;
  left: 16px;
`;

export const StyledDateContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  > p:first-child {
    margin-right: 20px;
  }

  .timeOfDay {
    flex-grow: 1;
  }
`;

export const StyledDepartureArrivalContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
  position: relative;

  > span:last-child {
    text-align: right;
  }

  .typoBackground {
    background-color: #fff;
    z-index: 1;
    &:first-child {
      padding-right: 10px;
    }
    &:last-child {
      padding-left: 10px;
    }
  }

  .inline {
    display: flex;
    align-items: baseline;
  }

  .estimated {
    margin-right: 4px;
  }

  .fuelStop {
    margin-left: 30px;
  }

  &.departureArrivalTime {
    margin-top: 5px;
  }
`;

export const StyledAircraftWrapper = styled.div`
  display: flex;
  > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    &:first-child {
      width: 65%;
    }
    &:last-child {
      width: 35%;
      text-align: right;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
`;

export const PriceRowContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const EstimatedTextStyled = styled(Typography)`
  width: 8rem;
`;

export const AircraftSection = styled.div`
  margin-top: 16px;
`;

export const StyledCostWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .price {
    display: flex;
    align-items: center;
    img {
      margin-right: 7px;
    }
  }

  &:last-child {
    margin-bottom: 20px;
  }
`;

export const StyledAircraftDottedLineWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LineSpaceStyled = styled.div`
  height: 1em;
`;
