import styled from 'styled-components';
import { Grid } from '@library';
import { solidColors } from '@constants/styles/colors.constants';

export const FleetTag = styled.div`
  background-color: ${solidColors.midnight.color};
  border-radius: 4px;
  font-size: 10px;
  width: 137px;
  height: 18px;
  text-align: center;
  margin: auto;
  margin-top: 16px;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
`;

export const FleetText = styled.p`
  color: ${solidColors.upWhite.color};
  font-weight: 500;
  letter-spacing: 1.14px;
  line-height: 10px;
`;

export const StyledAircraft = styled(Grid)`
  padding-top: 13px;
  height: 109px;
  width: fit-content;
  margin: 8px 16px 0 0;
  margin-left: 16px;
  justify-content: center;
`;

export const StyledInfo = styled(Grid)`
  height: 50px;
  margin-top: 60px;
  margin-right: 0;
  padding-right: 0;
  display: flex;
  flex-wrap: wrap;

  ${(props) => props.theme.breakpoints.down('md')} {
    width: 100%;
  }

  @media (max-width: 810px) {
    height: 250px;
  }

  @media (max-width: 600px) {
    height: 350px;
  }
`;

export const TextContainer = styled.div`
  margin-top: 60px;
  margin-left: 20px;
  margin-right: 20px;
  width: auto;
  max-width: 7rem;
  height: 50px;
  float: left;

  @media (max-width: 810px) {
    margin-bottom: 2.5rem;
  }

  @media (max-width: 574px) {
    margin-top: 2rem;
    margin-bottom: 1rem;
  }

  @media (max-width: 450px) {
    margin-top: 1rem;
  }
`;

export const TailIcon = styled.div`
  margin-right: 8px;
  height: 15px;
  width: 20px;
`;

export const TitleText = styled.span`
  display: inline-flex;
`;

export const StyledTypographyContainer = styled.div`
  margin-top: 8px;
`;
