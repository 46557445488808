import styled from 'styled-components';

export const StyledInputBase = styled.div<{
  $width?: string;
}>`
  display: flex;
  align-items: center;
  padding-left: 0em;
  ${({ $width }) => {
    if ($width) {
      return `
                &.MuiFormControl-root {
                    width: ${$width} !important;
                }
            `;
    }
  }}
`;

export const StyledLeadingCTA = styled.div`
  top: 0%;
  left: 0%;
  bottom: 0%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;

export const ActionWrapper = styled.div`
  cursor: pointer;
  :hover {
    filter: grayscale(0.5);
  }
`;
