import { ICardImageHeadline } from './CardImageTitleHeadlineCTA.interfaces';
import { Typography } from '@atoms';
import { typography } from '@constants/styles/typography.constants';
import { solidColors } from '@constants/styles/colors.constants';

import {
  CardMainContainer,
  CardContainer,
  CardBody,
  BottomCard,
  CTAButton,
  StyledImigixTopCard,
} from './CardImageTitleHeadlineCTA.styles';

const CardImageTitleHeadlineCTA = ({
  title,
  headline,
  copy,
  image,
  button,
}: ICardImageHeadline) => {
  return (
    <CardMainContainer className='CardImageTitleHeadlineCTA'>
      <StyledImigixTopCard
        alt={title || 'Title'}
        src={image?.url}
      />
      <BottomCard>
        <CardContainer>
          {headline && (
            <Typography
              variant={typography.heading04Small}
              truncate={1}
            >
              {headline}
            </Typography>
          )}
          {title && (
            <Typography
              as='p'
              variant={typography.heading03Small}
              truncate={1}
              color={solidColors.midnight}
              className='titleTextStyles'
            >
              {title}
            </Typography>
          )}
          <CardBody
            variant={typography.body2}
            truncate={3}
            color={solidColors.midnight}
          >
            {copy}
          </CardBody>
          
          <CTAButton 
            action={{
              actionMethod: button?.action?.actionMethod,
              data: button?.action?.data,
              type: button?.action?.type,
              ...button?.action,
            }}
            styleType='text_with_icon_trailing'
            title={button?.title || 'CTA'}
          />
        </CardContainer>
      </BottomCard>
    </CardMainContainer>
  );
};

export default CardImageTitleHeadlineCTA;
