import { IImagePlaneTailRight } from './ImagePlaneTailRight.interfaces';
import { PlaneImg } from './ImagePlaneTailRight.styles';

const ImagePlaneTailRight = ({
  image,
}: IImagePlaneTailRight) => {
  return (
    <PlaneImg
      data-name="ImagePlaneTailRight"
      src={image.url}
      alt="plane tail"
    />
  );
};

export default ImagePlaneTailRight;
