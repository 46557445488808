import styled from 'styled-components';
import {
  BaseButton,
  Button,
  Select,
  Typography,
} from '@atoms';
import { solidColors } from '@constants/styles/colors.constants';

export const StyledSubPageCreditCardForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  border-radius: 4px;
  background-color: #ffffff;
  position: relative;
  height: fit-content;
  min-width: 50%;
  margin: 0px 0px 20px 0px;
`;
export const StyledTitleContainer = styled.div`
  margin: 10px 0px 20px 0px;
`;
export const StyledLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  width: 100%;
  margin: 20px 0px;
  > * {
    margin: 0px 15px;
    width: 100%;
    > .MuiTextField-root {
      width: 100%;
    }
  }

  @media (max-width: 600px) {
    flex-direction: column;
    margin: 0;
  }
`;

export const MobileViewLineItemContainer = styled.div`
  @media (max-width: 600px) {
    margin: 0.5rem 0;
    min-width: 100%;
  }
`;

export const StyledLineItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  > * {
    width: 45%;
    min-width: 45%;
    max-width: 45%;
  }

  @media (max-width: 600px) {
    margin: 0;
    min-width: 100% !important;
  }
`;
export const StyledDropdownContainer = styled.div`
  width: 145px;
`;

export const SwitchContainer = styled.div`
  width: 100%;
  max-width: 300px;
  display: flex;
  justify-content: space-between;
  align: center;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
`;
export const SwitchTypography = styled(Typography)`
  margin: auto 0;
`;
export const StyledAddEditButton = styled(Button)`
  padding-left: 22px;
  padding-right: 1rem;
  margin: 10px 0px;
  background-color: ${solidColors.upWhite.color};
  color: ${solidColors.upBlue.color};
`;
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 70px;
  width: 100%;
  background-color: ${solidColors.upWhite.color};
  border-radius: 5px;
`;
export const ButtonItemContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  > button {
    width: 100%;
    height: 100%;
  }
`;
