import { Container } from '@library';
import styled from 'styled-components';
import { ImageImgix, TabPanel } from '@atoms';

export const StyledTabPanel = styled(TabPanel)`
  height: 100%;
`;

export const StyledImageImgix = styled(ImageImgix)`
  height: 500px;
  background-position: center top;
  width: 100vw;
  margin-left: 50%;
  transform: translateX(-50%);
`;

export const StyledChildWrapper = styled(Container)`
  padding-top: 280px;
`;
