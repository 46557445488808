import { Container } from '@components/library';
import React, { useEffect, useState } from 'react';
import { ICarouselWrapWebOnly } from './CarouselWrapWebOnly.interfaces';
import {
  StyledCarouselWrap,
  StyledCardContainer,
} from './CarouselWrapWebOnly.styles';
import styled from 'styled-components';

const CarouselWrapWebOnly = ({
  id,
  children,
  alignment = 'left',
  isChildComponent = false,
}: ICarouselWrapWebOnly) => {
  const [
    carouselAlignment,
    setCarouselAlignment,
  ] = useState('start');

  useEffect(() => {
    switch (alignment) {
      case 'left':
        setCarouselAlignment('start');
        break;
      case 'right':
        setCarouselAlignment('end');
        break;
      case 'center':
        setCarouselAlignment('center');
        break;
      default:
        setCarouselAlignment('start');
        break;
    }
  }, []);

  const cards = () => {
    if (Array.isArray(children)) {
      return children.map((child, index) => (
        <StyledCardContainer key={`index${index}`}>
          {child}
        </StyledCardContainer>
      ));
    } else {
      return (
        <StyledCardContainer>
          {children}
        </StyledCardContainer>
      );
    }
  };

  const GenericContainer = isChildComponent
    ? styled.div``
    : styled(Container)``;

  return (
    <GenericContainer data-id="CarouselWrapWebOnly">
      <StyledCarouselWrap
        id="CarouselWrapWebOnly"
        $alignment={carouselAlignment}
      >
        {cards()}
      </StyledCarouselWrap>
    </GenericContainer>
  );
};

export default CarouselWrapWebOnly;
