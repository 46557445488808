import { Container } from '@components/library';
import styled from 'styled-components';

export const StyledCarouselWrap = styled.div<{
  $alignment: string;
}>`
  display: flex;
  justify-content: ${({ $alignment }) => $alignment};
  flex-wrap: wrap;
  min-width: 100vh;

  @media (max-width: 1136px) {
    min-width: 100%;
  }
`;

export const StyledCardContainer = styled.div`
  margin: 0 40px 20px 0;

  ${(props) => props.theme.breakpoints.down('lg')} {
    margin: 0 32.5px 20px 0;
  }

  &: last-child {
    ${() =>
      window.location.pathname.includes('my-trips')
        ? 'margin-right: 0;'
        : ''}
  }
`;
