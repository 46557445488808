import { StyledFreeType } from './FormFieldFreeTypeLarge.styles';
import { IFormFieldFreeTypeLarge } from './FormFieldFreeTypeLarge.interfaces';

export default function FormFieldFreeTypeLarge({
  label,
  placeholder,
  value,
  variant,
  maxLength,
  disabled = false,
  onChange,
  ...rest
}: IFormFieldFreeTypeLarge) {
  return (
    <StyledFreeType
      {...rest}
      multiline
      rows={6}
      variant={variant}
      label={label}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      inputProps={{
        maxLength: maxLength,
        disableUnderline: true,
        disabled: disabled,
      }}
    />
  );
}
