import { Container } from '@components/library';
import { IContainerFlex } from './ContainerFlex.interfaces';
import { StyledFlex } from './ContainerFlex.styles';
import Spacer from '@atoms/Spacer/Spacer';

const ContainerFlex = ({
  children,
  spacerTypeTop,
  spacerTypeBottom,
}: IContainerFlex) => {
  return (
    <Container>
      {spacerTypeTop && <Spacer height={spacerTypeTop} />}
      <StyledFlex>{children}</StyledFlex>
      {spacerTypeBottom && (
        <Spacer height={spacerTypeBottom} />
      )}
    </Container>
  );
};

export default ContainerFlex;
