import { ImageImgix, Typography } from '@atoms';
import { solidColors } from '@constants/styles/colors.constants';
import { typography } from '@constants/styles/typography.constants';
import { ITripSection } from './FlightTripSection.interfaces';
import {
  DepartAirportNameContainer,
  ArriveAirportNameContainer,
  ArriveContainer,
  DepartContainer,
  TripContainer,
  IconContainer,
  StyledAirportName,
  StyledArriveAirportName,
  MultiFlightContainer,
  TimeContainer,
  FlightContainer,
  TopSection,
  TimeSection,
} from './FlightTripSection.styles';
import {
  ARRIVE_COPY,
  DEPART_COPY,
  NEW_ARRIVAL_COPY,
  NEW_DEPART_COPY,
  SCHEDULED_COPY,
} from './FlightTripSection.constants';

export default function FlightTripSection(
  props: ITripSection,
) {
  const isDelayFlex = Boolean(
    props.departureTimeDisplayValueUpdated &&
      props.arrivalTimeDisplayValueUpdated,
  );
  const textColor = props.isBlue
    ? solidColors.midnight
    : solidColors.darkGray;
  const tint =
    props.centerIconImage && props.centerIconImage.tintColor
      ? props.centerIconImage.tintColor
      : '';
  const centerIconImageUrl =
    props.centerIconImage && props.centerIconImage.url
      ? props.centerIconImage.url
      : '';
  return (
    <TripContainer data-test-id="flightTripSection">
      {props.multiFlightCopy ? (
        <MultiFlightContainer>
          <Typography variant={typography.heading01Large}>
            {props.multiFlightCopy}
          </Typography>
        </MultiFlightContainer>
      ) : (
        <FlightContainer>
          <TopSection>
            <DepartContainer>
              <Typography
                variant={typography.heading01Large}
                color={textColor}
              >
                {props.fromAirport.displayFullValue1}
              </Typography>
              <DepartAirportNameContainer>
                <StyledAirportName
                  variant={typography.body2}
                  color={solidColors.darkGray}
                  truncate={3}
                >
                  {props.fromAirport.displayFullValue2}
                </StyledAirportName>
              </DepartAirportNameContainer>
            </DepartContainer>
            <IconContainer>
              <ImageImgix
                alt="aircraft icon"
                src={centerIconImageUrl}
                filter={tint}
                width={20}
                height={20}
              />
            </IconContainer>
            <ArriveContainer>
              <Typography
                variant={typography.heading01Large}
                color={textColor}
                align='right'
              >
                {props.toAirport.displayFullValue1}
              </Typography>
              <ArriveAirportNameContainer>
                <StyledArriveAirportName
                  variant={typography.body2}
                  color={solidColors.darkGray}
                  truncate={3}
                  align='right'
                >
                  {props.toAirport.displayFullValue2}
                </StyledArriveAirportName>
              </ArriveAirportNameContainer>
            </ArriveContainer>
          </TopSection>
          <TimeSection>
            {isDelayFlex && (
              <>
                <TimeContainer>
                  <Typography
                    variant={typography.caption}
                    color={solidColors.darkGray}
                  >
                    {NEW_DEPART_COPY}
                  </Typography>
                  <Typography
                    variant={typography.caption}
                    color={solidColors.darkGray}
                  >
                    {NEW_ARRIVAL_COPY}
                  </Typography>
                </TimeContainer>
                <TimeContainer className="delayedTime">
                  <Typography
                    variant={typography.body1}
                    color={
                      props.isBlue
                        ? solidColors.jam
                        : solidColors.darkGray
                    }
                  >
                    {props.departureTimeDisplayValueUpdated}
                  </Typography>

                  <Typography
                    variant={typography.body1}
                    color={
                      props.isBlue
                        ? solidColors.jam
                        : solidColors.darkGray
                    }
                    align='right'
                  >
                    {props.arrivalTimeDisplayValueUpdated}
                  </Typography>
                </TimeContainer>
              </>
            )}
            <TimeContainer>
              <Typography
                variant={typography.caption}
                color={solidColors.darkGray}
              >
                {isDelayFlex ? SCHEDULED_COPY : DEPART_COPY}
              </Typography>
              <Typography
                variant={typography.caption}
                color={solidColors.darkGray}
              >
                {isDelayFlex ? SCHEDULED_COPY : ARRIVE_COPY}
              </Typography>
            </TimeContainer>
            <TimeContainer>
              <Typography
                variant={typography.body2}
                color={textColor}
              >
                {props.departureTimeDisplayValue}
              </Typography>

              <Typography
                variant={typography.body2}
                color={textColor}
                align='right'
              >
                {props.arrivalTimeDisplayValue}
              </Typography>
            </TimeContainer>
          </TimeSection>
        </FlightContainer>
      )}
    </TripContainer>
  );
}
