import { IPassengerPayment } from '@components/types';
import { getFromSessionStorage } from '@components/utils/storage';

export const getBookingFlowData = () => {
  const bookingFlow = getFromSessionStorage(
    'flightPurchaseCheckout',
  );
  const newBookingRequestLegDetails = bookingFlow?.bookingRequestLegDetails?.map(
    (leg: any) => {
      const newPassengers = leg?.passengers?.map(
        (passenger: any) => {
          if (passenger?.pet) {
            return {
              id: passenger?.id,
              firstName: passenger?.name,
              isPet: true,
              isLead: false,
            } as IPassengerPayment;
          } else {
            return {
              id: passenger?.id,
              firstName: passenger?.firstName,
              lastName: passenger?.lastName,
              isLead: passenger?.isLeadPassenger,
              phone: passenger?.phone,
              email: passenger?.email,
              isPet: false,
            } as IPassengerPayment;
          }
        },
      );

      return {
        ...leg,
        passengers: newPassengers,
      };
    },
  );

  return {
    ...{
      ...bookingFlow,
      bookingRequestLegDetails: newBookingRequestLegDetails,
    },
  };
};
