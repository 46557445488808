import { IIndexable } from '@components/types';
import { solidColors } from './colors.constants';
import { typographyBaseVariants } from '@wheelsup/wu-react-components';

//latest typography doc (2.26.2021) https://docs.google.com/spreadsheets/d/1zSKqRcxaE-UZT1dsK1tV56dIZuOYz0dpecp8JB_7aWU/edit#gid=1483426313

export interface ITypographyObject {
  name: string;
  fontSize: string;
  family?: string;
  textDecoration?: string;
  fontWeight: string;
  letterSpacing: string;
  lineHeight: string;
  textTransform: string;
  textAlign: string;
  color: string;
  as: string;
  variant?: string;
  libraryVariant?: keyof typeof typographyBaseVariants;
}

const aktivBoldWeight =
  navigator.userAgent.indexOf('Safari') != -1 ? 500 : 700;

export const typography: IIndexable<ITypographyObject> = {
  // Remove this old style once new style is merged
  h1a: {
    name: 'h1-a', //name in DSM
    fontSize: '3rem',
    fontWeight: '400',
    letterSpacing: '-0.82px',
    lineHeight: '80px',
    textTransform: 'none',
    textAlign: 'left',
    color: `${solidColors.midnight.color}`,
    as: 'h1', // renders this tag via 'as' prop in styled-components
    libraryVariant: 'heading01',
  },
  h2a: {
    name: 'h2-a',
    fontSize: '2.25rem',
    fontWeight: '400',
    letterSpacing: '-0.62px',
    lineHeight: '40px',
    textTransform: 'none',
    textAlign: 'left',
    color: `${solidColors.midnight.color}`,
    as: 'h2',
    libraryVariant: 'heading02',
  },
  h3a: {
    name: 'h3-a',
    fontSize: '1.75rem',
    fontWeight: '400',
    letterSpacing: '-0.48px',
    lineHeight: '34px',
    textTransform: 'none',
    textAlign: 'left',
    color: `${solidColors.upBlue.color}`,
    as: 'h3',
    libraryVariant: 'heading03',
  },
  h4a: {
    name: 'h4-a',
    fontSize: '1.5rem',
    fontWeight: '400',
    letterSpacing: '-0.41px',
    lineHeight: '30px',
    textTransform: 'none',
    textAlign: 'left',
    color: `${solidColors.midnight.color}`,
    as: 'h4',
    libraryVariant: 'heading04Small',
  },
  h4b: {
    name: 'h4-b',
    fontSize: '1.5rem',
    fontWeight: '500',
    letterSpacing: '-0.41px',
    lineHeight: '30px',
    textTransform: 'none',
    textAlign: 'left',
    color: `${solidColors.midnight.color}`,
    as: 'h4',
    libraryVariant: 'heading04',
  },
  h5a: {
    name: 'h5-a',
    fontSize: '1.375rem',
    fontWeight: '400',
    letterSpacing: '-0.38px',
    lineHeight: '26px',
    textTransform: 'none',
    textAlign: 'left',
    color: `${solidColors.upBlue.color}`,
    as: 'h5',
    libraryVariant: 'heading04',
  },
  h5b: {
    name: 'h5-b',
    fontSize: '1.375rem',
    fontWeight: '500',
    letterSpacing: '-0.38px',
    lineHeight: '26px',
    textTransform: 'none',
    textAlign: 'left',
    color: `${solidColors.midnight.color}`,
    as: 'h5',
    libraryVariant: 'heading04',
  },
  h6a: {
    name: 'h6-a',
    fontSize: '1.25rem',
    fontWeight: '400',
    letterSpacing: '-0.34px',
    lineHeight: '26px',
    textTransform: 'none',
    textAlign: 'left',
    color: `${solidColors.midnight.color}`,
    libraryVariant: 'heading04',
    as: 'h6',
  },
  h6b: {
    name: 'h6-b',
    fontSize: '1.25rem',
    fontWeight: '500',
    letterSpacing: '-0.34px',
    lineHeight: '26px',
    textTransform: 'none',
    textAlign: 'left',
    color: `${solidColors.midnight.color}`,
    as: 'h6',
    libraryVariant: 'heading04',
  },
  subheadA: {
    name: 'subhead-a',
    fontSize: '1.125rem',
    fontWeight: '400',
    letterSpacing: '-0.31px',
    lineHeight: '24px',
    textTransform: 'none',
    textAlign: 'left',
    color: `${solidColors.midnight.color}`,
    libraryVariant: 'body01',
    as: 'p',
  },
  subheadB: {
    name: 'subhead-b',
    fontSize: '1.125rem',
    fontWeight: '500',
    letterSpacing: '-0.31px',
    lineHeight: '24px',
    textTransform: 'none',
    textAlign: 'left',
    color: `${solidColors.midnight.color}`,
    as: 'p',
    libraryVariant: 'body01Bold',
  },
  body1a: {
    name: 'body1-a',
    fontSize: '1rem',
    fontWeight: '400',
    letterSpacing: '-0.27px',
    lineHeight: '24px',
    textTransform: 'none',
    textAlign: 'left',
    color: `${solidColors.midnight.color}`,
    as: 'p',
    libraryVariant: 'body01',
  },
  body1b: {
    name: 'body1-b',
    fontSize: '1rem',
    fontWeight: '500',
    letterSpacing: '-0.27px',
    lineHeight: '26px',
    textTransform: 'none',
    textAlign: 'left',
    color: `${solidColors.midnight.color}`,
    as: 'p',
    libraryVariant: 'body01Bold',
  },
  body2a: {
    name: 'body2-a',
    fontSize: '0.875rem',
    fontWeight: '400',
    letterSpacing: '-0.24px',
    lineHeight: '20px',
    textTransform: 'none',
    textAlign: 'left',
    color: `${solidColors.darkGray.color}`,
    as: 'p',
    libraryVariant: 'body02',
  },

  body2b: {
    name: 'body2-b',
    fontSize: '0.875rem',
    fontWeight: '500',
    letterSpacing: '-0.24px',
    lineHeight: '20px',
    textTransform: 'none',
    textAlign: 'left',
    color: `${solidColors.midnight.color}`,
    as: 'p',
    libraryVariant: 'body02Bold',
  },
  ctaA: {
    name: 'cta-a',
    fontSize: '0.8125rem',
    fontWeight: '500',
    letterSpacing: '1px',
    lineHeight: '18px',
    textTransform: 'uppercase',
    textAlign: 'center',
    color: `${solidColors.upBlue.color}`,
    as: 'p',
    libraryVariant: 'cta',
  },
  captionA: {
    name: 'caption-a',
    fontSize: '0.75rem',
    fontWeight: '400',
    letterSpacing: '-0.21px',
    lineHeight: '20px',
    textTransform: 'none',
    textAlign: 'left',
    color: `${solidColors.darkGray.color}`,
    as: 'p',
    libraryVariant: 'caption',
  },
  caption1a: {
    name: 'caption-a',
    fontSize: '0.75rem',
    fontWeight: '400',
    letterSpacing: '-0.21px',
    lineHeight: '20px',
    textTransform: 'none',
    textAlign: 'left',
    color: `${solidColors.darkGray.color}`,
    as: 'p',
    libraryVariant: 'caption',
  },
  captionB: {
    name: 'caption-b',
    fontSize: '0.75rem',
    fontWeight: '500',
    letterSpacing: '-0.21px',
    lineHeight: '20px',
    textTransform: 'none',
    textAlign: 'left',
    color: `${solidColors.darkGray.color}`,
    as: 'p',
    libraryVariant: 'captionBold',
  },
  footer1: {
    name: 'footer1',
    fontSize: '1rem',
    fontWeight: '500',
    letterSpacing: '3.43px',
    lineHeight: '20px',
    textTransform: 'uppercase',
    textAlign: 'left',
    color: `${solidColors.upWhite.color}`,
    as: 'p',
    libraryVariant: 'ctaDrawer'
  },
  footer2: {
    name: 'footer2',
    fontSize: '0.8125rem',
    fontWeight: '400',
    letterSpacing: '2px',
    lineHeight: '30px',
    textTransform: 'uppercase',
    textAlign: 'left',
    color: `${solidColors.upWhite.color}`,
    as: 'p',
    libraryVariant: 'label'
  },
  navDarkActive: {
    name: 'nav-dark-active',
    fontSize: '0.875rem',
    fontWeight: '500',
    letterSpacing: '3px',
    lineHeight: '20px',
    textTransform: 'uppercase',
    textAlign: 'left',
    color: `${solidColors.upWhite.color}`,
    as: 'span',
    libraryVariant: 'label'
  },
  navDarkInactive: {
    name: 'nav-dark-inactive',
    fontSize: '0.875rem',
    fontWeight: '500',
    letterSpacing: '3px',
    lineHeight: '20px',
    textTransform: 'uppercase',
    textAlign: 'left',
    color: `${solidColors.blue5Percent.color}`,
    as: 'span',
    libraryVariant: 'labelMini'
  },
  navLightActive: {
    name: 'nav-light-active',
    fontSize: '0.875rem',
    fontWeight: '500',
    letterSpacing: '3px',
    lineHeight: '20px',
    textTransform: 'uppercase',
    textAlign: 'left',
    color: `${solidColors.midnight.color}`,
    as: 'span',
    libraryVariant: 'labelMini'
  },
  navLightInactive: {
    name: 'nav-light-inactive',
    fontSize: '0.875rem',
    fontWeight: '500',
    letterSpacing: '3px',
    lineHeight: '20px',
    textTransform: 'uppercase',
    textAlign: 'left',
    color: `${solidColors.blue40Percent.color}`,
    as: 'span',
    libraryVariant: 'labelMini'
  },
  calendarRange1: {
    name: 'calendar-range1',
    fontSize: '1.125rem',
    fontWeight: '400',
    letterSpacing: '2.25px',
    lineHeight: '40px',
    textTransform: 'uppercase',
    textAlign: 'center',
    color: `${solidColors.midnight.color}`,
    as: 'p',
  },
  calendarRange2: {
    name: 'calendar-range2',
    fontSize: '2.25rem',
    fontWeight: '500',
    letterSpacing: '2.25px',
    lineHeight: '40px',
    textTransform: 'uppercase',
    textAlign: 'center',
    color: `${solidColors.midnight.color}`,
    as: 'p',
  },
  calendarRange3: {
    name: 'calendar-range3',
    fontSize: '0.875rem',
    fontWeight: '400',
    letterSpacing: '1.75px',
    lineHeight: '20px',
    textTransform: 'uppercase',
    textAlign: 'center',
    color: `${solidColors.darkGray.color}`,
    as: 'p',
  },
  calendarDate1: {
    name: 'calendar-date1',
    fontSize: '1.125rem',
    fontWeight: '400',
    letterSpacing: '2.25px',
    lineHeight: '50px',
    textTransform: 'uppercase',
    textAlign: 'center',
    color: `${solidColors.midnight.color}`,
    as: 'p',
  },
  calendarDate2: {
    name: 'calendar-date2',
    fontSize: '3rem',
    fontWeight: '400',
    letterSpacing: '3px',
    lineHeight: '50px',
    textTransform: 'uppercase',
    textAlign: 'center',
    color: `${solidColors.midnight.color}`,
    as: 'p',
  },
  calendarDate3: {
    name: 'calendar-date3',
    fontSize: '1rem',
    fontWeight: '400',
    letterSpacing: '1.75px',
    lineHeight: '20px',
    textTransform: 'uppercase',
    textAlign: 'center',
    color: `${solidColors.darkGray.color}`,
    as: 'p',
  },
  num1A: {
    name: 'num1-a',
    fontSize: '0.875rem',
    fontWeight: '400',
    letterSpacing: '0',
    lineHeight: '30px',
    textTransform: 'none',
    textAlign: 'left',
    color: `${solidColors.darkGray.color}`,
    as: 'p',
  },
  num2A: {
    name: 'num2-a',
    fontSize: '2.25rem',
    fontWeight: '500',
    letterSpacing: '0',
    lineHeight: '40px',
    textTransform: 'none',
    textAlign: 'right',
    color: `${solidColors.midnight.color}`,
    as: 'h2',
  },
  num2B: {
    name: 'num2-b',
    fontSize: '2.25rem',
    fontWeight: '400',
    letterSpacing: '0',
    lineHeight: '40px',
    textTransform: 'none',
    textAlign: 'center',
    color: `${solidColors.midnight.color}`,
    as: 'h2',
  },
  hero1a: {
    name: 'hero1-a',
    fontSize: '3.34rem',
    fontWeight: '400',
    letterSpacing: '-1.03px',
    lineHeight: '60px',
    textTransform: 'none',
    textAlign: 'left',
    color: `${solidColors.midnight.color}`,
    as: 'h1',
  },

  // End of old styles

  //new styles
  heading01Large: {
    name: 'heading-01-large',
    family: 'Mikro',
    fontSize: '2.25rem',
    fontWeight: '400',
    letterSpacing: '-0.82px',
    lineHeight: '44px',
    textTransform: 'none',
    textDecoration: 'none',
    textAlign: 'left',
    color: `${solidColors.midnight.color}`,
    as: 'h1', // renders this tag via 'as' prop in styled-components
    libraryVariant: 'heading01'
  },
  heading02Large: {
    name: 'heading-02-large',
    family: 'Mikro',
    fontSize: '1.75rem',
    fontWeight: '400',
    letterSpacing: '-0.82px',
    lineHeight: '34px',
    textTransform: 'none',
    textDecoration: 'none',
    textAlign: 'left',
    color: `${solidColors.midnight.color}`,
    as: 'h2', // renders this tag via 'as' prop in styled-components
    libraryVariant: 'heading02'
  },
  heading03Large: {
    name: 'heading-03-large',
    family: 'Mikro',
    fontSize: '1.5rem',
    fontWeight: '400',
    letterSpacing: '-0.82px',
    lineHeight: '30px',
    textTransform: 'none',
    textDecoration: 'none',
    textAlign: 'left',
    color: `${solidColors.midnight.color}`,
    as: 'h3', // renders this tag via 'as' prop in styled-components
    libraryVariant: 'heading03'
  },
  heading03Small: {
    name: 'heading-03-small',
    family: 'Mikro',
    fontSize: '1.125rem',
    fontWeight: '400',
    letterSpacing: '-0.82px',
    lineHeight: '24px',
    textTransform: 'none',
    textDecoration: 'none',
    textAlign: 'left',
    color: `${solidColors.midnight.color}`,
    as: 'h3', // renders this tag via 'as' prop in styled-components
    libraryVariant: 'heading03Small'
  },
  heading04Large: {
    name: 'heading-04-large',
    family: 'Mikro-Bold',
    fontSize: '1.25rem',
    fontWeight: '700',
    letterSpacing: '-0.82px',
    lineHeight: '26px',
    textTransform: 'none',
    textDecoration: 'none',
    textAlign: 'left',
    color: `${solidColors.midnight.color}`,
    as: 'h4', // renders this tag via 'as' prop in styled-components
    libraryVariant: 'heading04'
  },
  heading04Small: {
    name: 'heading-04-small',
    family: 'Mikro-Bold',
    fontSize: '.875rem',
    fontWeight: '700',
    letterSpacing: '-0.82px',
    lineHeight: '20px',
    textTransform: 'none',
    textDecoration: 'none',
    textAlign: 'left',
    color: `${solidColors.midnight.color}`,
    as: 'h4', // renders this tag via 'as' prop in styled-components
    libraryVariant: 'heading04Small'
  },
  heading05Large: {
    name: 'heading-05-large',
    family: 'Mikro-Bold',
    fontSize: '.875rem',
    fontWeight: '700',
    letterSpacing: '-0.82px',
    lineHeight: '20px',
    textTransform: 'none',
    textDecoration: 'none',
    textAlign: 'left',
    color: `${solidColors.midnight.color}`,
    as: 'h5', // renders this tag via 'as' prop in styled-components
    libraryVariant: 'heading05'
  },
  heading01Bold: {
    name: 'heading-01-bold',
    family: 'Mikro',
    fontSize: '2.25rem',
    fontWeight: '400',
    letterSpacing: '-0.82px',
    lineHeight: '44px',
    textTransform: 'none',
    textDecoration: 'none',
    textAlign: 'left',
    color: `${solidColors.midnight.color}`,
    as: 'h1', // renders this tag via 'as' prop in styled-components
    libraryVariant: 'heading01'
  },
  heading02Bold: {
    name: 'heading-02-bold',
    family: 'Mikro',
    fontSize: '1.75rem',
    fontWeight: '700',
    letterSpacing: '-0.82px',
    lineHeight: '34px',
    textTransform: 'none',
    textDecoration: 'none',
    textAlign: 'left',
    color: `${solidColors.midnight.color}`,
    as: 'h2', // renders this tag via 'as' prop in styled-components
    libraryVariant: 'heading02'
  },
  heading03Bold: {
    name: 'heading-03-bold',
    family: 'Mikro',
    fontSize: '1.5rem',
    fontWeight: '700',
    letterSpacing: '-0.82px',
    lineHeight: '24px',
    textTransform: 'none',
    textDecoration: 'none',
    textAlign: 'left',
    color: `${solidColors.midnight.color}`,
    as: 'h3', // renders this tag via 'as' prop in styled-components
    libraryVariant: 'heading03'
  },
  heading04Bold: {
    name: 'heading-04-bold',
    family: 'Mikro-Bold',
    fontSize: '1.25rem',
    fontWeight: '700',
    letterSpacing: '-0.82px',
    lineHeight: '26px',
    textTransform: 'none',
    textDecoration: 'none',
    textAlign: 'left',
    color: `${solidColors.midnight.color}`,
    as: 'h4', // renders this tag via 'as' prop in styled-components
    libraryVariant: 'heading04'
  },
  heading05Bold: {
    name: 'heading-05-bold',
    family: 'Mikro-Bold',
    fontSize: '.875rem',
    fontWeight: '700',
    letterSpacing: '-0.82px',
    lineHeight: '20px',
    textTransform: 'none',
    textDecoration: 'none',
    textAlign: 'left',
    color: `${solidColors.midnight.color}`,
    as: 'h5', // renders this tag via 'as' prop in styled-components
    libraryVariant: 'heading05'
  },
  body1: {
    name: 'body-1',
    family: 'Aktiv',
    fontSize: '1.125rem',
    fontWeight: '400',
    letterSpacing: '-0.27px',
    lineHeight: '24px',
    textTransform: 'none',
    textDecoration: 'none',
    textAlign: 'left',
    color: `${solidColors.midnight.color}`,
    as: 'p',
    libraryVariant: 'body01'
  },
  body1Strikethrough: {
    name: 'body-1-strikethrough',
    family: 'Aktiv',
    fontSize: '1.125rem',
    fontWeight: '400',
    letterSpacing: '-0.27px',
    lineHeight: '24px',
    textTransform: 'none',
    textDecoration: 'line-through',
    textAlign: 'left',
    color: `${solidColors.midnight.color}`,
    as: 'p',
    libraryVariant: 'body01'
  },
  body1Bold: {
    name: 'body-1-bold',
    family: 'AktivGrotesk-Bold',
    fontSize: '1.125rem',
    fontWeight: `${aktivBoldWeight}`,
    letterSpacing: '-0.27px',
    lineHeight: '24px',
    textTransform: 'none',
    textDecoration: 'none',
    textAlign: 'left',
    color: `${solidColors.midnight.color}`,
    as: 'p',
    libraryVariant: 'body01Bold'
  },
  body2: {
    name: 'body-2',
    family: 'Aktiv',
    fontSize: '.875rem',
    fontWeight: '400',
    letterSpacing: '-0.27px',
    lineHeight: '20px',
    textTransform: 'none',
    textDecoration: 'none',
    textAlign: 'left',
    color: `${solidColors.midnight.color}`,
    as: 'p',
    libraryVariant: 'body02'
  },
  body2Strikethrough: {
    name: 'body-2-strikethrough',
    family: 'Aktiv',
    fontSize: '.875rem',
    fontWeight: '400',
    letterSpacing: '-0.27px',
    lineHeight: '20px',
    textTransform: 'none',
    textDecoration: 'line-through',
    textAlign: 'left',
    color: `${solidColors.midnight.color}`,
    as: 'p',
    libraryVariant: 'body02'
  },

  body2Bold: {
    name: 'body-2-bold',
    family: 'AktivGrotesk-Bold',
    fontSize: '.875rem',
    fontWeight: `${aktivBoldWeight}`,
    letterSpacing: '-0.27px',
    lineHeight: '20px',
    textTransform: 'none',
    textDecoration: 'none',
    textAlign: 'left',
    color: `${solidColors.midnight.color}`,
    as: 'p',
    libraryVariant: 'body02Bold'
  },

  cta: {
    name: 'cta',
    family: 'Mikro-Bold',
    fontSize: '1rem',
    fontWeight: '500',
    letterSpacing: '1px',
    lineHeight: '20px',
    textTransform: 'uppercase',
    textDecoration: 'none',
    textAlign: 'start',
    color: `${solidColors.upBlue.color}`,
    as: 'p',
    libraryVariant: 'cta'
  },
  caption: {
    name: 'caption',
    family: 'Aktiv',
    fontSize: '.75rem',
    fontWeight: '400',
    letterSpacing: '-0.27px',
    lineHeight: '18px',
    textTransform: 'none',
    textDecoration: 'none',
    textAlign: 'left',
    color: `${solidColors.midnight.color}`,
    as: 'p',
    libraryVariant: 'caption'
  },
  captionBold: {
    name: 'caption-bold',
    family: 'AktivGrotesk-Bold',
    fontSize: '.75rem',
    fontWeight: `${aktivBoldWeight}`,
    letterSpacing: '-0.27px',
    lineHeight: '18px',
    textTransform: 'none',
    textDecoration: 'none',
    textAlign: 'left',
    color: `${solidColors.midnight.color}`,
    as: 'p',
    libraryVariant: 'captionBold'
  },
  numberLarge: {
    name: 'number-large',
    family: '',
    fontSize: '2.25rem',
    fontWeight: '400',
    letterSpacing: '0',
    lineHeight: '44px',
    textTransform: 'none',
    textDecoration: 'none',
    textAlign: 'left',
    color: `${solidColors.darkGray.color}`,
    as: 'p',
    libraryVariant: 'numberLarge'
  },
  numberMedium: {
    name: 'number-medium',
    family: 'Mikro',
    fontSize: '1.5rem',
    fontWeight: '400',
    letterSpacing: '0',
    lineHeight: '30px',
    textTransform: 'none',
    textDecoration: 'none',
    textAlign: 'left',
    color: `${solidColors.midnight.color}`,
    as: 'p',
    libraryVariant: 'numberMed'
  },
  numberSmall: {
    name: 'number-small',
    family: 'Mikro',
    fontSize: '1.125rem',
    fontWeight: '400',
    letterSpacing: '0',
    lineHeight: '24px',
    textTransform: 'none',
    textDecoration: 'none',
    textAlign: 'right',
    color: `${solidColors.midnight.color}`,
    as: 'p',
    libraryVariant: 'numberSmall'
  },
  cityLarge: {
    name: 'city-large',
    family: '',
    fontSize: '2.25rem',
    fontWeight: '400',
    letterSpacing: '0',
    lineHeight: '44px',
    textTransform: 'none',
    textDecoration: 'none',
    textAlign: 'left',
    color: `${solidColors.darkGray.color}`,
    as: 'p',
    libraryVariant: 'cityLarge'
  },
  helper: {
    name: 'helper',
    family: 'Aktiv',
    fontSize: '.75rem',
    fontWeight: '400',
    letterSpacing: '0',
    lineHeight: '18px',
    textTransform: 'none',
    textDecoration: 'none',
    textAlign: 'left',
    color: `${solidColors.darkGray.color}`,
    as: 'p',
    libraryVariant: 'helper'
  },
  tabLabelLarge: {
    name: 'tab-label-large',
    family: 'Aktiv',
    fontSize: '1.12rem',
    fontWeight: '400',
    letterSpacing: '0',
    lineHeight: '24px',
    textTransform: 'none',
    textDecoration: 'none',
    textAlign: 'center',
    color: `${solidColors.midnight.color}`,
    as: 'p',
    libraryVariant: 'tabLabelLarge'
  },
  label: {
    name: 'tab-label-large',
    family: 'Aktiv',
    fontSize: '1.12rem',
    fontWeight: '400',
    letterSpacing: '0',
    lineHeight: '24px',
    textTransform: 'none',
    textDecoration: 'none',
    textAlign: 'center',
    color: `${solidColors.midnight.color}`,
    as: 'p',
    libraryVariant: 'labelLarge'
  },
  tabLabelBoldLarge: {
    name: 'tab-label-bold-large',
    family: 'AktivGrotesk-Bold',
    fontSize: '1.12rem',
    fontWeight: `${aktivBoldWeight}`,
    letterSpacing: '0.30px !important',
    lineHeight: '24px',
    textTransform: 'none',
    textDecoration: 'none',
    textAlign: 'center',
    color: `${solidColors.midnight.color}`,
    as: 'p',
    libraryVariant: 'tabLabelLargeBold'
  },
  tagLabel: {
    name: 'tag-label',
    family: 'AktivGrotesk-Bold',
    fontSize: '.75rem',
    fontWeight: `${aktivBoldWeight}`,
    letterSpacing: '0',
    lineHeight: '18px',
    textTransform: 'none',
    textDecoration: 'none',
    textAlign: 'center',
    color: `${solidColors.darkGray.color}`,
    as: 'p',
    libraryVariant: 'tagLabel'
  },
};
