import { Typography } from '@atoms';
import { typography } from '@constants/styles/typography.constants';
import { solidColors } from '@constants/styles/colors.constants';
import { IPopUpCard } from './PopUpCard.interfaces';
import {
  HeadlineSpacerStyled,
  PopupCardWrapper,
  ImageContainer,
  ContentContainer,
  ButtonContainer,
  ButtonItemContainer,
  HeadlineStyled,
  BoxStyled,
  HeadlineCopyStyled,
  StyledP,
  PopUpChildrenContainer,
  ImagelessSpacer,
  BodyStyled,
  DialogStyled,
  ButtonStyled,
  SingleButtonContainer,
  SingleButtonStyled,
} from './PopUpCard.styles';
import { IButton } from '@atoms/Button/Button.interfaces';

export default function PopUpCard({
  open,
  headlineIcon,
  headline,
  copy,
  body,
  maxWidth,
  closeAction,
  primary,
  secondary,
  children,
  minWidth,
  minHeight,
  withTopSpacer = true,
  childWidth = 70,
}: IPopUpCard) {
  const renderButtons = (
    primary?: IButton,
    secondary?: IButton,
  ) => {
    if (primary && secondary) {
      return (
        <ButtonContainer>
          <ButtonItemContainer>
            <ButtonStyled {...secondary} />
          </ButtonItemContainer>
          <ButtonItemContainer>
            <ButtonStyled {...primary} />
          </ButtonItemContainer>
        </ButtonContainer>
      );
    } else if (primary && !secondary) {
      return (
        <SingleButtonContainer>
          <SingleButtonStyled {...primary} />
        </SingleButtonContainer>
      );
    } else {
      return null;
    }
  };

  const handleClose = (event: any, reason: any) => {
    if (reason && reason == 'backdropClick') {
      return;
    }
    closeAction;
  };

  return (
    <div
      style={{
        height: 'fit-content',
        width: 'fit-content',
      }}
    >
      <DialogStyled
        open={open}
        maxWidth={maxWidth}
        onClose={closeAction}
      >
        <PopupCardWrapper
          $width={minWidth}
          $height={minHeight}
        >
          {headlineIcon ? (
            <ImageContainer>
              <>
                {headlineIcon}
                <HeadlineSpacerStyled />
              </>
            </ImageContainer>
          ) : (
            withTopSpacer && <ImagelessSpacer />
          )}
          <ContentContainer>
            <BoxStyled>
              <HeadlineStyled>
                <Typography
                  variant={typography.heading02Large}
                  color={solidColors.midnight}
                  align="center"
                >
                  {headline}
                </Typography>
              </HeadlineStyled>
            </BoxStyled>
            {copy && (
              <HeadlineCopyStyled>
                <StyledP>{copy}</StyledP>
              </HeadlineCopyStyled>
            )}

            {body && <BodyStyled>{body}</BodyStyled>}
            <PopUpChildrenContainer $width={childWidth}>
              {children}
            </PopUpChildrenContainer>
          </ContentContainer>
          {renderButtons(primary, secondary)}
        </PopupCardWrapper>
      </DialogStyled>
    </div>
  );
}
