import styled from 'styled-components';

export const ListPaperContainer = styled.div`
  width: 100%;
  padding: 1px 2rem 2rem 2rem;
  align: center;
  margin: 1rem auto 0 auto;
  min-width: 100%;
`;

export const SubtitleContainer = styled.div`
  margin: 1rem 0 1rem 0;
`;
