import styled from 'styled-components';
import { solidColors } from '@constants/styles/colors.constants';
import { List, ListItem } from '@library';

export const InputLabelStyled = styled.div`
  position: relative;
  left: 0;
  max-width: 7rem;
`;

export const ListStyled = styled(List)`
  border: solid 1px ${solidColors.mediumGray.color};
  border-radius: 10px;
  width: 80%;
  height: 11.5rem;
  margin: 2rem auto 1rem;
  overflow: auto;
`;

export const ListItemStyled = styled(ListItem)`
  padding: 0 16px 0 16px;
  justify-content: flex-start;
`;

export const ItemFormattedStyled = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const NoResultTypography = styled.div`
  margin-left: 1rem;
`;
