import styled from 'styled-components';

export const CreditCardContainer = styled.section<{
  $isFirst: boolean;
  $isLast: boolean;
}>`
  display: flex;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  ${({ $isFirst, $isLast }) => {
    const firstChildStyle = $isFirst
      ? 'align-items: flex-start;'
      : '';
    const lastChildStyle = $isLast
      ? 'align-items: flex-end;'
      : '';
    return firstChildStyle + lastChildStyle;
  }}
`;

export const CCElementContainer = styled.div`
  margin-right: 1rem;
`;

export const CCElementsSection = styled.div`
  display: flex;
  align-items: center;
`;

export const CCImageContainer = styled.div`
  margin: 0 0 0 10px;
`;

export const CCElementVerticalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const IconContainer = styled.section<{
  $isFirst: boolean;
  $isLast: boolean;
}>`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${({ $isFirst, $isLast }) => {
    const firstChildStyle = $isFirst
      ? 'padding-bottom: .75rem;'
      : '';
    const lastChildStyle = $isLast
      ? 'padding-top: .75rem;'
      : '';
    return firstChildStyle + lastChildStyle;
  }}
`;
