import styled from 'styled-components';
import { Typography } from '@atoms';
import { Snackbar } from '@components/library';
import { typography } from '@constants/styles/typography.constants';
import { solidColors } from '@constants/styles/colors.constants';
import { useAppSelector } from '@app/hooks';

const StyledBox = styled.div`
  box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
  width: fit-content;
  background: ${solidColors.upWhite.color};
  padding: 24px;

  &.upBlue {
    width: 40vw;
    color: ${solidColors.upWhite.color};
    background: ${solidColors.upBlue.color};

    > h5 {
      color: ${solidColors.upWhite.color};
    }
  }
`;

const CardSnackbar = () => {
  const {
    isVisible,
    snackBarCopy,
    inApp,
    styleType,
  } = useAppSelector((state) => state.cardSnackBar);

  return (
    <>
      {isVisible && (
        <Snackbar
          id="CardSnackbar"
          anchorOrigin={{
            vertical: inApp ? 'top' : 'bottom',
            horizontal: 'center',
          }}
          data-testid={'CardSnackbar'}
          open={true}
        >
          <StyledBox
            className={styleType}
          >
            <Typography
              variant={typography.heading05Large}
              align={'center'}
            >
              {snackBarCopy}
            </Typography>
          </StyledBox>
        </Snackbar>
      )}
    </>
  );
};

export default CardSnackbar;
