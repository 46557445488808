import styled from 'styled-components';
import { BaseButton, Typography } from '@atoms';
import { typography } from '@constants/styles/typography.constants';
import { solidColors } from '@constants/styles/colors.constants';

const ButtonGroup = styled('div')`
  display: flex;
  justify-content: center;
  border: 1px solid ${solidColors.mediumGray.color};
  border-radius: 10px;
`;

const ButtonStyled = styled(BaseButton)`
  width: 100%;
  height: 100%;
  border-radius: 8px;
  padding-top: 20px;
  padding-bottom: 20px;
  font-weight: bold;
  &.active {
    box-shadow: 0 5px 10px 0 rgb(0, 0, 0, 0.08);
    background-color: ${solidColors.upWhite.color};
  }
`;

const SegmentedNavigation = ({
  segments,
  selectedSegment,
  onClick,
}: any) => {
  const getColor = (segment: any) => {
    return selectedSegment.label === segment.label
      ? solidColors.upBlue.color
      : solidColors.darkGray.color;
  };

  return (
    <ButtonGroup>
      {segments.map((segment: any, index: number) => (
        <ButtonStyled
          key={index}
          data-testid={`toggle-${index}`}
          className={`${
            selectedSegment.label === segment.label
              ? 'active'
              : ''
          }`}
          onClick={() => onClick(index)}
        >
          <Typography
            variant={typography.cta}
            color={{
              color: getColor(segment),
              fontWeight: 'bold',
            }}
            truncate={1}
          >
            {segment.label}
          </Typography>
        </ButtonStyled>
      ))}
    </ButtonGroup>
  );
};

export default SegmentedNavigation;
