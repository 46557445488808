import { IContainer } from './ContainerVerticalSpacer.interfaces';
import { StyledContainer } from './ContainerVerticalSpacer.styles';
import { SpacerType } from '@components/types';

const ContainerVerticalSpacer = ({
  spacerType,
}: IContainer) => {
  let px = spacerType;
  if (typeof spacerType === 'string') {
    px =
      SpacerType[spacerType as keyof typeof SpacerType] ??
      0;
  }
  return <StyledContainer $height={`${px}px`} />;
};

export default ContainerVerticalSpacer;
