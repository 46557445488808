import { useEffect, useRef } from 'react';
import { IconButton } from '@library';
import { hostedImage } from '@constants/images/images.constants';
import {
  BackIconStyled,
  CardHeaderStyled,
  CardContainer,
  PlaneImg,
  PageBackgroundStyled,
  LoginCardContainer,
} from './LogInContainer.styles';
import { useNavigate } from 'react-router-dom';
import { BackgroundType } from '@atoms/PageBackground/PageBackground';

import { determinePathToSendUser } from './LoginContainer.utils';
import { paths } from '@routing/routerPaths';
import { Elevation } from '@wheelsup/wu-react-components';

interface ILogInContainer {
  isRedirecting?: boolean;
  backButtonPath?: string;
  children?: JSX.Element | JSX.Element[] | null;
}

const LogInContainer = ({
  backButtonPath,
  children,
  isRedirecting,
}: ILogInContainer) => {
  const navigate = useNavigate();
  const planeElement = useRef(null);

  useEffect(() => {
    if (isRedirecting) {
      const path = determinePathToSendUser();
      window.location.assign(path);
    }
  }, [isRedirecting]);

  return (
    <PageBackgroundStyled
      metadata={undefined}
      backgroundType={BackgroundType.SIGNIN}
      animate={true}
    >
      <>
        <CardContainer>
          <PlaneImg
            ref={planeElement}
            src={hostedImage.wheelsUpPlane}
          />
          <LoginCardContainer>
            <Elevation
              variant="raisedLevel8dp"
              padding={32}
            >
              <CardHeaderStyled
                action={
                  backButtonPath ? (
                    <IconButton
                      onClick={() => {
                        backButtonPath === paths.SIGNIN
                          ? window.location.replace(
                              paths.SIGNIN,
                            )
                          : navigate(backButtonPath);
                      }}
                    >
                      <BackIconStyled data-testid="back-icon-button" />
                    </IconButton>
                  ) : null
                }
              />
              {children}
            </Elevation>
          </LoginCardContainer>
        </CardContainer>
      </>
    </PageBackgroundStyled>
  );
};

export default LogInContainer;
