import styled from 'styled-components';
import {
  solidColors,
  surfaceColors,
} from '@constants/styles/colors.constants';
import { typography } from '@constants/styles/typography.constants';
import {
  BaseTooltip,
  Icon,
} from '@wheelsup/wu-react-components';

export const DatePickerContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  font-family: ${typography.body1.family};
`;

export const DatePopupContainer = styled.div<{
  error?: boolean;
}>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  ${({ error }) => (error ? 'top: 25px' : '')}
`;

export const TooltipWithZIndex = styled(BaseTooltip)`
  z-index: 100;
`;

export const TooltipContent = styled.div`
  padding: 10px;
  font-family: 'Aktiv';
`;
export const DatePickerIcon = styled.div<{
  disable?: boolean;
}>`
  position: absolute;
  right: 0;
  top: 18px;
  color: ${({ disable }) =>
    disable
      ? `${solidColors.mediumGray.color}`
      : `${solidColors.upBlue.color}`};
`;

export const DateRangeStyled = styled.div<{
  peekColor?: boolean;
  dateSelected?: boolean;
}>`
  display: flex;

  .rdrCalendarWrapper {
    padding-top: -100px;
    width: 100%;
  }

  .rdrMonthAndYearWrapper,
  .rdrMonths {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;

    ${(props) => props.theme.breakpoints.down('md')} {
      width: 100%;
    }
  }

  .rdrMonths {
    ${(props) => props.theme.breakpoints.down('sm')} {
      justify-content: center;
    }
  }

  .rdrMonth {
    width: 22.69rem;
  }

  .rdrDay {
    height: 49px;

    &.rdrDayDisabled {
      background: none;
      .dayTypo {
        div {
          color: #6b778b;
        }
      }
    }

    &.rdrDayDisabled,
    &.rdrDayPassive {
      .skeletonContainer,
      .priceTypo {
        display: none;
      }
    }

    &:not(.rdrDayHovered) {
      .rdrDayStartPreview,
      .rdrDayEndPreview {
        border: none;
      }
    }
  }

  .rdrMonthName {
    font-family: ${typography.body1Bold.family};
    -webkit-font-smoothing: antialiased;
    text-align: center;
    color: ${solidColors.midnight.color};
    font-size: 18px;
    margin-top: -78px;
    font-weight: unset;
  }

  // btns next and prev
  .rdrNextPrevButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 1px;
    background: none;
    padding: 0;
    transition: all 0.2s;

    margin-top: -50px;
    i {
      border: solid ${solidColors.upBlue.color};
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 3px;
    }
    &:hover {
      background: none;
      i {
        border-color: ${solidColors.midnight.color};
      }
    }
  }

  // only prev btn
  .rdrPprevButton {
    i {
      transform: rotate(135deg);
      margin-left: 3px;
    }
  }

  // only next btn
  .rdrNextButton {
    i {
      transform: rotate(-45deg);
      margin-right: 3px;
    }
  }

  // days of the week
  .rdrWeekDay {
    font-weight: 400;
    font-size: 14px;
    color: ${solidColors.darkGray.color};
    padding: 5px;
  }

  //Day
  .rdrDayNumber span {
    color: ${({ peekColor }) =>
      peekColor
        ? solidColors.upBlue.color
        : solidColors.midnight.color};
    font-size: 18px;
    font-weight: 500;
    font-family: ${typography.body1.family};
  }

  // selected day range
  .rdrDay:not(.rdrDayPassive) {
    .rdrInRange {
      & ~ .rdrDayNumber {
        span {
          color: ${solidColors.midnight.color};
        }
      }
    }

    .rdrStartEdge {
      & ~ .rdrDayNumber {
        span {
          color: ${solidColors.upWhite.color};
        }
      }
    }

    .rdrEndEdge,
    .rdrSelected {
      & ~ .rdrDayNumber {
        span {
          color: ${solidColors.upWhite.color};
        }
      }
    }

    .rdrStartEdge,
    .rdrEndEdge {
      + .rdrDayNumber {
        div[role='paragraph'] {
          color: ${solidColors.upBlue.color};
          font-weight: 700;
        }
      }
    }
  }

  .rdrInRange {
    background: ${({ dateSelected }) =>
      dateSelected
        ? solidColors.lightGray.color
        : solidColors.upWhite.color};
    top: 0;
    bottom: 0;
  }

  .rdrDayStartOfMonth .rdrInRange,
  .rdrDayStartOfWeek .rdrInRange {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .rdrDayEndOfMonth .rdrInRange,
  .rdrDayEndOfWeek .rdrInRange {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .rdrDayStartOfMonth .rdrDayInPreview,
  .rdrDayStartOfWeek .rdrDayInPreview {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  .rdrDayEndOfMonth .rdrDayInPreview,
  .rdrDayEndOfWeek .rdrDayInPreview {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .rdrStartEdge,
  .rdrSelected,
  .rdrEndEdge {
    background: ${surfaceColors.backgroundUpWhite.color};
    border: 2px solid ${solidColors.upBlue.color};
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 8px;
    margin-left: 0;
    margin-top: 0;
  }

  // disable days
  .rdrDayPassive {
    pointer-events: none;
    opacity: 0;
    .dayTypo div {
      color: ${solidColors.mediumGray.color};
    }
    .rdrInRange,
    .rdrStartEdge,
    .rdrEndEdge,
    .rdrSelected,
    .rdrDayStartPreview,
    .rdrDayInPreview,
    .rdrDayEndPreview {
      display: none;
    }
  }

  .rdrDayStartPreview,
  .rdrDayEndPreview {
    background: ${surfaceColors.backgroundUpWhite.color};
    border: 2px solid ${solidColors.upBlue.color};
    border-radius: 8px;
  }

  // Range Select Dates Preview
  .rdrDayStartPreview,
  .rdrDayInPreview,
  .rdrDayEndPreview {
    background: transparent;
    position: absolute;
    top: 0;
    bottom: 0;
    pointer-events: none;
    margin-left: 0;
    z-index: 0;
  }

  // year and month selectors - container
  .rdrMonthAndYearPickers {
    // year and month selectors
    select {
      text-transform: capitalize;
      color: orange;
      font-weight: 600;
      appearance: none;
      -webkit-appearance: none;
      border: 0;
      background: transparent;
      padding: 5px 30px 5px 10px;
      border-radius: 4px;
      outline: 0;
      background: url("data:image/svg+xml;utf8,<svg width='9px' height='6px' viewBox='0 0 9 6' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><g id='Artboard' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' transform='translate(-636.000000, -171.000000)'><g id='input' transform='translate(172.000000, 37.000000)' fill='%238B8B8C' fill-rule='nonzero'><g id='Group-9' transform='translate(323.000000, 127.000000)'><path d='M142.280245,7.23952813 C141.987305,6.92353472 141.512432,6.92361662 141.219585,7.23971106 C140.926739,7.5558055 140.926815,8.06821394 141.219755,8.38420735 L145.498801,13 L149.780245,8.38162071 C150.073185,8.0656273 150.073261,7.55321886 149.780415,7.23712442 C149.487568,6.92102998 149.012695,6.92094808 148.719755,7.23694149 L145.498801,10.7113732 L142.280245,7.23952813 Z' id='arrow'></path></g></g></g></svg>")
        no-repeat;
      background-position: right 8px center;
      cursor: pointer;
      text-align: center;
      display: none;
    }
  }
`;

export const DateDecorationIcon = styled(Icon)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 0;
  pointer-events: none;

  &.top {
    top: -7px;
  }

  &.bottom {
    bottom: 4px;
    @media (max-width: 760px) {
      bottom: 0px;
    }
  }
  svg {
    width: 9px;
    height: 9px;
  }
`;

export const DayDecorationTooltipInner = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
`;

export const ColorPeekDayContainer = styled.span<{
  peekColor?: boolean;
}>`
  ${({ peekColor }) =>
    peekColor
      ? `color: ${solidColors.jam.color} !important`
      : null}
`;

export const BorderBottom = styled.div<{
  disable?: boolean;
}>`
  border-bottom: solid 1px;
  border-color: ${({ disable }) =>
    disable
      ? `${solidColors.mediumGray.color}`
      : `${solidColors.midnight.color}`};

  height: 1px;
  margin-top: -3px;
`;

export const DisabledDay = styled.div`
  position: absolute;
  left: 0;
  top: -6px;
  width: 49px;
  height: 49px;
`;

export const SkeletonContainer = styled.div`
  position: absolute;
  left: 50%;
  bottom: 2px;
  transform: translateX(-50%);
  width: 30px;
`;

export const PriceTypo = styled.div<{
  isCheap: boolean;
}>`
  position: absolute;
  left: 50%;
  bottom: -12px;
  transform: translateX(-50%);
  font-family: 'AktivGrotesk-Bold';
  -webkit-font-smoothing: antialiased;
  font-size: 10px;
  color: ${({ isCheap }) =>
    isCheap
      ? solidColors.basil.color
      : solidColors.midnight.color};
`;
