import styled from 'styled-components';
import { Typography } from '@atoms';
import { solidColors } from '@constants/styles/colors.constants';
import {
  Button,
  Divider,
} from '@wheelsup/wu-react-components';
import { theme } from '@constants/styles/theme.constants';

export const Container = styled.div<{
  maxHeight?: string;
  offsetTop?: string;
  picker?: string;
  width?: string;
  isCalendar?: boolean;
}>`
  display: flex;
  flex-direction: column;
  position: absolute;
  height: auto;
  min-height: 280px;
  max-height: ${({ maxHeight }) => maxHeight ?? '730px'};
  top: ${({ offsetTop }) => offsetTop ?? ''};
  border-radius: 5px;
  background-color: white;
  z-index: 1;
  // for any of the subpages that pop out of a calendar, time, passenger picker
  // I HATE THIS DEEPLY SOMEONE HELP
  min-width: ${({ isCalendar }) =>
    isCalendar ? 'unset' : '-moz-available'};
  min-width: ${({ isCalendar }) =>
    isCalendar ? 'unset' : '-webkit-fill-available;'};
  min-width: ${({ isCalendar }) =>
    isCalendar ? 'unset' : 'fill-available'};
  width: ${({ width }) => width ?? '390px'};
  ${(props) => props.theme.breakpoints.down('sm')} {
    width: unset;
    max-width: 100%;
  }
  padding: 24px;
  ${(props) => props.theme.breakpoints.down('sm')} {
    padding: 16px;
  }
  gap: 30px;

  box-shadow: 0px 4px 8px 2px rgba(0, 0, 0, 0.16),
    0px -2px 4px 0px rgba(0, 0, 0, 0.08);
  justify-content: space-between;
`;

export const CopyContainer = styled.div<{
  copy?: boolean;
}>`
  display: flex;
  justify-content: ${({ copy }) =>
    copy ? 'space-between' : 'flex-end'};
  align-items: center;
  margin-top: -25px;
`;

export const CopyWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 5px;
  max-width: 400px;
`;

export const ContentContainer = styled.div<{
  isScrollEnable?: boolean;
  height?: string;
}>`
  overflow-y: ${({ isScrollEnable }) =>
    isScrollEnable ? 'scroll' : 'unset'};
`;

export const CopyStyled = styled(Typography)`
  font-size: 12px;
  line-height: 14px;
`;

export const BtnContainer = styled.div<{
  isCalendar?: boolean;
  alignRight?: boolean;
  topGap?: number;
}>`
  border-block-start: ${({ isCalendar }) =>
    isCalendar ? '1px solid rgba(0, 0, 0, 0.12)' : '0px'};

  padding-block-start: ${({ topGap = 24 }) =>
    `${topGap}px`};
  display: flex;
  justify-content: ${({ alignRight }) =>
    alignRight ? 'flex-end' : 'space-between'};
  width: 100%;
  @media (max-width: ${theme.breakpoints.values.md}px) {
    flex-direction: column;
  }
`;

export const CustomPeekDayContainer = styled.div<{
  singleNum?: boolean;
}>`
  height: 7px;
  width: 8px;
  border-radius: 100%;
  background: ${solidColors.jam.color};
  top: -3px;
  right: 17px;
`;

export const ChildContainer = styled.div<{
  height?: string;
}>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  height: ${({ height }) => height ?? ''};
`;

export const LegendSection = styled.section`
  & > h2 {
    font-size: 1rem;
    margin-block-start: 0;
  }

  & > ul {
    list-style-type: none;
    font-size: 0.8rem;
    margin: 0;
    padding: 0;
    display: grid;
    @media screen and (min-width: 1000px) {
      grid-template-columns: 1fr 1fr;
    }
    grid-row-gap: 0.5rem;
  }

  & span {
    display: inline-block;
    width: 1rem;
    margin-inline-end: 0.5rem;
  }

  & li {
    width: fit-content;
  }
  @media (max-width: ${theme.breakpoints.values.md}px) {
    padding: 20px;
    width: 311px;
  }
  padding-bottom: 15px;
`;

export const PriceAside = styled.aside`
  text-align: right;
  @media screen and (min-width: 1000px) {
    margin-inline-end: 1rem;
  }
`;

export const AsideContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0;
  min-width: 150px;
`;

export const LegendContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledDivider = styled(Divider)`
  margin-top: 10px;
`;

export const NextButtonStyled = styled(Button)<{
  isCalendar?: boolean;
}>`
  width: 150px;
  @media (max-width: ${theme.breakpoints.values.sm}px) {
    width: ${({ isCalendar }) =>
      isCalendar ? '113px' : '100%'};
  }
`;

export const StyledList = styled.ul`
  margin-top: 16px !important;
`;

export const StyledListItemContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 8px;
`;
