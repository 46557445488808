import { useCarouselSlides, useViewport } from '@hooks';
import { useEffect, useRef, useState } from 'react';
import { ICarouselSmallArrowWebOnly } from './CarouselSmallArrowWebOnly.interfaces';
import {
  ArrowCircle,
  ArrowContainer,
  ArrowLabel,
  BackArrow,
  CarouselContainer,
  CarouselSlideWrapper,
  OverflowHiddenContainer,
} from './CarouselSmallArrowWebOnly.styles';
import Slides from './Slides';
import { ImageImgix, Typography } from '@atoms';
import { typography } from '@constants/styles/typography.constants';
import { solidColors } from '@constants/styles/colors.constants';
import { hostedImage } from '@constants/images/images.constants';
import { theme } from '@constants/styles/theme.constants';

const SLIDE_MARGIN = 8;
const SLIDE_PADDING = 16;
const NEWS_CARD_OVERRIDE_WIDTH = 340;

function CarouselSmallArrowWebOnly({
  children = {},
  inComponent = false,
}: ICarouselSmallArrowWebOnly) {
  const { width } = useViewport();
  const numItemsPerSlide = () => {
    let numSlides = 0; // needs to be 0 for other math
    if (width >= theme.breakpoints.values.lg) {
      numSlides = 3;
    } else if (width >= theme.breakpoints.values.md) {
      numSlides = 2;
    }
    return numSlides;
  };
  const parentRef = useRef<HTMLDivElement>(null);
  const childRef = useRef<HTMLDivElement>(null);
  const [
    fullCardWidthOverride,
    setFullCardWidthOverride,
  ] = useState(false);
  const {
    slidesPerView,
    totalSlides,
    transformWidth,
    slideView,
    startIndex,
    activeForward,
    activeBack,
    allActiveFallback,
    slideMargin,
    slides,
    handleArrowBack,
    handleArrowForward,
  } = useCarouselSlides({
    slideRef: childRef,
    parentContainer: parentRef,
    children,
    inComponent,
    width,
    fullCardWidthOverride:
      fullCardWidthOverride ||
      width <= theme.breakpoints.values.sm,
    slideMargin: SLIDE_MARGIN,
    slidePadding: SLIDE_PADDING,
  });
  const [showArrows, setShowArrows] = useState(false);

  useEffect(() => {
    setShowArrows(
      (totalSlides > 1 ||
        width <= theme.breakpoints.values.md) &&
        (!allActiveFallback ||
          inComponent ||
          fullCardWidthOverride) &&
        slides.length > 1,
    );
  }, [
    totalSlides,
    allActiveFallback,
    inComponent,
    fullCardWidthOverride,
    slides.length,
  ]);

  useEffect(() => {
    if (parentRef.current) {
      // Checking if the parent has a flight card
      // manually since we have to rely on dynamic rendering for the children
      const flightCard = parentRef.current.querySelector(
        '.CardRequestedBookedFlightStatusCard',
      );
      const newsCard = parentRef.current.querySelector(
        '.CardImageTitleHeadlineCTA',
      );

      // if (flightCard || lowPriorityAlertCard || width <= theme.breakpoints.values.md) {
      if (
        flightCard ||
        (width <= theme.breakpoints.values.md &&
          !newsCard) ||
        width <= NEWS_CARD_OVERRIDE_WIDTH
      ) {
        setFullCardWidthOverride(true);
      } else {
        setFullCardWidthOverride(false);
      }
    }
  }, [width]);

  if (slides.length === 0) {
    // adding a fallback incase we get 0 children
    return null;
  }

  return (
    <CarouselContainer
      ref={parentRef}
      data-id="CarouselSmallArrowWebOnly"
      id="CarouselSmallArrowWebOnly"
      className="CarouselSmallArrowWebOnly"
    >
      <CarouselSlideWrapper
        $isMobile={allActiveFallback && !inComponent}
      >
        <OverflowHiddenContainer>
          <Slides
            slideRef={childRef}
            transformAmount={transformWidth}
            slidesPerView={slidesPerView}
            startIndex={startIndex}
            slideMargin={slideMargin}
            allActiveFallback={
              allActiveFallback && !inComponent
            }
            inComponent={
              inComponent || fullCardWidthOverride
            }
            totalWidth={parentRef.current?.offsetWidth || 0}
            numItemsPerSlide={numItemsPerSlide()}
            fullCardWidthOverride={fullCardWidthOverride}
          >
            {slides}
          </Slides>
        </OverflowHiddenContainer>
      </CarouselSlideWrapper>
      {showArrows ? (
        <ArrowContainer>
          <ArrowCircle
            $active={activeBack}
            onClick={handleArrowBack}
          >
            <BackArrow
              src={hostedImage.roundedChevronRight}
              alt="Back Arrow"
              width={12}
              height={12}
              filter={activeBack ? 'upBlue' : 'darkGray'}
            />
          </ArrowCircle>
          <ArrowLabel>
            <Typography
              variant={typography.body2}
              color={solidColors.darkGray}
            >
              {slideView} of {totalSlides}
            </Typography>
          </ArrowLabel>
          <ArrowCircle
            $active={activeForward}
            onClick={handleArrowForward}
          >
            <ImageImgix
              src={hostedImage.roundedChevronRight}
              alt="Back Arrow"
              width={12}
              height={12}
              filter={activeForward ? 'upBlue' : 'darkGray'}
            />
          </ArrowCircle>
        </ArrowContainer>
      ) : null}
    </CarouselContainer>
  );
}

export default CarouselSmallArrowWebOnly;
