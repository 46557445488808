import { Container } from '@library';
import { Typography } from '@atoms';
import { solidColors } from '@constants/styles/colors.constants';
import { makeStyles } from '@library';
import { Theme } from '@material-ui/core';
import styled from 'styled-components';

export const useTabStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingLeft: '0',
    paddingRight: '0',
    textTransform: 'none',
    textAlign: 'left',
    fontFamily: theme.typography.fontFamily,
    letterSpacing: '-0.31px',
    lineHeight: '24px',
    marginRight: '2.5rem',
  },
  selected: {
    color: solidColors.midnight.color,
  },
  textColorInherit: {
    color: solidColors.midnight.color,
  },
  wrapper: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    '& svg': {
      width: '1rem',
    },
    '& .inline-svg': {
      marginRight: '0.5rem',
    },
  },
}));

export const useTabsStyles = makeStyles({
  indicator: {
    margin: 'auto',
    backgroundColor: solidColors.midnight.color,
    height: '4px',
  },
});

export const StyledTypography = styled(Typography)`
  letter-spacing: -0.62px;
  line-height: 40px;
  color: ${solidColors.midnight.color};
`;

export const StyledContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  padding: 0;
  border-bottom: 1px solid ${solidColors.mediumGray.color};
`;
