import { CalendarDateType } from '@components/types';
import { DateTime } from 'luxon';
import { Currency } from '@graphql/generated/graphql';
import { IDecorationMap } from '../decorationLoader';

export const decorationMap = {
  peakDay: {
    icon: 'dot',
    color: 'warning',
    position: 'top',
    disabled: false,
  },
  airportClosed: {
    icon: 'error-x',
    color: 'tertiary',
    position: 'bottom',
    disabled: true,
  },
  alreadyBooked: {
    icon: 'airplane',
    color: 'tertiary',
    position: 'bottom',
    disabled: true,
  },
  withinCalloutTime: {
    icon: 'price-tag',
    color: 'tertiary',
    position: 'bottom',
    disabled: true,
  },
  customQuote: {
    icon: 'price-tag',
    color: 'tertiary',
    position: 'bottom',
    disabled: true,
  },
};

export const priorityOrder: Record<
  CalendarDateType,
  number
> = {
  peakDay: 4,
  airportClosed: 3,
  alreadyBooked: 2,
  withinCalloutTime: 1,
  customQuote: 0,
};

export const manipulateDateForMap = (date: Date) => {
  const renderedDate = DateTime.fromJSDate(date);
  // neccesary due to the way the daycontentprops are structured
  return (
    renderedDate.toISO({
      includeOffset: false,
      suppressMilliseconds: false,
    }) + 'Z'
  );
};

export const formatPrice = (price: Currency) => {
  return price
    ? `${price.symbol}${price.amount.toLocaleString(
        'en-US',
        {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        },
      )}`
    : '';
};

export const formatDate = (date: Date) =>
  DateTime.fromJSDate(date).toFormat('yyyy-MM-dd');

export const requiresQuote = (
  date: Date,
  dateDecorationMap: IDecorationMap | undefined,
) => {
  if (dateDecorationMap) {
    const decoration = dateDecorationMap[formatDate(date)];
    return [
      'customQuote',
      'withinCalloutTime',
      'alreadyBooked',
    ].includes(decoration?.bottom?.dateType as string);
  } else {
    return false;
  }
};

export const extractAndFormatPrice = (
  date: Date,
  pricingData: Map<any, any> | undefined,
) => {
  if (pricingData) {
    const priceData = pricingData.get(formatDate(date));
    if (priceData) {
      return formatPrice(priceData.price);
    } else {
      return 'Request Quote';
    }
  } else {
    return 'Request Quote';
  }
};
