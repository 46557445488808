import { RadioChecked, RadioUnchecked } from '@atoms';
import { IRadio } from './Radio.interfaces';
import { RadioControlLabel } from './Radio.styles';
import { Radio } from '@library';
import { solidColors } from '@constants/styles/colors.constants';

const CustomRadio = ({
  marginLeft,
  marginRight,
  checked,
  label,
  labelPlacement,
  disabled = false,
  onClick,
}: IRadio) => {
  return (
    <RadioControlLabel
      $marginLeft={marginLeft}
      $marginRight={marginRight}
      control={
        <Radio
          checked={checked}
          onClick={onClick}
          icon={
            <RadioUnchecked
              color={
                disabled
                  ? solidColors.darkGray.color
                  : solidColors.midnight.color
              }
            />
          }
          checkedIcon={<RadioChecked />}
          disabled={disabled}
          inputProps={{
            'data-testid': `radio-${label}`,
          }}
        />
      }
      label={label}
      labelPlacement={labelPlacement}
    />
  );
};

export default CustomRadio;
