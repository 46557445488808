import ArrowIconButton from '@molecules/ArrowIconButton/ArrowIconButton';
import { IButtonContainerProps } from './ImageCarouselButtonContainer.interfaces';
import { ButtonContainerWrapper } from './ImageCarouselButtonContainer.styles';

const CarouselButtonContainer = ({
  disabledBack,
  disabledForward,
  backFunction,
  forwardFunction,
  marginTop,
  widthRem,
  imageCarousel,
  arrowsInside,
  arrowsSize,
}: IButtonContainerProps) => {
  return (
    <ButtonContainerWrapper marginTop={marginTop}>
      <ArrowIconButton
        imageCarousel={imageCarousel}
        disabled={disabledBack}
        clickHandler={backFunction}
        arrowsInside={arrowsInside}
        arrowsSize={arrowsSize}
      />
      <ArrowIconButton
        forward
        widthRem={widthRem}
        disabled={disabledForward}
        clickHandler={forwardFunction}
        arrowsInside={arrowsInside}
        arrowsSize={arrowsSize}
      />
    </ButtonContainerWrapper>
  );
};

export default CarouselButtonContainer;
