import { InputAdornment } from '@library';
import { Box, Typography } from '@atoms';
import {
  CustomCssTextField,
  CustomCssTextareaAutosize,
  inputUseStyles,
} from '@molecules/Input/Input.styles';
import {
  InputBaseProps,
  InputBaseType,
} from './InputBase.interfaces';
import { ImageImgix } from '@atoms';
import {
  StyledLeadingCTA,
  StyledInputBase,
  ActionWrapper,
} from './InputBase.styles';
import { typography } from '@constants/styles/typography.constants';
import { solidColors } from '@constants/styles/colors.constants';
import PhoneNumberMask from '@atoms/PhoneNumberMask';
import CreditCardExpirationMask from '@atoms/CreditCardExpirationMask/CreditCardExpirationMask';
import CurrencyMask from '@atoms/CurrencyMask/CurrencyMask';
import SimpleDateMask from '@atoms/SimpleDateMask/SimpleDateMask';
import CustomInputHelper from '@atoms/InputHelper/CustomInputHelper';

export default function InputBase({
  error,
  maxCharactersAllowed,
  minCharactersAllowed,
  payloadKey,
  value,
  onChange,
  title,
  type,
  errorMessage,
  datatestid,
  placeholder,
  styleType,
  leadingHelperCopy,
  trailingHelperCopy,
  leadingHelperAction,
  trailingHelperAction,
  trailingCTA,
  leadingCTA,
  textArea,
  isRequired,
  readOnly,
  fieldCopy,
  disabled,
  variant,
  ...rest
}: InputBaseProps) {
  const classes = inputUseStyles({
    error,
    paddingLeft: false,
  });

  const renderError = () => {
    if (error && errorMessage == '') {
      return undefined;
    } else if (error) {
      return { message: errorMessage };
    } else {
      return undefined;
    }
  };
  const InputTag = textArea
    ? CustomCssTextareaAutosize
    : CustomCssTextField;

  const injectMask = () => {
    switch (type) {
      case InputBaseType.Phone:
        return PhoneNumberMask as any;
      case InputBaseType.SimpleDate:
        return SimpleDateMask as any;
      case InputBaseType.CreditCardExpiration:
        return CreditCardExpirationMask;
      case InputBaseType.Currency:
        return CurrencyMask;
      default:
        return undefined;
    }
  };
  return (
    <>
      {textArea ? (
        <Box marginBottom={3 * 8}>
          <Typography
            variant={typography.body1}
            color={solidColors.midnight}
          >
            {title}
          </Typography>
        </Box>
      ) : null}
      <div>
        <StyledInputBase $width={rest.width}>
          {!!leadingCTA?.image?.url && !textArea ? (
            <StyledLeadingCTA>
              <ActionWrapper
                onClick={leadingCTA?.action?.actionMethod}
              >
                <ImageImgix
                  src={leadingCTA?.image?.url ?? ''}
                  filter={
                    leadingCTA?.image?.tintColor ?? ''
                  }
                  width={20}
                  height={20}
                  alt="Leading cta"
                  radius="4"
                />
              </ActionWrapper>
            </StyledLeadingCTA>
          ) : null}
          <InputTag
            {...rest}
            title={title}
            payloadKey={payloadKey}
            $width={rest.width}
            variant={variant}
            type={type}
            error={error}
            required={isRequired}
            name={payloadKey}
            label={title}
            value={value}
            defaultValue={fieldCopy}
            onChange={onChange}
            disabled={disabled}
            InputLabelProps={{
              classes: {
                root: classes.label,
                shrink: classes.shrink,
              },
            }}
            FormHelperTextProps={{
              classes: {
                root: classes.helperText,
              },
            }}
            inputProps={{
              maxLength: maxCharactersAllowed,
              minLength: minCharactersAllowed,
              'data-testid': datatestid,
              readOnly: readOnly,
            }}
            placeholder={placeholder}
            color={styleType}
            InputProps={{
              inputComponent: injectMask(),
              endAdornment: trailingCTA?.image?.url ? (
                <ActionWrapper
                  onClick={
                    trailingCTA?.action?.actionMethod
                  }
                >
                  <InputAdornment position="end">
                    <ImageImgix
                      src={trailingCTA?.image?.url ?? ''}
                      filter={
                        trailingCTA?.image?.tintColor ?? ''
                      }
                      width={20}
                      height={20}
                      alt="Trailing cta"
                      radius="4"
                    />
                  </InputAdornment>
                </ActionWrapper>
              ) : null,
              classes: {
                root: classes.input,
              },
            }}
          />
        </StyledInputBase>
        <CustomInputHelper
          leadingHelperCopy={leadingHelperCopy}
          trailingHelperCopy={trailingHelperCopy}
          leadingHelperAction={leadingHelperAction}
          trailingHelperAction={trailingHelperAction}
          error={renderError()}
        />
      </div>
    </>
  );
}
