import { IRegexLibrary } from '@components/types';

export const emailRegex = /^[\w-.+]+@([\w-]+\.)+[\w-]{2,7}$/;

export const phoneRegex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;

export const regexDict: IRegexLibrary = {
  validEmailAddress: emailRegex,
  eightOrMoreCharacters: /.{8,}/,
  bothUpperAndLowerCase: /(?=.*[a-z])(?=.*[A-Z])/,
  atLeastOneNumber: /(?=.*[0-9])/,
  atLeastOneSpecialCharacter: /(?=.*[!@#$%^&*])/,
  oneOrMoreCharacters: /.+/,
  validPhoneNumber: phoneRegex,
};
