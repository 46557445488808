import React from 'react';
import { TextStyleType } from '@components/types';
import { deconstructStyleType } from '@components/utils/deconstructStyleType';
import styled from 'styled-components';
import { Typography } from '@atoms';
import { solidColors } from '@constants/styles/colors.constants';
import { typography } from '@constants/styles/typography.constants';

interface IBorderedTextGroup {
  title: string;
  styleType: string;
  alignmentState?: string;
  borderRadius?: string;
}

const Container = styled.div<{
  $alignmentState: () =>
    | 'center'
    | 'flex-end'
    | 'flex-start';
}>`
  width: 100%;
  display: flex;
  justify-content: ${({ $alignmentState }) =>
    $alignmentState()};
`;

const TextContainer = styled.div<{
  $color?: string;
  $borderRadius: string;
}>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
  gap: 10px;

  width: 190px;
  min-width: fit-content;
  height: 26px;

  background: rgba(255, 255, 255, 0.2);
  border: 1px solid
    ${({ $color }) =>
      solidColors[$color as keyof typeof solidColors]
        .color};

  border-radius: ${({ $borderRadius }) => $borderRadius};
`;

function BorderedTextGroup({
  title,
  styleType = 'caption-bold_Up White',
  alignmentState = 'left',
  borderRadius = '4px',
}: IBorderedTextGroup) {
  const {
    typographyType,
    colorType,
  } = deconstructStyleType(styleType as TextStyleType);

  const align = () => {
    switch (alignmentState) {
      case 'center':
        return 'center';
      case 'right':
        return 'flex-end';
      default:
        return 'flex-start';
    }
  };

  return (
    <Container $alignmentState={align}>
      <TextContainer
        $color={colorType ?? 'upWhite'}
        $borderRadius={borderRadius}
      >
        <Typography
          variant={
            typography[
              typographyType as keyof typeof typography
            ]
          }
          color={
            solidColors[
              colorType as keyof typeof solidColors
            ]
          }
        >
          {title}
        </Typography>
      </TextContainer>
    </Container>
  );
}

export default BorderedTextGroup;
