import { Typography } from '@atoms';
import ContactInfoActionItemWebOnly from '@atoms/ContactInfoActionItemWebOnly/ContactInfoActionItemWebOnly';
import { solidColors } from '@constants/styles/colors.constants';
import { typography } from '@constants/styles/typography.constants';
import { ISubPageAccountContact } from './SubPageAccountContact.interfaces';
import {
  CardStyled,
  HeadingStyled,
} from './SubPageAccountContact.styles';
import { Elevation } from '@wheelsup/wu-react-components';

export default function SubPageAccountContact({
  contactInfo,
}: ISubPageAccountContact) {
  return (
    <>
      {contactInfo.map((ci, i) => {
        return (
          <Elevation
            key={i}
            variant="raisedLevel2"
            padding={0}
          >
            <CardStyled $maxWidth="100%">
              <HeadingStyled>
                <Typography
                  variant={typography.heading02Large}
                >
                  {ci.titleTextView.copy}
                </Typography>
                <Typography
                  variant={typography.body2}
                  color={solidColors.darkGray}
                >
                  {ci.copyTextView.copy}
                </Typography>
              </HeadingStyled>
              <ContactInfoActionItemWebOnly
                {...ci.contactInfoActionOne}
              />
              <ContactInfoActionItemWebOnly
                {...ci.contactInfoActionTwo}
              />
            </CardStyled>
          </Elevation>
        );
      })}
    </>
  );
}
