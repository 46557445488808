import { TextFieldProps } from '@components/library';
import { InputCustomProps } from '@components/molecules/Input/Input.interfaces';

export type InputBaseProps = Omit<
  InputCustomProps,
  'type'
> &
  Omit<TextFieldProps, 'label' | 'name'>;

export enum InputBaseType {
  Standard = 'standard',
  FirstName = 'firstName',
  LastName = 'lastName',
  Email = 'email',
  Password = 'password',
  NewPassword = 'newPassword',
  SmsCode = 'smsCode',
  Phone = 'phone',
  SimpleDate = 'simpleDate',
  CreditCardExpiration = 'creditCardExpiration',
  Currency = 'currency',
}
