import { InputWrapper } from '@components/molecules/FormFlightLeg/SearchFlight.styles';
import { Icon, Input } from '@wheelsup/wu-react-components';
import { useContext } from 'react';
import { FlightSearchContext } from '../FlightSearchContext/FlightSearchContext';
import { CalendarPricingRoundTrip } from '@components/molecules/DateSection/CalendarPricing/CalendarPricingRoundTrip';
import { CalendarPricingSingle } from '@components/molecules/DateSection/CalendarPricing/CalendarPricingSingle';
import { format } from 'date-fns';
import { useFlightSearch } from '../FlightSearchContext/FlightSearchHooks';
import {
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { getCalendarDecorations } from '@services/airportSubpageSearchService/airportSubpageSearchService';
import {
  IDecorationMap,
  restructureDateDecorations,
} from '@components/molecules/DateSection/CalendarPricing/decorationLoader';
import { fetchPricingData } from '@components/molecules/DateSection/priceLoader';
import { PricingParameters } from '../FlightSearchContext/FlightSearchContext.types';
import { flightTypes } from '../FlightSearchContext/FlightSearchContext.types';
import { endOfNextMonth, startOfCurrentMonth } from '@components/molecules/DateSection/dateHelper';

export type TSharedProps = {
  pricingData: Map<any, any> | undefined;
  dateDecorationMap: IDecorationMap | undefined;
  isPricingDataLoading: boolean;
  decorationMapLoading: boolean;
};

const CalendarSection = ({
  legNumber,
}: {
  legNumber: number;
}) => {
  const {
    legs,
    updateLeg,
    displayDate,
    isRoundTrip,
    flightType,
    pricingRequest,
    updatePricingRequest,
    contextDispatch,
  } = useFlightSearch(useContext(FlightSearchContext));
  const client = useQueryClient();

  const isMultiLeg = flightType === flightTypes.multiCity;

  const { dateError, dateOpen, date } = legs[legNumber];

  const CalendarPricing = isRoundTrip
    ? CalendarPricingRoundTrip
    : CalendarPricingSingle;

  const determineDisplayValue = () => {
    if (isMultiLeg) {
      return date ? format(date, 'EEEE, MMMM d') : '';
    }
    return displayDate;
  };

  const determineLabel = () => {
    if (isMultiLeg) {
      return `Leg ${legNumber + 1} Date`;
    }
    if (isRoundTrip) return 'Depart - Return';
    return 'Date';
  };

  const {
    data: dateDecorationMap,
    isLoading: decorationMapLoading,
  } = useQuery({
    queryKey: [
      'GetDatePickerData',
      JSON.stringify(pricingRequest),
    ],
    queryFn: async () => {
      const data = await getCalendarDecorations(
        legs[legNumber].departureAirport,
        legs[legNumber].arrivalAirport,
      );
      return restructureDateDecorations(
        data.data.elements[0].data.element.data.calendarData
          .dateDecorations,
      );
    },
  });
  const {
    isLoading: isPricingDataLoading,
    data: pricingData,
  } = useQuery({
    enabled: !isMultiLeg && dateOpen,
    queryKey: [
      'GetPricingForDates',
      JSON.stringify({...pricingRequest, flightType}),
    ],
    queryFn: async () =>
      await fetchPricingData({
        pricingParameters: pricingRequest as PricingParameters,
        client,
        contextDispatch,
      }),
  });

  const sharedProps = {
    pricingData,
    dateDecorationMap,
    isPricingDataLoading,
    decorationMapLoading,
  };

  const setNewDates = (input: Date | null): void => {
      let startDate : Date = new Date();
      let endDate : Date = new Date();
      if (input) {
        startDate = startOfCurrentMonth(input);
      }
      endDate = endOfNextMonth(startDate);
      updatePricingRequest({
        ...pricingRequest,
        startDate: startDate,
        endDate: endDate,
      });
  };

  return (
    <InputWrapper>
      <Input
        onClick={() => {
          updateLeg(
            { ...legs[legNumber], dateOpen: true },
            legNumber,
          );
          // Update the pricingRequest start/end date before opening the calendar
          setNewDates(legs[legNumber]?.date);
        }}
        autoComplete="off"
        name={'CalendarInput'}
        label={determineLabel()}
        labelSize={'md'}
        variant={'md'}
        value={determineDisplayValue()}
        isReadOnly={true}
        leftIcon={
          <Icon
            color="secondary"
            name="calendar"
            size="s"
          />
        }
        errorMessage={
          dateError && 'ERROR MESSAGE COMING SOON'
        }
      />
      {dateOpen && (
        <CalendarPricing
          sharedProps={sharedProps}
          legNumber={legNumber}
        />
      )}
    </InputWrapper>
  );
};
export default CalendarSection;
