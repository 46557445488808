import { IWebToCasePayloadValue } from '@components/molecules/FormFieldTitleDropDownConditionalUpdate/FormFieldTitleDropDownConditionalUpdate.interfaces';
import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { pegasusClient } from '@services/apiService';
import { IPage } from '@services/dynamicRenderingService/dynamicRendering.interfaces';
import { ISliceFactoryArgs } from '@services/sliceFactoryService/sliceFactoryService.types';
import mixpanel from 'mixpanel-browser';

interface IWebToCaseResponse {
  subject?: string;
  description?: string;
  flightId?: string;
  flightReservationId?: string;
  conditionalDropdownOptions?: IWebToCasePayloadValue[];
}

interface IState {
  panel?: string;
  sidebarIndex?: string;
  isWebToCaseState?: boolean;
  webToCaseResponse?: IWebToCaseResponse;
}

interface IHubPanel {
  panelIsLoading: boolean;
  panelError: unknown | null;
  panelData: IPage | undefined;
  webToCaseSuccess?: boolean | undefined;
}

const panelInitialState: IHubPanel = {
  panelIsLoading: false,
  panelError: null,
  panelData: undefined,
};

interface IProps {
  urlParams?: string;
  queryParams?: string;
  token?: string;
  type?: string;
  resPayload?: unknown;
}

export const initialState: IState = {};

export const HubSlice = createSlice({
  name: 'hub',
  initialState,
  reducers: {
    setPanel: (state, action: PayloadAction<string>) => {
      state.panel = action.payload;
      if (action.payload.includes('web-to-case')) {
        state.isWebToCaseState = true;
      } else {
        state.isWebToCaseState = false;
      }
    },
    setSidebarIndex: (
      state,
      action: PayloadAction<string>,
    ) => {
      state.sidebarIndex = action.payload;
    },
    setWebToCase: (
      state,
      action: PayloadAction<IWebToCaseResponse>,
    ) => {
      const { payload } = action;
      state.webToCaseResponse = {
        ...state.webToCaseResponse,
        ...payload,
      };
    },
  },
  extraReducers: {},
});

export function asyncThunkFactory({
  pageName,
  endpoint,
  version,
  type,
}: ISliceFactoryArgs) {
  return createAsyncThunk(
    `${pageName}/fetch${pageName}`,
    async (props: IProps) => {
      const { resPayload, queryParams = '', token } = props;
      // urlParams should be in format of '/param' to appear before "&"
      // queryParams should be in format of '&queryString' to appear after "?"
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          WUToken: token,
        },
      };
      try {
        if (type === 'post') {
          return (
            await pegasusClient.post(
              `${endpoint}?version=${version}&platform=web`,
              resPayload,
              config,
            )
          ).data;
        } else {
          return (
            await pegasusClient.get(
              `${endpoint}?version=${version}&platform=web${queryParams}`,
              config,
            )
          ).data;
        }
      } catch (err) {
        return err;
      }
    },
  );
}

export function hubPanelSliceFactory({
  pageName,
  endpoint,
  version,
}: ISliceFactoryArgs) {
  const hubPanelFetchAsyncThunk = asyncThunkFactory({
    endpoint: endpoint,
    pageName: pageName,
    version: version,
  });

  const hubPanelSlice = createSlice({
    name: pageName,
    initialState: panelInitialState,
    reducers: {
      getPage(state, action) {
        const { payload } = action;
        state.panelData = payload.data;
      },
    },
    extraReducers: (builder) => {
      builder.addCase(
        hubPanelFetchAsyncThunk.pending,
        (state) => {
          state.panelIsLoading = true;
        },
      );
      builder.addCase(
        hubPanelFetchAsyncThunk.fulfilled,
        (state, action) => {
          const { payload } = action;
          state.panelIsLoading = false;
          state.panelData = payload.data;
          if (
            state.panelData?.pageAnalytics?.trackingTitle
          ) {
            mixpanel.track(
              state.panelData?.pageAnalytics?.trackingTitle,
            );
          }
        },
      );
      builder.addCase(
        hubPanelFetchAsyncThunk.rejected,
        (state, action) => {
          const { payload } = action;
          state.panelIsLoading = false;
          state.panelError = payload;
        },
      );
    },
  });
  return hubPanelSlice.reducer;
}

export const {
  setPanel,
  setSidebarIndex,
  setWebToCase,
  // setWebToCaseSuccess,
} = HubSlice.actions;

export const HubReducer = HubSlice.reducer;

export default HubReducer;
