import { IAction } from '@atoms/Action/Action.interfaces';
import { IButton } from '@atoms/Button/Button.interfaces';
import { Link } from '@components/molecules/TextGroupBodyCopyLink/TextGroupCopyLink.interfaces';
import {
  IComponent,
  IPage,
} from '@services/dynamicRenderingService/dynamicRendering.interfaces';
import { IErrorPayload } from './molecules/Error/ErrorSlice';
import { ISubPageOnboarding } from './organisms/SubPageTermsOfService/SubPageTermsOfService.interface';
import { IComplexIconProps } from '@wheelsup/wu-react-components/types/design-system/icons/ComplexIcon';

export type RegexTypes =
  | 'validEmailAddress'
  | 'validCreditCardNumber'
  | 'validCVVNumber'
  | 'validCreditCardExpirationDate'
  | 'validZIPCode'
  | 'newPasswordValidations'
  | 'eightOrMoreCharacters'
  | 'bothUpperAndLowerCase'
  | 'atLeastOneNumber'
  | 'atLeastOneSpecialCharacter'
  | 'oneOrMoreCharacters'
  | 'validPhoneNumber';

export type IRegexLibrary = {
  [key in RegexTypes]?: RegExp;
};

export type BackgroundColor =
  | 's1'
  | 's2'
  | 's3'
  | 's4'
  | 's5'
  | 's6'
  | 'backgroundUpWhite'
  | 'backgroundUpBlue'
  | 'backgroundLava'
  | 'backgroundMidnight'
  | 'backgroundLightGray';
export interface IUpstream {
  [key: string]: any;
}

export interface ITermsOfService {
  data?: ISubPageOnboarding;
}
export interface IMeta {
  pageTitle: string;
  pageSubtitle?: string;
  columnLayout?: '70-30' | '30-70' | '70-30-100' | '100';
  stickyColumn?: 'left' | 'right' | 'center';
  displayPageFlags?: {
    hasConfirmedContactDetails: boolean;
  };
  backgroundImages?: {
    morning: IPageBackgroundImage;
    afternoon: IPageBackgroundImage;
    evening: IPageBackgroundImage;
  };
  webMobileBackgroundImages?: {
    morning: IPageBackgroundImage;
    afternoon: IPageBackgroundImage;
    evening: IPageBackgroundImage;
  };
  backgroundColor: BackgroundColor;
  upstream?: IUpstream;
  stickyFooterContent?: {
    elements: IComponent[];
  };
  termsOfService?: ITermsOfService;
}

export type IInputType =
  | 'standard'
  | 'firstName'
  | 'middleName'
  | 'lastName'
  | 'birthday'
  | 'weight'
  | 'email'
  | 'password'
  | 'newPassword'
  | 'smsCode'
  | 'phone';

export type IInputStyleType = 'primary' | 'secondary';
export interface IImage {
  url: string;
  tintColor?: string;
}

export type ImageFocusDirection =
  | 'left'
  | 'right'
  | 'top'
  | 'bottom'
  | 'bottom,left'
  | 'bottom,right'
  | 'top,left'
  | 'top,right'
  | 'center';

export interface IPageBackgroundImage {
  url: string;
  tintColor?: string;
  focusDirection?: ImageFocusDirection;
}

export interface ILuggage {
  luggageCount: number;
  luggageType: string;
}

export interface IAircraft {
  image: IImage;
  category: string;
  name: string;
  fleetType?: string;
  numberOfSeats: number;
}

export interface IKeyValue {
  key: string;
  isRequired?: boolean;
  value: any; // Need to accept more than just string as of https://wheelsup.atlassian.net/browse/FETE-274
  // using value: string | boolean | Date broke other use cases
}
export interface IAirport {
  id: string;
  code: string;
  name: string;
  city: string;
  country?: string;
  state: string;
  hours?: string;
  isHomeAirport?: boolean;
  isHighDensity?: boolean;
  isConditional?: boolean;
  displayCompactValue1?: string;
  displayCompactValue2?: string;
  displayFullValue1?: string;
  displayFullValue2?: string;
}

export interface IDate {
  startDate: Date;
  endDate: Date;
  key: string;
}
export interface IFlightLeg {
  id: string;
  departureAirport: IAirport | null;
  arrivalAirport: IAirport | null;
  date: Date | null;
  departTime: Date;
  flightType: string;
  tripTypeTitle?: string;
  numberOfPassengers: number;
  numberOfPets: number;
  steps: number;
  departureOpen?: boolean;
  arrivalOpen?: boolean;
  errors?: [];
  // for deal data situation
  tripType?: string;
}

export interface IFlight {
  fromAirport: IAirport;
  toAirport: IAirport;
  flightStatus?: string;
  departureTime?: string;
  departureDateTime?: string;
  departureTimeOfDay?: string;
  arrivalTime?: string;
  flightLength?: string;
  flightNotes?: string;
  flightLegCopy?: string;
  flightDetailCopyLeft?: string;
  estimatedDisclaimer?: string;
  flightDetailCopyCenter?: string;
  aircraft?: IAircraft;
  departureTimeDisplayValue?: string;
  departureDateDisplayValue?: string;
  arrivalTimeDisplayValue?: string;
  arrivalDateDisplayValue?: string;
  didDepartureDateChange?: boolean;
  departureTimeDisplayValueUpdated?: string;
  arrivalTimeDisplayValueUpdated?: string;
  reservationNumber?: string;
}

export interface AirportItemResult {
  city: string;
  timeDistance: string;
  code: string;
  selected: boolean;
}

export interface ObjectAccess {
  [key: string]: unknown;
}

export interface IResponseImage {
  url: string;
  tintColor?: string;
}

export interface IValidationInput {
  regex: RegexTypes;
  regexErrorCopy: string;
}

export interface IStepper {
  minValue?: number;
  maxValue?: number;
  defaultValue?: number;
  incrementButton?: IButton;
  decrementButton?: IButton;
  hideControls?: boolean;
}

// Combine with TextFieldProps from material UI by convenience.
export type InputCustomProps = {
  isRequired?: boolean;
  title: string;
  fieldCopy?: string;
  maxCharactersAllowed?: number;
  minCharactersAllowed?: number;
  validations?: IValidationInput[];
  payloadKey: string;
  errorMessage?: string;
};

// Is the above supposed to accomplish this?
export interface IInputField {
  id: string;
  key?: string;
  type: IInputType;
  styleType?: IInputStyleType;
  title?: string;
  fieldCopy?: string;
  placeholder?: string;
  leadingHelperCopy?: string;
  trailingHelperCopy?: string;
  trailingHelperAction?: IAction;
  leadingCTA?: IButton;
  trailingCTA?: IButton;
  maxCharactersAllowed?: number;
  minCharactersRequired?: number;
  validations?: IValidationInput[];
}

export type DetailListItem = {
  title: string;
  detail?: string;
  image?: IImage;
  button?: IButton;
};

export enum SpacerType {
  sp1 = 100,
  sp2 = 60,
  sp3 = 40,
  sp4 = 24,
  sp5 = 20,
  sp6 = 16,
  sp7 = 12,
  sp8 = 8,
}

export type ColorValueTypes =
  | 'c1'
  | 'c2'
  | 'c3'
  | 'c4'
  | 'c5'
  | 'c6'
  | 'c7'
  | 'c8'
  | 'c9'
  | 'c10'
  | 'c11'
  | 'c12'
  | 'c13'
  | 'c14'
  | 'c15'
  | 'c16'
  | 'c17'
  | 'c18'
  | 'midnight'
  | 'upBlue'
  | 'darkGray'
  | 'mediumGray'
  | 'lightGray'
  | 'basil'
  | 'lava'
  | 'jam'
  | 'upWhite'
  | 'blue5Percent'
  | 'blue40Percent';

export interface IIndexable<T> {
  [key: string]: T;
}

export enum BleedType {
  page = 'page',
  full = 'full',
}

export enum StyleType {
  flat = 'flat',
  card = 'card',
}

export interface PegasusErrorResponse {
  data: {
    data: {
      copy: string;
    };
  };
}
export interface PegasusError {
  response: PegasusErrorResponse;
}

export interface IState {
  isLoading: boolean;
  error: unknown | null;
  errorPayload?: IErrorPayload;
  isSuccessful?: boolean;
}

export interface IPageState extends IState {
  data: IPage | undefined;
  panelData?: any;
}

export const pageInitialState: IPageState = {
  isLoading: false,
  error: null,
  data: undefined,
  isSuccessful: false,
};

export interface IBasePassengerDisplay {
  id: string;
  displayName?: string;
  isLead?: boolean;
  profileImage?: IImage;
  isSelected?: boolean;
  ageGroupType?: 'lapInfant' | 'kid' | 'adult';
}

export interface IPassenger {
  id?: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  prefix?: string;
  suffix?: string;
  gender: string;
  profileImage?: IImage;
  dateOfBirth?: Date;
  weight?: number;
  isLeadPassenger?: boolean;
  isFavPassenger?: boolean;
  isAuthorizedUser?: boolean;
  phone?: string;
  email?: string;
  ageGroupType?: 'lapInfant' | 'kid' | 'adult';
}

export interface IPassengerLeg {
  selectedPassengers: IPassenger[];
}

export interface IPet {
  id: string;
  name: string;
  age?: number;
  weight?: number;
  petType: string;
  isServiceAnimal?: boolean;
  isHypoallergenic?: boolean;
  isGoodWithDogs?: boolean;
  isGoodWithCats?: boolean;
  isGoodWithKids?: boolean;
  isFavorite: boolean;
  petTypeDescription?: string;
  profileImage?: IResponseImage;
}

export interface ILuggage {
  luggageCount: number;
  luggageType: string;
}

export interface IFBO {
  companyName: string;
  airportCode: string;
  airportName: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
  phone?: string;
  displayFullValue1?: string;
  displayFullValue2?: string;
  weather?: Weather;
}

interface Weather {
  condition: IComplexIconProps['name'];
  temperatureMax: number;
  temperatureMin: number;
}

export interface ITextView {
  copy?: string;
  placeholder?: string;
  isEditable?: boolean;
  maxCharactersAllowed?: number;
  minCharactersRequired?: number;
  textAttributes?: {
    links?: Link[];
    styles?: IInlineText[];
  };
}

export interface IInlineText {
  start: number;
  end: number;
  action?: IAction;
  style?: 'style1' | 'style2';
}
export interface IDataState extends IState {
  data: undefined;
}

export const dataInitialState: IDataState = {
  isLoading: false,
  error: null,
  data: undefined,
  isSuccessful: false,
};

export interface ISearchPegausRes extends IState {
  status: string;
  legs: IFlightLeg[];
  errors: [];
  clientId: string;
}

export interface IFlightLegAlertData {
  id: string;
  title: string;
  copyTextView: {};
  buttonOne: IButton;
}
export interface IFlightLegAlert {
  type: string;
  data: IFlightLegAlertData;
}
export interface IFlightLegError {
  alert: IFlightLegAlert;
  linkedFormField: string[];
}

export interface ISwitch {
  isEnabled?: boolean;
  isSelected?: boolean;
}

export interface ISwitchButton {
  id?: string;
  checked: boolean;
  disabled?: boolean;
  onChange: (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => void;
}

export interface ITextGroupSubmodel {
  title: string;
  styleType: string;
  textAttributes?: string;
  alignmentState?: string;
}
export interface ITitleIconCopy {
  type: string;
  title?: string;
  copy?: string;
  image?: IImage;
}

export type IReservationType =
  | 'instant-book'
  | 'request-quote';

export interface IDateDecoration {
  date: string;
  dateCopy: string;
  dateImage: IImage;
  dateType: CalendarDateType;
}
export interface ICalendarData {
  incrementButton: IButton;
  decrementButton: IButton;
  dateDecorations: IDateDecoration[];
  startDate: string;
  endDate: string;
}

export type CalendarDateType =
  | 'airportClosed'
  | 'alreadyBooked'
  | 'peakDay'
  | 'withinCalloutTime'
  | 'customQuote';

export interface IJwtPayload {
  userName: string;
  firstName: string;
  lastName: string;
  memberSince: string;
  userId: number;
  memberUserTypeCode: string;
}

export type TextStyleType =
  | 'h1_a_c1'
  | 'h1_a_c2'
  | 'h1_a_c3'
  | 'h1_a_c4'
  | 'h1_a_c5'
  | 'h1_a_c6'
  | 'h1_a_c7'
  | 'h1_a_c8'
  | 'h1_a_c9'
  | 'h1_a_c10'
  | 'h1_a_c11'
  | 'h1_a_c12'
  | 'h1_a_c13'
  | 'h1_a_c14'
  | 'h1_a_c15'
  | 'h1_a_c16'
  | 'h1_a_c17'
  | 'h1_a_c18'
  | 'h2_a_c1'
  | 'h2_a_c2'
  | 'h2_a_c3'
  | 'h2_a_c4'
  | 'h2_a_c5'
  | 'h2_a_c6'
  | 'h2_a_c7'
  | 'h2_a_c8'
  | 'h2_a_c9'
  | 'h2_a_c10'
  | 'h2_a_c11'
  | 'h2_a_c12'
  | 'h2_a_c13'
  | 'h2_a_c14'
  | 'h2_a_c15'
  | 'h2_a_c16'
  | 'h2_a_c17'
  | 'h2_a_c18'
  | 'h3_a_c1'
  | 'h3_a_c2'
  | 'h3_a_c3'
  | 'h3_a_c4'
  | 'h3_a_c5'
  | 'h3_a_c6'
  | 'h3_a_c7'
  | 'h3_a_c8'
  | 'h3_a_c9'
  | 'h3_a_c10'
  | 'h3_a_c11'
  | 'h3_a_c12'
  | 'h3_a_c13'
  | 'h3_a_c14'
  | 'h3_a_c15'
  | 'h3_a_c16'
  | 'h3_a_c17'
  | 'h3_a_c18'
  | 'h3_b_c1'
  | 'h3_b_c2'
  | 'h3_b_c3'
  | 'h3_b_c4'
  | 'h3_b_c5'
  | 'h3_b_c6'
  | 'h3_b_c7'
  | 'h3_b_c8'
  | 'h3_b_c9'
  | 'h3_b_c10'
  | 'h3_b_c11'
  | 'h3_b_c12'
  | 'h3_b_c13'
  | 'h3_b_c14'
  | 'h3_b_c15'
  | 'h3_b_c16'
  | 'h3_b_c17'
  | 'h3_b_c18'
  | 'h4_a_c1'
  | 'h4_a_c2'
  | 'h4_a_c3'
  | 'h4_a_c4'
  | 'h4_a_c5'
  | 'h4_a_c6'
  | 'h4_a_c7'
  | 'h4_a_c8'
  | 'h4_a_c9'
  | 'h4_a_c10'
  | 'h4_a_c11'
  | 'h4_a_c12'
  | 'h4_a_c13'
  | 'h4_a_c14'
  | 'h4_a_c15'
  | 'h4_a_c16'
  | 'h4_a_c17'
  | 'h4_a_c18'
  | 'h4_b_c1'
  | 'h4_b_c2'
  | 'h4_b_c3'
  | 'h4_b_c4'
  | 'h4_b_c5'
  | 'h4_b_c6'
  | 'h4_b_c7'
  | 'h4_b_c8'
  | 'h4_b_c9'
  | 'h4_b_c10'
  | 'h4_b_c11'
  | 'h4_b_c12'
  | 'h4_b_c13'
  | 'h4_b_c14'
  | 'h4_b_c15'
  | 'h4_b_c16'
  | 'h4_b_c17'
  | 'h4_b_c18'
  | 'h5_a_c1'
  | 'h5_a_c2'
  | 'h5_a_c3'
  | 'h5_a_c4'
  | 'h5_a_c5'
  | 'h5_a_c6'
  | 'h5_a_c7'
  | 'h5_a_c8'
  | 'h5_a_c9'
  | 'h5_a_c10'
  | 'h5_a_c11'
  | 'h5_a_c12'
  | 'h5_a_c13'
  | 'h5_a_c14'
  | 'h5_a_c15'
  | 'h5_a_c16'
  | 'h5_a_c17'
  | 'h5_a_c18'
  | 'h5_b_c1'
  | 'h5_b_c2'
  | 'h5_b_c3'
  | 'h5_b_c4'
  | 'h5_b_c5'
  | 'h5_b_c6'
  | 'h5_b_c7'
  | 'h5_b_c8'
  | 'h5_b_c9'
  | 'h5_b_c10'
  | 'h5_b_c11'
  | 'h5_b_c12'
  | 'h5_b_c13'
  | 'h5_b_c14'
  | 'h5_b_c15'
  | 'h5_b_c16'
  | 'h5_b_c17'
  | 'h5_b_c18'
  | 'body1_a_c1'
  | 'body1_a_c2'
  | 'body1_a_c3'
  | 'body1_a_c4'
  | 'body1_a_c5'
  | 'body1_a_c6'
  | 'body1_a_c7'
  | 'body1_a_c8'
  | 'body1_a_c9'
  | 'body1_a_c10'
  | 'body1_a_c11'
  | 'body1_a_c12'
  | 'body1_a_c13'
  | 'body1_a_c14'
  | 'body1_a_c15'
  | 'body1_a_c16'
  | 'body1_a_c17'
  | 'body1_a_c18'
  | 'body1_a_c1_strikethrough'
  | 'body1_a_c2_strikethrough'
  | 'body1_a_c3_strikethrough'
  | 'body1_a_c4_strikethrough'
  | 'body1_a_c5_strikethrough'
  | 'body1_a_c6_strikethrough'
  | 'body1_a_c7_strikethrough'
  | 'body1_a_c8_strikethrough'
  | 'body1_a_c9_strikethrough'
  | 'body1_a_c10_strikethrough'
  | 'body1_a_c11_strikethrough'
  | 'body1_a_c12_strikethrough'
  | 'body1_a_c13_strikethrough'
  | 'body1_a_c14_strikethrough'
  | 'body1_a_c15_strikethrough'
  | 'body1_a_c16_strikethrough'
  | 'body1_a_c17_strikethrough'
  | 'body1_a_c18_strikethrough'
  | 'body1_b_c1'
  | 'body1_b_c2'
  | 'body1_b_c3'
  | 'body1_b_c4'
  | 'body1_b_c5'
  | 'body1_b_c6'
  | 'body1_b_c7'
  | 'body1_b_c8'
  | 'body1_b_c9'
  | 'body1_b_c10'
  | 'body1_b_c11'
  | 'body1_b_c12'
  | 'body1_b_c13'
  | 'body1_b_c14'
  | 'body1_b_c15'
  | 'body1_b_c16'
  | 'body1_b_c17'
  | 'body1_b_c18'
  | 'body2_a_c1'
  | 'body2_a_c2'
  | 'body2_a_c3'
  | 'body2_a_c4'
  | 'body2_a_c5'
  | 'body2_a_c6'
  | 'body2_a_c7'
  | 'body2_a_c8'
  | 'body2_a_c9'
  | 'body2_a_c10'
  | 'body2_a_c11'
  | 'body2_a_c12'
  | 'body2_a_c13'
  | 'body2_a_c14'
  | 'body2_a_c15'
  | 'body2_a_c16'
  | 'body2_a_c17'
  | 'body2_a_c18'
  | 'body2_b_c1'
  | 'body2_b_c2'
  | 'body2_b_c3'
  | 'body2_b_c4'
  | 'body2_b_c5'
  | 'body2_b_c6'
  | 'body2_b_c7'
  | 'body2_b_c8'
  | 'body2_b_c9'
  | 'body2_b_c10'
  | 'body2_b_c11'
  | 'body2_b_c12'
  | 'body2_b_c13'
  | 'body2_b_c14'
  | 'body2_b_c15'
  | 'body2_b_c16'
  | 'body2_b_c17'
  | 'body2_b_c18'
  | 'caption1_a_c1'
  | 'caption1_a_c2'
  | 'caption1_a_c3'
  | 'caption1_a_c4'
  | 'caption1_a_c5'
  | 'caption1_a_c6'
  | 'caption1_a_c7'
  | 'caption1_a_c8'
  | 'caption1_a_c9'
  | 'caption1_a_c10'
  | 'caption1_a_c11'
  | 'caption1_a_c12'
  | 'caption1_a_c13'
  | 'caption1_a_c14'
  | 'caption1_a_c15'
  | 'caption1_a_c16'
  | 'caption1_a_c17'
  | 'caption1_a_c18'
  // Start new styles
  | 'heading-01-small_Up Blue'
  | 'heading-01-small_Dark Gray'
  | 'heading-01-small_Basil'
  | 'heading-01-small_Lava'
  | 'heading-01-small_Jam'
  | 'heading-01-small_Up White'
  | 'heading-01-small_Midnight'
  | 'heading-02-small_Up Blue'
  | 'heading-02-small_Dark Gray'
  | 'heading-02-small_Basil'
  | 'heading-02-small_Lava'
  | 'heading-02-small_Jam'
  | 'heading-02-small_Up White'
  | 'heading-02-small_Midnight'
  | 'heading-03-small_Up Blue'
  | 'heading-03-small_Dark Gray'
  | 'heading-03-small_Basil'
  | 'heading-03-small_Lava'
  | 'heading-03-small_Jam'
  | 'heading-03-small_Up White'
  | 'heading-03-small_Midnight'
  | 'heading-04-small_Up Blue'
  | 'heading-04-small_Dark Gray'
  | 'heading-04-small_Basil'
  | 'heading-04-small_Lava'
  | 'heading-04-small_Jam'
  | 'heading-04-small_Up White'
  | 'heading-04-small_Midnight'
  | 'title-small_Up Blue'
  | 'title-small_Dark Gray'
  | 'title-small_Basil'
  | 'title-small_Lava'
  | 'title-small_Jam'
  | 'title-small_Up White'
  | 'title-small_Midnight'
  | 'number-small_Up Blue'
  | 'number-small_Dark Gray'
  | 'number-small_Basil'
  | 'number-small_Lava'
  | 'number-small_Jam'
  | 'number-small_Up White'
  | 'number-small_Midnight'
  | 'city-small_Up Blue'
  | 'city-small_Dark Gray'
  | 'city-small_Basil'
  | 'city-small_Lava'
  | 'city-small_Jam'
  | 'city-small_Up White'
  | 'city-small_Midnight'
  | 'body-1_Up Blue'
  | 'body-1_Dark Gray'
  | 'body-1_Basil'
  | 'body-1_Lava'
  | 'body-1_Jam'
  | 'body-1_Up White'
  | 'body-1_Midnight'
  | 'body-1-bold_Up Blue'
  | 'body-1-bold_Dark Gray'
  | 'body-1-bold_Basil'
  | 'body-1-bold_Lava'
  | 'body-1-bold_Jam'
  | 'body-1-bold_Up White'
  | 'body-1-bold_Midnight'
  | 'body-2_Up Blue'
  | 'body-2_Dark Gray'
  | 'body-2_Basil'
  | 'body-2_Lava'
  | 'body-2_Jam'
  | 'body-2_Up White'
  | 'body-2_Midnight'
  | 'body-2-bold_Up Blue'
  | 'body-2-bold_Dark Gray'
  | 'body-2-bold_Basil'
  | 'body-2-bold_Lava'
  | 'body-2-bold_Jam'
  | 'body-2-bold_Up White'
  | 'body-2-bold_Midnight'
  | 'title_Up Blue'
  | 'title_Dark Gray'
  | 'title_Basil'
  | 'title_Lava'
  | 'title_Jam'
  | 'title_Up White'
  | 'title_Midnight'
  | 'cta_Up Blue'
  | 'cta_Dark Gray'
  | 'cta_Basil'
  | 'cta_Lava'
  | 'cta_Jam'
  | 'cta_Up White'
  | 'cta_Midnight'
  | 'caption_Up Blue'
  | 'caption_Dark Gray'
  | 'caption_Basil'
  | 'caption_Lava'
  | 'caption_Jam'
  | 'caption_Up White'
  | 'caption_Midnight'
  | 'caption-bold_Up Blue'
  | 'caption-bold_Dark Gray'
  | 'caption-bold_Basil'
  | 'caption-bold_Lava'
  | 'caption-bold_Jam'
  | 'caption-bold_Up White'
  | 'caption-bold_Midnight'
  | 'tag-label_Up Blue'
  | 'tag-label_Dark Gray'
  | 'tag-label_Basil'
  | 'tag-label_Lava'
  | 'tag-label_Jam'
  | 'tag-label_Up White'
  | 'tag-label_Midnight'
  | 'helper_Up Blue'
  | 'helper_Dark Gray'
  | 'helper_Basil'
  | 'helper_Lava'
  | 'helper_Jam'
  | 'helper_Up White'
  | 'helper_Midnight';

export const newTypographyStylesSet = new Set([
  'heading-01-small_Up Blue',
  'heading-01-small_Dark Gray',
  'heading-01-small_Basil',
  'heading-01-small_Lava',
  'heading-01-small_Jam',
  'heading-01-small_Up White',
  'heading-01-small_Midnight',
  'heading-02-small_Up Blue',
  'heading-02-small_Dark Gray',
  'heading-02-small_Basil',
  'heading-02-small_Lava',
  'heading-02-small_Jam',
  'heading-02-small_Up White',
  'heading-02-small_Midnight',
  'heading-03-small_Up Blue',
  'heading-03-small_Dark Gray',
  'heading-03-small_Basil',
  'heading-03-small_Lava',
  'heading-03-small_Jam',
  'heading-03-small_Up White',
  'heading-03-small_Midnight',
  'heading-04-small_Up Blue',
  'heading-04-small_Dark Gray',
  'heading-04-small_Basil',
  'heading-04-small_Lava',
  'heading-04-small_Jam',
  'heading-04-small_Up White',
  'heading-04-small_Midnight',
  'heading-01-large_Up Blue',
  'heading-01-large_Dark Gray',
  'heading-01-large_Basil',
  'heading-01-large_Lava',
  'heading-01-large_Jam',
  'heading-01-large_Up White',
  'heading-01-large_Midnight',
  'heading-02-large_Up Blue',
  'heading-02-large_Dark Gray',
  'heading-02-large_Basil',
  'heading-02-large_Lava',
  'heading-02-large_Jam',
  'heading-02-large_Up White',
  'heading-02-large_Midnight',
  'heading-03-large_Up Blue',
  'heading-03-large_Dark Gray',
  'heading-03-large_Basil',
  'heading-03-large_Lava',
  'heading-03-large_Jam',
  'heading-03-large_Up White',
  'heading-03-large_Midnight',
  'heading-04-large_Up Blue',
  'heading-04-large_Dark Gray',
  'heading-04-large_Basil',
  'heading-04-large_Lava',
  'heading-04-large_Jam',
  'heading-04-large_Up White',
  'heading-04-large_Midnight',
  'heading-01-large_Up Blue',
  'heading-01-bold_Dark Gray',
  'heading-01-bold_Basil',
  'heading-01-bold_Lava',
  'heading-01-bold_Jam',
  'heading-01-bold_Up White',
  'heading-01-bold_Midnight',
  'heading-02-bold_Up Blue',
  'heading-02-bold_Dark Gray',
  'heading-02-bold_Basil',
  'heading-02-bold_Lava',
  'heading-02-bold_Jam',
  'heading-02-bold_Up White',
  'heading-02-bold_Midnight',
  'heading-03-bold_Up Blue',
  'heading-03-bold_Dark Gray',
  'heading-03-bold_Basil',
  'heading-03-bold_Lava',
  'heading-03-bold_Jam',
  'heading-03-bold_Up White',
  'heading-03-bold_Midnight',
  'heading-04-bold_Up Blue',
  'heading-04-bold_Dark Gray',
  'heading-04-bold_Basil',
  'heading-04-bold_Lava',
  'heading-04-bold_Jam',
  'heading-04-bold_Up White',
  'heading-04-bold_Midnight',
  'heading-05-bold_Up Blue',
  'heading-05-bold_Dark Gray',
  'heading-05-bold_Basil',
  'heading-05-bold_Lava',
  'heading-05-bold_Jam',
  'heading-05-bold_Up White',
  'heading-05-bold_Midnight',
  'title-small_Up Blue',
  'title-small_Dark Gray',
  'title-small_Basil',
  'title-small_Lava',
  'title-small_Jam',
  'title-small_Up White',
  'title-small_Midnight',
  'number-small_Up Blue',
  'number-small_Dark Gray',
  'number-small_Basil',
  'number-small_Lava',
  'number-small_Jam',
  'number-small_Up White',
  'number-small_Midnight',
  'number-medium_Up Blue',
  'number-medium_Dark Gray',
  'number-medium_Basil',
  'number-medium_Lava',
  'number-medium_Jam',
  'number-medium_Up White',
  'number-medium_Midnight',
  'city-small_Up Blue',
  'city-small_Dark Gray',
  'city-small_Basil',
  'city-small_Lava',
  'city-small_Jam',
  'city-small_Up White',
  'city-small_Midnight',
  'body-1_Up Blue',
  'body-1_Dark Gray',
  'body-1_Basil',
  'body-1_Lava',
  'body-1_Jam',
  'body-1_Up White',
  'body-1_Midnight',
  'body-1-bold_Up Blue',
  'body-1-bold_Dark Gray',
  'body-1-bold_Basil',
  'body-1-bold_Lava',
  'body-1-bold_Jam',
  'body-1-bold_Up White',
  'body-1-bold_Midnight',
  'body-2_Up Blue',
  'body-2_Dark Gray',
  'body-2_Basil',
  'body-2_Lava',
  'body-2_Jam',
  'body-2_Up White',
  'body-2_Midnight',
  'body-2-bold_Up Blue',
  'body-2-bold_Dark Gray',
  'body-2-bold_Basil',
  'body-2-bold_Lava',
  'body-2-bold_Jam',
  'body-2-bold_Up White',
  'body-2-bold_Midnight',
  'title_Up Blue',
  'title_Dark Gray',
  'title_Basil',
  'title_Lava',
  'title_Jam',
  'title_Up White',
  'title_Midnight',
  'cta_Up Blue',
  'cta_Dark Gray',
  'cta_Basil',
  'cta_Lava',
  'cta_Jam',
  'cta_Up White',
  'cta_Midnight',
  'caption_Up Blue',
  'caption_Dark Gray',
  'caption_Basil',
  'caption_Lava',
  'caption_Jam',
  'caption_Up White',
  'caption_Midnight',
  'caption-bold_Up Blue',
  'caption-bold_Dark Gray',
  'caption-bold_Basil',
  'caption-bold_Lava',
  'caption-bold_Jam',
  'caption-bold_Up White',
  'caption-bold_Midnight',
  'tag-label_Up Blue',
  'tag-label_Dark Gray',
  'tag-label_Basil',
  'tag-label_Lava',
  'tag-label_Jam',
  'tag-label_Up White',
  'tag-label_Midnight',
  'helper_Up Blue',
  'helper_Dark Gray',
  'helper_Basil',
  'helper_Lava',
  'helper_Jam',
  'helper_Up White',
  'helper_Midnight',
]);

export type Alignment = 'left' | 'center' | 'right';
export interface IPayment {
  id: string;
  paymentType:
    | 'creditCard'
    | 'credit'
    | 'fund'
    | 'check'
    | 'wireTransfer'
    | 'executiveHours'
    | 'atoFund';
  backupCreditCard?: any;
  image?: IImage;
  displayValue1: string;
  displayValue2?: string;
  displayValue3?: string;
  isPreselected?: boolean;
  preselectedSplitValue?: number;
  isEnabled?: boolean;
  isError?: boolean;
  balance?: number;
  fundExpirationDate?: string;
  fundBalanceForfeitDate?: string;
}

export interface IPassengerPayment {
  id: string;
  firstName: string;
  lastName?: string;
  isPet: boolean;
  isLead: boolean;
  phone?: string;
  email?: string;
}

export const stateItems = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'DC',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
];
