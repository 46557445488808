import styled from 'styled-components';
import { Icons, makeStyles } from '@library';
import { solidColors } from '@constants/styles/colors.constants';
import { IconButton } from '@atoms';
const { ArrowForwardIos } = Icons;
import CloseIcon from '@material-ui/icons/Close';

export const CarouselContainer = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
`;

export const CarouselSlideWrapper = styled.div<{
  $isMobile: boolean;
  $isLarge?: boolean;
}>`
  overflow-x: ${({ $isMobile }) => $isMobile && 'scroll'};
  width: 100%;
  display: inline-flex;
  margin-bottom: 16px;
  ${({ $isLarge }) => !$isLarge && 'margin-left: 24px;'}
`;

export const CarouselSlide = styled.div<{
  $translateX: number;
  $active: boolean;
  $marginRight: number;
  $selected: boolean;
}>`
  @-webkit-keyframes fadein {
    from {
      opacity: 0.5;
    }
    to {
      opacity: 1;
    }
  }
  @-moz-keyframes fadein {
    from {
      opacity: 0.5;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes fadein {
    from {
      opacity: 0.5;
    }
    to {
      opacity: 1;
    }
  }
  @-webkit-keyframes fadeout {
    from {
      opacity: 1;
    }
    to {
      opacity: 0.5;
    }
  }
  @-moz-keyframes fadeout {
    from {
      opacity: 1;
    }
    to {
      opacity: 0.5;
    }
  }
  @keyframes fadeout {
    from {
      opacity: 1;
    }
    to {
      opacity: 0.5;
    }
  }
  margin-right: ${({ $marginRight }) =>
    `${$marginRight}px`};
  opacity: ${({ $active }) => ($active ? 1 : 0.5)};
  transform: translateX(
    ${({ $translateX }) => $translateX}px
  );
  display: inline-flex;
  transition: transform 1s ease;
  scroll-snap-align: start;
  border: ${({ $selected }) =>
    $selected
      ? `2px solid ${solidColors.upBlue.color}`
      : 'none'};
  border-radius: 10px;
  ${({ $active }) => {
    if ($active) {
      return `
                animation: fadein .5s ease-in;
                -moz-animation: fadein .5s ease-in;
                -webkit-animation: fadein .5s ease-in;
            `;
    } else {
      return `
                animation: fadeout .5s ease-in;
                -moz-animation: fadeout .5s ease-in;
                -webkit-animation: fadeout .5s ease-in;
            `;
    }
  }}
`;

export const ArrowContainer = styled.div`
  width: fit-content;
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 24px 0 24px;
`;

export const ArrowCircle = styled.div<{
  $active?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid;
  min-width: 30px;
  min-height: 30px;
  transition: border-color 0.5s ease;
  ${({ $active }) => {
    if ($active) {
      return `
                border-color: ${solidColors.upBlue.color};
                cursor: pointer;
            `;
    } else {
      return `
                border-color: ${solidColors.mediumGray.color};
            `;
    }
  }};
`;

export const BackArrow = styled(ArrowForwardIos)<{
  $active?: boolean;
}>`
  color: ${({ $active }) =>
    $active
      ? solidColors.upBlue.color
      : solidColors.mediumGray.color};
  transform: rotate(180deg);
  transition: color 0.5s ease;
  width: 12px;
  height: 12px;
`;
export const ForwardArrow = styled(ArrowForwardIos)<{
  $active?: boolean;
}>`
  color: ${({ $active }) =>
    $active
      ? solidColors.upBlue.color
      : solidColors.mediumGray.color};
  width: 12px;
  height: 12px;
  transition: color 0.5s ease;
`;

export const ArrowLabel = styled.div`
  width: 100%;
  margin: 0 12px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const StyledIconButton = styled(IconButton)`
  margin: 0;
  position: absolute;
  top: 0;
  right: 0;
`;

export const StyledCloseIcon = styled(CloseIcon)`
  color: ${solidColors.upWhite.color};
  z-index: 1;
`;
