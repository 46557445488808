import {
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

interface IHandle401 {
  refreshIsLoading: boolean;
}

const initialState: IHandle401 = {
  refreshIsLoading: false,
};

export const appSlice = createSlice({
  name: 'handle401Slice',
  initialState,
  reducers: {
    setAppIsLoading: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.refreshIsLoading = action.payload;
    },
  },
});

export const { setAppIsLoading } = appSlice.actions;

export const Handle401Reducer = appSlice.reducer;

export default Handle401Reducer;
