import { solidColors } from '@constants/styles/colors.constants';
import styled from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
`;

export const LinkStyled = styled.a`
  font-size: 1em;
  color: ${solidColors.upBlue.color};
  font-weight: 600;
  margin-left: 0.5em;
  text-decoration: underline;
  line-height: 27px;
  &:hover {
    cursor: pointer;
  }
`;
