import ImgixClient from '@imgix/js-core';
import { fetchProcessEnvVariable } from '@services/envService/envService';
import { IImgix } from './imgix.interfaces';

const client = new ImgixClient({
  domain: 'wheelsup.imgix.net',
  secureURLToken: fetchProcessEnvVariable(
    'IMGIX_TOKEN',
    true,
  ),
  includeLibraryParam: false,
});

export const resize = ({
  src,
  isSrcSet,
  width,
  height,
  crop,
  fit,
  fill,
  minW,
  minH,
  maxW,
  maxH,
  ellipse,
  radius,
  widths,
  aspectRatio,
  fpX = 0,
  fpY = 0,
  fpZ = 0,
  trim = undefined,
  rect = undefined,
  sharp = 0,
}: IImgix): {
  srcResult: string;
  srcSetResult: string | undefined | false;
} => {
  const params = {
    ...(width && { w: width }),
    ...(height && { h: height }),
    ...(aspectRatio && { ar: aspectRatio }),
    ...(fit && { fit }),
    ...(fill && { fill }),
    ...(crop && { crop }),
    ...(trim && { trim }),
    ...(sharp && { sharp }),
    ...(fpX >= 0 && { 'fp-x': fpX }),
    ...(fpY >= 0 && { 'fp-y': fpY }),
    ...(fpZ >= 0 && { 'fp-z': fpZ }),
    ...(minW && { minW }),
    ...(minH && { minH }),
    ...(maxW && { maxW }),
    ...(maxH && { maxH }),
    ...(ellipse && { mask: 'ellipse' }),
    ...(radius && { 'corner-radius': radius }),
    ...(radius && { mask: 'corners' }),
    ...(src?.includes('jpg') && { fm: 'pjpg' }),
  };
  const options = {
    ...(widths && { widths }),
  };
  const srcResult = client.buildURL(src, params);
  const srcSetResult =
    isSrcSet && client.buildSrcSet(src, params, options);
  return { srcResult, srcSetResult };
};
