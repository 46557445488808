import { FlightLegItemLuggageInfo } from '@components/molecules';
import React, {
  useState,
  useRef,
  useEffect,
  useLayoutEffect,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ISubPageLuggageSelection,
  ISubmitLuggageData,
} from './SubpageLuggageSelection.interfaces';
import {
  AlertContainer,
  Border,
  ButtonContainer,
  LuggageItemsContainer,
  StyledButton,
  StyledCard,
  StyledSubpageLuggageSelection,
  TopSection,
} from './SubpageLuggageSelection.styles';
import { PaxContainer } from '@components/molecules/ContainerTripPassengerEditor/ContainerTripPassengerEditor.styles';
import CarouselScrollHorizontal from '../CarouselScrollHorizontal/CarouselScrollHorizontal';
import TextGroupSwitch from '@components/molecules/TextGroupSwitch/TextGroupSwitch';
import { solidColors } from '@constants/styles/colors.constants';
import { createPage } from '@services/dynamicRenderingService/dynamicRendering.service';
import { useViewport } from '@hooks';
import { isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import {
  ILuggageData,
  setClearLuggages,
  setLuggages,
  setSameLuggagesFlag,
} from '@features/Fly/FlySearchTripDetailsSlice';
import { getFromLocalStorage } from '@components/utils/storage';
import { useAppSelector } from '@app/hooks';
import { IImgTitleCopyStepperData } from '@components/molecules/ImageTitleSubtitleStepperListItemView/ImageTitleSubtitleStepperListItemView.interfaces';
import { asyncThunkFactory } from '@services/sliceFactoryService/requestSlice';
import { dataSliceOptions } from '@services/sliceFactoryService/sliceFactoryService.types';
import { BREAKPOINTS } from '@components/utils/breakpoints';
import { Elevation } from '@wheelsup/wu-react-components';
import { Toggle } from '@wheelsup/wu-react-components';

export default function SubPageLuggageSelection({
  listOfLuggageInfoForAllLegs,
  maxLuggageAlert,
  cancelButton,
  saveButton,
}: ISubPageLuggageSelection) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: reservationId } = useParams();
  const flightDetailId = getFromLocalStorage(
    'flightDetailId',
  );
  const { width } = useViewport();

  const { luggages, sameLuggagesFlag } = useAppSelector(
    (state) => state.searchTripDetails,
  );

  const sameLuggageForAllFlightsCopy =
    listOfLuggageInfoForAllLegs[0]
      .sameLuggageForAllFlightsCopy;
  const ref = useRef<HTMLDivElement>(null);
  const alertRef = useRef<HTMLDivElement>(null);
  const parentRef = useRef<HTMLDivElement>(null);

  const [activeIndex, setActiveIndex] = useState(0);
  const [hasMaxLuggage, setHasMaxLuggage] = useState(false);
  const [
    fallbackLuggageData,
    setFallbackLuggageData,
  ] = useState<ILuggageData | null>(luggages);
  const [firstClick, setFirstClick] = useState(false);
  const [alertHeight, setAlertHeight] = useState(0);
  const [noLuggageDiff, setNoLuggageDiff] = useState(true);
  useEffect(() => {
    hasMaxLuggage && setFirstClick(true);

    /*
            Below will iterate through each fallback luggage and compare against redux state
            If there is a difference, it will set noLuggageDiff to false and throw a
            controlled error to break the loop
        */
    setNoLuggageDiff(true);
    try {
      Object.values(fallbackLuggageData ?? []).forEach(
        (luggage, index) => {
          const luggagesData =
            luggages[activeIndex]?.luggageData;
          const fallbackData: IImgTitleCopyStepperData[] =
            luggage.luggageData;

          (fallbackData ?? []).forEach((l, i) => {
            if (
              l.data.stepper.defaultValue !==
              luggagesData[i].data.stepper.defaultValue
            ) {
              setNoLuggageDiff(false);
              throw new Error('StopIteration');
            }
          });
        },
      );
    } catch (e) {
      const { message } = e as Error;
      if (message !== 'StopIteration') {
        // Something else went wrong here. Not sure if we want to throw an error or just return out
        return;
      }
    }
  }, [luggages, fallbackLuggageData, hasMaxLuggage]);

  useLayoutEffect(() => {
    const currAlertHeight =
      alertRef.current?.clientHeight || 0;
    setAlertHeight(currAlertHeight);
  }, [parentRef, alertRef]);

  useEffect(() => {
    if (reservationId !== flightDetailId) {
      // Only want to reset the luggage data if we don't have any existing already
      let luggageObj = {};
      listOfLuggageInfoForAllLegs.forEach((leg, index) => {
        luggageObj = {
          ...luggageObj,
          [index]: {
            luggageData: leg.listOfLuggageItems,
            flightId: leg.flightId ?? '',
          },
        };
      });
      setFallbackLuggageData(luggageObj);
      dispatch(
        setLuggages({
          luggages: luggageObj,
          index: null,
          flightId: null,
        }),
      );
    } else if (!Array.isArray(luggages)) {
      setFallbackLuggageData(luggages);
      dispatch(
        setLuggages({
          luggages: luggages,
          index: null,
          flightId: flightDetailId,
        }),
      );
    }

    return () => {
      reservationId !== flightDetailId &&
        dispatch(setClearLuggages());
    };
  }, [listOfLuggageInfoForAllLegs]);

  const handleLegClick = (
    e: React.MouseEvent<HTMLDivElement>,
  ) => {
    e.preventDefault();
    const { id } = e.currentTarget;
    const index = parseInt(id.split('-')[1]);
    setActiveIndex(index);
  };

  const legNames = listOfLuggageInfoForAllLegs.map(
    (leg, index) => {
      const key = `luggageSlide-${index}`;

      if (isEmpty(leg.flightItemTitle)) return;

      return (
        <div key={key} id={key} onClick={handleLegClick}>
          <Toggle
            label={leg.flightItemTitle || ''}
            pressed={index === activeIndex}
            onChange={() => null}
          />
        </div>
      );
    },
  );

  const handleGoBack = () => {
    const availabilityId = getFromLocalStorage(
      'availabilityId',
    );
    dispatch(setClearLuggages());
    if (availabilityId) {
      navigate(
        `/flight-detail/one-way/${availabilityId}/request-book`,
      );
    } else {
      navigate(-1);
    }
  };

  const handleSwitch = () => {
    dispatch(setSameLuggagesFlag(!sameLuggagesFlag));
    if (!sameLuggagesFlag) {
      let luggage = luggages;
      if (
        fallbackLuggageData &&
        !luggages[0]?.luggageData
      ) {
        luggage = fallbackLuggageData;
      }
      setActiveIndex(0);
      setFallbackLuggageData(luggage);
      listOfLuggageInfoForAllLegs.forEach((leg, idx) => {
        dispatch(
          setLuggages({
            luggages: luggage[0]?.luggageData,
            index: idx,
            flightId: leg.flightId,
          }),
        );
      });
    } else {
      dispatch(
        setLuggages({
          luggages: fallbackLuggageData,
          index: null,
        }),
      );
    }
  };

  const hasErrorInLuggageData = (
    luggages: ILuggageData,
  ): boolean => {
    for (const key in luggages) {
      const luggageItems = luggages[key].luggageData;
      for (const item of luggageItems) {
        if (item.data.error === true) {
          return true;
        }
      }
    }
    return false;
  };

  const handleSubmit = () => {
    if (flightDetailId) {
      navigate(
        `/flight-detail/one-way/${flightDetailId}/request-book`,
      );
    } else if (hasErrorInLuggageData(luggages)) {
      return;
    } else {
      const submitData = Object.values(luggages).reduce<
        ISubmitLuggageData[]
      >((acc, leg) => {
        if (Array.isArray(leg.luggageData)) {
          const luggageData = leg.luggageData.map(
            (l: IImgTitleCopyStepperData) => ({
              bagType: l.data.codeValue,
              bagCount: l.data.stepper.defaultValue,
              note: l.data.note,
              flightId: leg.flightId,
            }),
          );
          return [...acc, ...luggageData];
        }
        return acc;
      }, []);
      const submitObj = {
        reservationId,
        flightLuggageInformationArray: submitData,
      };
      dispatch(
        asyncThunkFactory(
          dataSliceOptions.flightLegLuggageItemInfo,
        )({
          resPayload: submitObj,
        }),
      );
      setFallbackLuggageData(luggages);
      navigate(`/my-trips/${reservationId}`);
    }
  };

  return (
    <StyledSubpageLuggageSelection ref={parentRef}>
      <AlertContainer
        ref={alertRef}
        $isAlertVisible={hasMaxLuggage}
        $canAnimate={firstClick}
        $alertHeight={alertHeight}
      >
        {createPage(maxLuggageAlert)}
      </AlertContainer>
      <Elevation variant="raisedLevel2" padding={0}>
        <StyledCard
          ref={ref}
          $isVertical={width < BREAKPOINTS.MOBILE}
        >
          <TopSection
            $isVertical={width < BREAKPOINTS.MOBILE}
          >
            <CarouselScrollHorizontal
              parentWidth={ref?.current?.clientWidth || 1}
            >
              {legNames}
            </CarouselScrollHorizontal>
            <Border />
          </TopSection>
          <LuggageItemsContainer data-id="LuggageItemsContainer">
            {listOfLuggageInfoForAllLegs.map(
              (luggage, idx) => {
                const key = `luggageSlide-${idx}`;
                return (
                  activeIndex === idx && (
                    <PaxContainer
                      data-id="PaxContainer-SubpageLuggageSelection"
                      key={key}
                      $isHorizontal={true}
                    >
                      <FlightLegItemLuggageInfo
                        isStepperVertical={
                          width >= BREAKPOINTS.MOBILE
                        }
                        maximumTotalLuggage={
                          luggage?.maximumTotalLuggage
                        }
                        flightItemTitle={
                          luggage?.flightItemTitle
                        }
                        addLuggageCopy={
                          luggage?.addLuggageCopy
                        }
                        addLuggageLeadingIcon={
                          luggage?.addLuggageLeadingIcon
                        }
                        listOfLuggageItems={
                          luggage?.listOfLuggageItems
                        }
                        sameLuggageForAllFlightsCopy={
                          luggage?.sameLuggageForAllFlightsCopy
                        }
                        luggageCheckedAction={handleSwitch}
                        setHasMaxLuggage={setHasMaxLuggage}
                        quantityOfLegs={
                          listOfLuggageInfoForAllLegs.length
                        }
                        index={activeIndex}
                        flightId={luggage?.flightId}
                        isWithinChangeWindow={true}
                      />
                    </PaxContainer>
                  )
                );
              },
            )}
          </LuggageItemsContainer>
        </StyledCard>
      </Elevation>
      {sameLuggageForAllFlightsCopy &&
        activeIndex === 0 && (
          <TextGroupSwitch
            copy={sameLuggageForAllFlightsCopy}
            direction={'left'}
            color={solidColors.midnight}
            disabled={false}
            checked={sameLuggagesFlag}
            onChange={handleSwitch}
          />
        )}
      <ButtonContainer>
        <StyledButton
          {...cancelButton}
          action={{
            actionMethod: handleGoBack,
            actionAnalytics: {
              ...cancelButton.action.actionAnalytics,
              trackingTitle:
                cancelButton.action.actionAnalytics
                  ?.trackingTitle ?? '',
            },
          }}
        />
        <StyledButton
          disabled={
            saveButton.styleType === 'disabled' &&
            noLuggageDiff
          }
          {...saveButton}
          action={{
            ...saveButton.action,
            data: reservationId,
            actionMethod: handleSubmit,
            actionAnalytics: {
              ...cancelButton.action.actionAnalytics,
              trackingTitle:
                cancelButton.action.actionAnalytics
                  ?.trackingTitle ?? '',
            },
          }}
        />
      </ButtonContainer>
    </StyledSubpageLuggageSelection>
  );
}
