import { LoaderContainer } from './Loader.style';
import { Spinner } from '@wheelsup/wu-react-components';
interface ILoaderProps {
  height?: number;
}

const Loader = ({ height }: ILoaderProps) => {
  return (
    <LoaderContainer height={height}>
      <Spinner />
    </LoaderContainer>
  );
};

export default Loader;
