import { IDateDecoration } from '@components/types';
import { priorityOrder } from './DateWithPrice/helpers';
import { DateTime } from 'luxon';

export interface IDecorationMap {
  [key: string]: {
    top?: IDateDecoration;
    bottom?: IDateDecoration;
  };
}

const sortDecorations = (
  a: IDateDecoration,
  b: IDateDecoration,
) => {
  return (
    priorityOrder[b.dateType] - priorityOrder[a.dateType]
  );
};

export const restructureDateDecorations = (
  decorations: IDateDecoration[],
): IDecorationMap => {
  const map: IDecorationMap = {};
  // take this list of decorations and turn it into a map
  for (const decoration of decorations) {
    const { date, dateType } = decoration;
    // need this to match the existing string keys
    const formattedDate = DateTime.fromISO(date, { zone: 'utc' }).toFormat('yyyy-MM-dd');
    // is there an existing bottom decoration?
    const existingBottom = map[formattedDate]?.bottom;

    if (!map[formattedDate]) {
      // If there's no entry for the formatted date, initialize it
      map[formattedDate] = {};
    }

    if (dateType === 'peakDay') {
      // For peak days, add or update the 'top' decoration
      map[formattedDate].top = decoration;
    } else {
      // For non-peak days
      if (existingBottom) {
        // If there's already a bottom decoration, determine whether to overwrite it
        const bottomDecorations = [
          existingBottom,
          decoration,
        ].sort(sortDecorations);
        map[formattedDate].bottom = bottomDecorations[0];
      } else {
        // If there's no bottom decoration, assign the new one
        map[formattedDate].bottom = decoration;
      }
    }
  }
  return map;
};
