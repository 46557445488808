import styled from 'styled-components';
import { solidColors } from '@constants/styles/colors.constants';
import {
  IconButton,
} from '@components/library';
import { ImageImgix } from '@atoms';

export const ItemStyled = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
`;

export const ItemBulletCombined = styled.div`
  display: flex;
  align-items: center;
  margin-left: 0;
  width: 100%;
`;

export const AvatarStyled = styled(ImageImgix)`
  margin-right: 10px;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  width: 100%;
`;

export const StyledTableRow = styled.tr<{
  $isMyTrip?: boolean;
}>`
  color: inherit;
  display: table-row;
  outline: 0;
  vertical-align: middle;
  background-color: ${solidColors.upWhite.color};
  ${({ $isMyTrip }) => {
    if ($isMyTrip) {
      return `
                box-shadow: 0px -1px 4px rgba(0,0,0,0.08), 0px 2px 6px 1px rgba(0,0,0,0.12);
                border-radius: 8px;
            `;
    }
  }}
`;

export const SubtitleContainerStyled = styled.div`
  margin-top: 5px;
  display: flex;
`;

export const StyledTableCell = styled.td<{
  $width: number;
}>`
  width: ${({ $width }) => $width}px;
  border-top: 1px solid ${solidColors.mediumGray.color};
  border-bottom: none;
  padding: 16px;
  padding-left: 0;
  padding-right: 0;

  display: table-cell;
  font-size: 0.875rem;
  text-align: left;
  font-family: Mikro,'Rubik', sans-serif;
  font-weight: 400;
  line-height: 1.43;
  vertical-align: inherit;
`;

export const IconButtonStyled = styled(IconButton)`
  padding: 0 !important;
`;
