import styled from 'styled-components';

export const ButtonContainerWrapper = styled.div<{
  widthRem?: number;
  marginTop?: number;
}>`
  width: ${({ widthRem }) =>
    widthRem ? `${widthRem}rem` : '100%'};
  position: absolute;
  display: flex;
  z-index: 1;
  justify-content: space-between;
`;
