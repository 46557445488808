import styled from 'styled-components';
import { ITabArrow } from './TabArrow.interfaces';

export const StyledTabArrow = styled.svg<ITabArrow>`
  fill: ${({ theme }) => theme.palette.info.main};
  ${({ direction }) =>
    direction === 'left' &&
    `
        transform: rotate(180deg);
    `}
`;
