import { IButton } from '@atoms/Button/Button.interfaces';
import Typography from '@atoms/Typography/Typography';
import {
  InputLabel,
  MenuItem,
  Select,
} from '@components/library';
import { solidColors } from '@constants/styles/colors.constants';
import { typography } from '@constants/styles/typography.constants';
import {
  ExpandIconStyles,
  FormControlStyled,
  StyledFormHelperText,
} from './FormFieldTitleDropDown.styles';

export interface IFormFieldTitleDropDown {
  id: string;
  isOptional?: boolean;
  error?: boolean;
  style?: string;
  value?: string;
  title: string;
  subtitle?: string;
  fieldCopy?: string;
  placeholder?: string;
  items: string[];
  formInlineCTA?: IButton;
  leadingHelperCopy?: string;
  onChangeHandler?: (optionSelected: string) => void;
}

const FormFieldTitleDropDown = ({
  id,
  isOptional,
  error = false,
  value,
  title,
  leadingHelperCopy,
  items,
  onChangeHandler,
}: IFormFieldTitleDropDown) => {
  return (
    <FormControlStyled
      error={error}
      variant="outlined"
      id={id}
    >
      <InputLabel
        id={`form-field-title-drop-down-label-${title}`}
      >
        {title}
      </InputLabel>
      <Select
        IconComponent={ExpandIconStyles}
        labelId={`form-fiel-title-drop-down-label-${title}`}
        value={value}
        onChange={(
          e: React.ChangeEvent<HTMLSelectElement>,
        ) =>
          onChangeHandler && onChangeHandler(e.target.value)
        }
        label={title}
      >
        {isOptional ? (
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
        ) : null}
        {items.map((x, i) => (
          <MenuItem
            value={x}
            key={i}
          >
            {x}
          </MenuItem>
        ))}
      </Select>
      <StyledFormHelperText>
        <Typography
          variant={typography.caption}
          color={
            error ? solidColors.lava : solidColors.darkGray
          }
        >
          {leadingHelperCopy}
        </Typography>
      </StyledFormHelperText>
    </FormControlStyled>
  );
};

export default FormFieldTitleDropDown;
