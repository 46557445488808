import { IconButton, Typography } from '@atoms';
import { Icons } from '@components/library';
import { typography } from '@constants/styles/typography.constants';
import { solidColors } from '@constants/styles/colors.constants';
import { ITitleCTACardProps } from './TitleCTACard.interfaces';
import {
  ButtonStyled,
  CardAtomStyled,
  CardContentStyled,
  CardHeaderStyled,
  CTAContainerStyled,
  HeaderStyled,
  HeadlineStyled,
  ImageStyled,
  ImageStyledContainer,
} from './TitleCTACard.styles';

const TitleCTACard = ({
  headline,
  onDismiss,
  button,
  dismissable,
  children,
}: ITitleCTACardProps) => (
  <CardAtomStyled>
    {dismissable ? (
      <CardHeaderStyled
        action={
          dismissable.image?.url ? (
            <ImageStyledContainer onClick={onDismiss}>
              <ImageStyled
                src={dismissable.image?.url}
                alt="cta"
                width={32}
              />
            </ImageStyledContainer>
          ) : (
            <IconButton
              aria-label="settings"
              onClick={onDismiss}
            >
              <Icons.Close />
            </IconButton>
          )
        }
      />
    ) : null}
    <HeaderStyled>
      <HeadlineStyled>
        <Typography
          align="center"
          variant={typography.heading05Large}
          color={solidColors.midnight}
          truncate={2}
        >
          {headline}
        </Typography>
      </HeadlineStyled>
    </HeaderStyled>
    <CardContentStyled>{children}</CardContentStyled>
    <CTAContainerStyled>
      <ButtonStyled onClick={button.action.actionMethod}>
        <Typography
          variant={typography.cta}
          truncate={1}
        >
          {button.title}
        </Typography>
      </ButtonStyled>
    </CTAContainerStyled>
  </CardAtomStyled>
);

export default TitleCTACard;
