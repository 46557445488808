import { ISubPageCreditCardForm } from './SubPageCreditCardForm.interfaces';
import {
  StyledSubPageCreditCardForm,
  StyledLine,
  StyledLineItem,
  SwitchContainer,
  SwitchTypography,
  StyledDropdownContainer,
  MobileViewLineItemContainer,
} from './SubPageCreditCardForm.styles';
import { useAppDispatch } from '@app/hooks';
import { useState } from 'react';
import { FormFieldTitleDropDown } from '@atoms';
import { Switch } from '@components/library';
import InputBase from '@atoms/InputBase';
import { fetchEnvironment } from '@services/envService/envService';
import forge from 'node-forge';
import { typography } from '@constants/styles/typography.constants';
import {
  setLocationDisableNext,
  setNewCreditCardDetails,
} from '@features/Fly/Payment/PaymentStatusSlice';
import { validateExpirationDate } from './formUtils';

const SubPageCreditCardForm = ({
  firstNameFormField,
  lastNameFormField,
  cardNumberFormField,
  cardExpirationFormField,
  cardCVVFormField,
  addressFormField,
  cityFormField,
  stateDropDown,
  zipCodeFormField,
  switchTextGroup,
  isPrimaryCardToggle,
}: ISubPageCreditCardForm) => {
  const dispatch = useAppDispatch();
  interface ICreditCardDetails {
    [key: string]: any;
  }
  interface ICreditCardErrors {
    [key: string]: boolean;
  }
  const [
    creditCard,
    setCreditCard,
  ] = useState<ICreditCardDetails>({
    isPrimaryCard: isPrimaryCardToggle.isSelected,
  });
  const [
    creditCardErrors,
    setCreditCardErrors,
  ] = useState<ICreditCardErrors>({});
  const [creditCardNumber, setCreditCardNumber] = useState(
    '',
  );
  const handleCreditCardChange = (
    inputKey: string,
    value: string,
  ) => {
    if (inputKey === 'creditCardNumber') {
      const encryptText = encryptCreditCard(value);
      creditCard['cardNumber'] = encryptText;
      creditCard['isCardNumberEncrypted'] = true;
      setCreditCardNumber(value);
    } else if (inputKey === 'creditCardExpiration') {
      const expirationDate = [
        value.slice(0, 2),
        value.slice(2),
      ];
      creditCard['cardExpirationMonth'] =
        expirationDate[0] || '';
      creditCard['cardExpirationYear'] =
        expirationDate[1] || '';
      creditCard[inputKey] = value;
    } else {
      creditCard[inputKey] = value;
    }
    setCreditCard({ ...creditCard });
    validatePayload({ ...creditCard });
    dispatch(setNewCreditCardDetails({ ...creditCard }));
  };
  const handleToggle = (inputKey: string) => {
    creditCard[inputKey] = !creditCard[inputKey] || false;
    setCreditCard({ ...creditCard });
    dispatch(setNewCreditCardDetails({ ...creditCard }));
  };
  const validatePayload = (
    creditCardInfo: ICreditCardDetails,
  ) => {
    const keys = [
      'firstname',
      'lastname',
      'address',
      'city',
      'state',
      'zipCode',
      'cardNumber',
      'cardType',
      'cardExpirationMonth',
      'cardExpirationYear',
      'cardCVV',
      'isCardNumberEncrypted',
    ];
    let isPayloadComplete = true;
    keys.forEach((key) => {
      if (!(key in creditCardInfo)) {
        isPayloadComplete = false;
      } else {
        if (key === 'cardExpirationYear') {
          if (
            validateExpirationDate(
              creditCard['cardExpirationMonth'],
              creditCard['cardExpirationYear'],
            )
          ) {
            setCreditCardErrors({
              ...creditCardErrors,
              expirationDate: false,
            });
          } else {
            setCreditCardErrors({
              ...creditCardErrors,
              expirationDate: true,
            });
          }
        }
      }
    });
    if (isPayloadComplete) {
      dispatch(setLocationDisableNext(false));
    }
  };
  const encryptCreditCard = (creditCard: string) => {
    // this needs to be move
    const keys = {
      prod: `-----BEGIN PUBLIC KEY-----
            MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAjvXdTj18PYMxkMEOxZDmvTKv4oN0NwZcGgw/jz+4lgxMRSD/szYqdszW1mxUzmZxdo5I6NkRrsD/7ZHBJQC5uOLwNvQXhH3GbX4lbMJOpMoNu8YUxLP0c/FbU2iXip132B09cWG7ibql1ZI51LLCVnRNcBneUZFy+JnNyt1db/PbmP70HvG7rAhmBfYPFBbs36Pjmp8pxx09xhE8JfNNKTYwf3XbOpC/bd3GQAPtUGjzdKcQVHiwOT/uKV0G4q6DyUK90yEic8huCid7hllkO0nBtsvmmjSzdazQBI4fqA5H0BQEgwPCSuSe3DWRq9ocekdCAeaN8vLN4oVM1/bs6QIDAQAB
            -----END PUBLIC KEY-----
            `,
      lower: `-----BEGIN PUBLIC KEY-----
            MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAoNydzp9eSgLLPBFLUpEwWvMyss/ETiGlj38t31ascc3cOhEdMVhc2sZLRaM+hmssE1LPsSe15u/WyYHg0BOxzsxA521xEC2DqXfZLITAkaKCbPecUZEMrsdMbFeeeNTS8+MiX2o7CljljeRv7vgXYjuhHtgqF5PDQDR0rdmjx/7z/lJBU+XQFwkhXRVb/vvtpKcFieRdCnQ4LQ1T73FReWg7AIJD1QhUbOXlnKo9QRGWoWjQokq4erfa/vwiqyld2Unh7X0+XOhey1yuX7hqnL/ve0e68ABMJcrQhH35r0nniTERb1bfe7HPRoRNWdzvdztV4GG/hYMrbX6i7lhmWwIDAQAB
            -----END PUBLIC KEY-----
            `,
    };
    const env = fetchEnvironment();
    const publicKey =
      env === 'PROD' ? keys.prod : keys.lower;
    const pubKey = forge.pki.publicKeyFromPem(publicKey);
    const encryptText = pubKey.encrypt(creditCard);
    return btoa(encryptText);
  };
  const lostFocus = (
    event: React.FocusEvent<
      HTMLInputElement | HTMLTextAreaElement
    >,
  ) => {
    const creditCardNumber = event.target.value;
    const regexList = [
      {
        key: 'electron',
        value: /^(4026|417500|4405|4508|4844|4913|4917)\d+$/,
      },
      {
        key: 'maestro',
        value: /^(5018|5020|5038|5612|5893|6304|6759|6761|6762|6763|0604|6390)\d+$/,
      },
      { key: 'dankort', value: /^(5019)\d+$/ },
      { key: 'interpayment', value: /^(636)\d+$/ },
      { key: 'unionpay', value: /^(62|88)\d+$/ },
      {
        key: 'visa',
        value: /^4[0-9]{12}(?:[0-9]{3})?$/,
      },
      {
        key: 'mastercard',
        value: /^5[1-5][0-9]{14}$/,
      },
      { key: 'amex', value: /^3[47][0-9]{13}$/ },
      {
        key: 'diners',
        value: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
      },
      {
        key: 'discover',
        value: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
      },
      {
        key: 'jcb',
        value: /^(?:2131|1800|35\d{3})\d{11}$/,
      },
    ];
    regexList.map((regex) => {
      if (regex.value.test(creditCardNumber)) {
        creditCard['cardType'] = regex.key;
        setCreditCard({ ...creditCard });
        dispatch(
          setNewCreditCardDetails({
            ...creditCard,
          }),
        );
      }
    });
  };

  return (
    <StyledSubPageCreditCardForm>
      <StyledLine>
        <MobileViewLineItemContainer>
          <InputBase
            width="100%"
            title={firstNameFormField.title || ''}
            payloadKey={firstNameFormField.type}
            type={firstNameFormField.type}
            variant="outlined"
            value={creditCard['firstname']}
            minCharactersAllowed={
              firstNameFormField.minCharactersRequired
            }
            maxCharactersAllowed={
              firstNameFormField.maxCharactersAllowed
            }
            onChange={(e) => {
              handleCreditCardChange(
                'firstname',
                e.target.value,
              );
            }}
          />
        </MobileViewLineItemContainer>
        <MobileViewLineItemContainer>
          <InputBase
            width="100%"
            title={lastNameFormField.title || ''}
            payloadKey={lastNameFormField.type}
            type={lastNameFormField.type}
            variant="outlined"
            value={creditCard['lastname']}
            minCharactersAllowed={
              lastNameFormField.minCharactersRequired
            }
            maxCharactersAllowed={
              lastNameFormField.maxCharactersAllowed
            }
            onChange={(e) => {
              handleCreditCardChange(
                'lastname',
                e.target.value,
              );
            }}
          />
        </MobileViewLineItemContainer>
      </StyledLine>
      <StyledLine>
        <MobileViewLineItemContainer>
          <InputBase
            width="100%"
            title={cardNumberFormField.title || ''}
            payloadKey={cardNumberFormField.type}
            value={creditCardNumber}
            type={cardNumberFormField.type}
            variant="outlined"
            minCharactersAllowed={
              cardNumberFormField.minCharactersRequired
            }
            maxCharactersAllowed={
              cardNumberFormField.maxCharactersAllowed
            }
            onChange={(e) => {
              handleCreditCardChange(
                'creditCardNumber',
                e.target.value,
              );
            }}
            onBlur={(e) => {
              lostFocus(e);
            }}
          />
        </MobileViewLineItemContainer>
        <StyledLineItem>
          <MobileViewLineItemContainer>
            <InputBase
              width="100%"
              title={cardExpirationFormField.title || ''}
              payloadKey={cardExpirationFormField.type}
              value={creditCard['creditCardExpiration']}
              defaultValue={
                cardExpirationFormField.placeholder || ''
              }
              type={cardExpirationFormField.type}
              variant="outlined"
              minCharactersAllowed={
                cardExpirationFormField.minCharactersRequired
              }
              /*
                                For some reason the new cc exp input style doesn't let "7" work
                                which is what we get from pegasus. So we are now hardcoding to 8.
                            */
              maxCharactersAllowed={8}
              onChange={(e) => {
                handleCreditCardChange(
                  'creditCardExpiration',
                  e.target.value,
                );
              }}
              errorMessage="Invalid Date"
              error={creditCardErrors['expirationDate']}
            />
          </MobileViewLineItemContainer>
          <MobileViewLineItemContainer>
            <InputBase
              width="100%"
              title={cardCVVFormField.title || ''}
              payloadKey={cardCVVFormField.type}
              type={cardCVVFormField.type}
              variant="outlined"
              value={creditCard['cardCVV']}
              minCharactersAllowed={
                cardCVVFormField.minCharactersRequired
              }
              maxCharactersAllowed={
                cardCVVFormField.maxCharactersAllowed
              }
              onChange={(e) => {
                handleCreditCardChange(
                  'cardCVV',
                  e.target.value,
                );
              }}
            />
          </MobileViewLineItemContainer>
        </StyledLineItem>
      </StyledLine>
      <StyledLine>
        <MobileViewLineItemContainer>
          <InputBase
            width="100%"
            title={addressFormField.title || ''}
            payloadKey={addressFormField.type}
            type={addressFormField.type}
            variant="outlined"
            value={creditCard['address']}
            minCharactersAllowed={
              addressFormField.minCharactersRequired
            }
            maxCharactersAllowed={
              addressFormField.maxCharactersAllowed
            }
            onChange={(e) => {
              handleCreditCardChange(
                'address',
                e.target.value,
              );
            }}
          />
        </MobileViewLineItemContainer>
      </StyledLine>
      <StyledLine>
        <MobileViewLineItemContainer>
          <InputBase
            width="100%"
            title={cityFormField.title || ''}
            payloadKey={cityFormField.type}
            type={cityFormField.type}
            variant="outlined"
            value={creditCard['city']}
            minCharactersAllowed={
              cityFormField.minCharactersRequired
            }
            maxCharactersAllowed={
              cityFormField.maxCharactersAllowed
            }
            onChange={(e) => {
              handleCreditCardChange(
                'city',
                e.target.value,
              );
            }}
          />
        </MobileViewLineItemContainer>
        <StyledLineItem>
          <MobileViewLineItemContainer>
            <StyledDropdownContainer>
              <FormFieldTitleDropDown
                id="billing-state"
                title={stateDropDown.placeholder}
                value={creditCard['state']}
                onChangeHandler={(option) =>
                  handleCreditCardChange('state', option)
                }
                items={stateDropDown.items}
              />
            </StyledDropdownContainer>
          </MobileViewLineItemContainer>
          <MobileViewLineItemContainer>
            <InputBase
              width="100%"
              title={zipCodeFormField.title || ''}
              payloadKey={zipCodeFormField.type}
              type={zipCodeFormField.type}
              variant="outlined"
              value={creditCard['zipCode']}
              minCharactersAllowed={
                zipCodeFormField.minCharactersRequired
              }
              maxCharactersAllowed={
                zipCodeFormField.maxCharactersAllowed
              }
              onChange={(e) => {
                handleCreditCardChange(
                  'zipCode',
                  e.target.value,
                );
              }}
            />
          </MobileViewLineItemContainer>
        </StyledLineItem>
      </StyledLine>
      <StyledLine>
        <SwitchContainer>
          <SwitchTypography variant={typography.body1b}>
            {switchTextGroup.title}
          </SwitchTypography>
          <Switch
            checked={creditCard['isPrimaryCard']}
            disabled={!isPrimaryCardToggle.isEnabled}
            onChange={() => {
              handleToggle('isPrimaryCard');
            }}
          />
        </SwitchContainer>
      </StyledLine>
    </StyledSubPageCreditCardForm>
  );
};
export default SubPageCreditCardForm;
