import { ImageImgix } from '@atoms';
import { solidColors } from '@constants/styles/colors.constants';
import { typography } from '@constants/styles/typography.constants';
import { IItemObj } from './FlightItemsSection.interfaces';
import {
  FuelStopText,
  IconText,
  ItemContainer,
  ItemsContainer,
} from './FlightItemsSection.styles';
import { Icon } from '@wheelsup/wu-react-components';

export default function FlightItemsSection(
  props: IItemObj,
) {
  return (
    <ItemsContainer>
      <ItemContainer>
        {props.passengers && props.passengers.image && (
          <ImageImgix
            src={
              props.passengers && props.passengers.image
                ? props.passengers.image.url
                : ''
            }
            alt="icon"
            width={20}
            height={20}
            filter={
              props.passengers && props.passengers.image
                ? props.passengers.image?.tintColor
                : ''
            }
            borderRadius="0"
          />
        )}
        <IconText
          $paddingTop
          variant={typography.caption}
          color={
            props.passengers.isBlue
              ? solidColors.midnight
              : solidColors.darkGray
          }
        >
          {props.passengers && props.passengers.copy}
        </IconText>
      </ItemContainer>
      <ItemContainer>
        {props.estimatedDuration &&
          props.estimatedDuration.image && (
            <ImageImgix
              src={
                props.estimatedDuration.image
                  ? props['estimatedDuration'].image.url
                  : ''
              }
              alt="icon"
              width={20}
              height={20}
              filter={
                props.estimatedDuration.image
                  ? props['estimatedDuration'].image
                      .tintColor
                  : ''
              }
              borderRadius="0"
            />
          )}
        <IconText
          $paddingTop
          variant={typography.caption}
          color={
            props.estimatedDuration.isBlue
              ? solidColors.midnight
              : solidColors.darkGray
          }
        >
          {props.estimatedDuration &&
            props.estimatedDuration.copy}
        </IconText>
      </ItemContainer>
      {props.fuelStop && (
        <ItemContainer>
          {props.fuelStop.image ? (
            <ImageImgix
              src={
                props.fuelStop.image
                  ? props.fuelStop.image.url
                  : ''
              }
              alt="icon"
              width={20}
              height={20}
              filter={props.fuelStop.image?.tintColor}
              borderRadius="0"
            />
          ) : (
            <Icon
              name="location"
              size="m"
              color="secondary"
            />
          )}
          <FuelStopText
            $paddingTop
            variant={typography.caption}
            color={
              props.fuelStop.isBlue
                ? solidColors.midnight
                : solidColors.darkGray
            }
          >
            {props.fuelStop.copy}
          </FuelStopText>
        </ItemContainer>
      )}
    </ItemsContainer>
  );
}
