import {
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit';
import { pegasusClient } from '@services/apiService';
import { ISliceFactoryArgs } from '@services/sliceFactoryService/sliceFactoryService.types';

interface IWebToCase {
  webToCaseSuccess?: boolean | undefined;
  toastMessage?: string;
  webToCaseIsLoading: boolean;
  webToCaseError: unknown | null;
}

const initialState: IWebToCase = {
  webToCaseSuccess: undefined,
  toastMessage: '',
  webToCaseIsLoading: false,
  webToCaseError: null,
};

interface IProps {
  urlParams?: string;
  queryParams?: string;
  token?: string;
  type?: string;
  resPayload?: unknown;
}

export function submitWebToCase({
  pageName,
  endpoint,
  version,
}: ISliceFactoryArgs) {
  return createAsyncThunk(
    `${pageName}/fetch${pageName}`,
    async (props: IProps) => {
      const { resPayload, token } = props;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          WUToken: token,
        },
      };
      try {
        return (
          await pegasusClient.post(
            `${endpoint}?version=${version}&platform=web`,
            resPayload,
            config,
          )
        ).data;
      } catch (err) {
        return err;
      }
    },
  );
}

export function hubWebToCase({
  pageName,
  endpoint,
  version,
}: ISliceFactoryArgs) {
  const hubWebToCaseThunk = submitWebToCase({
    endpoint: endpoint,
    pageName: pageName,
    version: version,
  });

  const hubWebToCaseSlice = createSlice({
    name: pageName,
    initialState: initialState,
    reducers: {
      getPage(state, action) {
        const { payload } = action;
        state.toastMessage = payload;
      },
    },
    extraReducers: (builder) => {
      builder.addCase(
        hubWebToCaseThunk.pending,
        (state) => {
          state.webToCaseIsLoading = true;
        },
      );
      builder.addCase(
        hubWebToCaseThunk.fulfilled,
        (state, action) => {
          const { payload } = action;
          state.webToCaseSuccess = true;
          state.webToCaseIsLoading = false;
          state.toastMessage = payload.data.data.copy;
        },
      );
      builder.addCase(
        hubWebToCaseThunk.rejected,
        (state, action) => {
          const { payload } = action;
          state.webToCaseSuccess = false;
          state.webToCaseIsLoading = false;
          state.webToCaseError = payload;
        },
      );
    },
  });
  return hubWebToCaseSlice.reducer;
}
