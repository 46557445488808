import { useState } from 'react';
import { Grid } from '@components/library';
import TextGroupSwitch from '@components/molecules/TextGroupSwitch/TextGroupSwitch';
import {
  getFromLocalStorage,
  saveDataInLocalStorage,
} from '@components/utils/storage';
import { solidColors } from '@constants/styles/colors.constants';
import { typography } from '@constants/styles/typography.constants';
import {
  Checkbox,
  Divider,
  ModalWithTitle,
} from '@wheelsup/wu-react-components';
import { Typography } from '@wheelsup/wu-react-components';
import { useFeatureFlags } from '@services/featureFlagService/featureFlagService.services';
import { queryClient } from '@app/App';
import styled from 'styled-components';
import { useKeyboardShortcut } from '@hooks';
import { buildTime } from '../../buildTime';

const StyledModal = styled(ModalWithTitle)`
  width: 600px;
`;

const DevSettings = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const { data: flags } = useFeatureFlags();
  const isProd =
    window.location.host === 'members.wheelsup.com';

  useKeyboardShortcut(
    {
      keys: ['D'],
      ctrl: true,
      shift: true,
    },
    () => {
      setModalOpen(!modalOpen);
    },
  );

  const reduxLoggerEnabledLocal = getFromLocalStorage(
    'REDUX_LOGGER_ENABLED_KEY',
  );
  const [
    reduxLoggerEnabled,
    setReduxLoggerEnabled,
  ] = useState(reduxLoggerEnabledLocal);

  const setFlag = (flag: string) => {
    if (flags && flag in flags) {
      const currFlags = flags as { [key: string]: boolean };
      queryClient.setQueryData(['featureFlags'], {
        ...currFlags,
        [flag]: !currFlags[flag],
      });
    }
  };

  return (
    <StyledModal
      state={{
        isOpen: modalOpen,
        setOpen: setModalOpen,
        open: () => setModalOpen(true),
        close: () => setModalOpen(false),
        toggle: () => setModalOpen(!modalOpen),
      }}
      title="Dev Settings"
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-end',
          gap: '1rem',
          height: '100%',
          width: '100%',
          padding: '1rem 2rem',
        }}
      >
        <Typography variant="heading04">Version</Typography>
        <Typography variant="body02">
          {buildTime}
        </Typography>
      </div>
      <Divider width={66} />
      {isProd ? null : (
        <>
          <div
            style={{
              height: '100%',
              width: '100%',
              padding: '1rem 2rem',
            }}
          >
            <Typography variant="heading04">
              Redux Logger
            </Typography>
            <Checkbox
              label={'Redux Logger Enabled'}
              checked={reduxLoggerEnabled}
              onChange={(checked) => {
                setReduxLoggerEnabled(checked);
                saveDataInLocalStorage(
                  'REDUX_LOGGER_ENABLED_KEY',
                  checked,
                );
                document.location.reload();
              }}
            />
          </div>
          <Divider width={66} />
          <div
            style={{
              height: '100%',
              width: '100%',
              padding: '1rem 2rem',
            }}
          >
            <Typography variant="heading04">
              Feature Flags
            </Typography>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                padding: '1rem 0',
                gap: '.5rem',
              }}
            >
              {flags &&
                Object.entries(flags).map(
                  ([flag, value]) => {
                    return (
                      <Grid key={flag} item xs={12}>
                        <TextGroupSwitch
                          copy={flag}
                          checked={value}
                          onChange={() => setFlag(flag)}
                          direction="right"
                          textStyle={typography.body1}
                          color={solidColors.midnight}
                        />
                      </Grid>
                    );
                  },
                )}
            </div>
            <Typography variant="body02Bold">
              A hard refresh will reset the flags.
            </Typography>
          </div>

          <hr />
        </>
      )}
    </StyledModal>
  );
};

export default DevSettings;
