import React, { useState } from 'react';
import { Dialog } from '@material-ui/core';
import { DialogContent } from '@components/library';
import {
  ButtonContainer,
  ImageTextContainer,
  OnboardingButton,
  TextSection,
  HeadlineTypography,
  CheckboxSection,
  TermsTypography,
  HeaderSection,
} from './SubPageTermsOfService.styles';
import { typography } from '@constants/styles/typography.constants';
import { solidColors } from '@constants/styles/colors.constants';
import { ISubPageOnboarding } from './SubPageTermsOfService.interface';
import Iframe from '@atoms/Iframe/Iframe';
import { asyncThunkFactory } from '@services/sliceFactoryService/requestSlice';
import { useDispatch } from 'react-redux';
import { dataSliceOptions } from '@services/sliceFactoryService/sliceFactoryService.types';
import { Checkbox } from '@wheelsup/wu-react-components';
import { Divider } from '@wheelsup/wu-react-components';

const customProps = {
  style: {
    borderRadius: 8,
    width: 864,
    height: 791,
  },
};

function SubPageTermsOfService({
  title,
  termsOfServiceURL,
  checkboxTextGroup,
  acceptButton,
  signOutButton,
  version,
}: ISubPageOnboarding) {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [isChecked, setIsChecked] = useState(false);
  const [isAccepted, setIsAccepted] = useState(false);

  const dispatch = useDispatch();

  const textTitle =
    checkboxTextGroup?.data.titleTextGroup.title ??
    'I accept the terms of service';

  const handleTermsChange = (checked: boolean) => {
    setIsChecked(checked);
  };

  const handleSignout = () => {
    window.location.href = '/signout';
  };

  //url for pdf
  const url = `${termsOfServiceURL}#toolbar=0`;

  const handleSubmit = () => {
    if (!isChecked) {
      return;
    }
    const queryParams = '&action=set-terms-of-service';
    dispatch(
      asyncThunkFactory(dataSliceOptions.termsOfService)({
        resPayload: {
          termsAndConditionsAccepted: true,
          version,
        },
        queryParams,
      }),
    );
    setIsAccepted(true);
  };

  return (
    <>
      {!isAccepted ? (
        <Dialog
          maxWidth={'md'}
          PaperProps={customProps}
          open={isModalOpen}
        >
          <DialogContent datatestid="Terms of Service">
            <ImageTextContainer>
              <HeaderSection>
                <HeadlineTypography
                  color={solidColors.c1}
                  variant={typography.h4b}
                >
                  {title}
                </HeadlineTypography>
              </HeaderSection>
              <TextSection>
                <Iframe
                  refresh={false}
                  isLoading={true}
                  src={url ?? ''}
                  title={title ?? ''}
                  styleBorder="0"
                  styleBorderStyle="none"
                  styleHeight="400px"
                  styleWidth="100%"
                  styleBorderRadius="8px"
                />
              </TextSection>

              <CheckboxSection>
                <Checkbox
                  onChange={handleTermsChange}
                  checked={isChecked}
                  label={
                    <TermsTypography
                      color={solidColors.darkGray}
                      variant={typography.body2a}
                    >
                      {textTitle}
                    </TermsTypography>
                  }
                  size="sm"
                />
              </CheckboxSection>
            </ImageTextContainer>
            <Divider />
            <ButtonContainer>
              <OnboardingButton
                {...acceptButton}
                styleType={'card_disabled'}
                title={signOutButton?.title ?? 'SIGN OUT'}
                height="50px"
                action={{
                  actionMethod: () => handleSignout(),
                }}
              />
              <OnboardingButton
                disabled={!isChecked}
                styleType={'primary'}
                title={
                  acceptButton?.title ?? 'ACCEPT TERMS'
                }
                width="200px"
                height="50px"
                action={{
                  actionMethod: handleSubmit,
                }}
              />
            </ButtonContainer>
          </DialogContent>
        </Dialog>
      ) : null}
    </>
  );
}

export default SubPageTermsOfService;
