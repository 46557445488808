import React from 'react';
import { deprecate } from 'util';

interface BoxProps extends React.CSSProperties {
  children?: React.ReactNode;
  className?: string;
  id?: string;
}

/**
 // @deprecate
 * This component is used to replace the MUI Box component. It should not be used going forward.
 * { m, mx, my, mb, p, px, py, children, ...rest }
 * React.CSSProperties
 */
const Box = (props: BoxProps) => {
  const { children, className = '', id = '', ...style } = props;
  if (children) return <div
    className={className}
    id={id}
    style={style}
  >{children}</div>;

  return <div
    className={className}
    id={id}
    style={style}
  />;
};

export default Box;