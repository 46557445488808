import { ITabPanel } from './TabPanel.interfaces';

export default function TabPanel({
  children,
  id,
  value,
  position,
  ...other
}: ITabPanel) {
  return (
    <div
      {...other}
      role="tabpanel"
      hidden={value !== position}
      id={`scrollable-${id}-auto-tabpanel-${position}`}
      aria-labelledby={`scrollable-auto-tab-${position}`}
    >
      {value === position && children}
    </div>
  );
}
