import React, { useRef } from 'react';
import {
  CarouselSlide,
  CarouselSlidesContainer,
} from './CarouselSmallArrowWebOnly.styles';

interface ISlides {
  children: React.ReactNode[];
  transformAmount: number;
  slidesPerView: number;
  startIndex: number;
  slideMargin: number;
  allActiveFallback: boolean;
  inComponent?: boolean;
  slideRef?: React.RefObject<HTMLDivElement>;
  totalWidth?: number;
  numItemsPerSlide?: number;
  fullCardWidthOverride?: boolean;
}

function Slides({
  children,
  transformAmount,
  slidesPerView,
  startIndex,
  slideMargin,
  allActiveFallback,
  inComponent = false,
  slideRef,
  totalWidth = 0,
  numItemsPerSlide,
  fullCardWidthOverride = false,
}: ISlides) {
  return (
    <CarouselSlidesContainer
      $numItemsPerSlide={numItemsPerSlide}
    >
      {children.map((slide, index) => {
        const className =
          'CarouselSmallArrowWebOnlySlide-slide';
        const key = `${className}-${index}`;
        const active =
          index >= startIndex &&
          index < startIndex + slidesPerView;
        return (
          <CarouselSlide
            key={key}
            ref={slideRef}
            $width={totalWidth}
            className={className}
            data-id={className}
            $inComponent={inComponent}
            $translateX={transformAmount}
            $active={active || allActiveFallback}
            $marginRight={slideMargin}
            $numItemsPerSlide={numItemsPerSlide}
            $fullCardWidthOverride={fullCardWidthOverride}
          >
            {slide}
          </CarouselSlide>
        );
      })}
    </CarouselSlidesContainer>
  );
}

export default Slides;
