import styled from 'styled-components';
import { Typography } from '@atoms';
import { solidColors } from '@constants/styles/colors.constants';
import { Button } from '@atoms/Button/Button';

export const TextContainer = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  width: 100%;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
`;
export const CopyContainer = styled.div`
  display: flex;
  gap: 5px;
  color: ${solidColors.midnight.color};
`;

export const TypographyStyled = styled(Typography)<{
  headerWeight?: string;
  headerSize?: string;
}>`
  font-size: ${({ headerSize }) => headerSize ?? '24px'};
  font-weight: ${({ headerWeight }) => headerWeight ?? ''};
`;

export const RowWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`;

export const CopyStyled = styled(Typography)<{
  copySize?: string;
}>`
  font-size: ${({ copySize }) => copySize ?? '18px'};
`;

export const ItemContainer = styled.div<{
  flexDirection?: string;
}>`
  display: flex;
  flex-direction: ${({ flexDirection }) =>
    flexDirection ?? 'row'};
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;

export const StyledTrailIcon = styled.div`
  margin-right: 1rem;
  width: 12px;
`;
export const StyledButton = styled(Button)<{
  margin?: string;
}>`
  &.MuiButton-root {
    border: none;
    padding: 0;
    margin: ${({ margin }) => margin ?? '0'};
  }
`;
