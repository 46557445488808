import { IImgTitleCopyStepperData } from '@molecules/ImageTitleSubtitleStepperListItemView/ImageTitleSubtitleStepperListItemView.interfaces';

export type LuggageRequestType = {
  luggageCount?: number;
  luggageType?: string;
  note?: string;
}[];

// function used to transform luggage from stepper to required pegasus array
const transformLuggage = (
  luggage: IImgTitleCopyStepperData[],
): LuggageRequestType => {
  let newLuggages: LuggageRequestType = [];

  if (Array.isArray(luggage)) {
    newLuggages = luggage.map((l) => {
      return {
        luggageCount: l.data.stepper.defaultValue,
        luggageType: l.data.codeValue,
        note: l.data?.note
      };
    });
  }

  return newLuggages;
};

export default transformLuggage;
