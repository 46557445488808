import React from 'react';
import {
  IComponent,
  IError,
  IItem,
  IPage,
} from './dynamicRendering.interfaces';
import { Components } from './dynamicRendering.constants';
import {
  getPageFormattedElements,
  instanceOfComponent,
  instanceOfPage,
} from './utils';

export type DynamicUI =
  | IPage
  | IComponent
  | IItem
  | any
  | null;

export function createPage(
  data?: DynamicUI,
  alertsOnly?: boolean,
): React.ReactNode {
  // Former error handling component now handled in responseHandler
  if (!data) return;

  function createComp(
    item: IComponent,
    idx?: string,
  ): React.ReactNode {
    const { data, type } = item;
    if (!data) {
      return null;
    }
    const {
      items,
      element,
      elements,
      page,
      id,
      ...rest
    } = data;

    const wrapItems = items ?? elements;
    if (!(type in Components)) {
      return null;
    } else {
      // @FIXME: This is a temporary hack to handle rendering membership expiration alerts on the account page
      if (alertsOnly && type === 'ContainerEmpty') {
        if (
          element?.type ===
          'CardAccordionHighMidPriorityAlert'
        ) {
          return React.createElement(
            Components[type] as any,
            {
              id: element?.data?.id,
              title: element?.data?.title,
              copy: element?.data?.copy,
              key: element?.data?.id ?? idx,
            } as any,
            Array.isArray(wrapItems)
              ? wrapItems.map(renderer)
              : element
              ? renderer(element)
              : renderer(page ?? null),
          );
        }
      } else {
        return React.createElement(
          Components[type] as any,
          {
            id,
            ...rest,
            ...(!!wrapItems && {
              items: wrapItems,
            }),
            key: id ?? idx,
          } as any,
          Array.isArray(wrapItems)
            ? wrapItems.map(renderer)
            : element
            ? renderer(element)
            : renderer(page ?? null),
        );
      }
    }
  }

  function renderer(config: DynamicUI): React.ReactNode {
    if (!config) return null;

    if (instanceOfPage(config)) {
      const elements = getPageFormattedElements(config);

      // @FIXME: This is a temporary hack to handle rendering membership expiration alerts on the account page
      return elements.map((element, index) => {
        if (
          alertsOnly &&
          element?.data?.element?.type ===
            'CardAccordionHighMidPriorityAlert'
        ) {
          return createComp(element, `${index}`);
        }
        return createComp(element, `${index}`);
      });
    }
    if (instanceOfComponent(config)) {
      return createComp(config);
    }
    if (config?.element || config?.page) {
      return renderer(
        config.element ?? config.page ?? null,
      );
    }
    return null;
  }
  return renderer(data);
}

const componentBlackList = ['children', 'rest', 'id'];
const contractBlackList = [
  'id',
  'elements',
  'element',
  'items',
  'data',
  'page',
];
const extractionFields = [
  'data',
  'element',
  'elements',
  'items',
  'page',
];

export const ComponentNotFound =
  'Component not found. Please check component ID.';
export const ContractMismatch =
  'Contract mismatch with component or vice versa';

type IProcessDynamicValidation =
  | DynamicUI
  | Object
  | undefined
  | null;

/**
 * Enhance the pegasus payload detecting components not found and contract mismatch.
 * @param data Pegasus Payload
 * @returns Enhanced pegasus payload with error reporting.
 */
export function ProcessDynamicValidation(
  data?: DynamicUI,
): IProcessDynamicValidation {
  if (!data) return;
  const getComponentProps = (Component: any) => {
    const componentStr = Component.toString();
    const parseFieldsOutput = (fields: string[]) => {
      return fields
        .map((Field: string) => {
          let newField = Field.replace('{', '')
            .replace('}', '')
            .replace('...', '');
          const parts = newField.match(/(.*)=.*/);
          if (parts && parts?.length >= 1) {
            newField = parts[1];
          }
          return newField;
        })
        .filter((Field: string) => {
          return !componentBlackList.includes(Field);
        });
    };
    const functionMatch = componentStr.match(
      /function\s*([A-z0-9]+)?\s*\((?:[^)(]+|\((?:[^)(]+|\([^)(]*\))*\))*\)/,
    );
    if (functionMatch) {
      const match = componentStr.match(/\([^]*?\)/);
      const fields = match
        ? match[0].replace(/[()\s]/gi, '').split(',')
        : [];
      return parseFieldsOutput(fields);
    } else {
      const arrowMatch = componentStr.match(
        /\(?[^]*?\)?\s*=>/,
      );
      const arrowProps = arrowMatch[0]
        .replace(/[()\s]/gi, '')
        .replace('=>', '')
        .split(',');
      return parseFieldsOutput(arrowProps);
    }
  };

  function ProcessComponent(
    item: IComponent,
  ): IProcessDynamicValidation {
    const { data, type } = item;
    const { items, element, elements } = data;
    const wrapItems = items ?? elements;
    if (!(type in Components)) {
      item.error = {
        type,
        message: ComponentNotFound,
      };
    } else {
      const componentProps = getComponentProps(
        Components[type],
      ).filter((Field: string) => {
        return !componentBlackList.includes(Field);
      });
      const contractProps = Object.keys(data).filter(
        (Field: string) => {
          return !contractBlackList.includes(Field);
        },
      );
      const contractMissingProps = contractProps.filter(
        (Field: string) => !componentProps.includes(Field),
      );
      if (contractMissingProps.length !== 0) {
        item.error = {
          type,
          message: ContractMismatch,
          data: {
            contractMissingProps,
          },
        };
      }
      const componentMissingProps = componentProps.filter(
        (Field: string) => !contractProps.includes(Field),
      );
      if (componentMissingProps.length !== 0) {
        item.error = {
          type,
          message: ContractMismatch,
          data: {
            componentMissingProps,
            ...item.error?.data,
          },
        };
      }
    }
    Array.isArray(wrapItems)
      ? wrapItems.map(Process)
      : Process(element ?? null);
    return item;
  }

  function Process(
    config: DynamicUI,
  ): IProcessDynamicValidation {
    if (!config) return null;

    if (instanceOfPage(config)) {
      const elements = getPageFormattedElements(config);
      return elements.map(ProcessComponent);
    }
    if (instanceOfComponent(config)) {
      return ProcessComponent(config);
    }
    if (config?.element || config?.page) {
      return Process(config.element ?? config.page ?? null);
    }

    return null;
  }

  return Process(data);
}

/**
 * Verify if an enhanced payload has errors.
 * @param EnhancePayload
 * @returns Boolean determines at least one critical error (component not found)
 */
function verifyError(EnhancePayload: Object): Object[] {
  const isExtractable = (payload: Object) => {
    for (const [key, _] of Object.entries(payload)) {
      if (extractionFields.includes(key)) {
        return true;
      }
    }
    return false;
  };
  if (Array.isArray(EnhancePayload)) {
    return EnhancePayload.map((Obj: Object) =>
      verifyError(Obj),
    );
  }
  const error =
    typeof EnhancePayload === 'object'
      ? Object.keys(EnhancePayload).includes('error')
      : false;
  const errorList: Object[] = [];
  if (error) {
    const component = EnhancePayload as IComponent;
    errorList.push(component.error as Object);
  }
  if (isExtractable(EnhancePayload)) {
    for (const [key, value] of Object.entries(
      EnhancePayload,
    )) {
      if (extractionFields.includes(key)) {
        errorList.push(verifyError(value));
      }
    }
  }

  return errorList;
}

export function getErrors(data?: DynamicUI): IError[] {
  const state = ProcessDynamicValidation(data);
  function flatten(arr: Object[]): Object[] {
    return arr.reduce(function (
      flat: Object[],
      toFlatten: Object,
    ) {
      return flat.concat(
        Array.isArray(toFlatten)
          ? flatten(toFlatten)
          : toFlatten,
      );
    },
    []);
  }
  if (state) {
    const errors = flatten(verifyError(state));
    return errors as IError[];
  }
  return [];
}

export function verify(data?: DynamicUI): Boolean {
  const errors = getErrors(data);
  return (
    errors.filter(
      (Error: IError) =>
        Error.message === ComponentNotFound,
    ).length === 0
  );
}
