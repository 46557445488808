import { solidColors } from '@constants/styles/colors.constants';
import { typography } from '@constants/styles/typography.constants';
import { Typography } from '@atoms';
import {
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
} from './ListAccordionCTAHeadlineSubCopy.styles';
import parse from 'html-react-parser';
import { IListAccordionCTAHeadlineSubCopy } from './ListAccordionCTAHeadlineSubCopy.interfaces';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useState } from 'react';
import { useTextGroupBodyCopyLink } from '../TextGroupBodyCopyLink/TextGroupBodyCopyLink.styles';

const ListAccordionCTAHeadlineSubCopy = ({
  title,
  category,
  isExpandedByDefault,
  dropDownImage,
  expandedBody,
}: IListAccordionCTAHeadlineSubCopy) => {
  const [expanded, setExpanded] = useState<boolean>(
    isExpandedByDefault ?? false,
  );

  const handleChange = () => () => {
    setExpanded(!expanded);
  };

  const classes = useTextGroupBodyCopyLink();
  const validLinks =
    expandedBody.textAttributes?.links ?? [];

  const buildText = () => {
    const textToReplace = validLinks.map((l, i) => {
      let linkText = expandedBody.copy.substring(
        l.start,
        1 + l.end,
      );
      let subLink = undefined;
      let target = '';

      if (l.action.data.includes('//faq')) {
        subLink = '/hub';
      } else {
        subLink = l.action.data;
      }

      if (
        l.action.type &&
        l.action.type === 'out_of_app_browser'
      ) {
        target = '_blank';
      }

      if (
        linkText.substring(
          linkText.length - 1,
          linkText.length,
        ) === '\n'
      )
        linkText = linkText.substring(
          0,
          linkText.length - 1,
        );

      return {
        text: linkText,
        link: `<a
                data-testid="body-link-${i}-${linkText}"
                class="${classes.link}"
                target="${target}"
                href="${subLink}">
                ${linkText}
            </a>`,
      };
    });

    const expandedBodyWithLineBreaks = expandedBody.copy.replaceAll(
      '\n',
      '<br />',
    );
    const linkElements = textToReplace.reduce(
      (accumulator, link) => {
        return accumulator.replace(link.text, link.link);
      },
      expandedBodyWithLineBreaks,
    );
    return linkElements;
  };

  return (
    <StyledAccordion
      expanded={expanded}
      onChange={handleChange()}
    >
      <StyledAccordionSummary
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography
          variant={typography.body1}
          color={
            solidColors.midnight
          }
        >
          {title}
        </Typography>
      </StyledAccordionSummary>
      <StyledAccordionDetails>
        <Typography
          variant={typography.body1}
          color={solidColors.darkGray}
        >
          {parse(buildText())}
        </Typography>
      </StyledAccordionDetails>
    </StyledAccordion>
  );
};

export default ListAccordionCTAHeadlineSubCopy;
