import React, { useEffect, useState } from 'react';
import {
  TextContainer,
  HeadlineTypography,
  CheckboxSection,
  TermsTypography,
  HeaderSection,
  StyledCard,
  TermList,
  TermListItem,
  TermText,
  BtnWrapper,
} from './CardCheckboxBullets.styles';
import { ICardCheckboxBullets } from './CardCheckboxBullets.interfaces';
import { Button } from '@atoms/Button/Button';
import useSessionLock from '@hooks/use-session-lock';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from '@app/hooks';
import { getFromLocalStorage } from '@components/utils/storage';
import jwt from 'jsonwebtoken';
import { IJwtPayload } from '@components/types';
import {
  Checkbox,
  Elevation,
  Divider,
} from '@wheelsup/wu-react-components';

function CardCheckboxBullets({
  id,
  title,
  checkboxTitleTextGroup,
  checkboxCopyTextGroup,
  isPreselected,
  bullets,
}: ICardCheckboxBullets) {
  const [isChecked, setIsChecked] = useState(false);
  const { addSessionLock } = useSessionLock();
  const { flightDealId } = useParams();
  const navigate = useNavigate();
  const {
    lockId,
    availabilityId,
    error: lockError,
  } = useAppSelector((state) => state.sessionLock);

  const token = getFromLocalStorage('access_token') || '';
  const tokenDecoded = jwt.decode(token, {
    complete: true,
  });
  const { memberUserTypeCode } =
    tokenDecoded?.payload || ({} as IJwtPayload);

  const [hasLockError, setHasLockError] = useState(
    lockError,
  );

  const handleTermsChange = (checked: boolean) => {
    addSessionLock(flightDealId);
    setIsChecked(checked);
  };

  const style =
    !isChecked || hasLockError ? 'disabled' : 'primary';

  const submitButton =
    memberUserTypeCode !== 'DEMO' ? (
      <Button
        disabled={!isChecked || hasLockError}
        width="200px"
        // TODO: Clean molecule up, remove button and hardcoded navigation

        //update button once payload comes down
        action={{
          actionMethod: () =>
            navigate('/fly/checkout-flight'),
        }}
        styleType={style}
        title="Continue"
        height="3rem"
      />
    ) : null;

  useEffect(() => {
    setHasLockError(lockError);
  }, [lockError, isChecked]);

  return (
    <>
      <Elevation variant="raisedLevel2" padding={0}>
        <StyledCard>
          <TextContainer>
            <HeaderSection>
              <HeadlineTypography
                color="primary"
                variant="heading02"
              >
                {title}
              </HeadlineTypography>
            </HeaderSection>

            <TermList $hasBullets={true}>
              {bullets?.map((detail, idx) => {
                return (
                  <TermListItem
                    key={idx}
                    $hasBullets={true}
                  >
                    <TermText
                      titleTextView={detail.titleTextView}
                      $hasBullets={true}
                    />
                  </TermListItem>
                );
              })}
            </TermList>
          </TextContainer>
          <Divider />

          <CheckboxSection>
            <Checkbox
              onChange={handleTermsChange}
              checked={isChecked}
              size="sm"
              label={
                <TermsTypography
                  color="primary"
                  variant="body02"
                >
                  {checkboxTitleTextGroup.title}
                </TermsTypography>
              }
            />
          </CheckboxSection>
        </StyledCard>
      </Elevation>
      <BtnWrapper>{submitButton}</BtnWrapper>
    </>
  );
}

export default CardCheckboxBullets;
