import { IStyledRadioChecked } from './RadioChecked.interfaces';
import { Icons } from '@library';
import styled from 'styled-components';
import { solidColors } from '@constants/styles/colors.constants';

export const StyledRadioChecked = styled(
  Icons.RadioButtonCheckedOutlined,
)<IStyledRadioChecked>`
  color: ${({ $color }) =>
    $color ?? solidColors.upBlue.color};
`;
