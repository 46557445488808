import {
  IComponent,
  IPage,
} from './dynamicRendering.interfaces';

export function assembleLayout(page: IPage) {
  const pageElements: IComponent[] = page['elements'];

  const leftElements = pageElements.filter(
    (element) => element?.data['alignment'] === 'left',
  );

  const rightElements = pageElements.filter(
    (element) => element?.data['alignment'] === 'right',
  );

  const centerElements = pageElements.filter(
    (element) => element?.data['alignment'] === 'center',
  );

  const leftColumn: IComponent = {
    type: 'ContainerEmptyColumns',
    data: {
      id: '000',
      className:
        page.metadata.stickyColumn === 'left'
          ? 'isSticky'
          : null,
      elements: leftElements as IComponent[],
    },
  };

  const rightColumn: IComponent = {
    type: 'ContainerEmptyColumns',
    data: {
      id: '001',
      className:
        page.metadata.stickyColumn === 'right'
          ? 'isSticky'
          : null,
      elements: rightElements as IComponent[],
    },
  };

  const centerColumn: IComponent = {
    type: 'ContainerEmptyColumns',
    data: {
      id: '002',
      className:
        page.metadata.stickyColumn === 'center'
          ? 'isSticky'
          : null,
      elements: centerElements as IComponent[],
    },
  };

  const elements =
    centerElements.length > 0
      ? [leftColumn, rightColumn, centerColumn]
      : [leftColumn, rightColumn];

  const gridComponent: IComponent = {
    type: 'ContainerEmptyColumns',
    data: {
      id: '003',
      order: page.metadata.columnLayout,
      elements,
    },
  };

  return [gridComponent];
}
