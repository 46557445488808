import { Button, ImageImgix } from '@atoms';
import { solidColors } from '@constants/styles/colors.constants';
import styled from 'styled-components';

export const StyledSubPageManageIndividuals = styled.div<{
  padding?: string;
}>`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    max-width: 1136px;
    border-radius: 4px;
    background-color: ${solidColors.upWhite.color}};
    position: relative;
    height: fit-content;
    padding: 18px 24px 0px;
    padding-top: ${({ padding }) => padding ?? 'auto'};
    margin: auto;
`;

export const StyledIndividualList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px 30px 30px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
`;

export const StyledIndividualItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 30px 0px;
  border-bottom: 1px solid ${solidColors.mediumGray.color};
  cursor: pointer;
  &.last {
    margin-bottom: 10px;
  }
`;

export const ProfileImageContainer = styled.div`
  width: 50px;
`;

export const IndividualInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px;
  width: 85%;
`;

export const AvatarStyled = styled(ImageImgix)`
  margin-right: 10px;
`;

export const StyledButton = styled(Button)`
  width: 250px;
  margin: 25px 0px;
`;

export const StyledSubPageHeader = styled.div`
  margin-bottom: 30px;
`;

export const StyledSubPageBody = styled.div`
  padding-bottom: 20px;
  margin-bottom: 40px;
`;

export const StyledSubPageFooter = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: flex-end;
  width: 100%;

  button {
    margin-left: 10px;
  }
`;
