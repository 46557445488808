import { IButtonContainer } from './Carousel.interfaces';
import ArrowIconButton from '@molecules/ArrowIconButton/ArrowIconButton';
import { ButtonContainerWrapper } from './Carousel.styles';

export default function ButtonContainer({
  backDisabled,
  forwardDisabled,
  backFunction,
  forwardFunction,
}: IButtonContainer) {
  return (
    <ButtonContainerWrapper data-name="ButtonContainerWrapper">
      <ArrowIconButton
        disabled={backDisabled}
        clickHandler={backFunction}
      />
      <ArrowIconButton
        forward
        disabled={forwardDisabled}
        clickHandler={forwardFunction}
      />
    </ButtonContainerWrapper>
  );
}
