import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {
  Title,
  HeaderContainer,
  BackButtonContainer,
} from './HeadlineWithBackArrow.styles';
import { IHeadlineWithBackArrow } from './HeadlineWithBackArrow.interfaces';
import { IconButton } from '@library';
import { Box, Typography } from '@atoms';
import { typography } from '@constants/styles/typography.constants';
import { solidColors } from '@constants/styles/colors.constants';
import { trackAnalytics } from '@services/mixpanelService/mixpanel.service';
import { IKeyValue } from '@components/types';

const HeadlineWithBackArrow = ({
  title,
  titleTypo = 'heading01Large',
  subtitle,
  align,
  padding,
  paddingTop,
  marginLeft,
  action,
  metadataName,
  isSearchResultsPage,
}: IHeadlineWithBackArrow) => {
  const handleArrowClick = () => {
    if (action) action();
    const actionAnalytics: unknown = {
      key: 'Page Name',
      value: metadataName,
    };
    trackAnalytics({
      trackingTitle: 'Select_BackArrow_Button ',
      properties: [actionAnalytics] as IKeyValue[],
    });
  };

  return (
    <HeaderContainer
      data-name="HeaderContainer"
      padding={padding}
      paddingTop={paddingTop}
      marginLeft={marginLeft}
    >
      <BackButtonContainer
        isSearchResultsPage={isSearchResultsPage}
      >
        <IconButton
          aria-label="back"
          onClick={handleArrowClick}
        >
          <ArrowBackIcon
            style={{
              color: solidColors.upBlue.color,
            }}
            fontSize="large"
          />
        </IconButton>
      </BackButtonContainer>
      <Title align={align}>
        <Typography
          variant={typography[titleTypo]}
          color={solidColors.midnight}
          truncate={1}
        >
          {title}
        </Typography>
        {subtitle && (
          <Typography
            variant={typography.heading03Large}
            truncate={2}
            color={solidColors.midnight}
          >
            {subtitle}
          </Typography>
        )}
      </Title>
    </HeaderContainer>
  );
};

export default HeadlineWithBackArrow;
