import { withStyles } from '@material-ui/core';
import { TextField } from '../library';
import { ITextField } from './Input';
import { solidColors } from '@constants/styles/colors.constants';

const TextFieldStyled = withStyles({
  root: {
    '&  .MuiSelect-filled .MuiFormLabel-root': {
      color: `${solidColors.mediumGray.color}`,
    },
    '&  .MuiSelect-filled .MuiInput-underline:before': {
      borderBottomColor: `${solidColors.upBlue.color}`,
    },
    '&  .MuiSelect-filled .MuiInput-underline:hover:before': {
      borderBottomColor: `${solidColors.upBlue.color}`,
    },
    '&  .MuiSelect-filled .MuiInput-underline:after': {
      borderBottomColor: `${solidColors.upBlue.color}`,
    },
  },
})(TextField);

interface ISelect extends ITextField {
  defaultValue?: string;
  children?: JSX.Element | JSX.Element[];
  variant?: 'filled' | 'standard' | 'outlined';
  isRequired?: boolean;
}

const Select = ({
  label,
  onChange,
  type,
  value,
  error,
  helperText,
  children,
  isRequired,
  variant = 'filled',
}: ISelect) => {
  return (
    <TextFieldStyled
      select
      required={isRequired}
      label={label}
      type={type}
      error={error}
      value={value}
      helperText={helperText}
      onChange={onChange}
      variant={variant}
    >
      {children}
    </TextFieldStyled>
  );
};

export default Select;
