import AutoCompleteFlight from '@atoms/AutoCompleteFlight';
import {
  AutoContainer,
  IconContainer,
  RowWrapper,
} from '../../FormFlightLeg/SearchFlight.styles';
import { ISubpageRes } from '../../FormFieldAirportSearchDashboard/SearchFlight.interfaces';
import { useContext, useRef, useState } from 'react';
import { getSubpage } from '@services/airportSubpageSearchService/airportSubpageSearchService';
import { initialSubpageData } from '../initialState';
import { FlightSearchContext } from '../FlightSearchContext/FlightSearchContext';
import { useDispatch } from 'react-redux';

import { saveOrDeleteAirport } from './legHelpers';
import { IAirportItem } from '@components/molecules/FormFlightLeg/SearchFlight.interfaces';
import { useFlightSearch } from '../FlightSearchContext/FlightSearchHooks';
import { Icon } from '@wheelsup/wu-react-components';
import { useViewport } from '@hooks';
import { theme } from '@constants/styles/theme.constants';
import useSnackbar from './useSnackbar';
import { flightTypes } from '../FlightSearchContext/FlightSearchContext.types';
import { useFeatureFlag } from '@services/featureFlagService/featureFlagService.services';
import { FeatureFlags } from '@services/featureFlagService/constants';

interface ILegContainer {
  legNumber: number;
}

const FlightSection = ({ legNumber }: ILegContainer) => {
  const dispatch = useDispatch();

  const {
    legs,
    updateLeg,
    flightType,
    updateRoundTripFlights,
    updatePricingRequest,
    isRoundTrip,
    contextDispatch,
    pricingRequest,
  } = useFlightSearch(useContext(FlightSearchContext));

  const leg = legs[legNumber];

  const [
    subpageData,
    setSubpageData,
  ] = useState<ISubpageRes>(initialSubpageData);

  const [error, setError] = useState(false);

  const useTod = useFeatureFlag(FeatureFlags.USE_TOD);

  const getAirportSubpage = async (titleText: string) => {
    const data = await getSubpage(titleText, false);
    setSubpageData(data);
  };

  const switchAirports = () => {
    if (isRoundTrip) {
      // Swap airports for round trip (two legs)
      const [firstLeg, secondLeg] = legs;
      const updatedLegs = [
        {
          ...firstLeg,
          departureAirport: secondLeg.departureAirport,
          arrivalAirport: secondLeg.arrivalAirport,
        },
        {
          ...secondLeg,
          departureAirport: firstLeg.departureAirport,
          arrivalAirport: firstLeg.arrivalAirport,
        },
      ];

      contextDispatch({
        payload: { legs: updatedLegs },
        type: 'replaceLegsRoundTrip',
      });
    } else {
      // Swap airports for a single leg
      const updatedLeg = {
        ...leg,
        departureAirport: leg.arrivalAirport,
        arrivalAirport: leg.departureAirport,
      };

      updateLeg(updatedLeg, legNumber);
      updatePricingRequest({
        ...pricingRequest,
        arrivalAirport: parseInt(
          leg.departureAirport?.id ?? '',
        ),
        departureAirport: parseInt(
          leg.arrivalAirport?.id ?? '',
        ),
      });
    }
  };

  const updateOneWayLeg = (
    departureAirport: IAirportItem | null,
    arrivalAirport: IAirportItem | null,
    pickedSameAirports: boolean | undefined,
    shouldOpenNext = false,
  ) => {
    updateLeg(
      {
        ...leg,
        departureAirport,
        arrivalAirport,
        ...(useTod
          ? {
              passengersOpen: shouldOpenNext,
            }
          : {
              dateOpen: shouldOpenNext,
            }),
        airportError: pickedSameAirports
          ? 'Please pick two different airports'
          : undefined,
      },
      legNumber,
    );
  };
  const handleUpdateDeparture = (
    airport: IAirportItem | null,
  ) => {
    const pickedSameAirports =
      leg.arrivalAirport?.id === airport?.id;

    if (isRoundTrip) {
      // update the first legs departure and second legs arrival
      updateRoundTripFlights(
        airport,
        leg.arrivalAirport,
        true,
        pickedSameAirports,
      );
    } else {
      updateOneWayLeg(
        airport,
        leg.arrivalAirport,
        pickedSameAirports,
      );
    }
    // if we are in multi city, we dont care about pricing request
    if (legNumber === 0) {
      updatePricingRequest({
        ...pricingRequest,
        departureAirport: parseInt(airport?.id as string),
      });
    }
  };

  const handleUpdateArrival = (
    airport: IAirportItem | null,
  ) => {
    const pickedSameAirports =
      leg.departureAirport?.id === airport?.id;

    if (flightType === flightTypes.roundTrip) {
      updateRoundTripFlights(
        leg.departureAirport,
        airport,
        false,
        pickedSameAirports,
      );
    } else {
      updateOneWayLeg(
        leg.departureAirport,
        airport,
        pickedSameAirports,
        true,
      );
    }
    // if we are in multi city, we dont care about pricing request
    if (legNumber === 0) {
      updatePricingRequest({
        ...pricingRequest,
        arrivalAirport: parseInt(airport?.id as string),
      });
    }
  };

  const { width } = useViewport();
  const isDesktop = width > theme.breakpoints.values.lg;

  const sharedAirportProps = {
    setSubpageData,
    showError: () => setError(!error),
    subpageData,
    snackbarHandler: useSnackbar(dispatch),
  };

  return (
    <RowWrapper>
      <AutoContainer
        id={`departureInputContainer${legNumber}`}
      >
        <AutoCompleteFlight
          {...subpageData}
          titleField={'From'}
          transparent
          saveAction={(airportId) =>
            saveOrDeleteAirport({
              airportId,
              ...sharedAirportProps,
              isDelete: false,
            })
          }
          deleteAction={(airportId) =>
            saveOrDeleteAirport({
              airportId,
              ...sharedAirportProps,
              isDelete: true,
            })
          }
          getInitialPegasusCall={getAirportSubpage}
          titleText={'departure'}
          legNumber={legNumber}
          value={leg.departureAirport}
          onChange={handleUpdateDeparture}
          type="departureAirport"
          flightTripType={flightType}
        />
      </AutoContainer>
      <div
        data-name="AutocompleteCenterIcon"
        id={`centerIconContainer${legNumber}`}
      >
        <IconContainer onClick={switchAirports}>
          <Icon
            size="s"
            color="primary"
            name={
              isDesktop
                ? 'double-arrow-horizontal'
                : 'double-arrow-vertical'
            }
          />
        </IconContainer>
      </div>
      <AutoContainer
        id={`arrivalInputContainer${legNumber}`}
      >
        <AutoCompleteFlight
          {...subpageData}
          titleField={'To'}
          transparent
          saveAction={(airportId) =>
            saveOrDeleteAirport({
              airportId,
              ...sharedAirportProps,
              isDelete: false,
            })
          }
          deleteAction={(airportId) =>
            saveOrDeleteAirport({
              airportId,
              ...sharedAirportProps,
              isDelete: true,
            })
          }
          getInitialPegasusCall={getAirportSubpage}
          titleText={'arrival'}
          legNumber={legNumber}
          value={leg.arrivalAirport}
          onChange={handleUpdateArrival}
          error={leg.airportError}
          type="arrivalAirport"
          flightTripType={flightType}
        />
      </AutoContainer>
    </RowWrapper>
  );
};

export default FlightSection;
