const handleGeoLocationError = (
  error: GeolocationPositionError,
) => {
  switch (error.code) {
    case 1:
      // PERMISSION_DENIED	The acquisition of the geolocation information failed because the page didn't have the permission to do it.
      alert(
        "We couldn't access your location. Please allow location permissions to get directions.",
      );
      break;
    case 2:
      // POSITION_UNAVAILABLE	The acquisition of the geolocation failed because one or several internal sources of position returned an internal error.
      alert(
        "We're having trouble finding your location. Please check your device's location settings and try again.",
      );
      break;
    case 3:
      alert(
        'Fetching your location is taking longer than expected. Please try again in a moment.',
      );
      // TIMEOUT	Geolocation information was not obtained in the allowed time.
      break;
    default:
      alert(
        'Oops! Something went wrong while fetching your location. Please try again later.',
      );
      // Uncaught error
      break;
  }
};

export default handleGeoLocationError;
