import {
  getFromLocalStorage,
  removeFromLocalStorage,
} from '@components/utils/storage';
import { paths } from '@routing/routerPaths';
import { fetchProcessEnvVariable } from '@services/envService/envService';

export const determinePathToSendUser = () => {
  const userRollout =
    localStorage.getItem('userRollout') === null
      ? 'enabled'
      : localStorage.getItem('userRollout');

  let path =
    fetchProcessEnvVariable('ENV') === 'production' ||
    userRollout === 'enabled'
      ? paths.HOME_V1
      : paths.HOME;

  if (
    fetchProcessEnvVariable('ENV') === 'production' ||
    userRollout === 'enabled'
  ) {
    const route = (
      window.location.pathname + window.location.search
    ).split('?redirect_route=')[1];

    path = route ? decodeURIComponent(route) : paths.HOME;
  }
  if (getFromLocalStorage('amexRegistration', false)) {
    removeFromLocalStorage('amexRegistration');
    path = '/src/client/partner-program/registration';
  }

  return path;
};

/*
LP asked to remove this for now
else if (
            lastDigit <= 5 &&
            DateTime.now() > DateTime.fromSQL('2022-04-15')
        ) {
            path = paths.HOME;
        }
*/
