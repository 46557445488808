import { useAppDispatch, useAppSelector } from '@app/hooks';
import {
  setPanel,
  setSidebarIndex,
} from '@features/Hub/HubSlice';

const useCTA = () => {
  const dispatch = useAppDispatch();
  const { sidebarIndex } = useAppSelector(
    (state) => state.hub,
  );
  const updateCTA = (id: any, payload: any) => {
    if (id !== null) {
      dispatch(setPanel(payload));
      dispatch(setSidebarIndex(id));
    }
  };
  return [sidebarIndex, updateCTA] as const;
};

export default useCTA;
