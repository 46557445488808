import { ListPaperContainer } from './PassengerListCard.styles';
import PassengerListGroup from '@components/molecules/PassengerListGroup/PassengerListGroup';
import {
  IBasePassengerDisplay,
  IPassenger,
} from '@components/types';
import { IButton } from '@atoms/Button/Button.interfaces';
import { paths } from '@routing/routerPaths';
import { Elevation } from '@wheelsup/wu-react-components';

interface ITabData {
  tabTitle: string;
  tabLeadIcon: string;
  addButton: IButton;
}

interface IPassengerListCard {
  allTab: ITabData;
  selectedTabList: IBasePassengerDisplay[];
  handlePassengerSelection: (
    passenger: IBasePassengerDisplay,
    newPassengers?: IPassenger[],
  ) => void;
  leg?: number;
  LeadPaxButton?: React.ReactNode;
}

export default function PassengerListCard({
  allTab,
  selectedTabList,
  handlePassengerSelection,
  leg,
  LeadPaxButton,
}: IPassengerListCard) {
  return (
    <Elevation variant="raisedLevel2" padding={0}>
      <ListPaperContainer>
        <PassengerListGroup
          passengerList={selectedTabList}
          leadIcon={{
            url: allTab.tabLeadIcon,
          }}
          addButton={allTab.addButton}
          handlePassengerSelection={
            handlePassengerSelection
          }
          leg={leg}
          addPassengerUrl={paths.ADD_PASSENGER}
          LeadPaxButton={LeadPaxButton}
        />
      </ListPaperContainer>
    </Elevation>
  );
}
