import styled from 'styled-components';
import { Icons } from '@library';
import { IForward } from './ArrowIconButton.interfaces';

export const { ArrowForwardIos } = Icons;

export const ArrowBackIos = styled(ArrowForwardIos)`
  transform: rotate(180deg);
  vertical-align: middle;
`;

export const IconButtonWrapper = styled.div<IForward>`
  z-index: 1;

  ${({ forward }) =>
    forward
      ? `
        margin-right: -34px;
        `
      : `
        margin-left: -34px;
        `}

  ${({ arrowsInside }) =>
    arrowsInside &&
    `
        margin-left: 12px;
        margin-right: 12px;
    `}
`;
