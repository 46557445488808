import { saveDataInSessionStorage } from '@components/utils/storage';
import {
  addClientId,
  updateLeg,
} from '@features/Fly/searchFlightSlice';
import { Dispatch } from '@reduxjs/toolkit';
import { validateFlight } from '@services/airportSubpageSearchService/airportSubpageSearchService';
import { NavigateFunction } from 'react-router';
import { FlightSearchObjType } from './FlightSearchContext/FlightSearchContext.types';
import { DateTime } from 'luxon';
import { Action } from '@components/molecules/SubPageFlightSearchV2/FlightSearchContext/FlightSearchContext.types';

export const validateFlights = async (
  legs: FlightSearchObjType[],
  flightType: string,
  dispatch: Dispatch<any>,
  contextDispatch: React.Dispatch<Action>,
  navigate: NavigateFunction,
) => {
  try {
    // need this to be able to send data to backend :(
    const fixedLegs = legs.map((leg) => ({
      ...leg,
      departTime: DateTime.fromJSDate(
        leg.departTime,
      ).toFormat("yyyy-MM-dd'T'HH:mm:ss"),
      date: DateTime.fromJSDate(leg.date as Date).toFormat(
        "yyyy-MM-dd'T'HH:mm:ss",
      ),
      // needed in redux
      flightType: flightType,
    }));
    const response = await validateFlight({
      legs:
        flightType === 'One-way'
          ? fixedLegs.slice(0, 1)
          : fixedLegs,
    });
    if (response?.data['status'] === 'SUCCESS') {
      // save data to session storage
      dispatch(addClientId(response?.data['clientId']));
      saveDataInSessionStorage('searchCriteria', {
        ...response?.data,
        legs: fixedLegs,
        flightType
      });
      for (let i = 0; i < legs.length; i++) {
        if (i === 0) {
          // If success, clear the flight errors
          contextDispatch({
            type: 'updateFlightErrors',
            payload: {
              flightErrors: [],
            },
          });
        }
        // If success, clear the leg errors
        contextDispatch({
          type: 'updateLegErrors',
          payload: {
            legErrors: [],
            legNumber: i
          },
        });
      }
      // redirect to flight results page
      navigate('/fly/results');
    } else {
      const legs = response?.data?.legs;
      for (let i = 0; i < legs.length; i++) {
        if (i === 0) {
          // Update the flight errors just once
          contextDispatch({
            type: 'updateFlightErrors',
            payload: {
              flightErrors: response?.data?.errors || [],
            },
          });
        }
        // Update the leg errors for each leg
        contextDispatch({
          type: 'updateLegErrors',
          payload: {
            legErrors: legs[i]?.errors || [],
            legNumber: i
          },
        });
      }

      dispatch(
        updateLeg(
          fixedLegs.map((leg, index) => ({
            ...leg,
            flightType: flightType,
            errors: legs[index]?.errors || [],
          })),
        ),
      );
    }
  } catch (err) {
    console.log(err, 'err');
  }
};
