import InputBase from '@atoms/InputBase';
import React, { useEffect } from 'react';
import { IFormFieldTitleFreeType } from './FormFieldTitleFreeType.interfaces';
import { IValidationInput } from '@components/molecules/Input/Input.interfaces';
import { InputBaseType } from '@atoms/InputBase/InputBase.interfaces';
import {
  emailRegex,
  regexDict,
} from '@constants/validations/regexLibrary';

const FormFieldTitleFreeType = ({
  error,
  maxCharactersAllowed,
  minCharactersAllowed,
  payloadKey,
  value,
  title,
  fieldCopy,
  type,
  errorMessage,
  datatestid,
  placeholder,
  styleType,
  leadingHelperCopy,
  trailingHelperCopy,
  leadingHelperAction,
  trailingHelperAction,
  trailingCTA,
  leadingCTA,
  textArea,
  isRequired,
  validations,
  readOnly,
  disabled,
  ...rest
}: IFormFieldTitleFreeType) => {
  const [input, setInput] = React.useState(
    (fieldCopy as string) ?? (value as string) ?? '',
  );
  const [validation, setValidation] = React.useState(false);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setInput(event.target.value);
  };

  const formatPhoneNumber = (phoneNumberString: string) => {
    const cleaned = ('' + phoneNumberString).replace(
      /\D/g,
      '',
    );
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return (
        '(' + match[1] + ') ' + match[2] + '-' + match[3]
      );
    }
    return '';
  };

  useEffect(() => {
    if (!validations) {
      return setValidation(false);
    }
    const validationResults = validations?.map(
      (Validation: IValidationInput) => {
        let inputTransformed = input;
        if (type === InputBaseType.Phone) {
          inputTransformed = formatPhoneNumber(input);
        }

        if (type === InputBaseType.Email) {
          return emailRegex.test(inputTransformed);
        }
        const regexTest = regexDict[Validation.regex];
        const valid =
          regexTest && regexTest.test(inputTransformed);
        return valid;
      },
    );
    setValidation(
      !validationResults?.every(
        (Validation) => !!Validation,
      ) ?? false,
    );
  }, [input]);

  return (
    <>
      <InputBase
        data-name="FormFieldTitleFreeType"
        {...rest}
        maxCharactersAllowed={maxCharactersAllowed}
        minCharactersAllowed={minCharactersAllowed}
        payloadKey={payloadKey}
        title={title}
        type={type}
        errorMessage={errorMessage}
        fieldCopy={fieldCopy}
        datatestid={datatestid}
        placeholder={placeholder}
        styleType={styleType}
        leadingHelperCopy={leadingHelperCopy}
        trailingHelperCopy={trailingHelperCopy}
        leadingHelperAction={leadingHelperAction}
        trailingHelperAction={trailingHelperAction}
        trailingCTA={trailingCTA}
        leadingCTA={leadingCTA}
        textArea={textArea}
        isRequired={isRequired}
        validations={validations}
        value={input}
        onChange={handleChange}
        readOnly={readOnly}
        error={!!error || (validation && !!input)}
        disabled={disabled}
      />
    </>
  );
};

export default FormFieldTitleFreeType;
