import React from 'react';
import { Container } from '@components/library';
import { ICarouselListLine } from './CarouselListLine.interfaces';
import { Button } from '@atoms';
import {
  ButtonContainerStyled,
  TitleStyledContainer,
  Table
} from './CarouselListLine.styles';
import { Elevation, Typography } from '@wheelsup/wu-react-components';

const CarouselListLine = ({
  children,
  button,
  titleTextGroup,
  isCard = false,
}: ICarouselListLine) => {
  const carouselContent = (
    <div data-name="CarouselListLine">
      <Container>
        {titleTextGroup && (
          <TitleStyledContainer>
            <Typography
              variant='heading03Responsive'
              color='primary'
            >
              {titleTextGroup.title}
            </Typography>
          </TitleStyledContainer>
        )}

        <Table>
          {children && Array.isArray(children)
            ? (children as Array<JSX.Element>).filter(
                (Child: JSX.Element, index: number) => {
                  if (Child)
                    return React.cloneElement(Child, {
                      id: Child.key,
                      index,
                    });
                },
              )
            : null}
        </Table>
        {button && (
          <ButtonContainerStyled>
            <Button {...button} />
          </ButtonContainerStyled>
        )}
      </Container>
    </div>
  );

  if (isCard)
    return (
      <Elevation variant="raisedLevel2">
        {carouselContent}
      </Elevation>
    );
  else return carouselContent;
};

export default CarouselListLine;
