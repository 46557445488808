import { useAppDispatch, useAppSelector } from '@app/hooks';
import { Button } from '@atoms';
import { ActionTypes } from '@atoms/Action/Action.interfaces';
import { IButton } from '@atoms/Button/Button.interfaces';
import {
  saveCreditCardRequest,
  setPaymentLocation,
  setPaymentPageBack,
  setPaymentPageNext,
  setReloadPayment,
} from '@features/Fly/Payment/PaymentStatusSlice';
import { getAccessToken } from '@services/tokenService/token';
import { ITextGroupDoubleIconCTAs } from './TextGroupDoubleIconCTAs.interfaces';
import {
  ButtonContainer,
  ButtonItemContainer,
} from './TextGroupDoubleIconCTAs.styles';
import { trackAnalytics } from '@services/mixpanelService/mixpanel.service';
import { IKeyValue, IPayment } from '@components/types';

export default function TextGroupDoubleIconCTAs({
  title,
  buttons,
}: ITextGroupDoubleIconCTAs) {
  const dispatch = useAppDispatch();

  const {
    paymentLocation,
    locationDisableNext,
    newCreditCard,
  } = useAppSelector((state) => state.paymentStatus);

  const handleButton = (type: ActionTypes) => {
    switch (type) {
      case 'page_next':
        switch (paymentLocation) {
          case 'selectPayment':
            dispatch(setReloadPayment(true));
            dispatch(setPaymentLocation('payment'));
            break;
          case 'addCreditCardPopUp':
            dispatch(
              saveCreditCardRequest({
                token: getAccessToken(),
                resPayload: newCreditCard,
              }),
            );
            break;
          case 'checkWirePopUp': {
            const actionAnalytics: unknown = {
              key: 'Page Name',
              value: 'Check or Wire Payment',
            };
            trackAnalytics({
              trackingTitle:
                'Select_ApplyCheckWirePaymentMethod_Button',
              properties: [actionAnalytics] as IKeyValue[],
            });
            dispatch(setPaymentLocation('selectPayment'));
            break;
          }
          default:
            dispatch(setPaymentPageNext());
            break;
        }
        break;
      case 'page_back':
        dispatch(setPaymentPageBack());
        break;
      default:
        return null;
    }
  };

  const isDisable = (type: ActionTypes) => {
    switch (type) {
      case 'page_next':
        switch (paymentLocation) {
          case 'addCreditCardPopUp':
            return locationDisableNext;
          case 'selectPayment':
            return locationDisableNext;
          default:
            return false;
        }
      default:
        return false;
    }
  };

  return (
    <ButtonContainer>
      {buttons.map((itemButton, index) => (
        <ButtonItemContainer key={index}>
          <Button
            title={itemButton.title}
            action={{
              actionMethod: () => {
                handleButton(
                  itemButton.action.type || 'page_back',
                );
              },
            }}
            styleType={
              index === 1 ? 'primary' : 'secondary'
            }
            disabled={isDisable(
              itemButton.action.type || 'page_back',
            )}
          />
        </ButtonItemContainer>
      ))}
    </ButtonContainer>
  );
}
