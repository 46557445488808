import {
  IFlightLeg,
  IFlightLegError,
} from '@components/types';
import { ImageImgix } from '@atoms';
import { IFlightFormData } from '@components/molecules/CardRoundTripFlightLeg/CardRoundTripFlightLeg.interface';
import { Dispatch } from '@reduxjs/toolkit';
import {
  getFromLocalStorage,
  saveDataInSessionStorage,
} from '@components/utils/storage';

export const getErrorIndex = (
  formfield: string,
  error: IFlightLegError[],
) => {
  let index: number | undefined = undefined;

  error.map((error: any, idx: number) => {
    if (
      (error.linkedFormField as string[])?.includes(
        formfield,
      )
    ) {
      index = idx;
    }
  });

  return index;
};

export const customImgix = (
  srcUrl: string,
  filter?: string | undefined,
  alt?: string,
) => {
  return (
    <ImageImgix
      src={srcUrl}
      width={20}
      height={20}
      fit="crop"
      alt="Description"
      filter={filter}
    />
  );
};

export const getTitle = (
  flightTripType: string,
  oneWayTitle: string,
  roundTripTitle: string,
  multiLegTitle: string,
) => {
  let title = '';
  if (flightTripType == 'one-way') {
    title = oneWayTitle;
  } else if (flightTripType == 'round-trip') {
    title = roundTripTitle;
  } else if (flightTripType == 'multi-leg') {
    title = multiLegTitle;
  }
  return title;
};

export const renderMiddleIcon = (
  form: IFlightFormData | undefined,
) => {
  if (form?.flightLegViewData?.image) {
    return customImgix(
      form?.flightLegViewData?.image?.url,
      form?.flightLegViewData?.image?.tintColor,
    );
  }
};

export const openSubPages = (
  indexNum: number,
  name: string,
  legsData: IFlightLeg[],
  dispatch: Dispatch<any>,
  updateLeg: any,
) => {
  if (indexNum >= 0) {
    legsData.map((leg, idx) => {
      const currLeg = {
        ...leg,
      };

      if (indexNum == idx) {
        currLeg.departureOpen = false;
        currLeg.arrivalOpen = false;

        if (name) {
          if (name == 'departure') {
            currLeg.departureOpen = !currLeg.departureOpen;
          } else if (name == 'arrival') {
            currLeg.arrivalOpen = !currLeg.arrivalOpen;
          }
        }

        const updatedLegs = legsData.filter((leg) => leg);

        updatedLegs.splice(idx, 1, currLeg);

        dispatch(updateLeg(updatedLegs));
      }
    });
  }
};

export const initialSubpageData = {
  homeImage: {
    url: '',
  },
  deleteImage: {
    url: '',
  },
  pinImage: {
    url: '',
  },
  title: '',
  savedAirportsInstructions: '',
  savedAirportsHeader: '',
  recentlySearchedAirportsHeader: '',
  highDensityCopy: '',
  conditionalCopy: '',
  savedAirports: [],
  recentlySearchedAirports: [],
  nearbyAirports: [],
  emptyAirportListsCopy: '',
};

export const errorData = {
  saveError: {
    buttons: [{}, {}],
    copy: 'You have reach max save',
    headline: 'Something went wrong',
    requiredUserInteraction: false,
  },
};

export function formatCompactNumber(number: number) {
  if (number < 1000) {
    return number;
  } else if (number >= 1000 && number < 10000) {
    return `${(number / 1000).toFixed(1)}k`;
  } else if (number >= 10000 && number < 1_000_000) {
    return (
      (number / 1000).toFixed(1).replace(/\.0$/, '') + 'k'
    );
  } else if (
    number >= 1_000_000 &&
    number < 1_000_000_000
  ) {
    return (
      (number / 1_000_000).toFixed().replace(/\.0$/, '') +
      'M'
    );
  } else if (
    number >= 1_000_000_000 &&
    number < 1_000_000_000_000
  ) {
    return (
      (number / 1_000_000_000)
        .toFixed()
        .replace(/\.0$/, '') + 'B'
    );
  } else if (
    number >= 1_000_000_000_000 &&
    number < 1_000_000_000_000_000
  ) {
    return (
      (number / 1_000_000_000_000)
        .toFixed()
        .replace(/\.0$/, '') + 'T'
    );
  }
}
