import React from 'react';
import { typography } from '@constants/styles/typography.constants';
import { solidColors } from '@constants/styles/colors.constants';
import { IGreetingMessage } from './TextGroupContextualGreetingMessage.interfaces';
import { StyledTextGroupWrapper } from './TextGroupContextualGreetingMessage.styles';
import getTimeOfDayVar from '@components/utils/getTimeOfDayVar';
import { Typography } from '@wheelsup/wu-react-components';

const TextGroupContextualGreetingMessage = ({
  morning,
  afternoon,
  evening,
  copy,
  button,
}: IGreetingMessage) => {
  const title = () => {
    const timeOfDay = getTimeOfDayVar();
    switch (timeOfDay) {
      case 'morning':
        return morning;
      case 'afternoon':
        return afternoon;
      case 'evening':
        return evening;
      default:
        return morning;
    }
  };

  return (
    <StyledTextGroupWrapper data-name="TextGroupContextualGreetingMessage">
      <Typography
        variant='heading01'
        color='interactivePrimary'
        data-testid="text-group-contextual-greeting-message"
        role='header'
      >
        {title()}
      </Typography>
      {/* We do not want to render empty headers. 2.4.6: Headings and Labels Accessibility Success Criterion */}
      {copy && (
        <Typography
          variant='heading01'
          color='interactivePrimary'
        >
          {copy}
        </Typography>
      )}
      {/* // */}

      {/* We might not need this CTA icon for web */}
      {/*<Action*/}
      {/*    type={button.action.type}*/}
      {/*    data={button.action.data}*/}
      {/*    actionAnalytics={*/}
      {/*        button.action.actionAnalytics*/}
      {/*    }*/}
      {/*>*/}
      {/*    <ImageStyled src={button.image.url} />*/}
      {/*</Action>*/}
    </StyledTextGroupWrapper>
  );
};

export default TextGroupContextualGreetingMessage;
