import InputBase from '../InputBase/index';
import { InputNumberProps } from './InputNumber.interfaces';

const InputNumber = ({
  title,
  min,
  max,
  payloadKey,
  inputProps,
  value,
  setValue,
  ...rest
}: InputNumberProps) => (
  <InputBase
    title={title}
    type={'number'}
    onChange={(e) => {
      let value = parseFloat(e.currentTarget.value);
      if (value > max) value = max;
      if (value < min) value = min;
      setValue(value);
    }}
    value={value}
    payloadKey={payloadKey}
    {...rest}
  />
);

export default InputNumber;
