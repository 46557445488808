import { useState } from 'react';
import {
  ArrowIconStyled,
  Button,
  ImageImgix,
} from '@atoms';
import { typography } from '@constants/styles/typography.constants';
import {
  Elevation,
  Typography,
} from '@wheelsup/wu-react-components';
import {
  ICardHeadline,
  IIconListItem,
} from './CardImageHeadlineIconsSubCopyListCTA.interfaces';
import {
  CardBody,
  CardContainer,
  CardOversizedWrapper,
  FirstSideCard,
  OversizedSideCard,
  HeaderContainer,
  ListContainer,
  ItemUl,
  ItemLi,
  ItemContainer,
  ButtonContainer,
} from './CardImageHeadlineIconsSubCopyListCTA.styles';
import { solidColors } from '@constants/styles/colors.constants';
import MemberTabPopup from '../MemberTabPopup/MemberTabPopup';

//imports for redux
import ProviderWrapper from '@components/utils/ProviderWrapper';
import { useAppSelector } from '@app/hooks';
import { useDispatch } from 'react-redux';
import {
  buttonPopupOpen,
  pagePopup,
  snackbarOpen,
} from '@features/Members/popupSlice';

const CardImageHeadlineIconsSubCopyListCTA = ({
  headline,
  subtitle,
  copy,
  image,
  button,
  items,
  id,
}: ICardHeadline) => {
  //redux
  const { isButtonPopupOpen } = useAppSelector(
    (state) => state.popupOpen,
  );

  const [activeCard, setActiveCard] = useState<any>('');
  const dispatch = useDispatch();

  const type: any = button?.action?.type;

  const handleBtnClick = () => {
    dispatch(buttonPopupOpen(true));
    dispatch(pagePopup(true));
    dispatch(snackbarOpen(false));

    setActiveCard(headline);
  };

  return (
    <Elevation variant="raisedLevel2" padding={0}>
      <CardOversizedWrapper data-name="CardImageHeadlineIconsSubCopyListCTA">
        {isButtonPopupOpen && headline === activeCard && (
          <MemberTabPopup />
        )}

        <FirstSideCard>
          <CardContainer>
            <HeaderContainer>
              <Typography variant="heading02">
                {headline}
              </Typography>

              <Typography variant="heading02">
                {subtitle}
              </Typography>
            </HeaderContainer>

            <CardBody
              variant={typography.body2}
              truncate={3}
            >
              {copy}
            </CardBody>

            <ListContainer>
              <ItemUl>
                {items.map((item: IIconListItem) => {
                  return (
                    <ItemLi key={item.title}>
                      <ItemContainer>
                        <div>
                          <ImageImgix
                            width={20}
                            height={20}
                            src={item.image.url}
                            filter={item.image.tintColor}
                            alt="Image"
                          />
                        </div>
                        <div>
                          <Typography variant="body02">
                            {item.title}
                          </Typography>
                        </div>
                      </ItemContainer>
                    </ItemLi>
                  );
                })}
              </ItemUl>
            </ListContainer>

            <ButtonContainer>
              <Button
                action={{
                  actionMethod:
                    type === 'display_membership_lock'
                      ? handleBtnClick
                      : button.action?.actionMethod,
                  ...button.action,
                }}
                styleType={button?.styleType}
                title={button?.title}
                nativeIcon={
                  <ArrowIconStyled
                    color={solidColors.upBlue.color}
                  />
                }
              />
            </ButtonContainer>
          </CardContainer>
        </FirstSideCard>
        <OversizedSideCard>
          <ImageImgix
            fit="crop"
            width={900}
            height={500}
            src={image.url}
            filter={image.tintColor}
            alt="Oversized Image"
          />
        </OversizedSideCard>
      </CardOversizedWrapper>
    </Elevation>
  );
};

const CardImageHeadlineIconsSubCopyListCtaWrapper = ({
  headline,
  subtitle,
  copy,
  image,
  button,
  items,
  id,
}: ICardHeadline) => {
  return (
    <ProviderWrapper>
      <CardImageHeadlineIconsSubCopyListCTA
        headline={headline}
        subtitle={subtitle}
        copy={copy}
        image={image}
        button={button}
        items={items}
        id={id}
      />
    </ProviderWrapper>
  );
};
export default CardImageHeadlineIconsSubCopyListCtaWrapper;
