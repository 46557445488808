import { solidColors } from '@constants/styles/colors.constants';
import styled from 'styled-components';

export const CardContainer = styled.div`
  margin-top: 2rem;
`;

export const TitleContainer = styled.div`
  padding-bottom: 2rem;
  border-bottom: 1px solid ${solidColors.mediumGray.color};
`;

export const FBOContainer = styled.div`
  margin-top: 1.5rem;
`;
