import { ImageImgix } from '@atoms';
import {
  AsideContainer,
  BtnContainer,
  LegendSection,
  NextButtonStyled,
  PriceAside,
  StyledList,
  StyledListItemContainer,
} from './CustomSubpage.styles';
import { Typography} from '@wheelsup/wu-react-components';
import { ILegend } from './CustomSubpage.interfaces';
import { useViewport } from '@hooks';
import { WidthOptions } from '@wheelsup/wu-react-components';
import { PressEvent } from '@react-types/shared';
import { flightTypes } from '../SubPageFlightSearchV2/FlightSearchContext/FlightSearchContext.types';

interface IButtonSection {
  isCalendar?: boolean;
  legends?: Array<ILegend>;
  selectedPrice?: string;
  shouldLoadPrice?: boolean;
  action: (e: PressEvent) => void;
  tripType: string | undefined;
  isButtonDisabled?: boolean;
  button?: {
    title: string;
  };
  alignRight?: boolean;
  topGap?: number;
}

const NEXT_BUTTON_TEXT = 'Next';

const ButtonSection = ({
  isCalendar,
  legends,
  selectedPrice,
  shouldLoadPrice,
  action,
  tripType,
  isButtonDisabled,
  alignRight = false,
  topGap
}: IButtonSection) => {
  const { width: viewportWidth } = useViewport();
  const isDesktop = viewportWidth > 1000;

  const shouldShowPrice = selectedPrice && shouldLoadPrice;

  const renderPriceAside = (
    tripType: string | undefined,
    selectedPrice: {} | null | undefined,
    shouldShowTaxes: boolean,
  ) => {
    if (tripType === flightTypes.multiCity) {
      return;
    }
    if (selectedPrice === 'Request Quote') {
      return (
        <PriceAside>
          <Typography
            variant='body02Bold'
            color='primary'
            align="left"
          >
            {`${tripType}`}
          </Typography>
          <Typography
            variant='numberMed'
            color='primary'
            align="center"
          >
            {'Custom Quote'}
          </Typography>
        </PriceAside>
      );
    } else {
      return (
        <PriceAside>
          <Typography
            variant='body02Bold'
            color='primary'
            align="left"
          >
            {`${tripType} from`}
          </Typography>
          <Typography
            variant='numberMed'
            color='primary'
            align="center"
          >
            {selectedPrice}
          </Typography>
          {shouldShowTaxes && (
            <Typography
              variant='caption'
              color='primary'
              align="left"
            >
              {'+ taxes and fees'}
            </Typography>
          )}
        </PriceAside>
      );
    }
  };

  const getAside = () => {
    // if its not calendar, just render the button.
    // if its calendar and we need price, show button and price
    // if its calendar and we dont need price, show different button.
    if (!isCalendar) {
      return (
        <NextButton
          onPress={action}
          isDisabled={isButtonDisabled}
          buttonText={NEXT_BUTTON_TEXT}
        />
      );
    } else if (shouldShowPrice) {
      return (
        <>
          {renderPriceAside(tripType, selectedPrice, true)}
          <NextButton
            onPress={action}
            isDisabled={isButtonDisabled}
            buttonText={NEXT_BUTTON_TEXT}
            isCalendar={isCalendar}
          />
        </>
      );
    } else {
      return (
        <>
          <div />
          <NextButton
            onPress={action}
            isDisabled={isButtonDisabled}
            buttonText={NEXT_BUTTON_TEXT}
            isCalendar={isCalendar}
          />
        </>
      );
    }
  };
  return (
    <BtnContainer
      data-testid="button-section"
      alignRight={alignRight}
      data-name="BtnContainer"
      isCalendar={isCalendar}
      topGap={topGap}
    >
      {isCalendar && legends && isDesktop && (
        <LegendSection>
          <Typography variant='body01Bold'>Legend</Typography>
          <StyledList>
            {legends.map((legend, index) => (
              <li key={index}>
                <StyledListItemContainer>
                  <ImageImgix
                    width={legend.icon.width}
                    src={legend.icon.url}
                    alt={legend.copy}
                  />
                  <Typography variant='body02'>{legend.copy}</Typography>
                </StyledListItemContainer>
              </li>
            ))}
          </StyledList>
        </LegendSection>
      )}
      <AsideContainer>{getAside()}</AsideContainer>
    </BtnContainer>
  );
};


const NextButton = ({
  onPress,
  isDisabled,
  buttonText,
  isCalendar
}: {
  onPress: ((e: PressEvent) => void) | undefined,
  isDisabled: boolean | undefined,
  buttonText: {} | null | undefined,
  isCalendar?: boolean
}) => (
  <NextButtonStyled
    variant="primary"
    onPress={onPress}
    isDisabled={isDisabled}
    wrapperWidth={WidthOptions.PCT100}
    isCalendar={isCalendar}
  >
    {buttonText}
  </NextButtonStyled>
);

export default ButtonSection;


