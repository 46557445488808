import { IButton } from '@atoms/Button/Button.interfaces';
import {
  ICalendarData,
  IDate,
  IDateDecoration,
} from '@components/types';

export interface ISubPageDatePicker {
  dateDecorationCopy: string;
  button: IButton;
  calendarData: ICalendarData;
}

export interface IDatePicker extends ISubPageDatePicker {
  disable: boolean;
  flightTripType: string;
  hasFormError?: boolean;
  date?: string | null;
  setDate: (state: any) => void;
  legNumber: number;
  dateOpenModal: boolean;
  legType: string;
  dateDecorations?: IDateDecoration[];
  dateErrorMessage: string;
  setDateErrorMessage: (state: any) => void;
  setTimeOpenModal: (state: any) => void;
  nextStep: () => void;
  closeAllSubPages: () => void;
}

export interface IDateState {
  selection: IDate;
}

export interface ICalendarPricing {
  legNumber: number;
}

export const legends = [
  {
    icon: {
      url:
        'https://9dd4157e73239d65fbe9-be721e199265b7ef611d0853a58c51d2.ssl.cf2.rackcdn.com/peak_travel_day_icon.svg',
      width: 8,
    },
    copy: 'Peak travel day',
  },
  {
    icon: {
      url:
        'https://9dd4157e73239d65fbe9-be721e199265b7ef611d0853a58c51d2.ssl.cf2.rackcdn.com/trip_already_booked_icon.svg',
      width: 10,
    },
    copy: 'You already have a booked trip',
  },
  {
    icon: {
      url:
        'https://9dd4157e73239d65fbe9-be721e199265b7ef611d0853a58c51d2.ssl.cf2.rackcdn.com/custom_quote_only_icon.svg',
      width: 11,
    },
    copy: 'Custom quotes only',
  },
  {
    icon: {
      url:
        'https://9dd4157e73239d65fbe9-be721e199265b7ef611d0853a58c51d2.ssl.cf2.rackcdn.com/airport_closed_icon.svg',
      width: 11,
    },
    copy: 'Airport is temporarily closed',
  },
];

export const tripTypeTitle: {
  readonly [key: string]: string;
} = {
  'one-way': 'One way',
  'multi-leg': 'Multi city',
  'round-trip': 'Round trip',
};
