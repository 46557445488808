import {
  IComponent,
  IPage,
} from '@services/dynamicRenderingService/dynamicRendering.interfaces';
import {
  IMockData,
  ISubpageRes,
} from './SearchFlight.interfaces';

export const searchFlightProps: IComponent = {
  type: 'FormFieldAirportSearchDashboard',
  data: {
    id: '0',
    button: {
      title: 'Go',
      styleType: 'secondary',
    },
    departureTitle: 'From',
    departureSubtitle: 'Enter a city or airport',
    'departureImage?': {},
    departureButton: {},
    destinationTitle: 'To',
    destinationSubtitle: 'Enter a city or airport',
    'destinationImage?': {},
    destinationButton: {},
  },
};

export const rendererSearchFlightResponse: IPage = {
  metadata: {
    pageTitle: 'The Hub',
    backgroundColor: 'backgroundUpWhite',
  },
  elements: [searchFlightProps],
};

const savedAirports = [
  {
    id: '1',
    code: 'KTEB',
    name: 'Teterboro Airport',
    city: 'Teterboro',
    state: 'NJ',
    hours: 'Hours: 12:00am - 11:00pm',
    isHomeAirport: true,
    isHighDensity: false,
    latitude: 40.8583,
    longitude: 74.0615,
    isConditional: true,
  },
  {
    id: '2',
    code: 'KLAX',
    name: 'Los Angeles International Airport',
    city: 'Los Angeles',
    state: 'CA',
    hours: 'Hours: 12:00am - 11:00pm',
    isHomeAirport: false,
    isHighDensity: true,
    latitude: 40.8583,
    longitude: 74.0615,
    isConditional: false,
  },
  {
    id: '3',
    code: 'KLWT',
    name: 'Lewistown Municipal Airport',
    city: 'Lewistown',
    state: 'MT',
    hours: 'Hours: 12:00am - 11:00pm',
    isHomeAirport: false,
    isHighDensity: true,
    latitude: 40.8583,
    longitude: 74.0615,
    isConditional: true,
  },
  {
    id: '4',
    code: 'KLYH',
    name: 'Lynchburg Regional Preston Glenn Field',
    city: 'Lynchburg',
    state: 'VA',
    hours: 'Hours: 12:00am - 11:00pm',
    isHomeAirport: false,
    isHighDensity: false,
    latitude: 40.8583,
    longitude: 74.0615,
    isConditional: false,
  },
];

const recentAirports = [
  {
    id: '5',
    code: 'KEWR',
    name: 'Newark Liberty International Airport',
    city: 'Newark',
    state: 'NJ',
    hours: 'Hours: 12:00am - 11:00pm',
    isHomeAirport: false,
    isHighDensity: false,
    latitude: 40.8583,
    longitude: 74.0615,
    isConditional: false,
  },
  {
    id: '6',
    code: 'KJFK',
    name: 'John F Kennedy International Airport',
    city: 'New York',
    state: 'NY',
    hours: 'Hours: 12:00am - 11:00pm',
    isHomeAirport: false,
    isHighDensity: false,
    latitude: 40.8583,
    longitude: 74.0615,
    isConditional: false,
  },
];

export const mockSubpageResponse: ISubpageRes = {
  homeImage: {
    url:
      'https://57d941c476953b8523ab-d6cba39c7afbd165d69e38502dbfd065.ssl.cf2.rackcdn.com/house.svg',
  },
  deleteImage: {
    url:
      'https://57d941c476953b8523ab-d6cba39c7afbd165d69e38502dbfd065.ssl.cf2.rackcdn.com/remove.svg',
  },
  pinImage: {
    url:
      'https://57d941c476953b8523ab-d6cba39c7afbd165d69e38502dbfd065.ssl.cf2.rackcdn.com/pin.svg',
  },
  title: 'Dynamic Title',
  savedAirportsInstructions:
    'Select the pin to the right of an airport to save and deselect the star to remove from saved airports. Save up to 3 airports.',
  savedAirportsHeader: 'Saved Airports',
  recentlySearchedAirportsHeader:
    'Recently Searched Airports',
  highDensityCopy: 'High Traffic Fees',
  conditionalCopy: 'Conditional Airport',
  savedAirports: savedAirports,
  recentlySearchedAirports: recentAirports,
  emptyAirportListsCopy: 'No Airports to display',
};

export const mockSubpageResponseNoSaved: IMockData = {
  homeImage: {
    url:
      'https://57d941c476953b8523ab-d6cba39c7afbd165d69e38502dbfd065.ssl.cf2.rackcdn.com/house.svg',
  },
  deleteImage: {
    url:
      'https://57d941c476953b8523ab-d6cba39c7afbd165d69e38502dbfd065.ssl.cf2.rackcdn.com/remove.svg',
  },
  pinImage: {
    url:
      'https://57d941c476953b8523ab-d6cba39c7afbd165d69e38502dbfd065.ssl.cf2.rackcdn.com/pin.svg',
  },
  title: 'Dynamic Title',
  savedAirportsInstructions:
    'Select the pin to the right of an airport to save and deselect the star to remove from saved airports. Save up to 3 airports.',
  savedAirportsHeader: 'Saved Airports',
  recentlySearchedAirportsHeader:
    'Recently Searched Airports',
  highDensityCopy: 'High Traffic Fees',
  conditionalCopy: 'Conditional Airport',
  recentlySearchedAirports: recentAirports,
};

export const mockSubpageResponseNoAirports: IMockData = {
  homeImage: {
    url:
      'https://57d941c476953b8523ab-d6cba39c7afbd165d69e38502dbfd065.ssl.cf2.rackcdn.com/house.svg',
  },
  deleteImage: {
    url:
      'https://57d941c476953b8523ab-d6cba39c7afbd165d69e38502dbfd065.ssl.cf2.rackcdn.com/remove.svg',
  },
  pinImage: {
    url:
      'https://57d941c476953b8523ab-d6cba39c7afbd165d69e38502dbfd065.ssl.cf2.rackcdn.com/pin.svg',
  },
  title: 'Dynamic Title',
  savedAirportsInstructions:
    'Select the pin to the right of an airport to save and deselect the star to remove from saved airports. Save up to 3 airports.',
  savedAirportsHeader: 'Saved Airports',
  recentlySearchedAirportsHeader:
    'Recently Searched Airports',
  highDensityCopy: 'High Traffic Fees',
  conditionalCopy: 'Conditional Airport',
};
