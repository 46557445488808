import axios, { AxiosError } from 'axios';
import {
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit';

import { IErrorPayload } from '@components/molecules/Error/ErrorSlice';
import { saveDataInLocalStorage } from '@components/utils/storage';

interface ICreditCardDetails {
  [key: string]: any;
}

interface IAmexEligibilityInitialState {
  eligibilityConfirmationData: ICreditCardDetails;
  errorPayload?: IErrorPayload;
}

export const AmexEligibilityInitialState: IAmexEligibilityInitialState = {
  eligibilityConfirmationData: {},
};

interface IProps {
  urlParams?: string;
  queryParams?: string;
  token?: string;
  type?: string;
  resPayload?: unknown;
}

export const saveCreditCardRequest = createAsyncThunk(
  'amexEligibility/saveCreditCard',
  async (props: IProps) => {
    const { resPayload = '' } = props;

    try {
      return (
        //TODO: refactor this after azure migration
        (
          await axios.post(
            'https://stagingservices.wheelsup.com/public/amex/public/member/checkEligibility',
            resPayload,
          )
        ).data
      );
    } catch (err) {
      return err;
    }
  },
);

const AmexEligibilitySlice = createSlice({
  name: 'amexEligibility',
  initialState: AmexEligibilityInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      saveCreditCardRequest.fulfilled,
      (state, action) => {
        const { payload } = action;
        const { data } = payload;
        if (payload?.response?.status === 400) {
          state.errorPayload =
            payload.response.data.data.data;
        } else {
          state.eligibilityConfirmationData = data;
          saveDataInLocalStorage('_data', data);

          window.location.assign(
            '/partner-program/amex-landing-page',
          );
        }
      },
    );
  },
});

export default AmexEligibilitySlice.reducer;
