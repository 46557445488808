import {
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

interface ISubPageFlightDealResultsState {
  sortValue: string;
  hasChanged: boolean;
}

export const initialState: ISubPageFlightDealResultsState = {
  sortValue: '',
  hasChanged: false,
};

export const subPageFlightDealResultsSlice = createSlice({
  name: 'SubPageFlightDealResultsSlice',
  initialState,
  reducers: {
    setSortValue: (
      state,
      action: PayloadAction<string>,
    ) => {
      state.sortValue = action.payload;
    },
    setHasChanged: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.hasChanged = action.payload;
    },
  },
  extraReducers: {},
});

export const {
  setSortValue,
  setHasChanged,
} = subPageFlightDealResultsSlice.actions;

export const SubPageFlightDealResultsReducer =
  subPageFlightDealResultsSlice.reducer;

export default SubPageFlightDealResultsReducer;
