export default function getTimeOfDayVar() {
  const hours = new Date().getHours();
  const minutes = new Date().getMinutes();
  if (hours >= 5 && hours <= 11 && minutes <= 59) {
    return 'morning';
  } else if (hours >= 12 && hours <= 16 && minutes <= 59) {
    return 'afternoon';
  } else {
    return 'evening';
  }
}
