import styled from 'styled-components';
import { solidColors } from '@constants/styles/colors.constants';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@library';

export const StyledAccordion = styled(Accordion)`
  box-shadow: 0 0px 0 0 ${solidColors.c9.color};

  &:before {
    content: none;
  }

  &.Mui-expanded {
    margin: 0px 0 16px;
  }
`;

export const StyledAccordionSummary = styled(
  AccordionSummary,
)`
  padding: 0;
  min-height: 0;

  .MuiAccordionSummary-content {
    margin: 0;
  }

  .MuiSvgIcon-root {
    fill: ${solidColors.upBlue.color};
  }

  &.Mui-expanded {
    min-height: 0;
  }
`;

export const StyledAccordionDetails = styled(
  AccordionDetails,
)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  position: relative;
  height: fit-content;
  padding: 0px 0px 0px 10px;
`;
