import {
  TextContainer,
  TypographyStyled,
  CopyStyled,
  ItemContainer,
  StyledTrailIcon,
  LabelContainer,
} from './TextGroupIconTextVertical.styles';
import { ITextGroupIcon } from './TextGroupIconTextVertical.interfaces';
import { typography } from '@constants/styles/typography.constants';
import { solidColors } from '@constants/styles/colors.constants';
import { Icons } from '@library';
import { Button, ImageImgix, Typography } from '@atoms';

const TextGroupIconTextVertical = ({
  headline,
  headlineVariant,
  icon,
  isSpecial,
  color,
  headerWeight,
  headerSize,
  copySize,
  copy,
  extraCopy,
  isSelected,
  isButton,
  image,
  buttonAction,
}: ITextGroupIcon) => {
  return (
    <ItemContainer
      data-id="TextGroupIconTextVertical"
      onClick={() => {
        buttonAction ? buttonAction() : null;
      }}
    >
      <TextContainer>
        {icon}
        <LabelContainer>
          <TypographyStyled
            variant={
              headlineVariant
                ? headlineVariant
                : typography.body1
            }
            color={color ?? solidColors.midnight}
            headerWeight={headerWeight}
            headerSize={headerSize}
          >
            {headline}
          </TypographyStyled>
          {extraCopy && (
            <Typography variant={typography.body1}>
              {extraCopy}
            </Typography>
          )}
          {isSpecial && (
            <ImageImgix
              src={image?.url as string}
              width={20}
              height={20}
              fit="crop"
              alt="Description"
              filter={image?.tintColor}
            />
          )}
          {copy ? (
            <CopyStyled
              variant={typography.body1}
              color={color ?? solidColors.darkGray}
              copySize={copySize ?? '15px'}
            >
              {copy}
            </CopyStyled>
          ) : (
            // This is to fill the space and keep the layout consistent
            <div style={{ marginTop: '20px' }} />
          )}
        </LabelContainer>
      </TextContainer>
      {isSelected ? (
        <StyledTrailIcon>
          <Icons.Check
            style={{
              color: solidColors.upBlue.color,
            }}
          />
        </StyledTrailIcon>
      ) : null}
      {isButton && (
        <Button
          styleType="card_secondary_web"
          action={{
            actionMethod: buttonAction,
          }}
          nativeIcon={
            <Icons.ChevronRight
              style={{
                color: solidColors.upBlue.color,
              }}
            />
          }
        ></Button>
      )}
    </ItemContainer>
  );
};

export default TextGroupIconTextVertical;
