//omar: demo purposes only, I imagine this is optional since we are styling atoms independently? system-wide style constants will be in theme.constants.ts

export const styleConstants = {
  card: {
    maxWidth: '345px',
    height: '100%',
  },
  cardMedia: {
    height: '140px',
  },
};
