import styled from 'styled-components';
import { solidColors } from '@constants/styles/colors.constants';
import { Typography } from '@atoms';
import { Elevation } from '@wheelsup/wu-react-components';

export const StyledElevation = styled(Elevation).attrs({
  variant: 'raisedLevel2',
  padding: 0,
})`
  max-width: 360px;
`;

export const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: 360px;
  background-color: #ffffff;
  position: relative;
  height: fit-content;
  padding: 32px;
  border-radius: 8px;
  overflow: hidden;

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 16px;
  }

  * {
    box-shadow: none;
  }

  .MuiAccordionDetails-root div {
    padding: 0;
  }

  .MuiAccordionSummary-root {
    min-height: auto;
    justify-content: space-between;
    padding: 32px 0 0;

    * {
      padding: 0;
    }

    .MuiAccordionSummary-content,
    .MuiIconButton-edgeEnd {
      margin: 0;
    }

    ${({ theme }) => theme.breakpoints.down('md')} {
      padding: 16px 0 0;
    }
  }
`;

export const StyledFlightType = styled.div`
  height: 48px;
  width: 48px;
  border: 1px solid ${solidColors.mediumGray.color};
  background-color: ${solidColors.upWhite.color};
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.03);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -24px;
  left: 16px;
`;

export const StyledTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  > p:first-child {
    margin-right: 20px;
  }
`;

export const StyledDepartureArrivalContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
  position: relative;

  > span:last-child {
    text-align: right;
  }

  .typoBackground {
    background-color: #fff;
    z-index: 1;
    &:first-child {
      padding-right: 10px;
    }
    &:last-child {
      padding-left: 10px;
    }
  }

  .inline {
    display: flex;
    align-items: baseline;
  }

  .estimated {
    margin-right: 4px;
  }

  .fuelStop {
    margin-left: 30px;
  }

  &.departureArrivalTime {
    margin-top: 5px;
  }
`;

export const StyledAirportName = styled(Typography)<{
  $isArrival?: boolean;
}>`
  max-width: 140px;
  min-height: 60px;

  > div {
    text-align: ${({ $isArrival }) =>
      $isArrival ? 'right' : 'left'};
  }
`;
