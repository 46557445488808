import { TextGroupSingleTextGroup } from '@molecules';
import { ILineItem } from './LineItem.interfaces';
import {
  LineItemContainer,
  LeftItemContainer,
  ChevronStyled,
} from './LineItem.styles';

export default function LineItem({
  leftItem,
  leftItemSubtitle,
  rightItem,
  rightChevron = false,
}: ILineItem) {
  return (
    <LineItemContainer>
      {leftItem && (
        <LeftItemContainer>
          <TextGroupSingleTextGroup
            title={leftItem.title}
            styleType={leftItem.styleType}
            alignmentState={leftItem.alignmentState}
          />
          {leftItemSubtitle && (
            <TextGroupSingleTextGroup
              title={leftItemSubtitle.title}
              styleType={leftItemSubtitle.styleType}
              alignmentState={
                leftItemSubtitle.alignmentState
              }
            />
          )}
        </LeftItemContainer>
      )}
      {rightItem && (
        <>
          <TextGroupSingleTextGroup
            title={rightItem.title}
            styleType={rightItem.styleType}
            alignmentState={rightItem.alignmentState}
          />
          {rightChevron && <ChevronStyled />}
        </>
      )}
    </LineItemContainer>
  );
}
