import { DialogActions } from '../library';
import styled from 'styled-components';

const DialogActionsAtom: any = styled(DialogActions)`
  padding: 0;
  height: 4rem;
  border-top: solid 1px #d0d0d0;
  background: #f8f9fd;
  justify-content: center;
`;

export default DialogActionsAtom;
