import styled from 'styled-components';
import { solidColors } from '@constants/styles/colors.constants';
import { Typography } from '@atoms';
import { Elevation } from '@wheelsup/wu-react-components';

export const IconText = styled(Typography)<{
  $paddingTop?: boolean;
}>`
  padding-top: ${({ $paddingTop }) =>
    $paddingTop ? '4px' : '0'};
  margin-left: 5px;
`;

export const StyledContainer = styled.div`
  display: flex;
  width: 100%;
  padding-left: 40px;

  @media (max-width: 600px) {
    padding-left: 20px;
    justify-content: center;
  }
`;

export const FlightStatus = styled.div<{
  $backgroundColor?: string;
}>`
  width: fit-content;
  height: 22px;
  padding: 0 1.25rem 0 1rem;
  background-color: ${({ $backgroundColor }) =>
    $backgroundColor
      ? $backgroundColor
      : solidColors.midnight.color};
  border-radius: 0 0 75px 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SmallDivider = styled.div`
  border-left-style: solid;
  border-left-color: ${solidColors.mediumGray.color};
  border-left-width: 1px;
  margin: 0 10px;
  height: 20px;
  width: 0.5px;
`;

export const LargeDivider = styled.div<{
  $marginLeft: string;
  $marginRight: string;
}>`
  border-left-style: solid;
  border-left-color: ${solidColors.mediumGray.color};
  border-left-width: 1px;
  margin: ${({ $marginLeft, $marginRight }) =>
    `0 ${$marginRight} 0 ${$marginLeft}`};
  height: 113px;
  width: 0.5px;
`;

export const TopSectionContainer = styled.div`
  width: 100%;
  display: flex;
  margin: 15px 0 0 40px;
  align-items: center;

  @media (max-width: 960px) {
    margin: 15px 0 0 20px;
  }

  @media (max-width: 600px) {
    margin: 15px 0 0 20px;
  }
`;

export const StyledElevation = styled(Elevation).attrs({
  variant: 'raisedLevel2',
  padding: 0,
})`
  max-width: fit-content;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    max-width: unset;
  }
`;

export const StyledFlightCardMobile = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: 0px 16px;
  width: 100%;
  max-width: 1088px;
`;

export const StyledFlightCardDesktop = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  min-height: 208px;
  width: 100%;
  max-width: 1088px;
  border-radius: 8px;
  padding: 0 36px 0 0;
  margin: 0;
  height: fit-content;
  overflow: hidden;

  @media (max-width: 600px) {
    padding-right: 20px;
    padding-bottom: 0;
  }
`;

export const AircraftContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-left: 1px solid ${solidColors.mediumGray.color};
  margin: 0 20px 0 12px;
  padding-left: 12px;
  padding-right: 12px;
`;

export const AircraftTextContainer = styled.div`
  padding-top: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: fit-content;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: flex-end;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  height: 100%;

  @media (max-width: 600px) {
    align-items: center;
  }
`;

export const ButtonContainerMobile = styled.div`
  display: flex;
  margin: 10px 0px;
  justify-content: center;
`;
