import { ISubPageCheckWireTransfer } from './SubPageCheckWireTransfer.interfaces';
import {
  StyledCard,
  StyledTitleContainer,
  StyledTitle,
  StyledAddEditButton,
  StyledSubPageCheckWireTransfer,
} from './SubPageCheckWireTransfer.styles';
import { typography } from '@constants/styles/typography.constants';
import { solidColors } from '@constants/styles/colors.constants';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import {
  setCheckWireFlag,
  setPaymentLocation,
  setSelectedPaymentMethods,
} from '@features/Fly/Payment/PaymentStatusSlice';
import {
  CustomRadio,
  DetailListItemList,
} from '@molecules';
import { useEffect, useState } from 'react';
import { trackAnalytics } from '@services/mixpanelService/mixpanel.service';
import { IKeyValue, IPayment } from '@components/types';
import { FeatureFlags } from '@services/featureFlagService/constants';
import { useFeatureFlag } from '@services/featureFlagService/featureFlagService.services';

const SubPageCheckWireTransfer = ({
  checkWireTitle,
  checkWireSubtitle,
  checkRadioButton,
  wireRadioButton,
  backupCardTitle,
  creditCards,
  editPaymentButton,
  checkPaymentInfoTitle,
  checkPaymentInfoSubtitle,
  checkPaymentInfoItems,
  wirePaymentInfoTitle,
  wirePaymentInfoSubtitle,
  wirePaymentInfoItems,
}: ISubPageCheckWireTransfer) => {
  const dispatch = useAppDispatch();

  const useNativeManagePaymentMethods = useFeatureFlag(
    FeatureFlags.USE_NATIVE_MANAGE_PAYMENT_METHODS,
  );

  const { totalCostValue } = useAppSelector(
    (state) => state.paymentStatus,
  );

  const [
    selectedPaymentType,
    setSelectedPaymentType,
  ] = useState('');
  const [
    selectedCreditCard,
    setSelectedCreditCard,
  ] = useState<IPayment>();

  useEffect(() => {
    const preselectedCCList = creditCards.filter(
      (creditCard) => creditCard.isPreselected === true,
    );
    setSelectedCreditCard(preselectedCCList[0]);

    if (
      useNativeManagePaymentMethods &&
      wireRadioButton.isPreselected
    ) {
      setSelectedPaymentType('wireTransfer');
    } else {
      if (
        checkRadioButton?.isPreselected ||
        wireRadioButton.isPreselected
      ) {
        checkRadioButton?.isPreselected
          ? setSelectedPaymentType('check')
          : setSelectedPaymentType('wireTransfer');
      }
    }
  }, []);

  useEffect(() => {
    if (
      selectedPaymentType !== '' &&
      selectedCreditCard !== undefined
    ) {
      dispatch(setCheckWireFlag(true));
    }
  }, [selectedPaymentType, selectedCreditCard]);

  const handleRadioChange = (paymentType: string) => {
    setSelectedPaymentType(paymentType);
    const actionAnalytics: unknown = {
      key: 'Page Name',
      value: 'Check or Wire Payment',
    };
    trackAnalytics({
      trackingTitle:
        paymentType === 'check'
          ? 'Select_CheckPaymentMethod_Button'
          : 'Select_WirePaymentMethod_Button',
      properties: [actionAnalytics] as IKeyValue[],
    });
    dispatch(
      setSelectedPaymentMethods([
        {
          backupCreditCard: selectedCreditCard,
          paymentType: paymentType,
          preselectedSplitValue: totalCostValue,
          id: '',
          displayValue1: paymentType,
        },
      ]),
    );
  };

  const handleCreditCardRadioChange = (
    creditCard: IPayment,
  ) => {
    setSelectedCreditCard(creditCard);

    dispatch(
      setSelectedPaymentMethods([
        {
          backupCreditCard: creditCard,
          paymentType: selectedPaymentType,
          preselectedSplitValue: totalCostValue,
          id: '',
          displayValue1: selectedPaymentType,
        },
      ]),
    );
  };

  return (
    <StyledSubPageCheckWireTransfer>
      <StyledCard>
        {checkWireTitle && (
          <StyledTitleContainer>
            <StyledTitle
              variant={typography.h4a}
              color={solidColors.midnight}
            >
              {checkWireTitle}
            </StyledTitle>
            {checkWireSubtitle && (
              <StyledTitle
                variant={typography.body1a}
                color={solidColors.darkGray}
              >
                {checkWireSubtitle}
              </StyledTitle>
            )}
          </StyledTitleContainer>
        )}
        {!useNativeManagePaymentMethods &&
          checkRadioButton && (
            <CustomRadio
              label={checkRadioButton?.title}
              checked={selectedPaymentType === 'check'}
              onClick={() => {
                handleRadioChange('check');
              }}
            />
          )}
        <CustomRadio
          label={wireRadioButton.title}
          checked={selectedPaymentType === 'wireTransfer'}
          onClick={() => {
            handleRadioChange('wireTransfer');
          }}
        />

        {backupCardTitle && (
          <StyledTitleContainer>
            <StyledTitle
              variant={typography.h4a}
              color={solidColors.midnight}
            >
              {backupCardTitle}
            </StyledTitle>
          </StyledTitleContainer>
        )}
        {creditCards.map((card) => (
          <CustomRadio
            key={card.id}
            label={card.displayValue1}
            checked={
              selectedCreditCard?.displayValue1 ===
              card.displayValue1
            }
            onClick={() => {
              handleCreditCardRadioChange(card);
            }}
          />
        ))}
        <StyledAddEditButton
          title={editPaymentButton?.title}
          styleType={'icon_leading'}
          action={{
            actionMethod: () => {
              dispatch(setPaymentLocation('payment'));
            },
          }}
          image={editPaymentButton?.image}
        />
      </StyledCard>
      {!useNativeManagePaymentMethods &&
        selectedPaymentType === 'check' && (
          <StyledCard>
            {checkPaymentInfoTitle && (
              <StyledTitleContainer>
                <StyledTitle
                  variant={typography.h4a}
                  color={solidColors.midnight}
                >
                  {checkPaymentInfoTitle}
                </StyledTitle>
                {checkPaymentInfoSubtitle && (
                  <StyledTitle
                    variant={typography.body1a}
                    color={solidColors.darkGray}
                  >
                    {checkPaymentInfoSubtitle}
                  </StyledTitle>
                )}
              </StyledTitleContainer>
            )}
            {checkPaymentInfoItems?.map(
              (detailList, index) => (
                <DetailListItemList
                  key={index}
                  title={detailList.title}
                  items={detailList.items}
                />
              ),
            )}
          </StyledCard>
        )}
      {selectedPaymentType === 'wireTransfer' && (
        <StyledCard>
          {wirePaymentInfoTitle && (
            <StyledTitleContainer>
              <StyledTitle
                variant={typography.h4a}
                color={solidColors.midnight}
              >
                {wirePaymentInfoTitle}
              </StyledTitle>
              {wirePaymentInfoSubtitle && (
                <StyledTitle
                  variant={typography.body1a}
                  color={solidColors.darkGray}
                >
                  {wirePaymentInfoSubtitle}
                </StyledTitle>
              )}
            </StyledTitleContainer>
          )}
          {wirePaymentInfoItems.map((detailList, index) => (
            <DetailListItemList
              key={index}
              title={detailList.title}
              items={detailList.items}
            />
          ))}
        </StyledCard>
      )}
    </StyledSubPageCheckWireTransfer>
  );
};

export default SubPageCheckWireTransfer;
