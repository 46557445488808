import styled from 'styled-components';
import { surfaceColors } from '@constants/styles/colors.constants';

export const StyledDateTimeBlockPaper = styled.div`
  background-color: ${surfaceColors.backgroundUpWhite
    .color};
  width: 140px;
  height: 100%;
`;

export const StyledDateContainer = styled.div`
  padding-top: 24px;
`;

export const StyledTimeContainer = styled.div`
  margin-top: 12px;
`;

export const StyledDayString = styled.p`
  margin-bottom: 6px;
  margin-top: 0;
`;

export const StyledYear = styled.p`
  margin-top: 8px;
`;

export const StyledTimeString = styled.p`
  margin: 4px;
`;
