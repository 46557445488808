import { IImage } from '@components/types';
import {
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit';
import {
  endpoints,
  getAuthHeaders,
  pegasusClient,
} from '@services/apiService';

interface ILandingInfo {
  headerImage?: IImage;
  isAuthorizedUser?: boolean;
  isAlreadyAirMedMember?: boolean;
  purchasePrice?: string;
  selectedPaymentMethod?: string;
  startDate?: string;
  endDate?: string;
  passengersCovered?: string[];
}

export interface IAirmedPassenger {
  id?: string;
  firstName: string;
  firstNameEnableFlag?: boolean;
  middleName: string;
  middleNameEnableFlag?: boolean;
  lastName: string;
  lastNameEnableFlag?: boolean;
  birthDate: string;
  dateOfBirthDisplay?: string;
  birthDateEnableFlag?: boolean;
  phone?: string;
  phoneEnableFlag?: boolean;
  email?: string;
  emailEnableFlag?: boolean;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  postalCode: string;
  profileImage?: IImage;
}

interface IAirmedInitialState {
  landingInfo?: ILandingInfo;
  mainPassenger: IAirmedPassenger;
  passengerList: IAirmedPassenger[];
  mainPassengerFlag: boolean;
  passengerListFlag: boolean;
  registeredIndividuals?: IAirmedPassenger[];
  allRegisteredIndividuals?: IAirmedPassenger[];
  successRegisterIndividualFlag: boolean;
  successWebToCase: boolean;
}

export const AirmedInitialState: IAirmedInitialState = {
  mainPassenger: {
    firstName: '',
    middleName: '',
    lastName: '',
    birthDate: '',
    phone: '',
    email: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    postalCode: '',
  },
  passengerList: [],
  mainPassengerFlag: false,
  passengerListFlag: false,
  successRegisterIndividualFlag: false,
  successWebToCase: false,
};

interface IProps {
  urlParams?: string;
  queryParams?: string;
  token?: string;
  type?: string;
  resPayload?: unknown;
}

export const airmedLandingRequest = createAsyncThunk(
  'airmed/getLanding',
  async (props: IProps) => {
    const { queryParams = '' } = props;
    try {
      return (
        await pegasusClient.get(
          `${endpoints.airmedLanding}?version=1&platform=web${queryParams}`,
          {
            headers: getAuthHeaders(),
          },
        )
      ).data.data;
    } catch (err) {
      return err;
    }
  },
);

export const airmedSignUpRequest = createAsyncThunk(
  'airmed/getSignup',
  async (props: IProps) => {
    const { queryParams = '' } = props;
    try {
      return (
        await pegasusClient.get(
          `${endpoints.airmedSignUp}?version=1&platform=web${queryParams}`,
          {
            headers: getAuthHeaders(),
          },
        )
      ).data.data;
    } catch (err) {
      return err;
    }
  },
);

export const airmedManageIndividuals = createAsyncThunk(
  'airmed/getIndividuals',
  async (props: IProps) => {
    const { queryParams = '' } = props;
    try {
      return (
        await pegasusClient.get(
          `${endpoints.airmedManageIndiviuals}?version=1&platform=web${queryParams}`,
          {
            headers: getAuthHeaders(),
          },
        )
      ).data;
    } catch (err) {
      return err;
    }
  },
);

export const airmedAddIndividualsList = createAsyncThunk(
  'airmed/getAllIndividuals',
  async (props: IProps) => {
    const { queryParams = '' } = props;
    try {
      return (
        await pegasusClient.get(
          `${endpoints.airmedAddIndividualsList}?version=1&platform=web${queryParams}`,
          {
            headers: getAuthHeaders(),
          },
        )
      ).data;
    } catch (err) {
      return err;
    }
  },
);

export const airmedSaveNewIndividual = createAsyncThunk(
  'airmed/postAddIndividual',
  async (props: IProps) => {
    const { resPayload } = props;
    const config = {
      headers: getAuthHeaders(),
    };
    try {
      return (
        await pegasusClient.post(
          `${endpoints.airmedAddIndividual}?version=1&platform=web`,
          resPayload,
          config,
        )
      ).data;
    } catch (err) {
      return err;
    }
  },
);

export const airmedSubmitWebToCase = createAsyncThunk(
  'airmed/postAirmedWebToCase',
  async (props: IProps) => {
    const { resPayload } = props;
    const config = {
      headers: getAuthHeaders(),
    };
    try {
      return (
        await pegasusClient.post(
          `${endpoints.hubWebToCaseSubmit}?version=1&platform=web`,
          resPayload,
          config,
        )
      ).data;
    } catch (err) {
      return err;
    }
  },
);

const AirmedSlice = createSlice({
  name: 'Airmed',
  initialState: AirmedInitialState,
  reducers: {
    setHeaderImage: (state, action) => {
      const { payload } = action;
      state.landingInfo = payload;
    },
    updateMainPassenger: (state, action) => {
      const { payload } = action;
      state.mainPassenger = payload;
    },
    updatePassengerList: (state, action) => {
      const { payload } = action;
      state.passengerList = payload;
    },
    updateMainPassengerFlag: (state, action) => {
      const { payload } = action;
      state.mainPassengerFlag = payload;
    },
    updatePassengerListFlag: (state, action) => {
      const { payload } = action;
      state.passengerListFlag = payload;
    },
    updateSuccessRegisterIndividualFlag: (
      state,
      action,
    ) => {
      const { payload } = action;
      state.successRegisterIndividualFlag = payload;
    },
    updateSuccessWebToCaseFlag: (state, action) => {
      const { payload } = action;
      state.successWebToCase = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      airmedLandingRequest.fulfilled,
      (state, action) => {
        const { payload } = action;
        if (payload.metadata) {
          const { upstream } = payload.metadata;
          state.landingInfo = upstream;
        }
      },
    );
    builder.addCase(
      airmedSignUpRequest.fulfilled,
      (state, action) => {
        const { payload } = action;
        if (payload.metadata) {
          const { upstream } = payload.metadata;
          state.mainPassengerFlag = false;
          state.passengerListFlag = false;
          state.passengerList = [];
          state.mainPassenger = upstream;
          state.mainPassenger.firstNameEnableFlag =
            upstream.firstName &&
            upstream.firstName.length > 0
              ? true
              : false;
          state.mainPassenger.lastNameEnableFlag =
            upstream.lastName &&
            upstream.lastName.length > 0
              ? true
              : false;
          if (
            upstream.phone &&
            upstream.phone.includes('+1')
          ) {
            const validPhone = upstream.phone.substring(2);
            state.mainPassenger.phone = validPhone;
          }
          state.mainPassenger.phoneEnableFlag =
            upstream.phone && upstream.phone.length > 0
              ? true
              : false;
          state.mainPassenger.birthDate =
            upstream.birthDate || '';
          state.mainPassenger.birthDateEnableFlag =
            upstream.birthDate &&
            upstream.birthDate.length > 0
              ? true
              : false;
          state.mainPassenger.emailEnableFlag =
            upstream.email && upstream.email.length > 0
              ? true
              : false;
        }
      },
    );
    builder.addCase(
      airmedManageIndividuals.fulfilled,
      (state, action) => {
        const { payload } = action;
        state.registeredIndividuals =
          payload.data.individuals;
      },
    );
    builder.addCase(
      airmedAddIndividualsList.fulfilled,
      (state, action) => {
        const { payload } = action;
        state.allRegisteredIndividuals =
          payload.data.individuals;
      },
    );
    builder.addCase(
      airmedSaveNewIndividual.fulfilled,
      (state, action) => {
        const { payload } = action;
        state.successRegisterIndividualFlag = true;
      },
    );
    builder.addCase(
      airmedSubmitWebToCase.fulfilled,
      (state, action) => {
        const { payload } = action;
        state.successWebToCase = true;
      },
    );
  },
});

export const {
  setHeaderImage,
  updateMainPassenger,
  updatePassengerList,
  updateMainPassengerFlag,
  updatePassengerListFlag,
  updateSuccessRegisterIndividualFlag,
  updateSuccessWebToCaseFlag,
} = AirmedSlice.actions;

export default AirmedSlice.reducer;
