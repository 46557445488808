import {
  deleteLockSession,
  fetchLockSession,
} from '@services/sessionLockService/sessionLockSlice';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@app/store';
import { getFromLocalStorage } from '@components/utils/storage';

export default function useSessionLock() {
  const { requestId, lockId } = useSelector(
    (state: RootState) => state.sessionLock,
  );

  const dispatch = useDispatch();

  const addSessionLock = (flightDeailId?: string) => {
    if (flightDeailId !== undefined)
      dispatch(
        fetchLockSession({
          resPayload: {
            flightDealId: flightDeailId,
            requestId: uuidv4(),
          },
        }),
      );
  };

  const removeSessionLock = (flightDealId?: string) => {
    if (
      flightDealId !== undefined &&
      getFromLocalStorage('lockId') !== null
    )
      dispatch(
        deleteLockSession({
          resPayload: {
            flightDealId: flightDealId,
            requestId:
              requestId ?? getFromLocalStorage('requestId'),
            lockId: lockId ?? getFromLocalStorage('lockId'),
          },
        }),
      );
  };

  return { addSessionLock, removeSessionLock };
}
