import { datadogRum } from '@datadog/browser-rum';
import { fetchProcessEnvVariable } from '@services/envService/envService';
import { datadogLogs } from '@datadog/browser-logs';


export const datadogInit = () => {
  datadogRum.init({
    applicationId: 'd4a027bf-babf-404c-9f16-4868953184ef',
    clientToken: 'pub1be126aeea965050ec2dd4930dd48789',
    site: 'datadoghq.com',
    service: 'members-website',
    env: process.env.REACT_APP_LOCAL_ENVIRONMENT
      ? 'local'
      : fetchProcessEnvVariable('ENV'),
    version: process.env.REACT_APP_VERSION,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
    enableExperimentalFeatures: ['feature_flags'],
  });

  datadogRum.startSessionReplayRecording();
};


export const datadogLogsInit = () => {
  datadogLogs.init({
    clientToken: 'pub1be126aeea965050ec2dd4930dd48789',
    site: 'datadoghq.com',
    forwardErrorsToLogs: false, // TODO: Set to true when we want to forward errors to Datadog
    sessionSampleRate: 100,
    env: process.env.REACT_APP_LOCAL_ENVIRONMENT
    ? 'local'
    : fetchProcessEnvVariable('ENV'),
    service: 'wu-web',
  });
};
