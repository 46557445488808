import {
  endpoints,
  getAuthHeaders,
  pegasusClient,
} from '../apiService';

export const searchProgressiveProfiling = async (
  matcher: string,
  searchType: string,
) => {
  const headers = getAuthHeaders();
  const endpoint = `${endpoints.progressiveProfilingSearch}&matcher=${matcher}&searchType=${searchType}`;
  const res = await pegasusClient.get(endpoint, {
    headers: headers,
  });
  return res;
};
