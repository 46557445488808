import React, { useState } from 'react';
import { ICardNumberPicker } from './CardNumberPicker.interfaces';
import { TitleCTACard } from '@molecules';
import {
  Icon,
  PickerContainer,
  TypographyNumber,
} from './CardNumberPicker.styles';
import { ImageImgix } from '@atoms';
import { IconButton } from '@library';
import { typography } from '@constants/styles/typography.constants';
import { solidColors } from '@constants/styles/colors.constants';
import { useDispatch } from 'react-redux';
import { submitAnswers } from '@features/Dashboard/CarouselSlice';

export default function CardNumberPicker({
  headline,
  dismissable,
  numberPicker,
  button,
}: ICardNumberPicker) {
  const dispatch = useDispatch();
  const [numberState, setNumberState] = useState(
    numberPicker.defaultValue
      ? numberPicker.defaultValue
      : 0,
  );
  const question = button.action.outboundPayloadKeys?.filter(
    (obj) =>
      obj.key === 'Question' || obj.key === 'question',
  )[0];

  const clickHandler = (
    _event: React.ChangeEvent,
    increment: boolean,
  ) => {
    _event.preventDefault();
    if (increment) {
      numberPicker.maxValue &&
        numberState < numberPicker.maxValue &&
        setNumberState(numberState + 1);
    } else {
      (numberPicker.minValue as number) >= 0 &&
        (numberPicker.minValue as number) < numberState &&
        setNumberState(numberState - 1);
    }
  };

  const submitAnswer = () => {
    const submitQuestion = question?.value as string;
    dispatch(
      submitAnswers({
        q: submitQuestion,
        a: [`${numberState}`],
      }),
    );
  };

  // used to submit an empty answer when dismissing.
  const submitDismissAnswer = () => {
    const submitQuestion = question?.value as string;
    dispatch(submitAnswers({ q: submitQuestion, a: [''] }));
  };

  const handleDismiss = () => {
    submitDismissAnswer();
  };

  return (
    <TitleCTACard
      headline={headline as string}
      button={{
        ...button,
        action: {
          actionMethod: submitAnswer,
        },
      }}
      dismissable={dismissable}
      onDismiss={handleDismiss}
    >
      <PickerContainer>
        <IconButton
          onClick={(_event: React.ChangeEvent) =>
            clickHandler(_event, true)
          }
        >
          {numberPicker.incrementButton?.image?.url ? (
            <ImageImgix
              data-name="dynamic icon"
              filter={
                numberPicker.incrementButton?.image
                  ?.tintColor
              }
              src={
                numberPicker.incrementButton?.image
                  ?.url as string
              }
              alt="increment"
              height={20}
              width={20}
            />
          ) : (
            <Icon
              $increment
              data-name="static icon"
              fontSize="inherit"
            />
          )}
        </IconButton>
        <TypographyNumber
          variant={typography.heading01Large}
          color={solidColors.midnight}
        >
          {numberState}
        </TypographyNumber>
        <IconButton
          onClick={(_event: React.ChangeEvent) =>
            clickHandler(_event, false)
          }
        >
          {numberPicker.decrementButton?.image?.url ? (
            <ImageImgix
              filter={
                numberPicker.decrementButton?.image
                  ?.tintColor
              }
              src={
                numberPicker.decrementButton?.image
                  ?.url as string
              }
              alt="decrement"
              height={12}
              width={19}
            />
          ) : (
            <Icon
              data-name="static icon"
              fontSize="inherit"
            />
          )}
        </IconButton>
      </PickerContainer>
    </TitleCTACard>
  );
}
