import styled from 'styled-components';

export const LoaderContainer = styled.div<{
  height?: number;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: ${(props) =>
    props.height ? `${props.height}%` : '45%'};
  left: 0;
  width: 100%;
  height: 100px;

  z-index: 999992;
  pointer-events: none;
`;
