import {
  useEffect,
  useState,
  RefObject,
  useRef,
  useLayoutEffect,
} from 'react';

interface ICarouselSlides {
  slideRef: RefObject<HTMLElement>;
  parentContainer: RefObject<HTMLElement>;
  children: React.ReactNode | React.ReactNode[];
  inComponent?: boolean;
  width?: number;
  isHorizontalScroll?: boolean;
  slideMargin?: number;
  slidePadding?: number;
  fullCardWidthOverride?: boolean;
}

function useCarouselSlides({
  slideRef,
  parentContainer,
  children,
  inComponent,
  isHorizontalScroll = false,
  slideMargin = 24,
  slidePadding = 0,
  fullCardWidthOverride = false,
}: ICarouselSlides) {
  // slidesPerView is how many cards should be 'active' at a time
  const [slidesPerView, setSlidesPerView] = useState(0);
  // total slides is the total number of views it will take to reach the end of the carousel
  const [totalSlides, setTotalSlides] = useState(0);
  const [slideWidth, setSlideWidth] = useState(0);
  const [transformWidth, setTransformWidth] = useState(0);
  const [slideView, setSlideView] = useState(1);
  const [startIndex, setStartIndex] = useState(0);
  const [activeForward, setActiveForward] = useState(true);
  const [activeBack, setActiveBack] = useState(false);
  const [
    allActiveFallback,
    setAllActiveFallback,
  ] = useState(false);
  const slides: React.ReactNode[] = Array.isArray(children)
    ? children
    : [children];

  const inComponentOrFullCardWidthOverride =
    inComponent || fullCardWidthOverride;

  const parentWidthRef = useRef(0);
  const childWidthRef = useRef(0);

  useLayoutEffect(() => {
    const parentEl = parentContainer.current;
    const childEl = slideRef.current;

    if (!parentEl || !childEl) return;

    const parentWidth = parentEl.offsetWidth || 1;
    const childWidth = childEl.offsetWidth || 1;

    const parentWidthChanged =
      parentWidthRef.current !== parentWidth;
    const childWidthChanged =
      childWidthRef.current !== childWidth;

    if (!parentWidthChanged && !childWidthChanged) {
      return;
    }

    parentWidthRef.current = parentWidth;
    childWidthRef.current = childWidth;

    const slideWithMargin = childWidth + slideMargin;
    const slideWithMarginAndPadding =
      slideWithMargin + slidePadding;
    const currItemsPerSlide = Math.floor(
      parentWidth / slideWithMargin,
    );
    const newSlideWidth =
      slideWithMarginAndPadding * currItemsPerSlide;
    const newSlidesPerView = inComponentOrFullCardWidthOverride
      ? 1
      : currItemsPerSlide;
    const currTotalSlides = Math.ceil(
      slides.length / currItemsPerSlide,
    );

    setSlideWidth((prev) =>
      prev !== newSlideWidth ? newSlideWidth : prev,
    );
    setSlidesPerView((prev) =>
      prev !== newSlidesPerView ? newSlidesPerView : prev,
    );

    if (!Number.isFinite(currTotalSlides)) {
      if (inComponentOrFullCardWidthOverride) {
        const overrideSlideWidth =
          (childWidth ?? 0) + slideMargin;

        setSlideWidth((prev) =>
          prev !== overrideSlideWidth
            ? overrideSlideWidth
            : prev,
        );
      }
      setTotalSlides(slides.length);
      setAllActiveFallback(true);
    } else {
      setTotalSlides(currTotalSlides);
      setAllActiveFallback(false);
    }

    if (!isHorizontalScroll) {
      setStartIndex(0);
      setTransformWidth(0);
      setSlideView(1);
      setActiveForward(true);
      setActiveBack(false);
    }
  }, [
    slides.length,
    inComponentOrFullCardWidthOverride,
    isHorizontalScroll,
    slideMargin,
    slidePadding,
    slideRef.current,
    parentContainer.current,
  ]);

  useEffect(() => {
    if (slideView === 1) {
      setActiveBack(false);
    } else {
      setActiveBack(true);
    }
    if (slideView === totalSlides) {
      setActiveForward(false);
    } else {
      setActiveForward(true);
    }
  }, [slideView, totalSlides]);

  const handleArrowForward = (e: React.MouseEvent) => {
    e.preventDefault();
    if (activeForward) {
      setTransformWidth(transformWidth - slideWidth);
      setStartIndex(startIndex + slidesPerView);
      setSlideView(slideView + 1);
    }
  };

  const handleArrowBack = (e: React.MouseEvent) => {
    e.preventDefault();
    if (activeBack) {
      setTransformWidth(transformWidth + slideWidth);
      setStartIndex(startIndex - slidesPerView);
      setSlideView(slideView - 1);
    }
  };
  return {
    slidesPerView,
    totalSlides,
    transformWidth,
    slideView,
    startIndex,
    activeForward,
    activeBack,
    allActiveFallback,
    slideMargin,
    slides,
    handleArrowForward,
    handleArrowBack,
  };
}

export default useCarouselSlides;
