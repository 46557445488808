import { solidColors } from '@constants/styles/colors.constants';
import { Button } from '@atoms';
import styled from 'styled-components';
import { Icons } from '@components/library';

export const ItemContainer = styled.div`
  padding-bottom: 1rem;
  padding-top: 1rem;
  padding-left: 0.5rem;
  border-bottom: 1px solid ${solidColors.mediumGray.color};

  &:hover {
    background-color: ${solidColors.mediumGray.color};
    cursor: pointer;
  }
`;

export const GroupContainer = styled.div`
  margin-top: 2rem;
`;

export const IconContainer = styled.div`
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  border: 1px solid ${solidColors.midnight.color};
  padding: 10px;
  width: 64px;
  height: 64px;
`;

export const PetsIcon = styled(Icons.Pets)`
  margin: 9px 9px;
`;

export const PassengerIcon = styled(Icons.Person)`
  margin: 9px 9px;
`;
