import { FormFieldTitleDropDown } from '@atoms';
import styled from 'styled-components';

export const StyledContainer = styled.div``;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;

  h1 {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const CardContainer = styled.div`
  margin: 1.5rem 0;
`;
