import React, { useEffect } from 'react';
import { useAppSelector } from '@app/hooks';
import {
  Button,
  Toggle,
} from '@wheelsup/wu-react-components';
import { Typography } from '@atoms';
import PetBaseInformationForm from '@components/molecules/PetBaseInformationForm/PetBaseInformationForm';
import PetInformationTypeForm from '@components/molecules/PetInformationTypeForm/PetInformationTypeForm';
import { StyledInputContainer } from '@components/molecules/PetInformationTypeForm/PetInformationTypeForm.styles';
import { typography } from '@constants/styles/typography.constants';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Divider } from '../SubPagePassengerAddEdit/SubPagePassengerAddEdit.styles';
import { PageActionButtonContainer } from '../SubPagePassengerSelection/SubPagePassengerSelection.styles';
import {
  IIsGoodWith,
  ISubPagePetAddEdit,
} from './SubPagePetAddEdit.interfaces';
import {
  cleanPetState,
  setInputValue,
  setPet,
} from './SubPagePetAddEdit.slice';

import {
  createPet,
  editPet,
} from './SubPagePetAddEdit.services';
import CarouselScrollHorizontal from '../CarouselScrollHorizontal/CarouselScrollHorizontal';
import TextGroupSwitch from '@components/molecules/TextGroupSwitch/TextGroupSwitch';
import { solidColors } from '@constants/styles/colors.constants';
import { deconstructStyleType } from '@components/utils/deconstructStyleType';
import { IPet, TextStyleType } from '@components/types';

const SubPagePetAddEdit = ({
  pet,
  petInfoTitle,
  nameFormField,
  weightFormField,
  ageFormField,
  switchTextGroup,
  petTypeTitle,
  petTypeDogButton,
  petTypeCatButton,
  petTypeOtherButton,
  petAffinityTitle,
}: ISubPagePetAddEdit) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    isGoodWithCats,
    isGoodWithDogs,
    isGoodWithKids,
    name,
    petType,
    age,
    weight,
    isFavorite,
  } = useAppSelector((state) => state.subPetAddEdit);

  const state = useAppSelector(
    (state) => state.subPetAddEdit,
  );

  const handleInputChange = (
    inputKey: string,
    value: string | boolean,
  ) => {
    dispatch(
      setInputValue({
        key: inputKey,
        value: value,
      }),
    );
  };

  const addOrEditPet = async () => {
    pet === undefined
      ? createPet(state, navigate)
      : editPet(state, pet, navigate);
  };

  useEffect(() => {
    if (pet !== undefined) {
      /*
                Setting the obj to some initial values
                that will be overwritten by the actual pet obj.
                This reduces the leftover state from the previous pet
            */
      const petObj = {
        isGoodWithCats: false,
        isGoodWithDogs: false,
        isGoodWithKids: false,
        age: '',
        weight: '',
        ...pet,
      } as IPet;
      dispatch(setPet(petObj));
    }

    return function cleanup() {
      dispatch(cleanPetState());
    };
  }, [pet]);

  const {
    typographyType: switchTypographyType,

    colorType: switchColorType,
  } = deconstructStyleType(
    switchTextGroup.styleType as TextStyleType | undefined,
  );

  const isGoodWithObj: IIsGoodWith = {
    Dogs: 'isGoodWithDogs',
    Cats: 'isGoodWithCats',
    Kids: 'isGoodWithKids',
    isFavorite: 'isFavorite',
  };

  const handleIsGoodWith = (
    e:
      | React.MouseEvent
      | React.ChangeEvent<HTMLInputElement>,
  ) => {
    e.preventDefault();
    const { id } = e.currentTarget;
    const isGoodWith = isGoodWithObj[
      id
    ] as keyof typeof state;
    handleInputChange(isGoodWith, !state[isGoodWith]);
  };

  const isFormNotModified = () => {
    const currentValues = {
      name,
      age,
      weight,
    };

    const petReduced = {
      firstName: pet?.name,
      weight: pet?.weight?.toString() ?? '0',
      age: pet?.age?.toString() ?? '0',
    };

    if (pet) {
      if (
        currentValues.age === 0 ||
        !currentValues.age ||
        Number(currentValues.weight) === 0 ||
        !currentValues.weight
      ) {
        return true;
      }
    }

    return (
      JSON.stringify(currentValues) ===
      JSON.stringify(petReduced)
    );
  };

  return (
    <>
      <PetBaseInformationForm
        petInfoTitle={petInfoTitle}
        nameFormField={nameFormField}
        weightFormField={weightFormField}
        ageFormField={ageFormField}
        existingPet={!!pet}
      />
      <PetInformationTypeForm
        pet={pet}
        petTypeTitle={petTypeTitle}
        petTypeDogButton={petTypeDogButton}
        petTypeCatButton={petTypeCatButton}
        petTypeOtherButton={petTypeOtherButton}
      />
      <Typography variant={typography.heading03Large}>
        {petAffinityTitle}
      </Typography>
      <StyledInputContainer>
        <CarouselScrollHorizontal>
          <div id={'Dogs'} onClick={handleIsGoodWith}>
            <Toggle
              label={'Dogs'}
              pressed={isGoodWithDogs || false}
              onChange={() => null}
            />
          </div>
          <div id={'Cats'} onClick={handleIsGoodWith}>
            <Toggle
              label={'Cats'}
              pressed={isGoodWithCats || false}
              onChange={() => null}
            />
          </div>
          <div id={'Kids'} onClick={handleIsGoodWith}>
            <Toggle
              label={'Kids'}
              pressed={isGoodWithKids || false}
              onChange={() => null}
            />
          </div>
        </CarouselScrollHorizontal>
      </StyledInputContainer>
      <TextGroupSwitch
        copy={switchTextGroup.title}
        checked={isFavorite || false}
        onChange={handleIsGoodWith}
        direction={
          (switchTextGroup.alignmentState as
            | 'left'
            | 'right') ?? 'left'
        }
        color={solidColors[switchColorType ?? 'midnight']}
        textStyle={
          typography[switchTypographyType ?? 'body1']
        }
      />
      <Divider />

      <PageActionButtonContainer>
        <Button
          variant="secondary"
          onPress={() => navigate(-1)}
        >
          nevermind
        </Button>
        <Button
          variant="primary"
          onPress={addOrEditPet}
          isDisabled={
            !name || petType === '' || isFormNotModified()
          }
        >
          save
        </Button>
      </PageActionButtonContainer>
    </>
  );
};

export default SubPagePetAddEdit;
