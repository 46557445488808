import { Button } from '@atoms';
import { IButton } from '@atoms/Button/Button.interfaces';
import { SingleSelect } from '@components/molecules/CardHeadlineSearchSelectCTADismissSelect/SingleSelect';
import PopUpCard from '@components/molecules/PopUpCard/PopUpCard';
import { IPassenger } from '@components/types';
import { useState } from 'react';
import { ButtonContainer } from './RemovePaxSelector.styles';

export interface ILeadPassengerSelection {
  availableLeadPassengers: IPassenger[];
  currentLeadPassenger?: IPassenger;
  popUpButton?: IButton;
  confirmAction: (
    passenger: IPassenger,
    oldLeadPassenger?: IPassenger,
  ) => void;
  handleOpenButton: () => void;
  handleCloseButton: () => void;
  isOpen: boolean;
}

export default function LeadPassengerSelection({
  popUpButton,
  currentLeadPassenger,
  isOpen,
  handleOpenButton,
  handleCloseButton,
  confirmAction,
  availableLeadPassengers: selectedPassengers,
}: ILeadPassengerSelection) {
  const [
    selectedPassenger,
    setSelectedPassenger,
  ] = useState<IPassenger>(
    currentLeadPassenger as IPassenger,
  );

  const handleOnConfirm = () => {
    confirmAction(
      selectedPassenger,
      currentLeadPassenger as IPassenger,
    );
    handleCloseButton();
  };

  const leadSelectOnChange = (
    _event: React.ChangeEvent<HTMLInputElement>,
    leadPassenger: IPassenger,
  ) => {
    _event.preventDefault();
    leadPassenger.id === selectedPassenger?.id
      ? setSelectedPassenger(
          selectedPassenger as IPassenger,
        )
      : setSelectedPassenger(leadPassenger);
  };

  const selectList = (
    <>
      {selectedPassengers.map((item) => {
        return (
          <SingleSelect
            key={item.id}
            checked={item.id === selectedPassenger?.id}
            item={`${item.prefix ?? ''} 
                    ${item.firstName} 
                    ${item.middleName ?? ''} ${
              item.lastName
            } 
                    ${item.suffix ?? ''}`}
            onClick={(
              _event: React.ChangeEvent<HTMLInputElement>,
            ) => leadSelectOnChange(_event, item)}
          />
        );
      })}
    </>
  );

  return (
    <>
      <ButtonContainer>
        <Button
          styleType={
            popUpButton?.styleType ??
            'text_with_icon_leading'
          }
          action={{
            actionMethod: handleOpenButton,
          }}
          title={
            popUpButton?.title ??
            'DESIGNATE A DIFFERENT LEAD'
          }
          width="25rem"
          height="3.5rem"
        />
      </ButtonContainer>
      <PopUpCard
        open={isOpen}
        closeAction={handleCloseButton}
        primary={{
          title: 'Cancel',
          action: {
            actionMethod: handleCloseButton,
          },
        }}
        secondary={{
          title: 'Confirm',
          action: {
            actionMethod: handleOnConfirm,
          },
          styleType: 'primary'
        }}
        maxWidth="xs"
        headline="Choose a lead passenger"
        copy=""
        childWidth={100}
      >
        {selectList}
      </PopUpCard>
    </>
  );
}
