import { AxiosError, AxiosInstance } from 'axios';
import { AppDispatch, RootState } from '../../../app/store';
import handle304 from './handle304';
import handle401 from './handle401';
import {
  cacheEndpoints,
  cachePageEndpoints,
} from '../constants';
import { saveDataInLocalStorage } from '@components/utils/storage';
import { setFooterData } from '@services/footerService/footerSlice';
import { EnhancedStore } from '@reduxjs/toolkit';
import { setIsAppLoading } from '@app/AppLoadingSlice';
import {
  IErrorPayload,
  setErrorOpen,
  setErrorPayload,
} from '@components/molecules/Error/ErrorSlice';

let storeGetState: RootState;
let storeDispatch: AppDispatch;

export const injectStore = (_store: EnhancedStore) => {
  storeGetState = _store.getState();
  storeDispatch = _store.dispatch;
};

const errorHandler = (code: number, error: AxiosError) => {
  // Switch case to direct response code to correct helper function
  let errorData;
  switch (code) {
    case 304:
      return handle304({
        storeGetState,
        storeDispatch,
        error,
      });
    case 401:
      handle401(storeGetState, storeDispatch, error);
      break;
    case 404:
      window.location.href = `${window.location.origin}/`;
      break;
    default:
      if ((error as any)?.config.url && (error as any).config.url.includes('graphql')) {
        break;
      }
      if (error.message && error.message === 'cancel') {
        // do nothing when we have a cancelled request
        break;
      }
      // This is a global error handler. The Error component can be found in PageBuilderContainer.tsx
      errorData = error?.response?.data?.data
        ?.data as IErrorPayload;
      errorData && storeDispatch(setErrorPayload(errorData));
      storeDispatch(setErrorOpen(true));
      break;
  }
};

export const responseHandler = (
  instance: AxiosInstance,
) => {
  instance.interceptors.response.use(
    function (response) {
      const url = response.config.url?.split('?')[0] ?? '';
      const cacheData = {
        data: response.data,
        etag: response.headers.etag,
      };
      const isCachable =
        cachePageEndpoints.has(url) ||
        cacheEndpoints.has(url);

      if (isCachable) {
        saveDataInLocalStorage(url, cacheData);
      }

      storeDispatch(setIsAppLoading(false));

      if (response?.data?.data?.FooterWEBONLY) {
        saveDataInLocalStorage(
          'footerData',
          response.data.data.FooterWEBONLY,
        );
        storeDispatch(
          setFooterData(response.data.data.FooterWEBONLY),
        );
      }

      return response;
    },
    async function (error) {
      storeDispatch(setIsAppLoading(false));
      if (error.response?.status === 304) {
        return errorHandler(error.response?.status, error);
      } else {
        errorHandler(error.response?.status, error);
      }
    },
  );
};
