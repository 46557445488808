import {
  removeFromLocalStorage,
  saveDataInLocalStorage,
} from '@components/utils/storage';
import {
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit';
import {
  endpoints,
  getAuthHeaders,
  pegasusClient,
} from '@services/apiService';

export type paymentLocations =
  | 'payment'
  | 'selectPayment'
  | 'addCreditCardPopUp'
  | 'checkWirePopUp';

interface ISessionLockInitialState {
  isSessionActive: boolean;
  error: boolean;
  lockId?: string;
  requestId?: string;
  availabilityId?: string;
}

export const SessionLockInitialState: ISessionLockInitialState = {
  isSessionActive: false,
  error: false,
};

interface IProps {
  urlParams?: string;
  queryParams?: string;
  token?: string;
  type?: string;
  resPayload?: unknown;
}

export const fetchLockSession = createAsyncThunk(
  'sessionLock/fetchlockSession',
  async (props: IProps) => {
    const { resPayload = '', queryParams = '' } = props;
    try {
      return (
        await pegasusClient.post(
          `${endpoints.flightDealLock}?version=1&platform=web${queryParams}`,
          resPayload,
          {
            headers: getAuthHeaders(),
          },
        )
      ).data;
    } catch (err) {
      return err;
    }
  },
);

export const deleteLockSession = createAsyncThunk(
  'sessionLock/deletelockSession',
  async (props: IProps) => {
    const { queryParams = '', resPayload = '' } = props;
    try {
      return (
        await pegasusClient.delete(
          `${endpoints.flightDealLock}?version=1&platform=web${queryParams}`,
          {
            data: resPayload,
            headers: getAuthHeaders(),
          },
        )
      ).data;
    } catch (err) {
      return err;
    }
  },
);

const SessionLockSlice = createSlice({
  name: 'sessionLock',
  initialState: SessionLockInitialState,
  reducers: {
    setIsSessionActive: (state, action) => {
      const { payload } = action;
      state.isSessionActive = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchLockSession.fulfilled,
      (state, action) => {
        const { payload } = action;

        if (payload?.message === 'ERROR') {
          state.error = true;
          payload.response.data.data.data;
        } else if (
          payload?.message.includes('null') ||
          payload?.message.includes('undefined')
        ) {
          state.error = true;
        } else if (payload?.message === 'SUCCESS') {
          state.lockId =
            payload.data.lockAvailability.lockId;
          state.availabilityId =
            payload.data.lockAvailability.availabilityId;
          state.requestId =
            payload.data.lockAvailability.requestId;
          state.error = false;
          saveDataInLocalStorage(
            'lockId',
            payload.data.lockAvailability.lockId,
          );
          saveDataInLocalStorage(
            'availabilityId',
            payload.data.lockAvailability.availabilityId,
          );
          saveDataInLocalStorage(
            'requestId',
            payload.data.lockAvailability.requestId,
          );
        }
      },
    );
    builder.addCase(
      deleteLockSession.fulfilled,
      (state, action) => {
        const { payload } = action;
        if (payload?.response?.status === 400) {
          state.error = true;
        } else {
          state.lockId = undefined;
          state.error = false;
          state.isSessionActive = false;
          state.availabilityId = undefined;
          state.requestId = undefined;
          removeFromLocalStorage('lockId');
          removeFromLocalStorage('availabilityId');
          removeFromLocalStorage('requestId');
        }
      },
    );
  },
});

export const {
  setIsSessionActive,
} = SessionLockSlice.actions;

export default SessionLockSlice.reducer;
