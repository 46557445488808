import React from 'react';
import {
  FooterContainer,
  ContentContainer,
  StyledHr,
  LegalContainer,
  SocialContainer,
  LegalCopy,
  SocialItems,
  QuestionContainer,
  TitleStyled,
  ContactContainer,
  LinkStyled,
  CopyStyled,
  ButtonStyled,
} from './FooterWebOnly.styles';
import { typography } from '@constants/styles/typography.constants';
import { solidColors } from '@constants/styles/colors.constants';
import {
  IData,
  IFooterWebOnly,
} from './FooterWebOnly.interfaces';
import TexGroupBodyCopyLink from '@components/molecules/TextGroupBodyCopyLink/TextGroupBodyCopyLink';
import { ImageImgix } from '@atoms';
import { ColorStyleTranslation } from '@components/utils/newStyleTranslation';
import { Link } from '@wheelsup/wu-react-components';
import { buildText } from '@components/molecules/TextGroupBodyCopyLink/buildText';
import getServerAction from '@atoms/Button/getServerAction';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

const FooterWebOnly = ({
  legalCopy,
  logo,
  background,
  social,
  section_1,
  section_2,
  copyright,
}: IFooterWebOnly) => {
  const navigate = useNavigate();
  const dispatch= useDispatch();
  return (
    <FooterContainer
      data-name="FooterWebOnly"
      background={background}
    >
      <ContentContainer>
        <SocialContainer>
          <ImageImgix
            src={logo?.url}
            alt="Wheels Up logo"
            width={280}
            height={40}
            borderRadius="0px"
          />

          <SocialItems>
            {social?.map(({ action, image }, index) => {
              const key = `${index}`;
              return (
                <ButtonStyled
                  key={key + image?.url}
                  background={background}
                  action={action}
                  aria-label={social[index].title}
                  radius="50%"
                  styleType={'icon_button'}
                  nativeIcon={
                    <ImageImgix
                      src={image?.url ?? ''}
                      filter={ColorStyleTranslation(
                        image?.tintColor ?? '',
                      )}
                      alt="footer icon"
                      height={64}
                      width={64}
                    />
                  }
                ></ButtonStyled>
              );
            })}
          </SocialItems>
        </SocialContainer>

        <QuestionContainer>
          {section_1?.map((sec) => {
            return (
              <React.Fragment key={sec.title}>
                <TitleStyled
                  background={background}
                  variant={typography.body1Bold}
                  color={solidColors.darkGray}
                  align="left"
                >
                  {sec.title}
                </TitleStyled>

                {sec.data.map((item: IData) => {
                  return (
                    <LinkStyled
                      key={item.data.copy}
                      background={background}
                    >
                      <Link
                        onPress={() => getServerAction({
                          buttonAction: item.data?.textAttributes?.links?.[0].action ?? {},
                          action: item.data?.textAttributes?.links?.[0].action ?? {},
                          navigate,
                          dispatch,
                        })}
                      >
                        {item?.data?.copy ?? ''}
                      </Link>
                    </LinkStyled>
                  );
                })}
              </React.Fragment>
            );
          })}
        </QuestionContainer>

        <ContactContainer>
          {section_2?.map((sec) => {
            return (
              <React.Fragment key={sec.title}>
                <TitleStyled
                  background={background}
                  variant={typography.body1Bold}
                  color={solidColors.darkGray}
                  align="left"
                >
                  {sec.title}
                </TitleStyled>

                {sec.data.map((item: IData) => {
                  return (
                    <LinkStyled
                      key={item.data.copy}
                      $color={true}
                      background={background}
                    >
                      <Link
                        onPress={() => getServerAction({
                          buttonAction: item.data?.textAttributes?.links?.[0].action ?? {},
                          action: item.data?.textAttributes?.links?.[0].action ?? {},
                          navigate,
                          dispatch,
                        })}
                      >
                        {item?.data?.copy ?? ''}
                      </Link>
                    </LinkStyled>
                  );
                })}
              </React.Fragment>
            );
          })}
        </ContactContainer>
      </ContentContainer>
      <StyledHr />
      <LegalContainer>
        <LegalCopy>
          <CopyStyled background={background}>
            <TexGroupBodyCopyLink
              variant={typography.body2}
              libraryColor='secondary'
              copy={legalCopy?.data?.copy ?? ''}
              textAttributes={{
                links:
                  legalCopy?.data.textAttributes?.links ??
                  [],
              }}
              className="primary"
            />
          </CopyStyled>
        </LegalCopy>

        <CopyStyled background={background}>
          <TexGroupBodyCopyLink
            variant={typography.body2}
            libraryColor='secondary'
            truncate={1}
            copy={
              copyright?.data?.copy ??
              '© Wheels Up Partners LLC'
            }
            textAttributes={{
              links:
                copyright?.data.textAttributes?.links ?? [],
            }}
            className="primary"
          />
        </CopyStyled>
      </LegalContainer>
    </FooterContainer>
  );
};

export default FooterWebOnly;
