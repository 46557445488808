import {
  useState,
  useRef,
  useContext,
  useEffect,
} from 'react';
import {
  PassengerPickerContainer,
  HrStyled,
  PickerGroup,
  ChipContainer,
  SwitchContainer,
} from './PassengerPicker.styles';
import { useOnClickOutside, useViewport } from '@hooks';
import CustomSubpage from '@components/molecules/CustomSubpage/CustomSubpage';
import { IPassengerPicker } from './PassengerPicker.interfaces';
import TextGroupBodyCopySubCopy from '@molecules/TextGroupBodyCopySubCopy/TextGroupBodyCopySubCopy';
import { FlightSearchContext } from '../SubPageFlightSearchV2/FlightSearchContext/FlightSearchContext';
import {
  PickerContainer,
  PickerWrapper,
} from './PassengerPicker.styles';
import TextGroupIconText from '../TextGroupIconText/TextGroupIconText';
import { TitleIconContainer } from '..';
import MinimalStepper from './MinimalStepper';
import {
  passengerPickerConstants,
  petPickerConstants,
} from './pickerConstants';
import { useFlightSearch } from '../SubPageFlightSearchV2/FlightSearchContext/FlightSearchHooks';
import { v4 as uuidv4 } from 'uuid';
import {
  Chip,
  Divider,
  Switch,
  Typography,
} from '@wheelsup/wu-react-components';

const PassengerPickerV3 = ({
  legNumber,
}: IPassengerPicker) => {
  const {
    legs,
    updateLeg,
    flightType,
    isRoundTrip,
    contextDispatch,
  } = useFlightSearch(useContext(FlightSearchContext));
  const leg = legs[legNumber];

  const [passengerCount, setPassengerCount] = useState(1);
  const [petCount, setPetCount] = useState(0);
  const [
    paxCountRoundTripLeg2,
    setPaxCountRoundTripLeg2,
  ] = useState(1);
  const [
    petCountRoundTripLeg2,
    setPetCountRoundTripLeg2,
  ] = useState(0);
  const [
    samePaxPetSwitchValue,
    setSamePaxPetSwitchValue,
  ] = useState(true);

  // We might have a predefined number of passengers and pets
  useEffect(() => {
    setPassengerCount(leg.numberOfPassengers);
  }, [leg.numberOfPassengers]);

  useEffect(() => {
    setPetCount(leg.numberOfPets);
  }, [leg.numberOfPets]);

  const popupRef = useRef(null);

  const { width } = useViewport();

  const pickers = [
    {
      ...passengerPickerConstants,
      setter:
        samePaxPetSwitchValue && isRoundTrip
          ? (newCount: number) => {
              setPassengerCount(newCount);
              setPaxCountRoundTripLeg2(newCount);
            }
          : setPassengerCount,
      count: passengerCount,
      uid: `PassengerPicker0Leg${legNumber}${uuidv4()}`,
    },
    {
      ...petPickerConstants,
      setter:
        samePaxPetSwitchValue && isRoundTrip
          ? (newCount: number) => {
              setPetCount(newCount);
              setPetCountRoundTripLeg2(newCount);
            }
          : setPassengerCount,
      count: petCount,
      uid: `PetPicker0Leg${legNumber}${uuidv4()}`,
    },
  ];

  const roundTripReturnLegPickers = [
    {
      ...passengerPickerConstants,
      setter: setPaxCountRoundTripLeg2,
      count: paxCountRoundTripLeg2,
      uid: `PassengerPicker1Leg${legNumber}${uuidv4()}`,
    },
    {
      ...petPickerConstants,
      setter: setPetCountRoundTripLeg2,
      count: petCountRoundTripLeg2,
      uid: `PassengerPicker1Leg${legNumber}${uuidv4()}`,
    },
  ];

  const handleContinue = (shouldOpenNewPicker: boolean) => {
    const currLeg = {
      ...leg,
      numberOfPassengers: passengerCount,
      numberOfPets: petCount,
    };
    if (isRoundTrip) {
      contextDispatch({
        payload: {
          legs: [
            {
              ...currLeg,
              passengersOpen: false,
              dateOpen: shouldOpenNewPicker,
            },
            {
              ...legs[1],
              numberOfPassengers: paxCountRoundTripLeg2,
              numberOfPets: petCountRoundTripLeg2,
              passengersOpen: shouldOpenNewPicker,
            },
          ],
        },
        type: 'replaceLegsRoundTrip',
      });
    } else {
      updateLeg(
        {
          ...currLeg,
          passengersOpen: false,
          dateOpen: shouldOpenNewPicker,
        },
        legNumber,
      );
    }
  };

  useOnClickOutside(popupRef, () => handleContinue(false));

  return (
    <PassengerPickerContainer>
      {leg.passengersOpen && (
        <PickerWrapper ref={popupRef}>
          <CustomSubpage
            width="380px"
            action={() => handleContinue(true)}
          >
            <>
              {isRoundTrip && !samePaxPetSwitchValue && (
                <ChipContainer>
                  <Chip
                    data-name="Chip"
                    isSolid
                    isUpperCase
                  >
                    {'DEPART'}
                  </Chip>
                </ChipContainer>
              )}
              {pickers.map((picker, i) => {
                const {
                  image,
                  count,
                  setter,
                  stepper,
                  title,
                  uid,
                } = picker;
                return (
                  <PickerGroup key={uid}>
                    <PickerContainer>
                      <TextGroupIconText
                        headerSize={
                          width > 1000 ? undefined : '16px'
                        }
                        headline={title}
                        icon={
                          <TitleIconContainer
                            width="64px"
                            height="64px"
                            image={image.url}
                          />
                        }
                      />
                      <MinimalStepper
                        count={count}
                        setCount={setter}
                        limits={stepper}
                      />
                    </PickerContainer>
                    <Divider
                      color="disabled"
                      spacing={
                        i !== pickers.length - 1 ? 16 : 20
                      }
                    />
                  </PickerGroup>
                );
              })}
              {isRoundTrip && !samePaxPetSwitchValue && (
                <>
                  <ChipContainer>
                    <Chip
                      data-name="Chip"
                      isSolid
                      isUpperCase
                    >
                      {'RETURN'}
                    </Chip>
                  </ChipContainer>
                  {roundTripReturnLegPickers.map(
                    (picker, i) => {
                      const {
                        image,
                        count,
                        setter,
                        stepper,
                        title,
                        uid,
                      } = picker;
                      return (
                        <PickerGroup key={uid}>
                          <PickerContainer>
                            <TextGroupIconText
                              headerSize={
                                width > 1000
                                  ? undefined
                                  : '16px'
                              }
                              headline={title}
                              icon={
                                <TitleIconContainer
                                  width="64px"
                                  height="64px"
                                  image={image.url}
                                />
                              }
                            />
                            <MinimalStepper
                              count={count}
                              setCount={setter}
                              limits={stepper}
                            />
                          </PickerContainer>
                          <Divider
                            color="disabled"
                            spacing={
                              i !==
                              roundTripReturnLegPickers.length -
                                1
                                ? 16
                                : 20
                            }
                          />
                        </PickerGroup>
                      );
                    },
                  )}
                </>
              )}
              {isRoundTrip && (
                <SwitchContainer>
                  <Typography variant="body02">
                    Same passengers and pets for all flights
                  </Typography>
                  <Switch
                    checked={samePaxPetSwitchValue}
                    onChange={() => {
                      if (!samePaxPetSwitchValue) {
                        setPaxCountRoundTripLeg2(
                          passengerCount,
                        );
                        setPetCountRoundTripLeg2(petCount);
                      }
                      setSamePaxPetSwitchValue(
                        !samePaxPetSwitchValue,
                      );
                    }}
                  />
                </SwitchContainer>
              )}
            </>
          </CustomSubpage>
        </PickerWrapper>
      )}
    </PassengerPickerContainer>
  );
};

export default PassengerPickerV3;
