import * as React from 'react';
import {
  Navigate,
  useSearchParams,
} from 'react-router-dom';
import { IAuthRoute } from '../routesTypes';
import { isAuthenticated } from '@authService/authService';
import { paths } from '../routerPaths';
import { saveDataInSessionStorage } from '@components/utils/storage';
import { useLocation } from 'react-router-dom';

const AuthRoute: React.FC<IAuthRoute> = ({
  linkedRoute,
}: IAuthRoute) => {
  const location = useLocation();
  const [params, setParams] = useSearchParams();
  const { component: Component } = linkedRoute;

  const isAccesible = () =>
    !linkedRoute.isPrivate ||
    (linkedRoute.isPrivate && isAuthenticated());

  if (
    location.pathname === paths.SIGNIN &&
    isAuthenticated()
  ) {
    return (
      <Navigate
        to={paths.HOME}
        replace
        state={{ from: location }}
      />
    );
  }

  if (isAccesible()) {
    if (
      linkedRoute.path.includes('newsDetails') &&
      params.get('id')
    ) {
      /*
                TODO:
                This is the result of a request from iOS and
                should be resolved at a later date
                
                see `routerPaths.tsx` for NEWS_DETAILS_IOS
            */
      const url = JSON.parse(
        JSON.stringify({
          id: params.get('id') ?? '',
        }),
      );
      return (
        <Navigate
          to={`/news/${url.id}`}
          replace
          state={{ from: location }}
        />
      );
    } else {
      return <Component />;
    }
  }

  let path = paths.SIGNIN;
  if (!isAccesible()) {
    const pathToAttach = location.pathname + location.search;

    if (pathToAttach.includes('redirect_route') || pathToAttach.includes('?')) {
      const encodedUrl: string = encodeURIComponent(pathToAttach);
      const notOnLoginScreen = location.pathname !== paths.SIGNIN &&
      location.pathname !== paths.SIGNOUT;

      if (notOnLoginScreen) {
        path = path + `?redirect_route=${encodedUrl}`;
      } else if (pathToAttach.includes('redirect')) {
        path = pathToAttach;
      }
    }
  }

  return (
    <Navigate
      to={path}
      replace
      state={{ from: location }}
    />
  );
};

export default React.memo(AuthRoute);
