/*
  This file should be used to globally reference feature flags. This will
  allow us to easily change the name of a feature flag in one place, and
  have it update everywhere else in the app.

  Please also comment on the same line when the cleanup of the flag should occur.
*/

export enum FeatureFlags {
  ACTIVITY = 'activity',
  DASHBOARD_REDESIGN = 'dashboard_redesign',
  NATIVE_MANAGE_CREDIT_CARDS = 'native_manage_credit_cards',
  USE_ALTERNATE_AIRPORTS = 'use_alternate_airports',
  USE_GQL_ACCOUNT_ALERTS = 'use_gql_account_alerts',
  USE_NATIVE_HUB = 'use_native_hub',
  USE_NATIVE_NOTIFICATIONS = 'use_native_notifications',
  USE_NATIVE_MANAGE_PAYMENT_METHODS = 'use_native_manage_payment_methods',
  USE_NATIVE_MY_TRIPS = 'use_native_my_trips',
  USE_GQL_SEARCH_RESULTS = 'use_gql_search_results',
  USE_TOD = 'use_tod',
  USE_GQL_TERMS_OF_SERVICE = 'use_gql_tos',
  USE_PREMIUM_JET = 'use_premium_jet',
}
