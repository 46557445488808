import { solidColors } from '@constants/styles/colors.constants';
import styled from 'styled-components';

export const LuggageItemsContainer = styled.div<{
  $isVertical: boolean;
  $minHeight?: string | undefined;
}>`
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  ${({ $isVertical, $minHeight }) => {
    if (!$isVertical) {
      let minHeightStyles = '';
      const isVerticalStyles = `
                flex-direction: column;
            `;
      if ($minHeight) {
        minHeightStyles = `
                    min-height: ${$minHeight};
                    > div {
                        padding: 13px 0;
                        border-bottom: 1px solid
                            ${solidColors.mediumGray.color};
                    }
                `;
      }
      return isVerticalStyles + minHeightStyles;
    }
  }}
`;

export const AddLuggageButtonContainer = styled.span`
  display: flex;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  align-items: flex-end;
  margin-top: 10px;
`;

export const LuggageWrapperContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LuggageInputStyle = styled.div`
  padding-top: 16px;
  min-width: 160px;
  padding-left: 2px;
  padding-right: 2px;
`;
