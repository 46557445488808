import styled from 'styled-components';
import { Button, Typography } from '@atoms';
import { solidColors } from '@constants/styles/colors.constants';

export const StyledSubPageCheckWireTransfer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  border-radius: 4px;
  background-color: #ffffff;
  position: relative;
  height: fit-content;
  margin: 0px 0px;
`;
export const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  border-radius: 4px;
  background-color: #ffffff;
  position: relative;
  height: fit-content;
  min-width: 50%;
  margin: 0px 0px;
`;

export const StyledTitleContainer = styled.div`
  margin: 10px 0px 20px 0px;
`;

export const StyledTitle = styled(Typography)``;

export const StyledLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  min-width: 250px;
`;

export const StyledInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 12px 12px;
  width: 100%;
`;

export const StyledCustomCheckbox = styled.div`
  margin: 0px 0px 12px;
`;

export const StyledTextGroupIconText = styled.div`
  padding: 12px 24px;
  border-bottom: 1px solid ${solidColors.mediumGray.color};
  width: 100%;

  &:first-of-type {
    border-top: 1px solid ${solidColors.mediumGray.color};
  }
`;

export const StyledAddEditButton = styled(Button)`
  padding-left: 22px;
  padding-right: 1rem;
  margin: 10px 0px;
  background-color: ${solidColors.upWhite.color};
  color: ${solidColors.upBlue.color};
`;
