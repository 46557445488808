import { Link } from '@components/molecules/TextGroupBodyCopyLink/TextGroupCopyLink.interfaces';
import styled from 'styled-components';

const StyledA = styled.a`
  :hover {
    text-decoration: underline;
  }
`;

const BuildHyperlinkedText = (
  links: Link[] | undefined = [],
  copy: string | undefined = '',
  prefix = 'link',
): JSX.Element => {
  const LIMITER = '_:[replace]:_';
  const formattedLinks = links.map((l, i) => {
    const { data, type } = l.action;
    const linkText = copy.substring(l.start, 1 + l.end);
    return {
      text: linkText,
      link: (
        <StyledA
          data-testid={`${prefix}-${i}-${linkText}`}
          onClick={(e) => {
            e.stopPropagation();
            if (type === 'phone') {
              window.open(`tel:${data}`, '_self');
            } else {
              if (data) window.location.href = data;
            }
          }}
        >
          {linkText}
        </StyledA>
      ),
    };
  });
  const replacementTextList = formattedLinks
    .reduce((accumulator, link) => {
      return accumulator.replace(link.text, LIMITER);
    }, copy)
    .split(LIMITER);

  const output = replacementTextList.map(
    (value: string, index: number) => {
      if (index in formattedLinks) {
        return [value, formattedLinks[index].link];
      }
      return [value];
    },
  );
  return <>{output.flat()}</>;
};

export default BuildHyperlinkedText;
