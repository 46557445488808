import CarouselListLine from '@components/molecules/CarouselListLine/CarouselListLine';
import CreditCard from '@components/molecules/CreditCard/CreditCard';
import { ISubPageManageCreditCards } from './SubPageManageCreditCards.interfaces';
import { CCContainer, SubPageContainer } from './SubPageManageCreditCards.styles';
import { Elevation, Link } from '@wheelsup/wu-react-components';
import { useNavigate } from 'react-router-dom';

export default function SubPageManageCreditCards({
  id,
  creditCards,
  button,
}: ISubPageManageCreditCards) {
  const navigate = useNavigate();

  const handleNewCc = () => {
    navigate('/account/manage-credit-cards/credit-card?action=add');
  };

  return (
    <SubPageContainer>
      {creditCards && (
        <Elevation
          variant='raisedLevel2'
          data-name="SubPageManageCreditCards"
          padding={32}
        >
          {creditCards.map((cc, i) => (
            <CCContainer key={i}>
              <CreditCard
                {...cc}
                isFirst={i === 0}
                isLast={i === creditCards.length - 1}
              />
            </CCContainer>
          ))}
        </Elevation>
      )}
      <Link
        isUpperCase
        onPress={handleNewCc}
      >
        Add a New Card
      </Link>
    </SubPageContainer>
  );
}
