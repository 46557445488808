import React, { useState } from 'react';
import {
  INavigationSectionToggleTwo,
  INavigationSectionToggleTwoItem,
} from './NavigationSectionToggleTwo.interface';
import { SegmentedNavigation } from '@molecules';
import styled from 'styled-components';
import { trackAnalytics } from '@services/mixpanelService/mixpanel.service';

const ToggleContainer = styled.div`
  margin: 0 40px 20px;
`;

function NavigationSectionToggleTwo({
  items,
  children,
}: INavigationSectionToggleTwo) {
  const elements = items.map(
    (
      Item: INavigationSectionToggleTwoItem,
      index: number,
    ) => ({
      label: Item.title,
      selectedItem: index,
    }),
  );
  const [selectedSegment, setSelectedSegment] = useState(
    elements[0],
  );

  function handleSegmentClick(selectedSegment: number) {
    const segment = elements[selectedSegment];
    setSelectedSegment(segment);
    if (items[selectedSegment]?.analytics) {
      trackAnalytics(items[selectedSegment].analytics);
    }
  }

  return (
    <>
      <ToggleContainer>
        <SegmentedNavigation
          segments={elements}
          selectedSegment={selectedSegment}
          onClick={handleSegmentClick}
        />
      </ToggleContainer>
      {children[selectedSegment.selectedItem]}
    </>
  );
}

export default NavigationSectionToggleTwo;
