import { MainContainer } from './ImageTitleSubtitleStepperListItemView.styles';
import { IImgTitleCopyStepper } from './ImageTitleSubtitleStepperListItemView.interfaces';
import TextGroupIconText from '@molecules/TextGroupIconText/TextGroupIconText';
import TextGroupIconTextVertical from '@molecules/TextGroupIconTextVertical/TextGroupIconTextVertical';
import TitleIconContainer from '@molecules/TitleIconContainer/TitleIconContainer';
import { NumberStepper } from '@molecules';
import { ColorStyleTranslation } from '@components/utils/newStyleTranslation';

const ImageTitleSubtitleStepperListItemView = ({
  title,
  subtitle,
  image,
  stepper,
  getDataCount,
  hasMaxCapacity,
  headerWeight,
  headerSize,
  copySize,
  isSpecial,
  buttonSize,
  isWithinChangeWindow,
  isStepperVertical = false,
}: IImgTitleCopyStepper) => {
  const TextGroupElement = isStepperVertical
    ? TextGroupIconTextVertical
    : TextGroupIconText;
  return (
    <MainContainer
      data-name="ImageTitleSubtitleStepperListItemView"
      $isVertical={isStepperVertical}
    >
      <TextGroupElement
        headline={title}
        headerWeight={headerWeight}
        headerSize={headerSize}
        copySize={copySize}
        copy={subtitle}
        isSpecial={isSpecial}
        icon={
          image?.url ? (
            <TitleIconContainer
              width="64px"
              height="64px"
              image={image.url}
              tintColor={ColorStyleTranslation(
                image.tintColor as string,
              )}
            />
          ) : null
        }
      />
      <NumberStepper
        headline=""
        numberPicker={stepper}
        getDataCount={getDataCount}
        hasMaxCapacity={hasMaxCapacity}
        buttonSize={buttonSize}
        isWithinChangeWindow={isWithinChangeWindow}
      />
    </MainContainer>
  );
};

export default ImageTitleSubtitleStepperListItemView;
