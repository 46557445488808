import { Typography } from '@atoms';
import { IHelper } from './InputHelper.interfaces';
import {
  StyledCopyAction,
  StyledInputHelper,
} from './InputHelper.styles';
import { typography } from '@constants/styles/typography.constants';
import { solidColors } from '@constants/styles/colors.constants';

const CustomInputHelper = ({
  leadingHelperCopy,
  trailingHelperCopy,
  leadingHelperAction,
  trailingHelperAction,
  error,
}: IHelper) => {
  return (
    <StyledInputHelper>
      {error ? (
        <Typography
          variant={typography.caption}
          color={solidColors.lava}
        >
          {error.message ?? 'Error'}
        </Typography>
      ) : (
        <>
          <StyledCopyAction
            valid={!!leadingHelperAction}
            onClick={leadingHelperAction?.actionMethod}
          >
            <Typography
              variant={typography.caption}
              color={solidColors.darkGray}
            >
              {leadingHelperCopy}
            </Typography>
          </StyledCopyAction>
          <StyledCopyAction
            valid={!!trailingHelperAction}
            onClick={trailingHelperAction?.actionMethod}
          >
            <Typography
              variant={typography.caption}
              color={solidColors.darkGray}
            >
              {trailingHelperCopy}
            </Typography>
          </StyledCopyAction>
        </>
      )}
    </StyledInputHelper>
  );
};

export default CustomInputHelper;
