import styled from 'styled-components';
import { solidColors } from '@constants/styles/colors.constants';

import { Button } from '@atoms';
import { theme } from '@constants/styles/theme.constants';

export const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  background-color: #ffffff;
  position: relative;
  height: fit-content;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.08),
    0px 2px 6px 1px rgba(0, 0, 0, 0.12);
`;

export const StyledTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

export const StyledDivision = styled.div`
  width: 100%;
  border-top: solid 1px ${solidColors.mediumGray.color};
`;

export const StyledHoldContainer = styled.div`
  width: 100%;
  > * {
    padding: 20px 0px 0px 0px;
  }
`;

export const StyledPaymentRulesContainer = styled.div`
  width: 100%;
  padding: 20px 0px 0px 0px;
`;

export const StyledButton = styled(Button)`
  width: 100%;
  margin-top: 15px;
`;

export const StyledTextGroupIconText = styled.div`
  padding: 12px 24px;
  border-bottom: 1px solid ${solidColors.c9.color};
  width: 100%;

  &:first-of-type {
    border-top: 1px solid ${solidColors.c9.color};
  }
`;

export const LegalCheckboxesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;

  @media (max-width: ${theme.breakpoints.values.md}px) {
    gap: 24px;
  }

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    gap: 16px;
  }
`;

export const PaymentRulesContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Divider = styled.div`
  width: 100%;
  border-top: 1px solid ${solidColors.mediumGray.color};
  margin: 24px 0;
`;

export const LegalContainer = styled.div`
  margin-bottom: 24px;
`;
