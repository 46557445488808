import { useEffect, useState } from 'react';
import { ContainerWrapper } from './Footer.style';
import FooterWebOnly from '@components/organisms/FooterWebOnly/FooterWebOnly';
import { useAppSelector } from '@app/hooks';
import { getFromLocalStorage } from '@components/utils/storage';

const Footer = () => {
  const [footer, setFooter] = useState<any>();
  const [path, setPath] = useState<string>(
    window.location.pathname,
  );
  const { footerData } = useAppSelector(
    (state) => state.footerData,
  );
  const exemptPages = ['signin'];
  useEffect(() => {
    if (path.length == 1) {
      setPath('dashboard');
    } else {
      setPath(window.location.pathname.split('/')[1]);
    }
    const footerDataFromLocal = getFromLocalStorage(
      'footerData',
    );
    setFooter(
      footerDataFromLocal ?? footerData ?? undefined,
    );
  }, [footerData]);

  const shouldRenderFooter =
    !exemptPages.includes(path) &&
    footer &&
    footer.data != undefined;
  return (
    <>
      <ContainerWrapper id="footer">
        {shouldRenderFooter && (
          <FooterWebOnly {...footer.data} />
        )}
      </ContainerWrapper>
    </>
  );
};

export default Footer;
