import { IContainerEmptyColumns } from './ContainerEmptyColumns.interfaces';
import { StyledInnerContainer } from './ContainerEmptyColumns.styles';
import React, { ReactElement } from 'react';

const ContainerEmptyColumns = ({
  order,
  className,
  children,
}: IContainerEmptyColumns) => {
  const display = order && 'flex';
  const renderChildren = () => {
    if (Array.isArray(children)) {
      return (
        <StyledInnerContainer
          display={display && display}
          columnsType={order && order}
        >
          {children}
        </StyledInnerContainer>
      );
    } else if (children) {
      return React.cloneElement(children as ReactElement);
    } else return null;
  };

  return (
    <div className={className}>{renderChildren()}</div>
  );
};

export default ContainerEmptyColumns;
