import { InputCustomProps } from '@components/types';
import { solidColors } from '@constants/styles/colors.constants';
import {
  makeStyles,
  TextareaAutosize,
  TextField,
} from '@library';
import { Theme, withStyles } from '@material-ui/core';
import styled from 'styled-components';

export const CustomTextField = styled(TextField)<{
  $width?: string;
}>`
  ${({ $width }) => {
    return $width ? `width: ${$width};` : '';
  }}
`;

// Real annoying overrides to match the SMS and Phone mocks
export const CustomCssTextField = withStyles(
  ({ palette }) => ({
    root: {
      fontFamily: 'Rubik, sans-serif',
      color: 'palette.text.disabled',
      '& label': {
        color: solidColors.midnight.color,
      },
      '& label.Mui-focused': {
        color: solidColors.midnight.color,
      },
      '& MuiInput-underline, .Mui-disabled:before': {
        border: 'none',
      },
      '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottomColor: palette.info.dark,
      },
      '& .MuiInput-underline:before, .MuiInput-underline:after': {
        borderBottomColor: palette.info.dark,
      },
      '&  .MuiInput-underline, .Mui-error:after': {
        borderBottomColor: palette.error.light,
      },
    },
  }),
)(CustomTextField);

export const CustomCssTextareaAutosize = styled(
  TextareaAutosize,
)<InputCustomProps>`
  font-family: 'Mikro', sans-serif;
  width: 15rem;
`;

export interface IInputBase {
  error?: boolean;
  paddingLeft?: boolean;
}

export const inputUseStyles = makeStyles(
  (theme: Theme) => ({
    label: {
      color: ({ error = false }: IInputBase) =>
        error
          ? `${solidColors.darkGray.color} !important`
          : theme.palette.text.disabled,
      paddingLeft: ({ paddingLeft = false }: IInputBase) =>
        paddingLeft ? '2em' : '0em',
    },
    helperText: {
      color: `${theme.palette.text.disabled} !important`,
    },
    input: {
      color: solidColors.midnight.color,
      paddingLeft: ({ paddingLeft = false }: IInputBase) =>
        paddingLeft ? '2em' : '0em',
    },
    shrink: {
      paddingLeft: '0em !important',
    },
  }),
);
