import { putPassenger } from '@components/organisms/SubPagePassengerAddEdit/SubPagePassengerAddEdit.slice';
import { IPassengerLeg } from '@components/types';
import {
  getFromLocalStorage,
  saveDataInLocalStorage,
} from '@components/utils/storage';
import { updatePassengerLegs } from '@features/Fly/FlySearchTripDetailsSlice';
import { Middleware } from '@reduxjs/toolkit';

// Importing RootState and AppDispatch types here would cause a circular dependency.
export const passengerAddEditMiddleware: Middleware<
  {},
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any, // RootState
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any // AppDispatch
> = () => (next) => (action) => {
  if (action.type !== putPassenger.fulfilled.type) {
    return next(action);
  }

  const selectedPassengersLegs: IPassengerLeg[] = getFromLocalStorage(
    'selectedPassengersLegs',
  );

  if (
    !selectedPassengersLegs ||
    selectedPassengersLegs.length === 0
  ) {
    return next(action);
  }

  const updatedPassengerLegs = updatePassengerLegs(
    selectedPassengersLegs,
    action.payload.data.passenger,
  );

  saveDataInLocalStorage(
    'selectedPassengersLegs',
    updatedPassengerLegs,
  );

  return next(action);
};
