import {
  AccordionProps,
  AlertSummaryProps,
} from './CardAccordionHighMidPriorityAlert.interfaces';
import {
  BoxCenterStyled,
  AccordionHeadlineSpacer,
  EmbeddedViewContainer,
  StyledCardAccordionHighMidPriorityAlert,
  StyledAccordion,
  CenteredAccordionSummary,
  StyledIcon,
  ButtonStyled,
  AccordionDivider,
} from './CardAccordionHighMidPriorityAlert.styles';
import { ImageImgix, Typography } from '@atoms';
import getServerAction from '@atoms/Button/getServerAction';
import { solidColors } from '@constants/styles/colors.constants';
import { typography } from '@constants/styles/typography.constants';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4} from 'uuid';

const AccordionHeadline = (props: AlertSummaryProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSurfaceAction = () => {
    if (props.surfaceAction) {
      const action =
        props.button?.action ?? props.surfaceAction ?? {};
      getServerAction({
        navigate,
        dispatch,
        action: action,
        buttonAction: action,
      });
    }
  };

  return (
    <CenteredAccordionSummary
      expandIcon={
        props.dropDownImage && (
          <ImageImgix
            src={props.dropDownImage.url}
            alt="dropdown icon"
            width={20}
            height={20}
            borderRadius="0"
          />
        )
      }
      onClick={handleSurfaceAction}
    >
      <BoxCenterStyled>
        {props.image && (
          <StyledIcon
            src={props.image.url}
            filter={props.image.tintColor}
            alt="leading icon"
            width={props.imageSize ? props.imageSize : 32}
            height={props.imageSize ? props.imageSize : 32}
            borderRadius="0"
          />
        )}
        <AccordionHeadlineSpacer>
          <Typography
            variant={typography.heading04Large}
            color={solidColors.midnight}
            align="left"
            truncate={1}
          >
            {props.title}
          </Typography>
          <Typography
            variant={typography.body1}
            color={solidColors.darkGrey}
          >
            {props.body}
          </Typography>
          {props.button && (
            <ButtonStyled
              title={props.button.title}
              styleType={'card_primary'}
              action={props.button.action}
              textTransform="none"
            />
          )}
        </AccordionHeadlineSpacer>
      </BoxCenterStyled>
    </CenteredAccordionSummary>
  );
};

export default function CardAccordionHighMidPriorityAlert({
  title,
  copy,
  image,
  buttonOne,
  dropDownImage,
  isAccordionDisabled,
  isExpandedByDefault,
  children,
  imageSize,
  surfaceAction,
  id
}: AccordionProps) {
  const ref = useRef<HTMLDivElement>(null);
  const [expanded, setExpanded] = useState(
    isExpandedByDefault,
  );
  const [inCarousel, setInCarousel] = useState(false);

  const handleChange = () => {
    if (!isAccordionDisabled && dropDownImage) {
      setExpanded(!expanded);
    }
  };

  useEffect(() => {
    if (ref.current?.offsetParent?.className.includes('CarouselSmallArrowWebOnlySlide-slide')) {
      setInCarousel(true);
    }
  }, [ref, setInCarousel]);

  return (
    <StyledCardAccordionHighMidPriorityAlert
      ref={ref}
      data-name="CardAccordionHighMidPriorityAlert"
      $inCarousel={inCarousel}
      id={id ? id : uuidv4()}
    >
      <StyledAccordion
        disabled={false}
        defaultExpanded={isExpandedByDefault}
        expanded={expanded}
        onChange={handleChange}
      >
        <AccordionHeadline
          title={title}
          body={copy}
          button={buttonOne}
          dropDownImage={dropDownImage}
          image={image}
          imageSize={imageSize}
          surfaceAction={surfaceAction}
        />
        <AccordionDivider />
        <EmbeddedViewContainer>
          {children}
        </EmbeddedViewContainer>
      </StyledAccordion>
    </StyledCardAccordionHighMidPriorityAlert>
  );
}
