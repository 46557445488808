import { solidColors } from '@constants/styles/colors.constants';
import styled from 'styled-components';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
} from '@library';
import { Icons } from '@library';

export const StyledAccordion = styled(Accordion)<{
  $isHeader?: boolean;
}>`
  box-shadow: 0 -1px 0 0 ${solidColors.mediumGray.color};
  ${({ $isHeader }) =>
    $isHeader &&
    `background-color: ${solidColors.midnight.color};`}
  &:before {
    content: none;
  }

  &.reverse {
    display: flex;
    flex-direction: column-reverse;

    &.Mui-expanded {
      &:last-child {
        padding-bottom: 0px;
      }
    }

    &:last-child:after {
      opacity: 0;
    }
  }

  &.Mui-expanded {
    &:last-child {
      padding-bottom: 20px;
    }
  }

  &:last-child:after {
    content: '';
    bottom: -1px;
    left: 0;
    right: 0;
    height: 1px;
    content: '';
    opacity: 1;
    /* position: absolute; */
    background-color: ${solidColors.mediumGray.color};
  }
`;

export const StyledAccordionSummary = styled(
  AccordionSummary,
)`
  padding: 0;

  &.cta {
    display: flex;
    align-items: center;
    text-align: center;

    .MuiAccordionSummary-content {
      flex-grow: 0;
      margin: 8px 0;
    }
  }

  &.menu {
    padding: 0px 30px;
    .MuiAccordionSummary-content {
      margin: 0;
    }
  }

  .MuiAccordionSummary-content {
    margin: 32px 0;
  }

  .MuiSvgIcon-root {
    fill: ${solidColors.upBlue.color};
  }
`;

export const StyledAccordionDetails = styled(
  AccordionDetails,
)`
  padding: 0;
`;

export const StyledGrid = styled(Grid)`
  width: 100%;
`;

export const StyledExpandIcon = styled(Icons.ExpandMore)`
  fill: ${solidColors.upWhite.color} !important;
`;
