import styled from 'styled-components';
import { Button, ImageImgix, Typography } from '@atoms';

import { solidColors } from '@constants/styles/colors.constants';
import { theme } from '@constants/styles/theme.constants';
import { Elevation } from '@wheelsup/wu-react-components';

export const CardMainContainer = styled(Elevation).attrs({
  variant: 'raisedLevel2',
  padding: 0,
})`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #ffffff;
  position: relative;
  width: 21.25rem;
  height: inherit;

  .titleTextStyles {
    margin-top: 4px;
  }

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    width: 300px;
  }
`;

export const CardContainer = styled.div`
  margin: 0;
`;

export const CardBody = styled(Typography)`
  margin-top: 8px;
  min-height: 60px;
`;

export const StyledImigixTopCard = styled(ImageImgix)`
  overflow: hidden;
  width: 100%;
  height: 200px;
  padding: 0;
  margin: 0;
  background-size: cover;
  border-radius: 5px 5px 0 0;
  background-color: ${solidColors.mediumGray.color};
`;

export const BottomCard = styled.div`
  width: 100%;
  padding: 24px;

  ${(props) => props.theme.breakpoints.down('sm')} {
    padding: 24px 16px 24px 16px;
  }
`;

export const CTAButton = styled(Button)`
  margin-top: 24px;
`;
