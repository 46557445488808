import {
  Accordion,
  AccordionDetails,
} from '@components/library';
import { theme } from '@constants/styles/theme.constants';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/styles';
import styled from 'styled-components';

export const StyledAccordionBase = styled(Accordion)`
  width: 100%;
  height: fit-content;
  background-color: #ffffff;
  box-shadow: 0px 4px 8px 2px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
`;

export const StyledAccordionSummaryContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;
`;

export const StyledTypographyContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  margin-left: 12px;
`;

export const StyledAccordionDetails = styled.div`
  padding: 16px 32px;
`;

export const ExpandIcon = styled(ExpandMore)``;

export const BorderLine = styled.hr`
  display: block;
  height: 1px;
  color: #d1d1d1;
  width: 95%;
`;

export const useStyles = makeStyles(() => ({
  root: {
    padding: '0 !important',
    display: 'flex',
    flexDirection: 'column',
  },
}));
