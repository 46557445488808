import { IPageState } from '@components/types';
import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import {
  endpoints,
  getAuthHeaders,
  pegasusClient,
} from '@services/apiService';
import qs from 'qs';

interface IProfilingState extends IPageState {
  slide: boolean;
}

const initialState: IProfilingState = {
  isLoading: false,
  error: null,
  data: undefined,
  slide: false,
};

interface IAnswer {
  q: string;
  a: string[];
}

export const submitAnswers = createAsyncThunk(
  'progressiveProfiling/progressiveProfilingSubmit',
  async (answer: IAnswer) => {
    const { q, a } = answer;
    const headers = getAuthHeaders();
    try {
      const res = await pegasusClient.post(
        endpoints.progressiveProfilingSubmit,
        qs.stringify({
          question: { question: q, answers: a },
        }),
        {
          headers: headers,
        },
      );
      return res;
    } catch (err) {
      return err;
    }
  },
);

export const slideSlice = createSlice({
  name: 'slideCard',
  initialState,
  reducers: {
    slideCard: (state, action: PayloadAction<boolean>) => {
      state.slide = action.payload;
    },
  },
  extraReducers: {},
});

const progressiveProfilingSlice = createSlice({
  name: 'progressiveProfiling',
  initialState,
  reducers: {
    submit(state, action) {
      const { payload } = action;
      state.data = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(submitAnswers.pending, (state) => {
      state.isLoading = true;
      state.slide = false;
    });
    builder.addCase(submitAnswers.fulfilled, (state) => {
      state.isLoading = false;
      state.slide = true;
    });
    builder.addCase(
      submitAnswers.rejected,
      (state, action) => {
        const { payload } = action;
        state.isLoading = false;
        state.error = payload;
        state.slide = true;
      },
    );
  },
});

export const { slideCard } = slideSlice.actions;

export const CarouselReducer =
  progressiveProfilingSlice.reducer;

export default CarouselReducer;
