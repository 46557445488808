import { Typography } from '@atoms';
import styled from 'styled-components';

export const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: 360px;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05);
  position: relative;
  height: fit-content;
  padding: 8px 1rem;
`;

export const StyledFlightHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 0px 10px;
`;
export const TimeContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 115px;
`;

export const StyledFlightInfo = styled.div`
  display: grid;
  grid-template-columns: 35px auto;
  grid-template-rows: auto;
  margin-bottom: 0.6rem;
  position: relative;

  & > *:last-child {
    grid-column-start: 2;
  }
`;

export const DottedLine = styled.span`
  justify-self: center;
  width: 2px;
  height: 2rem;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23D0D0D0FF' stroke-width='4' stroke-dasharray='1%2c3' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e");
`;

export const StyledAirportContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const StyleFlightLabel = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
`;

export const StyledTimeTypography = styled(Typography)`
  min-width: 110px;
  margin-left: 5px;
`;

export const StyleContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TimeRangeContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const FuelContainer = styled.div`
  width: 60%;
`;
