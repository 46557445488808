import { ICardAircraftDetailsSubpage } from './CardAircraftDetailsSubpage.interfaces';
import {
  CardBody,
  ItemsContainer,
  MobileItemContainer,
  MobileItemsContainer,
  StyledBreak,
  StyledCard,
  TextContainer,
  useStyles,
} from './CardAircraftDetailsSubpage.styles';
import { CarouselSmallArrowSelectedImageWebOnly } from '@organisms';
import { Typography } from '@atoms';
import { typography } from '@constants/styles/typography.constants';
import { solidColors } from '@constants/styles/colors.constants';
import { useViewport } from '@hooks';
import { theme } from '@constants/styles/theme.constants';
import { Dialog } from '@components/library';
import { useDispatch } from 'react-redux';
import { handleShowAircraftDetails } from './AircraftDetailsSlice';
import { useAppSelector } from '@app/hooks';

const CardAircraftDetailsSubpage = ({
  id,
  images,
  headline,
  copy,
  items,
}: ICardAircraftDetailsSubpage) => {
  const dispatch = useDispatch();
  const { width } = useViewport();
  const dialogStyles = useStyles();
  const { open } = useAppSelector(
    (state) => state.handleShowAircraftDetails,
  );

  const isMobile = width < theme.breakpoints.values.sm;
  const itemsMid = Math.ceil(items.length / 2);
  const itemsLeft = items.slice(0, itemsMid);
  const itemsRight = items.slice(itemsMid, items.length);
  const itemsArray = [itemsLeft, itemsRight];

  const handleClose = () => {
    dispatch(handleShowAircraftDetails());
    return;
  };

  return (
    <Dialog
      open={open}
      classes={{
        paper: dialogStyles.root,
      }}
      onClose={handleClose}
    >
      <StyledCard>
        <CarouselSmallArrowSelectedImageWebOnly
          images={images}
          closeButtonAction={handleClose}
        />
        <CardBody>
          {images.length > 1 && <StyledBreak />}
          <TextContainer>
            <Typography
              variant={typography.heading02Large}
              color={solidColors.midnight}
            >
              {headline}
            </Typography>
            <Typography
              variant={typography.body1}
              color={solidColors.darkGray}
            >
              {copy}
            </Typography>
          </TextContainer>
          <ItemsContainer>
            {!isMobile
              ? items.map((item, idx) => {
                  const key = `item-${idx}`;
                  return (
                    <div key={key}>
                      <Typography
                        variant={typography.caption}
                        color={solidColors.darkGray}
                      >
                        {item.title}
                      </Typography>
                      <Typography
                        variant={typography.body2}
                        color={solidColors.midnight}
                      >
                        {item.detail}
                      </Typography>
                    </div>
                  );
                })
              : itemsArray &&
                itemsArray.map((itemArr, index) => {
                  const key = `itemArr-${index}`;
                  return (
                    <MobileItemsContainer key={key}>
                      {itemArr.map((item, idx) => {
                        const key = `item-${index}-${idx}`;
                        return (
                          <MobileItemContainer key={key}>
                            <Typography
                              variant={typography.caption}
                              color={solidColors.darkGray}
                            >
                              {item.title}
                            </Typography>
                            <Typography
                              variant={typography.body2}
                              color={solidColors.midnight}
                            >
                              {item.detail}
                            </Typography>
                          </MobileItemContainer>
                        );
                      })}
                    </MobileItemsContainer>
                  );
                })}
          </ItemsContainer>
        </CardBody>
      </StyledCard>
    </Dialog>
  );
};

export default CardAircraftDetailsSubpage;
