import styled from 'styled-components';
import { Container } from '@library';

export const ListItemsDiv = styled(Container)`
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 2rem;

  ${(props) => props.theme.breakpoints.down('lg')} {
    grid-template-columns: 1fr 1fr 1fr;
  }

  ${(props) => props.theme.breakpoints.down('md')} {
    grid-template-columns: 1fr 1fr;
  }

  ${(props) => props.theme.breakpoints.down('sm')} {
    grid-template-columns: 1fr;
  }
`;
