interface IObject {
  [key: string]: string;
}

export const getKeyByValue = (
  object: IObject,
  value: string,
) => {
  return Object.keys(object).find(
    (key) => object[key] === value,
  );
};
