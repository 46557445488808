const copyToClipboard = async (
  text: string | undefined,
) => {
  if (text) {
    try {
      await navigator.clipboard.writeText(text);
    } catch (error) {
      console.log('Failed to copy to clipboard', error);
    }
  }
};

export default copyToClipboard;
