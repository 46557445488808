import { Icons } from '@components/library';
import styled from 'styled-components';

export const LineItemContainer = styled.div`
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    height: auto;
    padding: 4px 0px;
    margin: 0;
    width 100%;

    > * {
        padding: 0px;
    }

    > :first-child {
        padding-right: 30px;
    }
`;

export const LeftItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    height: auto;
    padding: 0px;
    margin: 0;
    width 100%;
`;

export const ChevronStyled = styled(
  Icons.ArrowForwardIosRounded,
)`
  justify-content: end;
  height: 25px;
  padding-top: 10px;
  margin-right: -8px;
`;
