import { pegasusClient } from '@services/apiService';
import {
  combineReducers,
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit';
import {
  dataSliceOptions,
  ISliceFactoryArgs,
} from '@services/sliceFactoryService/sliceFactoryService.types';

export const PopUpStatusInitialState = {
  isOpen: false,
};

const PopUpStatusSlice = createSlice({
  name: 'confirmationPopUpStatus',
  initialState: PopUpStatusInitialState,
  reducers: {
    setStatus(state, action) {
      const { payload } = action;
      state.isOpen = payload;
    },
  },
});

export const { setStatus } = PopUpStatusSlice.actions;

export const PopUpStatusReducer = PopUpStatusSlice.reducer;

export const confirmationPopUpInitialState = {
  dataPopUp: undefined,
  isLoadingPopUp: false,
  errorPopUp: null,
  confirmContactPhone: '',
  confirmContactEmail: '',
};

interface IProps {
  urlParams?: string;
  queryParams?: string;
  token?: string;
  type?: string;
  resPayload?: unknown;
}

export function getConfirmationPopUp({
  pageName,
  endpoint,
  version,
}: ISliceFactoryArgs) {
  return createAsyncThunk(
    `${pageName}/fetch${pageName}`,
    async (props: IProps) => {
      const {
        urlParams = '',
        queryParams = '',
        token,
      } = props;
      try {
        return (
          await pegasusClient.get(
            `${endpoint}${urlParams}?version=${version}&platform=web${queryParams}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                WUToken: token,
              },
            },
          )
        ).data;
      } catch (err) {
        return err;
      }
    },
  );
}

export function postSaveContact({
  pageName,
  endpoint,
  version,
}: ISliceFactoryArgs) {
  return createAsyncThunk(
    `${pageName}/post${endpoint}`,
    async (props: IProps) => {
      const { resPayload, token } = props;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          WUToken: token,
        },
      };
      try {
        const data = (
          await pegasusClient.post(
            `${endpoint}?version=${version}&platform=web`,
            resPayload,
            config,
          )
        ).data;
        return data;
      } catch (err) {
        return err;
      }
    },
  );
}

function confirmationPopUpFactory({
  pageName,
  endpoint,
  version,
}: ISliceFactoryArgs) {
  const getConfirmationPopUpThunk = getConfirmationPopUp({
    endpoint: endpoint,
    pageName: pageName,
    version: version,
  });

  const postSaveContactThunk = postSaveContact({
    endpoint: endpoint,
    pageName: pageName,
    version: version,
  });

  const confirmationSlice = createSlice({
    name: 'confirmationPopUpDetails',
    initialState: confirmationPopUpInitialState,
    reducers: {
      setConfirmContactEmail(state, action) {
        const { payload } = action;
        state.confirmContactEmail = payload;
      },
      setConfirmContactPhone(state, action) {
        const { payload } = action;
        state.confirmContactPhone = payload;
      },
    },
    extraReducers: (builder) => {
      builder.addCase(
        getConfirmationPopUpThunk.pending,
        (state) => {
          state.isLoadingPopUp = true;
        },
      );
      builder.addCase(
        getConfirmationPopUpThunk.fulfilled,
        (state, action) => {
          const { payload } = action;
          state.isLoadingPopUp = false;
          state.dataPopUp = payload.data;
        },
      );
      builder.addCase(
        getConfirmationPopUpThunk.rejected,
        (state) => {
          state.isLoadingPopUp = false;
        },
      );
      builder.addCase(
        postSaveContactThunk.pending,
        (state) => {
          state.isLoadingPopUp = true;
        },
      );
      builder.addCase(
        postSaveContactThunk.fulfilled,
        (state, action) => {
          const { payload } = action;
          state.isLoadingPopUp = false;
          state.confirmContactEmail = payload.data.email;
          state.confirmContactPhone = payload.data.phone;
        },
      );
      builder.addCase(
        postSaveContactThunk.rejected,
        (state) => {
          state.isLoadingPopUp = false;
        },
      );
    },
  });

  return confirmationSlice;
}

const confirmationPopUpSlice = confirmationPopUpFactory(
  dataSliceOptions.confirmContactDetails,
);
const savePopUpSlice = confirmationPopUpFactory(
  dataSliceOptions.savePreferredContact,
);

export const {
  setConfirmContactEmail,
  setConfirmContactPhone,
} = confirmationPopUpSlice.actions;

const confirmationPopUpReducer = combineReducers({
  confirmationPopUp: confirmationPopUpSlice.reducer,
  savePopUp: savePopUpSlice.reducer,
});

export default confirmationPopUpReducer;
