import { Typography } from '@atoms';
import { typography } from '@constants/styles/typography.constants';
import { ITextGroupIconBody } from './TextGroupIconBody.interfaces';
import {
  FlexContainer,
  TextContainer,
  ImageStyledContainer,
  ImageStyled,
} from './TextGroupIconBody.styles';
import BuildHyperlinkedText from '@atoms/BuildHyperlikedText/BuildHyperlinkedText';

const TextGroupIconBody = ({
  image,
  type,
  titleTextView,
  bodyTextView,
  ...rest
}: ITextGroupIconBody) => {
  const getTypography = () => {
    switch (type) {
      case 'leadingRegular':
        return 'body1';
      case 'leadingMedium':
        return 'body2Bold';
      case 'leadingMedium_c18':
        return 'body2Bold';
      default:
        return 'body2Bold';
    }
  };

  return (
    <FlexContainer {...rest} data-name="TextGroupIconBody">
      {image?.url && (
        <ImageStyledContainer>
          <ImageStyled
            src={image?.url}
            filter={image?.tintColor}
            alt="icon"
            width={24}
          />
        </ImageStyledContainer>
      )}
      <TextContainer>
        <Typography variant={typography[getTypography()]}>
          {BuildHyperlinkedText(
            titleTextView?.textAttributes?.links,
            titleTextView?.copy,
          )}
        </Typography>
        <Typography variant={typography.body1}>
          {BuildHyperlinkedText(
            bodyTextView?.textAttributes?.links,
            bodyTextView?.copy,
          )}
        </Typography>
      </TextContainer>
    </FlexContainer>
  );
};

export default TextGroupIconBody;
