import styled from 'styled-components';
import { FormControlLabel } from '@library';
import { IStyledDottedLine } from './CardThreeOptionPicker.interfaces';

export const DottedLineContainer = styled.div`
  display: flex;
`;

export const GradientLeftBorderBeginning = styled.div`
  border-bottom: 4px solid rgba(0, 0, 0, 0.25);
  border-style: dotted;
  width: 1.5rem;
  margin-left: auto;
  border-top: none;
  border-left: none;
  margin-top: auto;
`;

export const GradientLeftBorder = styled.div`
  border-bottom: 4px solid rgba(0, 0, 0, 0.5);
  border-style: dotted;
  width: 1.5rem;
  border-top: none;
  border-left: none;
  margin-top: auto;
`;

export const CurvedDottedLine = styled.div`
  border-bottom: 4px solid rgba(0, 0, 0, 1);
  border-right: 4px solid rgba(0, 0, 0, 1);
  border-style: dotted;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 100%;
  min-height: 8rem;
  width: 20rem;
  margin: auto;
  margin-left: 1.5px;
  margin-right: 40px;
  border-top: none;
  border-left: none;
`;

export const GradientRightBorder = styled.div`
  border-right: 4px solid rgba(0, 0, 0, 0.5);
  border-style: dotted;
  border-top: none;
  border-left: none;
  margin-top: -18px;
  margin-left: -44px;
  margin-right: 15px;
  height: 1rem;
`;

export const RadioControlLabel = styled(FormControlLabel)`
  margin-left: 2.5em;
  margin-right: 2.5rem;
`;

export const StyledBackgroundImage = styled.div<IStyledDottedLine>`
  background-image: url(${({ $url }) => $url});
  background-position: center;
  background-size: 130%;
  height: 10rem;
  width: auto;
`;
