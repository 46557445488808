import styled from 'styled-components';
import { solidColors } from '@constants/styles/colors.constants';
import { Icons } from '@library';

export const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DottedLine = styled.span`
  width: 50px;
  height: 2px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23D0D0D0FF' stroke-width='4' stroke-dasharray='1%2c3' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e");
`;

export const AircraftIcon = styled(Icons.Flight)`
  color: ${solidColors.midnight.color};
  transform: rotate(90deg);
  margin: 7px;
`;
