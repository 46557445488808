import styled from 'styled-components';
import { Typography } from '@atoms';

export const StyledTitle = styled(Typography)`
  margin-top: 0.875rem;
`;

export const StyledSubtitle = styled(Typography)`
  margin-top: 0rem;
  padding-right: 25%;
`;
