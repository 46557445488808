import { createSlice } from '@reduxjs/toolkit';
import {
  endpoints,
  getAuthHeaders,
  pegasusClient,
} from '@services/apiService';

interface IState {
  isDeletePassengerModalOpen: boolean;
  managedPassengerId?: number;
}

export const initialState: IState = {
  isDeletePassengerModalOpen: false,
};

export const passengerSummarySlice = createSlice({
  name: 'passengerSummary',
  initialState: initialState,
  reducers: {
    setDeletePassengerModalOpen: (state, action) => {
      state.isDeletePassengerModalOpen = action.payload;
    },
    setManagedPassengerId: (state, action) => {
      state.managedPassengerId = action.payload;
    },
  },
});

export const {
  setDeletePassengerModalOpen,
  setManagedPassengerId,
} = passengerSummarySlice.actions;

export default passengerSummarySlice.reducer;

export const deletePassenger = async (
  passengerId: string,
  setDeletePassengerError: (arg0: string) => void,
) => {
  const headers = getAuthHeaders();
  try {
    return await pegasusClient.delete(
      `${endpoints.deletePassenger(passengerId)}`,
      { headers },
    );
  } catch (err: any) {
    setDeletePassengerError(err.data);
    throw err;
  }
};
