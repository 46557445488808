import { store } from '@app/store';
import { Provider } from 'react-redux';

interface IProviderWrapper {
  children?: React.ReactNode;
}

const ProviderWrapper = (props: IProviderWrapper) => (
  <Provider store={store}>{props.children}</Provider>
);

export default ProviderWrapper;
