import { IStyleAirportLabel } from './DigitalDealAirportSelector.interfaces';
import styled from 'styled-components';
import { MenuItem, Grid, Popover, Icons } from '@library';
import { Box, BaseButton, Typography } from '@atoms';
import { solidColors } from '@constants/styles/colors.constants';

export const ItemContainer = styled.div`
  width: 306px;
  height: auto;
`;

export const StyledAirportContainer = styled.div`
  width: 306 px;
  height: auto;
  display: flex;
`;

export const StyledMenu = styled(Popover)`
  color: ${solidColors.upWhite.color};
  min-width: 528px;
  height: auto;
  padding: 0;
  margin: 0;
`;

export const StyledMenuItem = styled(MenuItem)`
  width: 480px;
  height: auto;
  display: block;
  margin: 0;
  opacity: 1 !important;
`;

export const StyledAirportItem = styled(Grid)`
  width: auto;
  padding: 16px 0 16px 0;
`;

export const Airport = styled(Typography)`
  text-align: left;
  width: auto;
  height: auto;
`;

export const Price = styled(Typography)`
  text-align: right;
  width: auto;
  height: auto;
`;

export const HoursText = styled(Typography)`
  width: auto;
`;
export const DensityText = styled(Typography)`
  padding-left: 16px;
  width: auto;
`;

export const PriceContainer = styled.div`
  width: auto;
  max-width: 60px;
  text-align: right;
`;

export const PriceGridItem = styled(Grid)`
  text-align: -webkit-right;
`;

export const AirportSelectButton = styled(BaseButton)`
  width: 340px;
  max-height: 53px;
  text-transform: none;
  justify-content: flex-start;
`;

export const AirportSelectContainer = styled.div<IStyleAirportLabel>`
  height: ${({ $height }) => $height};
  width: inherit;
  text-align: left;
`;

export const StyledBox = styled.div`
  width: 340px;
  border-color: ${solidColors.upBlue.color};
  border-bottom: 1px;
`;

export const ButtonIcon = styled(Icons.ExpandMore)`
  color: ${solidColors.upBlue.color};
`;
