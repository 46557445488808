import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import {
  endpoints,
  getAuthHeaders,
  pegasusClient,
} from '@services/apiService';

export interface IMyTripsCancelOfferState {
  isCancelOfferPopUpOpen: boolean;
  dealId: string;
  status: string;
}

const initialState: IMyTripsCancelOfferState = {
  isCancelOfferPopUpOpen: false,
  dealId: '',
  status: '',
};

interface IProps {
  dealId: string;
}

export const postCancelFlightOffer = createAsyncThunk(
  'myTripsCancel/postCancelOffer',
  async (props: IProps) => {
    try {
      const res = await pegasusClient.post(
        `${endpoints.cancelOffer}?version=1&platform=web`,
        {
          reservationId: props.dealId,
        },
        {
          headers: getAuthHeaders(),
        },
      );
      return res.data;
    } catch (err) {
      return err;
    }
  },
);

const myTripsCancelOfferSlice = createSlice({
  name: 'myTripsCancelOffer',
  initialState,
  reducers: {
    setIsCancelOfferPopUpOpen: (
      state,
      action: PayloadAction<{
        isOpen: boolean;
        dealId: string;
      }>,
    ) => {
      state.isCancelOfferPopUpOpen = action.payload.isOpen;
      state.dealId = action.payload.dealId;
    },
    cleanState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(
      postCancelFlightOffer.fulfilled,
      (state, action) => {
        const { payload } = action;
        if (payload.message === 'SUCCESS') {
          state.dealId = '';
          state.status = '200';
          state.isCancelOfferPopUpOpen = false;
        }
      },
    );
  },
});

export const {
  setIsCancelOfferPopUpOpen,
  cleanState,
} = myTripsCancelOfferSlice.actions;

export default myTripsCancelOfferSlice.reducer;
