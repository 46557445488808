import React from 'react';
import { ImageImgix } from '@atoms';
import { IImage } from '@components/types';
import { CarouselSlide } from './CarouselSmallArrowSelectedImageWebOnly.styles';

interface ISlides {
  transformAmount: number;
  slidesPerView: number;
  startIndex: number;
  slideMargin: number;
  allActiveFallback: boolean;
  images: IImage[];
  imageWidth: number;
  imageHeight: number;
  isLarge?: boolean;
}

function Slides({
  transformAmount,
  slidesPerView,
  startIndex,
  slideMargin,
  allActiveFallback,
  images,
  imageWidth,
  imageHeight,
  isLarge = false,
}: ISlides) {
  return (
    <>
      {images.map((slide, index) => {
        const className = `CarouselSmallArrowSelectedImageWebOnlySlide-slide${
          isLarge ? '-large' : ''
        }`;
        const key = `${className}-${index}`;
        const active =
          index >= startIndex &&
          index < startIndex + slidesPerView;
        const selected = index === startIndex;
        return (
          <CarouselSlide
            className={className}
            key={key}
            data-id="CarouselSmallArrowSelectedImageWebOnlySlide"
            $translateX={transformAmount}
            $active={active || allActiveFallback}
            $marginRight={slideMargin}
            $selected={isLarge ? false : selected}
          >
            <ImageImgix
              alt="Slide Image"
              src={slide.url}
              width={imageWidth}
              height={imageHeight}
              borderRadius={isLarge ? '0' : '8px'}
            />
          </CarouselSlide>
        );
      })}
    </>
  );
}

export default Slides;
