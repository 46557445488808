import React from 'react';
import {
  Button as MuiButton,
  ButtonProps,
} from '@material-ui/core';

export interface IButtonAtom extends ButtonProps {
  backgroundColor?: String;
}

export default function BaseButton(props: IButtonAtom) {
  return <MuiButton {...props}></MuiButton>;
}
