import { useAppSelector } from '@app/hooks';
import { setAirmedLegalFlag } from '@features/Fly/Payment/PaymentStatusSlice';
import { useDispatch } from 'react-redux';
import TexGroupBodyCopyLink from '../TextGroupBodyCopyLink/TextGroupBodyCopyLink';
import { ICheckboxBodyCopyLink } from './TextGroupCheckboxRichLink.interfaces';
import { Checkbox } from '@wheelsup/wu-react-components';
import { Container } from './TextGroupCheckboxRichLink.styles';

const TextGroupCheckboxRichLink = ({
  titleTextGroup,
  onChange,
  isPreselected,
  isEnabled = true,
  keys,
}: ICheckboxBodyCopyLink) => {
  const dispatch = useDispatch();

  const { airmedLegalFlag } = useAppSelector(
    (state) => state.paymentStatus,
  );

  const onCheck = () => {
    if (keys && airmedLegalFlag.includes(keys[0])) {
      const newAirmedLegalFlag = airmedLegalFlag.filter(
        (flag) => flag !== keys[0],
      );
      dispatch(setAirmedLegalFlag(newAirmedLegalFlag));
    } else {
      dispatch(
        setAirmedLegalFlag([
          ...airmedLegalFlag,
          ...(keys || []),
        ]),
      );
    }
  };

  const isChecked = (isPreselected: boolean) => {
    if (keys) {
      return (
        isPreselected || airmedLegalFlag.includes(keys[0])
      );
    } else {
      return isPreselected;
    }
  };

  return (
    <Container>
      <Checkbox
        onChange={onCheck}
        checked={isChecked(isPreselected || false)}
        disabled={!isEnabled}
        label={
          <TexGroupBodyCopyLink
            copy={titleTextGroup.title}
            textAttributes={{
              links: titleTextGroup.textAttributes?.links,
            }}
          />
        }
        size="sm"
      />
    </Container>
  );
};

export default TextGroupCheckboxRichLink;
