import styled from 'styled-components';
import {
  Color,
  solidColors,
} from '@constants/styles/colors.constants';

export const StyledBackgroundImage = styled.div<{
  url: string;
  width: number | undefined;
  height: number | undefined;
  filter?: string;
  position?: string;
  bgPosition?: string;
}>`
  position: ${({ position }) => position};
  background-image: url(${({ url }) => url});
  background-size: cover;
  background-position: ${({ bgPosition }) => bgPosition};
  background-repeat: no-repeat;
  width: ${({ width }) =>
    width ? width + 'px' : 'inherit'};
  height: ${({ height }) =>
    height ? height + 'px' : 'inherit'};

  ${({ filter }) => {
    if (filter) {
      const element = `${filter}` as keyof Color;
      return `filter: ${solidColors[element].tintColor};`;
    }
  }}
`;

export const StyledImg = styled.img<{
  filter?: string;
  borderRadius?: string;
  $opacity?: number;
}>`
  ${({ filter }) => {
    if (filter) {
      const element = `${filter}` as keyof Color;
      return `filter: ${solidColors[element].tintColor};`;
    }
  }}

  opacity: ${({ $opacity = 1.0 }) => $opacity};
  border-radius: ${({ borderRadius }) =>
    borderRadius ?? '8px'};
`;
