import { Typography } from '@atoms';
import styled from 'styled-components';

export const ButtonContainerStyled = styled.div`
  margin-top: 0.5rem;
`;

export const TitleStyled = styled(Typography)`
  margin: 12px 0 16px 0;
`;

export const TitleStyledContainer = styled.div`
  margin: 12px 0 16px 0;
`;

// These are to match MUI Styling
export const Table = styled.table`
  width: 100%;
  display: table;
  border-spacing: 0;
  border-collapse: collapse;
  text-indent: initial;
`;
