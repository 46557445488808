export enum DeepLinksList {
  NEWS = 'wheelsup://news',
  NEWS_DETAILS = 'wheelsup://newsDetails',
  FLY = 'wheelsup://fly',
  HUB = 'wheelsup://hub',
  ACCOUNT = 'wheelsup://account',
  MY_TRIPS = 'wheelsup://myTrips',
  SELECT_ALTERNATE_AIRPORT = 'wheelsup://selectAlternateAirport',
  SIGN_IN = 'wheelsup://signin',
  LIMITED_TIME_DEALS = 'wheelsup://limited-time-deals',
  MEMBERS = 'wheelsup://members',
}

export enum DeepLinksAbnormalList {
  DASHBOARD = 'wheelsup://dashboard',
  SIGN_UP = 'wheelsup://signup',
  PASSWORD_RESET_CONFIRMATION = 'wheelsup://passwordResetConfirmation',
  FAQ = 'wheelsup://faq',
  PRE_CHAT = 'wheelsup://preChat',
  WEB_TO_CASE = 'wheelsup://webToCase',
  SEARCH_DEPARTURE_AIRPOT = 'wheelsup://searchDepartureAirpot',
  SEARCH_ARRIVAL_AIRPOT = 'wheelsup://searchArrivalAirpot',
  SELECT_DATE = 'wheelsup://selectDate',
  FLIGHT_BOOKING_RESULTS = 'wheelsup://flightBookingResults',
  HOT_NIGHTS = 'wheelsup://hotNights',
  PAYMENT = 'wheelsup://payment',
  ADD_PASSENGER = 'wheelsup://addPassenger',
  SELECT_PASSENGERS = 'wheelsup://selectPassengers',
  FLIGHT_DETAILS = 'wheelsup://flightDetails',
  NEWS_DETAILS = 'wheelsup://newsDetails',
  SELECT_TIME = 'wheelsup://selectTime',
  SELECT_PASSENGER_COUNT = 'wheelsup://selectPassengerCount',
  EDIT_PASSENGER = 'wheelsup://editPassenger',
  MANAGE_PASSENGERS = 'wheelsup://managePassengers',
  ADD_PET = 'wheelsup://addPet',
  EDIT_PET = 'wheelsup://editPet',
  PASSENGER_DETAILS = 'wheelsup://passengerDetails',
  PET_DETAILS = 'wheelsup://petDetails',
  RESERVATION_DETAILS = 'wheelsup://reservationDetails',
  MANAGE_PASSENGERS_PAGE = 'wheelsup://passengers',
  FLIGHT_DEALS = 'wheelsup://flightDeals',
  GLOBAL_RESPONSE = 'wheelsup://upglobalresponse',
  GLOBAL_RESPONSE_MANAGE = 'wheelsup://upglobalresponse/manage-individuals',
  GLOBAL_RESPONSE_CONTACT = 'wheelsup://upglobalresponse/contact-form',
  CANCEL_PENDING_FLIGHT = 'wheelsup://cancelPendingFlight', // Should not be linked in Map below
  RENEW_MEMBERSHIP = 'wheelsup://renewMembershipActive', // Should not be linked in Map below
}

export enum DeepLinksForHub {
  CALL_US = 'wheelsup://callUs',
  WEB_TO_CASE = 'wheelsup://webToCase',
  FAQ = 'wheelsup://faq',
  CONTACT_US = 'wheelsup://accountContactUs',
}

export const abnormalLinksMap: { [key: string]: string } = {
  [DeepLinksList.NEWS]: '/news',
  [DeepLinksList.FLY]: '/fly',
  [DeepLinksList.HUB]: '/hub',
  // TODO: Map deep links to open correct panel
  // being hub deep links
  [DeepLinksForHub.CALL_US]: '/hub',
  [DeepLinksForHub.WEB_TO_CASE]: '/hub',
  [DeepLinksForHub.FAQ]: '/hub',
  [DeepLinksForHub.CONTACT_US]: '/hub',
  // end deeplinks for hub
  [DeepLinksList.ACCOUNT]: '/account',
  [DeepLinksList.MY_TRIPS]: '/my-trips',
  [DeepLinksList.SIGN_IN]: '/signin',
  [DeepLinksList.MEMBERS]: '/members',
  [DeepLinksAbnormalList.DASHBOARD]: '/',
  [DeepLinksAbnormalList.SIGN_UP]: '/create-account/1',
  [DeepLinksAbnormalList.PASSWORD_RESET_CONFIRMATION]:
    '/new-password',
  [DeepLinksAbnormalList.PRE_CHAT]: '/hub',
  [DeepLinksAbnormalList.SEARCH_DEPARTURE_AIRPOT]: '/fly',
  // [DeepLinksAbnormalList.FLY_DEALS]: '/fly',
  [DeepLinksAbnormalList.SEARCH_ARRIVAL_AIRPOT]: '/fly',
  [DeepLinksAbnormalList.SELECT_DATE]: '/fly',
  [DeepLinksAbnormalList.FLIGHT_BOOKING_RESULTS]:
    '/fly/results',
  [DeepLinksAbnormalList.HOT_NIGHTS]:
    '/members/wheels-down*inspirato',
  [DeepLinksAbnormalList.PAYMENT]: '/fly/payment',
  [DeepLinksAbnormalList.ADD_PASSENGER]:
    '/manage/passenger',
  // need to talk to LP about this one
  [DeepLinksAbnormalList.SELECT_PASSENGERS]:
    '/select-passengers/<leg-id>/<max-number-pas>',
  [DeepLinksAbnormalList.FLIGHT_DETAILS]:
    '/fly/search-trip-details',
  [DeepLinksAbnormalList.NEWS_DETAILS]:
    '/news/<article-id>',
  [DeepLinksAbnormalList.SELECT_TIME]: '/fly',
  [DeepLinksAbnormalList.SELECT_PASSENGER_COUNT]: '/fly',
  [DeepLinksAbnormalList.EDIT_PASSENGER]:
    '/manage/passenger/<passenger-id>',
  [DeepLinksAbnormalList.MANAGE_PASSENGERS]:
    '/manage/passengers',
  [DeepLinksAbnormalList.MANAGE_PASSENGERS_PAGE]:
    '/my-trips/<trip-id>',
  [DeepLinksAbnormalList.ADD_PET]: '/manage/pet',
  [DeepLinksAbnormalList.EDIT_PET]:
    '/passengers/pet-summary/<pet-id>',
  [DeepLinksAbnormalList.PASSENGER_DETAILS]:
    '/passengers/passener-summary/<passenger-id>',
  [DeepLinksAbnormalList.PET_DETAILS]:
    '/passengers/pet-summary/<pet-id>',
  [DeepLinksAbnormalList.RESERVATION_DETAILS]:
    '/my-trips/<trip-id>',
  [DeepLinksAbnormalList.FLIGHT_DEALS]: '/flight-deals',
  [DeepLinksList.LIMITED_TIME_DEALS]: '/limited-time-deals',
  [DeepLinksAbnormalList.GLOBAL_RESPONSE]:
    '/upglobalresponse',
  [DeepLinksAbnormalList.GLOBAL_RESPONSE_MANAGE]:
    '/upglobalresponse/manage-individuals',
  [DeepLinksAbnormalList.GLOBAL_RESPONSE_CONTACT]:
    '/upglobalresponse/contact-form',
};
