import React from 'react';

import {
  CardPetDetailsContainer,
  PetEdit,
  TypographyStyled,
  TitleSubtitleContainer,
  TitleSubtitleWrapper,
  InfoContainer,
} from './CardPetDetails.styles';
import { ICardPetDetails } from './CardPetDetails.interface';
import { typography } from '@constants/styles/typography.constants';
import { solidColors } from '@constants/styles/colors.constants';
import { Button } from '@atoms';
import { Elevation } from '@wheelsup/wu-react-components';

const CardPetDetails = ({
  id,
  button,
  title,
  weightCaption,
  weightCopy,
  ageCaption,
  ageCopy,
  favoritePassengerCaption,
  favoritePassengerCopy,
  petTypeCaption,
  petTypeCopy,
  petAffinityCaption,
  petAffinityCopy,
}: ICardPetDetails) => {
  const CardPetData = [
    [weightCaption, weightCopy],
    [ageCaption, ageCopy],
    [favoritePassengerCaption, favoritePassengerCopy],
    [petTypeCaption, petTypeCopy],
    [petAffinityCaption, petAffinityCopy],
  ];

  const renderTitleSubtitle = (
    subTitle: string,
    title: string,
  ) => {
    return (
      <TitleSubtitleContainer>
        <TypographyStyled
          variant={typography.body2}
          color={solidColors.darkGray}
          fontSize="14px"
        >
          {subTitle}
        </TypographyStyled>

        <TypographyStyled
          variant={typography.body1}
          color={solidColors.midnight}
        >
          {title}
        </TypographyStyled>
      </TitleSubtitleContainer>
    );
  };
  return (
    <Elevation variant="raisedLevel2" padding={0}>
      <CardPetDetailsContainer>
        <PetEdit>
          <TypographyStyled
            variant={typography.body1}
            color={solidColors.midnight}
          >
            {title}
          </TypographyStyled>

          <Button
            title={button?.title}
            styleType={'icon_trailing'}
            action={{
              actionMethod: button?.action?.actionMethod,
            }}
            image={{
              url: button?.image?.url,
            }}
          />
        </PetEdit>

        <TitleSubtitleWrapper>
          {CardPetData.map(
            (data: string[], idx: number) => {
              return (
                <InfoContainer key={idx}>
                  {renderTitleSubtitle(data[0], data[1])}
                </InfoContainer>
              );
            },
          )}
        </TitleSubtitleWrapper>
      </CardPetDetailsContainer>
    </Elevation>
  );
};

export default CardPetDetails;
