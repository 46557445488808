import { FeatureFlags } from '@services/featureFlagService/constants';
import { useFeatureFlag } from '@services/featureFlagService/featureFlagService.services';
import SubPageFlightSearchV3 from '../SubPageFlightSearchV3/SubPageFlightSearchV3';
import SubPageFlightSearchV2 from '../SubPageFlightSearchV2/SubPageFlightSearchV2';

const SubPageFlightSearch = () => {
  const useTod = useFeatureFlag(FeatureFlags.USE_TOD);
  return (
    <>
      {useTod ? (
        <SubPageFlightSearchV3 />
      ) : (
        <SubPageFlightSearchV2 />
      )}
    </>
  );
};

export default SubPageFlightSearch;
