import styled from 'styled-components';
import { Button } from '@atoms';
import TextGroupIconBody from '@molecules/TextGroupIconBody/TextGroupIconBody';
import { Typography } from '@wheelsup/wu-react-components';

export const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  background-color: white;
  margin: 2rem 0rem;
  border-radius: 4px;
  padding: 32px;
  gap: 10px;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ImageSection = styled.div`
  margin-right: 10px;
`;

export const HeaderSection = styled.div`
  display: flex;
  padding-right: 50px;
  margin-bottom: 20px;
`;

export const TextSection = styled.div`
  padding-right: 50px;
  margin-bottom: 20px;
  height: 400px;
`;

export const CheckboxSection = styled.div`
  display: flex;
  text-align: center;
  align-content: center;
`;

export const ButtonContainer = styled.div`
  margin: 10px;
  display: flex;
`;

export const OnboardingButton = styled(Button)`
  min-height: 50px;
  margin: 10px 20px;
`;

export const CopyTypography = styled(Typography)`
  margin-bottom: 20px;
  margin-top: 20px;
`;

export const TermsTypography = styled(Typography)`
  margin-bottom: 20px;
  margin-top: 20px;
`;

export const StepsTypography = styled(Typography)``;

export const HeadlineTypography = styled(Typography)`
  height: 34px;
  width: auto;
`;

export const CloseContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const CustomContainer = styled.div`
  display: flex;
`;

export const TermList = styled.ul<{
  $hasBullets?: boolean;
}>`
  width: 100%;
  height: auto;
  margin: 0;
  padding-left: ${({ $hasBullets }) =>
    $hasBullets ? '25px' : '0'};
  list-style-type: ${({ $hasBullets }) =>
    $hasBullets ? 'disc' : 'none'};
`;

export const TermListItem = styled.li<{
  $hasBullets?: boolean;
}>`
  font-size: ${({ $hasBullets }) =>
    $hasBullets ? '18px' : '0'};
`;

export const TermText = styled(TextGroupIconBody)<{
  $hasBullets?: boolean;
}>`
  margin-left: ${({ $hasBullets }) =>
    $hasBullets ? '18px' : '0'};
  margin-bottom: 18px;
  width: auto;
`;

export const BtnWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;
