import { Typography } from '@atoms';
import { Container } from '@components/library';
import styled from 'styled-components';

export const StyledSubNavContainer = styled.div`
  flex-grow: 1;
  width: 100%;
`;

export const StyledSubNavTitle = styled(Typography)`
  margin-bottom: 3rem;
  font-size: 36px;
  letter-spacing: -0.62px;
  line-height: 40px;
`;

export const StyledContainerSubNavWrapper = styled.div`
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

export const StyledTabsWrapper = styled(Container)`
  position: relative;
  width: 100%;
  height: 100%;
`;
