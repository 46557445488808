import styled from 'styled-components';
import { solidColors } from '../../../constants/styles/colors.constants';
import { Container, Grid, Icons } from '../../library';
import { Box } from '@atoms';

export const IconsStyled = styled(Icons.ArrowForward)`
  color: ${solidColors.upBlue.color};
`;

export const ContainerContent = styled(Container)`
  margin-top: 1rem;
`;

export const StyledBox = styled.div`
  margin-top: 1.4rem;
  text-align: right;
`;

export const FlexGrid = styled(Grid)`
  justify-content: flex-end;
`;
