import React, { useEffect, useRef, useState } from 'react';
import {
  TextGroupIconText,
  TitleIconContainer,
} from '@molecules';
import {
  IFlightLegItemPassengerInfoItem,
  PassengerList,
} from './FlightLegItemPassengerInfo.interfaces';
import {
  ButtonStyled,
  CarouselContainer,
  CarouselContainerSearchTripDetails,
  TextGroupIconTextContainer,
} from './FlightLegItemPassengerInfo.styles';

import { useDispatch } from 'react-redux';
import { useAppSelector } from '@app/hooks';
import {
  useNavigate,
  generatePath,
  useParams,
} from 'react-router-dom';
import { paths } from '@routing/routerPaths';

import {
  setShowPassengerListAsCarousel,
  setCheckboxPassengersJustClicked,
  setIsEditingPassengers,
} from '@features/Fly/FlySearchTripDetailsSlice';
import { trackAnalytics } from '@services/mixpanelService/mixpanel.service';
import {
  IKeyValue,
  IPassenger,
  IPet,
} from '@components/types';
import { CarouselSmallArrowWebOnly } from '@components/organisms';
import { useViewport } from '@hooks';
import TextGroupSwitch from '../TextGroupSwitch/TextGroupSwitch';
import { solidColors } from '@constants/styles/colors.constants';
import { saveDataInLocalStorage } from '@components/utils/storage';
import { typography } from '@constants/styles/typography.constants';

const FlightLegItemPassengerInfo = ({
  flightLegTitle,
  addPassengerCopy,
  addPassengerLeadingIcon,
  leadPassengerIcon,
  samePassengersForAllLegsCopy,
  passengerCheckedAction,
  showPassengerListAsCarousel,
  index,
  maxNumberOfPassengers,
  flightId,
  isWithinChangeWindow,
}: IFlightLegItemPassengerInfoItem) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { reservationId } = useParams();
  const { width } = useViewport();

  const passengersStateData = useAppSelector(
    (state) => state.searchTripDetails,
  );

  const [hasCarousel, setHasCarousel] = useState(
    showPassengerListAsCarousel,
  );

  const [flightOccupancy, setFlightOccupancy] = useState<
    PassengerList[][]
  >([[]]);
  const [gotPax, setGotPax] = useState(false);
  const [parentWidth, setParentWidth] = useState(0);

  useEffect(() => {
    const parent = document.getElementById(
      'SubPageTripDetailsWebOnly',
    );
    const containerParent = document.getElementById(
      'ContainerTripPassengerEditor',
    );
    if (parent) {
      setParentWidth(parent.clientWidth);
    } else if (containerParent) {
      setParentWidth(containerParent.clientWidth);
    }
  }, [width]);

  const handleCheckBox = (value: boolean) => {
    setHasCarousel(!value);
    dispatch(setShowPassengerListAsCarousel(!value));
    dispatch(setCheckboxPassengersJustClicked(true));
  };

  const renderListOfPassengersItems = (
    legPosition: number,
  ) => {
    let leadPaxIdx = 0;
    const listOfPaxWithoutLeadWithFallback =
      passengersStateData.selectedPassengersLeg[legPosition]
        ?.selectedPassengers ?? [];
    const listOfPaxWithoutLead =
      listOfPaxWithoutLeadWithFallback.filter(
        (pax, idx) => {
          if (pax.isLeadPassenger) {
            leadPaxIdx = idx;
            return false;
          } else {
            return true;
          }
        },
      ) ?? [];
    const listOfPaxWithLeadWithFallback =
      [
        passengersStateData.selectedPassengersLeg[
          legPosition
        ]?.selectedPassengers[leadPaxIdx],
        ...listOfPaxWithoutLead,
      ] ?? [];
    const listOfPaxFinal = listOfPaxWithLeadWithFallback.map(
      (item) => ({
        ...item,
        isPassenger: true,
      }),
    );
    return listOfPaxFinal;
  };

  const renderListOfPetsItems = (legPosition: number) => {
    const listOfPetsWithFallback =
      passengersStateData.selectedPetsLegs[legPosition]
        ?.selectedPets ?? [];
    const listOfPetsFinal = listOfPetsWithFallback.map(
      (pet) => ({
        ...pet,
        isPassenger: false,
      }),
    );
    return listOfPetsFinal;
  };

  const handleSwitchClick = () => {
    passengerCheckedAction();
    handleCheckBox(hasCarousel);
  };

  const renderSamePassengersCheckbox = (
    legPosition: number,
  ) =>
    passengersStateData.selectedPassengersLeg[
      legPosition
    ]?.selectedPassengers?.map((item, i) => {
      const key = `${i}`;
      return (
        <React.Fragment key={key}>
          {samePassengersForAllLegsCopy &&
            i ===
              passengersStateData.selectedPassengersLeg[
                legPosition
              ].selectedPassengers.length -
                1 && (
              <div className="checkbox-wrapper">
                <TextGroupSwitch
                  onChange={handleSwitchClick}
                  checked={passengersStateData.samePaxFlag}
                  copy={samePassengersForAllLegsCopy}
                  direction={'left'}
                  color={solidColors.midnight}
                />
              </div>
            )}
        </React.Fragment>
      );
    });

  const addPassengerAction = () => {
    const actionAnalytics: unknown = {
      key: 'Page Name',
      value: 'My Trip',
    };
    trackAnalytics({
      trackingTitle: 'Select_ManagePassengersResPage_CTA',
      properties: [actionAnalytics] as IKeyValue[],
    });
    dispatch(setIsEditingPassengers(true));
    saveDataInLocalStorage(
      'flightLegTitle',
      flightLegTitle ?? '',
    );
    navigate(
      `${generatePath(paths.SELECT_PASSENGERS, {
        id: `${index}`,
        max: `${maxNumberOfPassengers}`,
      })}${
        window.location.href.includes('my-trips')
          ? `?reservation=${reservationId}&flightId=${flightId}`
          : ''
      }`,
    );
  };

  const getAllPassengers = () => {
    if (
      passengersStateData.selectedPassengersLeg[index]
        ?.selectedPassengers !== undefined
    ) {
      const passList =
        renderListOfPassengersItems(index) || [];
      const petsList = renderListOfPetsItems(index) || [];

      const currOccupancyList = [...passList, ...petsList];
      const currPassengerPagination: PassengerList[][] = [
        [],
      ];
      currOccupancyList.forEach((passenger, index) => {
        const arrayIdx = Math.floor(index / 4);
        currPassengerPagination[arrayIdx] =
          currPassengerPagination[arrayIdx] !== undefined
            ? [
                ...currPassengerPagination[arrayIdx],
                passenger,
              ]
            : [passenger];
      });
      setFlightOccupancy(currPassengerPagination);
      setGotPax(true);
    }
  };

  useEffect(getAllPassengers, [
    passengersStateData.selectedPassengersLeg,
    passengersStateData.selectedPetsLegs,
    gotPax,
  ]);

  const trackPaxMixpanel = () => {
    const actionAnalytics: unknown = {
      key: 'Page Name',
      value: 'My Trip',
    };
    trackAnalytics({
      trackingTitle:
        'Select_ViewPassengerProfileResPage_ListItem',
      properties: [actionAnalytics] as IKeyValue[],
    });
  };
  const flowParam = window.location.href.includes(
    'my-trips',
  )
    ? `?reservation=${reservationId}&flightId=${flightId}`
    : '';

  const passengerAction = (index: number, id?: string) => {
    trackPaxMixpanel();
    dispatch(setIsEditingPassengers(true));

    navigate(
      `/manage/passenger/${id}/${index}${flowParam}`,
    );
  };

  const CarouselDiv = window.location.pathname.includes(
    'fly',
  )
    ? CarouselContainerSearchTripDetails
    : CarouselContainer;

  const petAction = (route: string) => {
    trackPaxMixpanel();
    dispatch(setIsEditingPassengers(true));
    navigate(route + flowParam);
  };

  const renderPetsAndPassengers = () => (
    <>
      <CarouselSmallArrowWebOnly inComponent>
        {gotPax &&
          flightOccupancy?.map((page, i) => {
            const key = `page-${i}`;
            return (
              <CarouselDiv
                key={key}
                data-id="passenger-list"
                $width={parentWidth}
              >
                {page?.map((item, idx) => {
                  if (item.isPassenger) {
                    const passItem = item as IPassenger;
                    return (
                      <TextGroupIconTextContainer
                        className="styled-grid"
                        key={
                          passItem.firstName +
                          passItem.lastName +
                          passItem.email +
                          `${idx}`
                        }
                      >
                        <TextGroupIconText
                          headline={`${passItem.firstName} ${passItem.lastName}`}
                          headlineVariant={typography.body1}
                          extraCopyVariant={
                            typography.body2
                          }
                          extraCopyColor="disabled"
                          buttonAction={() => {
                            passengerAction(
                              index,
                              passItem.id,
                            );
                          }}
                          copy={
                            passItem.isLeadPassenger
                              ? 'Lead Passenger'
                              : ''
                          }
                          isSpecial={
                            passItem.isLeadPassenger
                          }
                          headerSize="1.375rem"
                          isButton
                          icon={
                            <TitleIconContainer
                              width="48px"
                              height="48px"
                              radius="50%"
                              image={
                                passItem.profileImage?.url
                              }
                              tintColor={
                                passItem.profileImage
                                  ?.tintColor
                              }
                            />
                          }
                          image={leadPassengerIcon}
                        />
                      </TextGroupIconTextContainer>
                    );
                  } else if (!item.isPassenger) {
                    const petItem = item as IPet;
                    const route = `/manage/pet/${petItem.id}/${index}`;
                    return (
                      <TextGroupIconTextContainer
                        className="styled-grid"
                        key={petItem.id}
                      >
                        <TextGroupIconText
                          buttonAction={() => {
                            petAction(route);
                          }}
                          headline={petItem.name}
                          headlineVariant={typography.body1}
                          extraCopyVariant={
                            typography.body2
                          }
                          extraCopyColor="disabled"
                          key={petItem.id}
                          headerSize="1.375rem"
                          isButton
                          icon={
                            <TitleIconContainer
                              width="64px"
                              height="64px"
                              radius="50%"
                              image={
                                petItem.profileImage?.url
                              }
                              tintColor={
                                petItem.profileImage
                                  ?.tintColor
                              }
                            />
                          }
                          image={leadPassengerIcon}
                        />
                      </TextGroupIconTextContainer>
                    );
                  }
                })}
              </CarouselDiv>
            );
          })}
      </CarouselSmallArrowWebOnly>
      {renderSamePassengersCheckbox(index)}

      {isWithinChangeWindow && (
        <ButtonStyled
          title={addPassengerCopy}
          styleType="text_with_icon_leading"
          image={addPassengerLeadingIcon}
          action={{
            actionMethod: addPassengerAction,
          }}
        />
      )}
    </>
  );
  return <>{renderPetsAndPassengers()}</>;
};

export default FlightLegItemPassengerInfo;
