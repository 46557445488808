import styled from 'styled-components';

export const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  background-color: white;
  margin: 2rem 0rem;
  border-radius: 4px;
  padding: 32px;
  gap: 40px;

  ${(props) => props.theme.breakpoints.down('sm')} {
    padding: 16px;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LuggageContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;
`;

export const ContentContainer = styled.div`
  display: flex;
`;
