import InputBase from '@atoms/InputBase';
import PhoneInput from '@atoms/PhoneInput';
import SmsCodeInput from '@atoms/SmsCodeInput';
import { InputFieldProps } from './Input.interfaces';

export default function Input({
  type,
  ...rest
}: InputFieldProps) {
  switch (type) {
    case 'phone':
      return <PhoneInput
        {...rest}
        type={type}
      />;
    case 'smsCode':
      return <SmsCodeInput
        {...rest}
        type={type}
      />;
    case 'password':
      return <InputBase
        {...rest}
        type={type}
      />;
    case 'newPassword':
      return <InputBase
        {...rest}
        type="password"
      />;
    case 'simpleDate':
      return <InputBase
        {...rest}
        type={type}
      />;
    case 'outlined':
      return (
        <InputBase
          {...rest}
          type={type}
          variant="outlined"
        />
      );
    default:
      return <InputBase
        {...rest}
        type="text"
      />;
  }
}
