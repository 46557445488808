import { IListItems } from './ListItemsWEBONLY.interfaces';
import { Grid } from '@library';
import { BackgroundWrapper } from '@atoms/BackgroundWrapper';
import { SpacerType } from '@components/types';
import { ListItemsDiv } from './ListItemsWEBONLY.styles';
import { StyledSpacer } from '@atoms/ContainerEmpty/ContainerEmpty.styles';

const ListItemsWEBONLY = ({
  spacerTypeTop,
  spacerTypeBottom,
  children,
}: IListItems) => {
  const items = Array.isArray(children) ? (
    children.map((card, index) => {
      return <Grid key={index}>{card}</Grid>;
    })
  ) : (
    <Grid>{children}</Grid>
  );

  let pxSpacerTypeTop = spacerTypeTop;
  if (typeof spacerTypeTop === 'string') {
    pxSpacerTypeTop =
      SpacerType[
        spacerTypeTop as keyof typeof SpacerType
      ] ?? 0;
  }

  let pxSpacerTypeBottom = spacerTypeBottom;
  if (typeof spacerTypeBottom === 'string') {
    pxSpacerTypeBottom =
      SpacerType[
        spacerTypeBottom as keyof typeof SpacerType
      ] ?? 0;
  }

  return (
    <>
      {pxSpacerTypeTop !== undefined &&
        Number(pxSpacerTypeTop) > 0 && (
          <StyledSpacer height={`${pxSpacerTypeTop}px`} />
        )}

      <BackgroundWrapper>
        <ListItemsDiv>{items}</ListItemsDiv>
      </BackgroundWrapper>

      {pxSpacerTypeBottom !== undefined &&
        Number(pxSpacerTypeBottom) > 0 && (
          <StyledSpacer
            height={`${pxSpacerTypeBottom}px`}
          />
        )}
    </>
  );
};

export default ListItemsWEBONLY;
