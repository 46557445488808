import styled from 'styled-components';
import { solidColors } from '@constants/styles/colors.constants';

export const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05);
  position: relative;
  height: fit-content;
  padding: 24px;
`;

export const StyledTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
`;

export const StyledDivision = styled.hr`
  width: 100%;
  color: ${solidColors.mediumGray.color};
`;
