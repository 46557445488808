import {
  StyledSingleTextGroup,
  StyledTitle,
  StyledSingleContainer,
} from './TextGroupSingleTextGroup.styles';
import { ISingleTextGroup } from './TextGroupSingleTextGroup.interfaces';
import { solidColors } from '@constants/styles/colors.constants';
import { typography } from '@constants/styles/typography.constants';
import { deconstructStyleType } from '@components/utils/deconstructStyleType';
import { TextStyleType } from '@components/types';

const TextGroupSingleTextGroup = ({
  title,
  styleType = 'h2_a_c18', // default value
  alignmentState,
}: ISingleTextGroup) => {
  const {
    typographyType,
    colorType,
  } = deconstructStyleType(styleType as TextStyleType);

  return (
    <StyledSingleContainer
      align={alignmentState}
      data-name="TextGroupSingleTextGroup"
    >
      <StyledSingleTextGroup>
        <StyledTitle
          className={colorType || ''}
          align={alignmentState}
          variant={
            typographyType
              ? typography[typographyType]
              : typography['heading01Large']
          }
          color={
            colorType
              ? solidColors[colorType]
              : solidColors.midnight
          }
        >
          {title}
        </StyledTitle>
      </StyledSingleTextGroup>
    </StyledSingleContainer>
  );
};

export default TextGroupSingleTextGroup;
