import { endpoints } from '@services/apiService';

export interface ISliceFactoryArgs {
  pageName: string;
  endpoint: string;
  requestType?: string;
  pageSliceName?: string;
  version: string;
  type?: string;
}

export interface IPageSliceOptions {
  [key: string]: ISliceFactoryArgs;
}

{
  /*
    current pages using FE caching
    - Dashboard
    - Fly
    - My Trips
    - Account
    - Members
    - Hub
*/
}

export const pageSliceOptions: IPageSliceOptions = {
  accountContactUs: {
    pageName: 'accountContactUs',
    endpoint: endpoints.accountContactUs,
    requestType: 'sliceFactory',
    pageSliceName: 'accountContactUs',
    version: '1',
  },
  accountSearch: {
    pageName: 'accountSearch',
    endpoint: endpoints.accountSearch,
    requestType: 'sliceFactory',
    pageSliceName: 'accountSearch',
    version: '1',
  },
  manageCreditCards: {
    pageName: 'manageCreditCards',
    endpoint: endpoints.manageCreditCards,
    requestType: 'sliceFactory',
    pageSliceName: 'manageCreditCards',
    version: '1',
  },
  petEdit: {
    pageName: 'petEdit',
    endpoint: endpoints.petEdit,
    requestType: 'sliceFactory',
    pageSliceName: 'petEdit',
    version: '1',
  },
  dashboard: {
    pageName: 'dashboardPage',
    endpoint: endpoints.dashboardPage,
    requestType: 'sliceFactory',
    pageSliceName: 'dashboard',
    version: '1',
  },
  fly: {
    pageName: 'flyPage',
    endpoint: endpoints.flyPage,
    requestType: 'sliceFactory',
    pageSliceName: 'fly',
    version: '1',
  },
  members: {
    pageName: 'membersPage',
    endpoint: endpoints.membersPage,
    requestType: 'sliceFactory',
    pageSliceName: 'members',
    version: '1',
  },
  demo: {
    pageName: 'demoPage',
    endpoint: endpoints.demoPage,
    requestType: 'sliceFactory',
    pageSliceName: 'demo',
    version: '1',
  },
  hub: {
    pageName: 'hubPage',
    endpoint: endpoints.hubPage,
    requestType: 'sliceFactory',
    pageSliceName: 'hub',
    version: '1',
  },
  news: {
    pageName: 'newsPage',
    endpoint: endpoints.newsPage,
    requestType: 'sliceFactory',
    pageSliceName: 'news',
    version: '1',
  },
  newsDetails: {
    pageName: 'newsDetailsPage',
    endpoint: endpoints.newsDetailsPage,
    requestType: 'sliceFactory',
    pageSliceName: 'newsDetails',
    version: '1',
  },
  flySearchResults: {
    pageName: 'flySearchResultsPage',
    endpoint: endpoints.flySearchResultsPage,
    requestType: 'sliceFactory',
    pageSliceName: 'flySearchResults',
    version: '1',
  },
  flySearchTripDetails: {
    pageName: 'flySearchTripDetailsPage',
    endpoint: endpoints.flySearchTripDetailsPage,
    requestType: 'requestFactory',
    pageSliceName: 'flySearchTripDetails',
    version: '1',
    type: 'post',
  },
  payment: {
    pageName: 'payment',
    endpoint: endpoints.paymentPage,
    requestType: 'sliceFactory',
    pageSliceName: 'payment',
    version: '1',
    type: 'post',
  },
  confirmation: {
    pageName: 'confirmation',
    endpoint: endpoints.confirmationPage,
    requestType: 'sliceFactory',
    pageSliceName: 'confirmation',
    version: '1',
    type: 'post',
  },
  confirmationMarket: {
    pageName: 'confirmationMarket',
    endpoint: endpoints.confirmationMarketPage,
    requestType: 'sliceFactory',
    pageSliceName: 'confirmationMarket',
    version: '1',
    type: 'post',
  },
  myTrips: {
    pageName: 'myTrips',
    endpoint: endpoints.myTrips,
    requestType: 'sliceFactory',
    pageSliceName: 'myTrips',
    version: '1',
  },
  myTripsDetails: {
    pageName: 'myTripsDetails',
    endpoint: endpoints.myTripsDetails,
    requestType: 'sliceFactory',
    pageSliceName: 'myTripsDetails',
    version: '1',
  },
  quoteRequestedConfirmation: {
    pageName: 'quoteRequestedConfirmation',
    endpoint: endpoints.quoteRequestedConfirmationPage,
    requestType: 'sliceFactory',
    pageSliceName: 'quoteRequestedConfirmation',
    version: '1',
    type: 'post',
  },
  account: {
    pageName: 'account',
    endpoint: endpoints.account,
    requestType: 'sliceFactory',
    pageSliceName: 'account',
    version: '1',
  },
  passengerPicker: {
    pageName: 'passengerPicker',
    endpoint: endpoints.passengerPicker,
    requestType: 'sliceFactory',
    pageSliceName: 'passengerPicker',
    version: '1',
  },
  passengerSummary: {
    pageName: 'passengerSummary',
    endpoint: endpoints.passengerSummary,
    requestType: 'sliceFactory',
    pageSliceName: 'passengerSummary',
    version: '1',
  },
  petSummary: {
    pageName: 'petSummary',
    endpoint: endpoints.petSummary,
    requestType: 'sliceFactory',
    pageSliceName: 'petSummary',
    version: '1',
  },
  passengerCreate: {
    pageName: 'passengerCreate',
    endpoint: endpoints.passengerCreateEdit,
    requestType: 'sliceFactory',
    pageSliceName: 'passengerCreate',
    version: '1',
  },
  selectPassengers: {
    pageName: 'selectPassengers',
    endpoint: endpoints.selectPassengers,
    requestType: 'sliceFactory',
    pageSliceName: 'selectPassengers',
    version: '1',
    type: 'post',
  },
  upcomingReservationTravelDetails: {
    pageName: 'upcomingReservationTravelDetails',
    endpoint: endpoints.upcomingReservationTravelDetails,
    requestType: 'sliceFactory',
    pageSliceName: 'upcomingReservationTravelDetails',
    version: '1',
  },
  airmedPayment: {
    pageName: 'airmedPayment',
    endpoint: endpoints.airmedPayment,
    requestType: 'sliceFactory',
    pageSliceName: 'airmedPayment',
    version: '1',
    type: 'post',
  },
  limitedTimeDeals: {
    pageName: 'limitedTimeDeals',
    endpoint: endpoints.limitedTimeDealsPage,
    requestType: 'sliceFactory',
    pageSliceName: 'limitedTimeDeals',
    version: '1',
  },
  manageLuggagePage: {
    pageName: 'manageLuggagePage',
    endpoint: endpoints.manageLuggagePage,
    requestType: 'sliceFactory',
    pageSliceName: 'manageLuggagePage',
    version: '1',
    type: 'get',
  },
  flightDetail: {
    pageName: 'flightDetail',
    endpoint: endpoints.flightDetail,
    requestType: 'sliceFactory',
    pageSliceName: 'flightDetail',
    version: '1',
    type: 'post',
  },
};

export const dataSliceOptions: IPageSliceOptions = {
  flightValidate: {
    pageName: 'flightValidate',
    endpoint: endpoints.flightValidate,
    requestType: 'requestFactory',
    pageSliceName: 'flightValidate',
    version: '1',
    type: 'post',
  },
  nearbyAirport: {
    pageName: 'nearbyAirport',
    endpoint: endpoints.nearbyAirport,
    requestType: 'requestFactory',
    pageSliceName: 'nearbyAirport',
    version: '1',
    type: 'get',
  },
  timePicker: {
    pageName: 'timePicker',
    endpoint: endpoints.timePicker,
    requestType: 'requestFactory',
    pageSliceName: 'timePicker',
    version: '1',
    type: 'get',
  },
  hubPanel: {
    pageName: 'hubPanel',
    endpoint: 'This is dynamically set in Hub.tsx',
    requestType: 'requestFactory',
    pageSliceName: 'hubPanel',
    version: '1',
    type: 'get',
  },
  datePicker: {
    pageName: 'datePicker',
    endpoint: endpoints.datePicker,
    requestType: 'requestFactory',
    pageSliceName: 'datePicker',
    version: '1',
    type: 'get',
  },
  hubWebToCaseSubmit: {
    pageName: 'hubWebToCaseSubmit',
    endpoint: endpoints.hubWebToCaseSubmit,
    requestType: 'requestFactory',
    pageSliceName: 'hubWebToCaseSubmit',
    version: '1',
    type: 'post',
  },
  bookOpportunity: {
    pageName: 'bookOpportunity',
    endpoint: endpoints.bookOpportunity,
    requestType: 'sliceFactory',
    pageSliceName: 'requestQuote',
    version: '1',
    type: 'post',
  },
  savedAirports: {
    pageName: 'savedAirports',
    endpoint: endpoints.savedAirports,
    requestType: 'requestFactory',
    pageSliceName: 'savedAirports',
    version: '1',
    type: 'post',
  },
  confirmContactDetails: {
    pageName: 'confirmationPopUp',
    endpoint: endpoints.confirmContactDetails,
    requestType: 'confirmContactDetails',
    pageSliceName: 'confirmationPopUp',
    version: '1',
    type: 'get',
  },
  savePreferredContact: {
    pageName: 'confirmationPopUp',
    endpoint: endpoints.savePreferredContact,
    requestType: 'savePreferredContact',
    pageSliceName: 'confirmationPopUp',
    version: '1',
    type: 'post',
  },
  postBookingCatering: {
    pageName: 'catering',
    endpoint: endpoints.postBookingCatering,
    requestType: 'postBookingCatering',
    pageSliceName: 'confirmationPopUp',
    version: '1',
    type: 'post',
  },
  postBookingGroundTransport: {
    pageName: 'groundTransportation',
    endpoint: endpoints.postBookingGroundTransport,
    requestType: 'postBookingGroundTransport',
    pageSliceName: 'confirmationPopUp',
    version: '1',
    type: 'post',
  },
  postBookingSpecialRequest: {
    pageName: 'specialRequest',
    endpoint: endpoints.postBookingSpecialRequest,
    requestType: 'postBookingSpecialRequest',
    pageSliceName: 'confirmationPopUp',
    version: '1',
    type: 'post',
  },
  selectPaymentRequest: {
    pageName: 'selectPaymentPopUp',
    endpoint: endpoints.selectPaymentRequest,
    requestType: 'getSelectPaymentPopUp',
    pageSliceName: 'SelectPaymentPopUp',
    version: '1',
    type: 'post',
  },
  addCreditCardRequest: {
    pageName: 'addCreditCardPopUp',
    endpoint: endpoints.addCreditCardRequest,
    requestType: 'addCreditCardPopUp',
    pageSliceName: 'addCreditCardPopUp',
    version: '1',
    type: 'get',
  },
  saveCreditCardRequest: {
    pageName: 'saveCreditCardPopUp',
    endpoint: endpoints.saveCreditCardRequest,
    requestType: 'saveCreditCardPopUp',
    pageSliceName: 'saveCreditCardPopUp',
    version: '1',
    type: 'post',
  },
  updatePriceBreakdownRequest: {
    pageName: 'updatePriceBreakdown',
    endpoint: endpoints.updatePriceBreakdown,
    requestType: 'updatePriceBreakdown',
    pageSliceName: 'updatePriceBreakdown',
    version: '1',
    type: 'post',
  },
  checkWireRequest: {
    pageName: 'checkWirePopUp',
    endpoint: endpoints.checkWireRequest,
    requestType: 'getCheckWirePopUp',
    pageSliceName: 'checkWirePopUp',
    version: '1',
    type: 'get',
  },
  flightLegLuggageItemInfo: {
    pageName: 'flightLegLuggageItem',
    endpoint: endpoints.updateLuggageList,
    requestType: 'requestFactory',
    pageSliceName: 'flightLegLuggageItemInfoSlice',
    version: '1',
    type: 'put',
  },
  passengerSelection: {
    pageName: 'passengerSelection',
    endpoint: endpoints.updatePassengerList,
    requestType: 'requestFactory',
    pageSliceName: 'passengerSelection',
    version: '1',
    type: 'put',
  },
  amexEligibility: {
    pageName: 'amexEligiblity',
    endpoint: endpoints.amexEligibilityCheck,
    requestType: 'requestFactory',
    pageSliceName: 'amexEligiblity',
    version: '1',
    type: 'post',
  },
  flightDeals: {
    pageName: 'flightDeals',
    endpoint: endpoints.flightDeals,
    requestType: 'requestFactory',
    pageSliceName: 'flightDeals',
    version: '1',
    type: 'post',
  },
  termsOfService: {
    pageName: 'termsOfService',
    endpoint: endpoints.termsOfService,
    requestType: 'requestFactory',
    pageSliceName: 'termsOfService',
    version: '1',
    type: 'post',
  },
  selectLuggage: {
    pageName: 'selectLuggage',
    endpoint: endpoints.selectLuggage,
    requestType: 'requestFactory',
    pageSliceName: 'selectLuggage',
    version: '1',
    type: 'post',
  },
  selectAirmedPaymentRequest: {
    pageName: 'selectAirmedPaymentPopUp',
    endpoint: endpoints.selectAirmedPaymentRequest,
    requestType: 'getSelectAirmedPaymentPopUp',
    pageSliceName: 'SelectAirmedPaymentPopUp',
    version: '1',
    type: 'post',
  },
  flyDeals: {
    pageName: 'flyPageDeals',
    endpoint: endpoints.flyPageDeals,
    requestType: 'requestFactory',
    pageSliceName: 'flyDeals',
    version: '1',
    type: 'get',
  },
};
