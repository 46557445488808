import { IPet } from '@components/types';
import {
  endpoints,
  getAuthHeaders,
  pegasusClient,
} from '@services/apiService';
import { NavigateFunction } from 'react-router-dom';
import { ISubPagePetAddEditState } from './SubPagePetAddEdit.slice';

export const createPet = async (
  state: ISubPagePetAddEditState,
  navigate: NavigateFunction,
) => {
  const headers = getAuthHeaders();
  const petState = {...state};
  petState.weight = Number(petState.weight);
  await pegasusClient.post(
    `${endpoints.petCreate}?version=1&platform=web`,
    { pet: { ...petState } },
    {
      headers: headers,
    },
  );
  navigate(-1);
};

export const editPet = async (
  state: ISubPagePetAddEditState,
  pet: IPet,
  navigate: NavigateFunction,
) => {
  const headers = getAuthHeaders();
  const petState = {...state};
  petState.weight = Number(petState.weight);

  await pegasusClient.put(
    `${endpoints.petCreate}?version=1&platform=web`,
    { pet: { ...petState, id: pet.id } },
    {
      headers: headers,
    },
  );
  navigate(-1);
};

export const deletePet = async (pet: IPet | undefined) => {
  const headers = getAuthHeaders();
  await pegasusClient.delete(
    `${endpoints.petCreate}?version=1&platform=web&id=${pet?.id}`,
    {
      headers: headers,
    },
  );
};
