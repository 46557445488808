import styled from 'styled-components';
import {
  MenuItem,
  MenuList,
  Popper,
} from '@components/library';
import { solidColors } from '@constants/styles/colors.constants';

export const StyledPopover = styled(Popper)`
  color: ${solidColors.upWhite.color};
  z-index: 50;
`;

export const StyledPopper = styled(Popper)`
  color: ${solidColors.upWhite.color};
  padding: 0;
  margin: 0;
  z-index: 10;
`;

export const StyledMenu = styled(MenuList)`
  width: 528px;
  height: 534px;
`;

export const Subpage = styled.div`
  display: flex;
`;

export const MenuItemContainer = styled(MenuItem)`
  width: auto;
  text-align: left;
  white-space: pre-wrap;
  padding-left: 3px;
  padding-right: 3px;
`;

export const InstructionsContainer = styled(MenuItem)`
  min-height: 24px;
  width: auto;
  text-align: left;
  white-space: pre-wrap;
  padding: 0;
  margin-top: 10px;
`;

export const HeaderContainer = styled(MenuItem)`
  min-height: 24px;
  margin-top: 24px;
  width: auto;
  text-align: left;
  white-space: pre-wrap;
  padding: 0;
`;

export const MenuItemContainerEmptyState = styled(MenuItem)`
  min-height: 24px;
  width: auto;
  text-align: center;
  white-space: pre-wrap;
  padding: 0;
  display: flex;
  justify-content: center;
`;

export const AirportCollectionHeaderContainer = styled.div`
  padding-top: 16px;
  padding-bottom: 16px;
`;
