import { IButton } from '@atoms/Button/Button.interfaces';
import Button from '@atoms/Button/Button';
import { ButtonContainer } from './ButtonView.styles';

interface IButtonView {
  button: IButton;
}

export default function ButtonView({
  button,
}: IButtonView) {
  /**
   * This is a one off for `/my-trips/:id`.
   * This is also used on dashboard where the button needs to be aligned left.
   */
  const textAlign = button.title === 'CANCEL FLIGHT' ? 'center' : 'left';
  return (
    <ButtonContainer styleType={button?.styleType}>
      <Button
        textAlign={textAlign}
        width="15rem"
        height="3rem"
        {...button}
      />
    </ButtonContainer>
  );
}
