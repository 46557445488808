import styled from 'styled-components';
import { FormControlLabel } from '@library';
import { IStyledRadio } from './Radio.interfaces';

export const RadioControlLabel = styled(
  FormControlLabel,
)<IStyledRadio>`
  margin-left: ${({ $marginLeft }) => $marginLeft ?? '0'};
  margin-right: ${({ $marginRight }) =>
    $marginRight ?? '0'};
`;
