import { useViewport } from '@hooks';
import ArrowIconButton from '@molecules/ArrowIconButton/ArrowIconButton';
import { useEffect, useRef, useState } from 'react';
import { IButtonContainerProps } from './CarouselButtonContainer.interfaces';
import { ButtonContainerWrapper } from './CarouselButtonContainer.styles';

const CarouselButtonContainer = ({
  disabledBack,
  disabledForward,
  backFunction,
  forwardFunction,
  marginTop,
  imageCarousel,
}: IButtonContainerProps) => {
  const [buttonWidth, setButtonWidth] = useState(1136);
  const widthRef = useRef(null);
  const { width } = useViewport();

  useEffect(() => {
    // 1220 is based off the largest possible card that we have (upcoming flight card)
    document.body.clientWidth < 1220
      ? setButtonWidth(width - 100)
      : setButtonWidth(1136);
  }, [width]);
  return (
    <ButtonContainerWrapper
      data-name="CarouselButtonContainer"
      marginTop={marginTop}
      ref={widthRef}
    >
      {disabledBack ? (
        <div>{/*/Empty When disabled/*/}</div>
      ) : (
        <ArrowIconButton
          imageCarousel={imageCarousel}
          disabled={disabledBack}
          clickHandler={backFunction}
        />
      )}

      {disabledForward ? (
        <div>{/*/Empty When disabled/*/}</div>
      ) : (
        <ArrowIconButton
          imageCarousel={imageCarousel}
          forward
          widthRem={buttonWidth / 16}
          disabled={disabledForward}
          clickHandler={forwardFunction}
        />
      )}
    </ButtonContainerWrapper>
  );
};
export default CarouselButtonContainer;
