import { ISubPageSelectPayment } from './SubPageSelectPayment.interfaces';
import {
  StyledSubPageCheckWireTransfer,
  StyledTitleContainer,
  StyledLine,
  StyledAddEditButton,
} from './SubPageSelectPayment.styles';
import { typography } from '@constants/styles/typography.constants';
import { solidColors } from '@constants/styles/colors.constants';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { CustomRadio } from '@molecules';
import Typography from '@atoms/Typography/Typography';
import { useEffect, useState } from 'react';
import {
  setLocationDisableNext,
  setPaymentLocation,
  setSelectedPaymentMethods,
} from '@features/Fly/Payment/PaymentStatusSlice';
import { IKeyValue, IPayment } from '@components/types';
import { trackAnalytics } from '@services/mixpanelService/mixpanel.service';

const AirmedSelectPayment = ({
  title,
  subtitle,
  totalCostCaption,
  totalCostAmount,
  totalCostValue,
  selectionType,
  paymentOptions,
  addCreditCardButton,
  checkOrWireTransferButton,
}: ISubPageSelectPayment) => {
  const dispatch = useAppDispatch();

  const {
    selectedCredits,
    selectedPaymentMethods,
  } = useAppSelector((state) => state.paymentStatus);

  const [
    paymentOptionList,
    setPaymentOptionList,
  ] = useState<IPayment[]>([]);

  const [remaining, setRemaining] = useState(0);

  useEffect(() => {
    setPaymentOptions(
      paymentOptions.filter(
        (option) => option.isPreselected,
      ),
    );
    setPaymentOptionList(paymentOptions);
  }, [paymentOptions]);

  useEffect(() => {
    if (
      remaining <= 0 &&
      selectedPaymentMethods.length > 0
    ) {
      dispatch(setLocationDisableNext(false));
    } else {
      dispatch(setLocationDisableNext(true));
    }
  }, [remaining]);

  const setPaymentOptions = (
    newSelectedPaymentList: IPayment[],
  ) => {
    setPaymentOptionList(newSelectedPaymentList);
    const newSelectedPaymentMethods = newSelectedPaymentList.filter(
      (payment: IPayment) => payment.isPreselected,
    );
    if (totalCostValue) {
      let newRemaining = totalCostValue;
      newSelectedPaymentMethods.forEach((option) => {
        newRemaining =
          newRemaining -
          (option.preselectedSplitValue || 0);
      });
      setRemaining(newRemaining);
    } else {
      setRemaining(0);
    }

    dispatch(
      setSelectedPaymentMethods(newSelectedPaymentMethods),
    );
  };

  const handleSelectedPaymentOptionsChange = (
    paymentOption: IPayment,
    isChecked: boolean,
  ) => {
    const newSelectedPayment = paymentOptionList.map(
      (mapPaymentOption) => {
        if (mapPaymentOption.id === paymentOption.id) {
          return {
            ...paymentOption,
            isPreselected: isChecked,
            preselectedSplitValue: totalCostValue,
          };
        } else {
          return {
            ...mapPaymentOption,
            isPreselected: false,
          };
        }
      },
    );
    setPaymentOptions(newSelectedPayment);
  };

  const handlePaymentButton = (type: string) => {
    if (type === 'addCreditCardPopUp') {
      const actionAnalytics: unknown = {
        key: 'Page Name',
        value: 'Airmed Payment',
      };
      trackAnalytics({
        trackingTitle:
          'Select_AddCreditCardMethodAirMed_Button',
        properties: [actionAnalytics] as IKeyValue[],
      });

      dispatch(setPaymentLocation('addCreditCardPopUp'));
    } else if (type === 'checkWirePopUp') {
      const actionAnalytics: unknown = {
        key: 'Page Name',
        value: 'Airmed Payment',
      };
      trackAnalytics({
        trackingTitle:
          'Select_CheckWireMethodAirMed_Button',
        properties: [actionAnalytics] as IKeyValue[],
      });

      dispatch(setPaymentLocation('checkWirePopUp'));
    }
  };

  const isDisabled = (paymentOption: IPayment) => {
    if (
      paymentOption.paymentType === 'fund' &&
      totalCostValue > (paymentOption.balance || 0)
    ) {
      return true;
    }
    return false;
  };

  return (
    <StyledSubPageCheckWireTransfer>
      {title && (
        <StyledTitleContainer>
          <Typography
            variant={typography.heading03Large}
            color={solidColors.midnight}
          >
            {title}
          </Typography>
          {subtitle && (
            <Typography
              variant={typography.body1}
              color={solidColors.darkGray}
            >
              {subtitle}
            </Typography>
          )}
        </StyledTitleContainer>
      )}

      {totalCostCaption && totalCostAmount && (
        <StyledLine separator={true}>
          <Typography
            variant={typography.body1}
            color={solidColors.midnight}
          >
            {totalCostCaption}
          </Typography>
          <Typography
            variant={typography.heading03Large}
            color={solidColors.midnight}
          >
            {totalCostAmount}
          </Typography>
        </StyledLine>
      )}

      {paymentOptionList &&
        paymentOptionList.map(
          (paymentOption: IPayment, index: number) => (
            <StyledLine key={index}>
              <CustomRadio
                label={
                  <>
                    <Typography
                      variant={typography.body1}
                      color={
                        isDisabled(paymentOption)
                          ? solidColors.darkGray
                          : solidColors.midnight
                      }
                    >
                      {paymentOption.displayValue1}
                    </Typography>
                    <Typography
                      variant={typography.body2}
                      color={
                        isDisabled(paymentOption)
                          ? solidColors.lava
                          : solidColors.midnight
                      }
                    >
                      {paymentOption.isPreselected &&
                      (paymentOption.paymentType ===
                        'fund' ||
                        paymentOption.paymentType ===
                          'atoFund') &&
                      remaining > 0
                        ? `Insufficient fund: ${paymentOption.displayValue2}`
                        : paymentOption.displayValue2}
                    </Typography>
                  </>
                }
                checked={
                  paymentOption.isPreselected || false
                }
                onClick={() =>
                  handleSelectedPaymentOptionsChange(
                    paymentOption,
                    true,
                  )
                }
                disabled={isDisabled(paymentOption)}
              />
            </StyledLine>
          ),
        )}

      {addCreditCardButton && (
        <StyledAddEditButton
          title={addCreditCardButton?.title}
          styleType={'icon_leading'}
          action={{
            actionMethod: () => {
              handlePaymentButton('addCreditCardPopUp');
            },
          }}
          image={addCreditCardButton?.image}
        />
      )}

      {checkOrWireTransferButton && (
        <StyledAddEditButton
          title={checkOrWireTransferButton?.title}
          styleType={'icon_leading'}
          action={{
            actionMethod: () => {
              handlePaymentButton('checkWirePopUp');
            },
          }}
          image={checkOrWireTransferButton?.image}
        />
      )}
    </StyledSubPageCheckWireTransfer>
  );
};

export default AirmedSelectPayment;
