import {
  StyledTitle,
  StyledList,
  StyledItem,
} from './DetailListItemList.styles';
import { IDetailListItemList } from './DetailListItemList.interfaces';
import { Typography, Button } from '@atoms';
import { typography } from '@constants/styles/typography.constants';
import { solidColors } from '@constants/styles/colors.constants';

const DetailListItemList = ({
  title,
  items,
}: IDetailListItemList) => {
  return (
    <>
      <StyledTitle>
        <Typography
          variant={typography.body1b}
          color={solidColors.midnight}
        >
          {title}
        </Typography>
      </StyledTitle>
      {items && (
        <StyledList>
          {items.map((item, index) => (
            <StyledItem key={index}>
              {item.title}
              {item.detail}
            </StyledItem>
          ))}
        </StyledList>
      )}
    </>
  );
};

export default DetailListItemList;
