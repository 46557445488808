import { RootState } from '@app/store';
import { FormFieldTitleDropDown, Typography } from '@atoms';
import CardFlightDealResult from '@components/molecules/CardFlightDealResult/CardFlightDealResult';
import { typography } from '@constants/styles/typography.constants';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ISubPageFlightDealResults } from './SubPageFlightDealResults.interfaces';
import { setSortValue } from './SubPageFlightDealResults.slice';
import {
  CardContainer,
  HeaderContainer,
  StyledContainer,
} from './SubPageFlightDealResults.styles';

export default function SubPageFlightDealResults({
  id,
  numberOfFlightsText,
  sortDropDownField,
  flightDealResults,
}: ISubPageFlightDealResults) {
  const dispatch = useDispatch();

  const { sortValue, hasChanged } = useSelector(
    (state: RootState) => state.subPageFlightDealResults,
  );

  const handleSortChange = (value: string) => {
    dispatch(setSortValue(value));
  };

  useEffect(() => {
    if (!hasChanged)
      dispatch(setSortValue(sortDropDownField.items[2]));
  });

  return (
    <StyledContainer
      id={id}
      data-testid={id}
      data-name="SubPageFlightDealResults"
    >
      <HeaderContainer>
        <Typography variant={typography.heading01Large}>
          {numberOfFlightsText}
        </Typography>
        <div>
          <FormFieldTitleDropDown
            {...sortDropDownField}
            value={sortValue}
            onChangeHandler={(option) =>
              handleSortChange(option)
            }
          />
        </div>
      </HeaderContainer>
      <div>
        {flightDealResults.map((fdr) => (
          <CardContainer key={fdr.data.id}>
            <CardFlightDealResult {...fdr.data} />
          </CardContainer>
        ))}
      </div>
    </StyledContainer>
  );
}
