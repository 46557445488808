import styled from 'styled-components';
import { withStyles } from '@material-ui/core';
import {
  Accordion,
  AccordionSummary,
  Container,
} from '@library';
import { Button, ImageImgix } from '@atoms';
import { solidColors } from '@constants/styles/colors.constants';
import { theme } from '@constants/styles/theme.constants';

export const StyledCardAccordionHighMidPriorityAlert = styled(
  Container,
)<{ $inCarousel: boolean }>`
  display: flex;
  min-width: 21.25rem;
  width: 100%;
  justify-content: center;

  ${({ $inCarousel }) => {
    if ($inCarousel) {
      return `
        width: 21.25rem;
      `;
    }
  }}

  @media (max-width: ${theme.breakpoints.values.md}px) {
    width: 100%;
  }

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    min-width: 335px;
    width: 100%;
  }
`;

export const BoxCenterStyled = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
`;

export const AccordionHeadlineSpacer = styled.div`
  margin-left: 15px;
  text-align: left;
`;

export const CenteredAccordionSummary = withStyles({
  root: {
    minHeight: 60,
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'flex-start',
    paddingLeft: '16px !important',
  },
  content: {
    flexGrow: 0,
  },
})(AccordionSummary);

export const EmbeddedViewContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 1rem;
`;

export const StyledAccordion = styled(Accordion)`
  width: 100%;
  border-radius: 8px !important;
  box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.08),
    0px 2px 6px 1px rgba(0, 0, 0, 0.12);
`;

export const StyledIcon = styled(ImageImgix)`
  align-self: flex-start;
`;

export const ButtonStyled = styled(Button)`
  padding: 0;
`;

export const AccordionDivider = styled.hr`
  width: 95%;
  border: 1px solid ${solidColors.mediumGray.color};
`;
