import {
  getFromSessionStorage,
  saveDataInSessionStorage,
} from '@components/utils/storage';
import { setError } from '@features/Fly/SessionSlice';
import { AppDispatch } from './../../../app/store';

let storeDispatch: AppDispatch;

export const injectSessionStore = (_store: any) => {
  storeDispatch = _store.dispatch;
};

export const isSessionValid = () => {
  const bookingFlowSession = getFromSessionStorage(
    'BOOKING_FLOW_SESSION',
  );
  if (bookingFlowSession.active) {
    const diffMs =
      Date.now() - bookingFlowSession.timestamp;
    const timeElapsed = Math.floor(diffMs / 1000 / 60);
    if (timeElapsed >= 30) {
      return false;
    }
    return true;
  } else {
    return true;
  }
};

export const setSession = () => {
  saveDataInSessionStorage('BOOKING_FLOW_SESSION', {
    active: true,
    timestamp: Date.now(),
  });

  setTimeout(() => {
    if (!isSessionValid()) {
      storeDispatch(setError(true));
    }
  }, 1800000);
};
export const endSession = () => {
  saveDataInSessionStorage('BOOKING_FLOW_SESSION', {
    active: false,
  });
};
