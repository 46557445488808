import { IEmbeddedVideoPlayer } from './EmbeddedVideoPlayer.interfaces';
import {
  StyledEmbeddedVideoPlayer,
  VideoContainer,
} from './EmbeddedVideoPlayer.styles';

const EmbeddedVideoPlayer = ({
  url,
}: IEmbeddedVideoPlayer) => {
  return (
    <VideoContainer>
      <StyledEmbeddedVideoPlayer
        src={url}
        frameBorder="0"
        allowFullScreen
      />
    </VideoContainer>
  );
};

export default EmbeddedVideoPlayer;
