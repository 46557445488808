import { Typography } from '@atoms';
import { ISwitchButton } from '@components/types';
import { Color } from '@constants/styles/colors.constants';
import { theme } from '@constants/styles/theme.constants';
import {
  ITypographyObject,
  typography,
} from '@constants/styles/typography.constants';
import { Switch } from '@wheelsup/wu-react-components';
import React, { useState } from 'react';
import styled from 'styled-components';

export interface ITextGroupSwitch extends ISwitchButton {
  copy: string;
  direction: 'left' | 'right';
  textStyle?: ITypographyObject;
  color: Color;
  spaceBetweenDesktop?: boolean;
}

const StyledContainer = styled.div<{
  direction: 'left' | 'right';
  spaceBetweenDesktop?: boolean;
}>`
  display: flex;
  flex-direction: ${({ direction }) =>
    direction === 'left' ? 'row' : 'row-reverse'};
  align-items: center;
  width: ${({ spaceBetweenDesktop }) =>
    spaceBetweenDesktop ? '100%' : 'fit-content'};
  justify-content: ${({ spaceBetweenDesktop }) =>
    spaceBetweenDesktop ? 'space-between' : 'flex-start'};
  @media (max-width: ${theme.breakpoints.values.sm}px) {
    justify-content: space-between;
    width: auto;
  }
`;

const SwitchContainer = styled.div<{
  direction: 'left' | 'right';
}>`
  margin-right: ${({ direction }) =>
    direction === 'right' ? '16px' : '0'};
  margin-left: ${({ direction }) =>
    direction === 'left' ? '16px' : '0'};
`;

export default function TextGroupSwitch({
  checked,
  copy,
  direction,
  disabled,
  color,
  textStyle,
  spaceBetweenDesktop,
  onChange,
}: ITextGroupSwitch) {
  const [isChecked, setIsChecked] = useState(checked);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setIsChecked(!isChecked);
    onChange(e);
  };

  return (
    <StyledContainer
      direction={direction}
      spaceBetweenDesktop={spaceBetweenDesktop}
    >
      <Typography
        variant={textStyle ?? typography.body2}
        color={color}
      >
        {copy}
      </Typography>
      <SwitchContainer direction={direction}>
        <Switch
          id={copy}
          onChange={handleChange}
          checked={isChecked}
          disabled={disabled}
        />
      </SwitchContainer>
    </StyledContainer>
  );
}
