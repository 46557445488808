import { Typography } from '@atoms';
import { typography } from '@constants/styles/typography.constants';
import { IListItemTitleIconBody } from './ListItemTitleIconBody.interfaces';
import {
  FlexContainer,
  TextContainer,
} from './ListItemTitleIconBody.styles';
import BuildHyperlinkedText from '@atoms/BuildHyperlikedText/BuildHyperlinkedText';
import { useNavigate } from 'react-router-dom';
import { solidColors } from '@constants/styles/colors.constants';
import useViewport from '@hooks/use-viewport';
import { PartialInternationalNumber } from './ListItemTitleIconBody.regex';
import { Box } from '@atoms';

const ListItemTitleIconBody = ({
  title,
  surfaceAction,
  subtitleTextView,
  index,
  items,
  ...rest
}: IListItemTitleIconBody) => {
  const { width } = useViewport();
  const navigate = useNavigate();

  let copy = subtitleTextView?.copy ?? '';
  if (PartialInternationalNumber.test(copy)) {
    copy = `+1 ${copy}`;
  }

  return (
    <FlexContainer
      {...rest}
      surfaceAction={surfaceAction}
      onClick={() => {
        if (surfaceAction) {
          if (surfaceAction.type === 'phone') {
            window.open(
              `tel:${surfaceAction.data}`,
              '_self',
            );
          } else if (surfaceAction.type === 'email') {
            window.open(
              `mailto:${surfaceAction.data}`,
              '_self',
            );
          } else {
            navigate(surfaceAction.data);
          }
        }
      }}
    >
      <Typography
        variant={typography.body1}
        color={solidColors.darkGray}
      >
        {title}
      </Typography>
      <TextContainer>
        <Typography
          variant={
            width <= 600
              ? typography.heading03Large
              : typography.heading03Large
          }
          color={solidColors.upBlue}
        >
          {BuildHyperlinkedText(
            subtitleTextView?.textAttributes?.links,
            copy,
          )}
        </Typography>
      </TextContainer>
      {index !== undefined &&
      items?.length !== index + 1 ? (
        <Box
          marginTop={2.5 * 8}
          marginBottom={2.5 * 8}
        />
      ) : (
        <></>
      )}
    </FlexContainer>
  );
};

export default ListItemTitleIconBody;
