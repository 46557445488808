import styled from 'styled-components';
import { ISurfaceAction } from './ListItemTitleIconBody.interfaces';

export const FlexContainer = styled.div<ISurfaceAction>`
  display: flex;
  padding-bottom: 30px;
  align-items: flex-start;
  flex-direction: column;
  cursor: ${(props) =>
    props.surfaceAction ? 'pointer' : ''};
`;

export const TextContainer = styled.div`
  padding-top: 0.3em;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
