import styled from 'styled-components';
import { solidColors } from '@constants/styles/colors.constants';
import { Button, Typography } from '@atoms';

export const FooterContainer = styled.div<{
  background: string;
}>`
  display: flex;
  flex-direction: column;
  background-color: ${({ background }) =>
    background === 'white'
      ? background
      : solidColors.midnight.color};
  height: 390px;
  padding: 100px 144px;

  ${(props) => props.theme.breakpoints.down('sm')} {
    height: auto;
    padding: 80px 115px;
  }
  ${(props) => props.theme.breakpoints.down('xs')} {
    height: auto;
    padding: 50px 50px;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;

  ${(props) => props.theme.breakpoints.down('sm')} {
    flex-direction: column;
  }
`;

export const StyledHr = styled.hr`
  border-top: 1px solid ${solidColors.lightGray.color};
  width: 100%;
`;

export const LegalContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 15px;

  ${(props) => props.theme.breakpoints.down('sm')} {
    flex-direction: column;
  }
`;

export const LegalCopy = styled.div`
  display: flex;
  flex-basis: 65%;

  ${(props) => props.theme.breakpoints.down('sm')} {
    margin-bottom: 2rem;
  }
`;

export const SocialContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  ${(props) => props.theme.breakpoints.down('sm')} {
    order: 3;
    margin-top: 4rem;
  }
`;

export const SocialItems = styled.div`
  display: flex;
  margin: 40px 0px;
  width: 250px;
  gap: 20px;
`;

export const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 210px;

  ${(props) => props.theme.breakpoints.down('sm')} {
    order: 1;
  }
`;

export const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;

  ${(props) => props.theme.breakpoints.down('sm')} {
    order: 2;
    margin-top: 2rem;
  }
`;

export const TitleStyled = styled(Typography)<{
  background: string;
}>`
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 3.43px;
  line-height: 20px;
  margin-bottom: 30px;
  width: 100%;

  ${({ background }) =>
    background === 'white'
      ? ''
      : `color: ${solidColors.upWhite.color}`};
`;

export const LinkStyled = styled.div<{
  background: string;
  $color?: boolean;
}>`
  margin: 4px 0;
  .primary {
    letter-spacing: 2px;
    font-size: 13px;
    font-weight: 700;
    line-height: 30px;
    ${({ background }) =>
      background === 'white'
        ? `color: ${solidColors.darkGray.color} `
        : `color: ${solidColors.upWhite.color} `}
  }

  a {
    font-weight: 400;
    ${({ background }) =>
      background === 'white'
        ? `color: ${solidColors.midnight.color} !important`
        : `color: ${solidColors.upWhite.color} !important`}
  }

  a {
    ${({ background, $color }) =>
      $color === true &&
      background !== 'blue' &&
      `color: ${solidColors.upBlue.color} !important`}
  }
`;

export const ButtonStyled = styled(Button)<{
  background: string;
}>`
  padding: 10px;
  padding-right: 23px;
  height: fit-content;
  width: fit-content;
`;

export const CopyStyled = styled.div<{
  background: string;
}>`
  .primary {
    ${({ background }) =>
      background === 'white'
        ? `color: ${solidColors.darkGray.color} `
        : `color: ${solidColors.upWhite.color} `}
  }
`;
