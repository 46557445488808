import { Checkbox } from '@wheelsup/wu-react-components';
import TexGroupBodyCopyLink from '../TextGroupBodyCopyLink/TextGroupBodyCopyLink';
import { ICheckboxBodyCopyLink } from './TextGroupCheckboxBodyCopyLink.interfaces';

const TextGroupCheckboxBodyCopyLink = ({
  titleTextGroup,
  onChange,
  isPreselected,
  isEnabled = true,
}: ICheckboxBodyCopyLink) => {
  return (
    <>
      <Checkbox
        onChange={onChange}
        checked={!!isPreselected}
        disabled={!isEnabled}
        size="sm"
        label={
          <TexGroupBodyCopyLink
            copy={titleTextGroup.title}
            textAttributes={{
              links: titleTextGroup.textAttributes?.links,
            }}
          />
        }
      />
    </>
  );
};

export default TextGroupCheckboxBodyCopyLink;
