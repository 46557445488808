import {
  ISlideProp,
  ISlideGroup,
} from './Carousel.interfaces';
import styled from 'styled-components';

export const SlideWrapper = styled.div<ISlideProp>`
  flex: 0 0 auto;
  opacity: ${(props) =>
    props.active ? 1 : props.hideInactive ? 0.0 : 0.4};
  transition: all 0.5s ease;
`;

export const SlideContainer = styled.div`
  margin-left: 1rem;
  margin-right: 1rem;
  height: 100%;
  width: 500px;
  display: flex;
  align-items: center;
`;

export const StyledCarousel = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  width: max-content;
  padding-left: 3rem;
`;

export const ButtonContainerWrapper = styled.div`
  display: flex;
  width: 440px;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  align-content: center;
`;

export const CarouselWrapper = styled.div`
  align-items: center;
  justify-content: flex-start;
  margin-left: -1rem;
`;

export const SlideGroupWrapper = styled.div<ISlideGroup>`
  display: flex;
  transform: ${({ transformAmount }) =>
    transformAmount != 0
      ? `translateX(-${transformAmount}px);`
      : 'none'};
  transition: all 1s ease;
  justify-content: ${({ singleCard }) =>
    singleCard ? 'center' : 'flex-start'};
  position: relative;
  width: 100%;
`;
