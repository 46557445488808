import React from 'react';
import { AccordionBase, Button, Typography } from '@atoms';
import { ICardAccordionBulletPoints } from './CardAccordionBullletPoints.interfaces';
import {
  ChildContainer,
  ChildSpan,
} from './CardAccordionBullletPoints.styles';
import { typography } from '@constants/styles/typography.constants';
import { solidColors } from '@constants/styles/colors.constants';

function CardAccordionBulletPoints({
  id,
  title,
  subtitle = '',
  image,
  dropDownImage,
  isExpandedByDefault = false,
  items,
}: ICardAccordionBulletPoints) {
  const renderListItems = () => {
    const itemList = Array.isArray(items) ? items : [items];
    return itemList.map((child, index) => {
      const key = `card-accordion-bullet-points-item-${index}`;
      const { title, button } = child;
      return (
        <ChildContainer
          key={key}
          data-test-id="card-accordion-bullet-points__item"
        >
          &bull;{' '}
          <ChildSpan>
            <Typography
              variant={typography.body1a}
              color={solidColors.midnight}
            >
              {`${title} `}
            </Typography>
            {button && (
              <Button
                {...button}
                styleType="text"
              />
            )}
          </ChildSpan>
        </ChildContainer>
      );
    });
  };

  return (
    <AccordionBase
      id={id}
      title={title}
      subtitle={subtitle}
      image={image}
      dropDownImage={dropDownImage}
      isExpandedByDefault={isExpandedByDefault}
    >
      {renderListItems()}
    </AccordionBase>
  );
}

export default CardAccordionBulletPoints;
