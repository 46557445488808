import {
  TextContainer,
  TypographyStyled,
  RowWrapper,
  ContentContainer,
  CopyStyled,
  CopyContainer,
  ItemContainer,
  StyledTrailIcon,
  StyledButton,
} from './TextGroupIconText.styles';
import { ITextGroupIcon } from './TextGroupIconText.interfaces';
import { typography } from '@constants/styles/typography.constants';
import { solidColors } from '@constants/styles/colors.constants';
import { Icons } from '@library';
import { ImageImgix, Typography } from '@atoms';
import { Typography as SharedLibraryTypography } from '@wheelsup/wu-react-components';

const TextGroupIconText = ({
  headline,
  headlineVariant,
  extraCopyVariant,
  extraCopyColor,
  icon,
  isSpecial,
  color,
  headerWeight,
  headerSize,
  copySize,
  copy,
  extraCopy,
  isSelected,
  isButton,
  image,
  buttonTitle,
  flexDirection,
  margin,
  buttonAction,
  subCopy,
  subCopy2,
}: ITextGroupIcon) => {
  return (
    <ItemContainer
      onClick={() => {
        buttonAction ? buttonAction() : null;
      }}
      flexDirection={flexDirection}
    >
      <TextContainer>
        {icon}

        <ContentContainer>
          <RowWrapper>
            <TypographyStyled
              variant={
                headlineVariant
                  ? headlineVariant
                  : typography.body1
              }
              color={color ?? solidColors.midnight}
              headerWeight={headerWeight}
              headerSize={headerSize}
            >
              {headline}
            </TypographyStyled>
            {extraCopy && (
              <Typography variant={typography.body2}>
                {extraCopy}
              </Typography>
            )}
          </RowWrapper>

          <CopyContainer>
            {isSpecial && (
              <ImageImgix
                src={image?.url as string}
                width={20}
                height={20}
                fit="crop"
                alt="Description"
                filter={image?.tintColor}
              />
            )}
            <CopyStyled
              variant={extraCopyVariant ? extraCopyVariant : typography.body2}
              libraryColor={extraCopyColor}
              color={color ?? solidColors.darkGray}
              copySize={copySize ?? '15px'}
            >
              {copy}
            </CopyStyled>
          </CopyContainer>
          
          {
            subCopy
            ? (
              <SharedLibraryTypography
                variant='body02'
                color='secondary'
              >
                {subCopy}
              </SharedLibraryTypography>
            ) : null
          }
          {
            subCopy2
            ? (
              <SharedLibraryTypography
                variant='body02'
                color='secondary'
              >
                {subCopy2}
              </SharedLibraryTypography>
            ) : null
          }
        </ContentContainer>
      </TextContainer>
      {isSelected ? (
        <StyledTrailIcon>
          <Icons.Check
            style={{
              color: solidColors.basil.color,
            }}
          />
        </StyledTrailIcon>
      ) : null}
      {isButton && (
        <StyledButton
          margin={margin}
          styleType="card_secondary_web"
          title={buttonTitle}
          action={{}}
          nativeIcon={
            <Icons.ChevronRight
              style={{
                color: solidColors.upBlue.color,
              }}
            />
          }
        />
      )}
    </ItemContainer>
  );
};

export default TextGroupIconText;
