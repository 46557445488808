import FormFieldEmailEntry from '@atoms/FormFieldEmailEntry/FormFieldEmailEntry';
import PhoneInput from '@atoms/PhoneInput';
import { Grid } from '@components/library';
import styled from 'styled-components';

export const GridStyled = styled(Grid)`
  margin: 24px 0;
`;

export const EmailInputStyled = styled(FormFieldEmailEntry)`
  width: 100%;
`;

export const StyledPhoneInput = styled(PhoneInput)`
  width: 100%;
  max-width: 286px;
  
  ${(props) => props.theme.breakpoints.down('sm')} {
    max-width: none;
  }
`;
