import { useState, useRef, useContext, useEffect } from 'react';
import {
  PassengerPickerContainer,
  HrStyled,
  PickerGroup,
} from './PassengerPicker.styles';
import { useOnClickOutside, useViewport } from '@hooks';
import CustomSubpage from '@components/molecules/CustomSubpage/CustomSubpage';
import { IPassengerPicker } from './PassengerPicker.interfaces';
import TextGroupBodyCopySubCopy from '@molecules/TextGroupBodyCopySubCopy/TextGroupBodyCopySubCopy';
import { FlightSearchContext } from '../SubPageFlightSearchV2/FlightSearchContext/FlightSearchContext';
import {
  PickerContainer,
  PickerWrapper,
} from './PassengerPicker.styles';
import TextGroupIconText from '../TextGroupIconText/TextGroupIconText';
import { TitleIconContainer } from '..';
import MinimalStepper from './MinimalStepper';
import {
  passengerPickerConstants,
  petPickerConstants,
} from './pickerConstants';
import { useFlightSearch } from '../SubPageFlightSearchV2/FlightSearchContext/FlightSearchHooks';
import { v4 as uuidv4 } from 'uuid';

const PassengerPicker = ({
  legNumber,
}: IPassengerPicker) => {
  const {
    legs,
    updateLeg,
    flightType,
    isRoundTrip,
    contextDispatch
  } = useFlightSearch(useContext(FlightSearchContext));
  const leg = legs[legNumber];

  const [passengerCount, setPassengerCount] = useState(1);
  const [petCount, setPetCount] = useState(0);

  // We might have a predefined number of passengers and pets
  useEffect(() => {
    setPassengerCount(leg.numberOfPassengers);
  }, [leg.numberOfPassengers]);

  useEffect(() => {
    setPetCount(leg.numberOfPets);
  }, [leg.numberOfPets]);

  const popupRef = useRef(null);

  const { width } = useViewport();

  const pickers = [
    {
      ...passengerPickerConstants,
      setter: setPassengerCount,
      count: passengerCount,
      uid: `PassengerPicker0Leg${legNumber}${uuidv4()}`,
    },
    {
      ...petPickerConstants,
      setter: setPetCount,
      count: petCount,
      uid: `PassengerPicker1Leg${legNumber}${uuidv4()}`,
    },
  ];

  const handleContinue = (shouldOpenNewPicker: boolean) => {
    const currLeg = {
      ...leg,
      numberOfPassengers: passengerCount,
      numberOfPets: petCount,
    };
    if (isRoundTrip && legNumber === 0) {
      contextDispatch({
        payload: {
          legs: [ {
            ...currLeg,
            passengersOpen: false,
          }, {
            ...legs[1],
            passengersOpen: shouldOpenNewPicker,
          }]
        },
        type:  'replaceLegsRoundTrip'
      });
    
    } else if (isRoundTrip && legNumber === 1) {
      updateLeg(
        {
          ...currLeg,
          passengersOpen: false,
        },
        1,
      );
    } else {
      updateLeg(
        {
          ...currLeg,
          passengersOpen: false,
        },
        legNumber,
      );
    }
  };

  useOnClickOutside(popupRef, () => handleContinue(false));

  return (
    <PassengerPickerContainer>
      {leg.passengersOpen && (
        <PickerWrapper ref={popupRef}>
          <CustomSubpage
            width="464px"
            height="290px"
            action={() => handleContinue(true)}
          >
            <>
              <TextGroupBodyCopySubCopy
                title={flightType}
                copy={
                  'Please tell us how many are traveling so we can find the right aircraft for you.'
                }
              />

              {pickers.map((picker) => {
                const {
                  image,
                  count,
                  setter,
                  stepper,
                  title,
                  uid
                } = picker;
                return (
                  <PickerGroup key={uid}>
                    <HrStyled />
                    <PickerContainer>
                      <TextGroupIconText
                        headerSize={
                          width > 1000 ? undefined : '16px'
                        }
                        headline={title}
                        icon={
                          <TitleIconContainer
                            width="64px"
                            height="64px"
                            image={image.url}
                          />
                        }
                      />
                      <MinimalStepper
                        count={count}
                        setCount={setter}
                        limits={stepper}
                      />
                    </PickerContainer>
                  </PickerGroup>
                );
              })}
            </>
          </CustomSubpage>
        </PickerWrapper>
      )}
    </PassengerPickerContainer>
  );
};

export default PassengerPicker;
