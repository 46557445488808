import { createSlice } from '@reduxjs/toolkit';

interface IState {
  open: boolean;
}

const initialState: IState = {
  open: false,
};

export const aircraftDetailsDisplay = createSlice({
  name: 'handleShowAircraftDetails',
  initialState,
  reducers: {
    handleShowAircraftDetails: (state) => {
      state.open = !state.open;
    },
  },
});

export const {
  handleShowAircraftDetails,
} = aircraftDetailsDisplay.actions;

export const AircraftDetailsReducer =
  aircraftDetailsDisplay.reducer;

export default AircraftDetailsReducer;
