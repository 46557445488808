export const passengerPickerConstants = {
  title: 'Passengers',
  image: {
    url:
      'https://35f0adf900eefe4e8c20-9ae5866a59bf2b247845fad2f9434a9a.ssl.cf5.rackcdn.com/documents/adult-passenger_16927244419.svg',
  },
  stepper: {
    minValue: 1,
    maxValue: 25,
  },
};

export const petPickerConstants = {
  title: 'Pets',
  image: {
    url:
      'https://35f0adf900eefe4e8c20-9ae5866a59bf2b247845fad2f9434a9a.ssl.cf5.rackcdn.com/documents/pet-passenger_16927244309.svg',
  },
  stepper: {
    minValue: 0,
    maxValue: 25,
  },
};
