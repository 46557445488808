import styled from 'styled-components';
import { Typography } from '@atoms';
import { solidColors } from '@constants/styles/colors.constants';

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const LabelContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
`;
export const CopyContainer = styled.div`
  display: flex;
  gap: 5px;
  color: ${solidColors.midnight.color};
`;

export const TypographyStyled = styled(Typography)<{
  headerWeight?: string;
  headerSize?: string;
}>`
  font-size: ${({ headerSize }) => headerSize ?? '24px'};
  font-weight: ${({ headerWeight }) => headerWeight ?? ''};
`;

export const RowWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

export const CopyStyled = styled(Typography)<{
  copySize?: string;
}>`
  font-size: ${({ copySize }) => copySize ?? '18px'};
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 140px;
`;

export const StyledTrailIcon = styled.div`
  margin-right: 1rem;
`;
