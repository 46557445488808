import React, {
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { Typography } from '@atoms';
import { Icons } from '@components/library';
import { solidColors } from '@constants/styles/colors.constants';
import { typography } from '@constants/styles/typography.constants';
import { IListCTAIconHeadline } from './ListCTAIconHeadline.interfaces';
import {
  AvatarStyled,
  ItemBulletCombined,
  ItemStyled,
  TextContainer,
  StyledTableRow,
  SubtitleContainerStyled,
  StyledTableCell,
  IconButtonStyled,
} from './ListCTAIconHeadline.styles';
import useCTA from './ListCTAIconHeadline.hooks';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '@app/hooks';
import { setAccountSubpage } from '@features/Account/AccountSubpageSlice';
import { saveDataInLocalStorage } from '@components/utils/storage';
import { checkIsTokenValid } from '@services/authService/authService';
import { trackAnalytics } from '@services/mixpanelService/mixpanel.service';
import { IAnalytics } from '@services/dynamicRenderingService/dynamicRendering.interfaces';
import getServerAction from '@atoms/Button/getServerAction';

export const ACCOUNT_SUBPAGE_WEBVIEW_KEY =
  'ACCOUNT_SUBPAGE_WEBVIEW_KEY';
export const ACCOUNT_SUBPAGE_WEBVIEW_TITLE =
  'ACCOUNT_SUBPAGE_WEBVIEW_TITLE';

const ListCTAIconHeadline = ({
  title,
  subtitle,
  icon,
  button,
  id,
  image,
  index = 0,
  handleAccountPageOverride,
}: IListCTAIconHeadline) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const ref = useRef<HTMLTableRowElement>(null);
  const [rowWidth, setRowWidth] = useState(0);

  const [sidebarIndex, updateCTA] = useCTA();

  useLayoutEffect(() => {
    const width = ref.current?.offsetWidth;
    setRowWidth(width ?? 0);
  }, []);

  const selectCta = () => {
    if (
      button &&
      button?.action.type === 'in_app_membersite'
    ) {
      dispatch(
        setAccountSubpage({
          accountSubpage: button.action.data,
          title,
        }),
      );
      saveDataInLocalStorage(ACCOUNT_SUBPAGE_WEBVIEW_KEY, {
        webviewKey: button.action.data,
      });
      saveDataInLocalStorage(
        ACCOUNT_SUBPAGE_WEBVIEW_TITLE,
        { webviewTitle: title },
      );
      saveDataInLocalStorage(
        'shouldShowLoadingPageWeb',
        true,
      );
      checkIsTokenValid();
      if (button.action.actionAnalytics) {
        trackAnalytics(
          button.action.actionAnalytics as IAnalytics,
        );
      }

      navigate(
        button?.action?.data?.split('/src/client')[1] ?? '',
      );
    } else if (button) {
      if (
        button.action.data?.includes('//travelInformation')
      ) {
        const btnUrl = new URL(button.action.data || '');
        const tripId = btnUrl.searchParams.get('id');
        navigate(`/upcoming-details/${tripId}`);
      } else if (
        button.action.data?.includes('//manageCreditCards')
      ) {
        navigate('/account/manage-credit-cards');
      } else if (button) {
        getServerAction({
          navigate,
          dispatch,
          action: button.action,
          buttonAction: button.action || {},
        });
      }
    }
    button?.action?.actionMethod &&
      button.action.actionMethod();
  };

  const selectCTAItem = () => {
    updateCTA(id, button?.action.data);
  };

  if (index === 0 && !sidebarIndex) {
    selectCTAItem();
  }

  const isMyTrips = window.location.pathname.includes(
    'my-trips',
  );

  return (
    <StyledTableRow
      $isMyTrip={isMyTrips}
      ref={ref}
      className={`${sidebarIndex === id ? 'active' : ''}`}
      data-testid={`toggle-content-${id}`}
    >
      <StyledTableCell $width={rowWidth}>
        <ItemStyled
          onClick={(event) => {
            if (handleAccountPageOverride) {
              handleAccountPageOverride(event);
            } else {
              selectCTAItem();
              selectCta();
            }
          }}
          data-testid="go-action"
        >
          <ItemBulletCombined data-id="ItemBulletCombined">
            {icon && (
              <AvatarStyled
                src={icon.url}
                filter={icon.tintColor}
                alt="avatar"
                width={isMyTrips ? 24 : 48}
                height={isMyTrips ? 24 : 48}
              />
            )}

            <TextContainer data-id="TextContainer">
              <Typography
                variant={typography.subheadA}
                color={solidColors.c1}
              >
                {title ?? button?.title}
              </Typography>
              <SubtitleContainerStyled>
                {image && (
                  <AvatarStyled
                    src={image.url}
                    filter={image.tintColor}
                    alt="icon"
                    width={20}
                    height={20}
                  />
                )}

                <Typography
                  variant={typography.body2a}
                  color={solidColors.c6}
                >
                  {subtitle}
                </Typography>
              </SubtitleContainerStyled>
            </TextContainer>
          </ItemBulletCombined>
          <IconButtonStyled>
            <Icons.NavigateNext
              style={{
                color: solidColors.upBlue.color,
              }}
            />
          </IconButtonStyled>
        </ItemStyled>
      </StyledTableCell>
    </StyledTableRow>
  );
};

export default ListCTAIconHeadline;
