import { IBannerSubcopyImage } from './BannerSubcopyImage.interfaces';
import {
  BannerContainer,
  BannerContent,
  BannerImage,
} from './BannerSubcopyImage.styles';
import { typography } from '@constants/styles/typography.constants';
import { solidColors } from '@constants/styles/colors.constants';
import { Typography, ImageImgix } from '@atoms';

const BannerSubcopyImage = ({
  subcopy,
  image,
}: IBannerSubcopyImage) => {
  return (
    <BannerContainer>
      <BannerContent>
        <Typography
          variant={typography.h5a}
          color={solidColors.c15}
          align="left"
        >
          {subcopy}
        </Typography>
      </BannerContent>
      <BannerImage>
        <ImageImgix
          src={image.url}
          isSrcSet
          fit="crop"
          alt="Description"
          isBackground
          bgPosition="top center"
        />
      </BannerImage>
    </BannerContainer>
  );
};

export default BannerSubcopyImage;
