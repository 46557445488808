import PhoneNumberMask from '@atoms/PhoneNumberMask';
import {
  CustomCssTextField,
  inputUseStyles,
} from '@components/molecules/Input/Input.styles';
import { US_COUNTRY_CODE } from '@constants/measurements';
import { FormHelperText, InputAdornment } from '@library';
import PhoneIcon from '@material-ui/icons/Phone';
import { IPhoneInput } from './PhoneInput.interfaces';

export default function PhoneInput({
  error,
  maxCharactersAllowed,
  minCharactersAllowed,
  payloadKey,
  value,
  onChange,
  title,
  errorMessage,
  phoneIcon,
  isRequired,
  ...rest
}: IPhoneInput) {
  const classes = inputUseStyles({ error });

  return (
    <>
      <CustomCssTextField
        {...rest}
        required={isRequired}
        variant="outlined"
        name={payloadKey}
        label={title}
        value={value}
        onChange={onChange}
        InputLabelProps={{
          classes: {
            root: classes.label,
          },
        }}
        FormHelperTextProps={{
          classes: {
            root: classes.helperText,
          },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              {US_COUNTRY_CODE}
            </InputAdornment>
          ),
          inputComponent: PhoneNumberMask as any,
          endAdornment: phoneIcon ? (
            <InputAdornment position="end">
              <PhoneIcon />
            </InputAdornment>
          ) : null,
        }}
        inputProps={{
          maxLength: maxCharactersAllowed,
          minLength: minCharactersAllowed,
        }}
        error={error}
      />
      {error && (
        <FormHelperText error>
          {errorMessage ?? 'Error'}
        </FormHelperText>
      )}
    </>
  );
}
