import { Typography } from '@atoms';
import { solidColors } from '@constants/styles/colors.constants';
import { ArrowLeft } from '@material-ui/icons';
import styled from 'styled-components';

export const PickerContainer = styled.div`
  height: 152px;
  width: inherit;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
`;

export const TypographyNumber = styled(Typography)`
  size: 60px;
`;

export const Icon = styled(ArrowLeft)<{
  $increment?: boolean;
}>`
  transform: ${({ $increment }) =>
    $increment ? 'rotate(90deg)' : 'rotate(270deg)'};
  filter: ${solidColors.upBlue.tintColor};
  font-size: 2.5rem;
`;
