import { IStyledCheckboxUnchecked } from './CheckboxUnchecked.interfaces';
import { Icons } from '@library';
import styled from 'styled-components';
import { solidColors } from '@constants/styles/colors.constants';

export const StyledCheckboxUnchecked = styled(
  Icons.CheckBoxOutlineBlankOutlined,
)<IStyledCheckboxUnchecked>`
  background-color: ${({ $background }) =>
    $background ?? 'transparent'};

  svg {
    color: ${({ $borderColor }) =>
      $borderColor ?? solidColors.mediumGray};
  }
`;
