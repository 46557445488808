import { CardMedia as MuiCardMedia } from '@material-ui/core';
import styled from 'styled-components';
import { styleConstants } from '../../constants/styles/styles.constants';
import { surfaceColors } from '../../constants/styles/colors.constants';

export interface Props {
  height?: string;
  bgcolor?: string;
}

const CardMedia: any = styled(MuiCardMedia)<Props>`
  height: ${styleConstants.cardMedia.height};
  background: ${(props) =>
    props.bgcolor || surfaceColors.backgroundUpWhite.color};
`;

export default CardMedia;
