import {
  TextStyleType,
  newTypographyStylesSet,
} from '@components/types';
import {
  TypographyStyleTranslation,
  ColorStyleTranslation,
} from './newStyleTranslation';

const handleNewTypographyType = (styleType?: string) => {
  if (styleType) {
    const newTypographyType = styleType
      .split('-')
      .map((word, index) => {
        if (index === 0) {
          return word;
        }
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join('');
    return newTypographyType;
  } else {
    return '';
  }
};

const handleNewColorType = (styleType?: string) => {
  if (styleType) {
    const newColorType = styleType
      ?.split(' ')
      .map((word, index) => {
        if (index === 0) {
          return (
            word.charAt(0).toLowerCase() + word.slice(1)
          );
        }
        return word;
      })
      .join('');
    return newColorType;
  } else {
    return '';
  }
};

export const deconstructStyleType = (
  styleType?: TextStyleType,
) => {
  if (!styleType) {
    return {};
  } else if (newTypographyStylesSet.has(styleType)) {
    const typographyType = handleNewTypographyType(
      styleType.split('_')[0],
    );
    const colorType = handleNewColorType(
      styleType.split('_')[1],
    );
    return { typographyType, colorType };
  } else {
    const splitStyleType = styleType.split('_');

    let typographyType =
      splitStyleType[0] + splitStyleType[1];
    if (styleType.includes('strikethrough')) {
      typographyType += 'Strikethrough';
    }
    let colorType = splitStyleType[2];

    typographyType = TypographyStyleTranslation(
      typographyType,
    );

    colorType = ColorStyleTranslation(colorType);

    return { typographyType, colorType };
  }
};
