import { ICardSearchResultsSearchCriteriaOverview } from './CardSearchResultsSearchCriteriaOverview.interfaces';
import {
  StyledContainer,
  StyledContainerHeader,
} from './CardSearchResultsSearchCriteriaOverview.styles';
import { Container } from '@components/library';
import FlightInfoHeader from '../HeadlineWithBackArrow/FlightInfoHeader';

export default function CardSearchResultsSearchCriteriaOverview({
  legs,
}: ICardSearchResultsSearchCriteriaOverview) {
  return (
    <StyledContainer
      data-name="CardSearchResultsSearchCriteriaOverview"
      id="CardSearchResultsSearchCriteriaOverview"
    >
      <StyledContainerHeader data-name="StyledContainerHeader">
        <Container style={{ justifyContent: 'center' }}>
          <FlightInfoHeader legs={legs} />
        </Container>
      </StyledContainerHeader>
    </StyledContainer>
  );
}
