import { DialogContentText } from '../library';
import styled from 'styled-components';

const DialogContentTextAtom: any = styled(
  DialogContentText,
)`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export default DialogContentTextAtom;
