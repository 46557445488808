import { solidColors } from '@constants/styles/colors.constants';
import {
  Divider,
  Typography,
} from '@wheelsup/wu-react-components';
import {
  Container,
  StyledDepartureArrivalContainer,
  StyledElevation,
} from './ContainerMultiLegTripFlightSummary.styles';
import { IContainerMultiLegTripFlightSummary } from './ContainerMultiLegTripFlightSummary.interfaces';
import { ListAccordionItem } from '@molecules';
import Spacer from '@atoms/Spacer/Spacer';

const ContainerMultiLegTripFlightSummary = ({
  button,
  children,
  spacerTypeTop,
  spacerTypeBottom,
}: IContainerMultiLegTripFlightSummary) => {
  return (
    <>
      {spacerTypeTop && <Spacer height={spacerTypeTop} />}
      <StyledElevation>
        <Container data-name="ContainerMultiLegTripFlightSummary">
          <StyledDepartureArrivalContainer>
            <Typography
              variant="heading01"
              className="typoBackground"
            >
              {`${children.length} flights`}
            </Typography>
          </StyledDepartureArrivalContainer>
          <Divider spacing={0} />
          <ListAccordionItem
            title={button.title ?? ''}
            body={children}
            direction={'reverse'}
            headerType={'cta'}
            typographyColor={solidColors.upBlue}
          />
        </Container>
      </StyledElevation>
      {spacerTypeBottom && (
        <Spacer height={spacerTypeBottom} />
      )}
    </>
  );
};

export default ContainerMultiLegTripFlightSummary;
