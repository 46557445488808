import {
  FormControl,
  FormHelperText,
  Icons,
} from '@components/library';
import { solidColors } from '@constants/styles/colors.constants';
import styled from 'styled-components';

export const FormControlStyled = styled(FormControl)`
  min-width: 100%;
`;

export const StyledFormHelperText = styled(FormHelperText)`
  margin: 0 !important;
  min-width: 100%;
`;

export const ExpandIconStyles = styled(Icons.ExpandMore)`
  color: ${solidColors.upBlue.color};
`;
