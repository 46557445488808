import { IContainerCardElements } from './ContainerCardElementsWEBONLY.interfaces';
import {
  StyledCard,
  CardContent,
} from './ContainerCardElementsWEBONLY.styles';

const ContainerCardElementsWEBONLY = ({
  children,
}: IContainerCardElements) => {
  return (
    <StyledCard>
      <CardContent>{children}</CardContent>
    </StyledCard>
  );
};

export default ContainerCardElementsWEBONLY;
