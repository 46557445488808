// Currently not a server driven component

import { IImage } from '@components/types';
import { useViewport } from '@hooks';
import React, { useEffect, useRef, useState } from 'react';
import { ICarouselSmallArrowSelectedImageWebOnly } from './CarouselSmallArrowSelectedImageWebOnly.interfaces';
import {
  ArrowCircle,
  ArrowContainer,
  ArrowLabel,
  BackArrow,
  CarouselContainer,
  CarouselSlideWrapper,
  ForwardArrow,
  StyledCloseIcon,
  StyledIconButton,
} from './CarouselSmallArrowSelectedImageWebOnly.styles';
import Slides from './Slides';

function CarouselSmallArrowSelectedImageWebOnly({
  images = [],
  closeButtonAction,
}: ICarouselSmallArrowSelectedImageWebOnly) {
  const { width } = useViewport();
  const parentContainer = useRef<HTMLDivElement>(null);
  const [
    parentContainerWidth,
    setParentContainerWidth,
  ] = useState(735);
  const imageHeightRatio = 500 / 735;
  const slide = document.getElementsByClassName(
    'CarouselSmallArrowSelectedImageWebOnlySlide-slide',
  )[0];
  const largeSlide = document.getElementsByClassName(
    'CarouselSmallArrowSelectedImageWebOnlySlide-slide-large',
  )[0];
  // slidesPerView is how many cards should be 'active' at a time
  const [slidesPerView, setSlidesPerView] = useState(0);
  // total slides is the total number of views it will take to reach the end of the carousel
  const [totalSlides, setTotalSlides] = useState(0);
  const [slideWidth, setSlideWidth] = useState(0);
  const [largeSlideWidth, setLargeSlideWidth] = useState(0);
  const [transformWidth, setTransformWidth] = useState(0);
  const [slideView, setSlideView] = useState(1);
  const [startIndex, setStartIndex] = useState(0);
  const [activeForward, setActiveForward] = useState(true);
  const [activeBack, setActiveBack] = useState(false);
  const [
    allActiveFallback,
    setAllActiveFallback,
  ] = useState(false);
  const slideMargin = 12;
  const slides: IImage[] = Array.isArray(images)
    ? images
    : [images];
  // const parentContainerWidth = 735;
  const [
    largeTansformWidth,
    setLargeTansformWidth,
  ] = useState(0);

  useEffect(() => {
    // Handles sizing for smaller slides
    const parentWidth =
      parentContainer.current?.offsetWidth || 1;
    const childWidth = slide?.clientWidth || 1;
    const currSlidesPerView = Math.floor(
      parentWidth / childWidth,
    );
    setSlideWidth(childWidth + slideMargin);
    const currTotalSlides = slides.length;
    setSlidesPerView(currSlidesPerView);
    if (currTotalSlides === Infinity) {
      setTotalSlides(slides.length);
      setAllActiveFallback(true);
    } else {
      setTotalSlides(currTotalSlides);
      setAllActiveFallback(false);
    }

    // Handles sizing for larger slides
    const largeChildWidth = largeSlide?.clientWidth || 1;
    setLargeSlideWidth(largeChildWidth);
    setParentContainerWidth(parentWidth);

    const resetSlideView = () => {
      /*
               When user changes the viewport size, we need to reset the carousel to the first slide
               to avoid some bad behavior
           */
      setStartIndex(0);
      setTransformWidth(0);
      setSlideView(1);
      setActiveForward(true);
      setActiveBack(false);
    };
    resetSlideView();
  }, [slides, slide, width]);

  useEffect(() => {
    if (slideView === 1) {
      setActiveBack(false);
    } else {
      setActiveBack(true);
    }
    if (slideView === totalSlides) {
      setActiveForward(false);
    } else {
      setActiveForward(true);
    }
  }, [slideView, totalSlides]);

  const handleArrowForward = (e: React.MouseEvent) => {
    e.preventDefault();
    if (activeForward) {
      setTransformWidth(transformWidth - slideWidth);
      setLargeTansformWidth(
        largeTansformWidth - largeSlideWidth,
      );
      setStartIndex(startIndex + 1);
      setSlideView(slideView + 1);
    }
  };

  const handleArrowBack = (e: React.MouseEvent) => {
    e.preventDefault();
    if (activeBack) {
      setTransformWidth(transformWidth + slideWidth);
      setLargeTansformWidth(
        largeTansformWidth + parentContainerWidth,
      );
      setStartIndex(startIndex - 1);
      setSlideView(slideView - 1);
    }
  };

  if (slides.length === 0) {
    // adding a fallback incase we get 0 children
    return null;
  }

  const closeSubpage = () => {
    if (closeButtonAction) {
      closeButtonAction();
    }
  };

  return (
    <div data-id="CarouselSmallArrowSelectedImageWebOnly">
      <div data-id="closeButtonContainer">
        <StyledIconButton onClick={closeSubpage}>
          <StyledCloseIcon />
        </StyledIconButton>
        <CarouselContainer ref={parentContainer}>
          <CarouselSlideWrapper
            $isMobile={allActiveFallback}
            $isLarge
          >
            <Slides
              transformAmount={largeTansformWidth}
              slidesPerView={1}
              startIndex={startIndex}
              slideMargin={0}
              allActiveFallback={allActiveFallback}
              images={slides}
              imageWidth={parentContainerWidth}
              imageHeight={
                parentContainerWidth * imageHeightRatio
              }
              isLarge
            />
          </CarouselSlideWrapper>
        </CarouselContainer>
      </div>
      {images.length > 1 && (
        <CarouselContainer ref={parentContainer}>
          <CarouselSlideWrapper
            $isMobile={allActiveFallback}
          >
            <Slides
              transformAmount={transformWidth}
              slidesPerView={slidesPerView}
              startIndex={startIndex}
              slideMargin={slideMargin}
              allActiveFallback={allActiveFallback}
              images={slides}
              imageWidth={160}
              imageHeight={115}
            />
          </CarouselSlideWrapper>
          {!allActiveFallback && (
            <ArrowContainer>
              <ArrowCircle
                $active={activeBack}
                onClick={handleArrowBack}
                data-slide-num={slideView}
              >
                <BackArrow $active={activeBack} />
              </ArrowCircle>
              <ArrowLabel>
                {slideView} of {totalSlides}
              </ArrowLabel>
              <ArrowCircle
                $active={activeForward}
                onClick={handleArrowForward}
                data-slide-num={slideView}
              >
                <ForwardArrow $active={activeForward} />
              </ArrowCircle>
            </ArrowContainer>
          )}
        </CarouselContainer>
      )}
    </div>
  );
}

export default CarouselSmallArrowSelectedImageWebOnly;
