import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { typography } from '@constants/styles/typography.constants';
import { Typography } from '@atoms';
import { IListAccordionItem } from './ListAccordionItem.interfaces';
import {
  StyledAccordion,
  StyledAccordionSummary,
  StyledAccordionDetails,
  StyledGrid,
} from './ListAccordionItem.styles';
import { solidColors } from '@constants/styles/colors.constants';
import { StyledExpandIcon } from './ListAccordionItem.styles';

const ListAccordionItem = ({
  title,
  body,
  direction,
  headerType,
  typographyColor,
}: IListAccordionItem) => {
  const getHeaderType = () => {
    switch (headerType) {
      case 'cta':
        return {
          typography: typography.cta,
          class: 'cta',
        };
      case 'header-menu':
      case 'menu':
        return {
          typography: typography.body2Bold,
          class: 'menu',
        };
      default:
        return {
          typography: typography.body1,
          class: '',
        };
    }
  };

  return (
    <StyledAccordion
      className={direction ?? ''}
      $isHeader={headerType === 'header-menu'}
    >
      <StyledAccordionSummary
        expandIcon={
          headerType === 'header-menu' ? (
            <StyledExpandIcon />
          ) : (
            <ExpandMoreIcon />
          )
        }
        className={getHeaderType().class}
      >
        <Typography
          variant={getHeaderType().typography}
          color={typographyColor || solidColors.darkGray}
        >
          {title}
        </Typography>
      </StyledAccordionSummary>
      <StyledAccordionDetails>
        {typeof body === 'string' ? (
          <Typography
            variant={typography.body1}
            color={solidColors.darkGray}
          >
            {body}
          </Typography>
        ) : (
          <StyledGrid className="styled-grid">
            {body}
          </StyledGrid>
        )}
      </StyledAccordionDetails>
    </StyledAccordion>
  );
};

export default ListAccordionItem;
