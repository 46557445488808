import { IBulletedList } from './ListViewTitleCopy.interfaces';
import { TextGroupBodyLink } from '@molecules';
import {
  TermTitle,
  TermList,
  TermListItem,
  TermText,
} from './ListViewTitleCopy.styles';
import { Typography } from '@atoms';
import { typography } from '@constants/styles/typography.constants';

export default function ListViewTitleCopy({
  title,
  bulleted = false,
  bodyCopys,
}: IBulletedList) {
  return (
    <>
      <TermTitle>
        <Typography variant={typography.body2Bold}>
          {title}
        </Typography>
      </TermTitle>
      <TermList $hasBullets={bulleted}>
        {bodyCopys.map((term) => (
          <TermListItem
            key={term.copy}
            $hasBullets={bulleted}
          >
            <TermText
              variant={typography.body2}
              $hasBullets={bulleted}
            >
              <TextGroupBodyLink {...term} />
            </TermText>
          </TermListItem>
        ))}
      </TermList>
    </>
  );
}
