import { DateTime } from 'luxon';
import { IAirportItem } from '../FormFieldAirportSearchDashboard/SearchFlight.interfaces';
import { IAlertData } from '../FormFlightLeg/SearchFlight.interfaces';

export const initialSubpageData: TSubpageData = {
  homeImage: {
    url: '',
  },
  deleteImage: {
    url: '',
  },
  pinImage: {
    url: '',
  },
  title: '',
  savedAirportsInstructions: '',
  savedAirportsHeader: '',
  recentlySearchedAirportsHeader: '',
  highDensityCopy: '',
  conditionalCopy: '',
  savedAirports: [],
  recentlySearchedAirports: [],
  nearbyAirports: [],
  emptyAirportListsCopy: '',
};

export type TSubpageData = {
  homeImage: {
    url: string;
  };
  deleteImage: {
    url: string;
  };
  pinImage: {
    url: string;
  };
  title: string;
  savedAirportsInstructions: string;
  savedAirportsHeader: string;
  recentlySearchedAirportsHeader: string;
  highDensityCopy: string;
  conditionalCopy: string;
  savedAirports: IAirportItem[];
  recentlySearchedAirports: IAirportItem[];
  nearbyAirports: IAirportItem[];
  emptyAirportListsCopy: string;
};

export type FlightObjType = {
  departureAirport: IAirportItem | null;
  arrivalAirport: IAirportItem | null;
  airportError?: string | undefined;
  date: Date | null;
  dateString?: string;
  dateOpen?: boolean;
  dateError?: any;
  dateErrorString?: string | null;
  departTime: Date;
  errors?: IAlertData[];
  timeError?: string | null;
  timeErrorsString?: string[];
  timeOpen?: boolean;
  numberOfPassengers: number;
  numberOfPets: number;
  passengersOpen?: boolean;
  passengersError?: string | null;
};

export const standardFlightObj: FlightObjType = {
  departureAirport: null,
  arrivalAirport: null,
  airportError: undefined,
  date: null,
  dateString: '',
  dateOpen: false,
  dateError: null,
  dateErrorString: null,
  departTime: DateTime.now()
    .startOf('day')
    .plus({ hours: 9 })
    .toJSDate(),
  errors: [],
  timeOpen: false,
  timeError: null,
  timeErrorsString: [''],
  numberOfPassengers: 1,
  numberOfPets: 0,
  passengersOpen: false,
  passengersError: null,
};
