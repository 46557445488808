import { getFromLocalStorage } from './storage';

interface Iv2MigratedRoutes {
  [key: string]: string;
}

export default function handleShouldRedirect() {
  const location = window.location.pathname;
  const hash = window.location.hash;
  const search = window.location.search;
  const MEMBER_SITE_V1 = '/src/client/';
  const v2MigratedRoutes: Iv2MigratedRoutes = {
    '/src/client/': '/',
  };
  const wupRedirect =
    getFromLocalStorage('wup-redirect') || '';

  // if (
  //     wupRedirect.len > 1 &&
  //     location in v2MigratedRoutes
  // ) {
  //     console.log(
  //         '%c location',
  //         'background: #222; color: #bada55',
  //         location,
  //     );
  //     const newLocation = v2MigratedRoutes[location];
  //     return {
  //         shouldRedirect: true,
  //         location: newLocation,
  //         hash: '',
  //         search: '',
  //         memberSite: '',
  //     };
  // } else
  if (location.indexOf(MEMBER_SITE_V1) >= 0) {
    console.log(
      '%c location.indexOf(MEMBER_SITE_V1) >= 0',
      'background: #222; color: #bada55',
      location.indexOf(MEMBER_SITE_V1) >= 0,
    );
    return {
      shouldRedirect: true,
      location: location,
      hash: hash,
      search: search,
      memberSite: MEMBER_SITE_V1,
    };
  } else {
    console.log(
      '%c should redirect else',
      'background: #222; color: #bada55',
    );
    return {
      shouldRedirect: false,
      location: '',
      hash: '',
      search: '',
      memberSite: '',
    };
  }
}
