export const saveDataInLocalStorage = (
  key: string,
  value: Object,
  stringify = true,
) =>
  localStorage.setItem(
    key,
    stringify ? JSON.stringify(value) : (value as string),
  );

export const saveDataInSessionStorage = (
  key: string,
  value: Object,
) => sessionStorage.setItem(key, JSON.stringify(value));

export const getFromLocalStorage = (
  key: string,
  jsonFlag = true,
) => {
  const data: string | null = localStorage.getItem(key);
  try {
    return jsonFlag ? JSON.parse(data as string) : data;
  } catch (err) {
    return null;
  }
};

export const getRemoveFromLocalStorage = (
  key: string,
  jsonFlag = true,
) => {
  const data: string | null = localStorage.getItem(key);
  localStorage.removeItem(key);
  try {
    return jsonFlag ? JSON.parse(data as string) : data;
  } catch (err) {
    return null;
  }
};

export const getFromSessionStorage = (key: string) => {
  const data = sessionStorage.getItem(key);
  return data ? JSON.parse(data) : null;
};

export const removeFromLocalStorage = (key: string) =>
  localStorage.removeItem(key);

export const removeFromSessionStorage = (key: string) =>
  sessionStorage.removeItem(key);

export const clearLocalStorage = () => localStorage.clear();

export const clearSessionStorage = () =>
  sessionStorage.clear();

export const isLocalStorageEmpty = () =>
  localStorage.length === 0;

export const isSessionStorageEmpty = () =>
  sessionStorage.length === 0;
