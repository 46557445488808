import { ImageImgix } from '@atoms';
import styled from 'styled-components';

export const FlexContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const TextContainer = styled.div`
  padding-top: 0.4em;
  display: flex;
  flex-direction: column;
`;

export const ImageStyled = styled(ImageImgix)`
  width: 2rem;
  padding: 0.2rem;
  margin-right: 1rem;
`;

export const ImageStyledContainer = styled.div`
  padding: 0.2rem;
  padding-right: 0.3rem;
`;
