import { IStyledCheckboxChecked } from './CheckboxChecked.interfaces';
import { Icons } from '@library';
import styled from 'styled-components';
import { solidColors } from '@constants/styles/colors.constants';

export const StyledCheckboxChecked = styled(
  Icons.CheckBoxOutlined,
)<IStyledCheckboxChecked>`
  background-color: ${({ $background }) =>
    $background ?? 'transparent'};

  svg {
    color: ${({ $borderColor }) =>
      $borderColor ?? solidColors.upBlue.color};
  }
`;
