import {
  closeSnackbar,
  openSnackbar,
} from '@components/molecules/CardSnackbar/CardSnackbarSlice';
import { Dispatch } from '@reduxjs/toolkit';
import { useRef } from 'react';

const useSnackbar = (dispatch: Dispatch<any>) => {
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const resetTimer = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(close, 3000);
  };

  const close = () => {
    dispatch(closeSnackbar());
  };

  const snackbarHandler = (
    copy: string,
    styleType?: string,
  ) => {
    dispatch(
      openSnackbar({
        snackBarCopy: copy,
        inApp: true,
        styleType,
      }),
    );
    resetTimer();
  };

  return snackbarHandler;
};

export default useSnackbar;
