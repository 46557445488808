import styled from 'styled-components';

export const StyledEmbeddedVideoPlayer = styled.iframe`
  height: 429.75px;
  width: 100%;
  max-width: 764px;
`;

export const VideoContainer = styled.div`
  display: flex;
  justify-content: center;
`;
