import { getFromLocalStorage } from '@components/utils/storage';

export default function getAuthHeaders() {
  const token = getFromLocalStorage('access_token');

  const authHeaders = {
    Authorization: `Bearer ${token}`,
    WUToken: token,
    'WU-UUID': 'WEB_APP',
  };
  return authHeaders;
}
