import React, { createContext, useReducer } from 'react';
import {
  BackgroundActions,
  backgroundReducer,
  Background,
} from './reducers/backgroundUser';
import {
  userReducer,
  UserActions,
  User,
} from './reducers/userReducer';

type InitialState = {
  user: User;
};

type InitialStateBackgroundState = {
  background: Background;
};

const initialState: InitialState = {
  user: {
    name: '',
    nickname: '',
  },
};

const initialBackgroundState: InitialStateBackgroundState = {
  background: {
    hasImage: false,
  },
};

const BackgroundContext = createContext<{
  backgroundState: InitialStateBackgroundState;
  backgroundDispatch: React.Dispatch<BackgroundActions>;
}>({
  backgroundState: initialBackgroundState,
  backgroundDispatch: () => null,
});

const AppContext = createContext<{
  state: InitialState;
  dispatch: React.Dispatch<UserActions>;
}>({
  state: initialState,
  dispatch: () => null,
});

const appReducer = (
  { user }: InitialState,
  action: UserActions,
) => ({
  user: userReducer(user, action),
});

const backgroundAppReducer = (
  { background }: InitialStateBackgroundState,
  action: BackgroundActions,
) => ({
  background: backgroundReducer(background, action),
});

interface IAppProvider {
  children: JSX.Element;
}

const AppProvider: React.FC<IAppProvider> = ({
  children,
}: IAppProvider) => {
  const [state, dispatch] = useReducer(
    appReducer,
    initialState,
  );

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};

const BackgroundProvider: React.FC<IAppProvider> = ({
  children,
}: IAppProvider) => {
  const [backgroundState, backgroundDispatch] = useReducer(
    backgroundAppReducer,
    initialBackgroundState,
  );

  return (
    <BackgroundContext.Provider
      value={{ backgroundState, backgroundDispatch }}
    >
      {children}
    </BackgroundContext.Provider>
  );
};

export {
  AppContext,
  AppProvider,
  BackgroundContext,
  BackgroundProvider,
};
