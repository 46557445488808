import { IImgTitleCopyStepperData } from '@components/molecules/ImageTitleSubtitleStepperListItemView/ImageTitleSubtitleStepperListItemView.interfaces';
import { putPassenger } from '@components/organisms/SubPagePassengerAddEdit/SubPagePassengerAddEdit.slice';
import {
  IPassenger,
  IPassengerLeg,
  IPet,
} from '@components/types';
import {
  getFromLocalStorage,
  removeFromLocalStorage,
  saveDataInLocalStorage,
} from '@components/utils/storage';
import {
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

export interface ILuggageData {
  [key: number]: {
    flightId: string;
    luggageData: IImgTitleCopyStepperData[];
  };
}

export interface ISelectedPassengerLegs {
  selectedPassengers: IPassenger[];
}

export interface ISelectedPetsLegs {
  selectedPets: IPet[];
}
export interface ISearchTripDetailsState {
  selectedLeg: number;
  luggages: ILuggageData;
  sameLuggagesFlag: boolean;
  luggagesPerLeg: number[];
  selectedPassengersLeg: ISelectedPassengerLegs[];
  selectedPetsLegs: ISelectedPetsLegs[];
  samePaxFlag: boolean;
  hasDataActive: boolean;
  showPassengerListAsCarousel: boolean;
  showAsCarousel: boolean;
  checkboxJustClicked: boolean;
  checkboxPassengersJustClicked: boolean;
  nextPage: boolean;
  maxNumberOfPassengers?: number;
  isEditingPassengers: boolean;
}

export const updatePassengerLegs = (
  passengerLegs: IPassengerLeg[],
  updatedPassenger: IPassenger,
) => {
  return passengerLegs.map((selectedPassengerLeg) => {
    return {
      selectedPassengers: selectedPassengerLeg.selectedPassengers.map(
        (passenger) => {
          if (passenger.id === updatedPassenger.id) {
            return {
              ...passenger,
              ...updatedPassenger,
            };
          }
          return passenger;
        },
      ),
    };
  });
};

const initialState: ISearchTripDetailsState = {
  selectedLeg: 0,
  luggages: [],
  sameLuggagesFlag: false,
  luggagesPerLeg: [],
  selectedPassengersLeg:
    getFromLocalStorage('selectedPassengersLegs') || [],
  selectedPetsLegs: [],
  samePaxFlag: false,
  hasDataActive: false,
  showPassengerListAsCarousel: false,
  showAsCarousel: false,
  checkboxJustClicked: false,
  checkboxPassengersJustClicked: false,
  nextPage: false,
  maxNumberOfPassengers: 0,
  isEditingPassengers: !!getFromLocalStorage(
    'isEditingPassengers',
  ),
};

const flySearchTripDetailsSlice = createSlice({
  name: 'flySearchTripDetailsComponents',
  initialState,
  reducers: {
    setSelectedLeg: (state, action: PayloadAction<any>) => {
      state.selectedLeg = action.payload;
    },
    clearAllFlyTripDetailsState: (state) => {
      state.luggages = [];
      state.selectedPassengersLeg = [];
      state.selectedPetsLegs = [];
      state.maxNumberOfPassengers = 0;
      removeFromLocalStorage('selectedPassengersLegs');
      removeFromLocalStorage('selectedPetsLegs');
      removeFromLocalStorage('leadPassenger');
    },
    setLuggages: (state, action: PayloadAction<any>) => {
      const { luggages, index, flightId } = action.payload;
      if (index !== null) {
        state.luggages = {
          ...state.luggages,
          [index]: {
            flightId: flightId,
            luggageData: luggages,
          },
        };
      } else if (index === null && flightId === null) {
        const luggageObj: ILuggageData = {};
        Object.entries(state.luggages).map((luggage) => {
          const l = luggage[1] as {
            flightId: string;
            luggageData: IImgTitleCopyStepperData[];
          };
          const i = Number(luggage[0]);
          luggageObj[i] = {
            flightId: l.flightId,
            luggageData: luggages,
          };
        });
        state.luggages = luggageObj;
      } else {
        state.luggages = luggages;
      }
    },
    setSameLuggagesFlag: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.sameLuggagesFlag = action.payload;
    },
    setSamePaxFlag: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.samePaxFlag = action.payload;
    },
    setTotalLuggagesPerLeg: (
      state,
      action: PayloadAction<any>,
    ) => {
      const { index, totalPerLeg } = action.payload;
      state.luggagesPerLeg[index] = totalPerLeg;
    },
    setClearLuggages: (state) => {
      state.luggages = {};
      state.sameLuggagesFlag = false;
      state.luggagesPerLeg = [];
      state.hasDataActive = false;
      state.showAsCarousel = false;
      state.checkboxJustClicked = false;
    },
    setSelectedPassengers: (
      state,
      action: PayloadAction<{
        index: number;
        selectedPassengers: ISelectedPassengerLegs;
      }>,
    ) => {
      const { index, selectedPassengers } = action.payload;
      const passengers = [
        ...selectedPassengers.selectedPassengers,
      ];
      const sorted = passengers.sort((a, b) =>
        a.firstName.localeCompare(b.firstName),
      );
      state.selectedPassengersLeg[index] = {
        selectedPassengers: sorted,
      };
      saveDataInLocalStorage(
        'selectedPassengersLegs',
        state.selectedPassengersLeg,
      );
      saveDataInLocalStorage(
        'leadPassenger',
        state.selectedPassengersLeg[
          Number(index)
        ]?.selectedPassengers.filter((x) => {
          return x.isLeadPassenger ? x : null;
        })[0],
      );
    },
    setSelectedPets: (
      state,
      action: PayloadAction<{
        index: number;
        selectedPets: ISelectedPetsLegs;
      }>,
    ) => {
      const { index, selectedPets } = action.payload;
      const sP = selectedPets?.selectedPets ?? [];
      const pets = [...sP];
      const sorted = pets.sort((a, b) =>
        a.name.localeCompare(b.name),
      );
      state.selectedPetsLegs[index] = {
        selectedPets: sorted,
      };
      saveDataInLocalStorage(
        'selectedPetsLegs',
        state.selectedPetsLegs,
      );
    },
    setAllSelectedPassengers: (
      state,
      action: PayloadAction<ISelectedPassengerLegs[]>,
    ) => {
      state.selectedPassengersLeg = action.payload;
      saveDataInLocalStorage(
        'selectedPassengersLegs',
        state.selectedPassengersLeg,
      );
    },
    setAllSelectedPets: (
      state,
      action: PayloadAction<ISelectedPetsLegs[]>,
    ) => {
      state.selectedPetsLegs = action.payload;
      saveDataInLocalStorage(
        'selectedPetsLegs',
        state.selectedPetsLegs,
      );
    },
    setClearPassengersAndPets: (state) => {
      state.selectedPassengersLeg = [];
      state.selectedPetsLegs = [];
      state.showPassengerListAsCarousel = false;
      state.checkboxPassengersJustClicked = false;
      state.maxNumberOfPassengers = 0;
    },
    setHasDataActive: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.hasDataActive = action.payload;
    },
    setShowAsCarousel: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.showAsCarousel = action.payload;
    },
    setCheckboxJustClicked: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.checkboxJustClicked = action.payload;
    },
    setShowPassengerListAsCarousel: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.showPassengerListAsCarousel = action.payload;
    },
    setCheckboxPassengersJustClicked: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.checkboxPassengersJustClicked = action.payload;
    },
    setNextPage: (state, action) => {
      state.nextPage = action.payload;
    },
    setIsEditingPassengers: (state, action) => {
      state.isEditingPassengers = action.payload;
      saveDataInLocalStorage(
        'isEditingPassengers',
        action.payload,
      );
    },
  },
  extraReducers(builder) {
    builder.addCase(
      putPassenger.fulfilled,
      (state, action) => {
        state.selectedPassengersLeg = updatePassengerLegs(
          state.selectedPassengersLeg,
          action.payload.data.passenger,
        );
      },
    );
  },
});

export const {
  clearAllFlyTripDetailsState,
  setLuggages,
  setSameLuggagesFlag,
  setSamePaxFlag,
  setTotalLuggagesPerLeg,
  setClearLuggages,
  setHasDataActive,
  setShowAsCarousel,
  setCheckboxJustClicked,
  setSelectedPassengers,
  setSelectedPets,
  setAllSelectedPassengers,
  setAllSelectedPets,
  setClearPassengersAndPets,
  setShowPassengerListAsCarousel,
  setCheckboxPassengersJustClicked,
  setNextPage,
  setSelectedLeg,
  setIsEditingPassengers,
} = flySearchTripDetailsSlice.actions;

export default flySearchTripDetailsSlice.reducer;
