import {
  IComponent,
  IItem,
  IPage,
} from './dynamicRendering.interfaces';
import { assembleLayout } from './pageManipulation.service';

export function instanceOfPage(
  item: IPage | IComponent | IItem,
): item is IPage {
  return (
    typeof item === 'object' &&
    ('metadata' in item || 'elements' in item)
  );
}

export function instanceOfComponent(
  item: IPage | IComponent | IItem,
): item is IComponent {
  return typeof item === 'object' && 'data' in item;
}

export function getPageFormattedElements(
  page: IPage,
): IComponent[] {
  let assemble = undefined;
  if (page.metadata?.columnLayout) {
    assemble =
      page.metadata.columnLayout !== '100' &&
      page.metadata.columnLayout;
  }
  return assemble ? assembleLayout(page) : page.elements;
}
