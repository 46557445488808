import Typography from '@atoms/Typography/Typography';
import styled from 'styled-components';

export const StyledCardDayOfFlightContact = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: 684px;
  border-radius: 4px;
  background-color: #ffffff;
  padding: 10px 0px;

  .MuiFormControl-root {
    width: 100%;
  }
`;

export const StyledTitle = styled(Typography)`
  margin-top: 4px;
  margin-bottom: 15px;

  &.strikethrough {
    text-decoration: line-through;
  }
`;
