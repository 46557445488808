import { IJwtPayload } from '@components/types';
import { getFromLocalStorage } from '@components/utils/storage';
import { MemberTypeCode } from '@graphql/generated/graphql';
import jwt from 'jsonwebtoken';

export const getAccessToken = () =>
  getFromLocalStorage('access_token');

export const getUserEmailFromLocalStorage = () => {
  const token = getAccessToken();
  const tokenDecoded = jwt.decode(token, {
    complete: true,
  });
  const payload = tokenDecoded?.payload as IJwtPayload;
  return payload?.userName;
};

export const getUserId = () => {
  const token = getAccessToken();
  const tokenDecoded = jwt.decode(token, {
    complete: true,
  });
  const payload = tokenDecoded?.payload as IJwtPayload;
  return payload?.userId;
};

export const getMemberId = () => {
  try {
    const token = getAccessToken();
    const tokenDecoded = jwt.decode(token, {
      complete: true,
    }) as jwt.JwtPayload;

    return tokenDecoded.payload.memberId;
  } catch (error) {
    return undefined;
  }
};

export const getMemberTypeCode = (): MemberTypeCode | undefined => {
  try {
    const token = getAccessToken();
    const tokenDecoded = jwt.decode(token, {
      complete: true,
    }) as jwt.JwtPayload;

    return tokenDecoded.payload.memberTypeCode;
  } catch (error) {
    return undefined;
  }
};

export const getMemberFullName = (): string | undefined => {
  try {
    const token = getAccessToken();
    const tokenDecoded = jwt.decode(token, {
      complete: true,
    }) as jwt.JwtPayload;

    return `${tokenDecoded.payload.firstName} ${tokenDecoded.payload.lastName}`;
  } catch (error) {
    return undefined;
  }
};

export const getMemberSinceYear = (): string | undefined => {
  try {
    const token = getAccessToken();
    const tokenDecoded = jwt.decode(token, {
      complete: true,
    }) as jwt.JwtPayload;

    return tokenDecoded.payload.memberSince.split('-')[0];
  } catch (error) {
    return undefined;
  }
};
