import Typography from '@atoms/Typography/Typography';
import { solidColors } from '@constants/styles/colors.constants';
import { typography } from '@constants/styles/typography.constants';
import { IContactInfoActionItemWebOnly } from './ContactInfoActionItemWebOnly.interfaces';
import {
  LinkStyled,
  StyledContainer,
} from './ContactInfoActionItemWebOnly.styles';

export default function ContactInfoActionItemWebOnly({
  title,
  info,
  action,
  dataTestId,
}: IContactInfoActionItemWebOnly) {
  return (
    <StyledContainer
      data-name="ContactInfoActionItemWebOnly"
      data-testid={dataTestId}
    >
      <Typography
        variant={typography.body1}
        color={solidColors.darkGray}
      >
        {title}
      </Typography>
      <LinkStyled
        href={`${
          action?.type === 'phone' ? 'tel:' : 'mailto:'
        } ${action?.data}`}
      >
        {info}
      </LinkStyled>
    </StyledContainer>
  );
}
