import { TextField } from '@components/library';
import { solidColors } from '@constants/styles/colors.constants';
import { withStyles } from '@material-ui/core';

const PillInput = withStyles(() => ({
  root: {
    '& .MuiInputBase-root': {
      height: '48px',
    },
    '& .MuiFormLabel-root': {
      color: `${solidColors.darkGray.color}`,
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(45px, 18px) scale(1)',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      maxWidth: '100%',
    },
    '& .MuiInputLabel-shrink ': {
      transform: 'translate(14px, -6px) scale(.75)',
      color: `${solidColors.midnight.color}`,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: `1px solid ${solidColors.mediumGray.color}`,
      },
      '&:hover fieldset': {
        border: `1px solid ${solidColors.mediumGray.color}`,
      },
      borderRadius: '24px',
      maxHeight: '48px',
    },
  },
}))(TextField);

export default PillInput;
