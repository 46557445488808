import styled from 'styled-components';

const ResponsiveWrapper = styled.div`
  z-index: -1000;
  padding: 0 256px 0 256px; // For screens >=1600px

  @media (max-width: 1440px) {
    padding: 0 165px 0 165px; // For screens >1440px
  }

  ${(props) => props?.theme?.breakpoints?.down('md')} {
    padding: 0 64px 0 64px;
  }

  ${(props) => props?.theme?.breakpoints?.down('sm')} {
    padding: 0 40px 0 40px;
  }

  ${(props) => props?.theme?.breakpoints?.down('xs')} {
    padding: 0 32px 0 32px;
  }
`;

export default ResponsiveWrapper;
