import {
  CardPassengerContainer,
  PassengerEdit,
  TypographyStyled,
  TitleSubtitleContainer,
  TitleSubtitleWrapper,
  InfoContainer,
} from './CardPassengerDetails.styles';
import { ICardPassengerDetails } from './CardPassengerDetails.interface';
import { typography } from '@constants/styles/typography.constants';
import { solidColors } from '@constants/styles/colors.constants';
import { Button } from '@atoms';
import { Elevation } from '@wheelsup/wu-react-components';

const CardPassengerDetails = ({
  id,
  title,
  button,
  birthDateCaption,
  birthDateCopy,
  weightCaption,
  weightCopy,
  phoneCaption,
  phoneCopy,
  favoritePassengerCaption,
  favoritePassengerCopy,
  emailCaption,
  emailCopy,
}: ICardPassengerDetails) => {
  const CardPassengerData = [
    [birthDateCaption, birthDateCopy],
    [weightCaption, weightCopy],
    [favoritePassengerCaption, favoritePassengerCopy],
    [phoneCaption, phoneCopy],
    [emailCaption, emailCopy],
  ];

  const renderTitleSubtitle = (
    subTitle: string,
    title: string,
  ) => {
    return (
      <TitleSubtitleContainer>
        <TypographyStyled
          variant={typography.body2}
          color={solidColors.darkGray}
          fontSize="14px"
        >
          {subTitle}
        </TypographyStyled>

        <TypographyStyled
          variant={typography.body1}
          color={solidColors.midnight}
        >
          {title}
        </TypographyStyled>
      </TitleSubtitleContainer>
    );
  };
  return (
    <Elevation variant="raisedLevel2" padding={0}>
      <CardPassengerContainer>
        <PassengerEdit>
          <TypographyStyled
            variant={typography.body1}
            color={solidColors.midnight}
          >
            {title}
          </TypographyStyled>

          <Button
            title={button?.title}
            styleType={'icon_trailing'}
            action={{
              actionMethod: button?.action?.actionMethod,
            }}
            image={{
              url: button?.image?.url,
            }}
          />
        </PassengerEdit>

        <TitleSubtitleWrapper>
          {CardPassengerData.map(
            (data: string[], idx: number) => {
              return (
                <InfoContainer key={idx}>
                  {renderTitleSubtitle(data[0], data[1])}
                </InfoContainer>
              );
            },
          )}
        </TitleSubtitleWrapper>
      </CardPassengerContainer>
    </Elevation>
  );
};

export default CardPassengerDetails;
