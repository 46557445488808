import styled from 'styled-components';

export const StyledContainer = styled.div<{
  $backgroundColor: string;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 5px 16px;
  background: ${({ $backgroundColor }) => $backgroundColor};
  border-radius: 4px;
  width: fit-content;
  height: fit-content;
`;
