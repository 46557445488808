import { Icons } from '@components/library';
import { solidColors } from '@constants/styles/colors.constants';
import styled from 'styled-components';

export const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 528px;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05);
  position: relative;

  ${(props) => props.theme.breakpoints.down('xs')} {
    width: 100%;

    .MuiButton-root {
      min-width: 100px;
    }
  }
`;

export const StyledFigure = styled.figure`
  overflow: hidden;
  width: 100%;
  height: 246px;
  padding: 0;
  margin: 0;
  background-color: ${solidColors.mediumGray.color};
`;

export const StyledInfo = styled.div`
  width: 100%;
  padding: 1.5rem;

  .status {
    margin-bottom: 0.75rem;
  }
`;

export const StyledPriceLocation = styled.div`
  display: flex;

  > div {
    display: flex;
    flex-direction: column;
  }
  > div:nth-child(2) {
    margin-left: 24px;
  }
`;

export const StyledBottomInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledCTAIcon = styled(Icons.ArrowForward)`
  color: ${solidColors.upBlue.color};
`;
