import { typography } from '@constants/styles/typography.constants';
import { Typography } from '@atoms';
import {
  StyledCard,
  StyledTitleContainer,
  StyledDivision,
} from './CardPriceRange.styles';
import { ICardPriceRange } from './CardPriceRange.interfaces';
import { solidColors } from '@constants/styles/colors.constants';

const CardPriceRange = ({
  title,
  subtitle,
  copy,
}: ICardPriceRange) => {
  return (
    <StyledCard
      data-name="CardPriceRange"
      style={{ padding: copy ? 'auto' : '50px' }}
    >
      <StyledTitleContainer>
        <Typography
          variant={typography.heading02Large}
          color={solidColors.midnight}
        >
          {title}
        </Typography>
      </StyledTitleContainer>
      {copy && <StyledDivision />}
      <StyledTitleContainer>
        <Typography
          variant={
            copy
              ? typography.body2Bold
              : typography.heading02Large
          }
          color={solidColors.midnight}
        >
          {subtitle}
        </Typography>
        <Typography
          variant={typography.heading03Large}
          color={solidColors.midnight}
        >
          {copy}
        </Typography>
      </StyledTitleContainer>
    </StyledCard>
  );
};

export default CardPriceRange;
