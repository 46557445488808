import { IInputField } from '@components/types';
import { useAppSelector } from '@app/hooks';
import { setInputValue } from '@components/organisms/SubPagePetAddEdit/SubPagePetAddEdit.slice';
import { useDispatch } from 'react-redux';
import { Typography } from '@atoms';
import { typography } from '@constants/styles/typography.constants';
import {
  InputsContainer,
  StyledContainer,
  StyledInputBase,
  StyledInputNumber,
} from './PetBaseInformationForm.styles';
import { isPositiveInteger } from '@components/utils/isPositiveInteger';

interface IPetBaseInformationBaseForm {
  nameFormField: IInputField;
  weightFormField: IInputField;
  ageFormField: IInputField;
  petInfoTitle: string;
  existingPet?: boolean;
}

const PetBaseInformationBaseForm = ({
  petInfoTitle,
  nameFormField,
  weightFormField,
  ageFormField,
  existingPet = false,
}: IPetBaseInformationBaseForm) => {
  const dispatch = useDispatch();

  const { name, age, weight } = useAppSelector(
    (state) => state.subPetAddEdit,
  );

  const handleInputChange = (
    inputKey: string,
    value: string | number,
  ) => {
    dispatch(
      setInputValue({
        key: inputKey,
        value: value,
      }),
    );
  };

  return (
    <StyledContainer>
      <Typography variant={typography.heading03Large}>
        {petInfoTitle}
      </Typography>
      <InputsContainer>
        <StyledInputBase
          type={nameFormField.type}
          variant="outlined"
          payloadKey={nameFormField.key || ''}
          title={nameFormField.title || 'Name'}
          leadingHelperCopy={
            nameFormField.leadingHelperCopy
          }
          value={name}
          onChange={(e: { target: { value: string } }) => {
            handleInputChange('name', e.target.value);
          }}
          maxCharactersAllowed={
            nameFormField.maxCharactersAllowed ?? 15
          }
        />
        <StyledInputBase
          type="text"
          variant="outlined"
          payloadKey={weightFormField.key ?? ''}
          title={weightFormField.title ?? 'Weight'}
          value={weight}
          error={
            existingPet &&
            (weight === '' || Number(weight) === 0)
          }
          errorMessage="Weight is missing"
          leadingHelperCopy={
            weightFormField.leadingHelperCopy
          }
          onChange={(e) => {
            if (
              e.target.value === '' ||
              isPositiveInteger(e.target.value)
            )
              handleInputChange(
                'weight',
                e.target.value,
              );
          }}
          maxCharactersAllowed={
            weightFormField.maxCharactersAllowed ?? 3
          }
        />
        <StyledInputNumber
          min={0}
          max={100}
          setValue={(e) => handleInputChange('age', e)}
          type={'number'}
          variant="outlined"
          payloadKey={ageFormField.key || ''}
          leadingHelperCopy={ageFormField.leadingHelperCopy}
          InputProps={{
            inputProps: {
              min: 0,
              max: 100,
            },
          }}
          title={ageFormField.title || 'Age'}
          value={age}
        />
      </InputsContainer>
    </StyledContainer>
  );
};

export default PetBaseInformationBaseForm;
