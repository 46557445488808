import styled from 'styled-components';

export const StyledTextGroupWrapper = styled.div`
  display: flex;
  flex-direction: column;

  p {
    margin-bottom: 7px;
  }
`;

export const ImageStyled = styled.img`
  width: 2rem;
  height: 2rem;
  margin-right: 1rem;
  margin-left: 1rem;
`;
