import React, { useState } from 'react';
import { Typography } from '@wheelsup/wu-react-components';
import {
  Color,
  solidColors,
} from '@constants/styles/colors.constants';
import {
  BackArrow,
  BackArrowContainer,
  BackArrowCopyContainer,
  Container,
} from './HeadlineSubtitleWithBackArrow.styles';
import { trackAnalytics } from '@services/mixpanelService/mixpanel.service';
import { TypographyProps } from '@wheelsup/wu-react-components/types/components/atoms/Typography/Typography';

export interface IHeadlineSubtitleWithBackArrow {
  headline: string;
  subtitle: string;
  color?: TypographyProps['color'];
  subtitleColor?: TypographyProps['color'];
  backArrowColor?: Color;
  action?: (e?: React.MouseEvent) => void;
  paddingTop?: number;
}

function HeadlineSubtitleWithBackArrow({
  headline,
  subtitle,
  color = 'link',
  subtitleColor = 'primary',
  backArrowColor = solidColors.upBlue,
  action,
  paddingTop,
}: IHeadlineSubtitleWithBackArrow) {
  const [hover, setHover] = useState(false);
  const handleAction = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (action) {
      trackAnalytics({
        trackingTitle: 'Select_BackArrow_Button',
        properties: [],
      });
      action();
    }
  };

  const handleMouse = () => {
    setHover(!hover);
  };

  return (
    <Container
      $paddingTop={paddingTop}
      data-name={'HeadlineSubtitleWithBackArrow'}
      data-testid="headline-subtitle"
    >
      <BackArrowCopyContainer
        onClick={action}
        onMouseEnter={handleMouse}
        onMouseLeave={handleMouse}
      >
        <BackArrowContainer
          $hover={hover}
          aria-label="back"
          onClick={handleAction}
        >
          <BackArrow
            $hover={hover}
            $color={backArrowColor}
          />
        </BackArrowContainer>
        <Typography variant="heading04Small" color={color}>
          {subtitle}
        </Typography>
      </BackArrowCopyContainer>
      <Typography
        variant="heading01Responsive"
        color={subtitleColor}
      >
        {headline}
      </Typography>
    </Container>
  );
}

export default HeadlineSubtitleWithBackArrow;
