import { useAxios } from '@graphql/generated/axiosHelper';
import {
  GetPricingForDatesDocument,
  GetPricingForDatesQuery,
  GetPricingForDatesQueryVariables,
} from '@graphql/generated/graphql';
import { QueryClient } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import { getMemberId } from '@services/tokenService/token';
import { v4 as uuidv4 } from 'uuid';
import { restructureDatePricesAndHighlightCheapest } from './dateHelper';
import { PricingParameters } from '../SubPageFlightSearchV2/FlightSearchContext/FlightSearchContext.types';

const memberId = getMemberId();

const NUMBER_OF_BUCKETS = 2;

interface IFetchPricingData {
  pricingParameters: PricingParameters;
  client: QueryClient;
  contextDispatch: React.Dispatch<any>;
}

export const fetchPricingData = async ({
  pricingParameters,
  client,
  contextDispatch,
}: IFetchPricingData) => {
  // prevent against calling the server before user has selected airports
  if (
    pricingParameters.departureAirport !== 1 &&
    pricingParameters.arrivalAirport !== 1
  ) {
    await client.cancelQueries({
      queryKey: ['GetPricingForDates'],
    });

    const startLuxonDate = DateTime.fromJSDate(
      new Date(pricingParameters.startDate),
    );

    const endLuxonDate = DateTime.fromJSDate(
      new Date(pricingParameters.endDate),
    );

    const daysBetween = endLuxonDate
      .diff(startLuxonDate, 'days')
      .toObject().days;

    // we are creating two buckets, one for each call to pricing
    const daysPerBucket = Math.floor(
      (daysBetween !== undefined ? daysBetween : 0) /
        NUMBER_OF_BUCKETS,
    );

    const promises = [];

    for (let i = 0; i < NUMBER_OF_BUCKETS; i++) {
      // either start or start of next month
      const newStartDate = startLuxonDate.plus({
        days: i * daysPerBucket,
      });
      // either adds full amount of days or half of it.

      const newEndDate =
        i + 1 === NUMBER_OF_BUCKETS
          ? endLuxonDate
          : newStartDate.plus({
              days: daysPerBucket - 1,
            });

      const modifiedCalendarRequest = {
        ...pricingParameters,
        clientId: `member_${memberId}_web_${uuidv4()}`, // this way each pricing call has its own unique identifier
        startDate: newStartDate.toFormat('yyyy-MM-dd'),
        endDate: newEndDate.toFormat('yyyy-MM-dd'),
      };

      const calendarRequestWithHardCodedClientId = {
        ...modifiedCalendarRequest,
        clientId: `member_${memberId}_web`, // if we use a clientId with a random UUID that busts all the caching
      };

      const query1 = useAxios<
        GetPricingForDatesQuery,
        GetPricingForDatesQueryVariables
      >(GetPricingForDatesDocument).bind(null, {
        flightCalendarRequest: modifiedCalendarRequest,
      })();

      // use useQueries to run these, then push to react query cache instead of context
      // then the day renderer pulls from react query cache

      promises.push(query1);
    }

    const results = await Promise.all(promises);

    const newData = results
      .map((result) => result.pricingForDates)
      .flat();

    const mappedData = restructureDatePricesAndHighlightCheapest(
      newData,
    );
    // need to do this to access in parent component
    contextDispatch({
      type: 'updatePricingData',
      payload: { pricingData: mappedData },
    });
    return mappedData;
  }
  return new Map();
};
