import {
  ISavedDeleteResData,
  ISubpageRes,
} from '@components/molecules/FormFieldAirportSearchDashboard/SearchFlight.interfaces';
import {
  saveAirportAction,
  deleteAirportAction,
} from '@services/airportSubpageSearchService/airportSubpageSearchService';

export interface ISaveDeleteProps {
  airportId: string;
  setSubpageData: (e: any) => void;
  showError: () => void;
  subpageData: ISubpageRes;
  snackbarHandler: (
    copy: string,
    styleType?: string,
  ) => void;
  isDelete: boolean;
}

// this is for favoriting or unfavoriting an airport
export const saveOrDeleteAirport = async ({
  airportId,
  setSubpageData,
  showError,
  subpageData,
  snackbarHandler,
  isDelete,
}: ISaveDeleteProps) => {
  try {
    const res: ISavedDeleteResData = await (isDelete
      ? deleteAirportAction(airportId)
      : saveAirportAction(airportId));
    const {
      savedAirports,
      recentlySearchedAirports,
      nearbyAirports,
    } = res.data.results;
    // updates the saved airports, recently searched airports, nearby airports
    // in the autocomplete fliight picker
    setSubpageData({
      ...subpageData,
      savedAirports: savedAirports,
      recentlySearchedAirports: recentlySearchedAirports,
      nearbyAirports: nearbyAirports,
    });

    snackbarHandler(
      res.data.userNotifications[0].data.copy,
    );
  } catch (err) {
    // shows the error message if there is one, within the snackbar
    showError();
  }
};
