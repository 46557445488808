import { useNavigate } from 'react-router-dom';
import { Typography } from '@atoms';
import {
  StyledSubPageManageIndividuals,
  StyledIndividualList,
  StyledIndividualItem,
  ProfileImageContainer,
  IndividualInfoContainer,
  AvatarStyled,
  StyledButton,
  StyledSubPageBody,
  StyledSubPageFooter,
  StyledSubPageHeader,
} from './SubpageAirmedAddIndividualsList.styles';
import { typography } from '@constants/styles/typography.constants';
import { paths } from '@routing/routerPaths';
import {
  IAirmedPassenger,
  updateMainPassenger,
} from '@features/AirMed/AirmedSlice';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '@app/hooks';

interface ISubpageAirmedAddIndividualsList {
  allRegisteredIndividuals?: IAirmedPassenger[];
}

export default function SubpageAirmedAddIndividualsList({
  allRegisteredIndividuals,
}: ISubpageAirmedAddIndividualsList) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { registeredIndividuals } = useAppSelector(
    (state) => state.airmed,
  );

  const handleButtonClick = () => {
    dispatch(updateMainPassenger(undefined));
    navigate(paths.AIRMED_ADD_INDIVIDUAL_FORM);
  };

  const handleButtonDone = () => {
    navigate(paths.AIRMED_MANAGE_INDIVIDUALS);
  };

  const handleButtonBack = () => {
    navigate(paths.AIRMED_MANAGE_INDIVIDUALS);
  };

  const handleSelectPassenger = (pax: IAirmedPassenger) => {
    dispatch(updateMainPassenger(pax));
    navigate(paths.AIRMED_ADD_INDIVIDUAL_FORM);
  };

  return (
    <StyledSubPageManageIndividuals padding={'11rem'}>
      <StyledSubPageHeader>
        <Typography variant={typography.heading01Large}>
          {'Add Individuals'}
        </Typography>
        <br />
        <Typography variant={typography.body1a}>
          {`Add up to ${
            12 - (registeredIndividuals?.length || 0)
          } individuals`}
        </Typography>
      </StyledSubPageHeader>
      <StyledSubPageBody>
        {allRegisteredIndividuals && (
          <StyledIndividualList>
            {allRegisteredIndividuals.map(
              (individual, idx) => (
                <StyledIndividualItem
                  key={idx}
                  onClick={(e) =>
                    handleSelectPassenger(individual)
                  }
                >
                  <ProfileImageContainer>
                    {individual.profileImage && (
                      <AvatarStyled
                        src={individual.profileImage.url}
                        filter={
                          individual.profileImage.tintColor
                        }
                        alt="avatar"
                        width={48}
                        height={48}
                      />
                    )}
                  </ProfileImageContainer>
                  <IndividualInfoContainer>
                    <Typography variant={typography.body1a}>
                      {`${individual.firstName} ${individual.lastName}`}
                    </Typography>
                  </IndividualInfoContainer>
                </StyledIndividualItem>
              ),
            )}
            <StyledButton
              action={{
                actionMethod: handleButtonClick,
              }}
              styleType={'card_primary'}
              title={'Add New Individual'}
            />
          </StyledIndividualList>
        )}
      </StyledSubPageBody>

      <StyledSubPageFooter>
        <StyledButton
          action={{
            actionMethod: handleButtonDone,
          }}
          styleType={'primary'}
          title={'Done'}
          disabled={
            Number(
              allRegisteredIndividuals?.length || [],
            ) >= 12
          }
        />
      </StyledSubPageFooter>
    </StyledSubPageManageIndividuals>
  );
}
