import {
  Card,
  Container,
  IconButton,
} from '@components/library';
import { Button, Typography } from '@atoms';
import { IListItemProfile } from './ListItemProfile.interfaces';
import { solidColors } from '@constants/styles/colors.constants';
import { typography } from '@constants/styles/typography.constants';
import {
  EditContainer,
  EditProfileButton,
  ProfileContainer,
  TitleContainer,
} from './ListItemProfile.styles';
import { useNavigate } from 'react-router-dom';
import jwt from 'jsonwebtoken';
import { useAppDispatch } from '@app/hooks';
import { setAccountSubpage } from '@features/Account/AccountSubpageSlice';
import {
  getFromLocalStorage,
  saveDataInLocalStorage,
} from '@components/utils/storage';
import {
  ACCOUNT_SUBPAGE_WEBVIEW_KEY,
  ACCOUNT_SUBPAGE_WEBVIEW_TITLE,
} from '../ListCTAIconHeadline/ListCTAIconHeadline';
import { IJwtPayload } from '@components/types';
import { Elevation } from '@wheelsup/wu-react-components';

const ListItemProfile = ({
  id,
  title,
  subtitle,
  button,
}: IListItemProfile) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const token = getFromLocalStorage('access_token') || '';
  const tokenDecoded = jwt.decode(token, {
    complete: true,
  });

  const {
    firstName,
    lastName,
    memberSince,
  } = tokenDecoded?.payload as IJwtPayload;

  const editProfile = () => {
    if (button?.action.data) {
      saveDataInLocalStorage(ACCOUNT_SUBPAGE_WEBVIEW_KEY, {
        webviewKey: button?.action.data,
      });
      saveDataInLocalStorage(
        ACCOUNT_SUBPAGE_WEBVIEW_TITLE,
        { webviewTitle: 'My Profile' },
      );
      dispatch(
        setAccountSubpage({
          accountSubpage: button?.action.data,
          title: 'My Profile',
        }),
      );
      navigate(button?.action.data.split('/src/client')[1]);
    }
  };

  return (
    <Container data-name="ListItemProfile">
      <Elevation variant="raisedLevel2">
        <ProfileContainer onClick={editProfile}>
          <EditContainer>
            <TitleContainer>
              <Typography
                variant={typography.heading02Large}
                color={solidColors.midnight}
              >
                {title && title === '<First> <Last>'
                  ? `${firstName} ${lastName}`
                  : title}
              </Typography>
            </TitleContainer>

            {button && (
              <EditProfileButton
                title={button?.title}
                styleType={
                  button?.styleType ?? 'card_secondary_web'
                }
                color={solidColors.upBlue}
                action={{
                  actionMethod: () => {
                    editProfile();
                  },
                  ...button.action,
                }}
              />
            )}
          </EditContainer>
          <Typography
            variant={typography.body1}
            color={solidColors.darkGray}
          >
            {subtitle?.replace(
              '<Start Date>',
              `${new Date(memberSince).getFullYear()}`,
            )}
          </Typography>
        </ProfileContainer>
      </Elevation>
    </Container>
  );
};

export default ListItemProfile;
