import { typography } from '@constants/styles/typography.constants';
import { solidColors } from '@constants/styles/colors.constants';
import {
  ICardHeadlineSubcopyListCTAsFooterText,
  IListItem,
} from './CardHeadlineSubcopyListCTAsFooterText.interfaces';
import {
  ItemLi,
  ItemUl,
  ListContainer,
  StyledCard,
  TypographyItemStyled,
  TypographyHeadline,
  TypographySubtitle,
  StyledPrice,
  TypographyPrice,
  StyledButtonContainer,
  StyledButton,
  TypographyPriceNote,
  TextGroupBodyCopyLinkViewLink,
  TypographyFooterNote,
} from './CardHeadlineSubcopyListCTAsFooterText.styles';

const CardHeadlineSubcopyListCTAsFooterText = ({
  headline,
  price,
  subtitle,
  items,
  buttonOne,
  buttonTwo,
  priceNote,
  viewLink,
  footerNote,
}: ICardHeadlineSubcopyListCTAsFooterText) => {
  return (
    <StyledCard>
      <TypographyHeadline
        variant={typography.h3a}
        color={solidColors.c5}
        align="center"
      >
        {headline}
      </TypographyHeadline>
      {price && (
        <StyledPrice>
          {price?.map((item: IListItem) => {
            return (
              <TypographyPrice
                key={item.text}
                variant={typography.h5a}
                color={solidColors.c6}
                className="price-item"
              >
                {item.text}
              </TypographyPrice>
            );
          })}
        </StyledPrice>
      )}
      <TypographySubtitle
        variant={typography.subheadA}
        color={solidColors.c5}
        align="center"
      >
        {subtitle}
      </TypographySubtitle>
      <ListContainer>
        <ItemUl>
          {items.map((item: IListItem) => {
            return (
              <ItemLi key={item.text}>
                <TypographyItemStyled
                  variant={typography.body1a}
                  color={solidColors.c6}
                >
                  {item.text}
                </TypographyItemStyled>
              </ItemLi>
            );
          })}
        </ItemUl>
      </ListContainer>
      <StyledButtonContainer>
        {buttonOne && (
          <StyledButton
            title={buttonOne?.title}
            action={buttonOne.action}
            styleType={buttonOne?.styleType}
          />
        )}
        {buttonTwo && (
          <StyledButton
            title={buttonTwo?.title}
            action={buttonTwo.action}
            styleType={buttonTwo?.styleType}
          />
        )}
      </StyledButtonContainer>
      {priceNote && (
        <TypographyPriceNote
          variant={typography.num1A}
          color={solidColors.c6}
        >
          {priceNote}
        </TypographyPriceNote>
      )}
      {viewLink && (
        <TextGroupBodyCopyLinkViewLink
          {...viewLink}
          as={undefined}
        />
      )}
      {footerNote && (
        <TypographyFooterNote
          variant={typography.num1A}
          color={solidColors.c6}
        >
          {footerNote}
        </TypographyFooterNote>
      )}
    </StyledCard>
  );
};

export default CardHeadlineSubcopyListCTAsFooterText;
