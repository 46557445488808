const shareData = async (text: string | undefined) => {
  if (text) {
    try {
      // This should open up native sharing options on mobile
      const shareData = {
        title: 'Wheels Up Flight',
        text,
      };
      await navigator.share(shareData);
    } catch (error) {
      console.log('Failed to share flight info', error);
    }
  }
};

export default shareData;
