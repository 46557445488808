import styled from 'styled-components';
import { Typography } from '@atoms';

export const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05);
  position: relative;
  height: fit-content;
  padding: 10px 20px;
`;

export const StyledTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

export const StyledSingleTextGroup = styled.div`
  text-decoration: none;
`;

export const StyledTitle = styled(Typography)`
  margin-top: 0.9rem;
`;
