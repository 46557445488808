import { withStyles } from '@material-ui/core';
import { TextField } from '../library';
import { solidColors } from '../../constants/styles/colors.constants';

const TextFieldStyled = withStyles({
  root: {
    '& .MuiFormLabel-root': {
      color: `${solidColors.mediumGray.color}`,
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: `${solidColors.upBlue.color}`,
    },
    '& .MuiInput-underline:hover:before': {
      borderBottomColor: `${solidColors.upBlue.color}`,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: `${solidColors.upBlue.color}`,
    },
  },
})(TextField);

export interface ITextField {
  label: string;
  type?: string;
  defaultValue?: string;
  error?: boolean;
  helperText?: string;
  value?: string;
  disabled?: boolean;
  onChange?: (input: any) => void;
  onBlur?: (
    e: React.FocusEvent<
      HTMLInputElement | HTMLTextAreaElement
    >,
  ) => void;
}

const Input = ({
  label,
  type,
  defaultValue,
  error,
  helperText,
  disabled,
  value,
  onChange,
  onBlur,
}: ITextField) => {
  return (
    <TextFieldStyled
      label={label}
      type={type}
      error={error}
      defaultValue={defaultValue}
      helperText={helperText}
      disabled={disabled}
      onChange={onChange}
      onBlur={onBlur}
      value={value}
    />
  );
};

export default Input;
