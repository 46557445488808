import React from 'react';
import { IImageView } from './ImageView.interfaces';
import { StyledImageImgix } from './ImageView.styles';
import { useEffect, useState } from 'react';
import { useViewport } from '@hooks';

const ImageView = ({
  image: { url },
  height,
}: IImageView) => {
  const { width } = useViewport();
  const [w, setW] = useState(1088);

  useEffect(() => {
    const img = document.getElementById(url);
    let w = 1088;
    if (img) {
      const parent = img.parentElement;
      if (parent) w = parent.clientWidth;
    }
    setW(w);
  }, [width, url]);

  return (
    <StyledImageImgix
      id={url}
      src={url}
      aspectRatio="16:9"
      height={width >= 1088 ? height : undefined}
      width={w}
      alt="WheelsUp Image"
      crop="focalpoint"
      fit="crop"
      position="absolute"
    />
  );
};

export default ImageView;
