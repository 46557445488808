import styled from 'styled-components';
import { ImageImgix } from '@atoms';
import { solidColors } from '@constants/styles/colors.constants';
import { theme } from '@constants/styles/theme.constants';
import { Typography } from '@wheelsup/wu-react-components';

export const StyledContainer = styled.div<{
  $clickable: boolean;
}>`
  cursor: ${({ $clickable }) =>
    $clickable ? 'pointer' : 'default'};
  padding: 0;
  min-width: 340px;
  width: 100%;
  height: fit-content;

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    min-width: 335px;
  }
`;

export const ExpirationText = styled(Typography)`
  margin: 8px 0 0 12px;
`;

export const BodyContainer = styled.div`
  margin: 1rem 0 0 0;
  display: flex;
  justify-content: space-between;
`;

export const HeadContainer = styled.div<{
  $isMobile: boolean;
}>`
  display: flex;
  ${({ $isMobile }) =>
    $isMobile &&
    `
        justify-content: space-between;
        padding-right: 1rem;
        margin: 0;
    `}
`;

export const TimesContainer = styled.div<{
  $isMobile: boolean;
}>`
  margin: 1rem 0 0 0;
  padding-bottom: 18px;
  display: flex;
  border-bottom: solid 1px ${solidColors.mediumGray.color};

  ${({ $isMobile }) =>
    $isMobile
      ? `
            flex-direction: column;
            align-items: flex-start;
        `
      : `
            flex-direction: row;
        `}
`;

export const FlightDetailsContainer = styled.div`
  margin: 1rem 0 0 0;
`;

export const FlightDateContainer = styled.div`
  background: ${solidColors.midnight.color};
  width: fit-content;
  height: 28px;
  padding: 0 1.25rem 0 1rem;
  border-radius: 6px 0 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DetailsContainer = styled.div`
  display: flex;
  margin-bottom: 0.3rem;
`;

export const AirportCodeStyled = styled(Typography)`
  margin-bottom: 0.3rem;
`;

export const AircraftDetailsSpacer = styled(Typography)`
  margin: 0 0.7rem;
`;

export const DepartureIcon = styled(ImageImgix)`
  margin-right: 5px;
`;

export const DepartureDetail = styled(Typography)`
  margin-right: 25px;
`;

export const ActionContainer = styled.div<{
  $isMobile: boolean;
}>`
  width: 100%;
  margin: auto 0;
  display: flex;
  align-items: center;

  ${({ $isMobile }) =>
    $isMobile
      ? `
            height: auto;
            padding: 10px 0px 11px 0px;
            justify-content: space-between;
        `
      : `
            height: 50px;
            justify-content: flex-end;
        `}
`;

export const MobilePriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: fit-content;
`;

export const ButtonContainer = styled.div<{
  isFlyUserAndCardIsSlim?: boolean;
}>`
  width: fit-content;
  height: fit-content;

  button {
    .MuiButton-endIcon {
      margin-left: 0.25rem;
    }
  }
  ${({ isFlyUserAndCardIsSlim }) =>
    isFlyUserAndCardIsSlim
      ? `
          .MuiButton-text {
            padding: 8px 0px 4px 0px;
          }
      `
      : ''}
`;

export const ContentContainer = styled.div<{
  $isMobile: boolean;
}>`
  padding: 0 2rem;

  ${({ $isMobile }) =>
    $isMobile
      ? `
            padding: 0 1rem;
        `
      : `
            padding: 0 2rem;
        `}
`;

export const DepartureContainer = styled.div<{
  $isMobile: boolean;
}>`
  display: flex;
  margin-bottom: ${({ $isMobile }) =>
    $isMobile ? '1rem' : '0'};

  &:last-child {
    margin-bottom: 0;
  }
`;
