import { ICardFboInfo } from './CardFboInfo.interfaces';
import { CardInnerContainer, CardOuterContainer, FboContainer } from './CardFboInfo.styles';
import { Elevation, Typography } from '@wheelsup/wu-react-components';
import FboSection from './FboSection';

const CardFboInfo = ({
  departFbo,
  arriveFbo,
}: ICardFboInfo) => {
  return (
    <CardOuterContainer>
      <Elevation
        variant='raisedLevel2'
        padding={0}
      >
        <CardInnerContainer data-testid={'CardInnerContainer'}>
          <Typography
            variant='heading03'
            color='primary'
          >
            Airport FBO
          </Typography>
          <FboContainer>
            <FboSection
              {...departFbo}
            />
            <FboSection
              {...arriveFbo}
            />
          </FboContainer>
        </CardInnerContainer>
      </Elevation>
      </CardOuterContainer>
  );
};

export default CardFboInfo;