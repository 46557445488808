import { pegasusClient } from '@services/apiService';
import {
  AsyncThunk,
  combineReducers,
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit';
import {
  dataSliceOptions,
  ISliceFactoryArgs,
} from '@services/sliceFactoryService/sliceFactoryService.types';

const initialState = {
  postBookingRequestStatus: '',
  reservationId: 0,
};

interface IProps {
  urlParams?: string;
  queryParams?: string;
  token?: string;
  type?: string;
  resPayload?: unknown;
}

export function postBookingRequest({
  pageName,
  endpoint,
  version,
}: ISliceFactoryArgs) {
  return createAsyncThunk(
    `postBookingRequest/${endpoint}`,
    async (props: IProps) => {
      const { resPayload, token, queryParams = '' } = props;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          WUToken: token,
        },
      };
      try {
        const data = (
          await pegasusClient.post(
            `${endpoint}?version=${version}&platform=web`,
            resPayload,
            config,
          )
        ).data;
        return { ...data, type: pageName };
      } catch (err) {
        return err;
      }
    },
  );
}

function postBookingFactory({
  pageName,
  endpoint,
  version,
}: ISliceFactoryArgs) {
  const postBookingRequestThunk = postBookingRequest({
    endpoint: endpoint,
    pageName: pageName,
    version: version,
  });

  const confirmationSlice = createSlice({
    name: 'confirmationPopUp',
    initialState,
    reducers: {
      setReservationId(state, action) {
        const { payload } = action;
        state.reservationId = payload.reservationId;
      },
    },
    extraReducers: (builder) => {
      builder.addCase(
        postBookingRequestThunk.fulfilled,
        (state, action) => {
          const { payload } = action;
          state.postBookingRequestStatus = payload.type;
        },
      );
    },
  });

  return confirmationSlice;
}

const postBookingCateringSlice = postBookingFactory(
  dataSliceOptions.postBookingCatering,
);

const postBookingGroundSlice = postBookingFactory(
  dataSliceOptions.postBookingGroundTransport,
);

const postBookingSpecialSlice = postBookingFactory(
  dataSliceOptions.postBookingSpecialRequest,
);

export const {
  setReservationId,
} = postBookingCateringSlice.actions;

const postBookingReducer = combineReducers({
  postBookCatering: postBookingCateringSlice.reducer,
  postBookGround: postBookingGroundSlice.reducer,
  postBookSpecial: postBookingSpecialSlice.reducer,
});

export default postBookingReducer;
