import { IPassenger } from '@components/types';
import { getSimpleDateFormat } from '@components/utils/dateUtils';
import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import {
  endpoints,
  getAuthHeaders,
  pegasusClient,
} from '@services/apiService';

export interface ISubPagePassengerAddEditState {
  id?: string;
  prefix?: string;
  suffix?: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  birthday: string;
  weight: string;
  gender: string;
  phone?: string;
  email?: string;
  favorite: boolean;
  isSuccesful: boolean;
}

const initialState: ISubPagePassengerAddEditState = {
  prefix: '',
  suffix: '',
  firstName: '',
  middleName: '',
  lastName: '',
  birthday: '',
  weight: '',
  gender: '',
  phone: '',
  email: '',
  favorite: false,
  isSuccesful: false,
};

export const putPassenger = createAsyncThunk(
  'subPassengerAddEdit/fetchSubPassengerAddEdit',
  async (param: IPassenger) => {
    const headers = getAuthHeaders();

    const apiMethod =
      param.id !== undefined
        ? pegasusClient.put
        : pegasusClient.post;

    try {
      // Set the date to 12:00:00 to avoid timezone issues.
      // Stripping the time part of the date could cause consumers to default to 00:00:00 UTC.
      param.dateOfBirth?.setHours(12, 0, 0, 0);

      const data = (
        await apiMethod(
          `${endpoints.passengerCreateEditPost}?version=1&platform=web`,
          {
            passenger: param,
          },
          {
            headers: headers,
          },
        )
      ).data;
      return data;
    } catch (err) {
      return err;
    }
  },
);
const SubPagePassengerAddEditSlice = createSlice({
  name: 'subPagePassengerAddEditSlice',
  initialState,
  reducers: {
    setInputValue: (
      state,
      action: PayloadAction<{
        key: string;
        value: string | boolean;
      }>,
    ) => {
      const { key, value } = action.payload;
      const accessKey = key as keyof typeof initialState;
      state[accessKey] = value as never;
    },
    setPassenger: (
      state,
      action: PayloadAction<IPassenger>,
    ) => {
      const passenger = action.payload;
      state.id = passenger.id;
      state.firstName = passenger.firstName;
      state.middleName = passenger.middleName;
      state.lastName = passenger.lastName;
      state.prefix = passenger.prefix;
      state.suffix = passenger.suffix;
      state.gender = passenger.gender;
      state.birthday = getSimpleDateFormat(
        passenger.dateOfBirth ?? new Date(),
      ).replaceAll('/', '');
      state.weight = passenger.weight?.toString() ?? '0';
      state.favorite = passenger.isFavPassenger ?? false;
      state.phone = passenger.phone;
      state.email = passenger.email;
    },
    cleanPassengerState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(putPassenger.pending, (state) => {
      state.isSuccesful = false;
    });
    builder.addCase(putPassenger.fulfilled, (state) => {
      state.isSuccesful = true;
    });
    builder.addCase(putPassenger.rejected, (state) => {
      state.isSuccesful = false;
    });
  },
});

export const {
  setInputValue,
  setPassenger,
  cleanPassengerState,
} = SubPagePassengerAddEditSlice.actions;

export default SubPagePassengerAddEditSlice.reducer;
