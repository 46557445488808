import { IArrowIconProps } from './ArrowIconButton.interfaces';
import { IconButton } from '@atoms';
import {
  IconButtonWrapper,
  ArrowBackIos,
  ArrowForwardIos,
} from './ArrowIconButton.styles';

const ArrowIconButton = ({
  forward,
  disabled = false,
  clickHandler,
  widthRem = 65,
  imageCarousel,
  arrowsInside,
  arrowsSize,
}: IArrowIconProps) => {
  const name = forward
    ? 'forward button'
    : 'backward button';
  return (
    <IconButtonWrapper
      imageCarousel={imageCarousel}
      forward={forward}
      widthRem={widthRem}
      className="arrow"
      arrowsInside={arrowsInside}
    >
      <IconButton
        imageCarousel={imageCarousel}
        disabled={disabled}
        onClick={clickHandler}
        arrowsSize={arrowsSize}
        name={name}
        aria-label={name}
      >
        {forward ? (
          <ArrowForwardIos
            name={name}
            label={name}
          />
        ) : (
          <ArrowBackIos
            name={name}
            label={name}
          />
        )}
      </IconButton>
    </IconButtonWrapper>
  );
};

export default ArrowIconButton;
