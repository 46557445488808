import { endSession } from '@components/utils/sessionTimer';
import { IAction } from '@atoms/Action/Action.interfaces';
import { saveDataInSessionStorage } from '@components/utils/storage';
//redux api Fetching
import { asyncThunkFactory } from '@services/sliceFactoryService/requestSlice';
import { dataSliceOptions } from '@services/sliceFactoryService/sliceFactoryService.types';
import { Dispatch } from 'redux';
import {
  addActiveNearby,
  addActiveindex,
} from '@features/Fly/searchFlightSlice';
import { parseUri } from '@atoms/Action/Action';
import {
  abnormalLinksMap,
  DeepLinksAbnormalList,
  DeepLinksList,
} from '@atoms/Action/DeepLinks';
import { getRenewalPopUp } from '@features/Account/AccountSubpageSlice';
import { setIsCancelOfferPopUpOpen } from '@features/MyTrips/MyTripsCancelOfferSlice';

export const selectAlternateAirport = async (
  action: Partial<IAction>,
  dispatch?: Dispatch<any>,
) => {
  const airportCode = action.title?.split(':')[1];
  const linkedForm = action.title?.split(':')[0];
  const activeIndex = action.title?.split(':')[2];

  const nearbyAirportParams = {
    queryParams: `&code=${airportCode}`,
  };
  saveDataInSessionStorage('last_call', {
    ...dataSliceOptions.nearbyAirport,
    ...nearbyAirportParams,
  });
  if (dispatch) {
    dispatch(
      await asyncThunkFactory(
        dataSliceOptions.nearbyAirport,
      )({
        ...nearbyAirportParams,
      }),
    );

    if (linkedForm) {
      dispatch(addActiveNearby(linkedForm));
      dispatch(addActiveindex(Number(activeIndex)));
    }
  }

  return;
};

export const generateUrlFromUri = (
  action: Partial<IAction>,
  isButton: boolean,
  dispatch?: Dispatch<any>,
) => {
  /*
        * If logic is added here, please add the same in the getServerAction function in atoms/Button.tsx
        
        * All new links should be used with the `url` case instead of `uri`.
        * This is only for legacy links that are still using `uri` and special
        cases if they arise.
    */
  if (action.data) {
    const actionData = action?.data;

    if (actionData?.includes('//newsDetails')) {
      const btnUrl = new URL(action?.data || '');

      const newsId = btnUrl.searchParams.get('id');
      return `/news/${newsId}`;
    } else if (
      actionData?.includes('//limited-time-deals')
    ) {
      return '/limited-time-deals';
    } else if (
      actionData?.includes(
        'manageReservation?reservationId=',
      )
    ) {
      const searchParamId = actionData.split(
        'reservationId=',
      )[1];
      return `/fly/manage-reservation/${searchParamId}`;
    }
    // use parseUri to get the url
    else if (actionData?.includes('//news')) {
      endSession();
      return '/news';
    }

    if (actionData?.includes('//fly?id=')) {
      const searchParamId = actionData.split('id=')[1];
      return '/fly?id=' + searchParamId;
    }
    if (actionData?.includes('//myTrips')) {
      endSession();
      return '/my-trips';
    }

    if (actionData?.includes('//flightDeals')) {
      endSession();
      return '/flight-deals';
    }

    if (actionData?.includes('//cancelPendingFlight')) {
      const dealId = actionData.split('=')[1];
      if (dispatch) {
        dispatch(
          setIsCancelOfferPopUpOpen({
            isOpen: true,
            dealId,
          }),
        );
      }
      return;
    }

    if (actionData?.includes('//create-account')) {
      endSession();
      return '/create-account/1';
    }

    if (
      actionData?.includes(
        '//upglobalresponse/manage-individuals',
      )
    ) {
      endSession();
      return '/upglobalresponse/manage-individuals';
    }

    if (
      actionData?.includes(
        '//upglobalresponse/contact-form',
      )
    ) {
      endSession();
      return '/upglobalresponse/contact-form';
    }

    if (actionData?.includes('//upglobalresponse')) {
      endSession();
      return '/upglobalresponse';
    }

    if (actionData?.includes('//create-account')) {
      endSession();
      return '/create-account/1';
    }

    if (actionData?.includes('//reservationDetails')) {
      const userId = actionData?.split(
        `${DeepLinksAbnormalList.RESERVATION_DETAILS}?id=`,
      )[1];
      return `/my-trips/${userId}`;
    }

    if (actionData?.includes('//callUs')) {
      if (isButton)
        window.location.href = 'tel:866-498-7359';
      return;
    }

    if (actionData?.includes('/account/legal')) {
      return '/account/legal';
    }

    if (actionData?.includes('//selectAlternateAirport')) {
      selectAlternateAirport(action, dispatch);
    }

    if (
      actionData?.includes('//renewMembershipActive') &&
      dispatch
    ) {
      dispatch(
        getRenewalPopUp({
          urlParams: '/active',
        }),
      );
      return;
    }

    if (
      actionData?.includes('//renewMembershipExpired') &&
      dispatch
    ) {
      dispatch(
        getRenewalPopUp({
          urlParams: '/expired',
        }),
      );
      return;
    }

    if (
      actionData?.includes(
        DeepLinksAbnormalList.EDIT_PASSENGER,
      )
    ) {
      const userId = actionData?.split(
        `${DeepLinksAbnormalList.EDIT_PASSENGER}?id=`,
      )[1];
      return `/manage/passenger/${userId}/0`;
    }
    if (
      Object.values(DeepLinksList).includes(
        actionData as DeepLinksList,
      )
    ) {
      return parseUri(actionData);
    }

    if (
      Object.values(DeepLinksAbnormalList).includes(
        actionData as DeepLinksAbnormalList,
      )
    ) {
      return abnormalLinksMap[
        actionData as DeepLinksAbnormalList
      ];
    }

    const r = new RegExp('^(?:[a-z]+:)?//', 'i');
    // test to see if it's an absolute URL
    if (r.test(actionData || '')) {
      const btnUrl = new URL(actionData || '');
      if (btnUrl.href.includes('http')) {
        if (isButton) {
          window.open(btnUrl.href, '_blank');
          return;
        }
        return btnUrl.href;
      }
    }
    return action.data;
  }
};
