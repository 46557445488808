import { surfaceColors } from '@constants/styles/colors.constants';
import styled from 'styled-components';

export const BackgroundWrapper = styled.div<{
  $blueBackground?: boolean;
}>`
  margin: 0;
  display: flow-root;
  background: ${({ $blueBackground }) =>
    $blueBackground
      ? surfaceColors.backgroundMidnight.color
      : 'transparent'};
`;

export const StyledImageContainer = styled.div`
  background-size: cover;
  z-index: -1;
  display: flex;
  width: inherit;
  min-height: 400px;
  justify-content: center;
  align-items: flex-end;
`;

export const ChildContainer = styled.div`
  z-index: 1;
  position: absolute;
  background: transparent;
`;
