import { IHeaderView } from './HeaderView.interfaces';
import { StyledHeaderView } from './HeaderView.styles';
import { Typography } from '@atoms';
import { typography } from '@constants/styles/typography.constants';
import { solidColors } from '@constants/styles/colors.constants';
import { deconstructStyleType } from '@components/utils/deconstructStyleType';

export default function HeaderView({
  title,
  styleType,
}: IHeaderView) {
  const {
    typographyType,
    colorType,
  } = deconstructStyleType(styleType);
  return (
    <Typography
      variant={
        typographyType
          ? typography[typographyType]
          : typography.heading01Large
      }
      color={
        colorType
          ? solidColors[colorType]
          : solidColors.upWhite
      }
    >
      <StyledHeaderView>{title}</StyledHeaderView>
    </Typography>
  );
}
