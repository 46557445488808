import InputBase from '@atoms/InputBase';
import InputNumber from '@atoms/InputNumber/InputNumber';
import styled from 'styled-components';

export const InputsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  height: fit-content;
  width: 100%;
  max-width: 906px;
  margin-top: 40px;
  flex-wrap: wrap;
`;

export const StyledInputBase = styled(InputBase)`
  margin-right: 24px;
  margin-bottom: 12px;
`;

export const StyledInputNumber = styled(InputNumber)`
  margin-right: 24px;
  margin-bottom: 12px;
`;

export const StyledContainer = styled.div`
  margin-top: 40px;
`;
