import { solidColors } from '@constants/styles/colors.constants';
import styled from 'styled-components';

export const CCContainer = styled.div`
  border-top: 1px ${solidColors.mediumGray.color} solid;
  height: 5rem;

  &:first-child {
    height: 4rem;
    border-top: none;
  }
  &:last-child {
    height: 4rem;
  }
`;

export const SubPageContainer = styled.section`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;
