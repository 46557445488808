import styled from 'styled-components';

export const StyledInputHelper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export interface CopyAction {
  valid: boolean;
}

export const StyledCopyAction = styled.div<CopyAction>`
  cursor: ${(props) => (props.valid ? 'pointer' : '')};
`;
