import {
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

interface IHandleUncaughtError {
  isError: boolean;
}

const initialState: IHandleUncaughtError = {
  isError: false,
};

export const uncaughtErrorSlice = createSlice({
  name: 'handleUncaughtErrorSlice',
  initialState,
  reducers: {
    setUncaughtError: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.isError = action.payload;
    },
  },
});

export const {
  setUncaughtError,
} = uncaughtErrorSlice.actions;

export const HandleUncaughtErrorReducer =
  uncaughtErrorSlice.reducer;

export default HandleUncaughtErrorReducer;
