import {
  endpoints,
  getAuthHeaders,
  pegasusClient,
} from '../apiService';
import {
  mockSubpageResponse,
  mockSubpageResponseNoSaved,
  mockSubpageResponseNoAirports,
} from '@components/molecules/FormFieldAirportSearchDashboard/SearchFlight.data';
import { IAirportItem } from '@components/molecules/FormFieldAirportSearchDashboard/SearchFlight.interfaces';
import { PricingParameters } from '@components/molecules/SubPageFlightSearchV2/FlightSearchContext/FlightSearchContext.types';
import axios, { AxiosError } from 'axios';

// Subpage for initial click
export const getSubpage = async (
  titleText: string,
  isMock: boolean,
) => {
  const headers = getAuthHeaders();
  if (isMock) {
    return mockSubpageResponse;
  }
  const endpoint = endpoints.airportSubpageSearch;
  const subpageData = await pegasusClient.post(
    endpoint,
    {
      titleText: titleText,
    },
    {
      headers: headers,
    },
  );
  return subpageData?.data?.data?.elements[0]?.data?.element
    ?.data;
};

// For saving an airport
export const saveAirportAction = async (
  airportId: string,
) => {
  const headers = getAuthHeaders();
  const endpoint = `${endpoints.airportSubpageSaveDeleteAction}&airportId=${airportId}`;
  const res = (
    await pegasusClient.post(endpoint, {
      headers: headers,
    })
  ).data;
  return res;
};

// For deleting an airport
export const deleteAirportAction = async (
  airportId: string,
) => {
  const headers = getAuthHeaders();
  const endpoint = `${endpoints.airportSubpageSaveDeleteAction}&airportId=${airportId}`;
  const res = (
    await pegasusClient.delete(endpoint, {
      headers: headers,
    })
  ).data;
  return res;
};

export const getCalendarDecorations = async (
  fromAirport: IAirportItem | null,
  toAirport: IAirportItem | null
) => {
  const headers = getAuthHeaders();
  let endpoint = `${endpoints.datePicker}?version=1&platform=web`;
  if (fromAirport?.code && toAirport?.code) {
    endpoint = `${endpoints.datePicker}?version=1&platform=web&departureAirport=${fromAirport.code}&arrivalAirport=${toAirport.code}`;
  }
  const res = (
    await pegasusClient.get(endpoint, {
      headers: headers,
    })
  ).data;
  return res;
};

// Airport Search Endpoint
// Cancel token logic can be moved to top of file if needed, but keeping here for documentation purposes
const CancelToken = axios.CancelToken; // This is a cancel token used to cancel the request
let cancel: any;
export const getAirportSearchResults = async (
  matchString: string,
) => {
  cancel && cancel('cancel'); // will cancel the request with an error message of 'cancel'
  const headers = getAuthHeaders();
  const endpoint = `${endpoints.airportSearch}&matcher=${matchString}`;

  try {
    const res = (
      await pegasusClient.get(endpoint, {
        headers: headers,
        cancelToken: new CancelToken(function executor(c) {
          // executor is called when you cancel the request with the cancel token
          cancel = c;
        }),
      })
    ).data;

    return res;
  } catch (err: unknown) {
    if (axios.isCancel(err as AxiosError)) {
      // catches the cancel error so it is not thrown
      console.log('Request canceled');
      return;
    } else {
      return err;
    }
  }
};

// mock file response for dev work if needed
export const getMockSubpageResponse = () => {
  // Function for getting FE mock data
  const val = (document.getElementById(
    'mockSubpageDataSelector',
  ) as HTMLInputElement)?.value;

  if (val === 'Default') {
    return mockSubpageResponse;
  } else if (val === 'No Saved Airports') {
    return mockSubpageResponseNoSaved;
  } else if (val === 'Empty State') {
    return mockSubpageResponseNoAirports;
  } else return mockSubpageResponse;
};

// used in v2
export const validateFlight = async (params: any) => {
  const headers = getAuthHeaders();

  const endpoint = `${endpoints.flightValidate}?version=1&platform=web`;
  try {
    const res = (
      await pegasusClient.post(
        endpoint,
        {
          ...params,
        },
        {
          headers: headers,
        },
      )
    ).data;
    return res;
  } catch (err) {
    console.log('error validating', err);
  }
};

export const getAirportHoursCopy = async (
  params: string,
) => {
  const headers = getAuthHeaders();
  const endpoint = `${endpoints.timePicker}${params}`;
  try {
    const res = (
      await pegasusClient.get(endpoint, {
        headers: headers,
      })
    ).data;
    return res;
  } catch (err) {
    console.log(err, 'error');
  }
};
