import styled from 'styled-components';
import { Button, Icons } from '@library';
import { solidColors } from '@constants/styles/colors.constants';
import Typography from '../Typography/Typography';
import {
  CTAVariants,
  IButtonTextStyled,
  IButton,
} from './Button.interfaces';

const primaryStyles = `
    background-color: ${solidColors.upBlue.color} !important;
    &:hover {
        background-color: ${solidColors.blue40Percent.color}  !important;
    }
    &:focus {
        background-color: ${solidColors.upBlue.color}  !important;
        border: solid 2px ${solidColors.blue20Percent.color}  !important;
    }
`;

const secondaryStyles = `
    border: solid 1px ${solidColors.upBlue.color} !important;
    background-color: ${solidColors.upWhite.color} !important;
    padding: .75rem 1.5rem;
    &:hover {
        border: solid 2px ${solidColors.blue40Percent.color}  !important;
    }
    &:focus {
        border: solid 2px ${solidColors.blue20Percent.color}  !important;
    }
`;

const lockStyles = `
    padding: 0 2rem 0 2rem;
    background-color: ${solidColors.midnight.color} !important;
    &:hover {
        background-color: ${solidColors.midnight.color} !important;
    }
`;

const pillStyle = `
    margin: 20px 0px;
    width: 345px;
    height: 55px;
    border-radius: 27.5px;
    display: flex;
    justify-content: left;
    align-items: left;
    background-color: ${solidColors.upWhite.color};
    border: 1px solid ${solidColors.mediumGray.color};
    &:hover {
        background-color: ${solidColors.lightGray.color};
        border: 1px solid ${solidColors.lightGray.color};
    }
`;

const cardStyles = `
    border: none;
`;

const textIconStyle = `
    background-color: transparent;
    border: none;
    width: auto;
    padding-left: 0;
    padding-right: 0;
    &:hover {
        background-color: transparent;
    }
`;

const textStyle = `
    background-color: transparent;
    padding-left: 0;
    padding-right: 0;
    &:hover {
        background-color: transparent;
    }
`;

const iconButtonStyle = `
    background-color: transparent;
    width: auto;
    padding: 0;
    &:hover {
        background-color: transparent;
    }
`;

const iconNoPaddingStyle = `
    min-width: 0;
`;

const getButtonVariantStyles = (variant: CTAVariants) => {
  switch (variant) {
    case 'primary':
      return primaryStyles;
    case 'secondary':
      return secondaryStyles;
    case 'card_secondary_web':
      return cardStyles;
    case 'destructive':
      return secondaryStyles;
    case 'card_destructive_web':
      return cardStyles;
    case 'lock':
      return lockStyles;
    case 'pill':
      return pillStyle;
    case 'text_with_icon_trailing':
      return textIconStyle;
    case 'text_with_icon_leading':
      return textIconStyle;
    case 'icon_button':
      return iconButtonStyle;
    case 'icon_no_padding':
      return iconButtonStyle + iconNoPaddingStyle;
    case 'links-icon-trailing':
      return textIconStyle;
    case 'text':
      return textStyle;
    default:
      return '';
  }
};

// Leaving this here for potential future use. This is an animation to make some dots that we might use as a loading state for buttons during api calls.
export const Dots = styled.div`
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: 0.5s;

  &:before,
  &:after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
  }

  &:before {
    left: -15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #9880ff;
    color: #9880ff;
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 0s;
  }

  &:after {
    left: 15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #9880ff;
    color: #9880ff;
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 1s;
  }

  @keyframes dotFlashing {
    0% {
      background-color: #ffffff;
    }
    50%,
    100% {
      background-color: #000000;
    }
  }
`;

export const ButtonStyled = styled(Button)<Omit<IButton, 'action'>>`
  padding-left: 1rem;
  padding-right: 1rem;

  ${(props) => {
    let styles = '';
    styles += props.width
      ? 'width: ' + props.width + ';'
      : '';
    styles += props.height
      ? 'height: ' + props.height + ';'
      : '';

    styles += props.radius
      ? 'border-radius: ' + props.radius + ';'
      : 'border-radius: ' + '8px' + ';';

    styles += getButtonVariantStyles(props.$styleType);
    return styles;
  }}
  &:disabled {
    background-color: ${solidColors.lightGray.color};
    box-shadow: none;
    border: solid 1px ${solidColors.darkGray.color};
    color: ${solidColors.darkGray.color};
  }
  ${props =>  props.$styleType === 'pill' && 'text-transform: none'};
  ${props =>  (props.$styleType === 'text_with_icon_leading' || props.$styleType === 'icon_leading') && 'width: auto !important;'};
`;

export const ButtonTextStyled = styled(
  Typography,
)<IButtonTextStyled>`
  ${(props) => {

    const baseStyles = `
            width: 100%;
            height: ${props.height ?? '2.5rem'};
            line-height: ${props.height ?? '2.5rem'};
            display: flex;
            align-items: center;
            ${props.align ?
              `
                text-align: ${props.align};
                justify-content: center;
              `
              : ''
            }
        `;
    const primaryStyles =
      `
            ${
              props.$isHovered &&
              `color: ${solidColors.blue5Percent.color}  !important;`
            }
        ` + baseStyles;

    const secondaryStyles =
      `
            ${
              props.$isHovered &&
              `color: ${solidColors.blue40Percent.color}  !important;`
            }
        ` + baseStyles;

    if (props.$styleType === 'primary') {
      return primaryStyles;
    } else if (props.$styleType === 'secondary') {
      return secondaryStyles;
    } else if (props.$styleType === 'text_with_icon_leading') {
      return `
        width: fit-content;
      `;
    }
  }}
`;

export const ArrowIconStyled = styled(Icons.ArrowForward)<{
  color?: string;
}>`
  color: ${({ color }) =>
    color ?? `${solidColors.lightGray.color}`};
`;

export const SvgIcon = styled.div`
  font-size: 0 !important;
  height: 20px;
  width: 20px;
  overflow: hidden;
`;
