import styled from 'styled-components';
import { Typography } from '@atoms';

export const StyledSingleContainer = styled.div<{
  align?: string;
}>`
  display: flex;
  justify-content: ${({ align }) => {
    switch (align) {
      case 'right':
        return 'flex-end';
        break;
      case 'center':
        return align;
      default:
        return 'left';
    }
  }};
`;

export const StyledSingleTextGroup = styled.div`
  font-size: 36px;
  color: #424242;
  text-decoration: none;
`;

export const StyledTitle = styled(Typography)`
  margin-top: 4px;

  &.strikethrough {
    text-decoration: line-through;
  }
`;
