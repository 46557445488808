import { useEffect, useState } from 'react';
import PopUpCard from '@molecules/PopUpCard/PopUpCard';
import { IErrorData } from './Error.interfaces';
import { ImgStyled } from './Error.styles';
import { useDispatch } from 'react-redux';
import { setUncaughtError } from '@services/apiService/responseInterceptors/handleUncaughtErrorSlice';
import { IAction } from '@atoms/Action/Action.interfaces';
import { IButton } from '@atoms/Button/Button.interfaces';
import {
  setErrorOpen,
  setErrorPayload,
} from './ErrorSlice';
import { useViewport } from '@hooks';

const Error = ({ open, data }: IErrorData) => {
  const { width } = useViewport();
  const dispatch = useDispatch();
  const [secondaryOpen, setSecondaryOpen] = useState(open);

  const handleCloseSecondary = (
    event: {},
    reason: string,
  ) => {
    if (reason === 'backdropClick') return;

    setSecondaryOpen(false);
    dispatch(setUncaughtError(false));
    dispatch(setErrorOpen(false));
    // setting back to initial state
    dispatch(
      setErrorPayload({
        buttons: [
          {
            action: {},
          },
          {
            action: {},
          },
        ],
        copy: 'Please try again later.',
        headline: 'Something went wrong',
        requiredUserInteraction: false,
      }),
    );
  };

  const getPopUpButton = (
    buttonType: 'primary' | 'secondary',
  ): IButton => {
    const emptyDataTitlePlaceholder =
      buttonType === 'primary' ? 'Cancel' : 'Ok';

    const btnData =
      data?.buttons[buttonType === 'primary' ? 1 : 0];

    const title =
      btnData?.title ?? emptyDataTitlePlaceholder;

    const action: IAction =
      btnData?.action.type === 'molecule_close' ||
      btnData === undefined
        ? {
            actionMethod: handleCloseSecondary,
          }
        : {
            ...btnData?.action,
          };

    return {
      ...btnData,
      styleType: buttonType,
      title,
      action,
    };
  };

  const getImg = () => {
    if (data && data.image) {
      return (
        <ImgStyled
          src={data.image.url}
          filter={data.image.tintColor}
          alt="Icon"
        />
      );
    }
  };

  const handleBackdropClick = (
    e: React.MouseEvent<HTMLDivElement>,
  ) => {
    e.preventDefault();
    e.stopPropagation();
    return;
  };

  return (
    <>
      <PopUpCard
        open={secondaryOpen}
        onBackdropClick={handleBackdropClick}
        closeAction={handleCloseSecondary}
        primary={getPopUpButton('primary')}
        secondary={getPopUpButton('secondary')}
        headlineIcon={getImg()}
        maxWidth="xs"
        minWidth={width > 600 ? '450px' : '300px'}
        headline={data?.headline ?? 'Something went wrong'}
        copy={data?.copy ?? 'Please try again later.'}
        {...data}
      />
    </>
  );
};

export default Error;
