import styled from 'styled-components';
import { solidColors } from '@constants/styles/colors.constants';

export const TitleIconContainer = styled.div<{
  radius?: string;
  borderColor?: string;
  bgColor?: string;
  width?: string;
  height?: string;
  active?: boolean;
}>`
  display: flex;
  background-color: ${({ bgColor }) =>
    bgColor ?? `${solidColors.upWhite.color}`};
  width: ${({ width }) => width ?? 'auto'};
  height: ${({ height }) => height ?? '35px'};
  border-radius: ${({ radius }) => radius ?? '8px'};
  justify-content: center;
  align-items: center;

  cursor: pointer;
  ${({ borderColor }) =>
    borderColor &&
    `
        border: 1px solid ${borderColor};
        padding: 10px;
        `}

  ${({ active }) =>
    active
      ? `border: 1px solid ${solidColors.upBlue.color}`
      : null}
`;
