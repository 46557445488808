import { ISubPageSelectPayment } from './SubPageSelectPayment.interfaces';
import { useAppSelector } from '@app/hooks';
import BookingFlowSelectPayment from './BookingFlowSelectPayment';
import AirmedSelectPayment from './AirmedSelectPayment';

const SubPageSelectPayment = ({
  title,
  subtitle,
  totalCostCaption,
  totalCostAmount,
  totalCostValue,
  selectionType,
  paymentOptions,
  addCreditCardButton,
  checkOrWireTransferButton,
}: ISubPageSelectPayment) => {
  const { airmedFlag } = useAppSelector(
    (state) => state.paymentStatus,
  );

  return (
    <>
      {airmedFlag ? (
        <AirmedSelectPayment
          title={title}
          subtitle={subtitle}
          totalCostCaption={totalCostCaption}
          totalCostAmount={totalCostAmount}
          totalCostValue={totalCostValue}
          selectionType={selectionType}
          paymentOptions={paymentOptions}
          addCreditCardButton={addCreditCardButton}
          checkOrWireTransferButton={
            checkOrWireTransferButton
          }
        />
      ) : (
        <BookingFlowSelectPayment
          title={title}
          subtitle={subtitle}
          totalCostCaption={totalCostCaption}
          totalCostAmount={totalCostAmount}
          totalCostValue={totalCostValue}
          selectionType={selectionType}
          paymentOptions={paymentOptions}
          addCreditCardButton={addCreditCardButton}
          checkOrWireTransferButton={
            checkOrWireTransferButton
          }
        />
      )}
    </>
  );
};

export default SubPageSelectPayment;
