import { theme } from '@constants/styles/theme.constants';
import {
  Icon,
  Typography,
} from '@wheelsup/wu-react-components';
import styled from 'styled-components';

export const HeadlineContainer = styled.div`
  @media (max-width: ${theme.breakpoints.values.md}px) {
    padding-top: 28px;
    margin-bottom: 10px;
  }
  @media screen and (min-width: 1000px) {
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 30px;
    margin-bottom: 10px;
  }
  width: 1136px;
`;

export const AirportInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const CodeSection = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  @media (max-width: ${theme.breakpoints.values.md}px) {
    margin-bottom: 2px;
  }
`;

export const CodeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background-color: white;
`;

export const DateAndEditContainer = styled.div`
  margin-bottom: 32px;
  @media (max-width: ${theme.breakpoints.values.md}px) {
    margin-bottom: 0px;
  }
  display: flex;
`;

export const MiddleIcon = styled(Icon)`
  margin: 0px 8px;
  @media (max-width: ${theme.breakpoints.values.md}px) {
    margin: 0px 5px;
  }
`;

export const ScrolledHeadlineTypography = styled(
  Typography,
)`
  margin-left: 32px;
  margin-right: 32px;
`;

export const ScrolledHeadlineContainer = styled.div`
  background-color: white;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const TopAreaContainer = styled.div`
  background-color: white;
  display: flex;
  justify-content: center;
`;

export const TripTypeTypography = styled(Typography)`
  margin-bottom: 5px;
  @media (max-width: ${theme.breakpoints.values.md}px) {
    margin-bottom: 3px;
  }
`;

export const DateTypography = styled(Typography)`
  margin-right: 10px;
`;

export const TripTypeContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const MobileTripTypeLabel = styled(Typography)`
  padding-right: 12.5px;
`;

export const HeadlineFlexed = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const FlightAmountText = styled(Typography)`
  margin-left: 8px;
`;
