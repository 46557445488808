import React, { useRef, useState } from 'react';
import { isEqual, cloneDeep } from 'lodash';
import {
  IconButton,
  InputAdornment,
  ListItemText,
} from '@library';
import {
  CheckIcon,
  CloseIcon,
  InputContainer,
  ListItemIconStyled,
  ListItemStyled,
  ListStyled,
  TextFieldStyled,
  VisibilityIcon,
  VisibilityOffIcon,
} from './PasswordField.styles';
import { inputUseStyles } from '../Input/Input.styles';

const validationRulesInitState: ValidationRule[] = [
  {
    validationSubCopy: 'Use 8 or more characters',
    regex: /^.{8,}$/,
    isValid: false,
  },
  {
    validationSubCopy: 'Use at least one number',
    regex: /^.*\d/,
    isValid: false,
  },
  {
    validationSubCopy:
      'Use both upper and lower case letters',
    regex: /(?=.*[a-z])(?=.*[A-Z])/,
    isValid: false,
  },
  {
    validationSubCopy:
      'Use at least one special character, excluding: ^() +=[]{};:’”<>,./~`\\|,',
    regex: /^(?=.*[@$!%*#?&-])[A-Za-z\d@$!%*#?&_-]{1,}$/,
    isValid: false,
  },
];

export interface PasswordResult {
  value: string;
  isValid: boolean;
}

interface ValidationRule {
  regex: RegExp;
  validationSubCopy: String;
  isValid: boolean;
}

interface IPasswordField {
  label: string;
  onChange: (result: PasswordResult) => void;
  onBlur?: (value: string) => void;
  error?: boolean;
  errorText?: string;
  isCreation?: boolean;
  onKeyDown?: any;
  datatestid?: string;
}

export default function PasswordField({
  label,
  onChange,
  onBlur,
  error,
  errorText,
  isCreation,
  datatestid,
  onKeyDown,
}: IPasswordField) {
  const classes = inputUseStyles({ error });

  const [inputVisible, setInputVisible] = useState(false);

  const [validationRules, setValidationRules] = useState(
    validationRulesInitState,
  );
  const isPasswordValid = useRef(isCreation ? false : true);

  const validateNewPassword = (newPassword: string) => {
    const validationsDuplicate = cloneDeep(validationRules);

    validationRules.forEach((v, i) => {
      const isMatch = v.regex.test(newPassword);
      validationsDuplicate[i].isValid = isMatch;
    });

    if (!isEqual(validationRules, validationsDuplicate)) {
      setValidationRules(validationsDuplicate);

      isPasswordValid.current = validationsDuplicate.every(
        (v) => {
          return v.isValid;
        },
      );
    }
  };

  const handleClickShowPassword = () => {
    setInputVisible(!inputVisible);
  };

  const onPasswordChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement
    >,
  ) => {
    if (isCreation) validateNewPassword(e.target.value);
    onChange({
      value: e.target.value,
      isValid: isPasswordValid.current,
    });
  };

  const hanldeOnBlur = (
    e: React.FocusEvent<
      HTMLInputElement | HTMLTextAreaElement
    >,
  ) => {
    onBlur?.(e.target.value);
  };

  return (
    <InputContainer>
      <TextFieldStyled
        onKeyDown={onKeyDown}
        error={!!errorText}
        helperText={errorText}
        label={label}
        type={inputVisible ? 'text' : 'password'}
        onChange={(e) => onPasswordChange(e)}
        onBlur={hanldeOnBlur}
        inputProps={{
          'data-testid': datatestid,
          maxLength: 100,
        }}
        InputLabelProps={{
          classes: {
            root: classes.label,
          },
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleClickShowPassword}
              >
                {inputVisible ? (
                  <VisibilityIcon />
                ) : (
                  <VisibilityOffIcon />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {isCreation ? (
        <div>
          <ListStyled>
            {validationRules.map((v, i) => {
              return (
                <ListItemStyled key={i}>
                  <ListItemIconStyled>
                    {v.isValid ? (
                      <CheckIcon />
                    ) : (
                      <CloseIcon />
                    )}
                  </ListItemIconStyled>
                  <ListItemText
                    primary={v.validationSubCopy}
                  />
                </ListItemStyled>
              );
            })}
          </ListStyled>
        </div>
      ) : null}
    </InputContainer>
  );
}
