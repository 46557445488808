import { Container } from '@components/library';
import styled from 'styled-components';
import { IStyledSpacer } from './EmptyContainer.interfaces';

export const StyledSpacer = styled.div<IStyledSpacer>`
  width: auto;
  background-color: transparent;
  height: ${({ height }) => height ?? '5px'};
`;

export const ContainerWrapper = styled(Container)`
  display: flex;
  flex-direction: column;
`;

export const FullContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

// if bleed is full, it needs to be a div, if not, make it a container
