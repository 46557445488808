import FormFieldEmailEntry from '@atoms/FormFieldEmailEntry/FormFieldEmailEntry';
import InputBase from '@atoms/InputBase';
import { solidColors } from '@constants/styles/colors.constants';
import { typography } from '@constants/styles/typography.constants';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ICardDayOfFlightContact } from './CardDayOfFlightContact.interfaces';
import {
  StyledCardDayOfFlightContact,
  StyledTitle,
} from './CardDayOfFlightContact.styles';
import {
  setConfirmContactEmail,
  setConfirmContactPhone,
} from '@features/Fly/Confirmation/ConfirmationPopUpSlice';
import { useAppSelector } from '@app/hooks';

const CardDayOfFlightContact = ({
  title,
  phoneFormField,
  emailFormField,
}: ICardDayOfFlightContact) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setConfirmContactEmail(
        emailFormField?.fieldCopy || '',
      ),
    );
    dispatch(
      setConfirmContactPhone(
        phoneFormField?.fieldCopy || '',
      ),
    );
  }, [dispatch]);

  const {
    confirmContactPhone,
    confirmContactEmail,
  } = useAppSelector(
    (state) =>
      state.confirmationContactDetails.confirmationPopUp,
  );

  const handleEmailChange = (e: any) => {
    dispatch(setConfirmContactEmail(e.target.value));
  };

  const handlePhoneChange = (e: any) => {
    dispatch(setConfirmContactPhone(e.target.value));
  };

  return (
    <StyledCardDayOfFlightContact>
      <StyledTitle
        variant={typography['heading04Large']}
        color={solidColors.midnight}
      >
        {title}
      </StyledTitle>
      <FormFieldEmailEntry
        title="Email"
        payloadKey="email"
        onChange={(e) => handleEmailChange(e)}
        getError={(error) => error}
        fieldCopy={confirmContactEmail || ''}
        {...emailFormField}
      />

      <InputBase
        type="phone"
        payloadKey="active"
        title={phoneFormField?.title || ''}
        fieldCopy={confirmContactPhone || ''}
        onChange={(e) => handlePhoneChange(e)}
        {...phoneFormField}
      />
    </StyledCardDayOfFlightContact>
  );
};

export default CardDayOfFlightContact;
