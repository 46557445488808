import styled from 'styled-components';
import { solidColors } from '@constants/styles/colors.constants';

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 70px;
  width: 100%;
  background-color: ${solidColors.upWhite.color};
  border-radius: 5px;
`;

export const ButtonItemContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  > button {
    width: 100%;
    height: 100%;
  }
`;
