import { createSlice } from '@reduxjs/toolkit';
export const initialState = {
  expiredSession: false,
};

const sessionSlice = createSlice({
  name: 'expiredSessionPopUp',
  initialState,
  reducers: {
    setError(state, action) {
      const { payload } = action;
      state.expiredSession = payload;
    },
  },
});

export const { setError } = sessionSlice.actions;

export default sessionSlice.reducer;
