import { useAppSelector } from '@app/hooks';
import { Typography } from '@atoms';
import FormFieldTitleDropDown, {
  IFormFieldTitleDropDown,
} from '@atoms/FormFieldTitleDropDown/FormFieldTitleDropDown';
import { Grid } from '@components/library';
import { setInputValue } from '@components/organisms/SubPagePassengerAddEdit/SubPagePassengerAddEdit.slice';
import { IInputField } from '@components/types';
import { solidColors } from '@constants/styles/colors.constants';
import { typography } from '@constants/styles/typography.constants';
import { useViewport } from '@hooks';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  DropdownContainer,
  InputBaseStyled,
} from './PassengerInformationForm.styles';
import { isPositiveInteger } from '@components/utils/isPositiveInteger';

interface IPassengerInformationForm {
  prefixDropdown: IFormFieldTitleDropDown;
  suffixDropdown: IFormFieldTitleDropDown;
  genderDropdown: IFormFieldTitleDropDown;
  firstNameFormField: IInputField;
  middleNameFormField: IInputField;
  lastNameFormField: IInputField;
  birthdayFormField: IInputField;
  weightFormField: IInputField;
  passengerInfoTitle: string;
  passengerInfoSubtitle?: string;
  isDateError: boolean;
  isGenderError: boolean;
  existingPassenger?: boolean;
  dateErrorMessage: string;
  handleBirthdayValidation: (birthday: string) => boolean;
  handleGenderValidation: () => void;
}

export default function PassengerInformationForm({
  prefixDropdown,
  suffixDropdown,
  genderDropdown,
  firstNameFormField,
  middleNameFormField,
  lastNameFormField,
  birthdayFormField,
  weightFormField,
  passengerInfoTitle,
  passengerInfoSubtitle,
  isDateError,
  isGenderError,
  existingPassenger = false,
  dateErrorMessage,
  handleBirthdayValidation,
  handleGenderValidation,
}: IPassengerInformationForm) {
  const dispatch = useDispatch();

  const { width } = useViewport();

  const {
    firstName,
    middleName,
    lastName,
    birthday,
    weight,
    prefix,
    suffix,
    gender,
  } = useAppSelector((state) => state.subPassengerAddEdit);

  useEffect(() => {
    if (existingPassenger) {
      handleGenderValidation();
    }
  }, [existingPassenger, gender]);

  const handleInputChange = (
    inputKey: string,
    value: string,
  ) => {
    dispatch(
      setInputValue({
        key: inputKey,
        value: value,
      }),
    );
  };

  const genderLeadingHelperCopy = () => {
    if (isGenderError) {
      return 'Gender missing';
    } else {
      return genderDropdown.leadingHelperCopy ?? '';
    }
  };

  return (
    <>
      <Typography
        variant={typography.heading03Large}
        color={solidColors.midnight}
      >
        {passengerInfoTitle}
      </Typography>
      <DropdownContainer>
        <FormFieldTitleDropDown
          id={prefixDropdown.id}
          style={prefixDropdown.style}
          title={prefixDropdown.title ?? ''}
          subtitle={prefixDropdown.placeholder}
          fieldCopy={prefixDropdown.fieldCopy}
          placeholder={prefixDropdown.placeholder}
          items={prefixDropdown.items}
          formInlineCTA={prefixDropdown.formInlineCTA}
          value={prefix}
          onChangeHandler={(option) =>
            handleInputChange('prefix', option)
          }
        />
      </DropdownContainer>
      <Grid
        container
        spacing={2}
      >
        <Grid
          container
          item
          md={12}
          spacing={2}
        >
          <Grid
            item
            md={4}
            sm={6}
            xs={6}
          >
            <InputBaseStyled
              type={firstNameFormField.type}
              variant="outlined"
              payloadKey={firstNameFormField.key ?? ''}
              title={
                firstNameFormField.title ?? 'First name'
              }
              value={firstName}
              onChange={(e) => {
                handleInputChange(
                  'firstName',
                  e.target.value,
                );
              }}
              maxCharactersAllowed={15}
            />
          </Grid>
          <Grid
            item
            md={4}
            sm={6}
            xs={6}
          >
            <InputBaseStyled
              type={middleNameFormField.type}
              variant="outlined"
              payloadKey={middleNameFormField.key ?? ''}
              title={
                width < 1000
                  ? 'Middle'
                  : `${middleNameFormField.title} (optional)`
              }
              value={middleName}
              onChange={(e) => {
                handleInputChange(
                  'middleName',
                  e.target.value,
                );
              }}
              maxCharactersAllowed={15}
            />
          </Grid>
          <Grid
            item
            md={4}
            sm={6}
            xs={6}
          >
            <InputBaseStyled
              type={lastNameFormField.type}
              variant="outlined"
              payloadKey={lastNameFormField.key ?? ''}
              title={lastNameFormField.title ?? 'Last Name'}
              value={lastName}
              onChange={(e) => {
                handleInputChange(
                  'lastName',
                  e.target.value,
                );
              }}
              maxCharactersAllowed={15}
            />
          </Grid>
          <Grid
            item
            md={3}
            sm={6}
            xs={6}
          >
            <InputBaseStyled
              type={'simpleDate'}
              variant="outlined"
              payloadKey="error"
              title={birthdayFormField.title ?? ''}
              value={birthday}
              error={isDateError}
              errorMessage={dateErrorMessage}
              leadingHelperCopy={
                birthdayFormField.leadingHelperCopy
              }
              onChange={(e) => {
                handleInputChange(
                  'birthday',
                  e.target.value,
                );
                handleBirthdayValidation(e.target.value);
              }}
            />
          </Grid>
          <Grid
            item
            md={3}
            sm={6}
            xs={6}
          >
            <FormFieldTitleDropDown
              id={genderDropdown?.id ?? ''}
              style={genderDropdown?.style ?? 'outlined'}
              error={isGenderError}
              title={genderDropdown?.title ?? ''}
              subtitle={genderDropdown?.placeholder ?? ''}
              fieldCopy={genderDropdown?.fieldCopy ?? ''}
              leadingHelperCopy={genderLeadingHelperCopy()}
              placeholder={
                genderDropdown?.placeholder ?? ''
              }
              items={genderDropdown?.items ?? []}
              formInlineCTA={genderDropdown?.formInlineCTA}
              value={gender ?? ''}
              onChangeHandler={(option) =>
                handleInputChange('gender', option)
              }
            />
          </Grid>
          <Grid
            item
            md={3}
            sm={6}
            xs={6}
          >
            <InputBaseStyled
              type="text"
              variant="outlined"
              payloadKey={weightFormField.key ?? ''}
              title={weightFormField.title ?? 'Weight'}
              value={weight}
              error={
                existingPassenger &&
                (weight === '' || Number(weight) === 0)
              }
              errorMessage="Weight is missing"
              leadingHelperCopy={
                weightFormField.leadingHelperCopy
              }
              onChange={(e) => {
                if (
                  e.target.value === '' ||
                  isPositiveInteger(e.target.value)
                )
                  handleInputChange(
                    'weight',
                    e.target.value,
                  );
              }}
              maxCharactersAllowed={
                weightFormField.maxCharactersAllowed ?? 3
              }
            />
          </Grid>
          <Grid
            item
            md={3}
            sm={6}
            xs={6}
          >
            <FormFieldTitleDropDown
              id={suffixDropdown.id}
              style={suffixDropdown.style}
              title={suffixDropdown.title ?? ''}
              subtitle={suffixDropdown.placeholder ?? ''}
              fieldCopy={suffixDropdown.fieldCopy}
              placeholder={suffixDropdown.placeholder}
              items={suffixDropdown.items}
              formInlineCTA={suffixDropdown.formInlineCTA}
              value={suffix}
              onChangeHandler={(option) =>
                handleInputChange('suffix', option)
              }
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
