import { useEffect, useState } from 'react';
import { typography } from '@constants/styles/typography.constants';
import { Typography } from '@atoms';
import { FormFieldFreeTypeLarge } from '@molecules';
import {
  StyledCard,
  FormFieldStyled,
  BoxStyled,
  HeadlineStyled,
  SubCopyStyled,
  ButtonStyled,
  StyledImage,
  StyledIconButton,
} from './CardPostBookingRequest.styles';
import { ICardPostBookingRequest } from './CardPostBookingRequest.interfaces';
import { solidColors } from '@constants/styles/colors.constants';
import { ActionTypes } from '@atoms/Action/Action.interfaces';
import { useDispatch } from 'react-redux';
import { dataSliceOptions } from '@services/sliceFactoryService/sliceFactoryService.types';
import { getFromLocalStorage } from '@components/utils/storage';
import { postBookingRequest } from '@features/Fly/Confirmation/PostBookingSlice';
import { useAppSelector } from '@app/hooks';
import { Icons } from '@components/library';
import { fetchProcessEnvVariable } from '@services/envService/envService';

const CardPostBookingRequest = ({
  title,
  copy,
  image,
  button,
  textView,
  postSubmissionTitle,
  postSubmissionBody,
  dismissable,
  requestType,
}: ICardPostBookingRequest) => {
  const dispatch = useDispatch();

  const [textFieldVal, setTextFieldVal] = useState('');
  const [dismissed, setDismissed] = useState(false);

  const { reservationId } = useAppSelector(
    (state) => state.postBooking.postBookGround,
  );

  const postBookGroundStatus = useAppSelector(
    (state) =>
      state.postBooking.postBookGround
        .postBookingRequestStatus,
  );

  const postBookCateringStatus = useAppSelector(
    (state) =>
      state.postBooking.postBookCatering
        .postBookingRequestStatus,
  );

  const postBookSpecialStatus = useAppSelector(
    (state) =>
      state.postBooking.postBookSpecial
        .postBookingRequestStatus,
  );

  const postBookingRequestStatusList = [
    postBookGroundStatus,
    postBookCateringStatus,
    postBookSpecialStatus,
  ];

  const token = getFromLocalStorage('access_token');

  const handleClose = () => {
    if (
      dismissable?.action.type ===
      ('molecule_close' as ActionTypes)
    ) {
      setDismissed(true);
    }
  };

  const buttonHandler = () => {
    if (
      button.action.type ===
      ('molecule_submit' as ActionTypes)
    ) {
      switch (requestType) {
        case 'specialRequest':
          dispatch(
            postBookingRequest(
              dataSliceOptions.postBookingSpecialRequest,
            )({
              token: token,
              urlParams: `/${fetchProcessEnvVariable(
                'CLIENT_ID',
                true,
              )}`,
              resPayload: {
                reservationId: reservationId,
                specialRequestText: textFieldVal,
              },
            }),
          );
          break;
        case 'groundTransportation':
          dispatch(
            postBookingRequest(
              dataSliceOptions.postBookingGroundTransport,
            )({
              token: token,
              urlParams: `/${fetchProcessEnvVariable(
                'CLIENT_ID',
                true,
              )}`,
              resPayload: {
                reservationId: reservationId,
                groundTransportText: textFieldVal,
              },
            }),
          );
          break;
        case 'catering':
          dispatch(
            postBookingRequest(
              dataSliceOptions.postBookingCatering,
            )({
              token: token,
              urlParams: `/${fetchProcessEnvVariable(
                'CLIENT_ID',
                true,
              )}`,
              resPayload: {
                reservationId: reservationId,
                cateringText: textFieldVal,
              },
            }),
          );
          break;
        default:
          break;
      }
    }
  };

  return (
    <>
      {!dismissed && (
        <StyledCard data-name="CardPostBookingRequest">
          <BoxStyled>
            {image ? (
              <StyledImage
                width={24}
                height={24}
                src={image.url}
                filter={image.tintColor}
                alt="LowPriorityImage"
              />
            ) : null}
            <HeadlineStyled>
              <Typography
                align="left"
                variant={typography.heading03Large}
                color={solidColors.midnight}
                truncate={2}
              >
                {!postBookingRequestStatusList.includes(
                  requestType,
                )
                  ? title
                  : postSubmissionTitle}
              </Typography>
            </HeadlineStyled>
            {dismissable && (
              <StyledIconButton
                onClick={handleClose}
                className="closeBtn"
              >
                <Icons.Close />
              </StyledIconButton>
            )}
          </BoxStyled>
          <SubCopyStyled>
            <Typography
              align="left"
              variant={typography.body2}
              color={solidColors.darkGray}
              truncate={2}
            >
              {!postBookingRequestStatusList.includes(
                requestType,
              )
                ? copy
                : postSubmissionBody}
            </Typography>
          </SubCopyStyled>

          {!postBookingRequestStatusList.includes(
            requestType,
          ) && (
            <>
              <FormFieldStyled>
                <FormFieldFreeTypeLarge
                  placeholder={
                    textView.placeholder
                      ? textView.placeholder
                      : 'Type here...'
                  }
                  value={textFieldVal}
                  variant={undefined}
                  maxLength={textView.maxCharactersAllowed}
                  onChange={(e) => {
                    setTextFieldVal(e.target.value);
                  }}
                  disabled={postBookingRequestStatusList.includes(
                    requestType,
                  )}
                />
              </FormFieldStyled>
              <ButtonStyled
                styleType={'card_secondary_web'}
                title={button.title || 'Add Request'}
                action={{
                  actionMethod: buttonHandler,
                }}
              />
            </>
          )}
        </StyledCard>
      )}
    </>
  );
};

export default CardPostBookingRequest;
