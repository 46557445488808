import { typography } from '@constants/styles/typography.constants';
import { Typography } from '@atoms';
import {
  LineItem,
  PriceItemsDropdownSection,
  TextGroupCheckboxBodyCopyLink,
  TextGroupSingleTextGroup,
} from '@molecules';
import {
  StyledDivision,
  StyledCard,
  StyledTitleContainer,
  StyledPaymentRulesContainer,
  StyledButton,
  LegalCheckboxesContainer,
  PaymentRulesContainer,
  Divider,
  LegalContainer,
} from './CardPriceBreakdown.styles';
import { ICardPriceBreakdown_v2 } from './CardPriceBreakdown.interfaces';
import { ActionTypes } from '@atoms/Action/Action.interfaces';
import {
  confirmPurchaseRequest,
  setInitialCheckboxFlags,
  setLegalCheckboxFlag,
  setLegalCheckboxFlags,
} from '@features/Fly/Payment/PaymentStatusSlice';
import { useAppSelector } from '@app/hooks';
import { useDispatch } from 'react-redux';
import { getFromSessionStorage } from '@components/utils/storage';
import { getAccessToken } from '@services/tokenService/token';
import { IPassengerPayment } from '@components/types';
import { IPriceItemsDropdownSection } from '../PriceItemsDropdownSection/PriceItemsDropdownSection.interfaces';
import useSessionLock from '@hooks/use-session-lock';
import { useParams } from 'react-router-dom';
import { ISingleTextGroup } from '../TextGroupSingleTextGroup/TextGroupSingleTextGroup.interfaces';
import { useEffect, useMemo } from 'react';
import { Typography as SharedLibraryTypography } from '@wheelsup/wu-react-components';

const CardPriceBreakdown_v2 = ({
  title,
  subtotalFeesCreditsSections,
  totalLineItem,
  savingsSection,
  paymentRulesTitle,
  paymentRulesCopy,
  legalCheckbox,
  legalCheckboxes,
  legalCheckboxCopy,
  applyPayment,
}: ICardPriceBreakdown_v2) => {
  const { flightDealId } = useParams();

  const dispatch = useDispatch();
  const { addSessionLock } = useSessionLock();

  const {
    paymentLocation,
    selectedCredits,
    selectedPaymentMethods,
    legalCheckboxFlag,
    legalCheckboxFlags,
  } = useAppSelector((state) => state.paymentStatus);
  
  useEffect(() => {
    const legalCheckboxesInitialValues = legalCheckboxes?.map(() => false) ?? [];
    dispatch(setInitialCheckboxFlags(legalCheckboxesInitialValues));
  }, [legalCheckboxes]);

  const handleButton = (type: ActionTypes) => {
    if (type === 'uri') {
      window.location.replace('/fly/confirmation');
    }

    if (
      type === 'page_submit' &&
      paymentLocation === 'payment'
    ) {
      const bookingFlow = getFromSessionStorage(
        'flightPurchaseCheckout',
      );
      const newBookingRequestLegDetails = bookingFlow?.bookingRequestLegDetails.map(
        (leg: any) => {
          const newPassengers = leg.passengers.map(
            (passenger: any) => {
              if (passenger.pet) {
                return {
                  id: passenger.id,
                  firstName: passenger.name,
                  isPet: true,
                  isLead: false,
                } as IPassengerPayment;
              } else {
                return {
                  id: passenger.id,
                  firstName: passenger.firstName,
                  lastName: passenger.lastName,
                  isLead: passenger.isLeadPassenger,
                  phone: passenger.phone,
                  email: passenger.email,
                  isPet: false,
                } as IPassengerPayment;
              }
            },
          );

          return {
            ...leg,
            passengers: newPassengers,
          };
        },
      );
      dispatch(
        confirmPurchaseRequest({
          token: getAccessToken(),
          resPayload: {
            ...{
              ...bookingFlow,
              bookingRequestLegDetails: newBookingRequestLegDetails,
            },
            selectedPayments: [
              ...selectedCredits,
              ...selectedPaymentMethods,
            ],
          },
        }),
      );
    }
  };

  const handleLegalCheckboxFlag = (
    legalCheckboxFlag: boolean,
  ) => {
    dispatch(setLegalCheckboxFlag(legalCheckboxFlag));
    addSessionLock(flightDealId);
  };

  const handleLegalCheckboxFlags = (
    legalCheckboxFlag: boolean,
    index: number,
  ) => {
    dispatch(setLegalCheckboxFlags({value: legalCheckboxFlag, payloadIndex: index}));
    addSessionLock(flightDealId);
  };

  const disablePaymentButton = useMemo(() => {
    const hasSingleLegalCheckbox = !(legalCheckboxes && legalCheckboxes.length > 0);
    let allCheckboxesChecked = false;
    if (hasSingleLegalCheckbox) {
      allCheckboxesChecked = legalCheckboxFlag;
    } else if (legalCheckboxFlags && legalCheckboxFlags.length > 0) {
      allCheckboxesChecked = legalCheckboxFlags.every((flag: boolean) => flag);
    }

    return !(
      (selectedCredits.length > 0 ||
        selectedPaymentMethods.length > 0) &&
        allCheckboxesChecked
    );
  }, [selectedCredits, selectedPaymentMethods, legalCheckboxFlag, legalCheckboxFlags]);

  const renderTotalLineItemStyle = (assignedType: string) => {
    return (
      (assignedType === 'leftItem' ? {...totalLineItem['leftItem'], styleType: 'h6_a_c18'} : {...totalLineItem['rightItem'], styleType: 'h6_a_c18'}) as ISingleTextGroup
    ); 
  };

  return (
    <StyledCard id="CardPriceBreakdown">
      <StyledTitleContainer>
        <Typography variant={typography.heading03Large}>
          {title}
        </Typography>
      </StyledTitleContainer>

      {subtotalFeesCreditsSections &&
        subtotalFeesCreditsSections.map(
          (
            priceItemsDropdownSection: IPriceItemsDropdownSection,
            index,
          ) => (
            <>
              <PriceItemsDropdownSection
                key={index}
                {...priceItemsDropdownSection}
              />

              <StyledDivision />
            </>
          ),
        )}

      {totalLineItem && (
        <>
          <LineItem
            leftItem={renderTotalLineItemStyle('leftItem')}
            leftItemSubtitle={
              totalLineItem.leftItemSubtitle
            }
            rightItem={renderTotalLineItemStyle('rightItem')}
          />
          {/* <StyledDivision /> */}
        </>
      )}

      {savingsSection && (
        <PriceItemsDropdownSection {...savingsSection} />
      )}

      {(paymentRulesTitle ||
        legalCheckbox ||
        applyPayment) && (
        <StyledPaymentRulesContainer>
          {paymentRulesTitle && (
            <TextGroupSingleTextGroup
              title={paymentRulesTitle.title}
              styleType={paymentRulesTitle.styleType}
              alignmentState={
                paymentRulesTitle.alignmentState
              }
            />
          )}
          {paymentRulesCopy && (
            <PaymentRulesContainer>
              <TextGroupSingleTextGroup
                title={paymentRulesCopy.title}
                styleType={paymentRulesCopy.styleType}
                alignmentState={
                  paymentRulesCopy.alignmentState
                }
              />
              <Divider />
            </PaymentRulesContainer>
          )}
          {
            legalCheckboxCopy ? (
              <LegalContainer>
                <SharedLibraryTypography
                  variant='body02'
                  color='primary'
                >
                  {legalCheckboxCopy.title}
                </SharedLibraryTypography>
              </LegalContainer>
            ) : null
          }
          {legalCheckbox && !legalCheckboxes && (
            <LegalContainer>
              <TextGroupCheckboxBodyCopyLink
                titleTextGroup={
                  legalCheckbox.data.titleTextGroup
                }
                isPreselected={legalCheckboxFlag}
                onChange={() =>
                  handleLegalCheckboxFlag(!legalCheckboxFlag)
                }
              />
            </LegalContainer>
          )}
          {legalCheckboxFlags && legalCheckboxes && legalCheckboxes.length > 0
            ? (
              <LegalCheckboxesContainer>
                {legalCheckboxes.map(
                  (
                    legalCheckbox: any,
                    index,
                  ) => {
                    return (
                      <TextGroupCheckboxBodyCopyLink
                        key={index}
                        titleTextGroup={legalCheckbox.data.titleTextGroup}
                        isPreselected={legalCheckboxFlags[index]}
                        onChange={() => {
                          handleLegalCheckboxFlags(
                            !legalCheckboxFlags[index],
                            index,
                          );
                        }}
                      />
                    );
                  })
                }
              </LegalCheckboxesContainer>
            )
            : null
          }
          {applyPayment && (
            <StyledButton
              height="52px"
              title={applyPayment.title}
              action={{
                actionMethod: () => {
                  handleButton(
                    applyPayment.action.type || 'page_back',
                  );
                },
              }}
              styleType={
                disablePaymentButton
                  ? 'disabled'
                  : 'primary'
              }
              disabled={disablePaymentButton}
            />
          )}
        </StyledPaymentRulesContainer>
      )}
    </StyledCard>
  );
};

export default CardPriceBreakdown_v2;
