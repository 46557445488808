import { Typography } from '@atoms';
import { SubtitleContainer } from '@components/organisms/SubPagePassengerSelection/PassengerListCard/PassengerListCard.styles';
import { solidColors } from '@constants/styles/colors.constants';
import { typography } from '@constants/styles/typography.constants';
import { theme } from '@constants/styles/theme.constants';
import React, { useEffect } from 'react';

import { IFlightDetailsEditor } from './CardFlightDetailsEditor.interfaces';
import {
  StyledCard,
  TitleContainer,
  LuggageContainer,
  ContentContainer,
} from './CardFlightDetailsEditor.styles';

import TextGroupIconText from '../TextGroupIconText/TextGroupIconText';
import {
  TitleIconContainer,
  ListCTAIconHeadline,
} from '@molecules';
import useSessionLock from '@hooks/use-session-lock';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getFromLocalStorage,
  saveDataInLocalStorage,
  saveDataInSessionStorage,
} from '@components/utils/storage';
import { useAppSelector } from '@app/hooks';
import { useViewport } from '@hooks';
import { ILuggage, IPassenger } from '@components/types';
import { IImgTitleCopyStepperData } from '../ImageTitleSubtitleStepperListItemView/ImageTitleSubtitleStepperListItemView.interfaces';
import CarouselListLine from '../CarouselListLine/CarouselListLine';
import { Elevation } from '@wheelsup/wu-react-components';

const CardFlightDetailsEditor = ({
  flightId,
  title,
  subtitle,
  passengerImage,
  passengerTitle,
  luggageImage,
  luggageTitle,
  luggage,
  passengers,
  pets,
  isWithinChangeWindow,
  maxNumberOfPassengers,
}: IFlightDetailsEditor) => {
  const navigate = useNavigate();
  const { width } = useViewport();
  const {
    lockId,
    availabilityId,
    error: lockError,
  } = useAppSelector((state) => state.sessionLock);

  const {
    luggages,
    selectedPassengersLeg,
  } = useAppSelector((state) => state.searchTripDetails);

  const lockIdStorage = getFromLocalStorage('lockId');
  const availabilityIdStorrage = getFromLocalStorage(
    'availabilityId',
  );
  const activeLockId = lockIdStorage ?? lockId;
  const activeAvailabilityId =
    availabilityIdStorrage ?? availabilityId;

  const { flightDealId } = useParams();
  const { addSessionLock } = useSessionLock();
  const activePassengers =
    selectedPassengersLeg[0]?.selectedPassengers ??
    passengers;

  const defaultPassenger = passengers[0];

  const selectedPassengersLegs = [
    {
      selectedPassengers: [defaultPassenger],
    },
  ];

  const getLeadPassenger = (passengers: IPassenger[]) => {
    const filteredPassenger = passengers
      ?.map((passenger) => {
        if (passenger.isLeadPassenger) {
          return passenger;
        }
      })
      .filter((lead) => lead?.isLeadPassenger === true);

    return filteredPassenger[0];
  };

  const getFullName = () => {
    const activePassenger =
      selectedPassengersLeg[0]?.selectedPassengers ??
      passengers;

    if (activePassenger.length > 1) {
      return `${leadPassenger?.firstName} ${leadPassenger?.lastName} (${activePassenger.length})`;
    } else {
      return `${leadPassenger?.firstName} ${leadPassenger?.lastName}`;
    }
  };

  const convertItem = (name: string) => {
    const item = name.split('_').join(' ').toLowerCase();
    return item === 'bags' ? 'standard' : item;
  };

  const transformLuggage = () => {
    if (luggages) {
      const newLuggages: {
        luggageCount?: number;
        luggageType?: string;
      }[] = [];
      Object.values(luggages).forEach(
        (item: {
          flightId: string;
          luggageData: IImgTitleCopyStepperData[];
        }) => {
          if (Array.isArray(item.luggageData)) {
            const luggageData = item.luggageData.map(
              (l) => {
                return {
                  luggageCount: l.data.stepper.defaultValue,
                  luggageType: l.data.codeValue,
                };
              },
            );
            newLuggages.push(...luggageData);
          }
        },
      );
      return newLuggages;
    } else {
      return luggage;
    }
  };

  const getLuggage = () => {
    if (luggages) {
      let selectedLuggages = '';
      const newLuggages = transformLuggage();
      if (Array.isArray(newLuggages)) {
        (newLuggages as ILuggage[])?.map(
          (item: ILuggage) => {
            if (item.luggageCount >= 1) {
              selectedLuggages += `${
                item.luggageCount
              } ${convertItem(item.luggageType)} `;
            }
          },
        );
      }

      if (newLuggages && newLuggages.length >= 1) {
        return selectedLuggages;
      } else {
        return 'No Luggage';
      }
    }
  };

  const leadPassenger = getLeadPassenger(activePassengers);

  saveDataInLocalStorage('leadPassenger', defaultPassenger);
  saveDataInLocalStorage(
    'selectedPassengersLegs',
    selectedPassengersLegs,
  );

  saveDataInLocalStorage(
    'flightDetailId',
    flightId as string,
  );

  useEffect(() => {
    const newLuggages = transformLuggage();
    const bookingRequestLegDetails = [
      {
        passengers: activePassengers,
        luggage: newLuggages,
      },
    ];

    const session = getFromLocalStorage('sessionInfo');

    const flightCheckoutData = {
      bookingRequestLegDetails,
      tripType: 'one-way',
      reservationType: 'request-book',
      flightDealId: activeAvailabilityId,
      lockId: activeLockId,
      sessionInfo: session?.sessionInfo,
    };

    saveDataInSessionStorage(
      'flightPurchaseCheckout',
      flightCheckoutData,
    );
  }, [lockId]);

  return (
    <Elevation variant="raisedLevel2" padding={0}>
      <StyledCard>
        <TitleContainer>
          <Typography
            variant={typography.heading02Large}
            color={solidColors.midnight}
          >
            {title}
          </Typography>

          <Typography
            variant={typography.body1}
            color={solidColors.midnight}
          >
            {subtitle}
          </Typography>
        </TitleContainer>
        {width > theme.breakpoints.values.sm ? (
          <LuggageContainer>
            <ContentContainer>
              <TextGroupIconText
                key={'text-data-1'}
                headline={passengerTitle}
                extraCopyVariant={typography.body2}
                extraCopyColor="secondary"
                buttonAction={() => {
                  addSessionLock(flightDealId);
                  navigate(
                    `/select-passengers/0/${maxNumberOfPassengers}`,
                  );
                }}
                copy={getFullName()}
                isSpecial={false}
                headerSize="1.375rem"
                isButton
                icon={
                  <TitleIconContainer
                    width="64px"
                    height="64px"
                    radius="50%"
                    image={passengerImage.url}
                  />
                }
                buttonTitle="ADD"
                flexDirection="column"
                margin="0px 80px"
              />
            </ContentContainer>

            <ContentContainer>
              <TextGroupIconText
                key={'text-data-2'}
                headline={luggageTitle}
                extraCopyVariant={typography.body2}
                extraCopyColor="secondary"
                buttonAction={() => {
                  addSessionLock(flightDealId);
                  navigate(
                    `/flight-detail/manage-luggage/${flightId}`,
                  );
                }}
                copy={getLuggage()}
                isSpecial={false}
                headerSize="1.375rem"
                isButton
                icon={
                  <TitleIconContainer
                    width="64px"
                    height="64px"
                    radius="50%"
                    image={luggageImage.url}
                  />
                }
                buttonTitle="ADD"
                flexDirection="column"
                margin="0px 80px"
              />
            </ContentContainer>
          </LuggageContainer>
        ) : (
          <CarouselListLine id={null}>
            <ListCTAIconHeadline
              key={'text-data-mweb-1'}
              id={null}
              title={passengerTitle}
              subtitle={getFullName()}
              icon={{
                url: passengerImage.url,
              }}
              button={{
                action: {
                  actionMethod: () => {
                    addSessionLock(flightDealId);
                    navigate(
                      `/select-passengers/0/${maxNumberOfPassengers}`,
                    );
                  },
                },
              }}
            />
            <ListCTAIconHeadline
              key={'text-data-mweb-2'}
              id={null}
              title={luggageTitle}
              subtitle={getLuggage()}
              icon={{
                url: luggageImage.url,
              }}
              button={{
                action: {
                  actionMethod: () => {
                    addSessionLock(flightDealId);
                    navigate(
                      `/flight-detail/manage-luggage/${flightId}`,
                    );
                  },
                },
              }}
            />
          </CarouselListLine>
        )}
      </StyledCard>
    </Elevation>
  );
};

export default CardFlightDetailsEditor;
