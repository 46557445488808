import { useGetUserTermsAndConditionsQuery } from '@graphql/generated/graphql';
import { termsOfServiceUrl } from '@constants/urls/urls.constants';
import SubPageTermsOfService from '../SubPageTermsOfService/SubPageTermsOfService';

const TermsOfServicePopup = () => {
  const {
    data,
    isLoading,
  } = useGetUserTermsAndConditionsQuery();

  if (
    isLoading ||
    data?.pwUserTermsAndConditions
      ?.termsAndConditionsAccepted
  ) {
    return null;
  }

  return (
    <SubPageTermsOfService
      termsOfServiceURL={termsOfServiceUrl}
      version={data?.pwUserTermsAndConditions?.version}
    />
  );
};

export default TermsOfServicePopup;
