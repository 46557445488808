import { useState } from 'react';
import styled from 'styled-components';
import { Grid } from '@library';
import { Typography } from '@atoms';
import { typography } from '@constants/styles/typography.constants';
import { solidColors } from '@constants/styles/colors.constants';
import { GroupSubCopyImageSpecs } from '@molecules';
import {
  ButtonGroupStyled,
  ButtonStyled,
  ContainerBackground,
  ContentArea,
} from './ContainerHeadlineBGSubnav.styles';
import { IContainerProps } from './ContainerHeadlineBGSubnav.interfaces';
import Spacer from '@atoms/Spacer/Spacer';
import { trackAnalytics } from '@services/mixpanelService/mixpanel.service';
import { useFeatureFlag } from '@services/featureFlagService/featureFlagService.services';
import { FeatureFlags } from '@services/featureFlagService/constants';

const HeadlineContainer = styled(Grid)``;

export default function ContainerHeadlineBGSubnav({
  headline,
  items,
  spacerTypeTop,
  spacerTypeBottom,
}: IContainerProps) {
  const [currentSelection, setCurrentSelection] = useState(
    items[0],
  );

  const usePremiumJet = useFeatureFlag(
    FeatureFlags.USE_PREMIUM_JET,
  );

  function handleSelection(selectedId: number) {
    setCurrentSelection(items[selectedId]);
    trackAnalytics(items[selectedId].analytics);
  }

  return (
    <ContainerBackground data-name="TextGroupHeadlineSubNavSubCopyImageSpecsWEBONLY">
      {spacerTypeTop && <Spacer height={spacerTypeTop} />}
      <ContentArea>
        <HeadlineContainer>
          <Typography
            variant={typography.heading01Large}
            color={solidColors.midnight}
            align="center"
            truncate={1}
          >
            {headline}
          </Typography>
        </HeadlineContainer>
        <ButtonGroupStyled>
          {items.map(({ title }, index: number) =>
            !usePremiumJet &&
            title === 'Premium Jet' ? null : (
              <ButtonStyled
                onClick={() => handleSelection(index)}
                key={title}
                selected={currentSelection.title === title}
              >
                <Typography
                  variant={typography.heading05Large}
                  color={solidColors.midnight}
                  align="center"
                  truncate={1}
                  as="p"
                >
                  {title}
                </Typography>
              </ButtonStyled>
            ),
          )}
        </ButtonGroupStyled>

        {currentSelection && (
          <GroupSubCopyImageSpecs {...currentSelection} />
        )}
      </ContentArea>
      {spacerTypeBottom && (
        <Spacer height={spacerTypeBottom} />
      )}
    </ContainerBackground>
  );
}
