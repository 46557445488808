import NumberFormat from 'react-number-format';

export interface ISimpleDateMask {
  inputRef: (
    instance: NumberFormat<string | number> | null,
  ) => void;
  onChange: (event: {
    target: { name: string; value: string };
  }) => void;
  name: string;
}

export default function SimpleDateMask({
  inputRef,
  name,
  onChange,
  ...rest
}: ISimpleDateMask) {
  return (
    <NumberFormat
      {...rest}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name,
            value: values.value,
          },
        });
      }}
      format="##/##/####"
    />
  );
}
