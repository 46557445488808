import {
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

interface ILoading {
  isButtonPopupOpen: boolean;
  isPagePopupOpen: boolean;
  isSnackbarPopupOpen: boolean;
}

export const initialState: ILoading = {
  isButtonPopupOpen: false,
  isPagePopupOpen: true,
  isSnackbarPopupOpen: false,
};

export const popupSlice = createSlice({
  name: 'popupOpen',
  initialState,
  reducers: {
    buttonPopupOpen: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.isButtonPopupOpen = action.payload;
    },
    pagePopup: (state, action: PayloadAction<boolean>) => {
      state.isPagePopupOpen = action.payload;
    },
    snackbarOpen: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.isSnackbarPopupOpen = action.payload;
    },
  },
  extraReducers: {},
});

export const {
  buttonPopupOpen,
  pagePopup,
  snackbarOpen,
} = popupSlice.actions;

export const MemberPopupReducer = popupSlice.reducer;

export default MemberPopupReducer;
