import {
  LineItem,
  TextGroupSingleTextGroup,
} from '@molecules';
import {
  StyledAccordion,
  StyledAccordionSummary,
  StyledAccordionDetails,
} from './PriceItemsDropdownSection.styles';
import { ILineItem } from '@molecules/LineItem/LineItem.interfaces';
import { IPriceItemsDropdownSection } from './PriceItemsDropdownSection.interfaces';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const PriceItemsDropdownSection = ({
  sectionTotalCostLineItem,
  detailedCostsLineItems,
  disclaimers,
}: IPriceItemsDropdownSection) => {
  return detailedCostsLineItems?.length > 0 &&
    detailedCostsLineItems[0].length > 0 ? (
    <StyledAccordion>
      <StyledAccordionSummary
        expandIcon={<ExpandMoreIcon />}
      >
        <LineItem {...sectionTotalCostLineItem} />
      </StyledAccordionSummary>
      <StyledAccordionDetails>
        {detailedCostsLineItems?.map(
          (lineItemList: ILineItem[]) => {
            return lineItemList.map(
              (lineItem: ILineItem, index) => (
                <LineItem
                  key={index}
                  {...lineItem}
                />
              ),
            );
          },
        )}

        {disclaimers &&
          disclaimers.map((disclaim, index) => (
            <TextGroupSingleTextGroup
              key={index}
              title={disclaim.title}
              styleType={disclaim.styleType}
              alignmentState={disclaim.alignmentState}
            />
          ))}
      </StyledAccordionDetails>
    </StyledAccordion>
  ) : (
    <LineItem
      leftItem={sectionTotalCostLineItem.leftItem}
      rightItem={sectionTotalCostLineItem.rightItem}
    />
  );
};

export default PriceItemsDropdownSection;
