import { SpacerType } from '@components/types';
import { ISpacer } from './Spacer.interfaces';
import { StyledSpacer } from './Spacer.styles';

const Spacer = ({ height }: ISpacer) => {
  const determinedHeight =
    SpacerType[height as keyof typeof SpacerType];
  return <StyledSpacer height={`${determinedHeight}px`} />;
};

export default Spacer;
