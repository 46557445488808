import { IAction } from './Action.interfaces';

export const parseUri = (text: string) => {
  const rx = /wheelsup:\/\/(.*)/g;
  const rxEx = rx.exec(text);
  if (rxEx) {
    const term = rxEx[1];
    return `/${term}`;
  }
  return '/' + text;
};

const Action = ({ type, data, children }: IAction) => {
  let href = data;
  switch (type) {
    case 'phone':
      href = `tel:${href}`;
      break;
    case 'email':
      href = `mailto:${href}`;
      break;
    case 'uri':
      href = parseUri(href);
      break;
    case 'page_action':
    case 'molecule_action':
    case 'in_app_browser':
    case 'out_app_browser':
    case 'log_out':
    default:
  }

  return (
    <a
      href={href}
      target={type === 'out_app_browser' ? '_blank' : ''}
      rel="noreferrer"
    >
      {children}
    </a>
  );
};

export default Action;
