import React from 'react';
import styled from 'styled-components';

import { solidColors } from '../../constants/styles/colors.constants';
import { hostedImage } from '../../constants/images/images.constants';
import Typography from '../atoms/Typography/Typography';
import { typography } from '../../constants/styles/typography.constants';
import { ImageImgix } from '@atoms';

const ItemStyled = styled.div`
  display: flex;
  padding: 25px 40px;
  border-bottom: 1px solid ${solidColors.mediumGray.color};
  cursor: pointer;
  align-items: center;
  &.active {
    background-color: ${solidColors.upWhite.color};
  }
`;

const AvatarStyled = styled(ImageImgix)`
  margin-right: 15px;
`;

function ListItem(props: any) {
  return (
    <ItemStyled {...props}>
      <AvatarStyled
        src={hostedImage.generic}
        alt="avatar"
        width={20} // FIXME: Is this correct?
      />
      <Typography variant={typography.body1}>
        {props.children}
      </Typography>
    </ItemStyled>
  );
}

// TODO ...create generic list, dynamically render listItemCard types...
const SegmentedNavigationContent = ({
  selectedSegment,
  onClick,
}: any) => {
  return (
    <div>
      {selectedSegment.data.map(
        (item: any, index: number) => (
          <ListItem
            key={index}
            className={`${
              selectedSegment.selectedItem === index
                ? 'active'
                : ''
            }`}
            onClick={() => onClick(index)}
          >
            <Typography variant={typography.heading03Large}>
              {item.data}
            </Typography>
          </ListItem>
        ),
      )}
    </div>
  );
};

export default SegmentedNavigationContent;
