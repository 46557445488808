import { ReactChild, ReactChildren } from 'react';
import styled from 'styled-components';

interface ISlide {
  children: ReactChild | ReactChildren;
}

const SlideContainer = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
  min-height: 75px;
`;

const StyledContainer = styled.div`
  display: flex;
  min-width: 100%;
`;

const CarouselListSlide = ({ children }: ISlide) => (
  <StyledContainer data-name="CarouselListSlide">
    <SlideContainer data-name="SlideContainer">
      {children}
    </SlideContainer>
  </StyledContainer>
);

export default CarouselListSlide;
