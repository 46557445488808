import styled from 'styled-components';

export const StyledFlex = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  div:first-child:nth-last-child(2),
  div:first-child:nth-last-child(2) ~ div {
    width: 46%;

    ${(props) => props.theme.breakpoints.down('sm')} {
      width: 100%;
      margin-bottom: 40px;
    }
  }

  div:first-child:nth-last-child(3),
  div:first-child:nth-last-child(3) ~ div {
    width: 30%;

    ${(props) => props.theme.breakpoints.down('sm')} {
      width: 100%;
      margin-bottom: 40px;
    }
  }
`;
