import { FlightSearchObjType } from '@components/molecules/SubPageFlightSearchV2/FlightSearchContext/FlightSearchContext.types';
import { getAirportHoursCopy } from '@services/airportSubpageSearchService/airportSubpageSearchService';

export const getTimeSubpage = async ({
  leg,
  setArrivalAirportHoursCopy,
  setDepartureAirportHoursCopy,
}: {
  leg: FlightSearchObjType;
  setArrivalAirportHoursCopy: (arg0: any) => void;
  setDepartureAirportHoursCopy: (arg0: any) => void;
}) => {
  const departCode = leg.departureAirport?.code;
  const arrivalCode = leg.arrivalAirport?.code;
  const dpTime = leg.departTime.toISOString().slice(0, 16);
  const params = `&departAirportCode=${departCode}&arriveAirportCode=${arrivalCode}&departDateTime=${dpTime}`;
  const hours = await getAirportHoursCopy(params);

  if (hours && hours.message === 'SUCCESS') {
    const timepicker =
      hours.data.elements[0].data.element.data;

    setArrivalAirportHoursCopy(
      timepicker.arrivalAirportHoursCopy,
    );
    setDepartureAirportHoursCopy(
      timepicker.departureAirportHoursCopy,
    );
  }
};
