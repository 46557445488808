import styled from 'styled-components';

export const IframeContainer = styled.div`
  padding-top: 7.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  height: auto;
  overflow: hidden;
  width: 100vw;
  ::-webkit-scrollbar {
    background: red;
  }
`;

export const FrameWrapper = styled.iframe`
  margin: 0;
  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
`;
