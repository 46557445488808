import {
  ButtonBase,
  TabScrollButtonProps,
} from '@components/library';
import React from 'react';
import { StyledTabArrow } from './TabArrow.styles';
import { ButtonRef } from './TabArrow.interfaces';

function TabArrow(
  { direction, disabled, ...rest }: TabScrollButtonProps,
  ref: ButtonRef,
) {
  return (
    <ButtonBase
      component="div"
      ref={ref}
      style={{ opacity: disabled ? 0 : 1 }}
      {...rest}
    >
      <StyledTabArrow
        direction={direction}
        viewBox="0 0 20 20"
        width="20"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
      >
        <path
          d="m13.382904 5.68223005.0102499.01034386.6865963.71337487c.1902628.19768342.1856826.51176035-.0102636.70381173l-5.52556465 5.41454049 4.12499995.0008961c.2761424 0 .5.2238576.5.5v1c0 .2761424-.2238576.5-.5.5h-6.99999995c-.27614238 0-.5-.2238576-.5-.5v-6.99999998c0-.27614237.22385762-.5.5-.5h1c.27614237 0 .5.22385763.5.5v4.28110388l5.50691065-5.61700023c.1932998-.19720491.5098665-.20037058.7070714-.00707072z"
          fillRule="evenodd"
          transform="matrix(-.70710678 -.70710678 .70710678 -.70710678 9.455182 23.980963)"
        />
      </StyledTabArrow>
    </ButtonBase>
  );
}

export default React.forwardRef<
  ButtonRef,
  TabScrollButtonProps
>(TabArrow);
