import {
  BackgroundImage,
  PageBackgroundStyled,
  StyledExtendedHeaderBackground,
} from './PageBackground.styles';
import {
  IMeta,
  IPageBackgroundImage,
} from '@components/types';
import {
  gradientColors,
  surfaceColors,
} from '@constants/styles/colors.constants';
import {
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import { IImgixFit } from '@services/imgix/imgix.interfaces';
import PageBackgroundClouds from '@atoms/PageBackgroundClouds/PageBackgroundClouds';
import getTimeOfDayVar from '@components/utils/getTimeOfDayVar';
import gsap from 'gsap';
import { useViewport } from '@hooks';
import { BackgroundContext } from '@services/contextService/context';
import { MainTypes } from '@services/contextService/reducers/reducerUtils';
import { useLocation } from 'react-router-dom';

const timeOfDay = getTimeOfDayVar();

export enum BackgroundType {
  SIGNIN,
  SIDE_CLOUD,
}

interface IPageBackground {
  children: JSX.Element;
  metadata?: IMeta | undefined;
  backgroundType?: BackgroundType;
  className?: string;
  animate?: boolean;
  hasExtendedHeader?: boolean;
}

export default function PageBackground({
  children,
  metadata,
  backgroundType,
  className,
  animate,
  hasExtendedHeader = false,
}: IPageBackground) {
  const { backgroundDispatch } = useContext(
    BackgroundContext,
  );
  const { width } = useViewport();
  const cloudsRef = useRef(null);
  const {
    backgroundImages,
    backgroundColor,
    webMobileBackgroundImages,
  } = metadata || {};
  const [imageObject, setImageObject] = useState<
    IPageBackgroundImage | undefined
  >();

  const getBackgroundColor = () => {
    if (
      backgroundType !== undefined &&
      backgroundType >= 0
    ) {
      return gradientColors.g4.background;
    } else if (backgroundColor) {
      const typedColor =
        surfaceColors[
          backgroundColor as keyof typeof surfaceColors
        ].color;
      return typedColor;
    } else {
      return surfaceColors.backgroundUpWhite.color;
    }
  };

  const renderClouds = () => {
    if (
      backgroundType !== undefined &&
      backgroundType >= 0
    ) {
      return (
        <PageBackgroundClouds
          cloudType={backgroundType}
          cloudsRef={cloudsRef}
        />
      );
    } else {
      return null;
    }
  };

  useEffect(() => {
    // we can expand this to select animation type or something like that
    if (animate) {
      gsap.fromTo(
        cloudsRef.current,
        20,
        { x: 0 },
        {
          x: -100,
          yoyo: true,
          repeat: -1,
          ease: 'slow',
        },
      );
    }
  }, []);

  useEffect(() => {
    const getBackgroundImage = () => {
      let images = backgroundImages;

      if (width <= 600) {
        if (webMobileBackgroundImages) {
          images = webMobileBackgroundImages;
        }
      }
      const hasClouds =
        backgroundType !== undefined && backgroundType >= 0;
      if (!images) {
        backgroundDispatch({
          type: MainTypes.Update,
          payload: { hasImage: hasClouds },
        });
        return;
      }
      backgroundDispatch({
        type: MainTypes.Update,
        payload: { hasImage: true },
      });
      switch (timeOfDay) {
        case 'morning':
          images.morning && setImageObject(images.morning);
          break;
        case 'afternoon':
          images.afternoon &&
            setImageObject(images.afternoon);
          break;
        case 'evening':
          images.evening && setImageObject(images.evening);
          break;
        default:
          setImageObject(undefined);
          return;
      }
    };
    getBackgroundImage();
  }, [metadata]);

  return (
    <>
      <PageBackgroundStyled
        className={`${className} background`}
        backgroundColor={getBackgroundColor()}
        clouds={Number(backgroundType) >= 0}
      >
        {renderClouds()}
        {imageObject && (
          <BackgroundImage
            isSrcSet
            className="backgroundImage"
            alt="background image"
            src={imageObject.url}
            width={width}
            height={550}
            fit={'crop'}
            position="absolute"
          />
        )}
        {
          hasExtendedHeader ?
            <StyledExtendedHeaderBackground id='StyledExtendedHeaderBackground'/>
            : null
          }
            {children}
      </PageBackgroundStyled>
    </>
  );
}
