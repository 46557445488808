import { AxiosError, AxiosInstance } from 'axios';
import { AppDispatch, RootState } from '../../../app/store';
import { EnhancedStore } from '@reduxjs/toolkit';
import { setIsAppLoading } from '@app/AppLoadingSlice';
import {
  IErrorPayload,
  setErrorOpen,
  setErrorPayload,
} from '@components/molecules/Error/ErrorSlice';

let storeGetState: RootState;
let storeDispatch: AppDispatch;

export const injectStore = (_store: EnhancedStore) => {
  storeGetState = _store.getState();
  storeDispatch = _store.dispatch;
};

const errorHandler = (code: number, error: AxiosError) => {
  // Switch case to direct response code to correct helper function
  let errorData;
  switch (code) {
    case 400:
      if (error?.response?.config.url === '/member-join/public/register-joiner/fly-member/delta') {
        return error.response;
      }
      // This is a global error handler. The Error component can be found in PageBuilderContainer.tsx
      errorData = error?.response?.data?.data
        ?.data as IErrorPayload;
      storeDispatch(setErrorPayload(errorData));
      storeDispatch(setErrorOpen(true));
      return error;
    default:
      if (error.message && error.message === 'cancel') {
        // do nothing when we have a cancelled request
        break;
      }
      // This is a global error handler. The Error component can be found in PageBuilderContainer.tsx
      errorData = error?.response?.data?.data
        ?.data as IErrorPayload;
      storeDispatch(setErrorPayload(errorData));
      storeDispatch(setErrorOpen(true));
      return;
  }
};

export const coreBackendResponseHandler = (
  instance: AxiosInstance,
) => {
  instance.interceptors.response.use(
    function (response) {
      storeDispatch(setIsAppLoading(false));
      return response;
    },
    async function (error) {
      storeDispatch(setIsAppLoading(false));
      return errorHandler(error.response?.status, error);
    },
  );
};
