import { useEffect, useState } from 'react';
import { ICardSelectedPayments } from './CardSelectedPayments.interfaces';
import {
  StyledCard,
  StyledTextGroupIconText,
  StyledTitleContainer,
  StyledAddEditButton,
  StyledInfoContainer,
  StyledChevron,
} from './CardSelectedPayments.styles';
import { typography } from '@constants/styles/typography.constants';
import TextGroupIconText from '@molecules/TextGroupIconText/TextGroupIconText';
import TitleIconContainer from '@molecules/TitleIconContainer/TitleIconContainer';
import { IPayment } from '@components/types';
import TextGroupSingleTextGroup from '@molecules/TextGroupSingleTextGroup/TextGroupSingleTextGroup';
import { Typography } from '@wheelsup/wu-react-components';

const CardSelectedPayments = ({
  title,
  selectedPayments,
  checkOrWireTransferButton,
  subtext,
}: ICardSelectedPayments) => {
  useEffect(() => {
    const preSelectedPayments = selectedPayments?.filter(
      (credit) => credit.isPreselected,
    );
    if (
      preSelectedPayments &&
      preSelectedPayments[0] &&
      (preSelectedPayments[0].paymentType === 'check' ||
        preSelectedPayments[0].paymentType ===
          'wireTransfer')
    ) {
      setBackupCreditCard(
        preSelectedPayments[0].backupCreditCard,
      );
    } else {
      setBackupCreditCard(undefined);
    }
  }, [selectedPayments]);

  const [
    backupCreditCard,
    setBackupCreditCard,
  ] = useState<IPayment>();

  return (
    <>
      {selectedPayments && (
        <StyledCard id="CardSelectedPayments">
          {title && (
            <StyledTitleContainer>
              <Typography
                variant='heading03'
                color='primary'
              >
                {title}
              </Typography>
            </StyledTitleContainer>
          )}
          <StyledInfoContainer>
            {selectedPayments?.map(
              (payment: IPayment, index) => {
                const image = (
                  <TitleIconContainer
                    width="64px"
                    height="64px"
                    radius="50%"
                    image={payment.image?.url}
                  />
                );

                return (
                  <StyledTextGroupIconText
                    key={index}
                    $showTopBorder={
                      title !== '' && title !== undefined
                    }
                  >
                    <TextGroupIconText
                      headline={payment.displayValue1}
                      headlineVariant={typography.body1Bold}
                      headerSize={
                        typography.body1Bold.fontSize
                      }
                      copy={payment.displayValue2}
                      copySize='12px'
                      extraCopy={payment.displayValue3}
                      icon={image}
                      subCopy={payment.fundExpirationDate ? payment.fundExpirationDate : ''}
                    />
                  </StyledTextGroupIconText>
                );
              },
            )}
          </StyledInfoContainer>

          {backupCreditCard && (
            <>
              <StyledTitleContainer>
                <Typography
                  variant='heading03'
                  color='disabled'
                >
                  {'Backup Credit Card'}
                </Typography>
              </StyledTitleContainer>

              <StyledInfoContainer>
                <StyledTextGroupIconText>
                  <TextGroupIconText
                    headline={
                      backupCreditCard.displayValue1
                    }
                    copy={backupCreditCard.displayValue2}
                    icon={
                      <TitleIconContainer
                        width="64px"
                        height="64px"
                        radius="50%"
                        image={backupCreditCard.image?.url}
                      />
                    }
                  />
                </StyledTextGroupIconText>
              </StyledInfoContainer>
            </>
          )}

          {subtext &&
            subtext.map((subtextItem, index) => (
              <TextGroupSingleTextGroup
                key={index}
                title={subtextItem.title}
                styleType={subtextItem.styleType}
                alignmentState={subtextItem.alignmentState}
              />
            ))}

          {checkOrWireTransferButton && (
            <StyledAddEditButton
              $styleType={
                checkOrWireTransferButton.styleType ??
                'icon_leading'
              }
              title={checkOrWireTransferButton?.title}
              styleType={
                checkOrWireTransferButton.styleType ??
                'icon_leading'
              }
              action={checkOrWireTransferButton.action}
              nativeIcon={<StyledChevron />}
            />
          )}
        </StyledCard>
      )}
    </>
  );
};

export default CardSelectedPayments;
