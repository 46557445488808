import {
  SideCloud,
  SignInClouds,
} from '@atoms/PageBackground/PageBackground.styles';
import { hostedImage } from '@constants/images/images.constants';

export enum BackgroundType {
  SIGNIN,
  SIDE_CLOUD,
}

interface IPageBackgroundClouds {
  cloudType: BackgroundType | undefined;
  cloudsRef: React.MutableRefObject<null>;
}

const PageBackgroundClouds = ({
  cloudType,
  cloudsRef,
}: IPageBackgroundClouds) => {
  if (cloudType === undefined) {
    return null;
  } else {
    switch (cloudType) {
      case BackgroundType.SIDE_CLOUD:
        return (
          <SideCloud
            src={hostedImage.wheelsUpSideCloud}
            ref={cloudsRef}
          />
        );
      case BackgroundType.SIGNIN:
        return (
          <SignInClouds
            src={hostedImage.wheelsUpCloudsSignIn}
            ref={cloudsRef}
          />
        );
      default:
        return null;
    }
  }
};

export default PageBackgroundClouds;
