import mixpanel from 'mixpanel-browser';
import jwt from 'jsonwebtoken';
import { IUserPayload } from './mixpanel.interfaces';
import { getFromLocalStorage } from '@components/utils/storage';
import { IKeyValue, ObjectAccess } from '@components/types';
import { IAnalytics } from '@services/dynamicRenderingService/dynamicRendering.interfaces';
import { fetchProcessEnvVariable } from '@services/envService/envService';

export const mixpanelInit = () => {
  // Mixpanel token is set in .env file or by k8s ConfigMap
  console.log(
    'in mixpanelInit',
    fetchProcessEnvVariable('MIXPANEL_TOKEN'),
  );
  mixpanel.init(fetchProcessEnvVariable('MIXPANEL_TOKEN'), {
    debug: true,
  });
  setSuperProperties();
};

export const getAccessToken = () => {
  try {
    const token = getFromLocalStorage('access_token') || '';
    const tokenDecoded = jwt.decode(token, {
      complete: true,
    });
    return tokenDecoded?.payload;
  } catch (err) {
    return false;
  }
};

const setSuperProperties = () => {
  if (getAccessToken()) {
    const payload = getAccessToken() as IUserPayload;
    mixpanel.register({
      'Last Name': payload.lastName,
      Email: payload.user_name,
      'Member Type': payload.memberTypeCode,
      Status: payload.memberStatus,
      'User ID': payload.userId,
      'Member User Type': payload.memberUserTypeCode,
      'Client User Type': payload.clientUserType,
      'Member ID': payload.memberId,
    });
  }
};

export const trackAnalytics = (data?: IAnalytics) => {
  const buildProperties: ObjectAccess = {};

  data?.properties.map((obj: IKeyValue) => {
    const key = obj.key;
    const value = obj.value;
    buildProperties[key] = value;
  });

  if (data)
    mixpanel.track(data.trackingTitle, buildProperties);
};

export const callIdentify = (isSignup?: boolean) => {
  const accessData = getAccessToken() as IUserPayload;
  if (accessData) {
    if (isSignup) {
      mixpanel.alias(String(accessData.userId));
    }

    mixpanel.identify(String(accessData.userId));

    mixpanel.people.set({
      $last_name: accessData.lastName,
      $email: accessData.user_name,
      Status: accessData.memberStatus,
      'User ID': accessData.userId,
      'Member User Type': accessData.memberUserTypeCode,
      'Client User Type': accessData.clientUserType,
      'Member ID': accessData.memberId,
    });
  }
};

export const customPageAnalytics = (
  title: string,
  properties: [] = [],
) => {
  const analytics = {
    trackingTitle: title,
    properties,
  } as IAnalytics;

  trackAnalytics(analytics);
};
