import NumberFormat from 'react-number-format';

export interface ICreditCardExpirationMask {
  onChange: (event: {
    target: { name: string; value: string };
  }) => void;
  name: string;
  onBlur?:
    | React.FocusEventHandler<
        HTMLInputElement | HTMLTextAreaElement
      >
    | undefined;
}

export default function CreditCardExpirationMask({
  name,
  onChange,
  onBlur,
  ...rest
}: ICreditCardExpirationMask) {
  return (
    <NumberFormat
      {...rest}
      onValueChange={(values) => {
        onChange({
          target: {
            name,
            value: values.value,
          },
        });
      }}
      onBlur={onBlur}
      format="##/####"
    />
  );
}
