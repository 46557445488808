import { useEffect, useState, useRef } from 'react';

import CarouselButtonContainer from '@molecules/CarouselButtonContainer/CarouselButtonContainer';
import {
  deviceSizes,
  ICarouselArrowProps,
} from './CarouselArrow.interfaces';
import { theme } from '@constants/styles/theme.constants';
import {
  StyledCarouselCarouselArrow,
  CarouselArrowWrapper,
  CarouselSlide,
  SlideGroupWrapper,
  SlideWrapper,
  StyledCarouselSlideDiv,
} from './CarouselCarouselArrowWEBONLY.styles';
import { useViewport } from '@hooks';
import { gsap } from 'gsap';

const CarouselCarouselArrowWEBONLY = ({
  numberSlidesTransform = 1,
  children,
  styleWidth,
  isFullWidth = false,
  customOffSet,
}: ICarouselArrowProps) => {
  const [currentSlides, setCurrentSlides] = useState([0]);
  const [
    currentTransformAmount,
    setCurrentTransformAmount,
  ] = useState(0 - numberSlidesTransform * 0);
  const [
    transformWidthAmount,
    setTransformWidthAmount,
  ] = useState(0);
  const [wrapperWidth, setWrapperWidth] = useState(0);

  const [isCardFull, setIsCardFull] = useState(false);

  const cardElement = useRef<HTMLDivElement>(null);
  const wrapperElementRef = useRef<HTMLDivElement>(null);

  const goBack = () => {
    const padding = isCardFull
      ? customOffSet
        ? customOffSet
          ? -customOffSet
          : 32
        : 32
      : 0;
    setCurrentSlides(
      currentSlides.map(
        (slideIndex) => slideIndex - numberSlidesTransform,
      ),
    );
    setCurrentTransformAmount(
      (prevValue) =>
        prevValue -
        numberSlidesTransform * transformWidthAmount -
        padding,
    );
  };
  const [shouldShowArrow, setShouldShowArrow] = useState(
    false,
  );
  const breakpoints = theme.breakpoints.values;
  const { width } = useViewport();

  const goForward = () => {
    const padding = isCardFull
      ? customOffSet
        ? -customOffSet
        : 32
      : 0;
    setCurrentSlides(
      currentSlides.map(
        (slideIndex) => slideIndex + numberSlidesTransform,
      ),
    );
    setCurrentTransformAmount(
      (prevValue) =>
        prevValue +
        numberSlidesTransform * transformWidthAmount +
        padding,
    );
  };

  useEffect(() => {
    const firstCard = (cardElement.current?.firstChild) as HTMLDivElement;
    const cardLength = firstCard?.offsetWidth ?? 0;

    const wrapperLength =
      wrapperElementRef.current?.offsetWidth ?? 0;

    const isCardLg = cardLength > 400;
    setIsCardFull(cardLength > 400);

    const deviceWindow =
      width <= breakpoints.sm
        ? 'sm'
        : window.innerWidth <= breakpoints.md
        ? 'md'
        : 'lg';

    const setDeviceSize = (size: deviceSizes) => {
      switch (size) {
        case 'sm': {
          setCurrentSlides([0]);
          return null;
        }
        case 'md': {
          if (isCardLg) {
            setCurrentSlides([0]);
            return null;
          }
          setCurrentSlides([0, 1]);
          return null;
        }
        case 'lg': {
          if (isCardLg) {
            setCurrentSlides([0]);
            return;
          }
          setTransformWidthAmount(0);
          setCurrentSlides([0, 1, 2]);
          return;
        }
        default:
          return '';
      }
    };
    setDeviceSize(deviceWindow);
    setWrapperWidth(wrapperLength);
    setCurrentTransformAmount(
      0 - numberSlidesTransform * 0,
    );
    if (isCardFull) setTransformWidthAmount(wrapperWidth);
  }, [width]);

  useEffect(() => {
    setShouldShowArrow(
      Array.isArray(children) &&
        children.length > currentSlides.length,
    );
  }, [currentSlides]);

  useEffect(() => {
    gsap
      .timeline({
        defaults: { duration: 2 },
      })
      .to('.hidden', { opacity: 0 });

    gsap
      .timeline({
        defaults: { duration: 2 },
      })
      .to('.visible', { opacity: 1 });
  }, [currentSlides]);

  const start = currentSlides[0];
  const end = currentSlides[currentSlides.length - 1] + 1;

  const activeSlides = Array.isArray(children) ? (
    children.map((slide, index) => {
      const key = `${index}`;
      return (
        <SlideWrapper
          data-name="SlideWrapper"
          data-width={transformWidthAmount}
          width={transformWidthAmount}
          isFullWidth={isFullWidth}
          padding={isCardFull ? 32 : 0}
          active={index > start - 1 && index < end}
          className={
            index < end && index >= start
              ? 'visible'
              : 'hidden'
          }
          key={key}
          ref={(element) => {
            if (!element || transformWidthAmount !== 0)
              return;
            const width = element.getBoundingClientRect()
              .width;
            if (width > 401) {
              setTransformWidthAmount(wrapperWidth);
            } else {
              setTransformWidthAmount(width);
            }
          }}
        >
          <CarouselSlide>
            <StyledCarouselSlideDiv
              data-name="CarouselSlideDiv"
              ref={cardElement}
              widthDiv={wrapperWidth}
              className={`s${currentSlides.length}`}
            >
              {slide}
            </StyledCarouselSlideDiv>
          </CarouselSlide>
        </SlideWrapper>
      );
    })
  ) : (
    <CarouselSlide>{children}</CarouselSlide>
  );

  return (
    <StyledCarouselCarouselArrow
      data-name="StyledCarouselCarouselArrow"
      styleWidth={styleWidth}
    >
      {shouldShowArrow && Array.isArray(children) && (
        <CarouselButtonContainer
          imageCarousel={true}
          disabledBack={currentSlides[0] === 0}
          disabledForward={
            currentSlides[currentSlides.length - 1] ===
            children.length - 1
          }
          backFunction={goBack}
          forwardFunction={goForward}
        />
      )}
      <CarouselArrowWrapper
        ref={wrapperElementRef}
        data-name="CarouselArrowWrapper"
      >
        <SlideGroupWrapper
          singleCard={
            !Array.isArray(children) && !isCardFull
          }
          data-name="SlideGroupWrapper"
          transformAmount={currentTransformAmount}
        >
          {activeSlides}
        </SlideGroupWrapper>
      </CarouselArrowWrapper>
    </StyledCarouselCarouselArrow>
  );
};

export default CarouselCarouselArrowWEBONLY;
