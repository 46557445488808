import { createSlice } from '@reduxjs/toolkit';

interface IState {
  isAppLoading?: boolean;
}

export const initialState: IState = {
  isAppLoading: false,
};

export const appLoadingSlice = createSlice({
  name: 'app',
  initialState: initialState,
  reducers: {
    setIsAppLoading: (state, action) => {
      state.isAppLoading = action.payload;
    },
  },
});

export const { setIsAppLoading } = appLoadingSlice.actions;

export const AppLoadingReducer = appLoadingSlice.reducer;
