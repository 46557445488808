import styled from 'styled-components';
import { theme } from '@constants/styles/theme.constants';
import { solidColors } from '@constants/styles/colors.constants';
import { Button } from '@atoms';

export const WIDTH_SM = 37.5; // 600px / 16px = 37.5rem
export const WIDTH_MD = 60; // 960px / 16px = 60rem
export const WIDTH_LG = 67.5; // 1080px / 16px = 67.5rem

export const CardInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 24px 32px 32px 32px;
`;

export const FboContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  margin-top: 16px;

  @media (max-width: ${WIDTH_SM}rem) {
    flex-direction: column;
    justify-content: flex-start;
    & > *:first-child {
      padding-bottom: 12px;
      border-bottom: 1px solid
        ${solidColors.mediumGray.color};
    }
    & > *:last-child {
      padding-top: 12px;
    }
  }
`;

export const Fbo = styled.div`
  display: flex;
  flex-direction: column;
  width: 48%;
  align-items: flex-start;

  @media (max-width: ${WIDTH_SM}rem) {
    width: 100%;
  }
`;

export const CardOuterContainer = styled.div`
  min-width: 1080px;

  @media (max-width: 1130px) {
    min-width: 900px;
  }
  @media (max-width: ${theme.breakpoints.values.md}px) {
    min-width: 600px;
    max-width: 100%;
  }
  @media (max-width: ${theme.breakpoints.values.sm}px) {
    min-width: 330px;
    max-width: 100%;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;

  @media (max-width: ${WIDTH_SM}rem) {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const Directions = styled(Button)`
  && .MuiButton-label {
    justify-content: flex-start;
  }
`;
