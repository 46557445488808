import { ITextGroupSqaurePill } from './TextGroupSqaurePill.interfaces';
import { StyledContainer } from './TextGroupSqaurePill.styles';

export default function TextGroupSqaurePill({
  id,
  backgroundColor,
  children,
}: ITextGroupSqaurePill) {
  return (
    <StyledContainer
      data-testid="TextGroupSqaurePill"
      $backgroundColor={backgroundColor.color}
    >
      {children}
    </StyledContainer>
  );
}

// iOS calls this FlightTypeTagLabelView, but I didn't like that name
