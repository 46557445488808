import styled from 'styled-components';
import { Typography, Button } from '@atoms';
import { solidColors } from '@constants/styles/colors.constants';
import TextGroupBodyCopyLink from '@molecules/TextGroupBodyCopyLink/TextGroupBodyCopyLink';
import { Elevation } from '@wheelsup/wu-react-components';

export const StyledCard = styled(Elevation).attrs({
  padding: 0,
})`
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05);
  margin: 0 auto;
  max-width: 41.875rem;
  padding: 3.125rem;
  border-radius: 8px;

  ${(props) => props.theme.breakpoints.down('sm')} {
    width: 100%;
    max-width: 100%;
  }
`;

export const TypographyHeadline = styled(Typography)`
  margin-bottom: 1.4rem;
`;

export const TypographySubtitle = styled(Typography)`
  line-height: 2rem;
`;

export const StyledPrice = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;

  .price-item {
    margin: 0 4px;
  }
`;

export const TypographyPrice = styled(Typography)`
  &:first-child:nth-last-child(2),
  &:first-child:nth-last-child(2) ~ & {
    &:first-child {
      text-decoration: line-through;
    }
  }
`;

export const ListContainer = styled.div`
  flex-grow: 1;
  width: 100%;
`;

export const ItemUl = styled.ul`
  margin: 30px 0;
`;

export const ItemLi = styled.li`
  margin-left: -40px;
  list-style-type: none;
  padding-bottom: 14px;
`;

export const TypographyItemStyled = styled(Typography)`
  line-height: 1.8rem;

  &:before {
    color: ${solidColors.c5.color};
    content: '+ ';
    font-size: 1.2em;
    font-weight: bold;
  }
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
`;

export const StyledButton = styled(Button)`
  margin: 8px 0;
`;

export const TypographyPriceNote = styled(Typography)`
  text-align: center;
`;

export const TextGroupBodyCopyLinkViewLink = styled(
  TextGroupBodyCopyLink,
)`
  text-align: center;
  margin-bottom: 24px;
`;

export const TypographyFooterNote = styled(Typography)`
  font-style: italic;
`;
