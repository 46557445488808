import { BaseButton } from '@atoms';
import { solidColors } from '@constants/styles/colors.constants';
import styled from 'styled-components';

export const HeadlineStepperWrapper = styled.div<{
  size?: string;
  $width?: string;
}>`
  width: ${({ $width }) => $width};
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${(props) =>
    props.size === 'small' &&
    `
        width: 72px;
    `};
`;

export const StepperWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 104px;
  position: relative;

  .count {
    user-select: none;
  }
`;

export const StyledButton = styled(BaseButton)<{
  $buttonSize?: string;
}>`
  width: 30px;
  height: 30px;
  min-width: 30px;
  max-width: 30px;
  padding: 0;
  border: 1px solid ${solidColors.upBlue.color};
  border-radius: 4px;

  &.Mui-disabled {
    border: 1px solid ${solidColors.mediumGray.color};
    .MuiButton-label {
      color: ${solidColors.mediumGray.color};
    }
  }

  .MuiButton-label {
    color: ${solidColors.upBlue.color};
  }

  ${(props) =>
    props.$buttonSize === 'small' &&
    `
        width: 20px;
        height: 20px;
        min-width: 20px;
        max-width: 20px;

        .MuiSvgIcon-root {
            font-size: 1.125rem;
        }
    `}
`;

export const PopupHandler = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;
