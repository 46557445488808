import styled from 'styled-components';
import { Container } from '@components/library';
import { solidColors } from '@constants/styles/colors.constants';

export const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 10px 0px;
`;

export const FormRow = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;

  width: 100%;
  margin: 15px 0px;

  > div {
    width: 30%;
    margin: 0rem 1rem 2rem 0rem;

    > .MuiFormControl-root {
      width: 100%;
    }

    .MuiFormHelperText-contained {
      margin-left: 0px;
    }
  }
`;

export const PassengerHeader = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  > * {
    margin: 10px 0px;
  }
`;

export const StyledAirmedPassengerForm = styled.div`
  align-items: flex-start;
  margin: 20px 0px;
`;

export const StyledAddPaxButtonContainer = styled.div`
  padding-bottom: 80px;
  margin-bottom: 40px;

  border-bottom: 1px solid ${solidColors.mediumGray.color};
`;

export const StyledSubmitWTCButtonContainer = styled.div`
  margin: 30px 0px;
`;

export const StyledRemovePaxButtonContainer = styled.div`
  padding-top: 10px;

  border-top: 1px solid ${solidColors.mediumGray.color};
`;
