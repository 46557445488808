import { Container } from '@components/library';
import { theme } from '@constants/styles/theme.constants';
import styled from 'styled-components';

export const WrapperInner = styled.div`
  width: 99%;
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 24px;
    @media (max-width: ${theme.breakpoints.values.sm}px) {
      gap: 16px;
      padding: 16px;
  }
`;

export const WrapperInnerContainer = styled.div`
  width: 99%;
`;

export const CarouselListWrapperContainer = styled(
  Container,
)`
  display: flex;
  overflow: hidden;
  justify-content: space-around;
`;
