//theme structured from Material UI, can be used to override MUI defaults or use in styled-components

import {
  solidColors,
  surfaceColors,
} from './colors.constants';

export const theme = {
  //MUI Overrides, can expand this object as needed
  //ref: default MUI theme object: https://material-ui.com/customization/default-theme/#default-theme

  background: {
    paper: `${surfaceColors.backgroundUpWhite.color}`,
  },
  typography: {
    fontFamily: " Mikro,'Rubik', sans-serif",
    allVariants: {
      color: `${solidColors.midnight.color}`,
    },
  },
  palette: {
    primary: {
      main: `${solidColors.midnight.color}`,
      contrastText: `${solidColors.upWhite.color}`,
    },
    secondary: {
      main: `${solidColors.midnight.color}`,
      contrastText: `${solidColors.upWhite.color}`,
    },
    error: {
      main: `${solidColors.lava.color}`,
      contrastText: `${solidColors.upWhite.color}`,
    },
    warning: {
      main: `${solidColors.jam.color}`,
      contrastText: `${solidColors.upWhite.color}`,
    },
    info: {
      main: `${solidColors.upBlue.color}`,
      contrastText: `${solidColors.upWhite.color}`,
    },
    success: {
      main: `${solidColors.basil.color}`,
      contrastText: `${solidColors.upWhite.color}`,
    },
    white: {
      main: `${solidColors.upWhite.color}`,
      contrastText: `${solidColors.upWhite.color}`,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1136,
      xl: 1600,
    },
  },
};
