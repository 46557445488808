import styled from 'styled-components';
import { Typography } from '@atoms';
import { solidColors } from '@constants/styles/colors.constants';

export const CardOversizedWrapper = styled.div`
  display: flex;
  height: 31.25rem;

  ${(props) => props.theme.breakpoints.down('sm')} {
    flex-direction: column-reverse;
    height: auto;
  }
`;

export const CardContainer = styled.div`
  margin: 3rem 2.5rem 1rem 2.5rem;
  display: flex;
  flex-direction: column;
  height: calc(100% - 6rem);

  ${(props) => props.theme.breakpoints.down('sm')} {
    height: auto;
  }
`;

export const CardBody = styled(Typography)`
  margin-top: 1rem;
  flex-grow: 0.6;

  ${(props) => props.theme.breakpoints.down('sm')} {
    display: block;
    margin-bottom: 2rem;
  }
`;

export const FirstSideCard = styled.div`
  border-radius: 5px 0 0 5px;
  width: 26rem;
  max-width: 26rem;

  ${(props) => props.theme.breakpoints.down('sm')} {
    width: 100%;
    max-width: 100%;
    border-radius: 0 0 5px 5px;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
  height: fit-content;
  flex-direction: column;
`;

export const OversizedSideCard = styled.div`
  background-color: ${solidColors.mediumGray.color};
  border-radius: 0 5px 5px 0;
  width: calc(100% - 22.5rem);
  max-width: calc(100% - 22.5rem);
  position: relative;

  img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  ${(props) => props.theme.breakpoints.down('sm')} {
    width: 100%;
    max-width: 100%;
    height: 300px;
    border-radius: 5px 5px 0 0;
  }
`;

export const ItemUl = styled.ul`
  margin: 0;
`;

export const ItemLi = styled.li`
  margin-left: -40px;
  list-style-type: none;
`;

export const ItemContainer = styled.div`
  display: flex;
  gap: 10px;
`;

export const ListContainer = styled.div`
  flex-grow: 1;
  width: 100%;
`;

export const ButtonContainer = styled.div`
  width: 100%;
`;

export const TypographyTitleStyled = styled(Typography)<{
  size?: string;
}>`
  line-height: 1.2rem;
`;
