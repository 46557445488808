import { theme } from '@constants/styles/theme.constants';
import { useState, useEffect } from 'react';

interface IViewport {
  width: number;
  height: number;
  isDesktop: boolean;
  isTablet: boolean;
  isMobile: boolean;
}

export default function useViewport(): IViewport {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleWindowResize = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleWindowResize);
    return () =>
      window.removeEventListener(
        'resize',
        handleWindowResize,
      );
  }, []);

  const isMobile = width <= theme.breakpoints.values.sm;
  const isTablet = width <= theme.breakpoints.values.md;
  const isDesktop = width > theme.breakpoints.values.md;

  return { width, height, isMobile, isTablet, isDesktop };
}
