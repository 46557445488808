import {
  Dropdown,
  Chip,
  Toggle,
  Typography,
} from '@wheelsup/wu-react-components';
import { options, presets } from '../timeHelpers';

import { FlightSearchContext } from '@components/molecules/SubPageFlightSearchV2/FlightSearchContext/FlightSearchContext';
import { useFlightSearch } from '@components/molecules/SubPageFlightSearchV2/FlightSearchContext/FlightSearchHooks';
import { DateTime } from 'luxon';
import { useContext } from 'react';
import {
  ChooseTimeCopy,
  DisplayContainer,
  ChipContainer,
  QuickSelectContainer,
  QuickSelectHeader,
  StyledDivider,
  ToggleWrapper
} from './TimePickerDisplay.styles';

interface ITimePickerDisplayProps {
  legNumber: number;
  text: string;
  departureAirportHoursCopy: string;
  arrivalAirportHoursCopy: string;
  selectRef: React.RefObject<HTMLDivElement>;
}

const TimePickerDisplay = ({
  legNumber,
  text,
  departureAirportHoursCopy,
  arrivalAirportHoursCopy,
}: ITimePickerDisplayProps) => {

  const handleSelectTime = (
    dateTime: string,
  ) => {
    const newDepartTime = DateTime.fromISO(
      dateTime,
    ).toJSDate();
    updateLeg(
      {
        departTime: newDepartTime,
      },
      legNumber,
    );
  };

  const { legs, updateLeg, isRoundTrip, } = useFlightSearch(
    useContext(FlightSearchContext),
  );

  const leg = legs[legNumber];


  return (
    <div>
      {departureAirportHoursCopy ||
        arrivalAirportHoursCopy ||
        (isRoundTrip && (
          <ChipContainer>
            {isRoundTrip && (
              <Chip
                data-name="Chip"
                isSolid
                isUpperCase
              >{text}</Chip>
            )}
            <Typography variant={'caption'}>
              {departureAirportHoursCopy}
            </Typography>
            <Typography variant={'caption'}>
              {departureAirportHoursCopy}
            </Typography>
          </ChipContainer>
        ))}

      <DisplayContainer data-name="DisplayContainer">
        <div>
          <QuickSelectHeader style={{ paddingBottom: 16 }}>
            <Typography variant="body01">
              Quick Select{' '}
            </Typography>
          </QuickSelectHeader>

          <QuickSelectContainer>
            {presets.map(({ value, text }) => 
              <ToggleWrapper key={value.toString()}>
                <Toggle
                  label={text}
                  pressed={DateTime.fromJSDate(leg.departTime).toISO() === value}
                  onChange={() =>
                    handleSelectTime(value)
                  }
                />
              </ToggleWrapper>
            )}
          </QuickSelectContainer>
        </div>
        <div>
          <ChooseTimeCopy variant="body01">
            Specific time
          </ChooseTimeCopy>
          <Dropdown
            name="specificTime"
            label="Time"
            value={{
              label: DateTime.fromJSDate(
                leg.departTime,
              ).toFormat('hh:mm a'),
              value: DateTime.fromJSDate(
                leg.departTime,
              ).toISO(),
            }}
            onChange={(e) => handleSelectTime(e.value)}
            items={options}
          />
        </div>
      </DisplayContainer>
      {legNumber === 0 && isRoundTrip && (
        <StyledDivider data-name="Divider" />
      )}
    </div>
  );
};

export default TimePickerDisplay;
