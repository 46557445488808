import { IStyledRadioUnchecked } from './RadioUnchecked.interfaces';
import { Icons } from '@library';
import styled from 'styled-components';
import { solidColors } from '@constants/styles/colors.constants';

export const StyledRadioUnchecked = styled(
  Icons.RadioButtonUnchecked,
)<IStyledRadioUnchecked>`
  color: ${({ $color }) =>
    $color ?? solidColors.mediumGray.color};
`;
