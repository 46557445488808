import { useNavigate } from 'react-router-dom';
import { Typography } from '@atoms';
import {
  StyledSubPageManageIndividuals,
  StyledIndividualList,
  StyledIndividualItem,
  ProfileImageContainer,
  IndividualInfoContainer,
  AvatarStyled,
  StyledButton,
  StyledSubPageBody,
  StyledSubPageFooter,
  StyledSubPageHeader,
} from './SubPageManageIndividuals.styles';
import { typography } from '@constants/styles/typography.constants';
import { paths } from '@routing/routerPaths';
import { ISubPageManageIndividuals } from './SubPageManageIndividuals.interfaces';

export default function SubPageManageIndividuals({
  registeredIndividuals,
}: ISubPageManageIndividuals) {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate(paths.AIRMED_ADD_INDIVIDUALS);
  };

  const handleButtonDone = () => {
    navigate(paths.AIRMED_LANDING);
  };

  return (
    <StyledSubPageManageIndividuals padding={'11rem'}>
      <StyledSubPageHeader>
        <Typography variant={typography.heading01Large}>
          {'Manage Individuals'}
        </Typography>
        <br />
        <Typography variant={typography.body1a}>
          {
            'You can add up to 11 individuals to receive UP Global Response access and benefits. Individuals added cannot be removed or changed once added to your membership.'
          }
        </Typography>
      </StyledSubPageHeader>
      <StyledSubPageBody>
        {registeredIndividuals && (
          <StyledIndividualList>
            {registeredIndividuals.length > 0 ? (
              registeredIndividuals.map(
                (individual, idx) => (
                  <StyledIndividualItem key={idx}>
                    <ProfileImageContainer>
                      {individual.profileImage && (
                        <AvatarStyled
                          src={individual.profileImage.url}
                          filter={
                            individual.profileImage
                              .tintColor
                          }
                          alt="avatar"
                          width={48}
                          height={48}
                        />
                      )}
                    </ProfileImageContainer>
                    <IndividualInfoContainer>
                      <Typography
                        variant={typography.body1a}
                      >
                        {`${individual.firstName}${
                          individual.middleName
                            ? ` ${individual.middleName} `
                            : ' '
                        }${individual.lastName}`}
                      </Typography>
                      <Typography
                        variant={typography.caption1a}
                      >
                        {individual.dateOfBirthDisplay}
                      </Typography>
                      <br />
                      <Typography
                        variant={typography.caption1a}
                      >
                        {individual.addressLine1}
                      </Typography>
                      <Typography
                        variant={typography.caption1a}
                      >
                        {individual.addressLine2}
                      </Typography>
                      <Typography
                        variant={typography.caption1a}
                      >
                        {`${individual.city}, ${individual.state} ${individual.postalCode}`}
                      </Typography>
                    </IndividualInfoContainer>
                  </StyledIndividualItem>
                ),
              )
            ) : (
              <StyledIndividualItem>
                <Typography variant={typography.body1a}>
                  {'No individuals register'}
                </Typography>
              </StyledIndividualItem>
            )}
          </StyledIndividualList>
        )}
        <StyledButton
          action={{
            actionMethod: handleButtonClick,
          }}
          styleType={'card_primary'}
          title={'Add Individuals'}
          disabled={
            Number(registeredIndividuals?.length || []) >=
            12
          }
        />
      </StyledSubPageBody>

      <StyledSubPageFooter>
        <StyledButton
          action={{
            actionMethod: handleButtonDone,
          }}
          styleType={'primary'}
          title={'Done'}
        />
      </StyledSubPageFooter>
    </StyledSubPageManageIndividuals>
  );
}
