import { Button } from '@atoms/Button/Button';
import { Container } from '@components/library';
import { solidColors } from '@constants/styles/colors.constants';
import styled from 'styled-components';

export const StyledSubpageLuggageSelection = styled(
  Container,
)`
  min-height: 530px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const StyledCard = styled.div<{
  $isVertical?: boolean;
}>`
  min-height: 332px;
  height: 400px;
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  padding: 32px;
  margin-bottom: 44px;

  ${({ $isVertical }) => {
    if ($isVertical) {
      return `
                height: fit-content;
            `;
    }
  }}
`;

export const Border = styled.div`
  border-top: 1px solid ${solidColors.mediumGray.color};
  margin-top: 24px;
`;

export const LuggageItemsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const TopSection = styled.div<{
  $isVertical?: boolean;
}>`
  margin-bottom: ${({ $isVertical }) =>
    !$isVertical ? '24px' : '0'};
`;

export const StyledButton = styled(Button)`
  width: 200px;

  &:first-child {
    margin-right: 16px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: fit-content;
  margin-top: 44px;
`;

export const AlertContainer = styled.div<{
  $isAlertVisible: boolean;
  $canAnimate: boolean;
  $alertHeight: number;
}>`
  @keyframes slide-in {
    0% {
      transform: translateX(-100%);
      opacity: 0;
      height: 0;
      margin: 0;
    }
    50% {
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
      margin: 40px 0;
      height: 87px;
      max-height: 300px;
    }
  }

  @keyframes slide-out {
    0% {
      transform: translateX(0);
      opacity: 1;
      margin: 40px 0;
      height: 87px;
      max-height: 300px;
    }
    50% {
      opacity: 0;
    }
    100% {
      transform: translateX(-100%);
      height: 0;
      margin: 0;
      height: 0;
    }
  }

  width: 100%;
  transform: translateX(-100%);

  ${({ $isAlertVisible, $canAnimate }) => {
    if ($isAlertVisible) {
      return `
                height: 87px;
                max-height: 300px;
                margin: 40px 0;
                opacity: 1;
                animation: slide-in 1s ease-in-out;
                transform: translateX(0)
            `;
    } else {
      return `
                ${
                  $canAnimate
                    ? 'animation: slide-out 1s ease-in-out;'
                    : ''
                }
                opacity: 0;
                height: 0;
                margin: 0;
            `;
    }
  }};
`;
