import {
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

interface IFooter {
  footerData: unknown;
}

const initialState: IFooter = {
  footerData: undefined,
};

export const footerSlice = createSlice({
  name: 'footerSlice',
  initialState,
  reducers: {
    setFooterData: (
      state,
      action: PayloadAction<unknown>,
    ) => {
      state.footerData = action.payload;
    },
  },
});

export const { setFooterData } = footerSlice.actions;

export const FooterReducer = footerSlice.reducer;

export default FooterReducer;
