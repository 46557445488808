import styled from 'styled-components';
import { Typography } from '@atoms';
import { theme } from '@constants/styles/theme.constants';

export const TripContainer = styled.div`
  max-width: 370px;
  width: fit-content;
  height: fit-content;
  display: flex;
  margin: 0;

  @media (max-width: ${theme.breakpoints.values.md}px) {
    max-width: 450px;
  }

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    max-width: 100%;
    width: 100%;
  }
`;

export const StyledAirportName = styled(Typography)`
  max-width: 175px;
`;

export const StyledArriveAirportName = styled(Typography)`
  max-width: 175px;
  text-align: right;
`;

export const DepartContainer = styled.div`
  min-width: 140px;
  width: 175px;
  display: flex;
  flex-direction: column;

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    margin: 0;
    width: 100%;
    min-width: 0;
  }
`;

export const DepartAirportNameContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const ArriveAirportNameContainer = styled.div`
  text-align-last: right;
`;

export const ArriveContainer = styled.div`
  min-width: 140px;
  width: 175px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    margin: 0;
    width: 100%;
    min-width: 0;
  }
`;

export const IconContainer = styled.div`
  margin-top: 10px;
`;

export const MultiFlightContainer = styled.div`
  width: 450px;
  display: flex;
  display-direction: column;
  align-items: center;

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    margin: 0 0 0 10px;
    height: fit-content;
  }
`;

export const TopSection = styled.div`
  display: flex;
  height: fit-content;
  width: inherit;

  @media (max-width: ${theme.breakpoints.values.md}px) {
    width: 100%;
    justify-content: space-between;
  }

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    width: 100%;
  }
`;

export const TimeSection = styled.div<{
  $isDelayFlex?: boolean;
}>`
  display: flex;
  flex-direction: column;
  min-width: 100%;
  width: 100%;
  margin-top: 8px;
`;

export const TimeContainer = styled.div`
  display: flex;
  display: flex;
  width: 100%;
  justify-content: space-between;

  &.delayedTime {
    margin-bottom: 4px;
  }
`;

export const FlightContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;

  @media (max-width: ${theme.breakpoints.values.sm}px) {
    margin: 0;
    width: 100%;
  }
`;
