import styled from 'styled-components';
import { Button } from '@atoms';
import { theme } from '@constants/styles/theme.constants';

export const ButtonStyled = styled(Button)`
  margin: 10px 2px 0;
`;

export const CarouselContainer = styled.div<{
  $width: number;
}>`
  width: ${(props) => props.$width * 0.575 - 40}px;

  @media (max-width: 976px) {
    min-width: ${(props) => props.$width * 0.53 - 40}px;
  }

  ${(props) =>
    props.theme.breakpoints.down(
      theme.breakpoints.values.md,
    )} {
    width: ${(props) => props.$width - 40}px;
  }
`;

export const CarouselContainerSearchTripDetails = styled.div<{
  $width: number;
}>`
  width: ${(props) => props.$width - 40}px;
`;

export const TextGroupIconTextContainer = styled.div`
  cursor: pointer;
`;
