import ProviderWrapper from '@components/utils/ProviderWrapper';
import { useDispatch } from 'react-redux';
import {
  buttonPopupOpen,
  pagePopup,
  snackbarOpen,
} from '@features/Members/popupSlice';
import { useAppSelector } from '@app/hooks';
import { sendContactRequest } from '@services/Contact/ContactServices';
import { useEffect, useState } from 'react';
import PopUpCard from '../PopUpCard/PopUpCard';
import useSnackbar from '../SubPageFlightSearchV2/LegContainers/useSnackbar';

const MemberTabPopup = () => {
  const {
    isPagePopupOpen,
    isButtonPopupOpen,
    isSnackbarPopupOpen,
  } = useAppSelector((state) => state.popupOpen);

  const { isError } = useAppSelector(
    (state) => state.handleUncaughtError,
  );

  const handleEmailClick = async () => {
    const res = await sendContactRequest();
    snackbarHandler(
      res?.data?.data?.copy ??
        'Request confirmed. Our Wheels Up Team will reach out shortly',
    );
  };

  const dispatch = useDispatch();

  const snackbarHandler = useSnackbar(dispatch);

  return (
    <>
      <PopUpCard
        maxWidth={'sm'}
        open={isPagePopupOpen && isButtonPopupOpen}
        copy={
          'Our Wheels Up Team will reach out to you to discuss membership options.'
        }
        headline="Request More Membership Information"
        primary={{
          title: 'Contact Me',
          styleType: 'primary',
          action: {
            actionMethod: () => handleEmailClick(),
          },
        }}
        secondary={{
          title: 'No Thanks',
          styleType: 'secondary',
          action: {
            actionMethod: () =>
              dispatch(buttonPopupOpen(false)),
          },
        }}
      />
    </>
  );
};

const PopupWrapper = () => {
  return (
    <ProviderWrapper>
      <MemberTabPopup />
    </ProviderWrapper>
  );
};
export default PopupWrapper;
