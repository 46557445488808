export const TypographyStyleTranslation = (
  styleType: string,
) => {
  // this is used in legacy code, so we need to keep it
  // We just want it to return back the same thing to handle in the new way
  return styleType;
};

export const ColorStyleTranslation = (
  styleType: string,
) => {
  switch (styleType) {
    case 'c1':
    case 'c4':
    case 'c5':
    case 'c18':
      return 'midnight';
    case 'c2':
    case 'c3':
      return 'upBlue';
    case 'c6':
      return 'darkGray';
    case 'c7':
    case 'c8':
    case 'c9':
      return 'mediumGray';
    case 'c10':
    case 'c11':
      return 'lightGray';
    case 'c12':
      return 'basil';
    case 'c13':
      return 'lava';
    case 'c14':
      return 'jam';
    case 'c15':
      return 'upWhite';
    case 'c16':
      return 'blue5Percent';
    case 'c17':
      return 'blue40Percent';
    case 's1':
    case 's2':
      return 'backgroundUpWhite';
    case 's3':
      return 'backgroundUpBlue';
    case 's4':
      return 'backgroundLava';
    case 's5':
      return 'backgroundMidnight';
    case 's6':
      return 'backgroundLightGray';
    case 'Midnight':
      return 'midnight';
    case 'Up Blue':
      return 'upBlue';
    case 'Dark Gray':
      return 'darkGray';
    case 'Medium Gray':
      return 'mediumGray';
    case 'Light Gray':
      return 'lightGray';
    case 'Basil':
      return 'basil';
    case 'Lava':
      return 'lava';
    case 'Jam':
      return 'jam';
    case 'Up White':
      return 'upWhite';
    case 'Blue 5%':
      return 'blue5Percent';
    case 'Blue 40%':
      return 'blue40Percent';
    case 'Background - Up White':
      return 'backgroundUpWhite';
    case 'Background - Up Blue':
      return 'backgroundUpBlue';
    case 'Background - Lava':
      return 'backgroundLava';
    case 'Background - Midnight':
      return 'backgroundMidnight';
    case 'Background - Light Gray':
      return 'backgroundLightGray';
    default:
      return styleType;
  }
};
