import { useState, useEffect } from 'react';
import { Icons } from '@library';
import { typography } from '@constants/styles/typography.constants';
import {
  HeadlineStepperWrapper,
  StepperWrapper,
  StyledButton,
  PopupHandler,
} from './NumberStepper.styles';
import { IStepperHeadlineNumber } from './NumberStepper.interfaces';
import PopUpCard from '@components/molecules/PopUpCard/PopUpCard';
import { trackAnalytics } from '@services/mixpanelService/mixpanel.service';
import { IAnalytics } from '@services/dynamicRenderingService/dynamicRendering.interfaces';
import { Typography } from '@wheelsup/wu-react-components';

const NumberStepper = ({
  headline,
  numberPicker: {
    minValue,
    maxValue,
    defaultValue = 1,
    incrementButton,
    decrementButton,
    hideControls = false,
  },
  getDataCount = () => 0,
  hasMaxCapacity = false,
  buttonSize,
  isWithinChangeWindow = true,
  width = '340px',
}: IStepperHeadlineNumber) => {
  const [count, setCount] = useState<number>(defaultValue);

  useEffect(() => {
    setCount(defaultValue);
  }, [defaultValue]);

  const tracking = (action: string) => {
    const button =
      action === '+' ? incrementButton : decrementButton;
    const analytics = button?.action?.actionAnalytics;
    if (analytics) {
      trackAnalytics(analytics as IAnalytics);
    }
  };

  const handleClick = (type: string) => {
    tracking(type);
    const countOperation =
      type === '+' ? count + 1 : count - 1;
    setCount(countOperation);
    getDataCount(countOperation);
  };

  const [popUpOpen, setPopUpOpen] = useState(false);
  const handleClosePopUp = () => setPopUpOpen(false);

  return (
    <HeadlineStepperWrapper
      size={buttonSize}
      $width={width}
    >
      <Typography variant='body01'>
        {headline}
      </Typography>
      <StepperWrapper className="stepper-wrapper">
        {!isWithinChangeWindow && (
          <>
            <PopupHandler
              onClick={() => setPopUpOpen(true)}
            />
            <PopUpCard
              open={popUpOpen}
              closeAction={handleClosePopUp}
              primary={{
                title: 'OK',
                action: {
                  actionMethod: handleClosePopUp,
                },
              }}
              secondary={{
                title: 'Call',
                action: {
                  type: 'phone',
                  data: '1-866-498-7359',
                },
              }}
              maxWidth="xs"
              headline="Cannot Update Luggage"
              copy="Update your luggage up until 24 hours before your first flight. Connect with Member Services via 866-498-7359 for updates."
            />
          </>
        )}
        {!hideControls && (
          <StyledButton
            onClick={() => handleClick('-')}
            disabled={
              count === minValue || !isWithinChangeWindow
            }
            aria-label="-"
            $buttonSize={buttonSize}
          >
            <Icons.Remove />
          </StyledButton>
        )}
        <Typography
          variant='body01'
          className="count"
          role='heading'
        >
          {count}
        </Typography>
        {!hideControls && (
          <StyledButton
            onClick={() => handleClick('+')}
            disabled={
              count === maxValue ||
              hasMaxCapacity ||
              !isWithinChangeWindow
            }
            aria-label="+"
            $buttonSize={buttonSize}
          >
            <Icons.Add />
          </StyledButton>
        )}
      </StepperWrapper>
    </HeadlineStepperWrapper>
  );
};

export default NumberStepper;
