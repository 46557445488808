import React from 'react';
import { IProps } from './ToggleContainersNavigation.interfaces';
import { Container } from '../../library';
import {
  ContainersWrapper,
  Slide,
  ClearFloat,
} from './ToggleContainersNavigation.styles';

const ToggleContainersNavigation = ({
  items,
  children,
}: IProps) => {
  return (
    <ContainersWrapper
      totalSlices={items.length}
      id="sliceContainer"
    >
      {React.Children.map(children, (child, index) => (
        <Slide
          key={`${index}`}
          id={`slide-${index}`}
        >
          <Container>
            <>{child}</>
          </Container>
        </Slide>
      ))}
      <ClearFloat />
    </ContainersWrapper>
  );
};

export default ToggleContainersNavigation;
