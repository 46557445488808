import React from 'react';
import { CarouselSlide } from './CarouselScrollHorizontal.styles';

interface ISlides {
  children: React.ReactNode[];
  transformAmount: number;
  slidesPerView: number;
  startIndex: number;
  slideMargin: number;
  slideRef?: React.RefObject<HTMLDivElement>;
}

function Slides({
  children,
  transformAmount,
  slidesPerView,
  startIndex,
  slideMargin,
  slideRef,
}: ISlides) {
  return (
    <>
      {children.map((slide, index) => {
        const className = 'CarouselScrollHorizontal-slide';
        const key = `${className}-${index}`;
        const active =
          index >= startIndex &&
          index < startIndex + slidesPerView;
        return (
          <CarouselSlide
            ref={slideRef}
            className={className}
            key={key}
            data-id="CarouselScrollHorizontal"
            $translateX={transformAmount}
            $marginRight={slideMargin}
          >
            {slide}
          </CarouselSlide>
        );
      })}
    </>
  );
}

export default Slides;
