import styled from 'styled-components';
import { IStyledIcon } from './AirportSearchListItem.interfaces';
import {
  MenuItem,
  Grid,
  Popover,
  Icons,
  IconButton,
} from '@library';
import { Button, Box } from '@atoms';
import { solidColors } from '@constants/styles/colors.constants';
import { Typography } from '@wheelsup/wu-react-components';

export const HomeIconButton = styled(
  IconButton,
)<IStyledIcon>`
  // background-image: url(${({ $imageUrl }) => $imageUrl});
  height: 20px;
  margin-right: 8px;
  border-radius: 0;
  background-repeat: no-repeat;
  background-size: contain;
  padding: 0;
`;

export const SavedIconButton = styled(
  IconButton,
)<IStyledIcon>`
  border-radius: 0;
  background-repeat: no-repeat;
  background-size: contain;
  padding: 0;
  width: 50px;
  height: -webkit-fill-available;
  justify-content: center;
  display: flex;
  align-content: center;
  align-items: center;
`;

export const DeleteIconButton = styled(
  IconButton,
)<IStyledIcon>`
  border-radius: 0;
  background-repeat: no-repeat;
  background-size: contain;
  padding: 0;
`;

export const HeartBorder = styled(Icons.FavoriteBorder)`
  color: ${solidColors.upBlue.color};
`;

export const HeartFill = styled(Icons.Favorite)`
  color: ${solidColors.upBlue.color};
`;

export const IconContainer = styled.div<{
  deleteIcon?: boolean;
}>`
  z-index: 100;
  width: fit-content;
  height: fit-content;
  max-height: 76px;
  background-color: ${({ deleteIcon }) =>
    deleteIcon ? solidColors.lava.color : 'transparent'};
  width: 50px;
  height: -webkit-fill-available;
  justify-content: center;
  display: flex;
  align-content: center;
  align-items: center;
`;

export const ItemContainer = styled.div`
  position: relative;
  width: 99%;
  height: auto;
  padding: 16px;
  margin-bottom: 10px;
  border-radius: 8px;
  box-shadow: 1px 2px 4px 1px rgba(0, 0, 0, 0.12);
`;

export const StyledAirportContainer = styled.div`
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledMenu = styled(Popover)`
  color: ${solidColors.upWhite.color};
  padding: 0;
  margin: 0;
  position: relative;
`;

export const StyledMenuItem = styled(MenuItem)`
  width: 480px;
  height: auto;
  display: block;
  margin: 0;
  opacity: 1 !important;
`;

export const StyledAirportItem = styled(Grid)`
  width: fit-contents;
  // padding: 16px 0 16px 0;
`;

export const Airport = styled(Typography)`
  text-align: left;
  width: auto;
  height: auto;
`;

export const AirportCode = styled(Typography)`
  text-align: left;
  width: auto;
  height: auto;
  margin-right: 16px;
`;

export const AirportCity = styled(Typography)`
  padding-top: 8px;
`;

export const Price = styled(Typography)`
  text-align: right;
  width: auto;
  height: auto;
`;

export const HoursText = styled(Typography)`
  width: auto;
`;
export const DensityText = styled(Typography)`
  padding-right: 16px;
  width: auto;
`;

export const ConditionalText = styled(Typography)`
  width: auto;
`;

export const IconGridItem = styled(Grid)`
  text-align: -webkit-right;
  // margin-right: 20px;
  display: inline-flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-content: center;
  justify-content: flex-end;
  align-items: center;
`;

export const AirportSelectButton = styled(Button)`
  width: 340px;
  max-height: 53px;
  text-transform: none;
  justify-content: flex-start;
`;

export const AirportSelectContainer = styled.div`
  height: 96px;
  width: inherit;
  text-align: left;
`;

export const ButtonIcon = styled(Icons.ExpandMore)`
  color: ${solidColors.upBlue.color};
`;
