import { solidColors } from '@constants/styles/colors.constants';
import { typography } from '@constants/styles/typography.constants';
import { ITitle } from './PageTitle.interfaces';
import {
  StyledTitle,
  StyledSubtitle,
} from './PageTitle.styles';

const PageTitle = ({ title, subtitle }: ITitle) => {
  return (
    <>
      <StyledTitle
        variant={typography.heading01Large}
        color={solidColors.midnight}
      >
        {title}
      </StyledTitle>
      {subtitle && (
        <StyledSubtitle
          variant={typography.body1}
          color={solidColors.darkGray}
        >
          {subtitle}
        </StyledSubtitle>
      )}
    </>
  );
};

export default PageTitle;
