import { solidColors } from '@constants/styles/colors.constants';
import styled from 'styled-components';

export const PriceStyle = styled.div<{
  isCheap: boolean;
}>`
  position: absolute;
  left: 50%;
  bottom: -5px;
  @media screen and (max-width: 760px) {
    bottom: -6px;
  }
  transform: translateX(-50%);
  font-family: 'AktivBold';
  font-size: 10px;
  color: ${({ isCheap }) =>
    isCheap
      ? solidColors.basil.color
      : solidColors.midnight.color};
`;

export const ReactDayPickerStyleOverrides = styled.div`
  .rdp {
    --rdp-cell-size: 49px;
    @media screen and (max-width: 760px) {
      --rdp-cell-size: 40px;
    }
  }

  .rdp-months {
    column-gap: 16px;
  }

  .rdp-nav_button {
    width: 26px;
    height: 26px;
  }

  .rdp-button_reset {
    color: ${solidColors.upBlue.color};
  }

  .rdp-caption_label {
    font-family: 'AktivBold';
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: ${solidColors.midnight.color};
  }

  .rdp-day {
    font-family: 'AktivRegular';
    font-weight: normal;
    font-size: 18px;
    // we may need this padding going forward for ,larger strings, e.g. 14.4k, checking with anthoby
    // padding: 30px;
    line-height: 24px;
    overflow: visible;
    color: ${solidColors.midnight.color};
  }

  .rdp-head_cell {
    font-family: 'AktivRegular';
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: ${solidColors.midnight20Percent.color};
    text-transform: unset;
  }

  .rdp-day_range_middle {
    border-radius: 0;
    background-color: ${solidColors.lightGray.color};
  }

  .rdp-day_selected:not(.rdp-day_range_middle) {
    background-color: ${solidColors.upWhite.color};
    color: ${solidColors.upBlue.color};
    font-family: 'AktivBold';
    border: 2px solid ${solidColors.upBlue.color};
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  .rdp-day_range_end,
  .rdp-day_range_start {
    background-color: ${solidColors.upWhite.color};
    color: ${solidColors.upBlue.color};
    font-family: 'AktivBold';
    border: 2px solid ${solidColors.upBlue.color};
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
`;
