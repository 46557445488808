import { IAction } from '@atoms/Action/Action.interfaces';
import {
  DeepLinksAbnormalList,
  abnormalLinksMap,
} from '@atoms/Action/DeepLinks';
import { setErrorOpen } from '@components/molecules/Error/ErrorSlice';
import { handleShowAircraftDetails } from '@components/organisms/CardAircraftDetailsSubpage/AircraftDetailsSlice';
import {
  getFromLocalStorage,
  saveDataInLocalStorage,
} from '@components/utils/storage';
import {
  getRenewalPopUp,
  setAccountSubpage,
  setPopUp,
  setRenewAlert,
} from '@features/Account/AccountSubpageSlice';
import { IAirmedPassenger } from '@features/AirMed/AirmedSlice';
import { setNextPage } from '@features/Fly/FlySearchTripDetailsSlice';
import {
  confirmAirmedPurchaseRequest,
  setPaymentPageBack,
  setPaymentPageNext,
} from '@features/Fly/Payment/PaymentStatusSlice';
import {
  buttonPopupOpen,
  pagePopup,
  snackbarOpen,
} from '@features/Members/popupSlice';
import { setIsCancelOfferPopUpOpen } from '@features/MyTrips/MyTripsCancelOfferSlice';
import {
  postCancelFlightRequest,
  setIsSelectFlightsPopupOpen,
} from '@features/MyTrips/MyTripsCancelSlice';
import {
  setDeletePassengerModalOpen,
  setManagedPassengerId,
} from '@features/Passengers/PassengerSummarySlice';
import { Dispatch } from '@reduxjs/toolkit';
import { checkIsTokenValid } from '@services/authService/authService';
import { IAnalytics } from '@services/dynamicRenderingService/dynamicRendering.interfaces';
import { trackAnalytics } from '@services/mixpanelService/mixpanel.service';
import { getAccessToken } from '@services/tokenService/token';
import { NavigateFunction } from 'react-router-dom';
import { selectAlternateAirport } from './Button.utils';
import { endSession } from '@components/utils/sessionTimer';
import { IPayment } from '@components/types';
import {
  copyToClipboard,
  shareData,
} from '@components/utils';

interface ServerAction {
  buttonAction: Partial<IAction>;
  action: Partial<IAction>;
  dispatch: Dispatch<any>;
  navigate: NavigateFunction;
  title?: string | undefined;
  mainPassenger?: IAirmedPassenger;
  passengerList?: IAirmedPassenger[];
  selectedCredits?: IPayment[];
  selectedPaymentMethods?: IPayment[];
}

interface PageAction {
  dispatch: Dispatch<any>;
  navigate: NavigateFunction;
}

const handlePageClose = ({
  dispatch,
  navigate,
}: PageAction) => {
  const flightDetailId = getFromLocalStorage(
    'flightDetailId',
  );
  if (flightDetailId) {
    navigate('/flight-deals');
  } else {
    navigate('/');
  }
  dispatch(setErrorOpen(false));
};

export const handlePageBack = ({
  dispatch,
  navigate,
}: PageAction) => {
  const flightDetailId = getFromLocalStorage(
    'flightDetailId',
  );
  if (window.location.pathname === '/fly/checkout-flight') {
    dispatch(setPaymentPageBack());
  } else {
    if (flightDetailId) {
      navigate('/');
    } else {
      navigate(-1);
    }

    dispatch(setErrorOpen(false));
  }
};

const handlePageNext = ({
  dispatch,
  navigate,
}: PageAction) => {
  if (
    window.location.href.indexOf('search-trip-details') !=
    -1
  ) {
    saveDataInLocalStorage(
      'shouldShowLoadingPageWeb',
      true,
    );
    checkIsTokenValid();
    dispatch(setNextPage(true));
  } else if (
    window.location.pathname === '/create-account/4'
  ) {
    navigate('/create-account/5');
  } else if (
    window.location.pathname === '/fly/checkout-flight'
  ) {
    dispatch(setPaymentPageNext());
  } else if (
    window.location.pathname === '/upglobalresponse/payment'
  ) {
    dispatch(setPaymentPageNext());
  }
};

const format_date = (input: string) => {
  return input.replace(/(\d{2})(\d{2})(\d{4})/, '$1/$2/$3');
};

export const getServerAction = ({
  buttonAction,
  action,
  dispatch,
  navigate,
  title,
  mainPassenger,
  passengerList,
  selectedCredits,
  selectedPaymentMethods,
}: ServerAction) => {
  // If logic is added here, please add the same in the getServerAction function in the Button.utils.ts file if relevant (deepLinks)
  const chunkedUrl =
    buttonAction.data && buttonAction.data.split('/');
  switch (buttonAction.type) {
    case 'url':
      if (buttonAction.data) {
        navigate(buttonAction.data);
      }
      break;
    case 'page_home':
      dispatch(setErrorOpen(false));
      navigate('/');
      break;
    case 'page_flightDeals':
      dispatch(setErrorOpen(false));
      navigate('/flight-deals');
      break;
    case 'page_back':
      handlePageBack({ dispatch, navigate });
      break;
    case 'page_close':
      handlePageClose({ dispatch, navigate });
      break;
    case 'log_out':
      dispatch(setPopUp(true));
      break;

    case 'in_app_membersite_demoUser':
      if (buttonAction.data) {
        const directoryChunk = buttonAction.data.split(
          '.wheelsup.com',
        )[1];

        // if we're in dev, push to localhost/ending
        // if we're anywhere else, just replace membersDomain and send there
        if (
          // fetchProcessEnvVariable('ENV') ===
          // 'dev'
          process.env.REACT_APP_LOCAL_ENVIRONMENT
        ) {
          navigate(directoryChunk);
        } else {
          navigate('/demo-user/search-flights');
        }
      }

      break;

    case 'in_app_membersite':
      if (
        chunkedUrl &&
        chunkedUrl[5] === 'account' &&
        buttonAction.data
      ) {
        if (buttonAction.actionAnalytics) {
          trackAnalytics(
            buttonAction.actionAnalytics as IAnalytics,
          );
        }

        if (
          chunkedUrl[chunkedUrl.length - 1] ===
          'authorized-users'
        ) {
          // FIXME: changed from history.location.pathname to window.location.pathname
          const location = window.location.pathname.split(
            '/',
          );
          // get passenger id from url
          dispatch(
            setManagedPassengerId(
              location[location.length - 1],
            ),
          );
        }
        dispatch(
          setAccountSubpage({
            accountSubpage: buttonAction.data,
            title,
          }),
        );
        saveDataInLocalStorage(
          'ACCOUNT_SUBPAGE_WEBVIEW_KEY',
          { webviewKey: buttonAction.data },
        );
        saveDataInLocalStorage(
          'ACCOUNT_SUBPAGE_WEBVIEW_TITLE',
          { webviewTitle: title },
        );
        saveDataInLocalStorage(
          'shouldShowLoadingPageWeb',
          true,
        );

        checkIsTokenValid();

        navigate(
          buttonAction.data &&
            buttonAction.data.split('/src/client')[1],
        );
      } else {
        // Logic originally from `CardHeadlinesubcopyCTAImageOversizedWEBONLY.tsx`
        const splitUrl = buttonAction.data?.split(
          'src/client/',
        )[1];
        const joinedStarUrl = splitUrl
          ? splitUrl.split('/').join('*')
          : '';

        saveDataInLocalStorage(
          'shouldShowLoadingPageWeb',
          true,
        );
        checkIsTokenValid();
        navigate(`/members/${joinedStarUrl}`);
      }

      break;
    // https://github.com/wheelsup/contracts/pull/327/files
    case 'delete_passenger':
      dispatch(setDeletePassengerModalOpen(true));
      break;

    case 'out_of_app_browser':
      window.open(buttonAction?.data);
      break;

    case 'page_next':
      handlePageNext({ dispatch, navigate });
      break;

    case 'molecule_close':
      // Delete this when pegasus sends 'page_next' for phone verification
      if (
        window.location.pathname === '/create-account/4'
      ) {
        navigate('/create-account/5');
      } else if (window.location.pathname === '/account') {
        dispatch(setRenewAlert(false));
      }
      break;
    case 'page_cancel':
      if (window.location.pathname === '/account') {
        dispatch(setRenewAlert(false));
      } else {
        dispatch(setIsSelectFlightsPopupOpen(true));
      }
      break;
    case 'page_submit':
      if (window.location.pathname.includes('/my-trips/')) {
        dispatch(
          postCancelFlightRequest({
            token: getFromLocalStorage('access_token'),
          }),
        );
      } else if (
        window.location.pathname.includes(
          '/upglobalresponse/payment',
        ) &&
        mainPassenger &&
        passengerList
      ) {
        const memberInfo = {
          firstName: mainPassenger.firstName,
          middleName: mainPassenger.middleName,
          lastName: mainPassenger.lastName,
          birthday: format_date(mainPassenger.birthDate),
          phone: mainPassenger.phone,
          email: mainPassenger.email,
          address: {
            addressLine1: mainPassenger.addressLine1,
            addressLine2: mainPassenger.addressLine2,
            city: mainPassenger.city,
            state: mainPassenger.state,
            postalCode: mainPassenger.postalCode,
          },
        };
        const passengers = passengerList.map((pax) => {
          return {
            firstName: pax.firstName,
            middleName: pax.middleName,
            lastName: pax.lastName,
            birthday: format_date(pax.birthDate),
            phone: pax.phone,
            email: pax.email,
            address: {
              addressLine1: pax.addressLine1,
              addressLine2: pax.addressLine2,
              city: pax.city,
              state: pax.state,
              postalCode: pax.postalCode,
            },
          };
        });
        if (selectedCredits && selectedPaymentMethods) {
          dispatch(
            confirmAirmedPurchaseRequest({
              token: getAccessToken(),
              resPayload: {
                memberInfo: memberInfo,
                selectedPayments: [
                  ...selectedCredits,
                  ...selectedPaymentMethods,
                ],
                passengers: passengers,
              },
            }),
          );
        }
      }
      break;
    case 'phone':
      window.location.href = `tel:${buttonAction.data}`;
      break;
    case 'email':
      window.location.href = `mailto:${buttonAction.data}`;
      break;
    case 'show_aircraft_details':
      dispatch(handleShowAircraftDetails());
      break;
    case 'display_membership_lock':
      dispatch(buttonPopupOpen(true));
      dispatch(pagePopup(true));
      dispatch(snackbarOpen(false));
      break;
    case 'share_flight':
      copyToClipboard(buttonAction.data);
      shareData(buttonAction.data);
      break;
    case 'uri':
      /*
              * All new links should be used with the `url` case instead of `uri`.
              * This is only for legacy links that are still using `uri` and special
              cases if they arise.
          */
      if (buttonAction.data) {
        const actionData = buttonAction.data;
        const r = new RegExp('^(?:[a-z]+:)?//', 'i');

        if (actionData?.includes('//newsDetails')) {
          const btnUrl = new URL(action?.data || '');

          const newsId = btnUrl.searchParams.get('id');
          return navigate(`/news/${newsId}`);
        } else if (actionData?.includes('//fly?id=')) {
          const searchParamId = actionData.split('id=')[1];
          return navigate('/fly?id=' + searchParamId);
        } else if (
          actionData?.includes(
            'manageReservation?reservationId=',
          )
        ) {
          const searchParamId = actionData.split(
            'reservationId=',
          )[1];
          return navigate(
            `/fly/manage-reservation/${searchParamId}`,
          );
        } else if (
          actionData?.includes('//cancelPendingFlight')
        ) {
          const dealId = actionData.split('=')[1];
          if (dispatch) {
            dispatch(
              setIsCancelOfferPopUpOpen({
                isOpen: true,
                dealId,
              }),
            );
          }
        } else if (
          actionData?.includes('//reservationDetails')
        ) {
          const userId = actionData?.split(
            `${DeepLinksAbnormalList.RESERVATION_DETAILS}?id=`,
          )[1];
          navigate(`/my-trips/${userId}`);
        } else if (actionData?.includes('//callUs')) {
          window.location.href = 'tel:866-498-7359';
        } else if (actionData?.includes('/account/legal')) {
          navigate('/account/legal');
        } else if (
          actionData?.includes('//selectAlternateAirport')
        ) {
          selectAlternateAirport(action, dispatch);
        } else if (
          actionData?.includes('//renewMembershipActive')
        ) {
          dispatch(
            getRenewalPopUp({
              urlParams: '/active',
            }),
          );
          return;
        } else if (
          actionData?.includes(
            '//renewMembershipExpired',
          ) &&
          dispatch
        ) {
          dispatch(
            getRenewalPopUp({
              urlParams: '/expired',
            }),
          );
          return;
        } else if (
          actionData?.includes(
            DeepLinksAbnormalList.EDIT_PASSENGER,
          )
        ) {
          const userId = actionData?.split(
            `${DeepLinksAbnormalList.EDIT_PASSENGER}?id=`,
          )[1];
          navigate(`/manage/passenger/${userId}/0`);
        } else if (actionData?.includes('wheelsup://')) {
          const url = abnormalLinksMap[actionData];
          url && navigate(url);
        } else if (r.test(actionData || '')) {
          const btnUrl = new URL(actionData || '');
          if (btnUrl.href.includes('http')) {
            window.open(btnUrl.href, '_blank');
          }
        } else {
          navigate(actionData?.split('.com')[1] || '');
        }
      }
      !buttonAction.data?.includes('/fly/') && endSession();
      break;
    default:
      break;
  }
};

export default getServerAction;
