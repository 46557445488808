import React from 'react';
import {
  ITypographyObject,
} from '@constants/styles/typography.constants';
import { Typography as SharedLibraryTypography, WidthOptions, typographyColorVariants } from '@wheelsup/wu-react-components';
import { TypographyProps as SharedLibraryTypographyProps } from '@wheelsup/wu-react-components/types/components/atoms/Typography/Typography';
import { solidColors } from '@constants/styles/colors.constants';
import './Typography.css';


export interface IndexableObject {
  [key: string]: unknown;
}

interface TypographyProps {
  variant: ITypographyObject;
  align?: string;
  color?: IndexableObject;
  children: React.ReactNode;
  truncate?: number;
  className?: string;
  datatestid?: string;
  as?: string;
  wrap?: boolean;
  isUpperCase?: boolean;
  ellipsis?: boolean;
  libraryColor?: SharedLibraryTypographyProps['color'];
  wrapperWidth?: WidthOptions;
}

function Typography(props: TypographyProps) {
  const libraryColorProp = props.libraryColor;
  const typographyVariant = props.variant; // typography object passed
  const typographyColor = props?.color?.colorName as keyof typeof solidColors ?? 'midnight';
  const libraryColor: keyof typeof typographyColorVariants = solidColors[typographyColor].libraryVariant ?? 'primary';
  const calcTruncate = (): SharedLibraryTypographyProps['truncate'] => {
    if (props.truncate) {
      return props.truncate > 3 ? 3 : props.truncate as SharedLibraryTypographyProps['truncate'];
    }
    return undefined;
  };
  
  const align = props.align as SharedLibraryTypographyProps['align'];
  const truncate = calcTruncate();
  
  const isUpperCase = props.isUpperCase || typographyVariant?.textTransform === 'uppercase';
  const textDecoration = typographyVariant?.textDecoration === 'line-through' ? 'strikethrough' : 'none';

  return (
    <SharedLibraryTypography
      wrapperWidth={props.wrapperWidth}
      data-test-id={typographyVariant?.name}
      className={`${textDecoration} ${props.className}`}
      variant={typographyVariant?.libraryVariant ?? 'body01'}
      color={libraryColorProp ?? libraryColor}
      truncate={truncate}
      align={align}
      wrap={props.wrap}
      isUpperCase={isUpperCase}
      ellipsis={props.ellipsis}
    >
      {props.children}
    </SharedLibraryTypography>
  );
}

export default Typography;
