import { solidColors } from '@constants/styles/colors.constants';
import { typography } from '@constants/styles/typography.constants';
import { ImageImgix, Typography } from '@atoms';
import {
  StyledCard,
  StyledAirportContainer,
  StyleFlightLabel,
  StyledFlightHeader,
  StyledFlightInfo,
  DottedLine,
  StyledTimeTypography,
  TimeContainer,
  StyleContentContainer,
  ContentWrapper,
  TimeRangeContainer,
  FuelContainer,
} from './CardFlight.styles';
import { ICardFlight } from './CardFlight.interfaces';
import { useEffect, useState } from 'react';
import { useAppSelector } from '@app/hooks';

const CardFlight = ({ ...data }: ICardFlight) => {
  const { legs } = useAppSelector(
    (state) => state.searchFlight,
  );
  const [tripTitle, setTripTitle] = useState('DEPART');

  if (!data) {
    return null;
  }

  useEffect(() => {
    const id = parseInt(data.id[data.id.length - 1], 10);
    const tripType = legs[0].flightType;
    if (tripType === 'round-trip') {
      setTripTitle(id === 0 ? 'DEPART' : 'RETURN');
    } else if (tripType === 'multi-leg') {
      setTripTitle(`Flight ${id + 1}`);
    }
  }, []);

  return (
    <StyledCard data-name="CardFlightItinerarySummary">
      <StyledFlightHeader>
        <Typography
          variant={typography.body2Bold}
          color={solidColors.midnight}
        >
          {tripTitle}
        </Typography>
        <Typography variant={typography.body1}>
          {data.flightDateCopy}
        </Typography>
      </StyledFlightHeader>

      <StyledFlightInfo>
        <ImageImgix
          src={data.departureImage.url}
          filter={data.departureImage.tintColor}
          width={25}
          height={25}
          fit="crop"
          alt="center icon"
        />

        <ContentWrapper>
          <StyleContentContainer>
            <StyleFlightLabel>
              <Typography
                variant={typography.body2}
                color={solidColors.midnight}
              >
                {'Depart'}
              </Typography>
            </StyleFlightLabel>
            <TimeContainer>
              <StyledTimeTypography
                variant={typography.body2}
                color={solidColors.midnight}
              >
                {data?.departureTimeTitle}
              </StyledTimeTypography>
            </TimeContainer>
          </StyleContentContainer>

          <TimeRangeContainer>
            <Typography
              variant={typography.caption}
              color={solidColors.darkGray}
            >
              {data.departureAirportName}
            </Typography>
            <StyledTimeTypography
              variant={typography.body2}
              color={solidColors.midnight}
            >
              {data.departureTime}
            </StyledTimeTypography>
          </TimeRangeContainer>
        </ContentWrapper>

        <DottedLine />
        <StyledAirportContainer>
          <Typography
            variant={typography.caption}
            color={solidColors.darkGray}
          >
            {data?.totalFlightTime}
          </Typography>
          <FuelContainer>
            <Typography
              variant={typography.body2}
              color={solidColors.darkGray}
            >
              {data.fuelStopCopy && (
                <span className="fuelStop">
                  {data.fuelStopCopy}
                </span>
              )}
            </Typography>
          </FuelContainer>
        </StyledAirportContainer>
      </StyledFlightInfo>
      <StyledFlightInfo>
        <ImageImgix
          src={data.arrivalImage.url}
          filter={data.arrivalImage.tintColor}
          width={25}
          height={25}
          fit="crop"
          alt="center icon"
        />

        <ContentWrapper>
          <StyleContentContainer>
            <StyleFlightLabel>
              <Typography
                variant={typography.body2}
                color={solidColors.midnight}
              >
                {'Arrive'}
              </Typography>
            </StyleFlightLabel>
            <TimeContainer>
              <StyledTimeTypography
                variant={typography.body2}
                color={solidColors.midnight}
              >
                {data?.arrivalTimeTitle}
              </StyledTimeTypography>
            </TimeContainer>
          </StyleContentContainer>

          <TimeRangeContainer>
            <Typography
              variant={typography.caption}
              color={solidColors.darkGray}
            >
              {data.arrivalAirportName}
            </Typography>
            <StyledTimeTypography
              variant={typography.body2}
              color={solidColors.midnight}
            >
              {data.estimatedArrivalTime}
            </StyledTimeTypography>
          </TimeRangeContainer>
        </ContentWrapper>
      </StyledFlightInfo>
    </StyledCard>
  );
};

export default CardFlight;
