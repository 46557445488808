import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import rootReducer from './rootReducer';
import { injectStore } from '@services/apiService/responseInterceptors/responseHandler';
import { injectStore as injectStoreCoreBe } from '@services/apiService/responseInterceptors/coreBackendEndpointResponseInterceptor';
import { getFromLocalStorage } from '@components/utils/storage';
import { injectSessionStore } from '@components/utils/sessionTimer';
import { injectStoreRequest } from '@services/apiService/requestInterceptors/requestInterceptor';
import { passengerAddEditMiddleware } from './middleware/passengerAddEdit';

const reduxLoggerEnabledLocal = getFromLocalStorage(
  'REDUX_LOGGER_ENABLED_KEY',
);

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    const middleware = getDefaultMiddleware({
      serializableCheck: false,
    });

    if (reduxLoggerEnabledLocal) middleware.push(logger);

    middleware.push(passengerAddEditMiddleware);

    return middleware;
  },
  devTools: process.env.NODE_ENV !== 'production',
});

injectStore(store);
injectStoreCoreBe(store);
injectStoreRequest(store);
injectSessionStore(store);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
