import {
  Option,
  ImageStyled,
} from './ToggleTabsNavigation.styles';
import { IToggleTabsNavProps } from './ToggleTabsNavigation.interfaces';
import { trackAnalytics } from '@services/mixpanelService/mixpanel.service';
import CarouselScrollHorizontal from '@components/organisms/CarouselScrollHorizontal/CarouselScrollHorizontal';
import { Typography } from '@wheelsup/wu-react-components';

const ToggleTabsNavigation = ({
  items,
  handleSlice,
  selected,
  setSelected,
}: IToggleTabsNavProps) => {
  const handleClick = (title: string, index: number) => {
    setSelected(title);
    handleSlice(index);

    if (items[index]?.analytics) {
      trackAnalytics(items[index].analytics);
    }
  };

  return (
    <div>
      <CarouselScrollHorizontal inComponent>
        {items.map((item, index) => (
          <Option
            data-name="Option"
            key={item.title}
            className={
              item.title === selected ? 'active' : ''
            }
            onClick={() => handleClick(item.title, index)}
          >
            {item.image && (
              <ImageStyled
                src={item.image.url}
                alt="image"
              />
            )}
            <Typography
              variant='body02'
              color={item.title === selected ? 'link' : 'primary'}
            >
              {item.title}
            </Typography>
          </Option>
        ))}
      </CarouselScrollHorizontal>
    </div>
  );
};

export default ToggleTabsNavigation;
