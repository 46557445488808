import styled from 'styled-components';
import { Button, Typography } from '@atoms';
import { solidColors } from '@constants/styles/colors.constants';
import { Icons } from '@components/library';

export const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.08),
    0px 2px 6px 1px rgba(0, 0, 0, 0.12);
  position: relative;
  height: fit-content;
  padding: 20px;
  margin: 20px 0px;
`;

export const StyledTitleContainer = styled.div`
  margin: 10px 0px 20px 0px;
`;

export const StyledTitle = styled(Typography)``;

export const StyledInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px;
  width: 100%;
`;

export const StyledTextGroupIconText = styled.div<{
  $showTopBorder?: boolean;
}>`
  padding: 12px 0px;
  border-bottom: 1px solid ${solidColors.mediumGray.color};
  width: 100%;

  ${({ $showTopBorder }) => {
    if ($showTopBorder) {
      return `&:first-of-type {
                border-top: 1px solid ${solidColors.mediumGray.color};
            }`;
    }
  }}
`;

export const StyledAddEditButton = styled(Button)<{
  $styleType?: string;
}>`
  ${({ $styleType }) => {
    if ($styleType !== 'icon_no_padding') {
      return `
                padding-left: 22px;
                padding-right: 1rem;
            `;
    }
  }}
  margin: 10px 0px;
  background-color: ${solidColors.c15.color};
  color: ${solidColors.c2.color};
`;

export const StyledChevron = styled(Icons.ChevronRight)`
  color: ${solidColors.upBlue.color};
`;
