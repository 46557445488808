export default function redirect(
  location: string,
  hash: string,
  search: string,
  memberSiteV1: string,
) {
  const locationObject = location.split(memberSiteV1);
  const redirectUrl = '/' + locationObject[1];
  if (
    redirectUrl !== '/login' &&
    redirectUrl !== '/register' &&
    redirectUrl !== '/logout'
  ) {
    localStorage.setItem('wup-redirect', redirectUrl);
  }
  localStorage.setItem('wup-location-search', search);

  // prevents v1 signin if route is v1 login,
  // v2 logic will redirect to v1 experience
  if (redirectUrl === '/login' || hash === '#/login') {
    window.location.assign(locationObject[0] + '/signin');
  } else {
    window.location.assign(
      locationObject[0] + memberSiteV1,
    );
  }
  // window.location.assign(
  //     'http://localhost:3000' +
  //         memberSiteV1,
  // );
}
