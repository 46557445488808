import styled from 'styled-components';
import { Container } from '@library';

export const StyledInnerContainer = styled(Container)<{
  display?: string;
  columnsType?: string;
}>`
  ${(props) =>
    props.display &&
    `
        display: ${props.display};
        @media (max-width: 900px) {
            flex-wrap: wrap;
        }
    `}

  ${(props) =>
    props.display && props.theme.breakpoints.down('lg')} {
  }

  ${(props) =>
    props.columnsType &&
    props.columnsType === '70-30' &&
    `
        > div {
            padding: 0;
            &:first-child {
                width: 68%;
                padding-right: 30px;
                @media (max-width: 900px) {
                    width: 100%;
                    padding-right: 0;
                }
            }
            &:last-child {
                width: 32%;
                @media (max-width: 900px) {
                    width: 100%;
                }
            }
        }
    `}

  ${(props) =>
    props.columnsType &&
    props.columnsType === '70-30-100' &&
    `
        flex-wrap: wrap;
        > div {
            padding: 0;
            &:first-child {
                width: 66%;
                padding-right: 20px;
                @media (max-width: 900px) {
                    width: 100%;
                    padding-right: 0;
                }
            }
            &:nth-child(2){
                max-width: 30%;
                @media (max-width: 900px) {
                    width: 100%;
                    padding-right: 0;
                }
            }
            &:nth-child(3){
                margin: 30px 0px;
                width: 100%;
                @media (max-width: 900px) {
                    width: 100%;
                }
            }
        }
    `}

    ${(props) =>
    props.columnsType &&
    props.columnsType === '30-70' &&
    `
        > div {
            padding: 0;
            &:first-child {
                width: 32%;
                @media (max-width: 900px) {
                    width: 100%;
                }
            }
            &:last-child {
                width: 68%;
                padding-left: 30px;
                @media (max-width: 900px) {
                    width: 100%;
                    padding-left: 0;
                }
            }
        }

    `}

    > div {
    .MuiContainer-root {
      padding-left: 0;
      padding-right: 0;
    }
  }
`;
