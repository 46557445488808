import InputBase from '@atoms/InputBase';
import { emailRegex } from '@constants/validations/regexLibrary';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import { EmailFieldCustomProps } from './FormFieldEmailEntry.interfaces';

enum EmailErrors {
  missing = 'Please enter your email address.',
  invalid = 'We do not recognize your email address. Please try again.',
}

export default function FormFieldEmailEntry({
  isRequired = true,
  ...rest
}: EmailFieldCustomProps) {
  const [
    inputError,
    setInputError,
  ] = useState<EmailErrors | null>();

  const validateEmail = (value: string) =>
    emailRegex.test(value);

  const handleInputOnBlur = (
    e: React.FocusEvent<
      HTMLInputElement | HTMLTextAreaElement
    >,
  ) => {
    const isMatch = validateEmail(e.target.value);

    rest.getError(!isMatch);

    if (isEmpty(e.target.value) && isRequired) {
      setInputError(EmailErrors.missing);
      return;
    }

    if (!isEmpty(e.target.value) && !isMatch) {
      setInputError(EmailErrors.invalid);
      return;
    }

    setInputError(null);
  };

  const handleOnChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    rest.getError(!validateEmail(event.target.value));
    rest.onChange?.(event);
  };

  return (
    <InputBase
      {...rest}
      title={rest.title ?? 'Email'}
      type="email"
      onBlur={handleInputOnBlur}
      error={!!inputError}
      errorMessage={inputError?.toString()}
      onChange={handleOnChange}
    />
  );
}
