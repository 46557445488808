import {
  Divider,
  Typography,
} from '@wheelsup/wu-react-components';
import { solidColors } from '@constants/styles/colors.constants';
import { typography } from '@constants/styles/typography.constants';
import { ImageImgix } from '@atoms';
import {
  StyledCard,
  StyledFlightType,
  StyledDepartureArrivalContainer,
  StyledAirportName,
  StyledElevation,
} from './ContainerRoundTripFlightSummary.styles';
import { IContainerRoundTripFlightSummary } from './ContainerRoundTripFlightSummary.interfaces';
import { ListAccordionItem } from '@molecules';
import Spacer from '@atoms/Spacer/Spacer';

const ContainerRoundTripFlightSummary = ({
  centerIconImage,
  flightDealTypeIcon,
  flight,
  children,
  button,
  spacerTypeTop,
  spacerTypeBottom,
}: IContainerRoundTripFlightSummary) => {
  return (
    <>
      {spacerTypeTop && <Spacer height={spacerTypeTop} />}
      <StyledElevation>
        <StyledCard data-name="ContainerRoundTripFlightSummary">
          {flightDealTypeIcon && (
            <StyledFlightType>
              <ImageImgix
                src={flightDealTypeIcon?.url}
                filter={flightDealTypeIcon?.tintColor}
                width={20}
                height={20}
                fit="crop"
                alt="Description"
              />
            </StyledFlightType>
          )}
          <div>
            <StyledDepartureArrivalContainer>
              <Typography
                variant="heading01"
                className="typoBackground"
              >
                {flight.fromAirport.displayCompactValue1}
              </Typography>

              {centerIconImage?.url && (
                <ImageImgix
                  src={centerIconImage.url}
                  filter={centerIconImage.tintColor}
                  width={25}
                  height={25}
                  fit="crop"
                  alt="center icon"
                />
              )}

              <Typography
                variant="heading01"
                className="typoBackground"
              >
                {flight.toAirport.displayCompactValue1}
              </Typography>
            </StyledDepartureArrivalContainer>
            <StyledDepartureArrivalContainer>
              <StyledAirportName
                variant={typography.caption}
                color={solidColors.darkGray}
              >
                {flight.fromAirport.displayCompactValue2}
              </StyledAirportName>
              <StyledAirportName
                $isArrival
                variant={typography.caption}
                color={solidColors.darkGray}
              >
                {flight.toAirport.displayCompactValue2}
              </StyledAirportName>
            </StyledDepartureArrivalContainer>
          </div>
          <Divider />
          <ListAccordionItem
            title={button.title ?? ''}
            body={children}
            direction={'reverse'}
            headerType={'cta'}
            typographyColor={solidColors.upBlue}
          />
        </StyledCard>
      </StyledElevation>
      {spacerTypeBottom && (
        <Spacer height={spacerTypeBottom} />
      )}
    </>
  );
};

export default ContainerRoundTripFlightSummary;
