import styled from 'styled-components';

import {
  ImageCarouselContainerProps,
  ImageCarouselContentProps,
} from './ImageCarousel.interfaces';
import { Flag } from '@wheelsup/wu-react-components';

export const ImageCarouselContent: any = styled.div<ImageCarouselContentProps>`
  opacity: 1;
  transform: translateX(-${({ translate }) => translate}px);
  transition: opacity 0.5s 0.5s, transform 0.5s 0.5s;
  height: 100%;
  width: ${({ width }) => width}px;
  display: flex;
  min-width: 385px;
  position: relative;
  left: -1px;
`;

export const ImageCarouselContainer = styled.div<ImageCarouselContainerProps>`
  position: relative;
  height: ${({ heightRem }) => heightRem}rem;
  width: ${({ widthRem }) => widthRem}rem;
  margin: 0 auto;
  overflow: hidden;
  min-width: 385px;
`;

export const StyledFlag = styled(Flag)<{
  textColor?: string;
}>`
  position: absolute;
  z-index: 5;

  ${({ textColor }) =>
    textColor &&
    `
    div[role="paragraph"] {
      color: ${textColor};
    }
  `}
`;
