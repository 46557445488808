import { useEffect, useState } from 'react';

import Error from '@components/molecules/Error/Error';
import Footer from '../Footer/Footer';
import { Header } from '@organisms';
import { IMainLayout } from './Layout.interfaces';
import { Loader } from '@components/molecules';
import { getFromLocalStorage } from '@components/utils/storage';
import { useAppSelector } from '@app/hooks';
import { useFeatureFlag } from '@services/featureFlagService/featureFlagService.services';
import { FeatureFlags } from '@services/featureFlagService/constants';

const MainLayout = ({
  children,
  showHeader,
  showFooter,
}: IMainLayout) => {
  const USE_NATIVE_HUB = useFeatureFlag(FeatureFlags.USE_NATIVE_HUB);
  const shouldShowLoadingPage = getFromLocalStorage(
    'shouldShowLoadingPage',
  );
  const [uncaughtError, setUncaughtError] = useState(false);
  const { refreshIsLoading } = useAppSelector(
    (state) => state.handle401,
  );
  const { isError } = useAppSelector(
    (state) => state.handleUncaughtError,
  );
  const { isAppLoading } = useAppSelector(
    (state) => state.appLoading,
  );

  const { errorPayload, errorOpen } = useAppSelector(
    (state) => state.error,
  );

  useEffect(() => {
    setUncaughtError(isError);
  }, [isError]);

  // this is to not show the whole layout when preparing an iframe
  if (shouldShowLoadingPage) {
    return (
      <>
        {refreshIsLoading && <Loader />}
        {errorOpen && (
          <Error
            open={true}
            data={errorPayload}
          />
        )}
        <div>{children}</div>
      </>
    );
  }

  const showLoader = refreshIsLoading || isAppLoading;
  return (
    <>
      {showLoader && <Loader />}
      {errorOpen && (
        <Error
          open={true}
          data={errorPayload}
        />
      )}
      {(showHeader || USE_NATIVE_HUB) && <Header />}
      <div
        id={'middle-children'}
        style={{ minHeight: '100vh' }}
      >
        {children}
      </div>
      {(showFooter || USE_NATIVE_HUB) && <Footer />}
    </>
  );
};

export default MainLayout;
