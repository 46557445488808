import AutoCompleteFlight from '@atoms/AutoCompleteFlight';
import { IAirportItem } from '@atoms/AutoCompleteFlight/AutoCompleteFlight.interfaces';
import FlightIcon from '@material-ui/icons/Flight';
import React, { useEffect, useRef, useState } from 'react';
import {
  ISavedDeleteResData,
  ISearchFlight,
  ISubpageRes,
} from './SearchFlight.interfaces';
import {
  StyledSMiddleContainer,
  StyledAutoCompleteContainer,
  StyledSubmitFlightSearch,
  StyledAutoCompleteItem,
} from './SearchFlight.styles';
import {
  getSubpage,
  saveAirportAction,
  deleteAirportAction,
} from '@services/airportSubpageSearchService/airportSubpageSearchService';
import { Container } from '@library';
import Error from '@molecules/Error/Error';
import { useOnClickOutside } from '@hooks';
import { useDispatch } from 'react-redux';
import useSnackbar from '../SubPageFlightSearchV2/LegContainers/useSnackbar';

const DUPLICATED_AIRPORT =
  'Please choose a different airport';

export default function FormFieldAirportSearchDashboard({
  id,
  button,
  departureTitle,
  departureSubtitle,
  departureImage,
  departureButton,
  destinationTitle,
  destinationSubtitle,
  destinationImage,
  destinationButton,
}: ISearchFlight) {
  const dispatch = useDispatch();

  const [
    from,
    setFrom,
  ] = React.useState<IAirportItem | null>(null);
  const [fromError, setFromError] = React.useState<
    string | null
  >(null);
  const [to, setTo] = React.useState<IAirportItem | null>(
    null,
  );
  const [toError, setToError] = React.useState<
    string | null
  >(null);
  const [error, setError] = useState(false);
  const initialSubpageData = {
    homeImage: {
      url: '',
    },
    deleteImage: {
      url: '',
    },
    pinImage: {
      url: '',
    },
    title: '',
    savedAirportsInstructions: '',
    savedAirportsHeader: '',
    recentlySearchedAirportsHeader: '',
    highDensityCopy: '',
    conditionalCopy: '',
    savedAirports: [],
    recentlySearchedAirports: [],
    nearbyAirports: [],
    emptyAirportListsCopy: '',
  };
  const [
    subpageData,
    setSubpageData,
  ] = useState<ISubpageRes>(initialSubpageData);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarCopy, setSnackbarCopy] = useState('');

  const onFromChange = (value: IAirportItem | null) => {
    if (to && value && to.id === value.id) {
      setFromError(DUPLICATED_AIRPORT);
    } else {
      if (fromError) setFromError(null);
    }
    setFrom(value);
  };

  const onToChange = (value: IAirportItem | null) => {
    if (from && value && from.id === value.id) {
      setToError(DUPLICATED_AIRPORT);
    } else {
      if (toError) setToError(null);
    }
    setTo(value);
  };

  const getAirportSubpage = async (titleText: string) => {
    const data = await getSubpage(titleText, false);
    setSubpageData(data);
  };

  const snackbarHandler = useSnackbar(dispatch);

  const saveAirport = async (airportId: string) => {
    try {
      const res: ISavedDeleteResData = await saveAirportAction(
        airportId,
      );
      setSubpageData({
        ...subpageData,
        savedAirports: res.data.results.savedAirports,
        recentlySearchedAirports:
          res.data.results.recentlySearchedAirports,
        nearbyAirports: res.data.results.nearbyAirports,
      });

      snackbarHandler(
        res.data.userNotifications[0].data.copy,
      );
    } catch (err) {
      showError();
    }
  };

  const deleteAirport = async (airportId: string) => {
    try {
      const res: ISavedDeleteResData = await deleteAirportAction(
        airportId,
      );
      setSubpageData({
        ...subpageData,
        savedAirports: res.data.results.savedAirports,
        recentlySearchedAirports:
          res.data.results.recentlySearchedAirports,
        nearbyAirports: res.data.results.nearbyAirports,
      });

      snackbarHandler(
        res.data.userNotifications[0].data.copy,
      );
    } catch (err) {
      showError();
    }
  };

  const showError = () => {
    setError(!error);
  };

  const errorRef = useRef(null);

  useOnClickOutside(errorRef, () => setError(!error));

  const errorData = {
    saveError: {
      buttons: [{}, {}],
      copy: 'You have reach max save',
      headline: 'Something went wrong',
      requiredUserInteraction: false,
    },
  };

  return (
    <Container
      id={id}
      data-name="FormFieldAirportSearchDashboard"
    >
      {error && (
        <Container ref={errorRef}>
          <Error
            open={true}
            data={errorData.saveError}
          />
        </Container>
      )}
      <StyledAutoCompleteContainer container>
        <StyledAutoCompleteItem
          item
          lg={12}
          md={12}
          xs={12}
          $isTo={false}
          data-name="AutocompleteContainerFrom"
        >
          <AutoCompleteFlight
            {...subpageData}
            error={fromError ?? undefined}
            onChange={onFromChange}
            titleField={departureTitle}
            transparent
            value={from}
            detail={departureSubtitle}
            titleText="departure"
            getInitialPegasusCall={getAirportSubpage}
            saveAction={saveAirport}
            deleteAction={deleteAirport}
          />
        </StyledAutoCompleteItem>
        <StyledSMiddleContainer
          item
          data-name="AutocompleteCenterIcon"
        >
          <FlightIcon />
        </StyledSMiddleContainer>
        <StyledAutoCompleteItem
          data-name="AutocompleteContainerTo"
          item
          lg={12}
          md={12}
          xs={12}
          $isTo={true}
        >
          <AutoCompleteFlight
            {...subpageData}
            error={toError ?? undefined}
            onChange={onToChange}
            titleField={destinationTitle}
            transparent
            value={to}
            detail={destinationSubtitle}
            titleText="arrival"
            getInitialPegasusCall={getAirportSubpage}
            saveAction={saveAirport}
            deleteAction={deleteAirport}
          />
        </StyledAutoCompleteItem>
      </StyledAutoCompleteContainer>
      <StyledSubmitFlightSearch
        // type="button"
        // disabled={disabled}
        styleType={
          button?.styleType ? button.styleType : 'secondary'
        }
        title={button?.title ? button?.title : 'Go'}
        // FIXME: replace when search endpoint is up
        action={{ actionMethod: () => null }}
      />
    </Container>
  );
}
