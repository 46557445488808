import React, { lazy } from 'react';

/**
 * When dynamically importing a component, webpack will create a separate chunk for that component.
 * This means that the component will be loaded only when it's needed.
 * Provide a `webpackChunkName` to give the chunk a name.
 * If the component is likely to be used on the initial load of the app, use `webpackPreload: true`.
 * If the component is likely to be used within the same flow as other components, use `webpackMode: "lazy-once"` and
 *    provide the same `webpackChunkName` as the other components in the flow.
 */

/**
 * Preloaded routes
 * These routes are preloaded on the initial load of the app
 */
const Account = lazy(
  () =>
    import(
      /* webpackChunkName: "account-chunk" */
      /* webpackPreload: true */
      '@features/Account/Account'
    ),
);
const Dashboard = lazy(
  () =>
    import(
      /* webpackChunkName: "dashboard" */
      /* webpackPreload: true */
      '@features/Dashboard/Dashboard'
    ),
);
const Fly = lazy(
  () =>
    import(
      /* webpackChunkName: "fly-flow" */
      /* webpackMode: "lazy-once" */
      /* webpackPreload: true */
      '@features/Fly/Fly'
    ),
);
const LogIn = lazy(
  () =>
    import(
      /* webpackChunkName: "login" */
      /* webpackPreload: true */
      '@features/LogIn/pages/LogIn'
    ),
);
const Members = lazy(
  () =>
    import(
      /* webpackChunkName: "members" */
      /* webpackPreload: true */
      '@features/Members/Members'
    ),
);
const MyTrips = lazy(
  () =>
    import(
      /* webpackChunkName: "my-trips" */
      /* webpackPreload: true */
      '@features/MyTrips/MyTrips'
    ),
);
const News = lazy(
  () =>
    import(
      /* webpackChunkName: "news" */
      /* webpackPreload: true */
      '@features/News/News'
    ),
);
const Signout = lazy(
  () =>
    import(
      /* webpackChunkName: "signout" */
      /* webpackPreload: true */
      '@features/Signout/Signout'
    ),
);
const DeltaSignup = lazy(
  () =>
    import(
      /* webpackChunkName: "delta-signup" */
      /* webpackPreload: true */
      '@features/DeltaSignup/DeltaSignup'
    ),
);

// Not preloaded since they are less likely to be used on first render
const AccountCreation = lazy(
  () =>
    import(
      /* webpackChunkName: "account-creation" */
      '@features/AccountCreation/pages/AccountCreation'
    ),
);
const AccountSubpage = lazy(
  () =>
    import(
      /* webpackChunkName: "account-subpage" */
      '@features/Account/AccountSubpage'
    ),
);
const AddEditPet = lazy(
  () =>
    import(
      /* webpackChunkName: "add-edit-pet" */
      '@features/AddEditPet/AddEditPet'
    ),
);
const AmexEligibility = lazy(
  () =>
    import(
      /* webpackChunkName: "amex-eligibility" */
      '@features/PartnerProgram/AmexEligibility'
    ),
);
const Confirmation = lazy(
  () =>
    import(
      /* webpackChunkName: "fly-flow" */
      /* webpackMode: "lazy-once" */
      '@features/Fly/Confirmation/Confirmation'
    ),
);
const Demo = lazy(
  () =>
    import(
      /* webpackChunkName: "demo" */
      '@features/Demo/Demo'
    ),
);
const FlySearchResults = lazy(
  () =>
    import(
      /* webpackChunkName: "fly-flow" */
      /* webpackMode: "lazy-once" */
      '@features/Fly/FlySearchResults'
    ),
);
const FlySearchTripDetails = lazy(
  () =>
    import(
      /* webpackChunkName: "fly-flow" */
      /* webpackMode: "lazy-once" */
      '@features/Fly/FlySearchTripDetails'
    ),
);
const Hub = lazy(
  () =>
    import(
      /* webpackChunkName: "hub" */
      '@features/Hub/Hub'
    ),
);
const MembersSubpage = lazy(
  () =>
    import(
      /* webpackChunkName: "members-subpage" */
      '@features/Members/MembersSubpage'
    ),
);
const MyTripDetails = lazy(
  () =>
    import(
      /* webpackChunkName: "my-trip-details" */
      '@features/MyTrips/MyTripDetails'
    ),
);
const NewsDetails = lazy(
  () =>
    import(
      /* webpackChunkName: "news-details" */
      '@features/News/NewsDetails'
    ),
);
const Passenger = lazy(
  () =>
    import(
      /* webpackChunkName: "passenger" */
      '@features/Passenger/Passenger'
    ),
);
const PassengerSummary = lazy(
  () =>
    import(
      /* webpackChunkName: "passenger-summary" */
      '@features/Passengers/PassengerSummary'
    ),
);
const PasswordResetConfirmationPage = lazy(
  () =>
    import(
      /* webpackChunkName: "password-reset-confirmation-page" */
      '@features/PasswordReset/pages/web/PasswordResetConfirmationPage/PasswordResetConfirmationPage'
    ),
);
const PasswordResetConfirmationPageMob = lazy(
  () =>
    import(
      /* webpackChunkName: "password-reset-confirmation-page-mob" */
      '@features/PasswordReset/pages/mobile/PasswordResetConfirmationPageMob'
    ),
);
const PasswordResetLandingPage = lazy(
  () =>
    import(
      /* webpackChunkName: "password-reset-landing-page" */
      '@features/PasswordReset/pages/web/PasswordResetLandingPage/PasswordResetLandingPage'
    ),
);
const PasswordSendResetPage = lazy(
  () =>
    import(
      /* webpackChunkName: "password-send-reset-page" */
      '@features/PasswordReset/pages/web/PasswordSendResetPage/PasswordSendResetPage'
    ),
);
const Payment = lazy(
  () =>
    import(
      /* webpackChunkName: "fly-flow" */
      /* webpackMode: "lazy-once" */
      '@features/Fly/Payment/Payment'
    ),
);
const PaymentWebView = lazy(
  () =>
    import(
      /* webpackChunkName: "payment-web-view" */
      '@features/Fly/Payment/PaymentWebView'
    ),
);
const PetSummary = lazy(
  () =>
    import(
      /* webpackChunkName: "pet-summary" */
      '@features/Passengers/PetSummary'
    ),
);
const QuoteRequestedConfirmation = lazy(
  () =>
    import(
      /* webpackChunkName: "fly-flow" */
      /* webpackMode: "lazy-once" */
      '@features/Fly/Confirmation/QuoteRequestedConfirmation'
    ),
);
const SelectPassengers = lazy(
  () =>
    import(
      /* webpackChunkName: "select-passengers" */
      '@features/SelectPassengers/SelectPassengers'
    ),
);
const UpcomingReservationTravelDetails = lazy(
  () =>
    import(
      /* webpackChunkName: "upcoming-reservation-travel-details" */
      '@features/UpcomingReservationTravelDetails/UpcomingReservationTravelDetails'
    ),
);
const DemoSearchByUser = lazy(
  () =>
    import(
      /* webpackChunkName: "demo-search-by-user" */
      '@features/Demo/DemoSearchByUser'
    ),
);
const AmexLanding = lazy(
  () =>
    import(
      /* webpackChunkName: "amex-landing" */
      '@features/PartnerProgram/pages/AmexLanding/AmexLanding'
    ),
);
const FlightDeals = lazy(
  () =>
    import(
      /* webpackChunkName: "fly-flow" */
      /* webpackMode: "lazy-once" */
      '@features/FlightDeals/FlightDeals'
    ),
);
const AccountManagerContact = lazy(
  () =>
    import(
      /* webpackChunkName: "account-manager-contact" */
      '@features/Account/AccountManagerContact/AccountManagerContact'
    ),
);
const ManageLuggage = lazy(
  () =>
    import(
      /* webpackChunkName: "manage-luggage" */
      '@features/ManageLuggage/ManageLuggage'
    ),
);
const ManageLuggagePost = lazy(
  () =>
    import(
      /* webpackChunkName: "manage-luggage-post" */
      '@features/ManageLuggagePost/ManageLuggagePost'
    ),
);
const LimitedTimeDeals = lazy(
  () =>
    import(
      /* webpackChunkName: "limited-time-deals" */
      '@features/LimitedTimeDeals/LimitedTimeDeals'
    ),
);
const StatementsAndActivity = lazy(
  () =>
    import(
      /* webpackChunkName: "statements-and-activity" */
      '@features/StatementsAndActivity/StatementsAndActivity'
    ),
);
const Notifications = lazy(
  () =>
    import(
      /* webpackChunkName: "notifications" */
      '@features/Notifications/Notifications'
    ),
);
const FlightDetail = lazy(
  () =>
    import(
      /* webpackChunkName: "fly-flow" */
      /* webpackMode: "lazy-once" */
      '@features/FlightDetail/FlightDetail'
    ),
);
const ManagePaymentMethods = lazy(
  () =>
    import(
      /* webpackChunkName: "manage-payments" */
      '@features/Account/ManagePaymentMethods/ManagePaymentMethods'
    ),
);
const AddEditCreditCard = lazy(
  () =>
    import(
      /* webpackChunkName: "add-edit-credit-card" */
      '@features/Account/ManagePaymentMethods/AddEditCreditCard/AddEditCreditCard'
    ),
);
const Legal = lazy(
  () =>
    import(
      /* webpackChunkName: "legal" */
      '@features/Account/Legal/Legal'
    ),
);
const AirmedLanding = lazy(
  () =>
    import(
      /* webpackChunkName: "airmed" */
      '@features/AirMed/AirmedLanding'
    ),
);
const AirmedManageIndividuals = lazy(
  () =>
    import(
      /* webpackChunkName: "airmed" */
      '@features/AirMed/AirmedManageIndividuals'
    ),
);
const AirmedAddIndividualsList = lazy(
  () =>
    import(
      /* webpackChunkName: "airmed" */
      '@features/AirMed/AirmedAddIndividualsList'
    ),
);
const AirmedAddIndividualForm = lazy(
  () =>
    import(
      /* webpackChunkName: "airmed" */
      '@features/AirMed/AirmedAddIndividualForm'
    ),
);
const AirmedContactForm = lazy(
  () =>
    import(
      /* webpackChunkName: "airmed" */
      '@features/AirMed/AirmedWebToCaseForm'
    ),
);

const AddPaymentMethod = lazy(
  () =>
    import(
      /* webpackChunkName: "add-payment-method" */
      '@features/AddPaymentMethod/AddPaymentMethod'
    ),
);

const EditPaymentMethod = lazy(
  () =>
    import(
      /* webpackChunkName: "add-payment-method" */
      '@features/EditPaymentMethod/EditPaymentMethod'
    ),
);

const QuoteRequestResults = lazy(
  () =>
    import(
      /* webpackChunkName: "quote-request-details" */
      '@features/QuoteRequestResults/QuoteRequestResults'
    ),
);

import { isMobileDevice } from '@constants/deepLink/deviceConstants';
import { ILinkedRoute } from './routesTypes';
import { FeatureFlags } from '@services/featureFlagService/constants';

const paths: { [key: string]: string } = {
  SIGNIN: '/signin',
  HOME: '/',
  HOME_V1: '/src/client', // Does not have route in router.tsx
  FLY: '/fly',
  FLY_DEALS: '/fly/:dealId',
  FLY_SEARCH_RESULTS: '/fly/results',
  FLY_SEARCH_TRIP_DETAILS: '/fly/search-trip-details',
  FLY_PAYMENT_WV: '/fly/payment-web-view',
  FLY_PAYMENT: '/fly/checkout-flight',
  FLY_CONFIRMATION: '/fly/confirmation',
  QUOTE_REQUESTED_CONFIRMATION:
    '/fly/quote-requested-confirmation',
  MEMBERS: '/members',
  MEMBERS_BENEFIT_DETAILS: '/members/:benefit',
  HUB: '/hub',
  ACCOUNT: '/account',
  ACCOUNT_LEGAL: '/account/legal',
  PASSENGER_SUMMARY: '/passengers/passenger-summary/:id',
  PET_SUMMARY: '/passengers/pet-summary/:id',
  MANAGE_PAYMENTS: '/account/payment-methods',
  ADD_PAYMENT_METHOD: '/account/add-payment-method',
  ADD_PAYMENT_METHOD_FLY: '/checkout/add-payment-method',
  EDIT_PAYMENT_METHOD: '/payment-method/:id',
  ADD_EDIT_CREDIT_CARD:
    '/account/manage-credit-cards/credit-card',
  ACCOUNT_SUBPAGE: '/account/*',
  ACCOUNT_MANAGER_CONTACT: '/account/contact-us',
  ACCOUNT_MANAGE_CREDIT_CARDS:
    '/account/manage-credit-cards',
  ADD_EDIT_PET: '/manage/pet/:id/:leg',
  ADD_PET: '/manage/pet', // Does not have route in router.tsx
  PASSENGER: '/manage/passenger/:id/:leg',
  ADD_PASSENGER: '/manage/passenger', // Does not have route in router.tsx
  PSWD_RESET_LANDING: '/new-password',
  PSWD_RESET: '/reset-password',
  PSWD_RESET_CONFIRMATION: '/reset-confirmation',
  CREATE_ACCOUNT: '/create-account/:id',
  NEWS: '/news',
  NEWS_DETAILS: '/news/:newsId',
  NEWS_DETAILS_IOS: '/newsDetails',
  DEMO: '/demo',
  DEMO_USER_SEARCH: '/demo-user/search-flights',
  SELECT_PASSENGERS: '/select-passengers/:id/:max',
  MANAGE_LUGGAGE: '/manage-luggage/:id/',
  MANAGE_LUGGAGE_POST: '/flight-detail/manage-luggage/:id/',
  MY_TRIPS: '/my-trips',
  MY_TRIPS_DETAILS: '/my-trips/:reservationId',
  SIGNOUT: '/signout',
  UPCOMING_RESERVATION_TRAVEL_DETAILS:
    '/upcoming-details/:id',
  AMEX_ELIGIBILITY: '/partner-program/amex-eligibility',
  AMEX_LANDING: '/partner-program/amex-landing-page',
  // The order for the params in flight-detail is crucial for ISO deeplinking, please do not modify unless it's required by IOS
  FLIGHT_DETAIL:
    '/flight-detail/:tripType/:flightDealId/:reservationType',
  FLIGHT_DEALS: '/flight-deals',
  LIMITED_TIME_DEALS: '/limited-time-deals',
  STATEMENTS_AND_ACTIVITY: '/account/activity/statements',
  NOTIFICATIONS: '/account/communications/notifications',
  AIRMED_LANDING: '/upglobalresponse',
  AIRMED_SIGNUP: '/upglobalresponse/signup',
  AIRMED_PAYMENT: '/upglobalresponse/payment',
  AIRMED_MANAGE_INDIVIDUALS:
    '/upglobalresponse/manage-individuals',
  AIRMED_ADD_INDIVIDUALS:
    '/upglobalresponse/add-individuals',
  AIRMED_ADD_INDIVIDUAL_FORM:
    '/upglobalresponse/add-individual-form',
  AIRMED_WEB_TO_CASE_FORM: '/upglobalresponse/contact-form',
  DELTA_SIGNUP: '/delta-signup',
  QUOTE_REQUEST_DETAILS: '/quote-requests/:id', // TODO: Find out actual path.
};

const MainLayoutRoutes: {
  [key: string]: ILinkedRoute;
} = {
  signin: {
    path: paths.SIGNIN,
    component: LogIn,
    isPrivate: false,
    showFooter: false,
  },
  addPet: {
    path: paths.ADD_PET,
    component: AddEditPet,
  },
  addPassenger: {
    path: paths.ADD_PASSENGER,
    component: Passenger,
  },
  account: {
    path: paths.ACCOUNT,
    component: Account,
  },
  account_subpage: {
    path: paths.ACCOUNT_SUBPAGE,
    component: AccountSubpage,
  },
  passengerSummary: {
    path: paths.PASSENGER_SUMMARY,
    component: PassengerSummary,
  },
  petSummary: {
    path: paths.PET_SUMMARY,
    component: PetSummary,
  },
  home: {
    path: paths.HOME,
    component: Dashboard,
  },
  fly: {
    path: paths.FLY,
    component: Fly,
  },
  flyDeals: {
    path: paths.FLY_DEALS,
    component: Fly,
  },
  flySearchResults: {
    path: paths.FLY_SEARCH_RESULTS,
    component: FlySearchResults,
  },
  flySearchTripDetails: {
    path: paths.FLY_SEARCH_TRIP_DETAILS,
    component: FlySearchTripDetails,
  },
  flyPaymentWV: {
    path: paths.FLY_PAYMENT_WV,
    component: PaymentWebView,
  },
  flyPayment: {
    path: paths.FLY_PAYMENT,
    component: Payment,
  },
  flyConfirmation: {
    path: paths.FLY_CONFIRMATION,
    component: Confirmation,
  },
  demoUserSearch: {
    path: paths.DEMO_USER_SEARCH,
    component: DemoSearchByUser,
  },
  quoteRequestedConfirmation: {
    path: paths.QUOTE_REQUESTED_CONFIRMATION,
    component: QuoteRequestedConfirmation,
  },
  members: {
    path: paths.MEMBERS,
    component: Members,
  },
  members_benefits: {
    path: paths.MEMBERS_BENEFIT_DETAILS,
    component: MembersSubpage,
  },
  news: {
    path: paths.NEWS,
    component: News,
  },
  newsDetails: {
    path: paths.NEWS_DETAILS,
    component: NewsDetails,
  },
  newsDetailsIOS: {
    path: paths.NEWS_DETAILS_IOS,
    component: NewsDetails,
  },
  demo: {
    path: paths.DEMO,
    component: Demo,
  },
  myTrips: {
    path: paths.MY_TRIPS,
    component: MyTrips,
  },
  passenger: {
    path: paths.PASSENGER,
    component: Passenger,
  },
  addEditPet: {
    path: paths.ADD_EDIT_PET,
    component: AddEditPet,
  },
  myTripsDetails: {
    path: paths.MY_TRIPS_DETAILS,
    component: MyTripDetails,
  },
  signout: {
    path: paths.SIGNOUT,
    component: Signout,
  },
  selectPassengers: {
    path: paths.SELECT_PASSENGERS,
    component: SelectPassengers,
  },
  manageLuggage: {
    path: paths.MANAGE_LUGGAGE,
    component: ManageLuggage,
  },
  manageLuggagePost: {
    path: paths.MANAGE_LUGGAGE_POST,
    component: ManageLuggagePost,
  },
  upcomingReservationTravelDetails: {
    path: paths.UPCOMING_RESERVATION_TRAVEL_DETAILS,
    component: UpcomingReservationTravelDetails,
  },
  hub: {
    path: paths.HUB,
    component: Hub,
    showHeader: false,
    showFooter: false,
  },
  pswdReset: {
    path: paths.PSWD_RESET,
    component: PasswordSendResetPage,
    isPrivate: false,
    showFooter: false,
  },
  pswdResetConfirmation: {
    path: paths.PSWD_RESET_CONFIRMATION,
    component: isMobileDevice
      ? PasswordResetConfirmationPageMob
      : PasswordResetConfirmationPage,
    isPrivate: false,
    showFooter: false,
  },
  createAccount: {
    path: paths.CREATE_ACCOUNT,
    component: AccountCreation,
    isPrivate: false,
    showFooter: false,
  },
  pswdResetLanding: {
    path: paths.PSWD_RESET_LANDING,
    component: PasswordResetLandingPage,
    isPrivate: false,
    showFooter: false,
  },
  amexEligibility: {
    path: paths.AMEX_ELIGIBILITY,
    component: AmexEligibility,
    showHeader: false,
    isPrivate: false,
  },
  amexLanding: {
    path: paths.AMEX_LANDING,
    component: AmexLanding as React.LazyExoticComponent<
      () => JSX.Element
    >,
    isPrivate: false,
  },
  flightDeals: {
    path: paths.FLIGHT_DEALS,
    component: FlightDeals,
    isPrivate: true,
  },
  flightDetail: {
    path: paths.FLIGHT_DETAIL,
    component: FlightDetail,
    isPrivate: true,
  },
  airmedLanding: {
    path: paths.AIRMED_LANDING,
    component: AirmedLanding,
    isPrivate: false,
  },
  airmedManageIndividuals: {
    path: paths.AIRMED_MANAGE_INDIVIDUALS,
    component: AirmedManageIndividuals,
    isPrivate: false,
  },
  airmedAddIndividuals: {
    path: paths.AIRMED_ADD_INDIVIDUALS,
    component: AirmedAddIndividualsList,
    isPrivate: false,
  },
  airmedAddIndividualForm: {
    path: paths.AIRMED_ADD_INDIVIDUAL_FORM,
    component: AirmedAddIndividualForm,
    isPrivate: false,
  },
  airmedContactForm: {
    path: paths.AIRMED_WEB_TO_CASE_FORM,
    component: AirmedContactForm,
    isPrivate: false,
  },
  limitedTimeDeals: {
    path: paths.LIMITED_TIME_DEALS,
    component: LimitedTimeDeals,
    isPrivate: true,
  },
  accountManagerContact: {
    path: paths.ACCOUNT_MANAGER_CONTACT,
    component: AccountManagerContact,
    isPrivate: true,
  },
  accountManageCreditCards: {
    path: paths.ACCOUNT_MANAGE_CREDIT_CARDS,
    component: ManagePaymentMethods,
    isPrivate: true,
  },
  statementsActivity: {
    path: paths.STATEMENTS_AND_ACTIVITY,
    component: StatementsAndActivity,
    isPrivate: true,
  },
  notifications: {
    path: paths.NOTIFICATIONS,
    component: Notifications,
    isPrivate: true,
    flag: FeatureFlags.USE_NATIVE_NOTIFICATIONS,
  },
  addEditCc: {
    path: paths.ADD_EDIT_CREDIT_CARD,
    component: AddEditCreditCard,
    isPrivate: true,
  },
  deltaSignup: {
    path: paths.DELTA_SIGNUP,
    component: DeltaSignup,
    isPrivate: false,
  },

  // All routes with flags should be here
  // This includes the true and false value of the flag

  // start legal flag
  nativeLegal: {
    path: paths.ACCOUNT_LEGAL,
    component: Legal,
    isPrivate: true,
  },
  // end legal flag

  // start manage payment method flag
  nativeManagePaymentMethods: {
    path: paths.MANAGE_PAYMENTS,
    component: ManagePaymentMethods,
    isPrivate: true,
    flag: FeatureFlags.USE_NATIVE_MANAGE_PAYMENT_METHODS,
  },
  addPaymentMethod: {
    path: paths.ADD_PAYMENT_METHOD,
    component: AddPaymentMethod,
    isPrivate: true,
    flag: FeatureFlags.USE_NATIVE_MANAGE_PAYMENT_METHODS,
  },
  addPaymentMethodFly: {
    path: paths.ADD_PAYMENT_METHOD_FLY,
    component: AddPaymentMethod,
    isPrivate: true,
    flag: FeatureFlags.USE_NATIVE_MANAGE_PAYMENT_METHODS,
  },
  editPaymentMethod: {
    path: paths.EDIT_PAYMENT_METHOD,
    component: EditPaymentMethod,
    isPrivate: true,
    flag: FeatureFlags.USE_NATIVE_MANAGE_PAYMENT_METHODS,
  },
  v1Cc: {
    path: paths.ACCOUNT_MANAGE_CREDIT_CARDS,
    component: AccountSubpage,
    isPrivate: true,
    flag: FeatureFlags.USE_NATIVE_MANAGE_PAYMENT_METHODS,
  },
  // end manage payment method flag

  quoteRequestDetails: {
    path: paths.QUOTE_REQUEST_DETAILS,
    component: QuoteRequestResults,
    isPrivate: true,
    flag: FeatureFlags.USE_NATIVE_MY_TRIPS,
  },
};

export { paths, MainLayoutRoutes };
