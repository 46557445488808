import styled from 'styled-components';

export const HeaderContainer = styled.div<{
  padding?: string;
  paddingTop?: string;
  marginLeft?: string;
}>`
  display: flex;
  justify-content: start;
  padding: ${({ padding }) => padding ?? '20px'};
  padding-top: ${({ paddingTop }) => paddingTop ?? '0px'};
  margin-left: ${({ marginLeft }) => marginLeft ?? '0px'};
`;

export const Title = styled.div<{
  align?: string;
}>`
  display: flex;
  padding-left: 15px;
  justify-content: ${({ align }) => align ?? 'center'};
  align-items: center;
  width: 100%;
`;

export const BackButtonContainer = styled.div<{
  isSearchResultsPage?: boolean;
}>`
    // this is an actual nightmare
    ${(props) => props.theme.breakpoints.down('md')} {
        ${({ isSearchResultsPage }) =>
          `
                position: ${
                  isSearchResultsPage
                    ? 'absolute'
                    : 'static'
                };
                top: ${isSearchResultsPage ? '87px' : '0px'}
            `}
`;
