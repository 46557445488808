import {
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import {
  ICardSnackBarArgs,
  ICardSnackBarState,
} from './CardSnackbar.interface';

export const initialState: ICardSnackBarState = {
  isVisible: false,
  snackBarCopy: 'true',
};

export const cardSnackBarSlice = createSlice({
  name: 'cardSnackBar',
  initialState,
  reducers: {
    openSnackbar: (
      state,
      action: PayloadAction<ICardSnackBarArgs>,
    ) => {
      const {
        snackBarCopy,
        inApp,
        timeoutSeconds,
        styleType,
      } = action.payload;
      state.isVisible = true;
      state.snackBarCopy = snackBarCopy;
      state.inApp = inApp;
      state.timeoutSeconds = timeoutSeconds;
      state.styleType = styleType;
    },
    closeSnackbar: (state) => {
      state.isVisible = false;
      state.snackBarCopy = '';
    },
  },
});

export const {
  openSnackbar,
  closeSnackbar,
} = cardSnackBarSlice.actions;

export const CardSnackBarReducer =
  cardSnackBarSlice.reducer;

export default CardSnackBarReducer;
