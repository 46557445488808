import styled from 'styled-components';
import { Container } from '@library';
import { solidColors } from '@constants/styles/colors.constants';

export const HeaderWrapper = styled.header<{
  openMenu: boolean;
  $isSticky: boolean;
  $hasImage: boolean;
}>`
  @keyframes colorChange {
    0% {
      background-color: transparent;
    }
    100% {
      background-color: ${solidColors.midnight.color};
    }
  }

  width: 100%;
  padding: 34px 0;
  position: fixed;
  transition: background-color 0.5s ease-in-out;
  z-index: 12;
  background-color: transparent;
  ${({ $hasImage }) => !$hasImage && 'height: 114px;'}

  &.sticky {
    background-color: ${solidColors.midnight.color};
    box-shadow: 0 1px 7.5px 0 rgba(0, 0, 0, 0.05);
  }

  &.blueBackground {
    background-color: ${solidColors.midnight.color};
    box-shadow: 0 1px 7.5px 0 rgba(0, 0, 0, 0.05);
    height: 114px;
  }

  ${(props) => props.theme.breakpoints.down('md')} {
    animation: ${({ $isSticky }) =>
      !$isSticky && 'colorChange 1s ease-in-out'};
    background-color: ${solidColors.midnight.color};
    box-shadow: 0 1px 7.5px 0 rgba(0, 0, 0, 0.05);
    height: 114px;
  }
`;

export const ContainerWrapper = styled(Container)`
  display: flex;
  align-items: center;
  max-width: 1150px;
  height: 100%;
`;

export const LinkLogo = styled.a<{
  $isBlue?: boolean;
}>`
  display: flex;
  align-items: center;
  position: relative;
  width: 10.875rem;
  margin-right: 6vw;

  &:after {
    content: '';
    width: 0.125rem;
    height: 2rem;
    background-color: ${(props) =>
      props.$isBlue
        ? props.theme.palette.primary.main
        : props.theme.palette.white.main};
    position: absolute;
    right: calc(-3vw + 0.0625rem);
    top: calc(50% - 1rem);
    pointer-events: none;
  }

  .logo {
    height: 20px;
    border-radius: 0;
  }

  &:hover {
    opacity: 0.7;
  }

  ${(props) => props.theme.breakpoints.down('sm')} {
    &:after {
      content: none;
    }
  }
`;
