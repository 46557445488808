import { IFlightLegs } from '@features/Fly/searchFlightSlice';
import {
  Currency,
  DatePrice,
} from '@graphql/generated/graphql';
import {
  parseDate,
  endOfMonth,
  getLocalTimeZone,
  CalendarDate,
} from '@internationalized/date';
import { QueryClient } from '@tanstack/react-query';
import { format } from 'date-fns';
import { DateTime } from 'luxon';

export const compareFlightLegDates = (
  date: Date,
  index: number,
  legsData: IFlightLegs,
) => {
  let status = false;
  let level = 'default';
  let legIndex = 0;

  const top: Date[] = [];
  const bottom: Date[] = [];

  for (const [key, value] of Object.entries(
    legsData.checkDate,
  )) {
    if (index > Number(key)) {
      top.push(new Date(String(value)));
    } else {
      if (index != Number(key)) {
        bottom.push(new Date(String(value)));
      }
    }

    top.map((topDate, idx) => {
      if (date < topDate) {
        status = true;
        level = 'top';
        legIndex = idx;
      }
    });

    bottom.map((bottomDate, idx) => {
      if (date > bottomDate) {
        status = true;
        level = 'bottom';
        legIndex = idx;
      }
    });
  }

  return { status, level, legIndex };
};

export const disabledDate = (
  legType: any,
  legNumber: any,
  flightTripType: any,
  legsData: any,
) => {
  if (legType == 'Return') {
    return legNumber == 1
      ? new Date(legsData.legs[0].date)
      : new Date();
  }

  if (flightTripType == 'multi-leg') {
    if (legNumber !== 0) {
      return new Date(legsData.legs[legNumber - 1].date);
    }
  }

  return new Date();
};

export const endOfNextMonth = (input: Date): Date => {
  return DateTime.fromJSDate(input)
    .plus({ month: 1 })
    .endOf('month')
    .toJSDate();
};

export const startOfCurrentMonth = (input: Date): Date => {
  return new Date(input.getFullYear(), input.getMonth(), 1);
};

export const getCalendarDate = (date: Date) => {
  return new CalendarDate(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
  );
};

export const daysBetween = (
  startDate: Date,
  endDate: Date,
): number => {
  try {
    const parsedStart = parseDate(
      startDate.toISOString().split('T')[0],
    );
    const parsedEnd = parseDate(
      endDate.toISOString().split('T')[0],
    );

    return parsedStart.compare(parsedEnd);
  } catch (e) {
    return -1;
  }
};

export const cheapDayHighlightingThresholds = (
  dates: DatePrice[] | undefined,
) => {
  const datesByMonth: { [key: string]: DatePrice[] } = {};
  const dateSet = new Set<string>();

  dates &&
    dates.forEach((item) => {
      // partition each date in to collections by month
      const date = new Date(item.date);
      const month = date.getUTCMonth();

      if (!datesByMonth[month]) {
        datesByMonth[month] = [];
      }

      datesByMonth[month].push(item);
    });

  for (const key in datesByMonth) {
    // go through each unique month, sort by price, pick bottom third plus ties
    const monthPrices = datesByMonth[key];

    if (monthPrices.length < 3) continue; // if there are less than 3 days left in the month or we have less than 3 prices available, don't highlight any of them

    monthPrices.sort(
      (a, b) =>
        (a.price ? a.price.amount : 0) -
        (b.price ? b.price.amount : 0),
    );

    const bottomThirdIndex = Math.floor(
      (monthPrices.length - 1) / 3,
    ); // the index where the bottom third ends

    const targetPrice =
      monthPrices[bottomThirdIndex].price?.amount;

    // If multiple days return the same price and if that price is exactly the same as the top 1/3 it also receives the style treatment. For example, if the values are 7050,7050,8532: Since there are 3 values, the first is green since that’s 1/3. However, since there is another value above the 1/3 point that is exactly the same, the 2nd 7050 is also green. Reversing the sort allows us to find the last index of the target price, not the first.
    const firstIndexOfTargetPrice = monthPrices
      .reverse()
      .findIndex(
        (item) => item.price?.amount === targetPrice,
      ); // had to use findIndex instead of findLastIndex because Typescript complained about findLastIndex

    monthPrices // the first third + ties of the sorted prices are the ones we want to highlight
      .slice(firstIndexOfTargetPrice)
      .map((item) => dateSet.add(item.date));
  }

  return dateSet; // return a set for quick look up while iterating through each calendar day
};

export type CalendarDateWithPrice = {
  date: Date;
  price: Currency;
  isCheap: boolean;
};

// everywhere that uses the map of CalendarDateWithPrice objects needs to use this function to
export const getPricingDataKeyForDate = (
  date: Date,
): string => {
  return format(date, 'yyyy-MM-dd');
};

export const restructureDatePricesAndHighlightCheapest = (
  dates: DatePrice[] | undefined,
) => {
  const cheapDates = cheapDayHighlightingThresholds(dates);
  const calendarValues = new Map<
    string,
    CalendarDateWithPrice
  >();

  dates?.forEach((date) => {
    // this is neccesary because date.date is 2020-01-01 and if you run
    // new Date(date.date), it goes back a day.
    const inputDate = DateTime.fromFormat(
      date.date,
      'yyyy-MM-dd',
    ).toJSDate();
    const dateObj = getPricingDataKeyForDate(inputDate);

    calendarValues.set(dateObj, {
      date: inputDate, // not totally necessary but didn't want to just rely on the map key
      price: date.price as Currency,
      isCheap: cheapDates.has(dateObj),
    });
  });

  return calendarValues;
};

/* below is the algorithm that Product wants to use in a fast follow release. saving here for when we do that. for the first release we need to use the algorithm above to match iOS.

export const cheapDayHighlightingThresholds = (
    dates: DatePrice[] | undefined,
) => {
    const datesByMonth: { [key: string]: DatePrice[] } = {};
    const dateMap = new Set<string>();

    dates &&
        dates.forEach((item) => {
            // partition each date in to collections by month
            const date = new Date(item.date);
            const month = date.getUTCMonth();

            if (!datesByMonth[month]) {
                datesByMonth[month] = [];
            }

            datesByMonth[month].push(item);
        });

    for (const key in datesByMonth) {
        // go through each unique month, sort by price, pick bottom third plus ties
        const monthPrices = datesByMonth[key];

        if (monthPrices.length < 3) continue; // if there are less than 3 days left in the month or we have less than 3 prices available, don't highlight any of them

        monthPrices.sort(
            (a, b) =>
                (a.price ? a.price.amount : 0) -
                (b.price ? b.price.amount : 0),
        );

        const bottomThirdIndex =
            monthPrices.length -
            Math.floor(monthPrices.length / 3);

        const targetPrice =
            monthPrices[bottomThirdIndex].price?.amount;

        // If multiple days return the same price and if that price is exactly the same as the top 1/3 it also receives the style treatment. For example, if the values are 7050,7050,8532: Since there are 3 values, the first is green since that’s 1/3. However, since there is another value above the 1/3 point that is exactly the same, the 2nd 7050 is also green. This finds the last index of the target price
        const lastIndexForTargetPrice = monthPrices.findIndex(
            (item) => item.price?.amount === targetPrice,
        ); // had to use findIndex instead of findLastIndex because Typescript complained about

        monthPrices // the first third + ties of the sorted prices are the ones we want to highlight
            .slice(0, lastIndexForTargetPrice)
            .map((item) => dateMap.add(item.date));
    }
    return dateMap; // return a map for quick look up while iterating through each calendar day
};
*/
