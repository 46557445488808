import { AppDispatch, RootState } from '@app/store';
import {
  getFromLocalStorage,
  getFromSessionStorage,
  removeFromLocalStorage,
} from '@components/utils/storage';
import {
  cacheEndpoints,
  cachePageEndpoints,
} from '../constants';
import { AxiosError } from 'axios';
import { retryHandler } from './retryHandler';

interface I304 {
  storeGetState: RootState;
  storeDispatch: AppDispatch;
  error: AxiosError;
}

export default function handle304({
  storeGetState,
  storeDispatch,
  error,
}: I304) {
  {
    /*
        Helper function responsible for handling
        '304 not modified' code from pegasus
    */
  }
  const cacheUrl =
    error.response?.config.url?.split('?')[0] ?? '';
  if (
    cachePageEndpoints.has(cacheUrl) ||
    cacheEndpoints.has(cacheUrl)
  ) {
    // need logic for if there isn't data but has eTag
    const data = getFromLocalStorage(cacheUrl);
    if (data.data) {
      return data;
    } else {
      removeFromLocalStorage(cacheUrl);
      const retryParams = getFromSessionStorage(
        'last_call',
      );
      retryHandler({
        ...retryParams,
        storeGetState: storeGetState,
        storeDispatch: storeDispatch,
      });
    }
  }
  return;
}
