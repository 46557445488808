import { Typography } from '@atoms';
import styled from 'styled-components';

export const StyledCard = styled.div`
    padding-left: 160px;
    padding-right: 160px
    margin-top: 60px;
    padding-top: 60px;
    padding-bottom: 40px;
    background: #FFFFFF;
    margin: 0 0 16px;
    border-radius: 4px;
    position: relative;
`;

export const StyledSubPageCreditCardForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  border-radius: 4px;
  background-color: #ffffff;
  position: relative;
  height: fit-content;
  min-width: 50%;
  margin: 0px 0px 20px 0px;
`;

export const StyledLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  width: 100%;

  margin: 20px 0px;

  > * {
    margin: 0px 15px;
    width: 100%;

    > .MuiTextField-root {
      width: 100%;
    }
  }
`;

export const StyledLineItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;

  > * {
    width: 45%;
    min-width: 45%;
    max-width: 45%;
  }
`;

export const SwitchContainer = styled.div`
  width: 100%;
  max-width: 300px;
  display: flex;
  justify-content: space-between;
  align: center;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
`;

export const SwitchTypography = styled(Typography)`
  margin: auto 0;
`;
