import { solidColors } from '@constants/styles/colors.constants';
import styled from 'styled-components';
import { Container, Icons } from '@library';

export const WrapperContainer = styled.div<{
  background: string;
}>`
  background-color: ${({ background }) =>
    background === 'white'
      ? solidColors.upWhite.color
      : solidColors.midnight.color};
  height: 730px;

  ${(props) => props.theme.breakpoints.down('md')} {
    padding: 3.75rem 0;
  }
`;

export const IconsStyled = styled(Icons.ArrowForward)`
  color: ${solidColors.upBlue.color};
`;

export const StyledContainer = styled(Container)`
  display: flex;

  ${(props) => props.theme.breakpoints.down('md')} {
    display: block;
  }
  padding: 4rem 0;
`;

export const StyledInfo = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px 0 32px 0;
  z-index: 10;

  .headline {
    margin-bottom: 48px;
  }

  .subCopy {
    flex-grow: 1;
  }

  ${(props) => props.theme.breakpoints.down('md')} {
    width: 100%;
    padding: 20px 0 50px;

    .subCopy {
      margin-bottom: 40px;
    }
  }
`;

export const StyledMedia = styled.div`
  width: 50%;
  padding-left: 15px;
  height: 100%;

  ${(props) => props.theme.breakpoints.down('md')} {
    width: 100%;
    padding: 0;
  }
`;

export const CardWrapper = styled.div`
  width: 100%;
  min-height: 470px;
  border-radius: 4px;
  background: ${solidColors.upWhite.color};
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);
`;
