import { IPet } from '@components/types';
import {
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

export interface ISubPagePetAddEditState {
  id?: string;
  name: string;
  weight?: number | string;
  age?: number;
  petType: string;
  isServiceAnimal?: boolean;
  isHypoallergenic?: boolean;
  isGoodWithDogs?: boolean;
  isGoodWithCats?: boolean;
  isGoodWithKids?: boolean;
  isFavorite?: boolean;
  petTypeDescription?: string;
}

const initialState: ISubPagePetAddEditState = {
  name: '',
  weight: '',
  petType: '',
};

const SubPagePetAddEditSlice = createSlice({
  name: 'subPagePetAddEditSlice',
  initialState,
  reducers: {
    setPet: (state, action: PayloadAction<IPet>) => {
      const pet = action.payload;
      const {
        id,
        name,
        age,
        weight,
        petType,
        isServiceAnimal,
        isHypoallergenic,
        isGoodWithCats,
        isGoodWithKids,
        isGoodWithDogs,
        isFavorite,
        petTypeDescription,
      } = pet;
      state.id = id;
      state.name = name;
      state.age = age;
      state.weight = weight?.toString() ?? '0';
      state.petType = petType;
      state.isServiceAnimal = isServiceAnimal;
      state.isHypoallergenic = isHypoallergenic;
      state.isGoodWithCats = isGoodWithCats;
      state.isGoodWithKids = isGoodWithKids;
      state.isGoodWithDogs = isGoodWithDogs;
      state.isFavorite = isFavorite;
      state.petTypeDescription = petTypeDescription;
    },
    setInputValue: (
      state,
      action: PayloadAction<{
        key: string;
        value: string | boolean | number;
      }>,
    ) => {
      const { key, value } = action.payload;
      const accessKey = key as keyof typeof initialState;
      state[accessKey] = value as never;
    },
    cleanPetState: () => initialState,
  },
});

export const {
  setInputValue,
  setPet,
  cleanPetState,
} = SubPagePetAddEditSlice.actions;

export default SubPagePetAddEditSlice.reducer;
