import { Typography } from '@wheelsup/wu-react-components';
import { ButtonContainer, Directions, Fbo } from './CardFboInfo.styles';
import { Button } from '@atoms';
import handleGeoLocationError from './GeoLocationErrorHander';
import { IFBO } from '@components/types';

const FboSection = ({
  displayFullValue1,
  address1,
  address2,
  city,
  state,
  postalCode,
  phone,
}: IFBO) => {
  const BUTTON_SPACE_HEIGHT = '.75rem';
  const canGeoLocate = !!navigator.geolocation;
  canGeoLocate && navigator.geolocation.getCurrentPosition(() => console.log('Geolocation access granted'));

  const handleDirectionsClick = () => {
    if (canGeoLocate) {
      navigator.geolocation.getCurrentPosition(
        // Success callback
        (position) => {
          const { latitude, longitude } = position.coords;
          const destination = `${address1} ${address2}, ${city}, ${state} ${postalCode}`;
          const encodedDestination = encodeURIComponent(destination);
          const mapsUrl = `https://www.google.com/maps/dir/?api=1&origin=${latitude},${longitude}&destination=${encodedDestination}`;
          window.open(mapsUrl, '_blank');
        },
        // Error callback
        (error) => {
          handleGeoLocationError(error);
        }
      );
    }
  };

  const handlePhoneClick = () => {
    window.open(`tel:${phone ?? ''}`, '_self');
  };

  return (
    <Fbo>
      <Typography
        variant='heading04'
        color='primary'
      >
        {displayFullValue1}
      </Typography>
      {/* start spacer */}
      <div style={{ height: '.375rem' }} />
      {/* end spacer */}
      <Typography
        variant='body01'
        color='secondary'
      >
        {address1}
      </Typography>
      <Typography
        variant='body01'
        color='secondary'
      >
        {address2}
      </Typography>
      <Typography
        variant='body01'
        color='secondary'
      >
        {city}, {state} {postalCode}
      </Typography>
      {/* start spacer */}
      <div style={{ height: BUTTON_SPACE_HEIGHT }} />
      {/* end spacer */}
      <ButtonContainer>
        <Directions
          datatestid={'directions-button'}
          styleType='icon_no_padding'
          textAlign='left'
          title='directions'
          action={{
            actionMethod: handleDirectionsClick
          }}
        />
        {phone && 
          <>
            {/* start spacer */}
            <div style={{ height: BUTTON_SPACE_HEIGHT }} />
            {/* end spacer */}
            <Button
              datatestid={'phone-button'}
              styleType='icon_no_padding'
              textAlign='left'
              title={phone ?? ''}
              action={{
                actionMethod: handlePhoneClick
              }}
            />
          </>
        }
      </ButtonContainer>
    </Fbo>
  );
};

export default FboSection;