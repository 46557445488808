import { ActionMap, MainTypes } from './reducerUtils';

export type User = {
  name: string;
  nickname: string;
};

type UserPayload = {
  [MainTypes.Update]: {
    name: string;
    nickname: string;
  };
};

export type UserActions = ActionMap<UserPayload>[keyof ActionMap<UserPayload>];

export const userReducer = (
  state: User,
  action: UserActions,
) => {
  const actions = {
    [MainTypes.Update]: {
      ...state,
      name: action.payload.name,
      nickname: action.payload.nickname,
    },
  };

  return actions[action.type];
};
