import NumberFormat from 'react-number-format';
import { NumberFormatCustomProps } from './PhoneNumberMask.interfaces';

export default function PhoneNumberMask({
  inputRef,
  name,
  onChange,
  ...rest
}: NumberFormatCustomProps) {
  return (
    <NumberFormat
      {...rest}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name,
            value: values.value,
          },
        });
      }}
      format="(###) ###-####"
    />
  );
}
