import { CalendarDateType, IDateDecoration } from '@components/types';
import React from 'react';
import { decorationMap } from './helpers';
import { IconType } from '@wheelsup/wu-react-components/types/design-system/icons/IconDefs';
import { DateDecorationIcon } from '../../SubPageDatePicker.style';

interface Props {
  decoration: CalendarDateType;
}

const DateDecoration: React.FC<Props> = ({
  decoration,
}) => {
  const mappedIcon = decorationMap[decoration];

  return (
    <DateDecorationIcon
      color={mappedIcon.color}
      name={mappedIcon.icon as IconType}
      size="xs"
      className={mappedIcon.position}
    />
  );
};

export default DateDecoration;
