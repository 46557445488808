import { BaseButton, ImageImgix } from '@atoms';
import TabArrow from '@atoms/TabArrow';
import { typography } from '@constants/styles/typography.constants';
import { Tab, Tabs as MuiTabs } from '@library';
import { a11yProps } from './Tabs.handlers';
import { ITabs } from './Tabs.interfaces';
import {
  useTabsStyles,
  useTabStyles,
  StyledTypography,
  StyledContainer,
} from './Tabs.styles';

export default function Tabs({
  current,
  handleChange,
  items,
}: ITabs) {
  const classes = useTabsStyles();
  const tabClasses = useTabStyles();
  const { title, button } = items[current ?? 0];

  return (
    <StyledContainer>
      <MuiTabs
        value={current}
        onChange={handleChange}
        classes={classes}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable tab"
        centered={false}
        ScrollButtonComponent={TabArrow}
      >
        {items.map((element, index) => (
          <Tab
            label={
              <StyledTypography
                variant={
                  element.title === title
                    ? typography.tabLabelBoldLarge
                    : typography.tabLabelLarge
                }
              >
                {element.title}
              </StyledTypography>
            }
            classes={tabClasses}
            key={element.title}
            {...a11yProps(index)}
          />
        ))}
      </MuiTabs>
      {button && (
        <BaseButton data-testid={'tabs-button'}>
          {button.image && (
            <ImageImgix
              src={button.image.url}
              alt={'Tab CTA Icon'}
            />
          )}
          {button.title}
        </BaseButton>
      )}
    </StyledContainer>
  );
}
