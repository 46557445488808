import styled from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  max-width: 175px;

  > div:first-child {
    user-select: none;
    pointer-events: none;
  }
`;

export const StepperWrapper = styled.div`
  margin-top: 16px;
  display: flex;
`;
