import { IListItem } from './AirportSearchListItem.interfaces';
import {
  StyledAirportItem,
  Airport,
  StyledAirportContainer,
  DensityText,
  ItemContainer,
  IconGridItem,
  AirportCode,
  IconContainer,
  ConditionalText,
  HomeIconButton,
  SavedIconButton,
  DeleteIconButton,
  HeartBorder,
  HeartFill,
  AirportCity
} from './AirportSearchListItem.styles';
import { ImageImgix } from '@atoms';
import React, { useEffect, useRef, useState } from 'react';
import { Elevation } from '@wheelsup/wu-react-components';
import styled from 'styled-components';

const StyledElevation = styled(Elevation)`
  width: 100%;
`;

export default function AirportSearchListItem({
  id,
  code,
  name,
  city,
  state,
  country,
  isHomeAirport,
  isHighDensity,
  highDensityCopy,
  isConditional,
  conditionalCopy,
  homeImage,
  pinImage,
  deleteImage,
  isSaved,
  saveAction,
  deleteAction,
}: IListItem) {
  const handleSave = (_event: React.MouseEvent) => {
    _event.stopPropagation();
    saveAction(_event, id);
  };

  const handleDelete = (_event: React.MouseEvent) => {
    _event.stopPropagation();
    deleteAction(_event, id);
  };

  return (
    <StyledElevation
      padding={16}
      variant="raisedLevel2"
    >
      <StyledAirportContainer>
        {isHomeAirport ? (
          <HomeIconButton $imageUrl={homeImage.url}>
            <ImageImgix
              src={homeImage.url}
              alt="home image"
              height={20}
              width={20}
              filter={homeImage.tintColor}
            />
          </HomeIconButton>
        ) : null}

        <Airport variant="heading04Small">
          {name}
        </Airport>

        <IconGridItem
          item
          xs
        >
          {!isHomeAirport &&
            (!isSaved ? (
              <IconContainer>
                <SavedIconButton onClick={handleSave}>
                  <HeartBorder />
                </SavedIconButton>
              </IconContainer>
            ) : (
              <IconContainer onClick={handleDelete}>
                <DeleteIconButton>
                  <HeartFill />
                </DeleteIconButton>
              </IconContainer>
            ))}
        </IconGridItem>
      </StyledAirportContainer>
      <StyledAirportContainer>
        <AirportCode variant="body02">
          {code}
        </AirportCode>
      </StyledAirportContainer>
      <StyledAirportContainer>
        <AirportCity
          variant="body02"
          color="secondary"
        >
          {`${city}${
            state !== undefined ? `, ${state}` : ''
          }${
            country !== 'US' && country !== undefined
              ? `, ${country}`
              : ''
          }`}
        </AirportCity>
      </StyledAirportContainer>
      <StyledAirportContainer>
        {isHighDensity ? (
          <DensityText
            variant="body02"
            color="warning"
          >
            {highDensityCopy}
          </DensityText>
        ) : null}
        {isConditional ? (
          <ConditionalText
            variant="body02"
            color="warning"
          >
            {conditionalCopy}
          </ConditionalText>
        ) : null}
      </StyledAirportContainer>
    </StyledElevation>
  );
}
