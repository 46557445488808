import { solidColors } from '../../../constants/styles/colors.constants';
import { makeStyles } from '../../library';

export const useTextGroupBodyCopyLink = makeStyles({
  link: {
    fontWeight: 600,
    color: `${solidColors.upBlue.color}`,
    textDecoration: 'none',
  },
});
