import { Button, ImageImgix } from '@atoms';
import { solidColors } from '@constants/styles/colors.constants';
import styled from 'styled-components';

export const StyledSubPageManageIndividuals = styled.div<{
  padding?: string;
}>`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    max-width: 1136px;
    border-radius: 4px;
    background-color: ${solidColors.upWhite.color}};
    position: relative;
    height: fit-content;
    padding: 18px 24px 0px;
    padding-top: ${({ padding }) => padding ?? 'auto'};
    margin: auto;
`;

export const StyledIndividualList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: baseline;
  padding: 10px 0px 60px;
`;

export const StyledIndividualItem = styled.div`
  flex: 1 0 47%;
  max-width: 47%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 30px;
  margin-right: 20px;
  margin-bottom: 40px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
`;

export const ProfileImageContainer = styled.div`
  width: 15%;
`;

export const IndividualInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px;
  width: 85%;
`;

export const AvatarStyled = styled(ImageImgix)`
  margin-right: 10px;
`;

export const StyledButton = styled(Button)`
  width: 200px;
`;

export const StyledSubPageHeader = styled.div`
  margin-bottom: 30px;
`;

export const StyledSubPageBody = styled.div`
  padding-bottom: 20px;
  margin-bottom: 40px;
  border-bottom: 1px solid ${solidColors.mediumGray.color};
`;

export const StyledSubPageFooter = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: flex-end;
  width: 100%;

  button {
    margin-left: 10px;
  }
`;
