import { useAppDispatch } from '@app/hooks';
import { ImageImgix } from '@atoms';
import { saveDataInLocalStorage } from '@components/utils/storage';
import { solidColors } from '@constants/styles/colors.constants';
import { typography } from '@constants/styles/typography.constants';
import { setAccountSubpage } from '@features/Account/AccountSubpageSlice';
import { checkIsTokenValid } from '@services/authService/authService';
import { IAnalytics } from '@services/dynamicRenderingService/dynamicRendering.interfaces';
import { trackingTitles } from '@services/mixpanelService/constants';
import { trackAnalytics } from '@services/mixpanelService/mixpanel.service';
import { useNavigate } from 'react-router-dom';
import {
  ACCOUNT_SUBPAGE_WEBVIEW_KEY,
  ACCOUNT_SUBPAGE_WEBVIEW_TITLE,
} from '../ListCTAIconHeadline/ListCTAIconHeadline';
import { ICreditCard } from './CreditCard.interfaces';
import {
  CCElementContainer,
  CreditCardContainer,
  CCElementsSection,
  CCElementVerticalContainer,
  IconContainer,
} from './CreditCard.styles';
import {
  Icon,
  Typography,
} from '@wheelsup/wu-react-components';
import { FeatureFlags } from '@services/featureFlagService/constants';
import { useFeatureFlag } from '@services/featureFlagService/featureFlagService.services';

export default function CreditCard({
  id,
  expiration,
  isExpired,
  isPrimaryCard,
  lastFourDigits,
  image,
  isFirst = false,
  isLast = false,
  cardType,
}: ICreditCard) {
  const nativeCcFlag = useFeatureFlag(
    FeatureFlags.NATIVE_MANAGE_CREDIT_CARDS,
  );
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleAnalytics = () =>
    trackAnalytics({
      trackingTitle:
        trackingTitles.Select_ManageCreditCard_ListItem,
      properties: [
        {
          key: 'EnvironmentVersion',
          value: 'V2',
        },
      ],
    } as IAnalytics);

  const handleV1Redirection = () => {
    const webviewPath =
      'https://{baseURL}.wheelsup.com/src/client/account/payment-methods';
    const webViewTitle = 'Manage credit card';

    dispatch(
      setAccountSubpage({
        accountSubpage: webviewPath,
        webViewTitle,
      }),
    );
    saveDataInLocalStorage(ACCOUNT_SUBPAGE_WEBVIEW_KEY, {
      webviewKey: webviewPath,
    });
    saveDataInLocalStorage(ACCOUNT_SUBPAGE_WEBVIEW_TITLE, {
      webviewTitle: webViewTitle,
    });
    saveDataInLocalStorage(
      'shouldShowLoadingPageWeb',
      true,
    );
    checkIsTokenValid();
    handleAnalytics();

    navigate(webviewPath.split('/src/client')[1] ?? '');
  };

  const handleNativeCcRedirection = () => {
    checkIsTokenValid();
    handleAnalytics();

    navigate(
      `/account/manage-credit-cards/credit-card?action=edit&is_primary=${
        isPrimaryCard ? 'true' : 'false'
      }`,
    );
  };

  const handleRedirection = () => {
    if (nativeCcFlag) {
      handleNativeCcRedirection();
    } else {
      handleV1Redirection();
    }
  };

  return (
    <CreditCardContainer
      id={id}
      onClick={handleRedirection}
      data-name="CreditCard"
      $isFirst={isFirst}
      $isLast={isLast}
    >
      <section>
        <CCElementVerticalContainer>
          <CCElementsSection>
            <CCElementContainer>
              <Typography
                align="left"
                variant="heading05"
                color="primary"
              >
                {`${cardType?.toLocaleUpperCase()} - ${lastFourDigits}`}
              </Typography>
            </CCElementContainer>
            <CCElementContainer>
              {isPrimaryCard && (
                <Typography
                  align="left"
                  variant="body02Bold"
                  color="primary"
                >
                  Primary
                </Typography>
              )}
            </CCElementContainer>
          </CCElementsSection>
        </CCElementVerticalContainer>
        <CCElementVerticalContainer>
          <CCElementContainer>
            <Typography
              align="left"
              variant="body02"
              color={!isExpired ? 'placeholder' : 'warning'}
            >
              {expiration}
            </Typography>
          </CCElementContainer>
        </CCElementVerticalContainer>
      </section>
      <IconContainer $isFirst={isFirst} $isLast={isLast}>
        <Icon
          name="chevron-right"
          size="s"
          color="primary"
        />
      </IconContainer>
    </CreditCardContainer>
  );
}
