import { solidColors } from '@constants/styles/colors.constants';
import {
  TextField as MuiTextField,
  withStyles,
} from '@material-ui/core';

const TextField = withStyles(({ palette }) => ({
  root: {
    color: palette.text.disabled,
    '& label': {
      color: palette.text.disabled,
    },
    '& label.Mui-focused': {
      color: palette.text.disabled,
    },
    '& MuiInput-underline, .Mui-disabled:before': {
      border: 'none',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottomColor: palette.info.main,
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: solidColors.mediumGray.color,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: palette.info.main,
    },
    '&  .MuiInput-underline, .Mui-error:after': {
      borderBottomColor: palette.error.main,
    },
    width: '19rem',
  },
}))(MuiTextField);

export default TextField;
