import { IFBO } from '@components/types';
import {
  FBOContainer,
  CardContainer,
  TitleContainer,
} from './ListFBOView.styles';
import { Typography } from '@atoms';
import { typography } from '@constants/styles/typography.constants';
import { solidColors } from '@constants/styles/colors.constants';
import { Elevation } from '@wheelsup/wu-react-components';

interface IListFBOView {
  title: string;
  fbos: IFBO[];
}

interface IFBOView {
  companyWithState: string;
  body: string;
}

const FBOView = ({ companyWithState, body }: IFBOView) => {
  const lines = body.split('\n');

  return (
    <FBOContainer>
      <Typography variant={typography.heading05Large}>
        {companyWithState}
      </Typography>
      {lines.map((l, i) => (
        <Typography
          key={i}
          variant={typography.heading05Large}
          color={solidColors.darkGray}
        >
          {l}
        </Typography>
      ))}
    </FBOContainer>
  );
};

export default function ListFBOView({
  title,
  fbos,
}: IListFBOView) {
  return (
    <CardContainer>
      <Elevation variant='raisedLevel2'>
        <TitleContainer>
          <Typography
            variant={typography.heading03Large}
            color={solidColors.midnight}
          >
            {title}
          </Typography>
        </TitleContainer>
        <>
          {fbos.map((f) => (
            <div key={f.postalCode}>
              <FBOView
                companyWithState={`${f.displayFullValue1}, ${f.state}`}
                body={f.displayFullValue2 ?? ''}
              />
            </div>
          ))}
        </>
      </Elevation>
    </CardContainer>
  );
}
