import { ImageImgix } from '@atoms';
import styled from 'styled-components';

export const PlaneImg = styled(ImageImgix)`
  position: absolute;
  top: 10rem;
  right: 0;
  z-index: 0;
  pointer-events: none;
  height: 345px;

  ${(props) => props.theme.breakpoints.down('lg')} {
    height: 300px;
    top: 10rem;
  }

  @media (max-width: 1100px) {
    height: 225px;
    top: 18rem;
  }

  ${(props) => props.theme.breakpoints.down('sm')} {
    height: 200px;
    top: 15rem;
  }

  ${(props) => props.theme.breakpoints.down('xs')} {
    height: 110px;
    top: 20rem;
  }
`;
