import { ICardFlightDealResult } from './CardFlightDealResult.interfaces';
import {
  AircraftDetailsSpacer,
  AirportCodeStyled,
  BodyContainer,
  DetailsContainer,
  ExpirationText,
  FlightDateContainer,
  FlightDetailsContainer,
  DepartureIcon,
  HeadContainer,
  StyledContainer,
  TimesContainer,
  DepartureDetail,
  ActionContainer,
  MobilePriceContainer,
  ButtonContainer,
  ContentContainer,
  DepartureContainer,
} from './CardFlightDealResult.styles';
import { useNavigate } from 'react-router-dom';
import { saveDataInLocalStorage } from '@components/utils/storage';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  buttonPopupOpen,
  pagePopup,
  snackbarOpen,
} from '@features/Members/popupSlice';
import { getMemberTypeCode } from '@services/tokenService/token';
import { hostedImage } from '@constants/images/images.constants';
import {
  Elevation,
  WidthOptions,
  Typography,
} from '@wheelsup/wu-react-components';
import { Button } from '@atoms/Button/Button';

export default function CardFlightDealResult({
  id,
  dealId,
  flightDateText,
  expirationText,
  airportCodeText,
  departureArrivalText,
  aircraftTypeLine1,
  aircraftTypeLine2,
  paxAvailabilityText,
  estimatedFlightTimeText,
  estimatedDepartureTimeRangeText,
  flightOperatorText,
  price,
  additionalFeesText,
  paxAvailabilityIcon,
  estimatedFlightTimeIcon,
  estimatedDepartureTimeRangeIcon,
  flight,
  button,
  surfaceAction,
}: ICardFlightDealResult) {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(false);
  const cardRef = useRef<HTMLDivElement>(null);

  const dispatch = useDispatch();
  const memberTypeCode = getMemberTypeCode();

  useEffect(() => {
    const checkSize = () => {
      if (cardRef.current) {
        setIsMobile(
          (cardRef.current.offsetWidth ?? 0) <= 900,
        );
      } else {
        setIsMobile(false);
      }
    };
    checkSize();

    window.addEventListener('resize', checkSize);

    return () => {
      window.removeEventListener('resize', checkSize);
    };
  }, []);

  const handleRequest = () => {
    if (button.action.type === 'display_membership_lock') {
      dispatch(buttonPopupOpen(true));
      dispatch(pagePopup(true));
      dispatch(snackbarOpen(false));
    } else {
      saveDataInLocalStorage(
        'flightLegTitle',
        airportCodeText,
      );
      navigate(
        `/flight-detail/one-way/${dealId}/request-book`,
      );
    }
  };

  return (
    <StyledContainer
      id={id}
      data-testid={id}
      data-name="CardFlightDealResult"
      ref={cardRef}
      onClick={surfaceAction && handleRequest}
      $clickable={!!surfaceAction}
    >
      <Elevation
        variant="raisedLevel2"
        padding={0}
        widthOptions={WidthOptions.INHERIT}
      >
        <HeadContainer $isMobile={isMobile}>
          <FlightDateContainer>
            <Typography
              variant="body02Bold"
              color="interactivePrimary"
            >
              {flightDateText}
            </Typography>
          </FlightDateContainer>
          <ExpirationText variant="body02">
            {expirationText}
          </ExpirationText>
        </HeadContainer>
        <ContentContainer $isMobile={isMobile}>
          <BodyContainer>
            <div>
              <AirportCodeStyled variant="body02Bold">
                {airportCodeText}
              </AirportCodeStyled>
              <Typography variant="heading02">
                {departureArrivalText}
              </Typography>
            </div>
            {!isMobile && (
              <div>
                <Typography variant="heading01">
                  {price}
                </Typography>
                <Typography
                  variant="body02"
                  color="placeholder"
                >
                  {additionalFeesText}
                </Typography>
              </div>
            )}
          </BodyContainer>

          <FlightDetailsContainer>
            <DetailsContainer>
              <Typography variant="body02" color="primary">
                {aircraftTypeLine1}
              </Typography>
              {aircraftTypeLine2 && (
                <>
                  <AircraftDetailsSpacer
                    variant="body02"
                    color="placeholder"
                  >
                    <>/</>
                  </AircraftDetailsSpacer>
                  <Typography
                    variant="body02"
                    color="placeholder"
                  >
                    {aircraftTypeLine2}
                  </Typography>
                </>
              )}
            </DetailsContainer>
            <Typography variant="body02" color="secondary">
              Operated by {flightOperatorText}
            </Typography>
          </FlightDetailsContainer>

          <TimesContainer $isMobile={isMobile}>
            <DepartureContainer $isMobile={isMobile}>
              <DepartureIcon
                alt="pax availability icon"
                src={paxAvailabilityIcon.url}
                filter={'darkGray'}
                height={20}
                width={20}
              />
              <DepartureDetail
                variant="body02"
                color="placeholder"
              >
                {paxAvailabilityText}
              </DepartureDetail>
            </DepartureContainer>
            <DepartureContainer $isMobile={isMobile}>
              <DepartureIcon
                alt="Estimated flight time icon"
                src={estimatedFlightTimeIcon.url}
                filter={'darkGray'}
                height={20}
                width={20}
              />
              <DepartureDetail
                variant="body02"
                color="placeholder"
              >
                {estimatedFlightTimeText}
              </DepartureDetail>
            </DepartureContainer>
            <DepartureContainer $isMobile={isMobile}>
              <DepartureIcon
                alt="depart icon"
                src={estimatedDepartureTimeRangeIcon.url}
                filter={'darkGray'}
                height={20}
                width={20}
              />
              <DepartureDetail
                variant="body02"
                color="placeholder"
              >
                {estimatedDepartureTimeRangeText}
              </DepartureDetail>
            </DepartureContainer>
          </TimesContainer>

          <ActionContainer $isMobile={isMobile}>
            {isMobile && (
              <MobilePriceContainer>
                <Typography variant="heading03">
                  {price}
                </Typography>
                <Typography
                  variant="body02"
                  color="secondary"
                >
                  {additionalFeesText}
                </Typography>
                {memberTypeCode === 'FLY' && (
                  <ButtonContainer isFlyUserAndCardIsSlim>
                    <Button
                      {...button}
                      image={{
                        ...button.image,
                        url:
                          hostedImage.roundedChevronRight,
                      }}
                      iconHeight={16}
                      iconWidth={16}
                      action={{
                        actionMethod: handleRequest,
                      }}
                    />
                  </ButtonContainer>
                )}
              </MobilePriceContainer>
            )}
            {(memberTypeCode !== 'FLY' || !isMobile) && (
              <ButtonContainer>
                <Button
                  {...button}
                  image={{
                    ...button.image,
                    url: hostedImage.roundedChevronRight,
                  }}
                  iconHeight={16}
                  iconWidth={16}
                  action={{
                    actionMethod: handleRequest,
                  }}
                />
              </ButtonContainer>
            )}
          </ActionContainer>
        </ContentContainer>
      </Elevation>
    </StyledContainer>
  );
}
