import { ImageImgix } from '@atoms';
import { solidColors } from '@constants/styles/colors.constants';
import styled from 'styled-components';

export const Tabs = styled.ul`
  background: rgba(255, 255, 255, 0.25);
  border-radius: 10px;
  padding: 10px;
  ${(props) => props.theme.breakpoints.down('sm')} {
    padding: 5px;
  }
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  position: relative;
`;

export const Option = styled.div`
  color: ${solidColors.midnight.color};
  border: 1px solid ${solidColors.mediumGray.color};
  border-radius: 24px;
  ${(props) => props.theme.breakpoints.up('sm')} {
    width: 129px;
  }
  height: 40px;

  cursor: pointer;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Aktiv;
  font-size: 14px;
  font-weight: 400;

  svg,
  img,
  .material-icons {
    width: 1.25rem;
    margin-right: 1rem;
    height: auto;
  }

  &.active {
    border: 1px solid ${solidColors.upBlue.color};
    color: ${solidColors.upBlue.color};
    border-radius: 24px;
    font-weight: 600;
  }
  ${(props) => props.theme.breakpoints.down('sm')} {
    width: auto;
    font-size: 0.75rem;
    padding: 15px 13px 13px 13px;
    margin-right: 0px;
  }
`;

export const ImageStyled = styled(ImageImgix)`
  margin-right: 1rem;
  width: 1.25rem;
  height: auto;
`;
