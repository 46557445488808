import { dataInitialState } from '@components/types';
import { getFromLocalStorage } from '@components/utils/storage';
import {
  createAsyncThunk,
  createSlice,
  Dispatch,
} from '@reduxjs/toolkit';
import { pegasusClient } from '@services/apiService';
import { ISliceFactoryArgs } from './sliceFactoryService.types';

// add params for urlParam and &param
interface IProps {
  resPayload?: unknown;
  queryParams?: string;
  urlParams?: string;
  retry?: boolean;
}

export function asyncThunkFactory({
  pageName,
  endpoint,
  version,
  type,
}: ISliceFactoryArgs) {
  return createAsyncThunk(
    `${pageName}/fetch${pageName}`,
    async (props: IProps) => {
      const {
        resPayload,
        urlParams = '',
        queryParams = '',
      } = props;
      const url = endpoint + urlParams;
      try {
        if (type === 'post') {
          return (
            await pegasusClient.post(
              `${url}?version=${version}&platform=web${queryParams}`,
              resPayload,
            )
          ).data;
        } else if (type === 'put') {
          return (
            await pegasusClient.put(
              `${url}?version=${version}&platform=web${queryParams}`,
              resPayload,
            )
          ).data;
        } else {
          return (
            await pegasusClient.get(
              `${url}?version=${version}&platform=web${queryParams}`,
            )
          ).data;
        }
      } catch (err) {
        return err;
      }
    },
  );
}

export function dataSliceFactory({
  endpoint,
  pageName,
  version,
}: ISliceFactoryArgs) {
  const dataFetchAsyncThunk = asyncThunkFactory({
    endpoint: endpoint,
    pageName: pageName,
    version: version,
  });

  const requestSlice = createSlice({
    name: pageName,
    initialState: dataInitialState,
    reducers: {
      getPage(state, action) {
        const { payload } = action;
        state.data = payload;
      },
      cleanState: () => dataInitialState,
    },
    extraReducers: (builder) => {
      builder.addCase(
        dataFetchAsyncThunk.pending,
        (state) => {
          state.isLoading = true;
        },
      );
      builder.addCase(
        dataFetchAsyncThunk.fulfilled,
        (state, action) => {
          const { payload } = action;
          state.isLoading = false;

          if (payload?.response?.status === 400) {
            state.error = true;
            state.isSuccessful = false;
            state.errorPayload =
              payload.response.data.data.data;
          } else {
            state.isSuccessful = true;
            state.data = payload.data;
          }
        },
      );
      builder.addCase(
        dataFetchAsyncThunk.rejected,
        (state, action) => {
          const { payload } = action;
          state.isLoading = false;
          state.error = payload;
          state.isSuccessful = false;
        },
      );
    },
  });

  return {
    mainReducer: requestSlice.reducer,
    actions: requestSlice.actions,
  };
}
