import { ISelectItem } from './CardHeadlineSearchSelectCTADismissSelect.interfaces';
import React from 'react';
import { solidColors } from '@constants/styles/colors.constants';
import { typography } from '@constants/styles/typography.constants';
import { Typography } from '@atoms';
import { ListItemText, ListItemIcon } from '@library';
import { ListItemStyled } from './CardHeadlineSearchSelectCTADismissSelect.styles';
import { Checkbox } from '@wheelsup/wu-react-components';

export const MultiSelect = ({
  item,
  disabled,
  checked,
  onClick,
}: ISelectItem) => {
  return (
    <ListItemStyled
      onClick={(
        _event: React.ChangeEvent<HTMLInputElement>,
      ) => {
        onClick(_event, item, false);
      }}
      dense
      button
      value={item}
      disabled={disabled}
    >
      <ListItemIcon>
        <Checkbox
          checked={false}
          onChange={() => null}
          disabled={disabled}
          size="sm"
        />
      </ListItemIcon>
      <ListItemText>
        <Typography
          variant={typography.heading05Large}
          color={solidColors.darkGray}
          truncate={1}
        >
          {item}
        </Typography>
      </ListItemText>
    </ListItemStyled>
  );
};
