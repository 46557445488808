import { Button, ButtonGroup, Container } from '@library';
import { solidColors } from '@constants/styles/colors.constants';
import styled from 'styled-components';

export const ContainerBackground = styled.div`
  padding-top: 11rem;
`;

export const ContentArea = styled(Container)`
  height: auto;
`;

export const ButtonStyled = styled(Button)`
  border: none;
  border-radius: 0;
  padding: 1rem 0;
  margin: 36px auto 50px auto;
  max-width: 200px;
  border-bottom: ${(props: { selected: boolean }) =>
    props.selected
      ? `2px solid ${solidColors.midnight.color}`
      : '2px solid rgba(0, 0, 0, 0)'};

  @media (max-width: 600px) {
    margin: 18px 20px 0 20px !important;
  }
`;

export const ButtonGroupStyled = styled(ButtonGroup)`
  display: flex;
  justify-content: center;
  @media (max-width: 600px) {
    flex-wrap: wrap;
  }
`;
