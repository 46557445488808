import styled from 'styled-components';
import { solidColors } from '@constants/styles/colors.constants';
import { TextField } from '@atoms';
import {
  Icons,
  List,
  ListItem,
  ListItemIcon,
} from '@library';

export const InputContainer = styled.div`
  max-width: 31rem;
`;

export const TextFieldStyled = styled(TextField)`
  width: 100%;
`;

export const ListStyled = styled(List)``;

export const ListItemStyled = styled(ListItem)`
  padding: 0 0 5px 0;
  max-width: 350px;
`;

export const ListItemIconStyled = styled(ListItemIcon)`
  min-width: 2rem;
`;

export const CloseIcon = styled(Icons.Close)`
  color: ${solidColors.lava.color};
`;

export const CheckIcon = styled(Icons.Check)`
  color: ${solidColors.basil.color};
`;

export const VisibilityIcon = styled(Icons.Visibility)`
  color: ${solidColors.midnight.color};
`;

export const VisibilityOffIcon = styled(
  Icons.VisibilityOff,
)`
  color: ${solidColors.midnight.color};
`;
