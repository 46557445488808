// This compoonent is used for ContainerEmpty and ContainerColororImageBackgroundWEBONLY

import React, {
  useState,
  useEffect,
  ReactElement,
} from 'react';
import { IEmptyContainer } from './EmptyContainer.interfaces';
import {
  BackgroundWrapper,
  ChildContainer,
  StyledImageContainer,
} from '@atoms/BackgroundWrapper';
import { Container } from '@library';
import { ImageImgix } from '@atoms';
import { BleedType, SpacerType } from '@components/types';
import {
  StyledSpacer,
  ContainerWrapper,
  FullContainer,
} from './ContainerEmpty.styles';

export default function ContainerEmpty({
  id,
  canContainDismissible,
  spacerTypeTop,
  spacerTypeBottom,
  bleed,
  image,
  background,
  children,
  ...rest
}: IEmptyContainer) {
  const [blueBackground, setBlueBackground] = useState(
    false,
  );

  useEffect(() => {
    background === 'blue'
      ? setBlueBackground(true)
      : setBlueBackground(false);
  }, []);

  let pxSpacerTypeTop = spacerTypeTop;
  if (typeof spacerTypeTop === 'string') {
    pxSpacerTypeTop =
      SpacerType[
        spacerTypeTop as keyof typeof SpacerType
      ] ?? 0;
  }

  let pxSpacerTypeBottom = spacerTypeBottom;
  if (typeof spacerTypeBottom === 'string') {
    pxSpacerTypeBottom =
      SpacerType[
        spacerTypeBottom as keyof typeof SpacerType
      ] ?? 0;
  }

  const renderChildren = () => {
    if (Array.isArray(children)) {
      return <Container {...rest}>{children}</Container>;
    } else if (children) {
      return React.cloneElement(children as ReactElement, {
        ...rest,
      });
    } else return null;
  };

  const ConditionalContainer =
    bleed === BleedType.full
      ? FullContainer
      : ContainerWrapper;

  return (
    <ConditionalContainer data-name="ContainerEmpty">
      {pxSpacerTypeTop !== undefined &&
        Number(pxSpacerTypeTop) > 0 && (
          <StyledSpacer
            height={`${pxSpacerTypeTop}px`}
            data-name="ContainerWrapper-StyledSpacer"
          />
        )}
      {image ? (
        <BackgroundWrapper $blueBackground={blueBackground}>
          <StyledImageContainer>
            <ImageImgix
              src={image?.url}
              filter={image?.tintColor}
              alt="background image"
              fit="crop"
              height={400}
            />
            <ChildContainer>
              {renderChildren()}
            </ChildContainer>
          </StyledImageContainer>
        </BackgroundWrapper>
      ) : (
        renderChildren()
      )}
      {pxSpacerTypeBottom !== undefined &&
        Number(pxSpacerTypeBottom) > 0 && (
          <StyledSpacer
            height={`${pxSpacerTypeBottom}px`}
          />
        )}
    </ConditionalContainer>
  );
}
