import React, {
  useRef,
  useState,
  useEffect,
  useContext,
} from 'react';
import {
  AirportSearchListItem,
  AirportSearchPopover,
} from '@components/molecules';
import { useOnClickOutside } from '@hooks';
import {
  IAutoCompleteFlight,
  IAirportItem,
} from './AutoCompleteFlight.interfaces';
import {
  StyledPlaceholderContainer,
  StyledTextField,
  useStyles,
  AutocompleteContainer,
  HomeIcon,
  TypographyStyled,
  LabelContainer,
  NearbyAirContainer,
  IconContainer,
  DeleteIconButton,
  DeleteIcon,
} from './AutoCompleteFlight.styles';
import { getAirportSearchResults } from '@services/airportSubpageSearchService/airportSubpageSearchService';
import { Typography } from '@atoms';
import { solidColors } from '@constants/styles/colors.constants';
import { typography } from '@constants/styles/typography.constants';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '@app/hooks';
import {
  updateLeg as updateLegV1,
  INearbyAirportProps,
  addNearbyAirports,
  initialNearbyAirports,
} from '@features/Fly/searchFlightSlice';
import { IPage } from '@services/dynamicRenderingService/dynamicRendering.interfaces';
import { useFlightSearch } from '@components/molecules/SubPageFlightSearchV2/FlightSearchContext/FlightSearchHooks';
import { FlightSearchContext } from '@components/molecules/SubPageFlightSearchV2/FlightSearchContext/FlightSearchContext';
import { flightTypes } from '@components/molecules/SubPageFlightSearchV2/FlightSearchContext/FlightSearchContext.types';

const AutoCompleteFlight = ({
  titleField,
  hasFormError,
  detail = 'Enter airport, city or ZIP',
  error,
  value,
  recentlySearchedAirports,
  savedAirports,
  onChange,
  transparent = false,
  highDensityCopy,
  conditionalCopy,
  homeImage,
  pinImage,
  deleteImage,
  savedAirportsInstructions,
  savedAirportsHeader,
  recentlySearchedAirportsHeader,
  titleText,
  emptyAirportListsCopy,
  textFieldWidth,
  disable,
  legNumber,
  type,
  flightTripType,
  getInitialPegasusCall,
  saveAction,
  deleteAction,
}: IAutoCompleteFlight) => {
  const {
    legs,
    updatePricingRequest,
    updateLeg,
    pricingRequest,
  } = useFlightSearch(useContext(FlightSearchContext));
  const popupRef = useRef(null);
  const dispatch = useDispatch();
  const legsData = useAppSelector(
    (state) => state.searchFlight.legs,
  );
  const NearBy = useAppSelector(
    (state) => state.searchFlight.nearbyAirports,
  );
  const activeNearby = useAppSelector(
    (state) => state.searchFlight.activeNearby,
  );
  const activeIndex = useAppSelector(
    (state) => state.searchFlight.activeIndex,
  );
  const nearbyData = useAppSelector(
    (state) => state.nearbyAirports.data,
  );

  const [
    inputAirportRef,
    setInputAirportRef,
  ] = useState<any>();

  const [inputValue, setInputValue] = useState('');
  const [
    nearbyAirports,
    setNearbyAirports,
  ] = useState<INearbyAirportProps>(NearBy);
  const [options, setOptions] = useState<IAirportItem[]>(
    [],
  );
  const [
    currVal,
    setCurrVal,
  ] = useState<IAirportItem | null>(null);
  const inputClasses = useStyles({
    transparent,
    disable,
  });

  const [
    anchorEl,
    setAnchorEl,
  ] = useState<HTMLInputElement | null>(null);
  const [
    openCustomPopover,
    setOpenCustomPopover,
  ] = useState(false);
  const [noOptionsText, setNoOptionsText] = useState('');
  const [label, setLabel] = useState(detail);
  const [
    savedAirportsState,
    setSavedAirportsState,
  ] = useState(savedAirports);
  const [
    recentlySearchedAirportsState,
    setRecentAirportsState,
  ] = useState(recentlySearchedAirports);
  const [isSearching, setIsSearching] = useState(false);
  const [
    isWaitingForSearchResponse,
    setIsWaitingForSearchResponse,
  ] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const [showIcon, setShowIcon] = useState(false);
  const mouseEnterHandler = () => setShowIcon(true);
  const mouseLeaveHandler = () => setShowIcon(false);

  const clearSearch = () => {
    setInputValue('');
    setLabel(detail);
    setCurrVal(null);
    // Update the pricing request to default airport if cleared
    if (flightTripType !== flightTypes.multiCity) {
      updatePricingRequest({
        ...pricingRequest,
        departureAirport:
          type === 'departureAirport'
            ? 1
            : pricingRequest.departureAirport,
        arrivalAirport:
          type === 'arrivalAirport'
            ? 1
            : pricingRequest.arrivalAirport,
      });
    }
    // Update the airports to null if cleared
    if (typeof legNumber === 'number') {
      if (type === 'departureAirport') {
        updateLeg(
          { ...legs[legNumber], departureAirport: null },
          legNumber,
        );
      }
      if (type === 'arrivalAirport') {
        updateLeg(
          { ...legs[legNumber], arrivalAirport: null },
          legNumber,
        );
      }
    }
  };

  const getOptions = async (matchString: string) => {
    if (matchString.length > 2) {
      const removeSpacesBetweenWordsregexPattern = /\s+/g;
      setIsWaitingForSearchResponse(true);
      const res = await getAirportSearchResults(
        matchString
          .replace(
            removeSpacesBetweenWordsregexPattern,
            ' ',
          )
          .trim(),
      );
      setOptions(res?.data?.results);
      setNoOptionsText(res?.data?.noResultsCopy);
      setTimeout(() => {
        setIsWaitingForSearchResponse(false);
      }, 250);
    }
    setIsSearching(true);
  };

  useEffect(() => {
    getInitialPegasusCall(titleText, true);
  }, []);

  useEffect(() => {
    setNearbyAirports(NearBy);
    onResultsClose();
  }, [NearBy]);

  useEffect(() => {
    if (value !== null) {
      setCurrVal(value);
      // UNCLEAR IF THIS BREAKS ANYTHING
      // onChange(value);

      setLabel(
        `${value?.city}${
          value?.state !== undefined
            ? `, ${value?.state}`
            : ''
        }${
          value?.country !== 'US' &&
          value?.country !== undefined
            ? `, ${value?.country}`
            : ''
        }`,
      );
      setInputValue(`${value?.code} - ${value?.name}`);
      setIsSearching(false);
      onResultsClose();
    } else {
      // Handling when 'value' is null
      setCurrVal(null); // Resetting or handling
      setLabel('Enter airport, city or ZIP');
      setInputValue('');
    }
  }, [value]);

  useEffect(() => {
    setSavedAirportsState(savedAirports);
    setRecentAirportsState(recentlySearchedAirports);
  }, [savedAirports, recentlySearchedAirports]);

  const onResultsClose = () => {
    setOpenCustomPopover(false);
    setAnchorEl(null);
    setOptions([]);
  };

  const handleSaveIconClick = (
    _event: React.MouseEvent,
    airportId: string,
  ) => {
    _event.stopPropagation();
    saveAction(airportId);
  };

  const handleDeleteIconClick = (
    _event: React.MouseEvent,
    airportId: string,
  ) => {
    _event.stopPropagation();
    deleteAction(airportId);
  };

  const onAutoCompleteChange = (
    _event: React.ChangeEvent<{}>,
    newValue: IAirportItem | null,
  ) => {
    setCurrVal(newValue);
    onChange(newValue);

    setInputValue(`${newValue?.city}, ${newValue?.state}`);
    setLabel(`${newValue?.code} - ${newValue?.name}`);
    setIsSearching(false);
    onResultsClose();

  };

  const onInputChange = (
    _event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    _event.preventDefault();

    setLabel('Enter a city or airport');
    const newInputValue = _event.currentTarget.value;
    setInputValue(newInputValue);
    isSearching && setOpenCustomPopover(true);
    if (newInputValue.length > 2) {
      setIsSearching(true);
      getOptions(newInputValue);
      setIsWaitingForSearchResponse(true);
    } else {
      setIsWaitingForSearchResponse(false);
    }
  };

  const handleFocus = (
    event: React.MouseEvent<HTMLInputElement>,
  ) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
    setOpenCustomPopover(true);

    //focus textfield from ref
    inputAirportRef.focus();
  };

  const onCustomPopperClose = () => {
    setOpenCustomPopover(false);
    setIsSearching(false);
  };

  const handleContinue = () => {
    dispatch(addNearbyAirports(initialNearbyAirports));
  };

  const handleNearbyClick = async (
    _event: React.ChangeEvent<{}>,
    index: number,
  ) => {
    if (legNumber != undefined) {
      const currLeg = {
        ...legsData[legNumber],
      };

      currLeg.errors = [];

      const updatedLegs = legsData.filter((leg) => leg);

      updatedLegs.splice(legNumber, 1, currLeg);

      dispatch(await updateLegV1(updatedLegs));
    }

    onAutoCompleteChange(
      _event,
      nearbyAirports?.data[index] as IAirportItem,
    );
  };

  const handleOutsideClick = () => {
    handleContinue();
  };

  useEffect(() => {
    if (nearbyData && activeNearby == type) {
      const data = (nearbyData as IPage)?.elements[0]?.data
        ?.element?.data?.airports as IAirportItem[];

      dispatch(
        addNearbyAirports({
          type,
          data,
        }),
      );
    }
  }, [nearbyData]);

  useEffect(() => {
    if (label !== 'Enter a city or airport') {
      setOpenCustomPopover(false);
    }
  }, [label]);

  useOnClickOutside(popupRef, handleOutsideClick);

  return (
    <AutocompleteContainer
      onClick={handleFocus}
      onMouseEnter={mouseEnterHandler}
      onMouseLeave={mouseLeaveHandler}
    >
      {showIcon && inputValue.length > 0 && (
        <IconContainer onClick={(e) => clearSearch()}>
          <DeleteIconButton >
            <DeleteIcon />
          </DeleteIconButton>
        </IconContainer>
      )}

      <StyledPlaceholderContainer data-testid="autocomplete-helper-text">
        <span
          {...(error && {
            className: 'error-message',
          })}
        >
          {currVal?.isHomeAirport && homeImage.url && (
            <HomeIcon
              src={homeImage.url}
              filter={homeImage.tintColor}
              alt="home icon"
              height={20}
              width={20}
            />
          )}
          {error ?? (
              <LabelContainer>
                <TypographyStyled
                  variant={typography.body1}
                  color={
                    disable
                      ? solidColors.mediumGray
                      : solidColors.midnight
                  }
                  truncate={2}
                >
                  {titleField}
                </TypographyStyled>
              </LabelContainer>
            ) ??
            detail}
        </span>
      </StyledPlaceholderContainer>

      <StyledTextField
        $textFieldWidth={textFieldWidth}
        data-testid="autocomplete-textbox"
        disabled={disable}
        error={!!error}
        fullWidth
        placeholder={label}
        variant="standard"
        value={inputValue}
        ref={inputRef}
        onClick={handleFocus}
        onChange={onInputChange}
        inputRef={(input) => {
          input && setInputAirportRef(input);
        }}
        InputProps={{
          disableUnderline: !error,
          classes: {
            input: inputClasses.inputRoot,
          },
        }}
      />

      {!error && (
        <>
          {activeNearby === type &&
            legNumber === activeIndex &&
            hasFormError?.includes(type) &&
            !(nearbyAirports.data.length == 0) && (
              <NearbyAirContainer
                data-name="nearbyair"
                ref={popupRef}
              >
                <Typography
                  variant={typography.body1}
                  color={solidColors.darkGray}
                >
                  Suggested Nearby Airports
                </Typography>

                {nearbyAirports?.data?.map(
                  (airports, idx) => {
                    return (
                      <div
                        key={airports.code}
                        onClick={(e) =>
                          handleNearbyClick(e, idx)
                        }
                      >
                        <AirportSearchListItem
                          {...airports}
                          highDensityCopy={highDensityCopy}
                          conditionalCopy={conditionalCopy}
                          pinImage={pinImage}
                          deleteImage={deleteImage}
                          homeImage={homeImage}
                          saveAction={() => null}
                          deleteAction={() => null}
                          isSaved={false}
                        />
                      </div>
                    );
                  },
                )}
              </NearbyAirContainer>
            )}
        </>
      )}

      {!disable && (
        <AirportSearchPopover
          waitingForResponse={isWaitingForSearchResponse}
          open={openCustomPopover}
          searchAirports={options}
          isSearching={isSearching}
          emptySearchResultsMessage={noOptionsText}
          savedAirports={savedAirportsState}
          recentAirports={recentlySearchedAirportsState}
          emptyAirportListsCopy={emptyAirportListsCopy}
          highDensityCopy={highDensityCopy}
          pinImage={pinImage}
          deleteImage={deleteImage}
          homeImage={homeImage}
          conditionalCopy={conditionalCopy}
          savedAirportsHeader={savedAirportsHeader}
          savedAirportsInstructions={
            savedAirportsInstructions
          }
          recentlySearchedAirportsHeader={
            recentlySearchedAirportsHeader
          }
          saveAction={handleSaveIconClick}
          deleteAction={handleDeleteIconClick}
          anchorEl={anchorEl}
          onChange={onAutoCompleteChange}
          handleClose={onCustomPopperClose}
          inputValue={inputValue}
        />
      )}
    </AutocompleteContainer>
  );
};

export default AutoCompleteFlight;
