import styled from 'styled-components';

export const CardFlightLegContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: auto;
  min-width: 100%;
  width: auto;
  gap: 24px;
  height: auto;
  position: relative;
`;
