export const trackingTitles = {
  Select_SearchFlight_Button: 'Select_SearchFlight_Button ',
  Select_OneWayFlight_Toggle: 'Select_OneWayFlight_Toggle ',
  Select_RoundTripFlight_Toggle:
    'Select_RoundTripFlight_Toggle ',
  Select_MultiCityFlight_Toggle:
    'Select_MultiCityFlight_Toggle ',
  Select_CostBreakdownDetails_Dropdown:
    'Select_CostBreakdownDetails_Dropdown ',
  Select_BackArrow_Button: 'Select_BackArrow_Button ',
  Select_SwitchSelectedAirports_Button:
    'Select_SwitchSelectedAirports_Button ',
  Select_AddRemovePassengerViaReservationPage_Button:
    'Select_AddRemovePassengerViaReservationPage_Button',
  Select_ManageCreditCard_ListItem:
    'Select_ManageCreditCard_ListItem',
  Unauthorized_Logout: 'Unauthorized Logout',
  Select_EditSearch_Button: 'Select_EditSearch_Button',
};
