import { Button } from '@atoms/Button/Button';
import styled from 'styled-components';

export const EditContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const TitleContainer = styled.div`
  margin-right: 10px;
`;

export const ProfileContainer = styled.div`
  padding: 0;
  cursor: pointer;
`;

export const EditProfileButton = styled(Button)`
  padding: 0;
  min-width: 34px;
`;
