import {
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit';
import { pageInitialState } from '@components/types';
import {
  endpoints,
  getAuthHeaders,
  pegasusClient,
} from '../../services/apiService';
import { saveDataInLocalStorage } from '@components/utils/storage';
import { FOOTER_STORAGE_STATE } from '@services/sliceFactoryService/sliceFactory';

export const fetchMembersSubPage = createAsyncThunk(
  'membersSubPage/fetchMembersSubPage',
  async (param: string) => {
    const headers = getAuthHeaders();
    try {
      const data = (
        await pegasusClient.get(
          endpoints.membersSubPage(param),
          {
            headers: headers,
          },
        )
      ).data;
      return data;
    } catch (err) {
      return err;
    }
  },
);
const membersSubPageSlice = createSlice({
  name: 'membersSubPage',
  initialState: pageInitialState,
  reducers: {
    getPage(state, action) {
      const { payload } = action;
      state.data = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchMembersSubPage.pending,
      (state) => {
        state.isLoading = true;
      },
    );
    builder.addCase(
      fetchMembersSubPage.fulfilled,
      (state, action) => {
        const { payload } = action;
        state.isLoading = false;
        state.data = payload;
        saveDataInLocalStorage(
          FOOTER_STORAGE_STATE,
          payload,
        );
      },
    );
    builder.addCase(
      fetchMembersSubPage.rejected,
      (state, action) => {
        const { payload } = action;
        state.isLoading = false;
        state.error = payload;
      },
    );
  },
});

export default membersSubPageSlice.reducer;
