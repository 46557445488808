import React, { useState, useEffect, useRef } from 'react';
import { ICarouselScrollHorizontal } from './CarouselScrollHorizontal.interfaces';
import {
  CarouselContainer,
  CarouselButton,
  Arrow,
  Wrapper,
} from './CarouselScrollHorizontal.styles';
import { useCarouselSlides, useViewport } from '@hooks';
import Slides from './Slides';

function CarouselScrollHorizontal({
  children,
  parentWidth = 1,
  inComponent = true,
}: ICarouselScrollHorizontal) {
  const { width } = useViewport();
  const [currWidth, setCurrWidth] = useState(width);
  const parentRef = useRef<HTMLDivElement>(null);
  const childRef = useRef<HTMLDivElement>(null);
  const {
    slidesPerView,
    totalSlides,
    transformWidth,
    startIndex,
    activeForward,
    activeBack,
    allActiveFallback,
    slideMargin,
    slides,
    handleArrowBack,
    handleArrowForward,
  } = useCarouselSlides({
    slideRef: childRef,
    parentContainer: parentRef,
    children,
    inComponent,
    width,
    isHorizontalScroll: currWidth === width,
    slideMargin: 16,
  });
  const [showArrows, setShowArrows] = useState(false);

  useEffect(() => {
    currWidth !== width && setCurrWidth(width);
    setShowArrows(
      totalSlides > slidesPerView &&
        inComponent &&
        slides.length > 1,
    );
  }, [
    width,
    totalSlides,
    slidesPerView,
    allActiveFallback,
    inComponent,
  ]);

  if (slides.length === 0) {
    // adding a fallback incase we get 0 children
    return null;
  }

  return (
    <Wrapper>
      {showArrows && (
        <CarouselButton
          id="left"
          onClick={handleArrowBack}
          $isLeft
          className={!activeBack ? 'disabled' : ''}
        >
          <Arrow
            id="left"
            onClick={handleArrowBack}
            className={!activeBack ? 'disabled' : ''}
            fontSize="small"
          />
        </CarouselButton>
      )}
      <CarouselContainer
        ref={parentRef}
        data-id="CarouselScrollHorizontal"
        id="CarouselScrollHorizontal"
      >
        <Slides
          slideRef={childRef}
          transformAmount={transformWidth}
          slidesPerView={slidesPerView}
          startIndex={startIndex}
          slideMargin={slideMargin}
        >
          {slides}
        </Slides>
      </CarouselContainer>
      {showArrows && (
        <CarouselButton
          id="right"
          onClick={handleArrowForward}
          className={!activeForward ? 'disabled' : ''}
        >
          <Arrow
            id="right"
            onClick={handleArrowForward}
            className={!activeForward ? 'disabled' : ''}
            fontSize="small"
          />
        </CarouselButton>
      )}
    </Wrapper>
  );
}

export default CarouselScrollHorizontal;
