import React from 'react';
import { Button } from '@atoms';
import { IButton } from '@atoms/Button/Button.interfaces';
import {
  ButtonContainer,
  ButtonsContainer,
  Divider,
  StyledQuickAccessBar,
} from './QuickAccessBar.styles';
import { IFlight } from '@components/types';

interface IQuickAccessBar {
  leftQuickAccessButtons: IButton[];
  rightQuickAccessButtons: IButton[];
  flight: IFlight;
  tailNumber: string;
}

function QuickAccessBar({
  leftQuickAccessButtons,
  rightQuickAccessButtons,
  flight,
  tailNumber,
}: IQuickAccessBar) {
  /*
        Notes for future iterations:
        At the time of building this, we are only going to expect the 'CHange/Cancel' button to come down in left buttons
        After speaking with LP, it is ok to build with this assumption until functionality is built for other buttons.
        There is a spacing issue when having a mobile card, so I think It might be worth to explore a two click option
        when we get there.
        1. First click on a button in the card to open a menu of options (Dropdown/Drawer from bottom card or popup)
        2. Second click to choose Extras/Change/Share from menu
    
    */

  const imgixStyles = {
    borderRadius: '0',
  };

  const { fromAirport, toAirport } = flight;
  const departureDate = flight.departureDateDisplayValue;
  const arrivalDate = flight.arrivalDateDisplayValue;
  const departureTime =
    flight.departureTimeDisplayValueUpdated &&
    flight.departureTimeDisplayValueUpdated.length > 0
      ? flight.departureTimeDisplayValueUpdated
      : flight.departureTimeDisplayValue;
  const arrivalTime =
    flight.arrivalTimeDisplayValueUpdated &&
    flight.arrivalTimeDisplayValueUpdated.length > 0
      ? flight.arrivalTimeDisplayValueUpdated
      : flight.arrivalTimeDisplayValue;

  const textToCopy = `Wheels Up | ${fromAirport.code} to ${
    toAirport.code
  }\n\nTail number: ${
    tailNumber ? tailNumber : 'Tail assigned 4 hrs prior'
  }\n\nReservation number: ${
    flight.reservationNumber
  }\n\nDeparting ${
    fromAirport.code
  } on ${departureDate} at ${departureTime}\n\nArriving ${
    toAirport.code
  } on ${arrivalDate} at ${arrivalTime}\n\n`; // eslint-disable-line

  return (
    <StyledQuickAccessBar className="QuickAccessBar">
      <Divider />
      <ButtonsContainer>
        <ButtonContainer $align="left">
          {leftQuickAccessButtons.map((button, idx) => {
            const key = `leftQuickAccessButton-${button.title}-${idx}`;
            return (
              <Button
                key={key}
                styleType="text_with_icon_leading"
                title={button.title}
                action={{
                  ...button.action,
                  actionMethod: undefined,
                  data:
                    button.action.type === 'share_flight'
                      ? textToCopy
                      : button.action.data,
                }}
                image={button.image}
                imgixStyles={imgixStyles}
              />
            );
          })}
        </ButtonContainer>
        <ButtonContainer $align="right">
          {rightQuickAccessButtons.map((button, idx) => {
            const key = `rightQuickAccessButton-${button.title}-${idx}`;
            return (
              <Button
                key={key}
                styleType="text_with_icon_leading"
                title={button.title}
                action={{
                  ...button.action,
                  actionMethod: undefined,
                  data:
                    button.action.type === 'share_flight'
                      ? textToCopy
                      : button.action.data,
                }}
                image={button.image}
                imgixStyles={imgixStyles}
              />
            );
          })}
        </ButtonContainer>
      </ButtonsContainer>
    </StyledQuickAccessBar>
  );
}

export default QuickAccessBar;
