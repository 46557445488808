import { Container, Grid } from '@library';
import { Button, ContainerEmpty, Typography } from '@atoms';
import { typography } from '@constants/styles/typography.constants';
import { IContainerHeadlineSubCopyCTAProps } from './ContainerHeadlineSubCopyCTA.interfaces';
import {
  ContainerContent,
  GridWithMarginTop,
} from './ContainerHeadlineSubCopyCTA.styles';
import { BackgroundWrapper } from '@atoms/BackgroundWrapper';
import Spacer from '@atoms/Spacer/Spacer';
import { solidColors } from '@constants/styles/colors.constants';
import { useViewport } from '@hooks';
import { hostedImage } from '@constants/images/images.constants';
import { IButton } from '@atoms/Button/Button.interfaces';

const ContainerHeadlineSubCopyCTA = ({
  headline,
  copy,
  button,
  children,
  spacerTypeTop,
  spacerTypeBottom,
}: IContainerHeadlineSubCopyCTAProps) => {
  const { width } = useViewport();

  const buttonProps: IButton = {
    styleType: 'text_with_icon_trailing',
    color: solidColors.midnight,
    action: {
      actionMethod: button?.action?.actionMethod,
      data: button?.action?.data,
      type: button?.action?.type,
    },
    title: button?.title || 'CTA',
    image: {
      url: hostedImage.roundedChevronRight,
      tintColor: 'upBlue',
    },
    iconWidth: 16,
    iconHeight: 16
  };

  return (
    <BackgroundWrapper data-name="ContainerHeadlineSubCopyCTA">
      {spacerTypeTop && <Spacer height={spacerTypeTop} />}
      <Container>
        <Grid container>
          <Grid
            item
            xs={12}
          >
            <Typography
              variant={typography.heading02Large}
              color={solidColors.midnight}
            >
              {headline}
            </Typography>
          </Grid>
          {copy && (
            <GridWithMarginTop
              item
              xs={12}
            >
              <Typography
                variant={typography.body1}
                color={solidColors.darkGray}
                truncate={3}
              >
                {copy}
              </Typography>
            </GridWithMarginTop>
          )}
        </Grid>
        {button && width > 600 && (
          <GridWithMarginTop
            item
            xs={12}
          >
            <Button {...buttonProps} />
          </GridWithMarginTop>
        )}
      </Container>
      <ContainerContent>
        <Grid>{children}</Grid>
      </ContainerContent>
      {width <= 600 && button && (
        <ContainerEmpty
          id="MobileButtonContainer"
          canContainDismissible={false}
        >
          <Grid container>
            <GridWithMarginTop
              item
              xs={12}
            >
              <Button {...buttonProps} />
            </GridWithMarginTop>
          </Grid>
        </ContainerEmpty>
      )}
      {spacerTypeBottom && (
        <Spacer height={spacerTypeBottom} />
      )}
    </BackgroundWrapper>
  );
};

export default ContainerHeadlineSubCopyCTA;
