const userAgent = window.navigator.userAgent;

// export const isMobileDevice = /iPhone|iPod|iPad/i.test(
//   userAgent,
// );
export const isMobileDevice = /Android|webOS|iPhone|iPod|iPad|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Silk|Opera Mini/i.test(
  userAgent,
);

export const isIOS = /iPad|iPhone|iPod/i.test(userAgent);

// Leaving here in case we need to add Android support
export const isAndroid = /Android/i.test(userAgent);

export const app = 'wheelsup://';

export const stores = {
  IOS:
    'https://itunes.apple.com/us/app/wheels-up/id956615077?mt=8',
  Android:
    'https://play.google.com/store/apps/details?id=com.wheelsup.app.app',
};

export const IOSPaths = {
  home: '',
  signin: 'signin',
};
