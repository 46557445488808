import { ContainerEmpty, PageBackground } from '@atoms';
import { PageBuilderContainerStyled } from '@atoms/PageBuilderContainer/PageBuilderContainer.styles';
import { useNavigate } from 'react-router-dom';
import { CardHeadlinesubcopyCTAImageOversizedWEBONLY } from '..';

const NoPathFoundPage = () => {
  const navigate = useNavigate();
  return (
    <PageBackground metadata={undefined}>
      <PageBuilderContainerStyled $margin={'10'}>
        <ContainerEmpty
          id={'1'}
          canContainDismissible
        >
          <CardHeadlinesubcopyCTAImageOversizedWEBONLY
            headline="There doesn't seem to be anything here."
            copy="Go back to continue."
            image={{
              url:
                'https://a986261fb00bf2dd4ca0-ce701a730a522f7cd904e72ae946fa25.ssl.cf2.rackcdn.com/Detailed%20Shots_CVG%20Shoot%20(2).jpg',
              tintColor: '',
            }}
            button={{
              title: 'Go Home',
              action: {
                actionMethod: () => navigate('/'),
              },
            }}
          />
        </ContainerEmpty>
      </PageBuilderContainerStyled>
    </PageBackground>
  );
};

export default NoPathFoundPage;
