import {
  endpoints,
  getAuthHeaders,
  pegasusClient,
} from '../apiService';

// Contact me request
export const sendContactRequest = async () => {
  const headers = getAuthHeaders();
  try {
    const data = (
      await pegasusClient.get(endpoints.contactMe, {
        headers: headers,
      })
    ).data;
    return data;
  } catch (err) {
    return err;
  }
};
