import { typography } from '@constants/styles/typography.constants';
import { Typography } from '@atoms';
import parse from 'html-react-parser';
import { useTextGroupBodyCopyLink } from './TextGroupBodyCopyLink.styles';
import { ITextGroupBodyCopyLink } from './TextGroupCopyLink.interfaces';
import { saveDataInLocalStorage } from '@components/utils/storage';
import { generateUrlFromUri } from '@atoms/Button/Button.utils';

export const LEGAL_SUBPAGE_WEBVIEW_KEY =
  'LEGAL_SUBPAGE_WEBVIEW_KEY';
export const LEGAL_SUBPAGE_WEBVIEW_TITLE =
  'LEGAL_SUBPAGE_WEBVIEW_TITLE';

export default function TextGroupBodyCopyLink({
  copy,
  textAttributes = {},
  alignmentState,
  className,
  variant = typography.body2,
  truncate,
  as,
  libraryColor,
}: ITextGroupBodyCopyLink) {
  const classes = useTextGroupBodyCopyLink();
  const validLinks = textAttributes.links ?? [];

  const subaccountPage = (action: any) => {
    saveDataInLocalStorage(LEGAL_SUBPAGE_WEBVIEW_KEY, {
      webviewKey: action.data,
    });
    saveDataInLocalStorage(LEGAL_SUBPAGE_WEBVIEW_TITLE, {
      webviewTitle: 'Legal Terms',
    });
  };

  const buildText = () => {
    const textToReplace = validLinks.map((l, i) => {
      const linkText = copy.substring(l.start, 1 + l.end);
      let subLink = undefined;
      // we only awnt to return the right url and then push them there...
      if (l.action.type === 'url') {
        subLink = l.action.data;
      } else {
        subLink = generateUrlFromUri(l.action, false);
      }

      if (
        l.action.type &&
        l.action.type === 'in_app_membersite'
      ) {
        subaccountPage(l.action);
      }

      const target: string =
        l.action.type === 'out_app_browser' ||
        l.action.type === 'out_of_app_browser'
          ? '_blank'
          : '';

      return {
        text: linkText,
        link: `<a
                data-testid="body-link-${i}-${linkText}"
                class="${classes.link}"
                target="${target}"
                rel="noreferrer"
                href="${subLink}">
                ${linkText}
            </a>`,
      };
    });

    const linkElements = textToReplace.reduce(
      (accumulator, link) => {
        return accumulator.replace(link.text, link.link);
      },
      copy,
    );
    return linkElements;
  };

  return (
    <>
      <Typography
        variant={variant}
        truncate={truncate}
        className={className}
        align={alignmentState}
        as={as}
        libraryColor={libraryColor}
      >
        {parse(buildText())}
      </Typography>
    </>
  );
}
