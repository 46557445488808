import styled from 'styled-components';
import { solidColors } from '@constants/styles/colors.constants';

export const StyledTimeFuelStopContainer = styled.div`
  width: auto;
  height: 24px;
  white-space: nowrap;
  margin-left: 40px;
  margin-top: 8px;
`;

export const TimeFuelStop = styled.div`
  margin-right: 44px;
`;

export const StyledPathContainer = styled.div`
  height: 50px;
  margin-top: 4rem;
  margin-left: 40px;
  display: inline-flex;

  ${(props) => props.theme.breakpoints.down('md')} {
    margin-left: 1rem;
  }
`;

export const PathDottedLine = styled.div`
  z-index: 10;
  width: 250px;
  height: 13px;
  border-bottom: 3px dotted;
  border-color: ${solidColors.mediumGray.color};

  ${(props) => props.theme.breakpoints.down('lg')} {
    width: 175px;
  }

  ${(props) => props.theme.breakpoints.down('md')} {
    width: 125px;
  }

  ${(props) => props.theme.breakpoints.down('sm')} {
    width: 80px;
  }

  @media (max-width: 600px) {
    width: 55px;
  }
`;

export const StyledCode = styled.div`
  font-size: 48px;
  color: ${solidColors.midnight.color};
`;

export const FromTextContainer = styled.div`
  z-index: 5;
  float: left;
  height: 50px;
  width: 60px;
  white-space: nowrap;
  margin: 0;
`;

export const ToTextContainer = styled.div`
  z-index: 5;
  float: left;
  height: 50px;
  width: 60px;
  white-space: nowrap;
  margin-left: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const PlaneIconContainer = styled.div`
  margin-left: 12px;
  margin-right: 12px;
  height: 20px;
  width: 20px;
`;
