import {
  CardAtomStyled,
  HeadlineStyled,
  SubCopyStyled,
  HeaderStyled,
  CardHeaderStyled,
  CTAContainerStyled,
  ButtonStyled,
  StyledImage,
  ImageContainerStyled,
  SingleButtonContainer,
} from './CardLowPriorityAlertNotifications.styles';
import { typography } from '@constants/styles/typography.constants';
import { solidColors } from '@constants/styles/colors.constants';
import { Typography } from '@atoms';
import { IconButton, Icons, Modal } from '@library';
import {
  IButtons,
  ICardLowPriorityAlertNotifications,
} from './CardLowPriorityAlertNotifications.interfaces';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setIsConfirmCancelPopupOpen } from '@features/MyTrips/MyTripsCancelSlice';
import { Action } from '@atoms/Action/Action.interfaces';
import { setRenewAlert } from '@features/Account/AccountSubpageSlice';

export default function CardLowPriorityAlertNotifications({
  image,
  title,
  copyTextView,
  buttonOne,
  buttonTwo,
  dismissable,
  width,
  height,
  leftOffset,
  isModalView,
}: ICardLowPriorityAlertNotifications) {
  const dispatch = useDispatch();

  const [showCard, setShowCard] = useState(true);

  const dismissCard = () => {
    setShowCard(false);
  };

  const handleClose = () => {
    if (window.location.pathname.includes('/my-trips/')) {
      dispatch(setIsConfirmCancelPopupOpen(false));
    } else if (window.location.pathname === '/account') {
      dispatch(setRenewAlert(false));
    } else {
      dismissCard();
    }
  };

  const getDesignatedTertiaryAction = (): Partial<Action> => {
    if (
      window.location.pathname.includes('/my-trips/') ||
      window.location.pathname === '/account'
    ) {
      return {
        actionMethod: handleClose,
      } as Partial<Action>;
    } else {
      return buttonOne?.action as Partial<Action>;
    }
  };

  const RenderButtons = (props: IButtons) => {
    if (
      props.buttonOne &&
      props.buttonOne.title &&
      props.buttonTwo &&
      props.buttonTwo.title
    ) {
      return (
        <CTAContainerStyled>
          <ButtonStyled
            $multi
            $isModalView={props.isModalView}
            styleType={
              props.buttonOne.styleType
                ? props.buttonOne.styleType
                : 'tertiary'
            }
            title={props.buttonOne.title}
            action={getDesignatedTertiaryAction()}
          />
          <ButtonStyled
            $multi
            $isModalView={props.isModalView}
            styleType={
              props.buttonTwo.styleType
                ? props.buttonTwo.styleType
                : 'card_secondary_web'
            }
            title={props.buttonTwo.title}
            action={props.buttonTwo.action}
          />
        </CTAContainerStyled>
      );
    } else if (props.buttonOne && props.buttonOne?.title) {
      return (
        <SingleButtonContainer>
          <ButtonStyled
            styleType={
              props.buttonOne.styleType ?? 'primary'
            }
            title={props.buttonOne.title}
            action={props.buttonOne.action}
            image={props.buttonOne?.image}
          />
        </SingleButtonContainer>
      );
    } else {
      return null;
    }
  };

  const alert = showCard ? (
    <>
      <CardAtomStyled
        data-name="CardLowPriorityAlertNotifications"
        className="CardLowPriorityAlertNotifications"
        height={height}
        width={width}
        leftOffset={leftOffset}
        $isModalView={isModalView}
      >
        <div>
        <CardHeaderStyled
          action={
            dismissable?.action?.type ===
            'molecule_close' ? (
              <IconButton
                aria-label="settings"
                onClick={dismissCard}
              >
                <Icons.Close />
              </IconButton>
            ) : null
          }
        />
        <HeaderStyled>
          <>
            {image && (
              <ImageContainerStyled>
                <StyledImage
                  width={50}
                  height={50}
                  src={image.url}
                  filter={image.tintColor}
                  alt="LowPriorityImage"
                />
              </ImageContainerStyled>
            )}
            <HeadlineStyled>
              <Typography
                align={'center'}
                variant={typography.heading03Large}
                color={solidColors.midnight}
                truncate={3}
              >
                {title}
              </Typography>
            </HeadlineStyled>
          </>
          <SubCopyStyled>
            <Typography
              align={'center'}
              variant={typography.body1}
              color={solidColors.darkGray}
              truncate={3}
            >
              {copyTextView?.copy}
            </Typography>
          </SubCopyStyled>
        </HeaderStyled>
        </div>
        <RenderButtons
          buttonOne={buttonOne}
          buttonTwo={buttonTwo}
          isModalView={isModalView}
        />
      </CardAtomStyled>
    </>
  ) : null;

  return isModalView ? (
    <Modal
      open={showCard}
      onClose={() => handleClose()}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      {alert}
    </Modal>
  ) : (
    alert
  );
}
