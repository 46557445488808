import { IIndexable } from '@components/types';
import { typographyColorVariants } from '@wheelsup/wu-react-components';

export type Color = {
  id: string;
  colorName: string;
  color: string;
  opacity: string;
  group: string;
  note: string;
  theme: string;
  tintColor: string;
  background: string;
  libraryVariant?: keyof typeof typographyColorVariants;
};

export type SurfaceColor = {
  id: string;
  colorName: string;
  color: string;
  background: string;
  opacity: string;
  group: string;
  note: string;
  theme: string;
};

//Generate tintColor from here https://codepen.io/sosuke/pen/Pjoqqp
export const solidColors: IIndexable<Color> = {
  // old colors to be removed once merged with athena
  c1: {
    id: 'c1',
    colorName: 'c1',
    color: '#0B2A44',
    background: '',
    opacity: '1',
    group: 'solidColors',
    note: 'text',
    theme: 'primaryVariant',
    tintColor:
      'invert(12%) sepia(11%) saturate(5366%) hue-rotate(174deg) brightness(102%) contrast(97%)',
  },
  c2: {
    id: 'c2',
    colorName: 'c2',
    color: '#039BE5',
    background: '',
    opacity: '1',
    group: 'solidColors',
    note: 'button',
    theme: 'secondary',
    tintColor:
      'invert(44%) sepia(89%) saturate(2531%) hue-rotate(173deg) brightness(98%) contrast(98%)',
  },
  c3: {
    id: 'c3',
    colorName: 'c3',
    color: '#028BCD',
    background: '',
    opacity: '1',
    group: 'solidColors',
    note: 'button tap state',
    theme: 'secondaryVariant',
    tintColor:
      'invert(37%) sepia(92%) saturate(981%) hue-rotate(169deg) brightness(92%) contrast(98%)',
  },
  c4: {
    id: 'c4',
    colorName: 'c4',
    color: '#78909C',
    background: '',
    opacity: '1',
    group: 'solidColors',
    note: 'icons',
    theme: 'primaryVariant',
    tintColor:
      'invert(61%) sepia(11%) saturate(585%) hue-rotate(155deg) brightness(89%) contrast(90%)',
  },
  c5: {
    id: 'c5',
    colorName: 'c5',
    color: '#424242',
    background: '',
    opacity: '1',
    group: 'solidColors',
    note: '',
    theme: 'primaryVariant',
    tintColor:
      'invert(24%) sepia(0%) saturate(14%) hue-rotate(242deg) brightness(92%) contrast(86%)',
  },
  c6: {
    id: 'c6',
    colorName: 'c6',
    color: '#757575',
    background: '',
    opacity: '1',
    group: 'solidColors',
    note: '',
    theme: 'primaryVariant',
    tintColor:
      'invert(48%) sepia(0%) saturate(427%) hue-rotate(176deg) brightness(94%) contrast(81%)',
  },
  c7: {
    id: 'c7',
    colorName: 'c7',
    color: '#D0D0D0',
    background: '',
    opacity: '1',
    group: 'solidColors',
    note: '',
    theme: 'primaryVariant',
    tintColor:
      'invert(86%) sepia(0%) saturate(157%) hue-rotate(257deg) brightness(99%) contrast(89%)',
  },
  c8: {
    id: 'c8',
    colorName: 'c8',
    color: '#E0E0E0',
    background: '',
    opacity: '1',
    group: 'solidColors',
    note: '',
    theme: 'primaryVariant',
    tintColor:
      'invert(98%) sepia(0%) saturate(1329%) hue-rotate(143deg) brightness(114%) contrast(76%)',
  },
  c9: {
    id: 'c9',
    colorName: 'c9',
    color: '#EBEBEB',
    background: '',
    opacity: '1',
    group: 'solidColors',
    note: '',
    theme: 'primaryVariant',
    tintColor:
      'invert(99%) sepia(0%) saturate(1421%) hue-rotate(105deg) brightness(122%) contrast(84%)',
  },
  c10: {
    id: 'c10',
    colorName: 'c10',
    color: '#F8F9FD',
    background: '',
    opacity: '1',
    group: 'solidColors',
    note: 'button',
    theme: 'secondaryVariant',
    tintColor:
      'invert(91%) sepia(4%) saturate(278%) hue-rotate(195deg) brightness(108%) contrast(98%)',
  },
  c11: {
    id: 'c11',
    colorName: 'c11',
    color: '#EDEFF8',
    background: '',
    opacity: '1',
    group: 'solidColors',
    note: 'button tap state',
    theme: 'secondaryVariant',
    tintColor:
      'invert(87%) sepia(48%) saturate(31%) hue-rotate(187deg) brightness(100%) contrast(95%)',
  },
  c12: {
    id: 'c12',
    colorName: 'c12',
    color: '#07A54F',
    background: '',
    opacity: '1',
    group: 'solidColors',
    note: 'status',
    theme: 'notification',
    tintColor:
      'invert(70%) sepia(87%) saturate(5623%) hue-rotate(121deg) brightness(90%) contrast(94%)',
  },
  c13: {
    id: 'c13',
    colorName: 'c13',
    color: '#F4452D',
    background: '',
    opacity: '1',
    group: 'solidColors',
    note: 'status',
    theme: 'alert',
    tintColor:
      'invert(40%) sepia(55%) saturate(6136%) hue-rotate(347deg) brightness(101%) contrast(91%)',
  },
  c14: {
    id: 'c14',
    colorName: 'c14',
    color: '#FAAB29',
    background: '',
    opacity: '1',
    group: 'solidColors',
    note: 'status',
    theme: 'notification',
    tintColor:
      'invert(79%) sepia(15%) saturate(5757%) hue-rotate(339deg) brightness(105%) contrast(96%)',
  },
  c15: {
    id: 'c15',
    colorName: 'c15',
    color: '#FFFFFF',
    background: '',
    opacity: '1',
    group: 'solidColors',
    note: '',
    theme: 'primaryVariant',
    tintColor:
      'invert(100%) sepia(0%) saturate(7474%) hue-rotate(359deg) brightness(107%) contrast(107%)',
  },
  c16: {
    id: 'c16',
    colorName: 'c16',
    color: 'rgba(255,255,255,0.5)',
    background: '',
    opacity: '0.5',
    group: 'solidColors',
    note: '',
    theme: 'secondaryVariant',
    tintColor: '',
  },
  c17: {
    id: 'c17',
    colorName: 'c17',
    color: 'rgba(45,66,102,0.6)',
    background: '',
    opacity: '0.5',
    group: 'solidColors',
    note: '',
    theme: 'secondaryVariant',
    tintColor: '',
  },
  c18: {
    id: 'c18',
    colorName: 'c18',
    color: '#2D4266',
    background: '',
    opacity: '1',
    group: 'solidColors',
    note: 'wheels up blue',
    theme: 'primaryVariant',
    tintColor:
      'invert(22%) sepia(17%) saturate(1657%) hue-rotate(179deg) brightness(96%) contrast(89%)',
  },
  //end of old color styles

  midnight: {
    id: 'c1',
    colorName: 'midnight',
    color: '#041C46',
    background: '',
    opacity: '1',
    group: 'solidColors',
    note: 'text',
    theme: 'primaryVariant',
    tintColor:
      'invert(7%) sepia(92%) saturate(1985%) hue-rotate(210deg) brightness(97%) contrast(101%)',
    libraryVariant: 'primary',
  },
  upBlue: {
    id: 'c2',
    colorName: 'upBlue',
    color: '#173FC2',
    background: '',
    opacity: '1',
    group: 'solidColors',
    note: 'text',
    theme: 'primaryVariant',
    tintColor:
      'invert(16%) sepia(94%) saturate(2533%) hue-rotate(223deg) brightness(99%) contrast(102%)',
    libraryVariant: 'link',
  },
  darkGray: {
    id: 'c3',
    colorName: 'darkGray',
    color: '#6B778B',
    background: '',
    opacity: '1',
    group: 'solidColors',
    note: 'text',
    theme: 'primaryVariant',
    tintColor:
      'invert(50%) sepia(6%) saturate(1278%) hue-rotate(179deg) brightness(90%) contrast(82%)',
    libraryVariant: 'secondary',
  },
  mediumGray: {
    id: 'c4',
    colorName: 'mediumGray',
    color: '#D1D1D1',
    background: '',
    opacity: '1',
    group: 'solidColors',
    note: 'text',
    theme: 'primaryVariant',
    tintColor:
      'invert(96%) sepia(1%) saturate(7470%) hue-rotate(189deg) brightness(115%) contrast(64%)',
    libraryVariant: 'placeholder',  
  },
  lightGray: {
    id: 'c5',
    colorName: 'lightGray',
    color: '#F1F3F8',
    background: '',
    opacity: '1',
    group: 'solidColors',
    note: 'text',
    theme: 'primaryVariant',
    tintColor:
      'invert(94%) sepia(25%) saturate(210%) hue-rotate(179deg) brightness(100%) contrast(95%)',
    libraryVariant: 'disabled',
  },
  basil: {
    id: 'c6',
    colorName: 'basil',
    color: '#008900',
    background: '',
    opacity: '1',
    group: 'solidColors',
    note: 'text',
    theme: 'primaryVariant',
    tintColor:
      'invert(26%) sepia(80%) saturate(1836%) hue-rotate(92deg) brightness(103%) contrast(105%)',
    libraryVariant: 'success',
  },
  lava: {
    id: 'c7',
    colorName: 'lava',
    color: '#E4250C',
    background: '',
    opacity: '1',
    group: 'solidColors',
    note: 'text',
    theme: 'primaryVariant',
    tintColor:
      'invert(13%) sepia(96%) saturate(5865%) hue-rotate(13deg) brightness(102%) contrast(91%)',
    libraryVariant: 'error',
  },
  jam: {
    id: 'c8',
    colorName: 'jam',
    color: '#FF6037',
    background: '',
    opacity: '1',
    group: 'solidColors',
    note: 'text',
    theme: 'primaryVariant',
    tintColor:
      'invert(47%) sepia(41%) saturate(2410%) hue-rotate(337deg) brightness(100%) contrast(103%)',
    libraryVariant: 'warning',
  },
  upWhite: {
    id: 'c9',
    colorName: 'upWhite',
    color: '#FFFFFF',
    background: '',
    opacity: '1',
    group: 'solidColors',
    note: 'text',
    theme: 'primaryVariant',
    tintColor:
      'invert(99%) sepia(4%) saturate(67%) hue-rotate(253deg) brightness(117%) contrast(100%)',
    libraryVariant: 'interactivePrimary',
  },
  blue5Percent: {
    id: 'c10',
    colorName: 'blue5Percent',
    color: '#E7E9F9',
    background: '',
    opacity: '1',
    group: 'solidColors',
    note: '',
    theme: 'secondaryVariant',
    tintColor:
      'invert(93%) sepia(5%) saturate(613%) hue-rotate(197deg) brightness(98%) contrast(100%)',
  },
  blue40Percent: {
    id: 'c11',
    colorName: 'blue40Percent',
    color: '#4B63D5',
    background: '',
    opacity: '1',
    group: 'solidColors',
    note: '',
    theme: 'secondaryVariant',
    tintColor:
      'invert(34%) sepia(92%) saturate(1074%) hue-rotate(210deg) brightness(88%) contrast(89%)',
  },
  blue30Percent: {
    id: 'c11',
    colorName: 'blue30Percent',
    color: '#6E80DD',
    background: '',
    opacity: '1',
    group: 'solidColors',
    note: '',
    theme: 'secondaryVariant',
    tintColor:
      'invert(45%) sepia(100%) saturate(337%) hue-rotate(193deg) brightness(94%) contrast(84%)',
  },
  blue20Percent: {
    id: 'c11',
    colorName: 'blue20Percent',
    color: '#9AA3E6',
    background: '',
    opacity: '1',
    group: 'solidColors',
    note: '',
    theme: 'secondaryVariant',
    tintColor:
      'invert(74%) sepia(87%) saturate(2132%) hue-rotate(194deg) brightness(100%) contrast(81%)',
  },
  blue10Percent: {
    id: 'c11',
    colorName: 'blue10Percent',
    color: '#C3C7F0',
    background: '',
    opacity: '1',
    group: 'solidColors',
    note: '',
    theme: 'secondaryVariant',
    tintColor:
      'invert(80%) sepia(20%) saturate(564%) hue-rotate(199deg) brightness(98%) contrast(92%)',
  },
  eggplant: {
    id: 'c11',
    colorName: 'eggplant',
    color: '#008900',
    background: '',
    opacity: '1',
    group: 'solidColors',
    note: '',
    theme: 'secondaryVariant',
    tintColor:
      'invert(22%) sepia(83%) saturate(4300%) hue-rotate(115deg) brightness(97%) contrast(102%)',
    libraryVariant: 'special1',
  },
  seaGreen: {
    id: 'c11',
    colorName: 'seaGreen',
    color: '#8C0C6B',
    background: '',
    opacity: '1',
    group: 'solidColors',
    note: '',
    theme: 'secondaryVariant',
    tintColor:
      'invert(9%) sepia(71%) saturate(5080%) hue-rotate(304deg) brightness(103%) contrast(98%)',
    libraryVariant: 'special2',
  },
  midnight20Percent: {
    id: 'c11',
    colorName: 'midnight20Percent',
    color: '#909EBA',
    background: '',
    opacity: '1',
    group: 'solidColors',
    note: '',
    theme: 'secondaryVariant',
    tintColor:
      'invert(71%) sepia(12%) saturate(569%) hue-rotate(182deg) brightness(85%) contrast(94%);',
  },
};

export const surfaceColors: IIndexable<SurfaceColor> = {
  //old color styles to be removed once merged with athena
  s1: {
    id: 's1',
    colorName: 's1',
    color: '#FFFFFF',
    background: '',
    opacity: '1',
    group: 'surfaceColors',
    note: '',
    theme: '',
  },
  s2: {
    id: 's2',
    colorName: 's2',
    color: '#F8F9FD',
    background: '',
    opacity: '1',
    group: 'surfaceColors',
    note: '',
    theme: '',
  },
  s3: {
    id: 's3',
    colorName: 's3',
    color: '#039BE5',
    background: '',
    opacity: '1',
    group: 'surfaceColors',
    note: '',
    theme: '',
  },
  s4: {
    id: 's4',
    colorName: 's4',
    color: '#FAAB29',
    background: '',
    opacity: '1',
    group: 'surfaceColors',
    note: '',
    theme: '',
  },
  s5: {
    id: 's5',
    colorName: 's5',
    color: '#2D4266',
    background: '',
    opacity: '1',
    group: 'surfaceColors',
    note: '',
    theme: '',
  },
  s6: {
    id: 's6',
    colorName: 's6',
    color: '#EBEBEB',
    background: '',
    opacity: '1',
    group: 'surfaceColors',
    note: '',
    theme: '',
  },
  //end of old color styles

  backgroundUpWhite: {
    id: 's1',
    colorName: 'backgroundUpWhite',
    color: '#FFFFFF',
    background: '',
    opacity: '1',
    group: 'surfaceColors',
    note: '',
    theme: '',
  },
  backgroundUpBlue: {
    id: 's2',
    colorName: 'backgroundUpBlue',
    color: '#173FC2',
    background: '',
    opacity: '1',
    group: 'surfaceColors',
    note: '',
    theme: '',
  },
  backgroundLava: {
    id: 's3',
    colorName: 'backgroundLava',
    color: '#E4250C',
    background: '',
    opacity: '1',
    group: 'surfaceColors',
    note: '',
    theme: '',
  },
  backgroundMidnight: {
    id: 's4',
    colorName: 'backgroundMidnight',
    color: '#041C46',
    background: '',
    opacity: '1',
    group: 'surfaceColors',
    note: '',
    theme: '',
  },
  backgroundLightGray: {
    id: 's5',
    colorName: 'backgroundLightGray',
    color: '#EBEBEB',
    background: '',
    opacity: '1',
    group: 'surfaceColors',
    note: '',
    theme: '',
  },
  backgroundJam: {
    id: 's6',
    colorName: 'backgroundJam',
    color: '#FF6037',
    background: '',
    opacity: '1',
    group: 'surfaceColors',
    note: '',
    theme: '',
  },
  backgroundBasil: {
    id: 's7',
    colorName: 'backgroundBasil',
    color: '#008900',
    background: '',
    opacity: '1',
    group: 'surfaceColors',
    note: '',
    theme: '',
  },
};

export const gradientColors = {
  g1: {
    id: 'g1',
    colorName: 'g1',
    color: '',
    background:
      'linear-gradient(to top, #2D4266, rgba(45, 66, 102, 0))',
    opacity: '1',
    group: 'gradientColors',
    note:
      'Vertical gradient, top is 0% opacity to bottom 100% opacity. Image overlay.',
    theme: '',
  },
  g2: {
    id: 'g2',
    colorName: 'g2',
    color: '',
    background:
      'linear-gradient(to bottom, #032993, #455CD4, #B2D5F8, #F8F9FD)',
    opacity: '1',
    group: 'gradientColors',
    note:
      'Top to bottom values translating as top to bottom in a linear gradient. Background (night)',
    theme: '',
  },
  g3: {
    id: 'g3',
    colorName: 'g3',
    color: '',
    background:
      'linear-gradient(to bottom, #0847C3, #6B98F2, #B2D5F8, #F8F9FD)',
    opacity: '1',
    group: 'gradientColors',
    note:
      'Top to bottom values translating as top to bottom in a linear gradient. Background (midday)',
    theme: '',
  },
  g4: {
    id: 'g4',
    colorName: 'g4',
    color: '',
    background:
      'linear-gradient(to bottom, #2989EA, #6EB6EF, #B2D5F8, #F8F9FD)',
    opacity: '1',
    group: 'gradientColors',
    note:
      'Top to bottom values translating as top to bottom in a linear gradient. Background (morning)',
    theme: '',
  },
  g5: {
    id: 'g5',
    colorName: 'g5',
    color: '',
    background:
      'linear-gradient(to bottom right, #FFFFFF, #6A6B8E)',
    opacity: '1',
    group: 'gradientColors',
    note:
      'Top to bottom values translating as top left corner to bottom right corner in a diagonal linear gradient. Image overlay in cards.',
    theme: '',
  },
  g6: {
    id: 'g6',
    colorName: 'g6',
    color: '',
    background:
      'linear-gradient(.25turn, rgb(255, 255, 255,0), rgb(255, 255, 255,.6))',
    opacity: '1',
    group: 'gradientColors',
    note:
      'Carousel overlay for the cards that are disappearing to the right ',
    theme: '',
  },
};
