import { ITextGroup } from './TextGroupBodyCopySubCopy.interfaces';
import { Headline, Subcopy } from '@atoms';
import styled from 'styled-components';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const TextGroup = ({
  title: headline,
  copy,
  subCopy,
  alignmentState = 'left',
}: ITextGroup) => {
  return (
    <StyledContainer>
      <Headline
        headline={headline}
        align={alignmentState}
      />
      <Subcopy
        copy={copy as string}
        align={alignmentState}
      />
    </StyledContainer>
  );
};

export default TextGroup;
