import ImageImgix from '@atoms/ImageImgix/ImageImgix';
import Typography from '@atoms/Typography/Typography';
import {
  AccordionDetails,
  AccordionSummary,
} from '@components/library';
import { typography } from '@constants/styles/typography.constants';
import React from 'react';
import { IAccordionBase } from './AccordionBase.interfaces';
import {
  BorderLine,
  ExpandIcon,
  StyledAccordionBase,
  StyledAccordionDetails,
  StyledAccordionSummaryContainer,
  StyledTypographyContainer,
  useStyles,
} from './AccordionBase.styles';
import { solidColors } from '@constants/styles/colors.constants';

function AccordionBase({
  title,
  subtitle,
  image,
  dropDownImage,
  disabled = false,
  isExpandedByDefault = false,
  children,
}: IAccordionBase) {
  const [isExpanded, setIsExpanded] = React.useState(
    isExpandedByDefault,
  );

  const classes = useStyles();

  const handleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const DropdownIcon: JSX.Element = dropDownImage?.url ? (
    <ImageImgix
      src={dropDownImage.url}
      alt="expand icon"
      width={15}
      height={15}
      borderRadius="0"
      filter="upBlue"
    />
  ) : (
    <ExpandIcon />
  );

  return (
    <StyledAccordionBase
      defaultExpanded={isExpandedByDefault}
      expanded={isExpanded}
      disabled={disabled}
      onClick={handleExpand}
    >
      <AccordionSummary expandIcon={DropdownIcon}>
        <StyledAccordionSummaryContainer>
          <ImageImgix
            src={image?.url || ''}
            alt="icon"
            width={32}
            height={32}
            borderRadius="0"
            filter={image?.tintColor || ''}
          />
          <StyledTypographyContainer>
            <Typography
              variant={typography.heading04Large}
              color={solidColors.midnight}
              align="left"
              truncate={1}
            >
              {title}
            </Typography>
            {subtitle && (
              <Typography
                variant={typography.body1}
                color={solidColors.darkGray}
              >
                {subtitle}
              </Typography>
            )}
          </StyledTypographyContainer>
        </StyledAccordionSummaryContainer>
      </AccordionSummary>
      <AccordionDetails
        classes={{
          root: classes.root,
        }}
      >
        <BorderLine />
        <StyledAccordionDetails>
          {children}
        </StyledAccordionDetails>
      </AccordionDetails>
    </StyledAccordionBase>
  );
}

export default AccordionBase;
